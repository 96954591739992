import { Subscription } from "rxjs";

import { Subs } from "../interfaces/subs.interface";

export function unsubscribeArray(subscriptions: Subscription[] = []) {
  subscriptions.forEach(subscription => {
    if (subscription) {
      subscription.unsubscribe();
    }
  });
}

export function unsubscribeObject(subscriptions: Subs) {
  const arraySubscriptions = Object.keys(subscriptions).map(key => {
    return subscriptions[key];
  });

  unsubscribeArray(arraySubscriptions);
}
