import { Directive, Injectable } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { GlobalService } from "../../../../../../core/providers/global.service";
import { OwDate } from "../../../../../../core/utility/ow-date.class";
import { Player } from "../../../../../player/interfaces/player";
import { PlayerService } from "../../../../../player/providers/player.service";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { ApiBusinessService } from "../../api/custom/services/api-business.service";
import { CARD_TEMPLATE } from "../../consts/custom/card-template.const";
import { VALUE_TYPE } from "../../consts/custom/value-type.const";
import { CardTask } from "../../interfaces/custom/card-task.interface";
import { Task } from "../../interfaces/custom/task.interface";

@Directive()
@Injectable()
export abstract class AbstractCardTaskDetailsComponent extends AbstractInjectBaseComponent {
  @OwInject(ApiBusinessService) apiBusinessService: ApiBusinessService;
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(MAT_DIALOG_DATA) data: {
    cardTask: CardTask;
    task?: Task;
    cardTasks?: CardTask[];
    showOtherClose?: boolean;
    player?: Player;
    backButton?: boolean;
    hideChooseTasksButtons?: boolean;
  };

  abstract dialogService: DialogService;
  VALUE_TYPE = VALUE_TYPE;
  CARD_TEMPLATE = CARD_TEMPLATE;
  task: Task;
  cardTask: CardTask;
  cardTasks: CardTask[];
  notEmptyCardTasks: CardTask[] = [];
  currentIndex: number;
  owDate: OwDate;
  otherTaskIndex = null;

  setCurrentIndex() {
    this.notEmptyCardTasks.forEach((cardTask, index) => {
      if (this.cardTask.card_position === cardTask.card_position) {
        this.currentIndex = index;
      }
    });
  }

  setTask(task: Task) {
    this.task = task;

    this.owDate = new OwDate({
      month: this.task.month,
      year: this.task.year,
    });
  }

  setCardTask(cardTask: CardTask) {
    this.cardTask = cardTask;
  }

  changeCardTask(cardTask: CardTask) {
    this.setCardTask(cardTask);
    this.setTask(cardTask.selected);
    this.setCurrentIndex();
    this.clearOtherTaskIndex();
  }

  next() {
    let index = 0;
    if (this.currentIndex < this.notEmptyCardTasks.length - 1) {
      index = this.currentIndex + 1;
    }
    this.changeCardTask(this.notEmptyCardTasks[index]);
  }

  prev() {
    let index = this.notEmptyCardTasks.length - 1;
    if (this.currentIndex > 0) {
      index = this.currentIndex - 1;
    }
    this.changeCardTask(this.notEmptyCardTasks[index]);
  }

  clearOtherTaskIndex() {
    this.otherTaskIndex = null;
  }

  openInfo() {
    this.dialogService.openAlert({
      description:
        "Cegły za zadania biznesowe (miesięczne) są naliczane wprost proporcjonalnie do realizacji. Maksymalnie za dany miesiąc możesz otrzymać 130% nagrody bazowej, np. nagroda bazowa wynosi 2000 cegieł, zadanie zostało zrealizowane na 145%, na Twoje konto zostanie naliczone 2600 cegieł.",
    });
  }
}
