import { Directive, Injectable, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { CurrencyService } from "../../../../../../core/providers/currency.service";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { NavigationUi } from "../../../shared-ui/interfaces/navigation-ui.interface";
import { STOCK_VIEW } from "../../../shared-ui/mobile/consts/stock-view.const";
import { GetCurrencyTransactionRequest } from "../../api/core/interfaces/get-currency-transaction-request.interface";
import { ApiTransactionHistoryService } from "../../api/core/services/api-transaction-history.service";
import { afterRequestCurrencyTransactionHelper } from "../../helpers/custom/after-request-currency-transaction.helper";
import { CurrencyTransactionHistory } from "../../interfaces/core/currency-transaction-history.interface";

@Directive()
@Injectable()
export abstract class CoreAbstractHistoryTransactionBaseComponent
  extends AbstractInjectBaseComponent
  implements OnInit
{
  @OwInject(ApiTransactionHistoryService) apiTransactionHistoryService: ApiTransactionHistoryService;
  @OwInject(MAT_DIALOG_DATA) data: any;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(CurrencyService) currencyService: CurrencyService;

  STOCK_VIEW = STOCK_VIEW;
  items: CurrencyTransactionHistory[];
  navigation: NavigationUi = {
    currentPage: 1,
    maxPage: null,
  };
  limit = 10;

  ngOnInit() {
    this.getCurrencyTransaction();
  }

  getCurrencyTransaction(page?: number) {
    if (page) {
      this.navigation.currentPage = page;
    }
    const getCurrencyTransactionRequest: GetCurrencyTransactionRequest = {
      currency: this.data.currency.currency_id,
      limit: this.limit,
      page: this.navigation.currentPage,
    };
    this.apiTransactionHistoryService.currencyTransaction(getCurrencyTransactionRequest).subscribe(resp => {
      this.items = resp.body;
      this.navigation.maxPage = resp.headers.get("x-pages-count");
      if (this.data.currency.is_gross) {
        this.items = this.items.map(item => {
          item.value = this.currencyService.divisionCurrency(this.data.currency, item.value);
          return item;
        });
      }
      afterRequestCurrencyTransactionHelper.call(this);
    });
  }
}
