import { TileMenuConfig } from "../../interfaces/tile-menu-config.interface";
import { BoardTile } from "../custom/BoardTile.class";
import { BUTTONS_CONFIG } from "./buttons-config";

const BASE_TEXT_STYLES = {
  color: "#FFF",
  fontFamily: "MikadoPhaser",
  align: "center",
  boundsAlignH: "bottom",
  wordWrapWidth: 200,
  wordWrap: true,
  boundsAlignV: "bottom",
};

export const TILE_MENU_CONFIG: TileMenuConfig = {
  buttons: BUTTONS_CONFIG,
  mainTextStyles: {
    ...BASE_TEXT_STYLES,
    fontSize: 32,
  },
  levelTextStyles: {
    ...BASE_TEXT_STYLES,
    fontSize: 24,
  },
  maxZoomScaleValue: 1.5,
  scaleFactor: 1,
  mainTextOffset: 16,
};

export function checkAdditionalBoardDataButtonActive(boardTile: BoardTile) {
  return boardTile.playerBuildingData.additional_board_data.hasOwnProperty(this.additionalBoardData);
}
