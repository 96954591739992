import { Injectable } from "@angular/core";

@Injectable()
export class DictionaryService {
  /**
   * DATE
   */
  private _langMonthRoman = {
    1: "I",
    2: "II",
    3: "III",
    4: "IV",
    5: "V",
    6: "VI",
    7: "VII",
    8: "VIII",
    9: "IX",
    10: "X",
    11: "XI",
    12: "XII",
  };

  private _langMonthText = {
    1: "Styczeń",
    2: "Luty",
    3: "Marzec",
    4: "Kwiecień",
    5: "Maj",
    6: "Czerwiec",
    7: "Lipiec",
    8: "Sierpień",
    9: "Wrzesień",
    10: "Październik",
    11: "Listopad",
    12: "Grudzień",
  };

  private _quarter = {
    1: "Q1",
    2: "Q2",
    3: "Q3",
    4: "Q4",
  };

  private _half = {
    1: "H1",
    2: "H2",
  };

  getMonth(month) {
    if (month >= 1 && month <= 12) {
      return this._langMonthRoman[month];
    }
    return 0;
  }

  getMonthText(month) {
    if (month >= 1 && month <= 12) {
      return this._langMonthText[month];
    }
    return 0;
  }

  getQuarterName(quarter) {
    if (quarter >= 1 && quarter <= 4) {
      return this._quarter[quarter];
    }
    return 0;
  }

  getHalfName(half) {
    if (half >= 1 && half <= 2) {
      return this._half[half];
    }
    return 0;
  }
}
