import { Injectable } from "@angular/core";

import { ApiOptions } from "../../../core/interfaces/api-options";
import { ApiService } from "../../../core/providers/api.service";

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  constructor(private apiService: ApiService) {}

  event(analyticsEvent: AnalyticsEvent) {
    const options: ApiOptions = {
      body: {
        ...analyticsEvent,
      },
    };

    return this.apiService.post(`analytics/event`, options);
  }
}

interface AnalyticsEvent {
  action: string;
  category: string;
  label?: string;
  value?: string;
}
