import { createAction, props } from "@ngrx/store";
import { ApplicationConfig } from "./interfaces/config.state";

export enum ActionTypes {
  FETCH_CONFIG_START = "[config] fetch application config start",
  FETCH_CONFIG_SUCCESS = "[config] fetch application config success",
  FETCH_CONFIG_FAILURE = "[config] fetch application config failure",
}

export const configFetchStart = createAction(ActionTypes.FETCH_CONFIG_START);
export const configFetchSuccess = createAction(ActionTypes.FETCH_CONFIG_SUCCESS, props<{ applicationConfig: ApplicationConfig }>());
export const configFetchFailure = createAction(ActionTypes.FETCH_CONFIG_FAILURE, props<{ error: any }>());
