import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";

import { ApiOptions } from "../../../../../../../core/interfaces/api-options";
import { ApiService } from "../../../../../../../core/providers/api.service";
import { SchemaValidatorService } from "../../../../../../../services/schema-validator/schema-validator.service";
import { PlayerService } from "../../../../../../player/providers/player.service";
import { EVENT_DIALOGS_NAMES_TRANSACTION_HISTORY } from "../../../consts/core/event-dialogs/event-names.const";
import { GetCurrencyTransactionRequest } from "../interfaces/get-currency-transaction-request.interface";

@Injectable({
  providedIn: "root",
})
export class ApiTransactionHistoryService {
  constructor(
    private apiService: ApiService,
    private playerService: PlayerService,
    private schemaValidator: SchemaValidatorService
  ) {}

  currencyTransaction({ page, currency, limit, player_id }: GetCurrencyTransactionRequest) {
    player_id = player_id || this.playerService.getActivePlayerId();

    const options: ApiOptions = {
      responseWithHeaders: true,
      params: {
        page,
        currency,
        limit,
      },
    };

    return this.apiService.get(`player/${player_id}/transactions`, options, EVENT_DIALOGS_NAMES_TRANSACTION_HISTORY.HISTORY).pipe(
      tap(response => {
        this.schemaValidator.validateResponse({
          url: `player/${player_id}/transactions`,
          payload: {},
          response: response["body"],
          schema: "playerTransactionsGame",
        });
      })
    );
  }
}
