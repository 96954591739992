import { Injectable } from "@angular/core";
import { MatDialogConfig } from "@angular/material/dialog";
import { select, Store } from "@ngrx/store";

import { EventEmitterDialogsService } from "../../../../../../core/services/core/event-emitter-dialogs.service";
import { AppState } from "../../../../../../store/state";
import { selectUtilityMissionToOpen } from "../../../../../../store/utility/selectors";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { EVENT_DIALOGS_NAMES_MISSION } from "../../consts/core/event-dialogs/event-names";
import { MissionDetails } from "../../interfaces/core";

@Injectable({
  providedIn: "root",
})
export class MissionService {
  missions: Partial<MissionDetails>[];
  timer: null | ReturnType<typeof setTimeout>;

  constructor(
    private store: Store<AppState>,
    private eventEmitterDialogsService: EventEmitterDialogsService
  ) {
    this.subscribeMissionToOpen();
  }

  subscribeMissionToOpen() {
    this.store.pipe(select(selectUtilityMissionToOpen)).subscribe(missions => {
      clearTimeout(this.timer);
      this.missions = missions;
      this.checkAllowOpenMissions();
    });
  }

  openMissionDetails(config: MatDialogConfig, callback?: (data?) => void) {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_MISSION.MISSION_DETAILS,
      config,
      callback,
    });
  }

  openMissions(config?: MatDialogConfig, callback?: (data?) => void) {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_MISSION.MISSIONS,
      config,
      callback,
    });
  }

  checkAllowOpenMissions() {
    if (this.missions.length > 0) {
      if (DialogService.openDialogs.length === 0) {
        this.openMissionDetails(
          {
            data: {
              mission: this.missions[0],
              afterCloseOpenList: true,
            },
          },
          ({ afterCloseOpenList }: { afterCloseOpenList?: boolean } = {}) => {
            if (afterCloseOpenList) {
              this.openMissions();
            }
          }
        );
      } else {
        this.timer = setTimeout(() => {
          this.checkAllowOpenMissions();
        }, 3000);
      }
    }
  }
}
