<!-- SCREEN LOCK IF POSITION = LANDSCAPE -->
<div id="landscape-screen" *ngIf="deviceService.isMobile && !deviceService.isAllowOrientation">
  <div class="flex-center">
    <i class="svg-icon mobile"></i>
    <p class="land-title">
      {{ "device-rotate.title" | myTranslate }}
    </p>
    <p class="land-description">
      {{ "device-rotate.description" | myTranslate }}
    </p>
  </div>
</div>
