import { RoadSide } from "../interfaces/board-tile.config";
import { PositionOffset } from "../interfaces/shared";

export const MAIN_SCENE_WORLD_WIDTH = 10000;
export const MAIN_SCENE_WORLD_HEIGHT = 7000;

export const MAIN_BOOT_SCENE = "MAIN_BOOT_SCENE";
export const MAIN_PRELOAD_SCENE = "MAIN_PRELOAD_SCENE";
export const MAIN_MAIN_SCENE = "MAIN_MAIN_SCENE";

export const ROADS_SIDES_VALUES: RoadSide[] = ["north", "east", "south", "west"];

export const BASE_ROADS_WIDTH = 5;
export const BASE_ROADS_HEIGHT = 5;

export const TILE_MENU_ATLAS = "tile-menu-atlas";
