<!--GAME ENGINE -->
<app-mobile-game-engine-pce></app-mobile-game-engine-pce>

<!-- GAME UI -->
<template #appHudTpl></template>

<app-device-rotate></app-device-rotate>

<game-loaded-placeholder></game-loaded-placeholder>

<!--DYNAMIC COMPONENTS-->
<ng-container
  appDynamicTemplate
  [ref]="entry.value['viewContainerRef']"
  *ngFor="let entry of dynamicComponentsOthers | keyvalue"></ng-container>
