import { Pipe, PipeTransform } from "@angular/core";

import { CurrencyBalance, CurrencyBalanceDetails } from "../../../core/interfaces/currency";
import { CurrencyService } from "../../../core/providers/currency.service";

@Pipe({
  name: "currencyGameBalance",
})
export class CurrencyGameBalancePipe implements PipeTransform {
  constructor(private currencyService: CurrencyService) {}

  transform(currencies: CurrencyBalanceDetails[], searchKey: string): CurrencyBalance {
    if (!searchKey) {
      return;
    }

    const currencyDefinition = <any>this.currencyService.getCurrencyDefinitionByKey(searchKey);
    return this.currencyService.getCurrencyBalance(currencyDefinition, currencies);
  }
}
