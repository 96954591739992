import { createReducer, on } from "@ngrx/store";

import { SOCKET_STATUS } from "../../core/consts/core/socket.constants";
import {
  socketAuthenticate,
  socketAuthenticated,
  socketAuthenticating,
  socketClearStatus,
  socketConnect,
  socketConnected,
  socketConnecting,
  socketConnectionError,
  socketDisconnect,
  socketDisconnected,
  socketDisconnecting,
  socketOtherSession,
  socketReconnectAttempt,
} from "./actions";

export interface SocketState {
  isConnecting: boolean;
  socketStatus: number;
  attempt: number;
}

const initialState: SocketState = {
  isConnecting: false,
  socketStatus: null,
  attempt: 0,
};

export const socketReducer = createReducer(
  initialState,
  on(socketClearStatus, (state, action) => {
    return {
      ...state,
      isConnecting: false,
      socketStatus: SOCKET_STATUS.CLEAR_STATUS,
    };
  }),
  on(socketConnect, (state, action) => {
    return {
      ...state,
      isConnecting: false,
      socketStatus: SOCKET_STATUS.CONNECT,
    };
  }),
  on(socketConnecting, (state, action) => {
    return {
      ...state,
      isConnecting: true,
      socketStatus: SOCKET_STATUS.CONNECTING,
    };
  }),
  on(socketConnected, (state, action) => {
    return {
      ...state,
      isConnecting: false,
      socketStatus: SOCKET_STATUS.CONNECTED,
      attempt: 0,
    };
  }),
  on(socketAuthenticate, (state, action) => {
    return {
      ...state,
      socketStatus: SOCKET_STATUS.AUTHENTICATE,
    };
  }),
  on(socketAuthenticating, (state, action) => {
    return {
      ...state,
      socketStatus: SOCKET_STATUS.AUTHENTICATING,
    };
  }),
  on(socketAuthenticated, (state, action) => {
    return {
      ...state,
      socketStatus: SOCKET_STATUS.AUTHENTICATED,
    };
  }),
  on(socketDisconnect, (state, action) => {
    return {
      ...state,
      isConnecting: false,
      socketStatus: SOCKET_STATUS.DISCONNECT,
    };
  }),
  on(socketDisconnecting, (state, action) => {
    return {
      ...state,
      isConnecting: false,
      socketStatus: SOCKET_STATUS.DISCONNECTING,
    };
  }),
  on(socketDisconnected, (state, action) => {
    return {
      ...state,
      isConnecting: false,
      socketStatus: SOCKET_STATUS.DISCONNECTED,
    };
  }),
  on(socketReconnectAttempt, (state, action) => {
    return {
      ...state,
      isConnecting: false,
      socketStatus: SOCKET_STATUS.RECONNECT_ATTEMPT,
      attempt: action.attempt,
    };
  }),
  on(socketOtherSession, (state, action) => {
    return {
      ...state,
      socketStatus: SOCKET_STATUS.OTHER_SESSION,
    };
  }),
  on(socketConnectionError, (state, action) => {
    return {
      ...state,
      isConnecting: false,
      socketStatus: SOCKET_STATUS.CONNECTION_ERROR,
    };
  })
);
