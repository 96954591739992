import { Component, OnDestroy, OnInit } from "@angular/core";

import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { AbstractBuildingAutoProductionComponent } from "../../../../abstract/core/abstract-building-auto-production.component";

@Component({
  selector: "app-m-building-daily-production",
  templateUrl: "./building-daily-production.component.html",
})
export class MBuildingDailyProductionComponent
  extends AbstractBuildingAutoProductionComponent
  implements OnInit, OnDestroy
{
  ngOnInit() {
    this.subscribeBoardTile();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
