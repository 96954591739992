import { CommonModule } from "@angular/common";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { OwPerfectScrollbarModule } from "@oskarwegner/ow-perfect-scrollbar";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { CurrencyService } from "../../../../../../../../core/providers/currency.service";
import { EventEmitterDialogsService } from "../../../../../../../../core/services/core/event-emitter-dialogs.service";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { SharedModule } from "../../../../../../../shared/shared.module";
import { UserService } from "../../../../../../../user/providers/user.service";
import { SharedUiMobileModule } from "../../../../../shared-ui/mobile/shared-ui-mobile.module";
import { EVENT_DIALOGS_NAMES_TRANSACTION_HISTORY } from "../../../../../transaction-history/consts/core/event-dialogs/event-names.const";
import { EventShop } from "../../../../api/core/interfaces/response/get-event-shops.interface";
import { EventShopProduct } from "../../../../api/core/interfaces/response/get-event-shops-products.interface";
import { ApiEventShopService } from "../../../../api/core/services/api-event-shop.service";
import { EVENT_DIALOGS_NAMES_EVENT_SHOP } from "../../../../consts/core/event-dialogs/event-names.const";
import { EVENT_SHOP_PRODUCTS_API_RESPONSE } from "../../../../mock/event-shop-products.mock";
import { of } from "rxjs";

@Component({
  selector: "event-shop-details",
  templateUrl: "./event-shop-details.html",
  styleUrl: "./event-shop-details.scss",
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, SharedModule, SharedUiMobileModule, ReactiveFormsModule, OwPerfectScrollbarModule],
})
export class EventShopDetails extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ApiEventShopService) apiEventShopService: ApiEventShopService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MAT_DIALOG_DATA) data: { eventShop: EventShop };
  @OwInject(CurrencyService) currencyService: CurrencyService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(UserService) userService: UserService;

  products: EventShopProduct[];

  ngOnInit() {
    this.getEventShopsProducts();
  }

  getEventShopsProducts() {
    this.apiEventShopService
      .getEventShopsProducts({ event_shop_id: this.data.eventShop.event_shop_id })
      // of(EVENT_SHOP_PRODUCTS_API_RESPONSE) MOCK DATA
      .subscribe(
        resp => {
          this.products = resp;
        },
        errResp => {
          this.dialogService.openAlertErrorApi({ errResp });
        }
      );
  }

  openHistoryTransaction(currency) {
    if (currency.show_currency_history) {
      this.eventEmitterDialogsService.emitter.emit({
        name: EVENT_DIALOGS_NAMES_TRANSACTION_HISTORY.HISTORY,
        config: {
          data: {
            currency,
            showBackBar: true,
          },
        },
      });
    }
  }

  openEventShopProductDetails(product: EventShopProduct) {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_EVENT_SHOP.PRODUCT_DETAILS,
      config: {
        data: {
          eventShop: this.data.eventShop,
          product: product,
        },
      },
      callback: value => {
        if (value) {
          this.ngOnInit();
        }
      },
    });
  }
}
