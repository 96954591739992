import { Injectable } from "@angular/core";

@Injectable()
export class LoadingService {
  show() {
    document.body.classList.add("is-loading");
  }

  hide() {
    document.body.classList.remove("is-loading");
  }
}
