import { Injectable } from "@angular/core";

import { ApiOptions } from "../../../../core/interfaces/api-options";
import { ApiService } from "../../../../core/providers/api.service";

@Injectable({
  providedIn: "root",
})
export class BranchService {
  constructor(private apiService: ApiService) {}

  branches({ page, limit }: { page: number; limit?: number }) {
    const options: ApiOptions = {
      params: {
        page,
        limit,
      },
      responseWithHeaders: true,
    };

    return this.apiService.get("branches", options);
  }

  branchesPlayers({ branchId }: { branchId: number }) {
    return this.apiService.get(`branches/${branchId}/players`);
  }

  precinct() {
    return this.apiService.get(`precinct`);
  }
}
