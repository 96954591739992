<div class="global-dialog ow-dialog window-a primary">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- TOP BAR LONG -->
  <div class="top-bar base" *ngIf="building">
    <!-- TITLE -->
    <div class="title">
      {{ building.name }}
    </div>

    <!-- PRODUCTION CYCLES -->
    <div class="absolute-right">
      <m-building-production-cycles
        [building]="building"
        (requirementsValid)="setCyclesRequirements($event)"></m-building-production-cycles>
    </div>
  </div>

  <div *ngIf="cyclesRequirements" class="height-100 relative loading-opacity">
    <!-- ARROW SLIDER -->
    <ng-container *ngIf="swiper">
      <i class="svg-icon angle-left up arrow-pagination" (click)="prevSlide()" *ngIf="!swiper?.isBeginning"></i>
      <i class="svg-icon angle-right down arrow-pagination" (click)="nextSlide()" *ngIf="!swiper?.isEnd"></i>
    </ng-container>

    <!-- SLIDER -->
    <swiper-container
      *ngIf="initSwiper"
      #swiperRef
      swiperElement
      [config]="swiperConfig"
      init="false"
      class="slider-container">
      <swiper-slide class="page" *ngFor="let page of productions.pages">
        <div class="each-production" *ngFor="let production of page">
          <!-- BUILDING ROW PRODUCTION -->
          <m-building-row-production
            [production]="production"
            (requirementsStatus)="setRequirementsStatus($event, production)"></m-building-row-production>

          <!-- START PRODUCTION -->
          <ng-container *ngIf="production.requirementsStatus">
            <ng-container
              *ngIf="
                !(actualProduction && actualProduction.production_recipe_id == production.production_recipe_id);
                else cancelProductionTpl
              ">
              <!-- NORMAL PRODUCTION -->
              <button
                class="short primary icon-left-side"
                [matTooltip]="'building-production.tooltip-start' | myTranslate"
                (click)="startProduction({ recipeId: production.production_recipe_id })"
                [disabled]="!production.requirementsStatus.valid">
                <i class="svg-icon play"></i>
                {{ production.time_in_days * 86400 || production.time_in_minutes * 60 | owDuration: 2 }}
              </button>
            </ng-container>
            <!-- CANCEL PRODUCTION -->
            <ng-template #cancelProductionTpl>
              <button
                class="short tertiary icon-left-side"
                [matTooltip]="'building-production.tooltip-cancel' | myTranslate"
                (click)="cancelPlayerProductionConfirm()">
                {{ "building-production.button-cancel" | myTranslate }}
              </button>
            </ng-template>

            <!-- FAST ACTION -->
            <button
              *ngIf="production.fast_production_currency_prices.length > 0"
              class="square small primary"
              (click)="openFastProductionConfirm(production)"
              [disabled]="!production.requirementsStatus.valid">
              <i class="svg-icon bolt"></i>
            </button>
          </ng-container>
        </div>
      </swiper-slide>
    </swiper-container>
  </div>

  <!-- BOTTOM BAR -->
  <div class="bottom-bar" *ngIf="actualProduction">
    <div class="bar-button base">
      <!-- ACTUAL PRODUCTION -->
      <!-- PRODUCT -->
      <m-ui-product
        *ngIf="actualProduction.product_prizes.length"
        [item]="actualProduction.product_prizes[0]"
        [stockView]="STOCK_VIEW.A"
        [lack]="false"
        [hideValue]="true"
        class="actual-production-item"></m-ui-product>

      <!-- CURRENCY -->
      <m-ui-currency
        *ngIf="actualProduction.currency_prizes.length"
        [item]="actualProduction.currency_prizes[0]"
        [stockView]="STOCK_VIEW.A"
        [lack]="false"
        [hideValue]="true"
        class="actual-production-item"></m-ui-currency>

      <div class="progress-container">
        <!-- TIMER -->
        <span class="timer">
          {{ diffTime.actualSeconds | owDuration }}
        </span>
        <!-- FILL PROGRESS -->
        <div class="fill one" [style.width]="diffTime.percentage + '%'"></div>
      </div>
    </div>
  </div>
</div>
