import { Component, HostBinding } from "@angular/core";
import { of, timer } from "rxjs";
import { debounce } from "rxjs/operators";

import { GameService } from "../../../game/services/game.service";
import { GuiService } from "../../../game/services/gui.service";
import {DeviceService} from '../../../../core/providers/device.service';

@Component({
  selector: "app-splash-screen",
  templateUrl: "./splash-screen.component.html",
})
export class SplashScreenComponent {
  @HostBinding("class.active") isShow: boolean;

  constructor(
    public guiService: GuiService,
    public gameService: GameService,
    private deviceService: DeviceService // eager service initialization
  ) {
    this.guiService.isSplashShow
      .pipe(
        debounce(value => {
          return value ? of(true) : timer(10);
        })
      )
      .subscribe(value => {
        this.isShow = value;
      });
  }
}
