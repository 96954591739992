import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { filter } from "rxjs/operators";

import { AppState } from "../../../../../../store/state";
import { MockDialogListService } from "../../../../../mock-dialogs/services/mock-dialog-list.service";
import { EVENT_DIALOGS_NAMES_BUILDINGS } from "../../consts/core/event-dialogs/event-names.const";
import { MOCK_BUILDING_AUTO_PROD } from "../consts/mock-building-auto-production.const";
import { MOCK_BUILDING_BUILD } from "../consts/mock-building-build.const";
import { MOCK_BUILDING_DEMOLISH, MOCK_BUILDING_DEMOLISH_KEY } from "../consts/mock-building-demolish.const";
import {
  MOCK_BUILDING_DETAILS,
  MOCK_BUILDING_DETAILS_KEY,
  MOCK_BUILDING_PRODUCTION_KEY,
  MOCK_BUILDING_UNLOCKED_KEY,
} from "../consts/mock-building-details.const";
import { MOCK_BUILDING_INFO } from "../consts/mock-building-info.const";
import { MOCK_BUILDING_LIST } from "../consts/mock-building-list.const";
import { MOCK_BUILDING_MOVE } from "../consts/mock-building-move.const";
import {
  MOCK_BUILDING_PRODUCTION,
  MOCK_BUILDING_PRODUCTION_CIRCLE_KEY,
} from "../consts/mock-building-production.const";
import { MOCK_BUILDING_SPECIAL } from "../consts/mock-building-special.const";
import { MOCK_BUILDING_TILE_BUY } from "../consts/mock-building-tile-buy.const";

@Injectable({ providedIn: "root" })
export class MockBuildingsService {
  constructor(
    private mockDialogService: MockDialogListService,
    private store: Store<AppState>
  ) {
    this.mockDialogService.keySubscription.subscribe(key => {
      switch (key) {
        case EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_UPGRADE: {
          this.mockDialogService.isMockDialogRefDisabled = true;
          this.mockDialogService.setDialogRef(MOCK_BUILDING_DETAILS.ref);
          // MOCK TODO
          // const buildingId = this.getBuilding(MOCK_BUILDING_DETAILS.ref.playerTileId).building_id;
          const upgradeBuildingId = this.getBuilding(MOCK_BUILDING_DETAILS.ref.playerTileId).upgrade_building_id;
          // this.mockDialogService.setMockApiData(`${MOCK_BUILDING_DETAILS_KEY}_${buildingId}`, MOCK_BUILDING_DETAILS.api.fist); MOCK TODO
          this.mockDialogService.setMockApiData(
            `${MOCK_BUILDING_DETAILS_KEY}_${upgradeBuildingId}`,
            MOCK_BUILDING_DETAILS.api.sec
          );
          this.mockDialogService.setMockApiData(
            `${MOCK_BUILDING_UNLOCKED_KEY}_${upgradeBuildingId}`,
            MOCK_BUILDING_DETAILS.api.sec
          );
          break;
        }
        case EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_INFO: {
          this.mockDialogService.isMockDialogRefDisabled = true;
          this.mockDialogService.setDialogRef(MOCK_BUILDING_INFO.ref);
          let id;
          // TODO mock
          // this.store
          //   .pipe(
          //     select(selectGameBoardTile, { playerTileId: MOCK_BUILDING_INFO.ref.playerTileId }),
          //     filter(state => !!state)
          //   )
          //   .subscribe(res => {
          //     // TODO mock
          //     // (id = res.player_building.building_id)
          //   });
          this.mockDialogService.setMockApiData(`${MOCK_BUILDING_DETAILS_KEY}_${id}`, MOCK_BUILDING_INFO.api);
          break;
        }
        case EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_LIST: {
          this.mockDialogService.setDialogRef(MOCK_BUILDING_LIST.ref);
          this.mockDialogService.setMockApiData(EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_LIST, MOCK_BUILDING_LIST.api);
          break;
        }
        case EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_BUILD: {
          this.mockDialogService.isMockDialogRefDisabled = true;
          this.mockDialogService.setDialogRef(MOCK_BUILDING_BUILD.ref);
          this.mockDialogService.setMockApiData(
            `${MOCK_BUILDING_DETAILS_KEY}_${MOCK_BUILDING_BUILD.ref.buildingId}`,
            MOCK_BUILDING_BUILD.api.details
          );
          this.mockDialogService.setMockApiData(
            `${MOCK_BUILDING_UNLOCKED_KEY}_${MOCK_BUILDING_BUILD.ref.buildingId}`,
            MOCK_BUILDING_BUILD.api.unlockedBuildings
          );
          this.mockDialogService.setMockApiData(
            `${MOCK_BUILDING_PRODUCTION_KEY}_${MOCK_BUILDING_BUILD.ref.buildingId}`,
            MOCK_BUILDING_BUILD.api.production
          );
          break;
        }
        case EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_AUTO_PRODUCTION:
          this.mockDialogService.isMockDialogRefDisabled = true;
          this.mockDialogService.setDialogRef(MOCK_BUILDING_AUTO_PROD.ref);
          // TODO mock
          // this.store
          //   .pipe(
          //     select(selectGameBoardTile, { playerTileId: MOCK_BUILDING_AUTO_PROD.ref.playerTileId }),
          //     filter(state => !!state)
          //   )
          //   .subscribe(tile => {
          //     // todo mock
          //     // this.mockDialogService.setMockApiData(
          //     //   `${MOCK_BUILDING_DETAILS_KEY}_${tile.player_building.building_id}`,
          //     //   MOCK_BUILDING_AUTO_PROD.api
          //     // );
          //   });
          break;
        case EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_PRODUCTION: {
          this.mockDialogService.isMockDialogRefDisabled = true;
          this.mockDialogService.setDialogRef(MOCK_BUILDING_PRODUCTION.ref);
          // TODO mock
          // this.store
          //   .pipe(
          //     select(selectGameBoardTile, { playerTileId: MOCK_BUILDING_PRODUCTION.ref.playerTileId }),
          //     filter(state => !!state)
          //   )
          //   .subscribe(tile => {
          //     // todo mock
          //     // this.mockDialogService.setMockApiData(
          //     //   `${MOCK_BUILDING_PRODUCTION_KEY}_${tile.player_building.building_id}`,
          //     //   MOCK_BUILDING_PRODUCTION.api.production
          //     // );
          //     // this.mockDialogService.setMockApiData(
          //     //   `${MOCK_BUILDING_PRODUCTION_CIRCLE_KEY}_${tile.player_building.player_building_id}`,
          //     //   MOCK_BUILDING_PRODUCTION.api.productionCircle
          //     // );
          //   });
          break;
        }
        case EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_SPECIAL_UPGRADE: {
          this.mockDialogService.isMockDialogRefDisabled = true;
          this.mockDialogService.setDialogRef(MOCK_BUILDING_SPECIAL.ref);
          // todo mock
          // this.store
          //   .pipe(
          //     select(selectGameBoardTile, { playerTileId: MOCK_BUILDING_SPECIAL.ref.playerTileId }),
          //     filter(state => !!state)
          //   )
          //   .subscribe(tile => {
          //     // TODO mock
          //     // this.mockDialogService.setMockApiData(
          //     //   `${MOCK_BUILDING_DETAILS_KEY}_${tile.player_building.upgrade_building_id}`,
          //     //   MOCK_BUILDING_SPECIAL.api.details
          //     // );
          //     // this.mockDialogService.setMockApiData(
          //     //   `${MOCK_BUILDING_PRODUCTION_KEY}_${tile.player_building.upgrade_building_id}`,
          //     //   MOCK_BUILDING_SPECIAL.api.production
          //     // );
          //     // this.mockDialogService.setMockApiData(
          //     //   `${MOCK_BUILDING_PRODUCTION_KEY}_${tile.player_building.building_id}`,
          //     //   MOCK_BUILDING_SPECIAL.api.production
          //     // );
          //   });
          break;
        }
        case EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_MOVE: {
          this.mockDialogService.setDialogRef(MOCK_BUILDING_MOVE.ref);
          break;
        }
        case EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_DEMOLISH: {
          this.mockDialogService.setDialogRef(MOCK_BUILDING_DEMOLISH.ref);
          // TODO mock
          // this.store
          //   .pipe(
          //     select(selectGameBoardTile, { playerTileId: MOCK_BUILDING_DEMOLISH.ref.playerTileId }),
          //     filter(state => !!state)
          //   )
          //   .subscribe(tile => {
          //     // TODO mock
          //     // this.mockDialogService.setMockApiData(
          //     //   `${MOCK_BUILDING_DEMOLISH_KEY}_${tile.player_building.player_building_id}`,
          //     //   MOCK_BUILDING_DEMOLISH.api
          //     // );
          //   });
          break;
        }
        case EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_TILE_BUY: {
          this.mockDialogService.setDialogRef(MOCK_BUILDING_TILE_BUY.ref);
          break;
        }
      }
    });
  }

  private getBuilding(tileID) {
    let playerBuilding;
    // MOCK TODO
    // this.store
    //   .pipe(
    //     select(selectGameBoardTile, { playerTileId: tileID }),
    //     filter(state => !!state)
    //   )
    //   .subscribe(tile => {
    //     playerBuilding = tile.player_building;
    //   });
    return playerBuilding;
  }
}
