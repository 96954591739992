import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { STOCK_VIEW } from "../../../../../../shared-ui/mobile/consts/stock-view.const";
import { LEVEL_FILTER_KEY } from "../../../../../consts/custom/rank-filter-key.const";

@Component({
  selector: "ranking-game-table",
  templateUrl: "./ranking-game-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RankingGameTableComponent {
  @Input() rankResults;
  @Input() rank;
  @Input() columns;
  @Input() show;
  @Input() activeFilter;

  STOCK_VIEW = STOCK_VIEW;
  LEVEL_FILTER_KEY = LEVEL_FILTER_KEY;
}
