import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";

import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { AbstractWarehouseDetailsComponent } from "../../../../abstract/core/abstract-warehouse-details.component";

@Component({
  selector: "app-core-warehouse-details",
  templateUrl: "./warehouse-details.component.html",
})
export class CoreWarehouseDetailsComponent extends AbstractWarehouseDetailsComponent implements OnInit, OnDestroy {
  @ViewChild("inputProductAmount") inputProductAmount: ElementRef;
  ngOnInit() {
    this.subscribeDefaultTradingCurrencyId();
    this.parseProduct();
    this.getProductDetails();
    this.subscribePlayer();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }

  checkInput() {
    if (this.inputProductAmount.nativeElement.value > this.product.balance) {
      this.inputProductAmount.nativeElement.value = this.product.balance;
      this.transactionSellForm.controls["amount"].setValue(this.product.balance);
    }
    if (this.transactionSellForm.controls["amount"].value < 1) {
      this.inputProductAmount.nativeElement.value = 0;
      this.transactionSellForm.controls["amount"].setValue(0);
    }
  }
}
