import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

import { ApiService } from "../../core/providers/api.service";
import { TokenExchangeService } from "../../core/providers/token-exchange.service";
import { removeToken } from "../../core/utility/token";
import { TokenObject } from "../../modules/auth/interfaces/token-object";
import { PlayerService } from "../../modules/player/providers/player.service";
import {
  utilityFetchNewTokenFinish,
  utilityFetchNewTokenStart,
  utilityRemoveToken,
  utilitySetToken,
  utilityUpdateActivePlayerId,
  utilityUpdateMePlayerId,
} from "./actions";
import { RegisterCustomFirebaseTokenService } from '../../core/services/core/register-firebase-token.service';

@Injectable()
export class UtilityEffects {
  constructor(
    private actions$: Actions,
    private playerService: PlayerService,
    private apiService: ApiService,
    private tokenExchangeService: TokenExchangeService,
    private store: Store<AppendMode>,
    private registerCustomFirebaseTokenService: RegisterCustomFirebaseTokenService
  ) {}

  $updateMePlayerId = createEffect(() =>
    this.actions$.pipe(
      ofType(utilityUpdateMePlayerId),
      tap(action => {
        this.playerService.setMePlayerId(action.playerId);
        if (action.playerId) {
          this.tokenExchangeService.exchangeTimeoutStart();
          this.registerCustomFirebaseTokenService.registerFirebase();
        }
      }),
      map(action => {
        return utilityUpdateActivePlayerId({
          playerId: action.playerId,
        });
      })
    )
  );

  $updateActivePlayerId: Observable<void | Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(utilityUpdateActivePlayerId),
        tap(action => {
          this.playerService.setActivePlayerId(action.playerId);
        })
      ),
    { dispatch: false }
  );

  setToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(utilitySetToken),
        tap(action => {
          this.apiService.setToken(action.token);
          this.tokenExchangeService.exchangeTimeoutStart();
        })
      ),
    { dispatch: false }
  );

  removeToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(utilityRemoveToken),
        tap(() => {
          removeToken();
        })
      ),
    { dispatch: false }
  );

  fetchNewTokenStart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(utilityFetchNewTokenStart),
        tap(() => {
          this.tokenExchangeService.exchangeToken().subscribe((tokenObject: TokenObject) => {
            this.store.dispatch(utilityFetchNewTokenFinish());
            this.store.dispatch(utilitySetToken({ token: tokenObject }));
          });
        })
      ),
    { dispatch: false }
  );
}
