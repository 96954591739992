import { CUSTOM_CURRENCIES } from "../custom/currencies.const";

export const CURRENCIES = {
  ONE: null,
  TWO: null,
  THREE: null,
  FOUR: null,
  FIVE: null,
  SIX: null,
  SEVEN: null,
  EIGHT: null,
  NINE: null,
  TEN: null,
  ...CUSTOM_CURRENCIES,
};
