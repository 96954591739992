import { Component } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { CardTask } from "../../../../interfaces/custom/card-task.interface";

@Component({
  selector: "app-card-task-vendors",
  templateUrl: "./card-task-vendors.component.html",
})
export class CardTaskVendorsComponent extends AbstractInjectBaseComponent {
  @OwInject(MAT_DIALOG_DATA) data: {
    cardTasksVendors: CardTask[];
  };
}
