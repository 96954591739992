import { Component, OnInit } from "@angular/core";

import { AbstractMessageDetailsComicsComponent } from "../../../../abstract/core/abstract-message-details-comics.component";

@Component({
  selector: "app-message-details-comics",
  templateUrl: "./message-details-comics.component.html",
})
export class MessageDetailsComicsComponent extends AbstractMessageDetailsComicsComponent implements OnInit {
  ngOnInit() {
    this.afterInit();
  }
}
