import { EVENT_DIALOGS_NAMES_QA } from "../../consts/core/event-dialogs/event-names.const";

export const MOCK_QA_DIALOGS = [
  {
    key: EVENT_DIALOGS_NAMES_QA.QA_LIST,
    name: "QA List",
    available: true,
  },
  {
    key: EVENT_DIALOGS_NAMES_QA.QA_DETAILS,
    name: "QA Details",
    available: true,
  },
  {
    key: EVENT_DIALOGS_NAMES_QA.QA_FINISH,
    name: "QA Finish",
    available: true,
  },
];
