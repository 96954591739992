import { Component } from "@angular/core";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { AbstractCardTaskComponent } from "../../../../abstract/custom/abstract-card-task.component";
import { CardTaskDetailsComponent } from "../../dialogs/card-task-details/card-task-details.component";
import { ChooseTaskComponent } from "../../dialogs/choose-task/choose-task.component";

@Component({
  selector: "card-task-vendor",
  templateUrl: "./card-task.component.html",
})
export class CardTaskVendorComponent extends AbstractCardTaskComponent {
  @OwInject(DialogService) dialogService: DialogService;
  cardTaskDetailsComponent = CardTaskDetailsComponent;
  chooseTaskComponent = ChooseTaskComponent;
}

