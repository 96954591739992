export const CUSTOM_TILE_MAP_WIDTH = null;
export const CUSTOM_TILE_MAP_HEIGHT = null;
export const CUSTOM_TILE_WIDTH = null;
export const CUSTOM_TILE_HEIGHT = null;
export const CUSTOM_TILE_TOOLTIP_OFFSET = {};
export const CUSTOM_TILE_HOVER_TYPES = {};
export const CUSTOM_TILE_HOVER_TYPE_ANCHORS = {};
export const CUSTOM_TILE_TYPE_POLYGON = {};
export const CUSTOM_TILE_MENU_POSITION_OFFSETS = {};
export const CUSTOM_BUILDINGS_LEVEL_COLOR = {};
