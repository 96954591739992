import { Component, OnDestroy, OnInit } from "@angular/core";

import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { AbstractBuildingInfoComponent } from "../../../../abstract/core/abstract-building-info.component";

@Component({
  selector: "app-m-building",
  templateUrl: "./m-building-info.component.html",
})
export class MBuildingInfoComponent extends AbstractBuildingInfoComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.subscribeBoardTile();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
