import { Pipe, PipeTransform } from "@angular/core";

import { CurrencyService } from "../../../core/providers/currency.service";
import { ProductPlayerService } from "../../player/providers/product-player.service";

@Pipe({
  name: "resourceFromString",
})
export class ResourceFromStringPipe implements PipeTransform {
  // 'small' | 'basic' | 'big'
  iconSize: string;
  tooltip: string;
  text: string;
  regexCurrency = /\[currency-icon id=([\d]+)\]/;
  regexProduct = /\[product-icon id=([\d]+)\]/;

  constructor(
    private currencyService: CurrencyService,
    private productPlayerService: ProductPlayerService
  ) {}

  transform(text: string, iconSize?: string): string {
    this.text = text;
    this.iconSize = iconSize || "basic";
    this.checkAndReplace();
    return this.text;
  }

  checkAndReplace() {
    if (this.text) {
      this.loopReplace({
        regex: this.regexCurrency,
        type: "currency",
      });

      this.loopReplace({
        regex: this.regexProduct,
        type: "product",
      });
    }
  }

  loopReplace({ regex, type }) {
    do {
      const match = this.text.match(regex);
      if (match === null) {
        break;
      }
      const stringToReplace = match[0];
      const id = parseInt(match[1], 10);
      this.text = this.replaceTextToImg({ stringToReplace, id, type });
    } while (1);
  }

  replaceTextToImg({
    stringToReplace,
    id,
    type,
  }: {
    stringToReplace: string;
    id: number;
    type: "currency" | "product";
  }) {
    let src;
    switch (type) {
      case "currency":
        src = this.getCurrencyIcon(id);
        break;

      case "product":
        src = this.getProductIcon(id);
        break;
    }

    const htmlImg = `<span class="img-box"><img class="resource-from-string-icon ${this.iconSize}" src="${src}"><span class="mdc-tooltip resource-from-string">${this.tooltip}</span></span>`;
    return this.text.replace(stringToReplace, htmlImg);
  }

  getCurrencyIcon(id: number) {
    let iconUrl = `currency_${id}_not_exist.png`;

    const resource = this.currencyService.getCurrencyDefinition({ currency_id: id });
    this.tooltip = resource.name;
    if (resource) {
      iconUrl = this.getIconSize(resource);
    }

    return iconUrl;
  }

  getProductIcon(id: number) {
    let iconUrl = `product_${id}_not_exist.png`;

    const resource = this.productPlayerService.getProduct({ product_id: id });
    this.tooltip = resource.name;

    if (resource) {
      iconUrl = this.getIconSize(resource);
    }

    return iconUrl;
  }

  getIconSize(resource) {
    switch (this.iconSize) {
      case "small":
      case "basic":
        return resource.iconUrl;
      case "big":
        return resource.iconUrlBig;
    }
  }
}
