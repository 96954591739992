import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";

import { environment } from "../environments/environment";
import { BuildVersionService } from "./core/providers/build-version.service";
import { NativeAppService } from "./core/providers/native-app.service";
import { RegisterEventDialogsService } from "./core/services/core/register-event-dialogs.service";
import { RoutingService } from "./core/services/core/routing.service";
import { ReauthorizeService } from "./modules/auth/services/core/reauthorize.service";
import { configFetchStart } from "./store/config/actions";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  constructor(
    private buildVersionService: BuildVersionService,
    private registerEventDialogsService: RegisterEventDialogsService,
    private routingService: RoutingService,
    private reauthorizeService: ReauthorizeService,
    private nativeAppService: NativeAppService,
    private store: Store
  ) {}

  ngOnInit() {
    this.store.dispatch(configFetchStart());
    console.log(environment);
  }
}
