import { createFeatureSelector, createSelector } from "@ngrx/store";

import { ConfigState } from "./reducer";

export const selectConfigState = createFeatureSelector<ConfigState>("config");
export const selectConfigIsLoading = createSelector(selectConfigState, state => state.isLoading);
export const selectConfigIsLoaded = createSelector(selectConfigState, state => state.loaded);
export const selectConfigOfApplication = createSelector(selectConfigState, state => state.applicationConfig);

export const selectConfigHudPosition = createSelector(selectConfigOfApplication, config => config.hud);

export const selectConfigPlayerDetailsLinks = createSelector(selectConfigHudPosition, hud =>
  Object.values(hud.player_details.links)
);
