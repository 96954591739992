import { Injectable } from "@angular/core";
import { mergeMap, Observable, of } from "rxjs";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { configFetchFailure, configFetchStart, configFetchSuccess } from "./actions";
import { ApplicationConfigService } from "../../services/application-config/application-config.service";
import { ApplicationConfig } from "./interfaces/config.state";
import { catchError, map, tap } from "rxjs/operators";
import { omitErrorResponseHelper } from "../../core/helpers/omit-error-response.helper";

@Injectable()
export class ConfigEffects {
  constructor(
    private actions$: Actions,
    private applicationConfig: ApplicationConfigService
  ) {}

  $fetchConfig: Observable<any | Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(configFetchStart),
      mergeMap(action => {
        return this.getConfig();
      })
    )
  );

  getConfig() {
    return this.applicationConfig.getApplicationConfig().pipe(
      map((config: ApplicationConfig) => {
        return configFetchSuccess({ applicationConfig: config });
      }),
      catchError(error => {
        return of(configFetchFailure({ error: omitErrorResponseHelper(error) }));
      })
    );
  }
}
