import { createFeatureSelector, createSelector } from "@ngrx/store";

import { PrimaryState } from "./reducer";

export const selectPrimaryState = createFeatureSelector<PrimaryState>("primary");
export const selectPrimaryIsLoading = createSelector(selectPrimaryState, state => state.isLoading);
export const selectPrimaryIsLoaded = createSelector(selectPrimaryState, state => state.isLoaded);

export const selectPrimary = createSelector(selectPrimaryState, state => state.primary);

export const selectPrimaryCurrencies = createSelector(selectPrimary, state => state.currencies);

export const selectPrimaryProducts = createSelector(selectPrimary, state => state.products);

export const selectPrimaryParameters = createSelector(selectPrimary, state => state.parameters);

export const selectPrimaryDefaultTracingCurrencyId = createSelector(selectPrimaryCurrencies, state => {
  const tradingCurrencyDefinition = state.find(x => x.is_default_trading_currency);

  return tradingCurrencyDefinition ? tradingCurrencyDefinition.currency_id : null;
});
