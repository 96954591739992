import { Component, OnInit } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ApiBusinessService } from '../../../../api/custom/services/api-business.service';
import { DialogService } from '../../../../../../../shared/providers/dialog.service';
import { PlayerService } from '../../../../../../../player/providers/player.service';
import { Task } from '../../../../interfaces/custom/task.interface';

@Component({
  selector: 'app-contracts-details',
  templateUrl: './contracts-details.component.html',
})
export class ContractsDetailsComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(MAT_DIALOG_DATA) data: {
    task: Task,
    title_id: number,
  };
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<ContractsDetailsComponent>;
  @OwInject(ApiBusinessService) apiBusinessService: ApiBusinessService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(PlayerService) playerService: PlayerService;

  playerTaskId;
  activeFilter: number;
  contracts;
  math = Math;
  filters = [
    {id: 0, label: 'Umowy sprzedawców'},
    {id: 1, label: 'Twoje umowy'},
  ];

  ngOnInit() {
    if (this.data) {
      this.playerTaskId = this.data.task.player_task_id;
      this.getContracts();
    }
  }

  getContracts() {
    this.apiBusinessService.getPlayerTask(this.playerTaskId)
      .subscribe((resp) => {
        this.contracts = resp;
        if (this.data.title_id === 2) {
          this.activeFilter = 0;
        }
      });
  }

  changeFilter(filter) {
    this.activeFilter = filter.id;
  }

  close() {
    this.matDialogRef.close(true);
  }
}
