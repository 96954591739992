export const EVENTS = {
  GUI: {
    RELOAD_CARDS: "reload_cards",
    HIDE_CARDS: {
      NORMAL: "hide_cards",
      FORCE: "hide_cards_force",
    },
    SHOW_CARDS: {
      NORMAL: "show_cards",
      FORCE: "show_cards_force",
    },
    RELOAD_COMPONENT: "reload_component",
    REQUIREMENTS: {
      COSTS: "costs",
      PLAYER_LEVEL: "player_level",
      BUILDING: "building",
      GROUP_LIMIT: "group_limit",
      POPULATION: "population",
    },
    HUD_PARAMETERS: {
      SHOW: "hud_parameters_show",
      HIDE: "hud_parameters_hide",
    },
  },
  GAME: {
    TILE_CLICK: "tile_click",
    BUILDING_MENU_CLICK: "building_menu_click",
    BUILDING_MENU_CLICK_ACTION: {
      INFO: "info",
      PRODUCTION_START: "production_start",
      PRODUCTION_CHANGE: "production_change",
      UPGRADE: "upgrade",
      DELETE: "delete",
      MOVE: "move",
      SPECIAL_UPGRADE: "special_upgrade",
      OPEN_FUNCTIONAL: "OPEN_FUNCTIONAL",
      OPEN_BHP: "OPEN_BHP",
    },
    BUILDING_MOVE_START: "building_move_start",
    BUILDING_MOVE_SUCCESS: "building_move_success",
    BUILDING_MOVE_FAIL: "building_move_fail",
    PRODUCTION_COLLECT: "production_collect",
    CHANGE_TO_OLD_MAP_GAME: {
      SANCITY: "change_to_old_map_game_sancity",
      SANVILLE: "change_to_old_map_game_sanville",
    },
    ENABLE_DEBUG: "ENABLE_DEBUG",
    DISABLE_DEBUG: "DISABLE_DEBUG",
    REGION_BUY: "BUY_REGION_",
    REGION_UPDATE: "region_update",
    ISLAND_CLICK: "ISLAND_CLICK",
    ISLAND_CLICK_DISCOVERED: "ISLAND_CLICK_DISCOVERED",
    GREEK: "greek",
    NEWS: "news",
    MAP_OBJECT_EVENT: "map-object-event",
    CENTER_TILE: "center-tile",
    OPEN_BUILDINGS_LEVEL_LAYER: "open_buildings_level_layer",
    CLOSE_BUILDINGS_LEVEL_LAYER: "close_buildings_level_layer",
    TOGGLE_BUILDINGS_LEVEL_LAYER: "toggle_buildings_level_layer",
  },
  GLOBAL: {
    UNAUTHORIZED: "unauthorized",
    MUST_CHANGE_PASSWORD: "must-change-password",
    CHANGE_VIEW_STARED: "change_view_started",
    CHANGE_VIEW_INIT: "change_view_init",
    CHANGE_VIEW_FINISHED: "change_view_finished",
    GAME_STARTED: "game_started",
    GAME_LOAD_STARTED: "game_load_started",
    FARM_NAME_CHANGED: "farm-name-changed",
    UPDATE_PLAYER: "player",
    PLAYER: "player",
    SET_PLAYER: "player",
    RELOAD_PLAYER: "reload_player",
    CHANGE_ACTIVE_PLAYER: "change_active_player",
    NEW_IMPORTANT_MESSAGE: "new_important_message",
    MISSION_COMPLETED: "mission_completed",
    PRODUCTION_STARTED: "production_started",
    UNREAD_COUNT: "unread_count",
    READ_BADGE: "read_badge",
    TILE_CHANGED: "tile_changed",
    SHIP_UPDATE: "ship_update",
    SHIP_UPDATED_DONE: "ship_updated_done",
    DIFFERENT_VERSION_GAME: "different_version_game",
    RELOAD_GAME: "reload_game",
    OTHER_SESSION: "other_session",
    DISCONNECT: "disconnect",
    DISCONNECTED: "disconnected",
    CONNECT: "connect",
    CONNECT_ERROR: "connect_error",
    RECONNECT: "reconnect",
    RECONNECT_ATTEMPT: "reconnect_attempt",
    RECONNECTED: "reconnect",
    SOCKET_CONNECTION_ERROR: "socket_connection_error",
    AUTHENTICATE: "authenticate",
    AUTHENTICATING: "authenticating",
    PLAYER_MISSION_SHOW_DETAILS: "player_mission_show_details",
    NEW_PRODUCTS_AT_LEVEL: "new_products_at_level",
    TIMESYNC: "timesync",
    PONG: "pong",
    SOCKET_CLEAR_STATUS: "socket_clear_status",
    PLAYER_MISSION_CONDITIONS_CHANGED: "player_mission_conditions_changed",
  },
};
