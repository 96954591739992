<ng-container *ngIf="playerDetails">
  <div class="hud-player-container-with-photo"
       [matTooltip]="
        'hud-player.level-tooltip' | myTranslate: [playerDetails.points_balance | NumberWithSpaces, playerDetails.next_level_points | NumberWithSpaces]
       "
       matTooltipPosition="below"
       matTooltipShowDelay="300"
       [ngClass]="{'block-details-open': blockDetailsOpen }"
       (click)="playerService.isActiveMe && openPlayerDetails()">
    <div class="avatar-container default"
         [style.background-image]="playerDetails.profile_image_url ? 'url(' + playerDetails.profile_image_url + ')' : null">
      <div class="user-image-uploader">
        <i class="svg-icon image"></i>
        <input accept="image/x-png, image/gif, image/jpeg" type="file" (change)="changeImage($event)"/>
      </div>
    </div>

    <div class="level-container">
      <p>{{ playerDetails.level }}</p>
    </div>

    <svg id="circle" viewBox="0 0 200 200">
      <circle
        cx="100"F
        cy="100"
        fill="transparent"
        id="bar"
        r="84"
        stroke-width="34"
        stroke-dasharray="580"
        [style.strokeDashoffset]="strokeDashOffset"></circle>
    </svg>
  </div>
</ng-container>
