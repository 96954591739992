export const MOCK_MESSAGES_FILTER = "mock_message_filter";

export const MOCK_MESSAGES = {
  api: {
    messages: {
      headers: [
        {
          key: "x-pages-count",
          value: 10,
        },
        {
          key: "x-total-items",
          value: 10,
        },
      ],
      status: 200,
      statusText: "OK",
      url: "https://komputronik.o-w.dev/api/messages?page=1&limit=5&type=1",
      ok: true,
      type: 4,
      body: [
        {
          read: true,
          title: "Bonus za logowanie!",
          tip: "",
          teaser: "",
          player_message_id: 159380,
          important: true,
          type: 1,
          category_name: "Świat gry",
          sender_group_id: 15,
          sender_name: "Dodatkowe cegły",
          sender_name_legacy: "Dodatkowe cegły",
          sender_icon: "bonus_bricks",
          created_at: "2021-05-12T09:15:24+02:00",
          description:
            "Co za determinacja! To Twoje 2 logowanie w tym tygodniu &ndash; otrzymujesz za nie 700 cegieł &ndash; mieszkańcy Komp Town już widzą oczami wyobraźni jak rosną kolejne budynki w mieście!",
        },
        {
          read: true,
          title: "Wyniki ankiety: Najlepsze praktyki",
          tip: "",
          teaser: "",
          player_message_id: 159373,
          important: true,
          type: 1,
          category_name: "Biznes",
          sender_group_id: 4,
          sender_name: "Info",
          sender_name_legacy: "Info",
          sender_icon: "info",
          created_at: "2021-05-11T08:45:58+02:00",
          description:
            'Cześć!<br><br>Wracamy do Was z podsumowaniem ankiety &bdquo;<b>Raty 0% vs Kredyt marżowy &ndash; najlepsze praktyki</b>!&rdquo;<br>Po raz kolejny wykazaliście się dużym zaangażowaniem, a zbi&oacute;r wypełnionych ankiet to ponad 120 sztuk.<br>Mega!!! Jeszcze raz wielkie dzięki wszystkim, kt&oacute;rzy podeszli do tematu.<br><br>W niniejszym argumenterze prezentujemy <b>10 najlepszych &bdquo;szach-mat&oacute;w&rdquo;</b>, kt&oacute;re powinny być pomocne w&oacute;wczas, <br>kiedy Klient na wczesnym etapie rozmowy dopytuje o raty &bdquo;0&rdquo;.<br><br>Tym samym zamknęliśmy miesiąc kwiecień, w kt&oacute;rym akcją wiodącą był właśnie &bdquo;KREDYT&rdquo;, a konkretnie <br>wsparcie sprzedaży jego marżowych odsłon. Wypatrujcie nowych misji.<br><br>A tymczasem&hellip;Sprawdźcie, testujecie, wcielajcie w życie.<br><br>Owocnej sprzedaży!<br><br>[pdf src="https://komptown.pl/api/files/download/2d417932-c876-4561-80f1-7f354fde80f2/raty%20ZERO%20-%20szach-maty.pdf"]',
        },
        {
          read: true,
          title: "Wypełnij ankietę: Najlepsze praktyki",
          tip: "",
          teaser: "",
          player_message_id: 159372,
          important: true,
          type: 1,
          category_name: "Biznes",
          sender_group_id: 4,
          sender_name: "Info",
          sender_name_legacy: "Info",
          sender_icon: "info",
          created_at: "2021-05-11T08:45:58+02:00",
          description:
            "Cześć! Miesiąc z akcją specjalną, kt&oacute;rej celem był wzrost sprzedaży kredyt&oacute;w marżowych (&gt;0%) powoli dobiega końca. Przed Wami ostatnia misja, czyli ankieta. Podziel się z nami swoimi najlepszymi zwrotami, wypełniając ankietę już teraz.",
        },
        {
          read: true,
          title: "Zmiany w zasadach naliczeń",
          tip: "",
          teaser: "",
          player_message_id: 159371,
          important: true,
          type: 1,
          category_name: "Biznes",
          sender_group_id: 4,
          sender_name: "Info",
          sender_name_legacy: "Info",
          sender_icon: "info",
          created_at: "2021-05-11T08:45:58+02:00",
          description:
            'Cześć! 😊<br>Ważna informacja!☝️<br><br>Od 1.04.2021 zmienił się system naliczania Waszych nagr&oacute;d za sprzedaż usług finansowych.<br><br><b>Zadania w Komp Town, objęte zmianą to</b>: <br><ul class="decoda-list"><li>kredyt (wraz z leasingiem; bez kredytu 0%)</li><li>polisy</li></ul> <br><b>Co dokładnie się zmieni?</b> <br><ol class="decoda-olist"><li>Plan sprzedażowy sklepu wykonany na poziomie <b>do 100%</b> - przeliczacie swoje wypracowane <b>punkty x 1</b></li><li>Plan sprzedażowy sklepu wykonany na poziomie <b>100% lub wyżej</b> - DOSTAJECIE BONUS i przeliczacie swoje wypracowane <b>punkty x 1,2</b></li></ol><br>Zatem życzymy owocnej sprzedaży i jak najwięcej uzyskanych nagr&oacute;d! 😉',
        },
        {
          read: true,
          title: "Nowa akcja specjalna",
          tip: "",
          teaser: "",
          player_message_id: 159370,
          important: true,
          type: 1,
          category_name: "Biznes",
          sender_group_id: 4,
          sender_name: "Info",
          sender_name_legacy: "Info",
          sender_icon: "info",
          created_at: "2021-05-11T08:45:58+02:00",
          description:
            "Cześć!<br><br>Trwają prace nad zmianą zadań w związku ze zmianą systemu naliczania nagr&oacute;d.<br><br>Zadania zostaną opublikowane wkr&oacute;tce.<br><br>Akcją specjalną w tym miesiącu jest KREDYT (bez 0%)!",
        },
      ],
    },
    categories: [
      { id: 2, category_name: "Biznes" },
      { id: 3, category_name: "Komiks" },
      {
        id: 5,
        category_name: "Listy z przysz\u0142o\u015bci",
      },
      { id: 1, category_name: "\u015awiat gry" },
      { id: 4, category_name: "Wiedza" },
    ],
    senderGroups: [
      {
        sender_group_id: 1,
        sender_name: "Awans",
      },
      {
        sender_group_id: 10,
        sender_name: "Burmistrz Edward Korytko",
      },
      {
        sender_group_id: 15,
        sender_name: "Dodatkowe ceg\u0142y",
      },
      {
        sender_group_id: 4,
        sender_name: "Info",
      },
      {
        sender_group_id: 6,
        sender_name: "Jan Kompetentny",
      },
      {
        sender_group_id: 3,
        sender_name: "Komiks",
      },
      {
        sender_group_id: 2,
        sender_name: "Nowo\u015b\u0107",
      },
    ],
  },
  ref: {},
};
