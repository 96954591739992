import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { ApiOptions } from "../../../../../../../core/interfaces/api-options";
import { ApiService } from "../../../../../../../core/providers/api.service";
import { SchemaValidatorService } from "../../../../../../../services/schema-validator/schema-validator.service";
import { EVENT_DIALOGS_NAMES_MISSION } from "../../../consts/core/event-dialogs/event-names";
import { MissionDetails } from "../../../interfaces/core";
import { GetMissionsRequest, PatchMissionHandOver } from "../interfaces";

@Injectable({
  providedIn: "root",
})
export class ApiMissionService {
  constructor(
    private apiService: ApiService,
    private schemaValidator: SchemaValidatorService
  ) {}

  getMissionList({ slot }: GetMissionsRequest = {}) {
    const options: ApiOptions = {
      params: {
        slot,
      },
    };

    return this.apiService.get("/player-missions", options, EVENT_DIALOGS_NAMES_MISSION.MISSIONS).pipe(
      tap(response => {
        this.schemaValidator.validateResponse({
          url: `/player-missions`,
          payload: {},
          response: response,
          schema: "playerMissionsGame",
        });
      })
    );
  }

  getMissionDetails(player_mission_id): Observable<MissionDetails> {
    return this.apiService.get(`/player-missions/${player_mission_id}`, {}, EVENT_DIALOGS_NAMES_MISSION.MISSION_DETAILS).pipe(
      tap(response => {
        this.schemaValidator.validateResponse({
          url: `/player-missions/${player_mission_id}`,
          payload: {},
          response: response,
          schema: "playerMissionDetailsGame",
        });
      })
    );
  }

  missionSeen(player_mission_id) {
    return this.apiService.patch(`/player-missions/${player_mission_id}/seen`);
  }

  missionCollect(player_mission_id) {
    return this.apiService.patch(`player-missions/${player_mission_id}/collect`);
  }

  missionReject(player_mission_id) {
    return this.apiService.patch(`player-missions/${player_mission_id}/reject`);
  }

  missionHandOver({ player_mission_condition_id, amount }: PatchMissionHandOver) {
    const options: ApiOptions = {
      body: {
        amount,
      },
    };

    return this.apiService.patch(`player-missions-conditions/${player_mission_condition_id}/hand-over`, options);
  }
}
