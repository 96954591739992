import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";

import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { AbstractWarehouseComponent } from "../../../../abstract/core/abstract-warehouse.component";

@Component({
  selector: "app-core-warehouse",
  templateUrl: "./warehouse.component.html",
})
export class CoreWarehouseComponent extends AbstractWarehouseComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.getStorages();
    this.subscribePlayer();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
