import { Routes } from "@angular/router";
import {AUTH_ROUTING_PATH} from '../core/routing-auth.const';
import {CustomRegistrationAComponent} from '../../base/custom/components/registration/variant-a/registration-a.component';
import {RegistrationConfirmComponent} from '../../base/custom/components/registration-confirm/registration-confirm.component';

export const CUSTOM_ROUTING_AUTH_CHILDREN: Routes = [
  {
    path: AUTH_ROUTING_PATH.REGISTRATION,
    component: CustomRegistrationAComponent,
  },
  {
    path: "registration/confirm/:token",
    component: RegistrationConfirmComponent,
  },
];
