import { Directive, EventEmitter, Injectable, OnDestroy, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";

import { APP_ROUTING_ABSOLUTE_PATH } from "../../../../../consts/routing-app-absolute.const";
import { AbstractInjectBaseComponent } from "../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../core/decorators/ow-inject.decorator";
import { unsubscribeObject } from "../../../../../core/utility/unsubscribe-array";
import { AppState } from "../../../../../store/state";
import { selectUser } from "../../../../../store/user/selectors";

@Directive()
@Injectable()
export abstract class AbstractChangePlayerButtonComponent
  extends AbstractInjectBaseComponent
  implements OnInit, OnDestroy
{
  @OwInject(Router) router: Router;
  @OwInject(Store) store: Store<AppState>;
  @Output() clicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  isMoreEnabledPlayers: boolean;
  subs = {
    user: null,
  };

  ngOnInit() {
    this.subscribeUser();
  }

  subscribeUser() {
    this.subs.user = this.store.pipe(select(selectUser)).subscribe(user => {
      this.isMoreEnabledPlayers = user.enabled_players.length >= 2;
    });
  }

  click() {
    this.clicked.emit(true);
    this.router.navigate([APP_ROUTING_ABSOLUTE_PATH.PLAYER_CHOOSE], {
      queryParams: {
        reload: 1,
      },
    });
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
