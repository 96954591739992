<div class="global-dialog ow-dialog window-a primary no-transparent">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button [preventDefault]="true" (clicked)="close()"></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button></m-ui-back-button>

  <ng-container *ngIf="contracts">
    <div class="container" [class.max-height]="data.title_id == 1">
      <!-- TITLE -->
      <div class="title">
        Punkty za to zadanie pochodzą z umów:
      </div>
      <ow-perfect-scrollbar scrollGradient>
        <table>
          <tr class="head">
            <td class="date-sign">Data zawarcia</td>
            <td class="doc">Dokument</td>
            <td class="status">Data statusu</td>
            <td class="points">Punkty</td>
            <td class="bonus" *ngIf="data.task.task_with_bonus">Punkty_2</td>
          </tr>

          <!-- IF MANAGER -->
          <ng-container
            *ngIf="(data.title_id === 2 && contracts['manager'].length && activeFilter === 0)"
            [ngTemplateOutlet]="trTpl"
            [ngTemplateOutletContext]="{contracts: contracts['manager']}"
          ></ng-container>

          <!-- IF TRADER -->
          <ng-container
            *ngIf="(data.title_id === 2 && contracts['player'].length && activeFilter === 1) || data.title_id === 1 && contracts['player'].length"
            [ngTemplateOutlet]="trTpl"
            [ngTemplateOutletContext]="{contracts: contracts['player']}"
          ></ng-container>
        </table>
      </ow-perfect-scrollbar>
    </div>

    <!-- CATEGORY -->
    <div class="container-filter" *ngIf="data.title_id === 2">
      <ng-container *ngFor="let filter of filters">
        <div class="each-filter" (click)="changeFilter(filter)">

          <!-- CHECKBOX -->
          <button
            class="checkbox"
            [class.active]="filter.id == activeFilter"
          ></button>

          <!-- LABEL -->
          <span>{{ filter.label }}</span>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template #trTpl let-contracts="contracts">
  <tr class="body" *ngFor="let contract of contracts">
    <td class="date-sign">{{ contract.date_signed | date: 'yyyy-MM-dd'}}</td>
    <td class="doc">{{ contract.document }}</td>
    <td class="status">{{ contract.date_status | date: 'yyyy-MM-dd' }}</td>
    <td class="points">{{ contract.points }}</td>
    <td class="bonus" *ngIf="data.task.task_with_bonus">{{ contract.bonus }}</td>
  </tr>
</ng-template>
