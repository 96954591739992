import { Component, OnInit } from "@angular/core";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { EventEmitterDialogsService } from "../../../../../../../../core/services/core/event-emitter-dialogs.service";
import { STOCK_VIEW } from "../../../../../shared-ui/mobile/consts/stock-view.const";
import { ApiWarehouseService } from "../../../../api/custom/services/api-warehouse.service";
import { EVENT_DIALOGS_NAMES_WAREHOUSE } from "../../../../consts/core/event-dialogs/event-names.const";
import { WarehouseDetailsData } from "../../../../interfaces/core/dialogs/warehouse-details-data.interface";
import { WarehouseSale } from "../../../../interfaces/custom/warehouse-sale.interface";

@Component({
  selector: "app-core-warehouse-sale",
  templateUrl: "./warehouse-sale.component.html",
})
export class CoreWarehouseSaleComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(ApiWarehouseService) apiWarehouseService: ApiWarehouseService;

  sales: WarehouseSale[];
  total = {
    currency_id: 2,
    amount: 0,
  };
  CURRENCY_SALE_ID = 2;
  STOCK_VIEW = STOCK_VIEW;
  isLoading: boolean;

  ngOnInit() {
    this.getWarehouseSale();
  }

  getWarehouseSale() {
    this.isLoading = true;

    this.apiWarehouseService.warehouseSale().subscribe(resp => {
      this.sales = resp;
      this.sumPrevIncome();
      this.setDaysReserve();
      this.isLoading = false;
      // console.log("RESPP", resp);
    });
  }

  setDaysReserve() {
    this.sales?.forEach(s => {
      s.days_reserve_format = this.warehouseSaleDaysReserveHelper(s.days_reserve);
    });
  }

  sumPrevIncome() {
    let sum = 0;
    this.sales?.forEach(s => {
      sum += s?.last_day_income;
    });
    this.total.amount = sum;
  }

  openWarehouse(productId) {
    const warehouseDetailsData: WarehouseDetailsData = {
      product_id: productId,
    };

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_WAREHOUSE.WAREHOUSE_DETAILS,
      config: {
        data: warehouseDetailsData,
      },
    });
  }

  warehouseSaleDaysReserveHelper(days) {
    return days > 1
      ? days + " dni"
      : days === 1
        ? "1 dzień"
        : days < 1 && days > 0
          ? "< niż 1 dzień"
          : days === 0
            ? "BRAK"
            : days;
  }
}
