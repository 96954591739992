import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import * as Sentry from "@sentry/angular";
import { of } from "rxjs";
import { catchError, map, mergeMap, tap } from "rxjs/operators";

import { omitErrorResponseHelper } from "../../../core/helpers/omit-error-response.helper";
import { RegisterCustomFirebaseTokenService } from "../../../core/services/core/register-firebase-token.service";
import { AuthService } from "../../../modules/auth/api/services/auth.service";
import { Credentials } from "../../../modules/auth/interfaces/credentials";
import { TokenObject } from "../../../modules/auth/interfaces/token-object";
import { AppState } from "../../state";
import { utilitySetToken } from "../../utility/actions";
import { loginFailed, loginLogout, loginStart, loginSuccess } from "./actions";

@Injectable()
export class LoginEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private store: Store<AppState>,
    private registerCustomFirebaseTokenService: RegisterCustomFirebaseTokenService
  ) {}

  $authStart = createEffect(() =>
    this.actions$.pipe(
      ofType(loginStart),
      mergeMap(action => {
        return this.login(<Credentials>action);
      })
    )
  );

  $authLogout = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginLogout),
        tap(async () => {
          await this.registerCustomFirebaseTokenService.unregisterFirebase();
          this.authService.logout();
          Sentry.setUser(null);
          location.href = this.authService.logoutUrl || "/main";
        })
      ),
    { dispatch: false }
  );

  login(credentials: Credentials) {
    return this.authService
      .doLogin({ username: credentials.username, password: credentials.password, remember_me: credentials.remember_me })
      .pipe(
        tap((tokenObject: TokenObject) => {
          this.handleLogin(tokenObject);
        }),
        map(() => {
          return loginSuccess();
        }),
        catchError((error: any) => {
          return of(loginFailed(omitErrorResponseHelper(error)));
        })
      );
  }

  handleLogin(tokenObject) {
    if (tokenObject) {
      this.store.dispatch(utilitySetToken({ token: tokenObject }));
    }
  }
}
