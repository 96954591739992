import { createAction, props } from "@ngrx/store";

import { User } from "../../modules/user/interfaces/user";

export enum ActionTypes {
  FETCH_USER_START = "[user] fetch user",
  FETCH_USER_SUCCESS = "[user] fetch user success",
  FETCH_USER_FAILURE = "[user] fetch user failure",
  UPDATE_USER_START = "[user] update user",
  UPDATE_USER_SUCCESS = "[user] update user success",
  UPDATE_USER_CURRENCY_BALANCES = "[user] update user currency balances",
  UPDATE_USER_DISCOUNT = "[user] update user discount",
  NULL_ACTION = "[user] null action",
}

export const userFetchStart = createAction(ActionTypes.FETCH_USER_START, props<{ user?: User }>());
export const userFetchSuccess = createAction(ActionTypes.FETCH_USER_SUCCESS, props<{ user: User }>());
export const userFetchFailure = createAction(ActionTypes.FETCH_USER_FAILURE, props<{ error: any }>());

export const userUpdateStart = createAction(ActionTypes.UPDATE_USER_START, props<{ user: User }>());

export const userUpdateSuccess = createAction(ActionTypes.UPDATE_USER_SUCCESS, props<{ user: User }>());

export const userUpdateCurrencyBalances = createAction(
  ActionTypes.UPDATE_USER_CURRENCY_BALANCES,
  props<{ balances: any }>()
);

export const userUpdateDiscount = createAction(ActionTypes.UPDATE_USER_DISCOUNT, props<{ discount: number }>());

export const userNullAction = createAction(ActionTypes.NULL_ACTION);
