import {
  BOARD_TILE_ACTIVE,
  BOARD_TILE_BUYABLE,
  BUILDING_GROUP_TYPE_NON_INTERACTIVE,
  TILE_HEIGHT,
  TILE_WIDTH,
} from "../../constants";
import { ProcessedProduction } from "../../interfaces/production.interfaces";
import { MyScene } from "../classes/core/MyScene";

const TILE_WIDTH_HALF = TILE_WIDTH / 2;
const TILE_HEIGHT_HALF = TILE_HEIGHT / 2;

export function isTileBuyable(state: string) {
  return state === BOARD_TILE_BUYABLE;
}

export function isNonInteractiveBuilding(group_type: string) {
  return group_type === BUILDING_GROUP_TYPE_NON_INTERACTIVE;
}

export function isTileActive(state: string) {
  return state === BOARD_TILE_ACTIVE;
}

export function isSameFinalProduct(firstProduction: ProcessedProduction, secondProduction: ProcessedProduction) {
  const isCurrency = firstProduction.producingCurrency && secondProduction.producingCurrency;
  const isProduct = firstProduction.producingProduct && secondProduction.producingProduct;

  // console.log(firstProduction, secondProduction);
  // (isCurrency) && console.log('curr', firstProduction.producingCurrency.currency_id === secondProduction.producingCurrency.currency_id);
  // (isProduct) && console.log('prod', firstProduction.producingProduct.product_id === secondProduction.producingProduct.product_id);

  if (
    (isCurrency && firstProduction.producingCurrency.currency_id === secondProduction.producingCurrency.currency_id) ||
    (isProduct && firstProduction.producingProduct.product_id === secondProduction.producingProduct.product_id)
  ) {
    return true;
  } else {
    return false;
  }
}

export function cullingMapObjects() {
  this.buildingsLayer.children.entries.forEach(sprite => (sprite.visible = false));
  this.cameras.main.cull(this.buildingsLayer.children.entries).forEach(sprite => (sprite.visible = true));

  const diagonal = Phaser.Math.Distance.Between(
    this.cameras.main.x,
    this.cameras.main.y,
    this.cameras.main.centerX,
    this.cameras.main.centerY
  );

  this.bLayer &&
    this.bLayer.children.entries.forEach(sprite => {
      const distance =
        Phaser.Math.Distance.Between(sprite.x, sprite.y, this.cameras.main.centerX, this.cameras.main.centerY) -
        950 / this.cameras.main.zoom;
      const visible = distance < diagonal / this.cameras.main.zoom;
      sprite.visible = visible;
    });
}

export function calculateMinZoomValue(camera: Phaser.Cameras.Scene2D.Camera) {
  const bounds = camera.getBounds();
  const scaleW = camera.width / bounds.width;
  const scaleH = camera.height / bounds.height;
  return Math.max(scaleH, scaleW);
}

//
// /**
//  *
//  * @param objectGID
//  * @param imagesCollection
//  */
// export function getObjectImage(objectGID: number, imagesCollection: any[]) {
//   let objectImage;
//   imagesCollection.forEach(collection => collection.images.forEach(image => {
//     if (!objectImage && (image.gid === objectGID)) {
//       objectImage = image.image;
//     }
//   }));
//   objectImage = objectImage && objectImage.indexOf('/') ? objectImage.split('/').reverse()[0] : objectImage;
//   return objectImage;
// }
//
// /**
//  * Calculate screen positions.
//  * @param gameState
//  * @param isoX
//  * @param isoY
//  * @param isoZ
//  */
// export function isoToScreen(gameState: MyState, isoX: number, isoY: number, isoZ: number = 0) {
//   const screenPos = new Point();
//   gameState.iso.project(new Point3(isoX, isoY, isoZ), screenPos);
//   return screenPos;
// }
//
// export function screenToIso(gameState: MyState, screenX: number, screenY: number) {
//
//   return {
//     x: (screenX / TILE_WIDTH_HALF + screenY / TILE_HEIGHT_HALF) / 2,
//     y: (screenY / TILE_HEIGHT_HALF - (screenX / TILE_WIDTH_HALF)) / 2
//   }
//   // console.log(x, y)
//   // const isoPos = new Point3();
//   // gameState.iso.unproject(new Point(x, y), isoPos);
//   // console.log(isoPos)
//   // return isoPos;
// }
//
// export function tileToIsoPos(x: number, y: number) {
//   return {
//     x: x * TILE_WIDTH_HALF,
//     y: y * TILE_HEIGHT,
//   }
// }
//
// export function boardTileToIsoPos(tileX: number | MyPoint, tileY?: number) {
//   if (typeof tileX === 'object') {
//     tileY = tileX.y;
//     tileX = tileX.x;
//   }
//   return {
//     x: (tileX + BOARD_GRID_START.x) * TILE_HEIGHT * BOARD_TILE_SIZE,
//     y: (tileY + BOARD_GRID_START.y) * TILE_HEIGHT * BOARD_TILE_SIZE
//   }
// }
//
// /**
//  * Process assets in tiled json data.
//  * @param tilemapData
//  */
// export function getTilemapAssets(tilemapData) {
//   const tilesArray = {};
//   const tilesProps = [];
//   const tilesOffsets = [];
//   tilemapData.tilesets.forEach(function (el, i) {
//     const firstgid = el.firstgid;
//     const tiles = el.tiles;
//     for (const key in tiles) {
//       tilesArray['' + (firstgid + parseInt(key))] = tiles[key];
//       if (el.tileoffset) {
//         (tilesOffsets['' + (firstgid + parseInt(key))]) = el.tileoffset;
//       }
//     }
//     const tilesprops = el.tileproperties;
//     for (const key in tilesprops) {
//       tilesProps['' + (firstgid + parseInt(key))] = tilesprops['' + key];
//     }
//   });
//
//   return {
//     tilesArray,
//     tilesProps,
//     tilesOffsets
//   };
// }
//
// /**
//  * Found atlas name by asset name.
//  * Return null if not found.
//  * @param assetName
//  * @param atlases
//  */
// export function findAtlasNameByAssetName(assetName: string, atlases: any[]) {
//   let foundedAtlasName = null;
//
//   atlases.forEach((atlas) => {
//     atlas.images.find((image: string) => {
//       if (image === assetName) {
//         foundedAtlasName = atlas.name;
//       }
//     });
//   });
//
//   return foundedAtlasName;
// }

/**
 * Detect Mali GPU.
 * Used to force Phaser.Canvas rendering mode. On Mali and WebGL (autodetect) black screen occurs.
 */
export function maliDetect() {
  const canv = document.createElement("canvas");
  canv.id = "mali-test";
  canv.setAttribute("width", "1");
  canv.setAttribute("height", "1");
  document.body.appendChild(canv);

  const canvas = document.getElementById("mali-test") as any;
  const gl = canvas.getContext("webgl", { stencil: true });
  canvas.parentNode.removeChild(canvas);

  if (!gl) {
    return false;
  }

  const dbgRenderInfo = gl.getExtension("WEBGL_debug_renderer_info");
  let renderer;
  if (dbgRenderInfo != null) {
    renderer = gl.getParameter(dbgRenderInfo.UNMASKED_RENDERER_WEBGL);
  } else {
    return false;
  }
  const n = renderer.search("Mali-400");
  return n != -1;
}

export function isAssetLoadedToPhaserCache(key: string, cacheKeys: string[] = []) {
  cacheKeys = [...cacheKeys];
  return cacheKeys.indexOf(key) !== -1;
}

export function isPointerupOnCanvas(pointer: Phaser.Input.Pointer, scene: MyScene) {
  return scene.input.isOver && pointer.upElement && pointer.upElement.tagName === "CANVAS";
}

export function isPointerOnCanvas(pointer: Phaser.Input.Pointer, scene: MyScene) {
  return scene.input.isOver;
}
