function findFirstPositive(
  f,
  b = 1,
  d = (e, g, c) => (g < e ? -1 : 0 < f((c = (e + g) >>> 1)) ? (c == e || 0 >= f(c - 1) ? c : d(e, c - 1)) : d(c + 1, g))
) {
  for (; 0 >= f(b); b <<= 1);
  return d(b >>> 1, b) | 0;
}
export function getDPI() {
  return findFirstPositive(x => matchMedia(`(max-resolution: ${x}dpi)`).matches);
}
