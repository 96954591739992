import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "toHour",
})
export class ToHourPipe implements PipeTransform {
  transform(time: number, unit = "seconds", showSeconds = true) {
    if (isNaN(time) || time <= 0) {
      time = 0;
    }

    let sec = 0;

    switch (unit) {
      case "minutes":
        sec = time * 60;
        break;

      case "seconds":
        sec = time;
        break;
    }

    /*
     ~~ fast change float number to int

     12.5 -> ~12.5 = -12 -> ~ -12 = 12
    */
    const hours = Math.floor(sec / 3600);
    const minutes = Math.floor((sec / 60) % 60);
    sec = parseInt((sec % 60).toFixed(2), 10);

    const seconds = showSeconds ? `:${this.pad(sec, 2)}` : ``;

    return `${this.pad(hours, 2)}:${this.pad(minutes, 2)}${seconds}`;
  }

  pad(num: number, size: number): string {
    let s = num + "";
    while (s.length < size) {
      s = "0" + s;
    }
    return s;
  }
}
