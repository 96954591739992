import { Directive, Injectable, Input } from "@angular/core";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { BuildingDetailsConfig } from "../../../../game-engine/interfaces/building-details-config";

@Directive()
@Injectable()
export abstract class AbstractBuildingThumbnailComponent extends AbstractInjectBaseComponent {
  @Input() building: BuildingDetailsConfig;
}
