import { Directive, Injectable, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";

import { APP_ROUTING_ABSOLUTE_PATH } from "../../../consts/routing-app-absolute.const";
import { AbstractInjectBaseComponent } from "../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../core/decorators/ow-inject.decorator";
import { TokenExchangeService } from "../../../core/providers/token-exchange.service";
import { InactiveMeasureService } from "../../../core/services/core/inactive-measure.service";
import { loginLogout } from "../../../store/auth/login/actions";
import { socketClearStatus } from "../../../store/socket/actions";
import { AppState } from "../../../store/state";
import { GuiService } from "../../game/services/gui.service";
import { DialogService } from "../../shared/providers/dialog.service";
import { User } from "../../user/interfaces/user";
import { UserService } from "../../user/providers/user.service";
import { AuthService } from "../api/services/auth.service";
import { ReauthorizeService } from "../services/core/reauthorize.service";
import {ApplicationConfig} from '../../../store/config/interfaces/config.state';
import {selectConfigOfApplication} from '../../../store/config/selectors';
import {take} from 'rxjs/operators';

@Directive()
@Injectable()
export abstract class AbstractReauthorizeComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(UserService) userService: UserService;
  @OwInject(AuthService) authService: AuthService;
  @OwInject(InactiveMeasureService) inactiveMeasureService: InactiveMeasureService;
  @OwInject(ReauthorizeService) reauthorizeService: ReauthorizeService;
  @OwInject(TokenExchangeService) tokenExchangeService: TokenExchangeService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(Router) router: Router;
  @OwInject(GuiService) guiService: GuiService;
  @OwInject(DialogService) dialogService: DialogService;
  config: ApplicationConfig = null;

  hiddenUserInfo: string;

  ngOnInit() {
    this.store.select(selectConfigOfApplication).pipe(take(1)).subscribe(res => {
      this.config = res;
    })

    this.dialogService.closeAll();
    this.store.dispatch(socketClearStatus());
    this.inactiveMeasureService.stop();

    if (this.reauthorizeService.isTokenRememberMe()) {
      this.tokenExchangeService.exchangeTimeoutStop();
      this.setHashUser();
    } else {
      this.redirectToGame();
    }
  }

  reauthorize() {
    this.authService.reauthorize().subscribe(() => {
      this.redirectToGame();
    });
  }

  setHashUser() {
    const user: User = this.userService.me;
    this.hiddenUserInfo = this.hiddenString(user.first_name || "") + " " + this.hiddenString(user.last_name || "");
  }

  hiddenString(string: string) {
    return string
      .split("")
      .map((char, index) => {
        return index > 0 ? "*" : char;
      })
      .join("");
  }

  redirectToGame() {
    this.router.navigate([APP_ROUTING_ABSOLUTE_PATH.PLAYER_CHOOSE]);
  }

  logout() {
    this.authService.logoutApi().subscribe(() => {
      this.guiService.isSplashShow.next(true);
      this.store.dispatch(loginLogout());
    });
  }
}
