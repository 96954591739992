import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { AbstractBuildingParametersComponent } from "./abstract-building-parameters.component";
import { AbstractBuildingProductionComponent } from "./abstract-building-production.component";

@NgModule({
  declarations: [AbstractBuildingParametersComponent, AbstractBuildingProductionComponent],
  imports: [CommonModule],
  exports: [],
})
export class BuildingsAbstractModule {}
