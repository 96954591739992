import { Directive, Injectable } from "@angular/core";
import * as R from "ramda";

import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { CurrencyDetails } from "../../../../../../core/interfaces/currency";
import { ProductDetailsBalance } from "../../../../../player/interfaces/product.interface";
import { ProductPlayerService } from "../../../../../player/providers/product-player.service";
import { STOCK_VIEW } from "../../../shared-ui/mobile/consts/stock-view.const";
import { ApiMessageService } from "../../api/core/services/api-message.service";
import { MessageDetailsHiddenReward } from "../../interfaces/core/message-details-hidden-reward.interface";
import { AbstractMessageDetailsBaseComponent } from "./abstract-message-details-base.component";

@Directive()
@Injectable()
export abstract class AbstractMessageDetailsHiddenRewardComponent extends AbstractMessageDetailsBaseComponent {
  message: MessageDetailsHiddenReward;
  @OwInject(ProductPlayerService) productPlayerService: ProductPlayerService;
  @OwInject(ApiMessageService) apiMessageService: ApiMessageService;
  // END INJECTIONS

  loading: boolean;
  icon = "new-finding";
  STOCK_VIEW = STOCK_VIEW;

  currencyDetails(value): CurrencyDetails {
    return value;
  }

  getProducts() {
    this.message = R.clone(this.message);
    this.message.parameters.prizes = <ProductDetailsBalance[]>(
      this.productPlayerService.getProducts(this.message.parameters.prizes)
    );
  }

  setMessageProperty() {
    if (this.message.parameters.message) {
      /**
       * ICON
       */
      const icon = this.message.parameters.message.icon;
      if (icon) {
        this.icon = icon;
      }
      /**
       * TITLE
       */
      const title = this.message.parameters.message.title;
      if (title) {
        this.message.title = title;
      }
      /**
       * DESCRIPTION
       */
      const description = this.message.parameters.message.description;
      if (description) {
        this.message.description = description;
      }
    }
  }
}
