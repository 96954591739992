import { Injectable } from "@angular/core";
import { CanActivate, CanLoad } from "@angular/router";
import { concatLatestFrom } from "@ngrx/operators";
import { select, Store } from "@ngrx/store";
import { Observable, of, timer } from "rxjs";
import { catchError, debounce, filter, switchMap, take, tap } from "rxjs/operators";
import { selectPlayer } from "src/app/store/player/selectors";

import { playerFetch } from "../../store/player/actions";
import { socketConnect } from "../../store/socket/actions";
import { selectSocketState } from "../../store/socket/selectors";
import { AppState } from "../../store/state";
import { SOCKET_STATUS } from "../consts/core/socket.constants";

@Injectable({
  providedIn: "root",
})
export class SocketGuard implements CanActivate, CanLoad {
  constructor(public store: Store<AppState>) {}

  getState(): Observable<any> {
    this.store.dispatch(playerFetch({}));

    return this.store.pipe(
      concatLatestFrom(() => [this.store.pipe(select(selectSocketState)), this.store.pipe(select(selectPlayer))]),
      debounce(() => timer(0)),
      filter(([state, socketState, playerState]) => {
        // console.log(socketState, playerState);
        return !!playerState;
      }),
      tap(([state, socketState]) => {
        if (
          (!socketState.socketStatus || socketState.socketStatus === SOCKET_STATUS.DISCONNECTED) &&
          !socketState.isConnecting
        ) {
          this.store.dispatch(socketConnect());
        }
      }),
      filter(([state, socketState]) => {
        return socketState.socketStatus == SOCKET_STATUS.AUTHENTICATED;
      }),
      take(1)
    );
  }

  checkAuth(): Observable<boolean> {
    return this.getState().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  canActivate(): Observable<boolean> {
    return this.checkAuth();
  }

  canLoad(): Observable<boolean> {
    return this.checkAuth();
  }
}
