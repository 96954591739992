import { CommonModule } from "@angular/common";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { SharedModule } from "../../../../../../../shared/shared.module";

@Component({
  selector: "event-shop-alert-confirm",
  templateUrl: "./event-shop-alert-confirm.component.html",
  styleUrl: "./event-shop-alert-confirm.component.scss",
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, SharedModule, ReactiveFormsModule],
})
export class EventShopAlertConfirm extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(FormBuilder) fb: FormBuilder;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<EventShopAlertConfirm>;
  @OwInject(DialogService) dialogService: DialogService;

  form: FormGroup;

  ngOnInit() {
    this.form = this.fb.group({
      phoneNumber: [null, [Validators.required, Validators.minLength(9), Validators.maxLength(9)]],
    });
  }

  submit() {
    this.matDialogRef.close(this.form.value.phoneNumber);
  }
}
