import { Component, OnDestroy, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { take } from "rxjs/operators";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { AssetsService } from "../../../../../../../../core/providers/assets.service";
import { OwPaginationDate } from "../../../../../../../../core/utility/ow-pagination.class";
import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { AppState } from "../../../../../../../../store/state";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { GameService } from "../../../../../../services/game.service";
import { QA_TYPE } from "../../../../../qa/consts/core/qa-type.const";
import { QaService } from "../../../../../qa/services/core/qa.service";
import { AbstractBusinessCards } from "../../../../abstract/custom/abstract-business-cards.component";
import { BasicActionsComponent } from "../basic-actions/basic-actions.component";
import { DashboardComponent } from "../../dialogs/dashboard/dashboard.component";
import {PlayerService} from '../../../../../../../player/providers/player.service';
import {selectPrimary} from '../../../../../../../../store/primary/selectors';
import {BranchPreviewComponent} from '../../../../../preview-player/basic/custom/dialogs/branch-preview/branch-preview.component';

@Component({
  selector: "app-business-cards",
  templateUrl: "./business-cards.component.html",
})
export class BusinessCardsComponent extends AbstractBusinessCards implements OnInit, OnDestroy {
  @OwInject(Store) store: Store<AppState>;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(GameService) gameService: GameService;
  @OwInject(AssetsService) assetsService: AssetsService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(QaService) qaService: QaService;

  basicActionsComponent = BasicActionsComponent;
  NUMBER_CARDS = 0;
  visible = true;
  QA_TYPE = QA_TYPE;
  LOCATION_QA = 0;

  ngOnInit() {
    this.subscribePrimary();
    this.subscribeGlobalEvents();
  }

  subscribePrimary() {
    this.store.pipe(select(selectPrimary), take(1)).subscribe(primary => {
      this.primary = primary;

      this.subscribePlayer();
      this.checkAvailableQa();
    });
  }

  setPagination() {
    this.owPaginationDate = new OwPaginationDate({
      start: this.startOwDate,
      current: this.owDate,
    });
  }

  openPreviewPlayer() {
    this.dialogService.open(BranchPreviewComponent, {});
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }

  openCardTaskVendors() {
    this.dialogService.open(DashboardComponent, {
      data: {
        cardTasks: this.cardTasks,
        cardVendors: this.cardTasksVendors
      },
    });
  }
 
}
