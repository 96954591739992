import { Injectable, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { UAParser } from "ua-parser-js";

import { ConfigState } from "../../store/config/reducer";
import { selectConfigState } from "../../store/config/selectors";
import { OrientationManager } from "../helpers/orientation-manager.helper";
import { NativeAppService } from "./native-app.service";

export let mobileGui = false;
enum GUI {
  mobile,
  tablet,
  desktop,
}

@Injectable({
  providedIn: "root",
})
export class DeviceService implements OnDestroy {
  gui: GUI;
  isMobile: boolean;
  resizeTimer: number;
  windowScale = 1;
  windowMinHeightPx = 534;
  externalBarPx = 60;
  dialogScale = 1;
  isAllowOrientation: boolean;
  allowOrientation = "landscape";

  constructor(
    private router: Router,
    private store: Store<ConfigState>,
    private nativeAppService: NativeAppService
  ) {
    this.checkAndSetMobileDevice();
    this.checkAndSetGui();
  }

  checkAndSetMobileDevice() {
    const deviceDetected = new UAParser(window.navigator.userAgent).getResult();
    this.isMobile = deviceDetected.device.type === "mobile";
  }

  checkAndSetGui() {
    this.gui = GUI.desktop;

    if (this.isMobile) {
      this.gui = GUI.mobile;

      const size = 1000;
      const someSizeMoreThan = window.innerHeight >= size || window.innerWidth >= size;
      const everySizeMoreThan = window.innerHeight >= size && window.innerWidth >= size;

      if (someSizeMoreThan) {
        this.gui = GUI.tablet;
      }

      if (everySizeMoreThan) {
        this.gui = GUI.desktop;
      }
    }

    this.handlerChange();
    this.startOrientationManager();
  }

  setBodyClass() {
    document.body.classList.remove("mobile");
    document.body.classList.remove("tablet");
    document.body.classList.remove("desktop");

    switch (this.gui) {
      case GUI.mobile:
        document.body.classList.add("mobile");
        break;

      case GUI.tablet:
        document.body.classList.add("tablet");
        break;

      case GUI.desktop:
        document.body.classList.add("desktop");
        break;
    }
  }

  disableMobileGui() {
    this.gui = GUI.desktop;
    this.refreshRouter();
  }

  activeMobileGui() {
    this.gui = GUI.mobile;
    this.refreshRouter();
  }

  refreshRouter() {
    this.handlerChange();
    this.router.navigateByUrl(this.router.url);
  }

  handlerChange() {
    this.setGlobalGui();
    this.setBodyClass();
    this.setMobileWindowMaxHeightAndWidthClass();
    this.setOrientation();
  }

  setOrientation() {
    if (this.isMobile) {
      if (this.allowOrientation === "landscape") {
        this.isAllowOrientation = window.orientation === -90 || window.orientation === 90;
      }

      if (this.allowOrientation === "portrait") {
        this.isAllowOrientation = window.orientation === 0 || window.orientation === 180;
      }
    }
    if (this.nativeAppService.nativeInterface) {
      this.isAllowOrientation = true;
    }
  }

  setGlobalGui() {
    mobileGui = this.gui !== GUI.desktop;
  }

  calcScale() {
    const tempScale = window.innerHeight / (this.windowMinHeightPx + this.externalBarPx);
    this.windowScale = tempScale >= 1 ? 1 : tempScale;
  }

  calcDialogScale() {
    const tempScale = window.innerHeight / 320;
    this.dialogScale = tempScale >= 1 ? 1 : window.innerHeight / 320;
  }

  startOrientationManager() {
    window.addEventListener(OrientationManager.orientationEvent, this.onOrientationChange.bind(this));
  }

  onOrientationChange() {
    setTimeout(() => {
      if (OrientationManager.orientationChanged()) {
        this.handlerChange();
      }
    }, 50);
  }

  setMobileWindowMaxHeightAndWidthClass() {
    setTimeout(() => {
      const prevStyles = Array.from(document.querySelectorAll('style[mobile="style"]'));
      prevStyles.forEach(element => {
        element.remove();
      });
    }, 0);

    setTimeout(() => {
      setTimeout(() => {
        if (this.gui !== GUI.desktop) {
          this.calcDialogScale();
          const extraSheet = new CSSStyleSheet();
          extraSheet.replaceSync(`
          .mobile div:not(.no-dynamic-scale) > mat-dialog-container,
          .tablet div:not(.no-dynamic-scale) > mat-dialog-container {
            transform: scale(${this.dialogScale}) !important;
          }
        `);
          document.adoptedStyleSheets = [...document.adoptedStyleSheets, extraSheet];
        }
      }, 150);
    });
  }

  ngOnDestroy() {
    window.removeEventListener(OrientationManager.orientationEvent, this.onOrientationChange.bind(this));
  }
}
