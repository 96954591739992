import { Directive, ElementRef, Injectable, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { select, Store } from "@ngrx/store";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { CurrencyService } from "../../../../../../core/providers/currency.service";
import { selectPlayer } from "../../../../../../store/player/selectors";
import { AppState } from "../../../../../../store/state";
import { ProductPlayerService } from "../../../../../player/providers/product-player.service";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { PatchMissionHandOver } from "../../api/core/interfaces";
import { ApiMissionService } from "../../api/core/services/api-mission.service";
import { MissionCondition } from "../../interfaces/core";

@Directive()
@Injectable()
export class AbstractMissionTransferComponent extends AbstractInjectBaseComponent {
  @OwInject(MAT_DIALOG_DATA) data: {
    condition: MissionCondition;
  };
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractMissionTransferComponent>;
  @OwInject(ApiMissionService) apiMissionService: ApiMissionService;
  @OwInject(ProductPlayerService) productPlayerService: ProductPlayerService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(CurrencyService) currencyService: CurrencyService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(FormBuilder) fb: FormBuilder;
  @ViewChild("inputProductAmount") inputProductAmount: ElementRef;

  loading: boolean;
  condition: MissionCondition;
  productOrCurrency: any;
  error: boolean;
  reload: boolean;
  form: FormGroup;
  max: number;
  requiredAmount: number;

  subs = {
    player: null,
  };

  clearForm() {
    this.form = this.fb.group({
      amount: this.fb.control(null, [Validators.required, Validators.min(1)]),
    });
  }

  subscribePlayer() {
    this.subs.player = this.store.pipe(select(selectPlayer)).subscribe(player => {
      this.changePlayer(player.currency_balances);
    });
  }

  changePlayer(playerCurrencyBalances) {
    if (this.data.condition.currency) {
      this.productOrCurrency = this.currencyService.getCurrencyBalance(
        this.data.condition.currency,
        playerCurrencyBalances
      );
    }
    this.setRequiredAmount();
  }

  missionHandOver() {
    const amount = this.form.value.amount;
    this.loading = true;

    const patchMissionHandOver: PatchMissionHandOver = {
      player_mission_condition_id: this.condition.player_mission_condition_id,
      amount,
    };

    this.apiMissionService.missionHandOver(patchMissionHandOver).subscribe(
      () => {
        this.loading = false;
        this.reload = true;
        this.close();
      },
      () => {
        this.loading = false;
      }
    );
  }

  setRequiredAmount() {
    this.requiredAmount = this.condition.target - this.condition.value;
  }

  setMax() {
    if (this.productOrCurrency.balance > this.requiredAmount) {
      this.max = this.requiredAmount;
    } else {
      this.max = this.productOrCurrency.balance;
    }
    this.valueChange(this.max);
  }

  valueChange(value) {
    this.form.controls.amount.setValue(value);
  }

  checkInput(event: InputEvent) {
    if (this.inputProductAmount.nativeElement.value <= 0) {
      this.form.controls["amount"].setValue(null);
      this.inputProductAmount.nativeElement.value = null;
    }
  }

  close() {
    this.matDialogRef.close({ reload: this.reload });
  }
}
