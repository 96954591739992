import { Injectable } from "@angular/core";
import moment from "moment";

@Injectable()
export class SynchronizeTimeService {
  private timeDifference = 0;
  private timeOffset = 0;

  setActualServerTime(serverTimeObject: { date: string }) {
    if (serverTimeObject.hasOwnProperty("date")) {
      this.setDifferenceTime(serverTimeObject.date);
    }
  }

  /**
   * Set time difference between client and server.
   * @param actualServerTime
   */
  setDifferenceTime(actualServerTime) {
    const serverTime = moment(actualServerTime);
    const clientTime = moment(new Date());
    this.timeDifference = serverTime.diff(clientTime);
  }

  /**
   * Set time offset based on user real_time property.
   * Allow user to play in `future/past`.
   */
  setTimeOffset(futureTime) {
    const clientTime = moment(this.getActualLocalTime());
    return (this.timeOffset = moment(futureTime).diff(clientTime));
  }

  getActualLocalTime(offset = 0) {
    return new Date(new Date().getTime() + this.timeDifference + offset);
  }

  getActualLocalTimeWithOffset() {
    return this.getActualLocalTime(this.timeOffset);
  }
}
