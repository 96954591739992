import { Component, OnInit } from "@angular/core";

import { AbstractAlertConfirmComponent } from "../../../../abstracts/abstract-alert-confirm.component";

@Component({
  selector: "app-core-alert-confirm",
  templateUrl: "./alert-confirm.component.html",
})
export class AlertConfirmComponent extends AbstractAlertConfirmComponent implements OnInit {
  ngOnInit() {
    this.setDefaultLack();
  }
}
