import { GameFeatures } from "../interfaces/game-features.interface";
import { CustomGameFeaturesMap } from "./game-features.custom";

export const GameFeaturesMap: GameFeatures = {
  tiledMap: true,
  board: true,
  boardTileInput: true,
  sceneBgImages: true,
  tooltip: true,
  cameraControls: true,
  forceCanvas: false,
  forceLowerFramerate: false,
  ...CustomGameFeaturesMap,
};
