/**
 * This file was created to allow import base config into default config and custom config which is also imported into default config.
 * With this we will avoid circular dependency problem.
 */

import { TILE_HOVER_CONFIG } from "../classes/tile-hover/tile-hover.config";
import { TILE_MENU_CONFIG } from "../classes/tile-menu/tile-menu.config";
import { TILE_TOOLTIP_CONFIG } from "../classes/tile-tooltip/tile-tooltip.config";
import { CITY_SCENE_CONFIG_KEY } from "../consts/core/game-map.const";
import { SceneConfig } from "../interfaces/scene-config.interface";
import { BASIC_SCENE_BASIC_HEIGHT, BASIC_SCENE_BASIC_WIDTH } from "./basic.constants";

export const BASIC_SCENE_BASE_CONFIG: SceneConfig = {
  backgroundImages: [
    {
      filename: "map.jpg",
      key: "map",
      origin: {
        x: 0,
        y: 0,
      },
      displayWidth: BASIC_SCENE_BASIC_WIDTH,
      displayHeight: BASIC_SCENE_BASIC_HEIGHT,
      x: 0,
      y: 0,
    },
  ],
  backgroundColor: "#71aee3",
  tiledJsonPrefix: "island",
  mapDirectoryName: "world",
  boardStartOffset: {
    x: 0,
    y: 0,
  },
  tiledStartOffset: {
    x: 0,
    y: 0,
  },
  mapAtlasDirectory: "map",
  tileMenuAtlasDirectory: "tile-menu",
  islandGroupIdForBoard: "B",
  tiledMapFileName: "map",
  yAxisSign: -1,
  cameraBounds: {
    x: -5000,
    y: 0,
    width: 10000,
    height: 7000,
  },
  cameraStartPosition: {
    x: 5000,
    y: 3500,
  },
  gui_unlocks_button: CITY_SCENE_CONFIG_KEY, // this is needed for proper hud menu buttons display, please set key in custom config
  configKeyName: CITY_SCENE_CONFIG_KEY, // this is needed for proper hud menu buttons display, please set key in custom config
  tileTooltipConfig: { ...TILE_TOOLTIP_CONFIG },
  tileMenuConfig: { ...TILE_MENU_CONFIG },
  tileHoverConfig: { ...TILE_HOVER_CONFIG },
};
