import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";

import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { ConfigState } from "../../../../../../../store/config/reducer";
import { selectConfigState } from "../../../../../../../store/config/selectors";
import { AbstractChangePasswordButtonComponent } from "../../../abstract/abstract-change-password-button.component";

@Component({
  selector: "m-ui-change-password-button",
  templateUrl: "./ui-change-password-button.component.html",
})
export class MUiChangePasswordButtonComponent extends AbstractChangePasswordButtonComponent {}
