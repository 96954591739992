import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import moment from "moment";
import { Swiper, SwiperOptions } from "swiper/types";

import { swiperInjectionStyles } from "../../../../../../../../../styles/swiper/swiper-injection-styles";
import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { SynchronizeTimeService } from "../../../../../../../../core/providers/synchronize-time.service";
import { EventEmitterDialogsService } from "../../../../../../../../core/services/core/event-emitter-dialogs.service";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import { generateEachPages } from "../../../../../../../shared/helpers/generate-pages.helper";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { SharedModule } from "../../../../../../../shared/shared.module";
import { SharedUiMobileModule } from "../../../../../shared-ui/mobile/shared-ui-mobile.module";
import { EventShop } from "../../../../api/core/interfaces/response/get-event-shops.interface";
import { ApiEventShopService } from "../../../../api/core/services/api-event-shop.service";
import { EVENT_DIALOGS_NAMES_EVENT_SHOP } from "../../../../consts/core/event-dialogs/event-names.const";
import { EventsShopService } from "../../../../services/core/events-shop.service";
import { map } from "rxjs/operators";
import { EVENT_SHOP_LIST_API_RESPONSE_MOCK } from "../../../../mock/event-shop-list.mock";

@Component({
  selector: "event-shop-list",
  templateUrl: "./event-shop-list.html",
  standalone: true,
  styleUrl: "./event-shop-list.scss",
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, SharedModule, SharedUiMobileModule],
})
export class EventShopList extends AbstractInjectBaseComponent implements OnInit, AfterViewInit {
  @OwInject(ApiEventShopService) apiEventShopService: ApiEventShopService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(SynchronizeTimeService) synchronizeTimeService: SynchronizeTimeService;
  @OwInject(EventsShopService) eventsShopService: EventsShopService;

  eventShops = {
    config: {
      itemPerPage: 1,
    },
    pages: [],
    items: [],
  };

  swiper?: Swiper;
  @ViewChild("swiperRef", { static: false })
  swiperRef: ElementRef | undefined;
  currentSlideIndex = 0;
  config: SwiperOptions = {
    slidesPerView: 3,
    initialSlide: 0,
    centeredSlides: true,
    pagination: {
      dynamicBullets: true,
      clickable: true,
    },
    injectStyles: [swiperInjectionStyles],
  };

  ngOnInit() {
    this.eventsShopService.clearEventShopNotification();
    this.getEventShops();
  }

  ngAfterViewInit() {
    this.setSwiper();
  }

  setSwiper() {
    this.swiper = this.swiperRef.nativeElement.swiper;
    this.swiper.on("slideChange", swiper => {
      this.currentSlideIndex = swiper.activeIndex;
    });
  }

  getEventShops() {
    this.apiEventShopService
      .getEventShops()
      // .pipe(map(value => EVENT_SHOP_LIST_API_RESPONSE_MOCK))
      .subscribe(
        resp => {
          this.eventShops.pages = [];
          this.eventShops.items = resp;
          this.generatePages();
          this.swiper.update();
        },
        errResp => {
          this.dialogService.openAlertErrorApi({ errResp });
        }
      );
  }

  generatePages() {
    this.eventShops = generateEachPages(this.eventShops);
  }

  openShopDetails(eventShop: EventShop) {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_EVENT_SHOP.DETAILS,
      config: {
        data: {
          eventShop,
        },
      },
    });
  }

  isShopEventDeactivate(eventShop: EventShop) {
    const currencyStart = moment(eventShop.currency_collection_start).toDate();
    const openStart = moment(eventShop.open_start).toDate();
    const openEnd = moment(eventShop.open_end).toDate();

    const currentDate = moment(this.synchronizeTimeService.getActualLocalTime()).startOf("day").toDate();
    const startDate = currencyStart < openStart ? currencyStart : openStart;

    return currentDate >= startDate && currentDate <= openEnd;
  }

  prevSlide() {
    this.swiper.slidePrev();
    this.currentSlideIndex = this.swiper.activeIndex;
  }

  nextSlide() {
    this.swiper.slideNext();
    this.currentSlideIndex = this.swiper.activeIndex;
  }
}
