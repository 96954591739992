import { createReducer, on } from "@ngrx/store";

import { User } from "../../modules/user/interfaces/user";
import { userFetchFailure, userFetchStart, userFetchSuccess, userUpdateSuccess } from "./actions";

export interface UserState {
  isLoading: boolean;
  me: User;
  error: any;
}

const initialState: UserState = {
  isLoading: false,
  me: null,
  error: null,
};

export const userReducer = createReducer(
  initialState,
  on(userFetchStart, (state, action) => {
    return {
      ...state,
      isLoading: true,
    };
  }),
  on(userFetchSuccess, (state, action) => {
    return {
      ...state,
      isLoading: false,
      error: null,
    };
  }),
  on(userFetchFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      me: null,
      error: action.error,
    };
  }),
  on(userUpdateSuccess, (state, action) => {
    return {
      ...state,
      me: action.user,
    };
  })
);
