<div class="global-dialog">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <div class="cards">
    <ng-container *ngIf="data.cardTasksVendors?.length > 0; else noCardsTpl">
      <card-task
        *ngFor="let cardTask of data.cardTasksVendors"
        [cardTasks]="data.cardTasksVendors"
        [cardTask]="cardTask"
      ></card-task>
    </ng-container>

    <ng-template #noCardsTpl>
      <span class="no-cards">Brak zadań</span>
    </ng-template>
  </div>
</div>
