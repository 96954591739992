import { NgModule } from "@angular/core";

import { UtilityAbstractModule } from "../abstract/utility-abstract.module";
import { MInfoMessageScreenComponent } from "./components/info-message-screen/info-message-screen.component";

@NgModule({
  imports: [UtilityAbstractModule],
  declarations: [MInfoMessageScreenComponent],
  exports: [MInfoMessageScreenComponent],
})
export class UtilityMobileModule {}
