<div class="global-dialog ow-dialog window-a primary">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button></m-ui-back-button>

  <!-- TOP BAR BASE -->
  <div class="top-bar base">
    <!-- PRODUCT DETAILS NAME -->
    <div class="title">
      {{ product.name }}
    </div>
  </div>

  <div class="product-details loading-opacity" *ngIf="product && transactionBuyForm && transactionSellForm">
    <div class="product-info">
      <div class="product-in-glow-box">
        <!-- PRODUCT ICON -->
        <img class="product-icon" [src]="product.iconUrlBig" />

        <!-- PRODUCT BALANCE -->
        <span class="product-balance">
          {{ product.balance | NumberWithSpaces }}
        </span>
        <div class="clip-path"></div>
      </div>

      <!-- PRODUCT DESCRIPTION -->
      <p class="description" [innerHTML]="product.description" owEllipsis></p>
    </div>

    <ng-container *ngIf="playerService.isActiveMe">
      <div class="clip-path-horizontal"></div>
      <div class="clip-path-vertical"></div>

      <!-- TRANSACTION CONTAINER -->
      <div class="transaction-container">
        <!-- BUY TRANSACTION -->
        <div class="each-transaction">
          <ng-container *ngIf="product.buyable">
            <!-- INPUT -->
            <form [formGroup]="transactionBuyForm">
              <input type="number" formControlName="amount" [placeholder]="'warehouse.form.set-amount' | myTranslate" />
            </form>

            <!-- SUMMARY -->
            <div class="summary">
              <ng-container
                [ngTemplateOutlet]="currencyAmountTpl"
                [ngTemplateOutletContext]="{ amount: product.buying_price, lack: false }"></ng-container>
              &nbsp;&nbsp;x
              <m-ui-product
                [item]="product"
                [stockView]="STOCK_VIEW.H"
                [lockOpenStorage]="true"
                [lack]="false"
                [hideValue]="true"></m-ui-product>
              {{ transactionBuyForm.controls["amount"].value || 0 }}
              &nbsp;&nbsp;=
              <ng-container
                [ngTemplateOutlet]="currencyAmountTpl"
                [ngTemplateOutletContext]="{
                  amount: product.buying_price * transactionBuyForm.controls['amount'].value,
                  lack: true,
                  transaction: this.TRANSACTION.BUY,
                }"></ng-container>
            </div>
          </ng-container>
          <div class="no-transation" *ngIf="!product.buyable">
            {{ "warehouse.product-not-buyable" | myTranslate }}
          </div>

          <!-- BUTTON BUY -->
          <span
            matTooltip="{{
              product?.buyable_from_level
                ? ('warehouse.product-blocked-until-reach' | myTranslate) +
                  ' ' +
                  product?.buyable_from_level +
                  ' ' +
                  ('warehouse.level' | myTranslate) +
                  '.'
                : '' + product?.sellable_from_star?.star_name
                  ? ('warehouse.product-blocked-until-reach' | myTranslate) +
                    ' ' +
                    product?.buyable_from_star?.star_name +
                    '.'
                  : ''
            }}"
            [matTooltipPosition]="'below'"
            [matTooltipShowDelay]="300"
            [matTooltipDisabled]="
              playerService.player.level >= product.buyable_from_level &&
              (product?.buyable_from_star
                ? playerService.player.star.star_id >= product.buyable_from_star.star_id
                : true)
            ">
            <button
              class="base primary"
              (click)="transaction(TRANSACTION.BUY, transactionBuyForm.controls['amount'].value)"
              [disabled]="
                transactionBuyForm.invalid ||
                (product?.buyable_from_level ? product.buyable_from_level > playerService.player.level : false) ||
                (product?.buyable_from_star
                  ? product.buyable_from_star.star_id > playerService.player.star.star_id
                  : false)
              ">
              {{ "warehouse.button.buy" | myTranslate }}
            </button>
          </span>
        </div>

        <!-- SELL TRANSACTION -->
        <div class="each-transaction">
          <ng-container *ngIf="product.sellable">
            <!-- INPUT -->
            <form [formGroup]="transactionSellForm">
              <input
                #inputProductAmount
                (input)="checkInput()"
                type="number"
                formControlName="amount"
                [placeholder]="'warehouse.form.set-amount' | myTranslate" />
            </form>

            <!-- SUMMARY -->
            <div class="summary">
              <ng-container
                [ngTemplateOutlet]="currencyAmountTpl"
                [ngTemplateOutletContext]="{ amount: product.selling_price, lack: false }"></ng-container>
              &nbsp;&nbsp;x
              <m-ui-product
                [item]="{ product_id: product.product_id, amount: transactionSellForm.controls['amount'].value || 0 }"
                [stockView]="STOCK_VIEW.H"
                [lockOpenStorage]="true"
                [lack]="true"
                [hideValue]="true"
                (itemChanged)="changeAmountSum($event, this.TRANSACTION.SELL)"></m-ui-product>
              {{ transactionSellForm.controls["amount"].value || 0 }}
              &nbsp;&nbsp;=
              <ng-container
                [ngTemplateOutlet]="currencyAmountTpl"
                [ngTemplateOutletContext]="{
                  amount: product.selling_price * transactionSellForm.controls['amount'].value,
                  lack: false,
                }"></ng-container>
            </div>
          </ng-container>
          <div class="no-transation" *ngIf="!product.sellable">
            {{ "warehouse.product-not-sellable" | myTranslate }}
          </div>

          <!-- BUTTON SELL -->
          <span
            matTooltip="{{
              product?.sellable_from_level
                ? ('warehouse.sale-blocked-until-reach' | myTranslate) +
                  ' ' +
                  product?.sellable_from_level +
                  ' ' +
                  ('warehouse.level' | myTranslate) +
                  '.'
                : '' + product?.sellable_from_star?.star_name
                  ? ('warehouse.sale-blocked-until-reach' | myTranslate) +
                    ' ' +
                    product?.sellable_from_star?.star_name +
                    '.'
                  : ''
            }}"
            [matTooltipPosition]="'below'"
            [matTooltipShowDelay]="300"
            [matTooltipDisabled]="
              playerService.player.level >= product.sellable_from_level &&
              (product?.sellable_from_star
                ? playerService.player.star.star_id >= product.sellable_from_star.star_id
                : true)
            ">
            <button
              class="base primary"
              (click)="transaction(TRANSACTION.SELL, transactionSellForm.controls['amount'].value)"
              [disabled]="
                transactionSellForm.invalid ||
                (product?.sellable_from_level ? product.sellable_from_level > playerService.player.level : false) ||
                (product?.sellable_from_star
                  ? product.sellable_from_star.star_id > playerService.player.star.star_id
                  : false)
              ">
              {{ "warehouse.button.sell" | myTranslate }}
            </button>
          </span>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<!-- CURRENCY AMOUNT TEMPLATE -->
<ng-template #currencyAmountTpl let-amount="amount" let-lack="lack" let-transaction="transaction">
  <ng-container *ngIf="tradingCurrencyId; else noTradingCurrencyTpl">
    <m-ui-currency
      [item]="{ currency_id: tradingCurrencyId, amount: amount }"
      [stockView]="STOCK_VIEW.H"
      [hideValue]="true"
      [observable]="true"
      [lack]="lack"
      (itemChanged)="changeAmountSum($event, transaction)"></m-ui-currency>

    {{ amount }}
  </ng-container>
  <ng-template #noTradingCurrencyTpl> {{ amount }}$ </ng-template>
</ng-template>
