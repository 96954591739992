import { Directive, Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { take } from "rxjs/operators";

import { APP_ROUTING_ABSOLUTE_PATH } from "../../../consts/routing-app-absolute.const";
import { AbstractInjectBaseComponent } from "../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../core/decorators/ow-inject.decorator";
import { AppState } from "../../../store/state";
import { utilitySetToken } from "../../../store/utility/actions";
import { DialogService } from "../../shared/providers/dialog.service";
import { AuthService } from "../api/services/auth.service";
import { AUTH_ROUTING_ABSOLUTE_PATH } from "../consts/core/routing-auth-absolute.const";
import { TokenObject } from "../interfaces/token-object";

@Directive()
@Injectable()
export abstract class AbstractTempTokenExchangeComponent extends AbstractInjectBaseComponent {
  @OwInject(Router) router: Router;
  @OwInject(ActivatedRoute) activatedRoute: ActivatedRoute;
  @OwInject(AuthService) authService: AuthService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(Store) store: Store<AppState>;
  tempToken: string;
  tokenData: TokenObject;

  subscribeRoutingParams() {
    this.activatedRoute.params.pipe(take(1)).subscribe(params => {
      if (params.hasOwnProperty("temp-token")) {
        this.tempToken = params["temp-token"];
        this.exchangeToken();
      }
    });
  }

  exchangeToken() {
    this.authService.tempTokenExchange({ temp_token: this.tempToken }).then(
      resp => {
        this.tokenData = resp;
        this.store.dispatch(utilitySetToken({ token: resp }));
        this.redirectPlayerChoose();
      },
      () => {
        this.redirectLogin();
      }
    );
  }

  redirectPlayerChoose() {
    this.router.navigate([APP_ROUTING_ABSOLUTE_PATH.PLAYER_CHOOSE]);
  }

  redirectLogin() {
    this.router.navigate([AUTH_ROUTING_ABSOLUTE_PATH.LOGIN]);
  }
}
