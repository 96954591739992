export interface RankType {
  label: string;
  order: number;
  type: string;
  show: boolean;
}

export enum RANKS_TABS {
  BUSINESS = 'BUSINESS',
  GAMES = 'GAMES'
}
