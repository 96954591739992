import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class RoutingService {
  constructor(private router: Router) {
    this.subscribeRoute();
  }

  subscribeRoute() {
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd && val.url !== "/auth/game") {
        document.body.style.touchAction = "initial";
      }
    });
  }
}
