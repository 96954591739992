<div class="ow-dialog dialog-mobile dialog-primary">
  <!-- DIALOG EXIT -->
  <m-ui-close-button [preventDefault]="true" (clicked)="closeOrCloseAll()"></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button
    [preventDefault]="true"
    (clicked)="close()"
    *ngIf="messageOpenDetailsData.fromList"></m-ui-back-button>

  <!-- LOADING -->
  <loading></loading>

  <!-- DIALOG INNER -->
  <div class="dialog-inner">
    <div>
      <!-- MESSAGE DATE -->
      <div class="message-date">
        {{ message.created_at | date: "yyyy-MM-dd-,HH:mm" }}
      </div>

      <!-- TITLE -->
      <p class="title">{{ message.title }}</p>

      <!-- IMAGE -->
      <div
        class="message-image"
        [style.background-image]="
          'url(' + (message.parameters.chest_product_icon + '-empty.png' | asset: 'ui') + ')'
        "></div>

      <!-- DESCRIPTION -->
      <div class="description" [innerHTML]="message.description | safe: 'html'"></div>
    </div>
  </div>
</div>
