import { StorageLocationBase } from "./location-base.class";

export class StorageLocationSession extends StorageLocationBase {
  init() {
    super.init();
    this.supported = this.isSupported();
  }

  isSupported() {
    try {
      const key = "checkSupportedSessionStorage";
      sessionStorage.setItem(key, key);
      const val = sessionStorage.getItem(key);
      if (val !== key) {
        throw new Error("Session storage value is not equal to the provided one");
      }
      sessionStorage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  }

  get(key) {
    return sessionStorage.getItem(key);
  }

  set(key, value) {
    return sessionStorage.setItem(key, value);
  }

  remove(key) {
    sessionStorage.removeItem(key);
  }

  clear() {
    sessionStorage.clear();
  }
}
