import { createFeatureSelector, createSelector } from "@ngrx/store";

import { boardAdapter, GameState } from "./reducer";

const { selectIds, selectEntities, selectAll, selectTotal } = boardAdapter.getSelectors();

export const selectGameState = createFeatureSelector<GameState>("game");

export const selectGameBoard = createSelector(selectGameState, state => state.boardState);

export const selectGameReady = createSelector(selectGameState, state => state.ready);

export const selectGameCurrentScene = createSelector(selectGameState, state => state.currentScene);

export const selectGameLastUpdateTile = createSelector(selectGameBoard, state => state.lastBoardTileUpdate);

export const selectGamePreviewBoard = createSelector(selectGameBoard, state => state.previewBoard);

export const selectGameBoardTilesByBuildingGroup = createSelector(selectGameBoard, (state, action) => {
  return state.boardState.board.filter(tile => {
    return tile.player_building && tile.player_building.group === action.group; // check if player id is not null
  });
});

export const selectGameImportantMessages = createSelector(selectGameState, state => state.importantMessages);

export const selectGameBoardLoaded = createSelector(selectGameBoard, state => state.loaded);

// select all currently active board tiles
export const selectGameAllTiles = createSelector(selectGameBoard, state => {
  return selectAll(state.board);
});

// select all gameStatuses
export const selectGameBoardStatuses = createSelector(selectGameBoard, state => {
  return state.boardStatus;
});

// initiation data used in .boot.scenes
export const selectGameBoardInitiationData = createSelector(
  selectGameBoardLoaded,
  selectGameAllTiles,
  selectGameState,
  selectGamePreviewBoard,
  (loaded, tiles, gameState, previewBoard) => {
    // if there is something in preview board then you're in preview mode
    if (previewBoard != null && previewBoard.length) {
      return {
        loaded: true,
        boardTiles: previewBoard,
      };
    }

    const tilesArr = tiles.filter(x => x.player_island_id === gameState.boardState.playerIslandId);
    return {
      loaded: loaded,
      boardTiles: tilesArr,
    };
  }
);

// select specific tile by tile_id
export const selectGameTileByTileId = (id: number) =>
  createSelector(selectGameBoard, state => {
    return state.board.entities[id];
  });

export const selectGameBoardTileHideLevelInformation = createSelector(selectGameBoard, state => {
  return selectAll(state?.board).map(
    tile => tile.player_building && tile.player_building?.hide_level_information_on_front
  );
});
