<div class="upgrade-container">
  <!-- PRODUCTION NORMAL -->
  <div class="upgrade-label">
    <ng-container *ngIf="normal">
      <m-ui-product
        *ngIf="normal.product_prizes[0]"
        [item]="normal.product_prizes[0]"
        [other]="{
          value: 'x',
        }"
        [stockView]="STOCK_VIEW.C"
        [lack]="false"></m-ui-product>

      <m-ui-currency
        *ngIf="normal.currency_prizes[0]"
        [item]="normal.currency_prizes[0]"
        [other]="{
          value: 'x',
        }"
        [stockView]="STOCK_VIEW.C"
        [lack]="false"></m-ui-currency>
    </ng-container>

    <!-- ARROW PARAMETERS -->
    <ng-container *ngIf="parameters">
      <div class="production-container arrow" *ngIf="parameters.quantity_diff !== undefined"></div>
    </ng-container>
  </div>

  <!-- PRODUCTION UPGRADE/NEW -->
  <div class="upgrade">
    <ng-container *ngIf="upgrade">
      <m-ui-product
        *ngIf="upgrade.product_prizes[0]"
        [item]="upgrade.product_prizes[0]"
        [other]="{
          value: 'x',
        }"
        [stockView]="STOCK_VIEW.C"
        [lack]="false"
        [class.quantity-diff]="parameters && parameters.quantity_diff"></m-ui-product>

      <m-ui-currency
        *ngIf="upgrade.currency_prizes[0]"
        [item]="upgrade.currency_prizes[0]"
        [other]="{
          value: 'x',
        }"
        [stockView]="STOCK_VIEW.C"
        [lack]="false"
        [class.quantity-diff]="parameters && parameters.quantity_diff"></m-ui-currency>
    </ng-container>

    <!-- NEW INDICATOR -->
    <div class="new-indicator" *ngIf="parameters && parameters.new">
<!--      <i class="fas fa-exclamation exclamation-new"></i>-->
      <span class="new">{{"building-upgrade.new" | myTranslate}}</span>
    </div>
  </div>
</div>
