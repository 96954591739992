import * as R from "ramda";

import { SceneConfigMap } from "../interfaces/scene-config.interface";
import { BASIC_SCENE_BASE_CONFIG } from "./basic-scene.base-config";
import { BASIC_SCENE_CUSTOM_CONFIG } from "./basic-scene.custom.config";

export const BASIC_SCENE_CONFIG: SceneConfigMap = {
  default: {
    ...R.clone(BASIC_SCENE_BASE_CONFIG),
  },
  ...BASIC_SCENE_CUSTOM_CONFIG,
};
