<div
  class="global-dialog ow-dialog window-a primary"
>
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <m-ui-back-button buttonSize="small"></m-ui-back-button>

  <!-- NAVIGATION CENTER -->
  <ng-container *ngIf="data.cardTasks.length > 1">
    <m-ui-navigation-center
      (prevClicked)="prev()"
      (nextClicked)="next()"
    ></m-ui-navigation-center>
  </ng-container>

  <div class="content-wrapper">
    <div class="title">
      {{data.cardTasks[index]?.name}}
    </div>

    <div class="container-inner" [innerText]="data.cardTasks[index]?.description">
    </div>

    <div class="awards">
      <p>Nagrody do zdobycia</p>
      <div class="wrapper">
        <m-ui-currency *ngFor="let currency of data.cardTasks[index].currency_prizes"
                       [stockView]="STOCK_VIEW.A"
                       [item]="currency"
                       [lack]="false"
                       [lockOpenStorage]="true">
        </m-ui-currency>
        <m-ui-product *ngFor="let product of data.cardTasks[index].product_prizes"
                      [stockView]="STOCK_VIEW.A"
                      [item]="product"
                      [lack]="false"
                      [lockOpenStorage]="true">
        </m-ui-product>
      </div>
    </div>
  </div>

  <!-- RANKING BUTTON -->
  <div class="bottom-bar rankings-button" *ngIf="data.cardTasks[index].show_ranks">
    <div class="bar-button long">
      <div class="pagination">
        <div class="buttons">
          <button
            (click)="openRankList()"
            matTooltip='Przejdź do rankingu {{data.cardTasks[index].name}}'
            class="square small primary"
            matTooltipPosition="above"
            matTooltipShowDelay="300">
            <img [src]="'rankings.png' | asset: 'ui'"/>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
