import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { SharedModule } from "../../../../../shared/shared.module";
import { SharedUiMobileModule } from "../../../shared-ui/mobile/shared-ui-mobile.module";
import { BranchPreviewComponent } from "../../basic/custom/dialogs/branch-preview/branch-preview.component";
import { OwPerfectScrollbarModule } from '@oskarwegner/ow-perfect-scrollbar';

@NgModule({
  imports: [CommonModule, SharedModule, SharedUiMobileModule, OwPerfectScrollbarModule],
  declarations: [BranchPreviewComponent],
})
export class PreviewPlayerModule {}
