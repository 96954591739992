import { EnvironmentType } from "../app/core/interfaces/environment.type";
import APP_CONFIG from "../config.json";

const config: Partial<EnvironmentType> = APP_CONFIG;

export const environment = {
  production: true,
  apiUrl: config.apiUrl,
  wsUrl: config.wsUrl,
  baseHref: config.baseHref,
  language: config.language,
  title: config.title,
  devel: config.devel,
};
