import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { AbstractInjectBaseComponent } from "src/app/core/abstracts/abstract-inject-base.component";

@Component({
  selector: "app-business-task-vendors",
  templateUrl: "./task-vendors.component.html",
})
export class TaskVendorsComponent extends AbstractInjectBaseComponent {
  @Input() cardVendor = [];
  @Input() currentPage: string = '';
  @Input() blockNext = true;
  @Input() blockPrev = true;
  @Output() prev: EventEmitter<any> = new EventEmitter();
  @Output() next: EventEmitter<any> = new EventEmitter();

  triggerNext() {
    this.next.emit(true);
  }

  triggerPrev() {
    this.prev.emit(true);
  }
}

