import { Component, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngrx/store";

import { AbstractInjectBaseComponent } from "../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../core/decorators/ow-inject.decorator";
import { translate } from "../../../../../core/helpers/translate.helper";
import { SynchronizeTimeService } from "../../../../../core/providers/synchronize-time.service";
import { EventEmitterDialogsService } from "../../../../../core/services/core/event-emitter-dialogs.service";
import { getToken } from "../../../../../core/utility/token";
import { playerFetch } from "../../../../../store/player/actions";
import { AppState } from "../../../../../store/state";
import { BaseChest } from "../../../../player/interfaces/player";
import { ProductDetailsBalance } from "../../../../player/interfaces/product.interface";
import { DialogService } from "../../../../shared/providers/dialog.service";
import { ProductsService } from "../../../services/products.service";
import { MESSAGE_DETAILS_TYPE } from "../../message/consts/core/message-details-type.const";
import { MessageEventFromDetails } from "../../message/interfaces/core/message-callback-from-details.interface";
import {
  MessageDetailsChestSuccess,
  ProductChest,
} from "../../message/interfaces/core/message-details-chest-success.interface";
import { MessageOpenDetailsData } from "../../message/interfaces/core/message-open-details-data.interface";
import { MessageService } from "../../message/services/core/message.service";
import { ApiChestService } from "../api/core/services/api-chest.service";
import { ChestClaimResponse } from "../interfaces/chest-claim-response.interface";

@Component({
  template: "",
})
export class AbstractChestComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(MatDialogRef) matDialogDef: MatDialogRef<AbstractChestComponent>;
  @OwInject(MAT_DIALOG_DATA) data: {
    chest: BaseChest;
  };
  @OwInject(ProductsService) productsService: ProductsService;
  @OwInject(ApiChestService) apiChestService: ApiChestService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MessageService) messageService: MessageService;
  @OwInject(SynchronizeTimeService) synchronizeTimeService: SynchronizeTimeService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;

  isImpersonate: boolean = getToken().is_impersonate;
  loading: boolean;
  chest: ProductDetailsBalance & BaseChest;
  chestType: number;
  titleAcquired: string;
  descriptionAcquired: string;
  buttonOpen: string;

  ngOnInit() {
    this.playerChestsId();
  }

  playerChestsId() {
    this.apiChestService.playerChestsId(this.data.chest.player_chest_id).subscribe((resp: { chest_type: number }) => {
      this.chestType = resp.chest_type;
      this.setTranslateVariables();
      this.mapProductToDirectory();
    });
  }

  setTranslateVariables() {
    this.titleAcquired = translate("chest.title-acquired");
    this.descriptionAcquired = translate("chest.description-acquired");
    this.buttonOpen = translate("chest.button-open");
  }

  mapProductToDirectory() {
    this.chest = this.productsService.getProduct(this.data.chest);
  }

  openAndClaim() {
    this.apiChestService
      .playerChestsIdOpenAndClaim(this.chest.player_chest_id)
      .subscribe((resp: ChestClaimResponse) => {
        this.checkAndOpenChestPrizes(resp);
      });
  }

  checkAndOpenChestPrizes(chestClaim: ChestClaimResponse) {
    const fakeMessageDetails: MessageDetailsChestSuccess = {
      generic_type: MESSAGE_DETAILS_TYPE.CHEST_FAILED,
      sender_name: "",
      title: translate("chest.title-failed"),
      category_name: "",
      chest_id: chestClaim.chest_id,
      chest_product_icon: "",
      chest_product_id: 0,
      chest_product_name: "",
      created_at: this.synchronizeTimeService.getActualLocalTimeWithOffset().toISOString(),
      deleted: true,
      description: translate("chest.description-failed"),
      gallery: [],
      mobile_gallery: [],
      parameters: {
        currency_rewards: chestClaim.currency_rewards,
        product_rewards: <ProductChest[]>chestClaim.product_rewards,
        chest_product_icon: this.chest.icon,
      },
      player_chest_id: chestClaim.player_chest_id,
      player_message_id: -1,
      read: true,
      seen: true,
      sender_icon: "",
      teaser: translate("chest.teaser-failed"),
      tip: "",
      qa_info: null,
      type: 1,
      deletable: null,
    };

    if (chestClaim.rewards_count > 0) {
      fakeMessageDetails.generic_type = MESSAGE_DETAILS_TYPE.CHEST_SUCCESS;
      fakeMessageDetails.title = translate("chest.title-success");
      fakeMessageDetails.teaser = translate("chest.teaser-success");
      fakeMessageDetails.description = translate("chest.description-success");
    } else {
      this.store.dispatch(playerFetch({}));
    }

    const eventDialog = this.messageService.handleMessageDetailsComponent(fakeMessageDetails.generic_type);

    const messageOpenDetailsData: MessageOpenDetailsData = {
      message: fakeMessageDetails,
      fromList: false,
    };

    this.eventEmitterDialogsService.emitter.emit({
      name: eventDialog,
      config: {
        data: messageOpenDetailsData,
      },
      callback: (event: MessageEventFromDetails) => {
        this.matDialogDef.close();
      },
    });
  }
}
