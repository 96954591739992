import { Injectable } from "@angular/core";

import { ApiService } from "../../../../../../../core/providers/api.service";

@Injectable({
  providedIn: "root",
})
export class ApiChestService {
  constructor(private apiService: ApiService) {}

  playerChests() {
    return this.apiService.get(`player-chests`);
  }

  playerChestsId(player_chest_id: number) {
    return this.apiService.get(`player-chests/${player_chest_id}`);
  }

  playerChestsIdClaim(player_chest_id: number) {
    return this.apiService.patch(`player-chests/${player_chest_id}/claim`);
  }

  playerChestsIdOpen(player_chest_id: number) {
    return this.apiService.patch(`player-chests/${player_chest_id}/open`);
  }

  playerChestsIdOpenAndClaim(player_chest_id: number) {
    return this.apiService.patch(`player-chests/${player_chest_id}/open-and-claim`);
  }
}
