import { Injectable } from "@angular/core";

import { BuildingsEventDialogsService } from "../../../modules/game/game-ui/buildings/services/core/event-dialogs.service";
import { ChestEventDialogsService } from "../../../modules/game/game-ui/chest/services/core/event-dialogs.service";
import { CurrencyExchangeEventDialogsService } from "../../../modules/game/game-ui/currency-exchange/services/core/event-dialogs.service";
import { DailyQuestionsEventDialogsService } from "../../../modules/game/game-ui/daily-questions/services/core/event-dialogs.service";
import { EventShopEventDialogsService } from "../../../modules/game/game-ui/event-shop/services/core/event-dialogs.service";
import { HudEventDialogsService } from "../../../modules/game/game-ui/hud/services/core/event-dialogs.service";
import { MessageEventDialogsService } from "../../../modules/game/game-ui/message/services/core/event-dialogs.service";
import { MissionEventDialogsService } from "../../../modules/game/game-ui/mission/services/core/event-dialogs.service";
import { QaEventDialogsService } from "../../../modules/game/game-ui/qa/services/core/event-dialogs.service";
import { TransactionHistoryEventDialogsService } from "../../../modules/game/game-ui/transaction-history/services/core/event-dialogs.service";
import { WarehouseEventDialogsService } from "../../../modules/game/game-ui/warehouse/services/core/event-dialogs.service";
import { RegisterCustomEventDialogsService } from "../custom/register-custom-event-dialogs.service";
import { CoreEventDialogsService } from "./core-event-dialogs.service";
import { EventEmitterDialogsService } from "./event-emitter-dialogs.service";

@Injectable({
  providedIn: "root",
})
export class RegisterEventDialogsService {
  constructor(
    private eventEmitterDialogsService: EventEmitterDialogsService,
    private registerCustomEventDialogsService: RegisterCustomEventDialogsService,
    private messageEventDialogsService: MessageEventDialogsService,
    private missionEventDialogsService: MissionEventDialogsService,
    private coreEventDialogsService: CoreEventDialogsService,
    private buildingsEventDialogsService: BuildingsEventDialogsService,
    private warehouseEventDialogsService: WarehouseEventDialogsService,
    private currencyExchangeEventDialogsService: CurrencyExchangeEventDialogsService,
    private transactionHistoryEventDialogsService: TransactionHistoryEventDialogsService,
    private hudEventDialogsService: HudEventDialogsService,
    private qaEventDialogsService: QaEventDialogsService,
    private chestEventDialogsService: ChestEventDialogsService,
    private dailyQuestionsEventDialogsService: DailyQuestionsEventDialogsService,
    private eventShopEventDialogsService: EventShopEventDialogsService
  ) {}
}
