<div
  class="ow-dialog window-d primary no-transparent exchange-confirm-container"
  [ngStyle]="{
    maxWidth: data.style?.maxWidth,
    minWidth: data.style?.minWidth,
    maxHeight: data.style?.maxHeight,
    minHeight: data.style?.minHeight,
  }">
  <!-- TITLE -->
  <p class="title" *ngIf="data">
    {{ data.title ? data.title : ("alert.alert" | myTranslate) }}
  </p>

  <!-- DESCRIPTION -->
  <div class="description">
    Czy napewno chcesz wymienić <m-ui-currency [item]="data.from" [stockView]="STOCK_VIEW.K" [lack]="false"></m-ui-currency> na <m-ui-currency [item]="data.to" [stockView]="STOCK_VIEW.K" [lack]="false"></m-ui-currency>?
    Tej wymiany nie da się cofnąć! Obracanie zbyt dużymi kwotami w kantorze może okazać się niekorzystne.
  </div>

  <!-- CONFIRM -->
  <div class="bottom-bar">
    <!-- CANCEL -->
    <div class="bar-button base">
      <button
        class="base"
        [ngClass]="data.buttonClose?.colorSchema ? data.buttonClose.colorSchema : 'primary'"
        (click)="close(false)">
        {{ data.buttonClose?.label || ("alert.close" | myTranslate) }}
      </button>
    </div>

    <!-- CONFIRM -->
    <div class="bar-button base">
      <button
        class="base"
        [ngClass]="data.buttonSuccess?.colorSchema ? data.buttonSuccess.colorSchema : 'secondary'"
        (click)="close(true)"
        [disabled]="data.buttonSuccess?.disabled || (costsRequirement && !costsRequirement.valid)">
        {{ data.buttonSuccess?.label || ("alert.ok" | myTranslate) }}
      </button>
    </div>
  </div>
</div>
