import { Component, OnInit } from "@angular/core";

import { AbstractChangePasswordComponent } from "../../../../abstract/abstract-change-password.component";

@Component({
  selector: "auth-change-password",
  templateUrl: "./change-password.component.html",
})
export class ChangePasswordComponent extends AbstractChangePasswordComponent implements OnInit {
  ngOnInit() {
    this.baseInit();
  }
}
