import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import moment from "moment";

import { PlayerService } from "../../modules/player/providers/player.service";
import { AppState } from "../../store/state";
import { utilityFetchNewTokenStart } from "../../store/utility/actions";
import { getToken } from "../utility/token";
import { ApiService } from "./api.service";
import { SynchronizeTimeService } from "./synchronize-time.service";

@Injectable({
  providedIn: "root",
})
export class TokenExchangeService {
  static timeout;
  intervalCheckTimeoutSeconds = 60; // 60 sec -> 1 min

  constructor(
    private apiService: ApiService,
    private synchronizeTimeService: SynchronizeTimeService,
    private store: Store<AppState>,
    private playerService: PlayerService
  ) {}

  exchangeTimeoutStart() {
    const refreshAfterDate = getToken().refresh_after;
    if (!refreshAfterDate) {
      return;
    }

    const currentMomentDate = moment(this.synchronizeTimeService.getActualLocalTime());
    const isAfterRefresh = moment(currentMomentDate).isAfter(moment(refreshAfterDate));

    if (isAfterRefresh && this.playerService.getMePlayerId()) {
      this.tokenExpiredAction();
    } else {
      TokenExchangeService.exchangeTimeoutStop();
      TokenExchangeService.timeout = setTimeout(() => {
        this.exchangeTimeoutStart();
      }, this.intervalCheckTimeoutSeconds * 1000);
    }
  }

  exchangeTimeoutStop() {
    clearTimeout(TokenExchangeService.timeout);
  }

  tokenExpiredAction() {
    this.store.dispatch(utilityFetchNewTokenStart());
  }

  exchangeToken() {
    return this.apiService.post("api-token/exchange");
  }

  static exchangeTimeoutStop() {
    clearTimeout(TokenExchangeService.timeout);
  }
}
