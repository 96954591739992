import { ChangeDetectorRef, Directive, ElementRef, Injectable, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { select, Store } from "@ngrx/store";
import moment from "moment";
import { filter, take } from "rxjs/operators";
import { Swiper, SwiperOptions } from "swiper/types";

import { swiperInjectionStyles } from "../../../../../../../styles/swiper/swiper-injection-styles";
import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { translate } from "../../../../../../core/helpers/translate.helper";
import { GlobalService } from "../../../../../../core/providers/global.service";
import { ParametersService } from "../../../../../../core/providers/parameters.service";
import { selectGameTileByTileId } from "../../../../../../store/game/selectors";
import { AppState } from "../../../../../../store/state";
import { PlayerService } from "../../../../../player/providers/player.service";
import { generateEachPages } from "../../../../../shared/helpers/generate-pages.helper";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { GAME_EVENTS } from "../../../../constants";
import { BuildingDetailsConfig } from "../../../../game-engine/interfaces/building-details-config";
import { PlayerBuilding } from "../../../../game-engine/interfaces/player-building.config";
import { BASIC_BOOT_SCENE } from "../../../../game-engine/scenes-basic/basic.constants";
import { ParameterValue } from "../../../../interfaces/parameters";
import { BuildingsService } from "../../../../services/buildings.service";
import { ProductionService } from "../../../../services/production.service";
import { ChangeMapService } from "../../../hud/hud-menu-buttons/services/core/change-map.service";
import { STOCK_VIEW } from "../../../shared-ui/mobile/consts/stock-view.const";
import { BUILDING_TYPES } from "../../consts/core/buidling-types.const";
import { upgradeDifferenceParameters } from "../../helpers/core/upgrade.helpers";
import { BuildingData } from "../../interfaces/core/dialogs/building-data.interface";

@Directive()
@Injectable()
export abstract class AbstractBuildingSpecialComponent extends AbstractInjectBaseComponent {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractBuildingSpecialComponent>;
  @OwInject(MAT_DIALOG_DATA) data: BuildingData;
  @OwInject(BuildingsService) buildingsService: BuildingsService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ProductionService) productionService: ProductionService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(ChangeDetectorRef) changeDetectorRef: ChangeDetectorRef;
  @OwInject(ParametersService) parametersService: ParametersService;
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(ChangeMapService) changeMapService: ChangeMapService;

  @ViewChild("sliderUpgrade") sliderUpgrade;

  BUILDING_TYPES = BUILDING_TYPES;
  STOCK_VIEW = STOCK_VIEW;
  buildingDetails: PlayerBuilding;
  upgradeBuildingDetails: BuildingDetailsConfig;
  boardTile;
  unlockedBuildings: any[];
  differentGroup: boolean;
  requirementsStatus: { valid: boolean; requirementsList: any[] };

  subs = {
    board: null,
  };

  parameters: {
    notOutcome: ParameterValue[];
    outcome: ParameterValue[];
  };

  diffProductionsSlider = {
    config: {
      itemPerPage: 6,
    },
    pages: [],
    items: [],
  };

  diffParametersSlider = {
    config: {
      itemPerPage: 6,
    },
    pages: [],
    items: [],
  };

  initSwiper = false;
  swiper: Swiper = null;
  @ViewChild("swiperRef", { static: false })
  swiperRef: ElementRef | undefined;
  currentSlideIndex = 0;
  config: SwiperOptions = {
    slidesPerView: 1,
    initialSlide: 0,
    centeredSlides: true,
    pagination: {
      dynamicBullets: true,
      clickable: true,
    },
    injectStyles: [swiperInjectionStyles],
  };

  subscribeBoardTile() {
    this.subs.board = this.store
      .pipe(
        select(selectGameTileByTileId(this.data.tileId)),
        filter(state => !!state),
        take(1)
      )
      .subscribe(tile => {
        this.boardTile = tile;
        this.buildingDetails = tile.player_building;
        if (this.buildingDetails.upgrade_building_id) {
          this.getBuildingDetails(this.buildingDetails.upgrade_building_id);
        }
        this.setSwiper();
      });
  }

  goToAnotherScene() {
    if (this.boardTile.open_player_island_id) {
      this.changeMapService.goToIsland(this.boardTile.open_player_island_id);
      this.matDialogRef.close();
    }
  }

  getBuildingDetails(buildingId: number) {
    this.buildingsService.getBuildingDetails(buildingId).subscribe(buildingDetails => {
      this.upgradeBuildingDetails = buildingDetails;
      this.setDifferentGroup();
      this.setParameters();
      this.getUnlockedBuildings();
      this.getUpgradeDifferenceParameters();
      this.setSwiper();
    });
  }

  setParameters() {
    this.parameters = this.parametersService.getParametersToDisplay(this.upgradeBuildingDetails.parameters);
  }

  setDifferentGroup() {
    this.differentGroup = this.buildingDetails.group !== this.upgradeBuildingDetails.group;
  }

  getUnlockedBuildings() {
    switch (this.upgradeBuildingDetails.group_type) {
      case BUILDING_TYPES.NORMAL:
        this.buildingsService.getUnlockedBuildings(this.upgradeBuildingDetails.building_id).subscribe(
          (buildings: any[]) => {
            this.unlockedBuildings = buildings;
            if (this.unlockedBuildings.length === 0) {
              this.unlockedBuildings = null;
            }
            this.setSwiper();
          },
          errResp => {
            if (errResp.status === 404) {
              errResp.defaultHandler.unsubscribe();
            }
          }
        );
        break;
    }
  }

  getUpgradeDifferenceParameters() {
    this.diffParametersSlider.items = [];
    this.diffParametersSlider.pages = [];

    this.diffParametersSlider.items = upgradeDifferenceParameters(
      this.buildingDetails.parameters,
      this.upgradeBuildingDetails.parameters
    );

    this.diffParametersSlider = generateEachPages(this.diffParametersSlider);
  }

  upgrade({ fastUpgrade }: { fastUpgrade?: boolean } = {}) {
    this.buildingsService.upgrade(this.buildingDetails.player_building_id, fastUpgrade).subscribe(() => {
      this.afterUpgradeRequest();
    });
  }

  afterUpgradeRequest() {
    this.matDialogRef.close(true);
  }

  openFastSpecialUpgradeConfirm() {
    let description = translate("fast-action.description");
    if (this.upgradeBuildingDetails.fast_building_time_in_seconds) {
      const time = moment
        .utc(this.upgradeBuildingDetails.fast_building_time_in_seconds * 1000)
        .format("HH[h] mm[m] ss[s]");
      const extendDescription = translate("building-special.fast-action-extend-description", [time]);
      description += extendDescription;
    }

    this.dialogService.openConfirm(
      {
        title: translate("fast-action.title"),
        description,
        costs: {
          separatorTitle: translate("fast-action.separator-title"),
          currencies: this.upgradeBuildingDetails.fast_build_currency_prices,
          products: this.upgradeBuildingDetails.fast_build_product_prices,
        },
      },
      confirm => {
        if (confirm) {
          this.upgrade({ fastUpgrade: true });
        }
      }
    );
  }

  setRequirementsStatus(status) {
    this.requirementsStatus = status;
  }

  setSwiper() {
    this.initSwiper = false;
    this.swiper = null;
    this.changeDetectorRef.detectChanges();
    this.initSwiper = true;
    this.changeDetectorRef.detectChanges();
    if (this.swiperRef?.nativeElement) {
      this.swiper = this.swiperRef.nativeElement.swiper;
      this.swiper.on("slideChange", swiper => {
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  prevSlide() {
    this.swiper.slidePrev();
    this.currentSlideIndex = this.swiper.activeIndex;
  }

  nextSlide() {
    this.swiper.slideNext();
    this.currentSlideIndex = this.swiper.activeIndex;
  }
}
