import { createReducer, on } from "@ngrx/store";

import { loginFailed, loginLogout, loginStart, loginSuccess } from "./actions";

export interface LoginState {
  error?: any;
  isTokenRefreshing: boolean;
  isAuthenticating: boolean;
}

const initialState: LoginState = {
  error: null,
  isTokenRefreshing: false,
  isAuthenticating: false,
};

export const loginReducer = createReducer(
  initialState,
  on(loginStart, (state, action) => {
    return {
      ...state,
      isAuthenticating: true,
    };
  }),
  on(loginSuccess, (state, action) => {
    return {
      ...state,
      isAuthenticating: false,
      error: null,
    };
  }),
  on(loginFailed, (state, action) => {
    return {
      ...state,
      isAuthenticating: false,
      error: action.payload,
    };
  }),
  on(loginLogout, (state, action) => {
    return JSON.parse(JSON.stringify(initialState));
  })
);
