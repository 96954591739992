import { TileTooltipConfig } from "../../interfaces/tile-tooltip-config.interface";

export const TILE_TOOLTIP_CONFIG: TileTooltipConfig = {
  textStyles: {
    color: "#FFF",
    fontFamily: "MikadoPhaser",
    align: "center",
    boundsAlignH: "bottom",
    wordWrapWidth: 200,
    wordWrap: true,
    boundsAlignV: "bottom",
    fontSize: 32,
  },
  scaleFactor: 1,
  maxZoomScaleValue: 1.5,
};
