export const SOCKET_STATUS = {
  CLEAR_STATUS: 0,

  CONNECT: 10,
  CONNECTING: 11,
  CONNECTED: 12,

  AUTHENTICATE: 20,
  AUTHENTICATING: 21,
  AUTHENTICATED: 22,

  DISCONNECT: 30,
  DISCONNECTING: 31,
  DISCONNECTED: 32,

  RECONNECT_ATTEMPT: 40,

  OTHER_SESSION: 50,
  CONNECTION_ERROR: 100,
  DIFFERENT_VERSION_GAME: 999,
};
