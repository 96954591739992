import { Component, OnInit } from "@angular/core";

import { AbstractRegistrationAComponent } from "../../../../../abstract/registration/variant-a/abstract-registration-a.component";

@Component({
  selector: "app-registration-a",
  templateUrl: "./registration-a.component.html",
})
export class RegistrationAComponent extends AbstractRegistrationAComponent implements OnInit {
  ngOnInit() {
    super.ngOnInit();
    this.clearForm();
  }
}
