import { Currency } from "../../../../../../core/interfaces/currency";
import { Scope } from "../../interfaces/custom/scope.interface";

export function setScopePrize(scopes: Scope[]): Currency[] {
  if (scopes && scopes.length) {
    const searchingScope = scopes[scopes.length - 1];

    let currencies: Currency[] = [];
    if (searchingScope) {
      currencies = searchingScope.currency_prizes;
    }

    return currencies;
  }

  return null;
}
