<div class="hud-menu-more-container" (click)="close()">
  <loading></loading>

  <div class="hud-buttons-container">
    <div class="menu-element" *ngFor="let btn of buttons"
         (click)="handleBtnAction(btn)">
      <img [src]="btn.icon | asset: 'ui/menu/left'"/>
      <p>{{btn.tooltip}}</p>
    </div>
  </div>
</div>
