import { Directive, Injectable } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { EventEmitterDialogsService } from "../../../../../../core/services/core/event-emitter-dialogs.service";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { ApiMessageService } from "../../api/core/services/api-message.service";
import { MESSAGE_EVENTS } from "../../consts/core/message-events.const";
import { MESSAGE_TYPE_ID } from "../../consts/core/message-type.const";
import { MessageDetails } from "../../interfaces/core/message-details.interface";
import { MessageOpenDetailsData } from "../../interfaces/core/message-open-details-data.interface";

@Directive()
@Injectable()
export abstract class AbstractMessageDetailsBaseComponent extends AbstractInjectBaseComponent {
  @OwInject(ApiMessageService) apiMessageService: ApiMessageService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractMessageDetailsBaseComponent>;
  @OwInject(MAT_DIALOG_DATA) messageOpenDetailsData: MessageOpenDetailsData;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  MESSAGE_TYPE_ID = MESSAGE_TYPE_ID;
  message: MessageDetails;
  closeEvent: any;

  afterConstructor() {
    this.message = this.messageOpenDetailsData.message;
  }

  afterInit() {
    if (!this.message.seen) {
      this.markSeenRequest().subscribe(
        () => {},
        error => {
          if (error.status === 409) {
            error.defaultHandler.unsubscribe();
          }
        }
      );
    }
  }

  moveToTrashRequest() {
    return this.apiMessageService.deleteMessage({ messageId: this.message.player_message_id });
  }

  markSeenRequest() {
    return this.apiMessageService.markSeen({ messageId: this.message.player_message_id });
  }

  markReadRequest() {
    return this.apiMessageService.markRead({ messageId: this.message.player_message_id });
  }

  moveToTrash() {
    this.moveToTrashRequest().subscribe(async () => {
      await this.close(true);
    });
  }

  async setRead(): Promise<void> {
    if (this.message.read) {
      return;
    }
    try {
      await this.markReadRequest().toPromise();
      this.closeEvent = { action: MESSAGE_EVENTS.MARKED_READ };
      this.afterRequestRead();
    } catch (error) {
      if (error.status === 409) {
        error.defaultHandler.unsubscribe();
      }
    }
  }

  async close(isMessageDeleted?: boolean) {
    await this.setRead();
    if (isMessageDeleted) {
      this.closeEvent = { action: MESSAGE_EVENTS.MOVED_TO_TRASH };
    }
    this.matDialogRef.close(this.closeEvent);
  }

  async closeAll() {
    await this.setRead();
    this.dialogService.closeAll();
  }

  async closeOrCloseAll() {
    if (this.messageOpenDetailsData.fromList) {
      await this.closeAll();
    } else {
      await this.close();
    }
  }

  afterRequestRead() {}
}
