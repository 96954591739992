import {
  ChangeDetectorRef,
  ComponentFactoryResolver,
  Directive,
  Injectable,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { selectPlayer } from "src/app/store/player/selectors";

import { AbstractInjectBaseComponent } from "../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../core/decorators/ow-inject.decorator";
import { setDynamicsComponents } from "../../../../core/helpers/dynamic-component.helper";
import { CurrencyService } from "../../../../core/providers/currency.service";
import { NativeAppService } from "../../../../core/providers/native-app.service";
import { PingService } from "../../../../core/providers/ping.service";
import { playerUpdateTry, playerUpdateUnreadMessages } from "../../../../store/player/actions";
import { AppState } from "../../../../store/state";
import { userUpdateStart } from "../../../../store/user/actions";
import { selectUser } from "../../../../store/user/selectors";
import { AbstractMessageService } from "../../../game/game-ui/message/abstract/core/abstract-message.service";
import { GameService } from "../../../game/services/game.service";
import { GlobalGameEventsHandlerService } from "../../../game/services/global-game-events-handler.service";
import { GuiService } from "../../../game/services/gui.service";
import { PlayerService } from "../../../player/providers/player.service";
import { DynamicTemplateDirective } from "../../../shared/directives/dynamic-template.directive";
import { DialogService } from "../../../shared/providers/dialog.service";
import {
  CORE_DYNAMIC_COMPONENTS,
  CORE_DYNAMIC_COMPONENTS_OTHERS,
} from "../../consts/core/dynamic-components/play-game/dynamic-components.const";
import {
  CUSTOM_DYNAMIC_COMPONENTS,
  CUSTOM_DYNAMIC_COMPONENTS_OTHERS,
} from "../../consts/custom/dynamic-components/play-game/dynamic-components.const";

@Directive()
@Injectable()
export abstract class AbstractPlayGameComponent extends AbstractInjectBaseComponent {
  @OwInject(GameService) gameService: GameService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(CurrencyService) currencyService: CurrencyService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(PingService) pingService: PingService;
  @OwInject(GuiService) guiService: GuiService;
  @OwInject(NativeAppService) nativeAppService: NativeAppService;
  @OwInject(Router) router: Router;
  @OwInject(GlobalGameEventsHandlerService) globalGameEventsHandlerService: GlobalGameEventsHandlerService;
  @OwInject(ComponentFactoryResolver) componentFactoryResolver: ComponentFactoryResolver;
  @ViewChildren(DynamicTemplateDirective) dynamicContainerRefs: QueryList<DynamicTemplateDirective>;
  @OwInject(ChangeDetectorRef) changeDetectorRef: ChangeDetectorRef;
  @ViewChild("appHudTpl", { static: true, read: ViewContainerRef }) appHudTpl: ViewContainerRef;
  abstract messagesService: AbstractMessageService;
  inactivityTimeout: number;

  gameReady$: Observable<boolean>;

  subs = {
    globalEvents: null,
    user: null,
    importantMessages: null,
    other: null,
  };

  dynamicComponentsOthers = { ...CORE_DYNAMIC_COMPONENTS_OTHERS, ...CUSTOM_DYNAMIC_COMPONENTS_OTHERS };

  updateUserOnce() {
    this.store.pipe(select(selectUser), take(1)).subscribe(user => {
      this.store.dispatch(userUpdateStart({ user: user }));
    });
  }

  updatePlayerOnce() {
    this.store.pipe(select(selectPlayer), take(1)).subscribe(player => {
      this.store.dispatch(playerUpdateTry({ player: player }));
    });
  }

  setDynamicsComponents() {
    setDynamicsComponents.bind(this)({
      coreDynamicComponents: CORE_DYNAMIC_COMPONENTS,
      customDynamicComponents: CUSTOM_DYNAMIC_COMPONENTS,
    });
  }

  setDynamicsComponentsOthers() {
    //get all components from const, and add them to playgame view
    Object.keys(this.dynamicComponentsOthers).forEach(key => {
      const component = this.dynamicComponentsOthers[key];
      const dynamicHost = this.dynamicContainerRefs.toArray().find(item => item.ref === component.viewContainerRef);
      const viewContainerRef = dynamicHost.viewContainerRef;
      viewContainerRef.clear();
      viewContainerRef.createComponent(component.component);
    });
    this.changeDetectorRef.detectChanges();
  }
}
