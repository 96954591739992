<div class="global-dialog ow-dialog window-a primary no-transparent">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button></m-ui-back-button>

  <ng-container *ngIf="rank">
    <!-- TOP BAR BASE -->
    <div class="top-bar base">
      <!-- RANK NAME -->
      <div class="title">
        {{ rank.name }}
      </div>
    </div>

    <!-- RANK DETAILS -->
    <div
      class="rank-details-container loading-opacity"
      *ngIf="rankResults"
    >
      <ng-container *ngIf="rankResults.results.length; else noItemsTpl">
        <div class="header">
          <ow-perfect-scrollbar class="height-auto">
            <ranking-business-table
              [rankResults]="rankResults"
              [rank]="rank"
              [columns]="RANK_CONFIG.columns"
              [show]="'header'"
              [filterKey]="activeFilter.filterKey"
            ></ranking-business-table>
          </ow-perfect-scrollbar>
        </div>

        <!-- RANK RESULTS -->
        <ow-perfect-scrollbar [scrollGradient]="rankResults">
          <ranking-business-table
            [rankResults]="rankResults"
            [rank]="rank"
            [columns]="RANK_CONFIG.columns"
            [show]="'results'"
            [filterKey]="activeFilter.filterKey"
          ></ranking-business-table>
        </ow-perfect-scrollbar>

        <!-- MY POSITION -->
        <div class="my-position-container" *ngIf="rankResults.my_position">

          <!-- LABEL -->
          <label>{{ 'ranks.rank-details-game.my-position' | myTranslate }}</label>

          <ow-perfect-scrollbar class="height-auto my-position">
            <ranking-business-table
              [rankResults]="rankResults"
              [rank]="rank"
              [columns]="RANK_CONFIG.columns"
              [show]="'myPosition'"
              [filterKey]="activeFilter.filterKey"
            ></ranking-business-table>
          </ow-perfect-scrollbar>
        </div>
      </ng-container>
      <ng-template #noItemsTpl>
        <div class="flex-center no-items">
          Aktualnie brak graczy spełniających założenia tego rankingu.
        </div>
      </ng-template>

      <div class="filter-container">
        <ng-container *ngFor="let filter of FILTERS">
          <div
            class="each-filter"
            *ngIf="filter.visible"
            (click)="changeFilter(filter)"
          >
            <!-- CHECKBOX -->
            <button
              class="checkbox"
              [class.active]="filter == activeFilter"
            ></button>

            <!-- LABEL -->
            <span>{{ filter.label }}</span>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
