import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";

import { PlayerEffects } from "../../store/player/effects";

@NgModule({
  imports: [
    CommonModule,
    //StoreModule.forFeature('player', playerReducer),
    EffectsModule.forFeature([PlayerEffects]),
  ],
  declarations: [],
})
export class PlayerModule {}
