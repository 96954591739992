import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { GameEngineP3Component } from "./components/game-engine-p3/game-engine-p3.component";
import { GameEnginePceComponent } from "./components/game-engine-pce/game-engine-pce.component";
import { GameEngineMobileModule } from "./mobile/game-engine-mobile.module";

@NgModule({
  imports: [CommonModule, GameEngineMobileModule],
  declarations: [GameEnginePceComponent, GameEngineP3Component],
  exports: [GameEnginePceComponent, GameEngineP3Component],
})
export class GameEngineModule {}
