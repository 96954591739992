import { Component, OnInit } from "@angular/core";

import { AbstractMessageDetailsLevelUpComponent } from "../../../../abstract/core/abstract-message-details-level-up.component";

@Component({
  selector: "app-message-details-level-up",
  templateUrl: "./message-details-level-up.component.html",
})
export class MessageDetailsLevelUpComponent extends AbstractMessageDetailsLevelUpComponent implements OnInit {
  ngOnInit() {
    this.sliderUnlockedBuildingsActiveIndex = 0;
    this.sliderUnlockedProductsActiveIndex = 0;
    this.getUnlocks();
    this.afterInit();
  }
}
