import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";

import { SchemaValidatorService } from "../../../../services/schema-validator/schema-validator.service";
import { ApiOptions } from "../../../interfaces/api-options";
import { ApiService } from "../../../providers/api.service";
import { SynchronizeTimeService } from "../../../providers/synchronize-time.service";

@Injectable({
  providedIn: "root",
})
export class ApiTimesyncService {
  constructor(
    private apiService: ApiService,
    private synchronizeTimeService: SynchronizeTimeService,
    private schemaValidator: SchemaValidatorService
  ) {}

  getTimesync() {
    const options: ApiOptions = {
      sendRequestWithoutToken: true,
    };

    return this.apiService.get("timesync", options).pipe(
      tap(response => {
        this.schemaValidator.validateResponse({
          url: "timesync",
          payload: {},
          response: response,
          schema: "timesyncCore",
        });
      }),
      tap((serverTimeObject: { date: string }) => {
        this.synchronizeTimeService.setActualServerTime(serverTimeObject);
      })
    );
  }
}
