<!-- LOGO -->
<div class="logo"></div>

<loading></loading>

<div class="wrapper">
    <div *ngIf="enabledPlayers" class="container loading-opacity">
        <form>
            <p class="title">{{ "auth.player-choose.title" | myTranslate }}:</p>
            <div *ngFor="let player of enabledPlayers" class="control control-checkbox">
                <div class="form-checkbox">
                    <input type="checkbox" (click)="changePlayer(player)" [checked]="currentPlayer == player" />
                    <span class="form-checkbox-fill">
            <i class="svg-icon square"></i>
            <i class="svg-icon square-check"></i>
          </span>
                </div>

                <label>
                    {{ "auth.player-choose.form.value" | myTranslate: player }}
                </label>
            </div>
        </form>

        <div class="buttons">
            <button class="base primary" (click)="setDefaultPlayer()" [disabled]="!currentPlayer">
                {{ "auth.player-choose.form.button-send" | myTranslate }}
            </button>
        </div>
    </div>
    <a class="contact" [href]="'mailto:' + config?.common?.contact_email">{{ config?.common?.contact_email ?? ''}}</a>
</div>
<div class="copyright-container" *ngIf="config.default_page.copyright_text">
    <p [innerHTML]="config.default_page.copyright_text"></p>
</div>
