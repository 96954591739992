import { createReducer, on } from "@ngrx/store";

import { Primary } from "../../core/interfaces/primary";
import { primaryFetchFailure, primaryFetchStart, primaryFetchSuccess } from "./actions";

export interface PrimaryState {
  isLoading: boolean;
  isLoaded: boolean;
  primary: Primary;
  error: any;
}

const initialState: PrimaryState = {
  isLoading: false,
  isLoaded: false,
  primary: {
    move_building: {
      currency_price: null,
      product_price: null,
    },
    bricks_to_silver: 0,
    add_posts_date_range: [],
    available_quizzes: [],
    available_surveys: [],
    is_available_gallery: false,
    is_available_survey: null,
    currencies: [],
    parameters: [],
    spreads: [],
    products: [],
    gallery_max_upload_size_in_mb: 0,
    stars: [],
    chests: {
      check_and_open: false,
    },
    photo_gallery: {
      show_description: false,
    },
    action_block_dates: [],
  },
  error: null,
};

export const primaryReducer = createReducer(
  initialState,
  on(primaryFetchStart, (state, action) => {
    return {
      ...state,
      isLoading: true,
      isLoaded: false,
    };
  }),
  on(primaryFetchSuccess, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      primary: {
        ...state.primary,
        ...action.primary,
      },
      error: null,
    };
  }),
  on(primaryFetchFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      primary: null,
      error: action.error,
    };
  })
);
