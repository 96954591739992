import { STORAGE_LOCATION_NO_SUPPORTED } from "../../../consts/core/storage/location-no-supported.const";

export class StorageLocationBase {
  storage;
  type;
  options;
  supported;

  constructor({ storage = null, type, options = {} }) {
    this.storage = storage;
    this.type = type;
    this.options = options;

    this.init();
  }

  init() {}

  isSupported() {}

  get(key) {}

  set(key, value, parameters = {}) {}

  remove(key) {}

  clear() {}
}
