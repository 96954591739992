import { TokenObject } from "../../modules/auth/interfaces/token-object";
import { STORAGE_DATA } from "../consts/core/storage/storage-data.const";

const STORAGE_CONFIG = {
  TOKEN: STORAGE_DATA,
};

export function getToken(): TokenObject {
  let token: TokenObject = {
    token: null,
    expired_at: null,
    download_token: null,
    is_impersonate: null,
    reauthorize_after: null,
    refresh_after: null,
  };

  const lsToken = STORAGE_CONFIG.TOKEN.get("token");

  if (lsToken) {
    try {
      token = { ...lsToken };
    } catch (e) {}
  }

  return token;
}

export function setToken(token: TokenObject) {
  let tokenString: string;

  if (token) {
    tokenString = JSON.stringify(token);
  }

  STORAGE_CONFIG.TOKEN.set("token", tokenString, {
    expired_at: token && token.reauthorize_after ? token.expired_at : null,
  });
}

export function removeToken() {
  STORAGE_CONFIG.TOKEN.remove("token");
}
