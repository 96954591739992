import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, mergeMap, tap } from "rxjs/operators";

import { omitErrorResponseHelper } from "../../core/helpers/omit-error-response.helper";
import { Primary } from "../../core/interfaces/primary";
import { CurrencyService } from "../../core/providers/currency.service";
import { ParametersService } from "../../core/providers/parameters.service";
import { PrimaryService } from "../../core/providers/primary.service";
import { ProductsService } from "../../modules/game/services/products.service";
import { ProductPlayerService } from "../../modules/player/providers/product-player.service";
import { AppState } from "../state";
import { primaryFetchFailure, primaryFetchStart, primaryFetchSuccess } from "./actions";

@Injectable()
export class PrimaryEffects {
  constructor(
    private actions$: Actions,
    private primaryService: PrimaryService,
    private store: Store<AppState>,
    private currencyService: CurrencyService,
    private parametersService: ParametersService,
    private productService: ProductsService,
    private productPlayerService: ProductPlayerService
  ) {}

  $fetchStart = createEffect(() =>
    this.actions$.pipe(
      ofType(primaryFetchStart),
      mergeMap(() => {
        return this.fetchPrimary();
      })
    )
  );

  $fetchSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(primaryFetchSuccess),
        tap(action => {
          this.currencyService.setCurrencyDefinitions(action.primary.currencies);
          this.parametersService.setParametersDefinitions(action.primary.parameters);
          this.productPlayerService.setProductsDefinitions(action.primary.products);
          // @TODO:gutyo [remove productService after clean product definitions]
          this.productService.setProductsDefinitions(action.primary.products);
        })
      ),
    { dispatch: false }
  );

  fetchPrimary() {
    return this.primaryService.primary().pipe(
      map((primary: Primary) => {
        return primaryFetchSuccess({ primary: primary });
      }),
      catchError((error: any) => {
        return of(primaryFetchFailure({ error: omitErrorResponseHelper(error) }));
      })
    );
  }
}
