import { Injectable } from "@angular/core";
import * as R from "ramda";
import { BehaviorSubject, Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { PLAYER_TITLES } from "../../../core/consts/core/player-titles";
import { ApiOptions } from "../../../core/interfaces/api-options";
import { ApiService } from "../../../core/providers/api.service";
import { SynchronizeTimeService } from "../../../core/providers/synchronize-time.service";
import { SchemaValidatorService } from "../../../services/schema-validator/schema-validator.service";
import { Player } from "../interfaces/player";
import {Region} from '../../game/interfaces/region.interface';

interface ProductAttribute {
  value: number;
  name: string;
  max_value_per_product?: number;
}

@Injectable({
  providedIn: "root",
})
export class PlayerService {
  /**
   * key player is only me player
   * change if get first time from endpoint or socket player
   */
  player: Player;
  private activePlayerId$: BehaviorSubject<number> = new BehaviorSubject(null);
  private mePlayerId$: BehaviorSubject<number> = new BehaviorSubject(null);
  firstTimeMePlayerSnapshot: Player;
  notDorOrDorNotActive: boolean;
  notPartnerOrPartnerNotActive: boolean;
  isActiveMe: boolean;
  playerRegions: BehaviorSubject<{
    playerId: number;
    regions: Region[];
  }> = new BehaviorSubject(null);

  constructor(
    private apiService: ApiService,
    private synchronizeTimeService: SynchronizeTimeService,
    private schemaValidator: SchemaValidatorService
  ) {}

  getRealTime() {
    return this.synchronizeTimeService.getActualLocalTimeWithOffset();
  }

  checkIsActiveMe(): boolean {
    const activePlayerId = this.getActivePlayerId();
    return this.checkIsActiveMyById(activePlayerId);
  }

  checkIsActiveMyById(id: number) {
    return this.getMePlayerId() === this.getActivePlayerId() && this.getActivePlayerId() === id;
  }

  setActiveMe() {
    this.isActiveMe = this.checkIsActiveMe();
  }

  setActivePlayerId(id: number) {
    this.activePlayerId$.next(id);
  }

  getActivePlayerId(): number {
    return this.activePlayerId$.value;
  }

  setMePlayerId(id: number) {
    this.mePlayerId$.next(id);
  }

  getMePlayerId(): number {
    return this.mePlayerId$.value;
  }

  checkNotDorOrDorNotActive(): boolean {
    return this.player.title_id.id < PLAYER_TITLES.DOR.id ? true : !this.isActiveMe;
  }

  checkNotPartnerOrPartnerNotActive(): boolean {
    return this.player.title_id.id < PLAYER_TITLES.PARTNER.id ? true : !this.isActiveMe;
  }

  playerChange() {
    if (this.player) {
      this.setActiveMe();
      this.notDorOrDorNotActive = this.checkNotDorOrDorNotActive();
      this.notPartnerOrPartnerNotActive = this.checkNotPartnerOrPartnerNotActive();
    }

    if (!this.firstTimeMePlayerSnapshot) {
      this.firstTimeMePlayerSnapshot = R.clone(this.player);
    }
  }

  allowRequiredLevel(level: number): boolean {
    return this.player.level >= level;
  }

  /**
   * API
   */
  getPlayer(playerId) {
    return this.apiService.get(`player/${playerId}`).pipe(
      tap(response => {
        this.schemaValidator.validateResponse({
          url: `player/${playerId}`,
          payload: {},
          response: response,
          schema: "playerPlayer",
        });
      })
    );
  }

  getSettings() {
    const playerId = this.getActivePlayerId();
    return this.apiService.get(`player/${playerId}/settings`);
  }

  updateSettings({
    private_email,
    facebook_email,
    facebook_username,
    business_phone,
  }: {
    private_email?: string;
    facebook_email?: string;
    facebook_username?: string;
    business_phone?: string;
  }): Observable<any> {
    const options = {
      body: {
        private_email,
        facebook_email,
        facebook_username,
        business_phone,
      },
    };

    return this.apiService.post("settings", options);
  }

  patchPlayer({ farm_name }: { farm_name?: string }) {
    const options = {
      body: {
        farm_name,
      },
    };

    return this.apiService.patch("player", options);
  }

  changeRole(title_id: number): Observable<any> {
    const options = {
      body: {
        title_id,
      },
    };

    return this.apiService.post("change-role", options);
  }

  setProfileImage(file: any) {
    const formData = new FormData();
    formData.append("profile_image", file, file.name);

    const options: ApiOptions = {
      body: formData,
      contentTypeAuto: true,
    };

    return this.apiService.post("set-profile-image", options);
  }

  getPlayerProductAttributes(playerId?: number) {
    playerId = playerId || this.getActivePlayerId();
    return this.apiService.get(`player/${playerId}/product-attributes`);
  }

  getAttributeByName(productAttributes: ProductAttribute[], name: string) {
    return productAttributes.find(attribute => attribute.name === name);
  }
}
