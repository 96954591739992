import {Directive, Injectable, Input} from '@angular/core';

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { EventEmitterDialogsService } from "../../../../../../core/services/core/event-emitter-dialogs.service";
import { Player } from "../../../../../player/interfaces/player";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { EVENT_DIALOGS_NAMES_HUD } from "../../const/core/event-dialogs/event-names.const";
import {Store} from '@ngrx/store';
import {PlayerService} from '../../../../../player/providers/player.service';

@Directive()
@Injectable()
export abstract class AbstractHudPlayerComponent extends AbstractInjectBaseComponent {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(PlayerService) playerService: PlayerService;
  @Input() blockDetailsOpen = false;
  @Input() set player(player: Player) {
    this.playerDetails = player;
    this.calcPlayerLevelProgress();
  }
  strokeDashOffset: number;
  valuePerOnePercent = 5.3; // 1% of dasharray, the value comes from the plate split; it has 580 pieces
  playerDetails: Player = null;

  openPlayerDetails() {
    if (this.blockDetailsOpen) {
      return;
    }

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_HUD.PLAYER_DETAILS,
    });
  }

  calcPlayerLevelProgress() {
    let strokeDashOffset = 0;
    const levelPercent = this.playerDetails.level_percent;
    if (Number.isInteger(levelPercent)) {
      strokeDashOffset = 580 - levelPercent * this.valuePerOnePercent;
    }
    this.strokeDashOffset = strokeDashOffset;
  }
}
