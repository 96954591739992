export const CONFIG = {
  apiKey: "AIzaSyAA6P6An3eMJLiv4g80sgZX_7T7HuKIoI0",
  authDomain: "komp-town.firebaseapp.com",
  projectId: "komp-town",
  storageBucket: "komp-town.firebasestorage.app",
  messagingSenderId: "881613018779",
  appId: "1:881613018779:web:df097c4a12f2e8d951d83e",
};

export const VAPIDKEY = "BLd_ZSUc6cMd6THsGJ47H8HPKaTU_XefJWDgi4y4owIkbaezmqBtRsHUVMyxCXl8aZDyxHFc-2t6DHss9zSjCyI";
