<div
  class="ow-dialog window-d primary no-transparent"
  [ngStyle]="{
    maxWidth: data.style?.maxWidth,
    minWidth: data.style?.minWidth,
    maxHeight: data.style?.maxHeight,
    minHeight: data.style?.minHeight,
  }">
  <!-- TITLE -->
  <p class="title" *ngIf="data">
    {{ data.title ? data.title : ("alert.alert" | myTranslate) }}
  </p>

  <!-- DESCRIPTION -->
  <p class="description" *ngIf="data.description" [innerHTML]="data.description"></p>

  <!-- EXPERIENCE - tile buy -->
  <m-ui-exp-up *ngIf="data.exp" [experience]="data.exp"></m-ui-exp-up>

  <ng-container *ngIf="data.costs?.currencies.length || data.costs?.products.length">
    <!-- SEPARATOR TITLE -->
    <m-ui-separator [title]="data.costs.separatorTitle"></m-ui-separator>

    <!-- COSTS -->
    <m-ui-costs
      [currencies]="data.costs.currencies"
      [products]="data.costs.products"
      [stockView]="STOCK_VIEW.D"
      [observable]="true"
      [lack]="data.costs.lack"
      (eventEmitter)="checkCostsRequirements($event)"></m-ui-costs>
  </ng-container>

  <!-- CONFIRM -->
  <div class="bottom-bar">
    <!-- CANCEL -->
    <div class="bar-button base">
      <button
        class="base"
        [ngClass]="data.buttonClose?.colorSchema ? data.buttonClose.colorSchema : 'primary'"
        (click)="close(false)">
        {{ data.buttonClose?.label || ("alert.close" | myTranslate) }}
      </button>
    </div>

    <!-- CONFIRM -->
    <div class="bar-button base">
      <button
        class="base"
        [ngClass]="data.buttonSuccess?.colorSchema ? data.buttonSuccess.colorSchema : 'secondary'"
        (click)="close(true)"
        [disabled]="data.buttonSuccess?.disabled || (costsRequirement && !costsRequirement.valid)">
        {{ data.buttonSuccess?.label || ("alert.ok" | myTranslate) }}
      </button>
    </div>
  </div>
</div>
