import { createFeatureSelector, createSelector } from "@ngrx/store";

import { UtilityPlayer } from "./interfaces/utility-player";
import { UtilityState } from "./reducer";

export const selectUtilityState = createFeatureSelector<UtilityState>("utility");

export const selectUtilityPlayer = createSelector(selectUtilityState, state => state.player);

export const selectUtilityActivePlayerId = createSelector(
  selectUtilityPlayer,
  (state: UtilityPlayer) => state.activePlayerId
);

export const selectUtilityMePlayerId = createSelector(selectUtilityPlayer, (state: UtilityPlayer) => state.mePlayerId);

export const selectUtilityToken = createSelector(selectUtilityState, (state: UtilityState) => state.token);

export const selectUtilityTokenObject = createSelector(selectUtilityToken, state => state.tokenObject);

export const selectUtilityHasMissionsToCollect = createSelector(
  selectUtilityPlayer,
  (state: UtilityPlayer) => state.hasMissionsToCollect
);

export const selectUtilityMissionToOpen = createSelector(
  selectUtilityPlayer,
  (state: UtilityPlayer) => state.missionsToOpen
);

export const selectUtilityNewProductInStorage = createSelector(
  selectUtilityPlayer,
  (state: UtilityPlayer) => state.newProductsInStorage
);

export const selectUtilityHasNewMessagesToRead = createSelector(
  selectUtilityPlayer,
  (state: UtilityPlayer) => state.hasNewMessagesToRead
);

export const selectUtilityInfoMessage = createSelector(selectUtilityState, state => state.infoMessage);
