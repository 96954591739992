import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";

import { ApiService } from "../../../../../../../core/providers/api.service";
import { SchemaValidatorService } from "../../../../../../../services/schema-validator/schema-validator.service";
import { PlayerService } from "../../../../../../player/providers/player.service";

@Injectable({
  providedIn: "root",
})
export class ApiWarehouseService {
  constructor(
    public apiService: ApiService,
    public playerService: PlayerService,
    private schemaValidator: SchemaValidatorService
  ) {}

  warehouseSale() {
    return this.apiService.get(`warehouse/summary`).pipe(
      tap(response => {
        this.schemaValidator.validateResponse({
          url: `warehouse/summary`,
          payload: {},
          response: response,
          schema: "warehouseSaleGame",
        });
      })
    );
  }

  warehouseSaleReceive() {
    return this.apiService.post(`warehouse/receive`);
  }
}
