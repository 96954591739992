import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AbstractInjectBaseComponent } from "src/app/core/abstracts/abstract-inject-base.component";

import { CardTask } from "../../../../interfaces/custom/card-task.interface";

@Component({
  selector: "app-business-task-cards",
  templateUrl: "./task-cards.component.html",
})
export class TaskCardsComponent extends AbstractInjectBaseComponent {
  @Input() cardTasks: CardTask[];
  @Input() currentPage: string = "";
  @Input() currentPageMonthName: string = "";
  @Input() blockNext = true;
  @Input() blockPrev = true;
  @Output() prev: EventEmitter<any> = new EventEmitter();
  @Output() next: EventEmitter<any> = new EventEmitter();

  triggerNext() {
    this.next.emit(true);
  }

  triggerPrev() {
    this.prev.emit(true);
  }
}
