import { createReducer, on } from "@ngrx/store";

import { Player } from "../../modules/player/interfaces/player";
import { playerFetch, playerFetchSuccess, playerUpdate, playerUpdateTry, playerUpdateUnreadMessages } from "./actions";

export interface PlayerState {
  isLoading: boolean;
  player: Player;
}

const initialState: PlayerState = {
  isLoading: false,
  player: null,
};

export const playerReducer = createReducer(
  initialState,
  on(playerFetch, (state, action) => {
    return {
      ...state,
      isLoading: true,
    };
  }),
  on(playerFetchSuccess, (state, action) => {
    return {
      ...state,
      isLoading: false,
    };
  }),
  on(playerUpdateTry, (state, action) => {
    return {
      ...state,
    };
  }),
  on(playerUpdate, (state, action) => {
    return {
      ...state,
      player: action.player,
    };
  }),
  on(playerUpdateUnreadMessages, (state, action) => {
    return {
      ...state,
      player: {
        ...state.player,
        unread_messages: action.messages,
      },
    };
  })
);
