import { HttpRequest } from "@angular/common/http";

import { NOT_ALLOWED_PAIR_REQUESTS } from "../consts/core/not-allowed-pair-requests.const";

export function checkPairRequest(request: HttpRequest<any>, requests: HttpRequest<any>[]) {
  return NOT_ALLOWED_PAIR_REQUESTS.filter(({ blockPartRequestUrl }) => request.url.includes(blockPartRequestUrl)).some(
    ({ partRequestUrl }) => {
      return requests.some(({ url }) => url.includes(partRequestUrl));
    }
  );
}
