<ng-container *ngIf="playerDetails">
  <div class="hud-player-container"
       [matTooltip]="
        'hud-player.level-tooltip' | myTranslate: [playerDetails.points_balance | NumberWithSpaces, playerDetails.next_level_points | NumberWithSpaces]
       "
       matTooltipPosition="right"
       matTooltipShowDelay="300"
       [ngClass]="{'block-details-open': blockDetailsOpen }"
       (click)="playerService.isActiveMe && openPlayerDetails()">
    <div class="level-container">
      <p>{{ playerDetails.level }}</p>
    </div>
    <svg id="circle" viewBox="0 0 200 200">
      <circle
        cx="100"
        cy="100"
        fill="transparent"
        id="bar"
        r="84"
        stroke-width="34"
        stroke-dasharray="580"
        [style.strokeDashoffset]="strokeDashOffset"></circle>
    </svg>
  </div>
</ng-container>
