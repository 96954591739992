import { Injectable, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import moment from "moment";

import { SOCKET_STATUS } from "../../../../core/consts/core/socket.constants";
import { SynchronizeTimeService } from "../../../../core/providers/synchronize-time.service";
import { getToken } from "../../../../core/utility/token";
import { unsubscribeObject } from "../../../../core/utility/unsubscribe-array";
import { socketDisconnect } from "../../../../store/socket/actions";
import { SocketState } from "../../../../store/socket/reducer";
import { selectSocketState } from "../../../../store/socket/selectors";
import { AppState } from "../../../../store/state";
import { AuthService } from "../../api/services/auth.service";
import { AUTH_ROUTING_ABSOLUTE_PATH } from "../../consts/core/routing-auth-absolute.const";

@Injectable({
  providedIn: "root",
})
export class ReauthorizeService implements OnDestroy {
  socketState: SocketState;
  subs = {
    socketState: null,
  };

  constructor(
    private router: Router,
    private authService: AuthService,
    private synchronizeTimeService: SynchronizeTimeService,
    private store: Store<AppState>
  ) {
    this.subscribeSocketState();
  }

  subscribeSocketState() {
    this.subs.socketState = this.store.pipe(select(selectSocketState)).subscribe(socketState => {
      this.socketState = socketState;
    });
  }

  isAllowReauthorize() {
    let isAllowReauthorize = false;

    const reauthorizeAfterDate = this.getReauthorizeAfter();

    if (reauthorizeAfterDate) {
      const currentMomentDate = moment(this.synchronizeTimeService.getActualLocalTime());
      isAllowReauthorize = currentMomentDate.isAfter(moment(reauthorizeAfterDate));
    }

    return isAllowReauthorize;
  }

  getReauthorizeAfter() {
    return getToken().reauthorize_after;
  }

  isTokenRememberMe() {
    return !!getToken().reauthorize_after;
  }

  isSocketClearStatus() {
    return this.socketState.socketStatus === SOCKET_STATUS.CLEAR_STATUS;
  }

  checkReauthorizeAfter() {
    return this.checkLogicReauthorize();
  }

  checkLogicReauthorize() {
    if (this.isAllowReauthorize()) {
      if (this.isSocketClearStatus()) {
        return this.redirectToReauthorizeAndDisconnect();
      } else {
        return this.reauthorize();
      }
    }

    return true;
  }

  redirectToReauthorizeAndDisconnect() {
    this.store.dispatch(socketDisconnect());
    this.router.navigate([AUTH_ROUTING_ABSOLUTE_PATH.REAUTHORIZE]);
    return false;
  }

  reauthorize(): Promise<boolean> {
    return new Promise(resolve => {
      this.authService.reauthorize().subscribe(
        () => {
          resolve(true);
        },
        () => {
          resolve(false);
        }
      );
    });
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
