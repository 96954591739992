<div class="mock-dialog-list">
  <div class="mock-list">
    <button
      class="base primary"
      [disabled]="mockDialogService.mockDataJSON.length === 0 && !mockDialogService.mockDialogRefJSON"
      (click)="openDialog(selectedDialog.key, this.mockDialogService.mockDialogRefJSON)">
      Otwórz okno
    </button>
    <ul class="list-group">
      <li *ngFor="let dialog of dialogs" (click)="selectDialog(dialog)">
        {{ dialog.name }}
        <div class="circle" [class.circle-disable]="!dialog.available"></div>
      </li>
    </ul>
  </div>
  <div class="json-editor">
    <button class="base primary store-button" (click)="this.showMockStore = !this.showMockStore">STORE</button>
    <label class="mock-checkbox">
      <input type="checkbox" [(ngModel)]="mockDialogService.isMocked" />
      <span>Dane Mockowe</span>
    </label>
    <p>MAT DIALOG REF</p>
    <div class="editor-container">
      <textarea
        [(ngModel)]="mockDialogService.mockDialogRefJSON"
        [disabled]="!mockDialogService.mockDialogRefJSON || mockDialogService.isMockDialogRefDisabled"></textarea>
    </div>
    <ng-container
      *ngIf="mockDialogService.mockDataJSON.length > 0 && mockDialogService.mockDataJSON[0].key !== MOCK_BOARD_KEY">
      <p>API RESPONSE</p>
      <div class="editor-container">
        <div class="editor-container-response" *ngFor="let apiRes of this.mockDialogService.mockDataJSON" r>
          {{ apiRes.key }}
          <textarea [(ngModel)]="apiRes.data"></textarea>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<app-mock-store-data *ngIf="showMockStore" (close)="this.showMockStore = false"> </app-mock-store-data>
