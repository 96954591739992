<!-- SEPARATOR -->
<m-ui-separator *ngIf="isShowSeparator" [title]="separatorTitle" [class.prize-reduction]="qa.prize_reduction !== null"></m-ui-separator>

<div class="score-and-prizes" #scoreAndPrizes>
  <!-- SCORE AND TIME -->
  <m-ui-stock-other
    *ngIf="qa.type == QA_TYPE.QUIZ && answeringTime"
    [item]="{ icon: 'time' }"
    [other]="{ amount: answeringTime }"
    [stockView]="STOCK_VIEW.B"
    [lack]="false"></m-ui-stock-other>

  <m-ui-stock-other
    *ngIf="qa.type == QA_TYPE.QUIZ && scoreText"
    [item]="{ icon: 'check' }"
    [other]="{ amount: scoreText }"
    [stockView]="STOCK_VIEW.B"
    [lack]="false"></m-ui-stock-other>

  <!-- PRIZES FOR QA -->
  <ng-container *ngIf="qa.prize_reduction === null && (!qa.required_threshold || (qa.required_threshold && !qa.last_attempt?.finished_at))">
    <m-ui-currency *ngFor="let prize of qa.currency_prizes" [item]="prize" [stockView]="STOCK_VIEW.B" [lack]="false"></m-ui-currency>

    <m-ui-product *ngFor="let prize of qa.product_prizes" [item]="prize" [stockView]="STOCK_VIEW.B" [lack]="false"></m-ui-product>
  </ng-container>

  <!-- PRIZES REDUCTION FOR ANSWERS -->
  <ng-container *ngIf="qa.prize_reduction !== null && (!qa.last_attempt || !qa.last_attempt?.finished_at)">
    <ng-container *ngIf="qa.prize_reduction as prizes">
      <div class="container-prizes loading-opacity">
        <!-- CURRENT PRIZES -->
        <div class="box">
          <span class="title">
            <ng-container>
              {{ prizes.next_prizes ? ('qa.qa-summary.current' | myTranslate) : ('qa.qa-summary.possible' | myTranslate)
              }}{{ "qa.qa-summary.max-prize" | myTranslate }}
            </ng-container>
          </span>
          <div class="prizes">
            <m-ui-currency
              *ngFor="let prize of prizes.current_prizes.currency_prizes"
              [item]="prize"
              [stockView]="STOCK_VIEW.B"
              [lack]="false"></m-ui-currency>

            <m-ui-product
              *ngFor="let prize of prizes.current_prizes.product_prizes"
              [item]="prize"
              [stockView]="STOCK_VIEW.B"
              [lack]="false"></m-ui-product>
          </div>

          <div class="valid-time" *ngIf="prizes.next_prizes">
            <span class="title">{{"qa.qa-summary.prize-validity" | myTranslate}}</span>
            <br />
            <span class="timer" *ngIf="!isLoading && prizeReductionTimer">
              {{ prizeReductionTimer | toHour }}
            </span>
          </div>
        </div>

        <!-- NEXT PRIZES -->
        <div class="box" *ngIf="prizes.next_prizes">
          <span class="title">{{"qa.qa-summary.next-reward" | myTranslate}}</span>

          <div class="prizes">
            <m-ui-currency
              *ngFor="let prize of prizes.next_prizes.currency_prizes"
              [item]="prize"
              [stockView]="STOCK_VIEW.B"
              [lack]="false"></m-ui-currency>

            <m-ui-product
              *ngFor="let prize of prizes.next_prizes.product_prizes"
              [item]="prize"
              [stockView]="STOCK_VIEW.B"
              [lack]="false"></m-ui-product>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <!-- PRIZES FOR QA WITH MIN THRESHOLD -->
  <ng-container *ngIf="qa.required_threshold && qa.last_attempt?.finished_at">
    <m-ui-currency
      *ngFor="let prize of qa.last_attempt.currency_prizes_for_finish"
      [item]="prize"
      [stockView]="STOCK_VIEW.B"
      [lack]="false"></m-ui-currency>

    <m-ui-product
      *ngFor="let prize of qa.last_attempt.product_prizes_for_finish"
      [item]="prize"
      [stockView]="STOCK_VIEW.B"
      [lack]="false"></m-ui-product>
  </ng-container>

  <!-- PRIZES FOR THE RIGHT ANSWER -->
  <ng-container *ngIf="qa.last_attempt">
    <m-ui-currency
      *ngFor="let prize of qa.last_attempt.currency_prizes_for_answers"
      [item]="prize"
      [stockView]="STOCK_VIEW.B"
      [lack]="false"></m-ui-currency>

    <m-ui-product
      *ngFor="let prize of qa.last_attempt.product_prizes_for_answers"
      [item]="prize"
      [stockView]="STOCK_VIEW.B"
      [lack]="false"></m-ui-product>
  </ng-container>
</div>
