<div class="global-dialog ow-dialog window-a primary">
  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <loading></loading>

  <ng-container *ngIf="showBaseActions">
    <!-- BACK BAR -->
    <div class="back-bar">
      <button
        class="square normal primary back"
        (click)="toggleBaseActions()"
      >
        <i class="svg-icon chevron-left"></i>
      </button>
    </div>
    <ng-container [ngTemplateOutlet]="baseActionTpl"></ng-container>
  </ng-container>

  <ng-container *ngIf="!showBaseActions">
    <!-- MENU RIGHT -->
    <div class="menu-right">
      <button
        class="square normal primary list no-before-shadow"
        (click)="toggleBaseActions()"
      >
        <i class="svg-icon list-regular"></i>
      </button>
    </div>
    <ng-container [ngTemplateOutlet]="dailyBasicActionsTpl"></ng-container>
  </ng-container>
</div>

<!-- BASE ACTIONS TEMPLATE -->
<ng-template #baseActionTpl>
  <div class="title loading-opacity">
    {{ 'business.basic-actions.current.title' | myTranslate }}
  </div>

  <ow-perfect-scrollbar scrollGradient *ngIf="basicActionCurrent && basicActionCurrent.length">
    <table>
      <thead>
      <tr>
        <th>{{ 'business.basic-actions.basic-action' | myTranslate }}</th>
        <th class="no-wrap">{{ 'business.basic-actions.reward' | myTranslate }}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of basicActionCurrent">
        <td class="full-width" [class.sticky]="item.sticky_order !== null">
          <ng-container *ngIf="item.sticky_order !== null">
            <i class="svg-icon star-regular"></i>
          </ng-container>
          {{ item.name }}
        </td>
        <td class="no-wrap">
          <div class="prizes">
            <div class="flex-center prize" *ngFor="let prize of item.currency_prizes">
              <m-ui-currency
                [item]="prize"
                [lack]="false"
                [stockView]="STOCK_VIEW.J"
              ></m-ui-currency>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </ow-perfect-scrollbar>
</ng-template>

<!-- DAILY BASIC ACTIONS TEMPLATE -->
<ng-template #dailyBasicActionsTpl>
  <div class="title loading-opacity">
    {{ 'business.basic-actions.details.title' | myTranslate }}
  </div>

  <div
    class="description loading-opacity"
    [innerHTML]="'business.basic-actions.details.description' | myTranslate:[limitPrize]"
  ></div>

  <ng-container *ngIf="basicActionDetails && basicActionDetails.items.length">
    <div class="navigate-container loading-opacity">
      <div class="prev-container">
        <div class="flex-center" *ngIf="basicActionDetails.previous">
          <button
            class="svg-icon chevron-left"
            (click)="getBasicActionDetails(basicActionDetails.previous)"
          >
            <i class="svg-icon chevron-left"></i>
          </button>
          <span class="date">{{ basicActionDetails.previous }}</span>
        </div>
      </div>

      <div
        class="current"
        [innerHTML]="'business.basic-actions.details.action-per-day-calc' | myTranslate:[basicActionDetails.items[0].date]"
      >
      </div>

      <div class="next-container">
        <div class="flex-center" *ngIf="basicActionDetails.next">
          <span class="date">{{ basicActionDetails.next }}</span>
          <button
            class="svg-icon chevron-right"
            (click)="getBasicActionDetails(basicActionDetails.next)"
          >
            <i class="svg-icon chevron-right"></i>
          </button>
        </div>
      </div>
    </div>

    <ow-perfect-scrollbar scrollGradient *ngIf="basicActionDetails.items.length" class="loading-opacity">
      <table>
        <thead>
        <tr>
          <th>{{ 'business.basic-actions.basic-action' | myTranslate }}</th>
          <th class="no-wrap">{{ 'business.basic-actions.reward' | myTranslate }}</th>
          <th class="no-wrap">{{ 'business.basic-actions.details.action-number' | myTranslate }}</th>
          <th class="no-wrap">{{ 'business.basic-actions.details.receive' | myTranslate }}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of basicActionDetails.items">
          <td class="full-width" [class.sticky]="item.basic_action.sticky_order !== null">
            <ng-container *ngIf="item.basic_action.sticky_order !== null">
              <i class="svg-icon star-regular"></i>
            </ng-container>
            {{ item.basic_action.name }}
          </td>
          <td class="no-wrap">
            <div class="prizes">
              <div class="flex-center prize" *ngFor="let prize of item.prizes">
                <m-ui-currency
                  [item]="prize"
                  [lack]="false"
                  [stockView]="STOCK_VIEW.J"
                ></m-ui-currency>
              </div>
            </div>
          </td>
          <td class="no-wrap border-color">{{ item.prizes.length ? item.prizes[0].quantity : '-' }}</td>
          <td class="no-wrap">
            <div class="prizes">
              <div class="flex-center prize" *ngFor="let prize of item.prizes">
                <m-ui-currency
                  [item]="prize"
                  [lack]="false"
                  [stockView]="STOCK_VIEW.J"
                ></m-ui-currency>
              </div>
            </div>
          </td>
        </tr>
        <tr class="summary">
          <td colspan="2" class="line-height-v1">
            {{ 'business.basic-actions.details.sum-per-day' | myTranslate }} {{ basicActionDetails.items[0].date }}
            <!-- LIMIT INFO -->
            <span class="limit-info" *ngIf="basicActionDetails.monthly_income >= 8000">
              <br/>
              {{ 'business.basic-actions.details.limit-reached' | myTranslate:[limitPrize] }}
            </span>
          </td>
          <td class="border-color" *ngIf="quantitySums.length">
            {{ quantitySums[0].value }}
          </td>
          <td class="no-wrap">
            <div class="prizes">
              <div class="flex-center prize" *ngFor="let prize of amountSums">
                <m-ui-currency
                  [item]="prize"
                  [lack]="false"
                  [stockView]="STOCK_VIEW.J"
                ></m-ui-currency>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </ow-perfect-scrollbar>
  </ng-container>
</ng-template>
