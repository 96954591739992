import { isoToScreen } from "../../utils/utils";
import { MyScene } from "./MyScene";

export class IsoSprite extends Phaser.GameObjects.Sprite {
  public isoX: number;
  public isoY: number;
  depthIndex: number;
  customData: any = {};

  constructor(params) {
    super(params.scene as MyScene, params.x, params.y, params.key, params.frame);

    this.isoX = params.x;
    this.isoY = params.y;
    this.depthIndex = params.depthIndex;

    params.scene.add.existing(this);

    const screenPos = isoToScreen(this.isoX, this.isoY);

    this.x = screenPos.x;
    this.y = screenPos.y;

    this.depth = this.y;
  }
}
