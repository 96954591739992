import {Component, Input} from '@angular/core';
import { AbstractHudPlayerComponent } from "../../../../abstract/core/abstract-hud-player.component";

@Component({
  selector: "app-hud-player-with-photo",
  templateUrl: "./hud-player-with-photo.component.html",
})
export class HudPlayerWithPhotoComponent extends AbstractHudPlayerComponent {
  @Input() changeProfileImage = false;

  changeImage(event: Event) {
    const eventObj: any = event;
    const target = eventObj.target;
    const files: FileList = target.files;
    const file = files[0];
    if (file) {
      this.setProfileImage(file);
    }
  }

  setProfileImage(file) {
    this.playerService.setProfileImage(file).subscribe(() => {});
  }
}
