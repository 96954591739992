import { createAction, props } from "@ngrx/store";

import { Player } from "../../modules/player/interfaces/player";

export enum ActionTypes {
  FETCH_PLAYER = "[player] fetch player",
  FETCH_PLAYER_SUCCESS = "[player] fetch player success",
  TRY_UPDATE_PLAYER = "[player] try update player",
  UPDATE_PLAYER = "[player] update player",
  UPDATE_UNREAD_MESSAGES = "[player] update unread messages",
  NULL_ACTION = "[user] null action",
}

export const playerFetch = createAction(ActionTypes.FETCH_PLAYER, props<{ playerId?: number }>());
export const playerFetchSuccess = createAction(ActionTypes.FETCH_PLAYER_SUCCESS);
export const playerUpdateTry = createAction(ActionTypes.TRY_UPDATE_PLAYER, props<{ player: Player }>());
export const playerUpdate = createAction(ActionTypes.UPDATE_PLAYER, props<{ player: Player }>());
export const playerUpdateUnreadMessages = createAction(ActionTypes.UPDATE_UNREAD_MESSAGES, props<{ messages: any }>());
export const playerNullAction = createAction(ActionTypes.NULL_ACTION, props<any>());
