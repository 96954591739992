import { Component, OnDestroy, OnInit } from "@angular/core";

import { AbstractQaListComponent } from "../../../../abstract/core/abstract-qa-list.component";

@Component({
  selector: "app-m-qa-list",
  templateUrl: "./qa-list.component.html",
})
export class MQaListComponent extends AbstractQaListComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.baseInit();

    this.setNextPrizeTimer();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
