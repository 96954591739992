import { createAction, props } from "@ngrx/store";

import { TokenObject } from "../../modules/auth/interfaces/token-object";
import { Mission } from "../../modules/game/game-ui/mission/interfaces/core";

export enum ActionTypes {
  UPDATE_ACTIVE_PLAYER_ID = "[utility] update active player id",
  UPDATE_ME_PLAYER_ID = "[utility] update me player id",
  FETCH_NEW_TOKEN_START = "[utility] fetch new token start",
  FETCH_NEW_TOKEN_FINISH = "[utility] fetch new token finish",
  SET_TOKEN = "[utility] set token",
  REMOVE_TOKEN = "[utility] clear token",
  SET_HAS_MISSIONS_TO_COLLECT = "[utility] set has missions to collect",
  ADD_MISSION_TO_OPEN = "[utility] add mission to open",
  REMOVE_MISSION_TO_OPEN = "[utility] remove mission to open",
  OPEN_MISSION = "[utility] open mission",
  ADD_NEW_PRODUCTS_IN_STORAGE = "[utility] new products in storage",
  CLEAR_NEW_PRODUCTS_IN_STORAGE = "[utility] clear products in storage",
  SET_HAS_NEW_MESSAGES_TO_READ = "[utility] set has new messages to read",
  CLEAR_NEW_MESSAGES_TO_READ = "[utility] clear new messages to read",
  ERROR_PRELOAD_ASSETS_MAP = "[utility] error preload assets map",
  DIFFERENT_VERSION_GAME = "[utility] different version game",
}

export const utilityUpdateActivePlayerId = createAction(
  ActionTypes.UPDATE_ACTIVE_PLAYER_ID,
  props<{ playerId: number }>()
);
export const utilityUpdateMePlayerId = createAction(ActionTypes.UPDATE_ME_PLAYER_ID, props<{ playerId: number }>());

export const utilityFetchNewTokenStart = createAction(ActionTypes.FETCH_NEW_TOKEN_START);

export const utilityFetchNewTokenFinish = createAction(ActionTypes.FETCH_NEW_TOKEN_FINISH);

export const utilitySetToken = createAction(ActionTypes.SET_TOKEN, props<{ token: TokenObject }>());

export const utilityRemoveToken = createAction(ActionTypes.REMOVE_TOKEN);

export const utilitySetHasMissionsToCollect = createAction(
  ActionTypes.SET_HAS_MISSIONS_TO_COLLECT,
  props<{ setMissionToCollect: boolean }>()
);

export const utilityAddMissionToOpen = createAction(
  ActionTypes.ADD_MISSION_TO_OPEN,
  props<{ mission: Partial<Mission> }>()
);

export const utilityRemoveMissionToOpen = createAction(
  ActionTypes.REMOVE_MISSION_TO_OPEN,
  props<{ mission: Partial<Mission> }>()
);

export const utilityOpenMission = createAction(ActionTypes.OPEN_MISSION);

export const utilityAddNewProductsInStorage = createAction(
  ActionTypes.ADD_NEW_PRODUCTS_IN_STORAGE,
  props<{ products: { product_category_id: number }[] }>()
);

export const utilityClearNewProductsInStorage = createAction(ActionTypes.CLEAR_NEW_PRODUCTS_IN_STORAGE);

export const utilitySetHasNewMessagesToRead = createAction(
  ActionTypes.SET_HAS_NEW_MESSAGES_TO_READ,
  props<{ setHasNewMessagesToRead: boolean }>()
);

export const utilityClearNewMessagesToRead = createAction(ActionTypes.CLEAR_NEW_MESSAGES_TO_READ);

export const utilityErrorPreloadAssetsMap = createAction(ActionTypes.ERROR_PRELOAD_ASSETS_MAP);

export const utilityDifferentVersionGame = createAction(ActionTypes.DIFFERENT_VERSION_GAME);
