<div class="global-dialog ow-dialog window-a primary no-transparent" [ngClass]="activeTemplate">
  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button
    *ngIf="data.fromList && activeTemplate === TEMPLATES.QUESTION_DETAILS"
    [preventDefault]="true"
    (clicked)="backToList()"></m-ui-back-button>

  <m-ui-back-button
    [preventDefault]="true"
    (clicked)="changeTemplate(TEMPLATES.QUESTION_DETAILS)"
    *ngIf="activeTemplate === TEMPLATES.QUESTION_AND_ANSWERS"></m-ui-back-button>

  <loading></loading>

  <!-- TOP BAR BASE -->
  <div class="top-bar base">
    <div class="title">Pytanie codzienne</div>
  </div>

  <ng-container [ngSwitch]="activeTemplate">
    <ng-container *ngSwitchCase="TEMPLATES.QUESTION_DETAILS" [ngTemplateOutlet]="questionDetailsTpl"></ng-container>
    <ng-container
      *ngSwitchCase="TEMPLATES.QUESTION_AND_ANSWERS"
      [ngTemplateOutlet]="questionAndAnswersTpl"></ng-container>
  </ng-container>
</div>

<!-- QUESTION DETAILS TEMPLATE -->
<ng-template #questionDetailsTpl>
  <div class="question-container loading-container">
    <ng-container *ngIf="!question.is_expired && !question.answered_at">
      <!-- TEXT -->
      <p class="title">Odpowiedz na pytanie i zdobądź wirtualną walutę niezbędną do rozbudowy Twojego miasta.</p>

      <div class="separator"></div>

      <!-- TIMER -->
      <div class="timer">
        {{ question.diffTimeSeconds | toHour }}
      </div>
    </ng-container>

    <!-- TEXT -->
    <p class="title" *ngIf="question.answered_at && question.is_correct">
      Gratulacje!<br />
      To poprawna odpowiedź - zdobywasz premię!<br />
    </p>

    <!-- TEXT -->
    <p class="title" *ngIf="question.answered_at && !question.is_correct">
      Ups! Błędna odpowiedź.<br />
      Nie udało Ci się zdobyć premii.
    </p>

    <div class="separator"></div>

    <!-- PRIZES -->
    <ng-container *ngIf="!question.answered_at">
      <p class="title">Premia do zdobycia:</p>

      <div class="flex-center prizes">
        <m-ui-currency
          *ngFor="let currency of question.prizes.currency_prizes"
          [item]="currency"
          [lack]="false"
          [stockView]="STOCK_VIEW.A"></m-ui-currency>

        <m-ui-product
          *ngFor="let product of question.prizes.product_prizes"
          [item]="product"
          [lack]="false"
          [stockView]="STOCK_VIEW.A"
          [lockOpenStorage]="true"></m-ui-product>
      </div>
    </ng-container>

    <!-- PLAYER PRIZES -->
    <ng-container *ngIf="question.answered_at">
      <p class="title">Zdobyta premia:</p>

      <div class="flex-center prizes" *ngIf="question.is_correct; else emptyPlayerPrizesTpl">
        <m-ui-currency
          *ngFor="let currency of question.player_prizes.currency_prizes"
          [item]="currency"
          [lack]="false"
          [stockView]="STOCK_VIEW.A"></m-ui-currency>

        <m-ui-product
          *ngFor="let product of question.player_prizes.product_prizes"
          [item]="product"
          [lack]="false"
          [stockView]="STOCK_VIEW.A"
          [lockOpenStorage]="true"></m-ui-product>
      </div>

      <ng-template #emptyPlayerPrizesTpl>
        <p class="title margin-1">brak</p>
      </ng-template>
    </ng-container>
  </div>

  <div class="bottom-bar" *ngIf="!question.is_expired || (question.is_expired && question.answered_at)">
    <div class="bar-button base">
      <button class="base primary" (click)="changeTemplate(TEMPLATES.QUESTION_AND_ANSWERS)">
        {{ question.answered_at ? "Sprawdź odpowiedź" : "Otwórz pytanie" }}
      </button>
    </div>

    <ng-container *ngIf="question.answered_at || question?.show_attachment_before || question?.show_explanation_before">
      <!-- ATTACHMENT -->
      <div class="bar-button base" *ngIf="question.attachment || question?.show_attachment_before">
        <a [href]="question.attachment" target="_blank">
          <button class="base secondary">Dowiedz się więcej</button>
        </a>
      </div>

      <!-- EXPLANATION -->
      <div class="bar-button base" *ngIf="question.explanation || question?.show_explanation_before">
        <button (click)="openExplanation()" class="base secondary">Dowiedz się więcej</button>
      </div>
    </ng-container>
  </div>
</ng-template>

<!-- QUESTION AND ANSWERS TEMPLATE -->
<ng-template #questionAndAnswersTpl>
  <div class="question-container loading-opacity">
    <!--QUESTION-->
    <div class="question-title">
      <div class="question-image" *ngIf="question.image_url">
        <a
          [href]="'https://cdn.photoswipe.com/photoswipe-demo-images/photos/1/img-2500.jpg'"
          data-pswp-width="1875"
          data-pswp-height="2500"
          target="_blank">
          <img [src]="'https://cdn.photoswipe.com/photoswipe-demo-images/photos/1/img-2500.jpg'" alt="" />
        </a>
      </div>
      <p class="title" [innerHTML]="question.title"></p>
    </div>
    <div class="answers-container">
      <ow-perfect-scrollbar scrollGradient>
        <!-- ANSWERS -->
        <div class="elem-flex question" *ngFor="let answer of question.answers">
          <button
            *ngIf="!question.answered_at"
            class="square small checkbox no-before-shadow"
            (click)="actionAnswer(question, answer)">
            <i *ngIf="answer.answer_id === question.selected_answer?.answer_id" class="fas fa-square"></i>
          </button>
          <a *ngIf="answer.image_url"
            href="https://cdn.photoswipe.com/photoswipe-demo-images/photos/1/img-2500.jpg"
            data-pswp-width="1875"
            data-pswp-height="2500"
            target="_blank">
            <img src="https://cdn.photoswipe.com/photoswipe-demo-images/photos/1/img-200.jpg" alt="" />
          </a>
          <button *ngIf="question.answered_at" class="square small checkbox no-before-shadow">
            <i
              class="fas"
              [class.fa-times]="!answer.is_correct && answer.is_chosen"
              [class.fa-check]="answer.is_correct"
              [class.selected-correct]="answer.is_chosen"></i>
          </button>

          <!-- ANSWER DESCRIPTION -->
          <p>
            {{ answer.description }}
          </p>
        </div>
      </ow-perfect-scrollbar>
    </div>
  </div>

  <div class="bottom-bar" *ngIf="!question.answered_at">
    <div class="bar-button base">
      <button class="primary base" (click)="endQuestion(question)" [disabled]="!question.selected_answer">
        Wyślij odpowiedź
      </button>
    </div>
  </div>
</ng-template>
