import { TILE_HOVER_CONFIG } from "../classes/tile-hover/tile-hover.config";
import { TILE_MENU_CONFIG } from "../classes/tile-menu/tile-menu.config";
import { TILE_TOOLTIP_CONFIG } from "../classes/tile-tooltip/tile-tooltip.config";
import { WORLD_SCENE_CONFIG_KEY } from "../consts/core/game-map.const";
import { SceneConfig } from "../interfaces/scene-config.interface";
import { MAIN_SCENE_WORLD_HEIGHT, MAIN_SCENE_WORLD_WIDTH } from "./main.constants";

export const MAIN_SCENE_CONFIG: SceneConfig = {
  backgroundImages: [
    {
      filename: "map.jpg",
      key: "map",
      origin: {
        x: 0,
        y: 0,
      },
      displayWidth: MAIN_SCENE_WORLD_WIDTH,
      displayHeight: MAIN_SCENE_WORLD_HEIGHT,
      x: 0,
      y: 0,
    },
  ],
  backgroundColor: "#71aee3",
  tiledJsonPrefix: "island",
  mapDirectoryName: "world",
  boardStartOffset: {
    x: 0,
    y: 0,
  },
  tiledStartOffset: {
    x: 0,
    y: 0,
  },
  mapAtlasDirectory: "map",
  tileMenuAtlasDirectory: "tile-menu",
  islandGroupIdForBoard: "B",
  tiledMapFileName: "map",
  yAxisSign: -1,
  cameraBounds: {
    x: -5000,
    y: 0,
    width: 10000,
    height: 7000,
  },
  cameraStartPosition: {
    x: 5000,
    y: 3500,
  },
  gui_unlocks_button: WORLD_SCENE_CONFIG_KEY, // this is needed for proper hud menu buttons display, please set key in custom config
  configKeyName: WORLD_SCENE_CONFIG_KEY, // this is needed for proper hud menu buttons display, please set key in custom config
  tileMenuConfig: { ...TILE_MENU_CONFIG },
  tileHoverConfig: { ...TILE_HOVER_CONFIG },
  tileTooltipConfig: { ...TILE_TOOLTIP_CONFIG },
};
