import { Component, OnInit } from "@angular/core";

import { AbstractRegistrationConfirmAComponent } from "../../../../../abstract/registration-confirm/variant-a/abstract-registration-confirm-a.component";

@Component({
  selector: "app-registration-confirm-a",
  templateUrl: "./registration-confirm-a.component.html",
})
export class RegistrationConfirmAComponent extends AbstractRegistrationConfirmAComponent implements OnInit {
  ngOnInit() {
    super.ngOnInit();
    this.subscribeRoutingParams();
  }
}
