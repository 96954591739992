import { Directive, Injectable } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { select, Store } from "@ngrx/store";
import { filter } from "rxjs/operators";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { ParametersService } from "../../../../../../core/providers/parameters.service";
import { selectGameTileByTileId } from "../../../../../../store/game/selectors";
import { AppState } from "../../../../../../store/state";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { BuildingDetailsConfig } from "../../../../game-engine/interfaces/building-details-config";
import { ParameterValue } from "../../../../interfaces/parameters";
import { BuildingsService } from "../../../../services/buildings.service";
import { STOCK_VIEW } from "../../../shared-ui/mobile/consts/stock-view.const";
import { BuildingData } from "../../interfaces/core/dialogs/building-data.interface";

@Directive()
@Injectable()
export abstract class AbstractBuildingInfoComponent extends AbstractInjectBaseComponent {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractBuildingInfoComponent>;
  @OwInject(MAT_DIALOG_DATA) data: BuildingData;
  @OwInject(BuildingsService) buildingsService: BuildingsService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(ParametersService) parametersService: ParametersService;

  STOCK_VIEW = STOCK_VIEW;
  buildingDetails: BuildingDetailsConfig;

  subs = {
    board: null,
  };

  parameters: {
    notOutcome: ParameterValue[];
    outcome: ParameterValue[];
  };

  subscribeBoardTile() {
    this.subs.board = this.store
      .pipe(
        select(selectGameTileByTileId(this.data.tileId)),
        filter(state => !!state)
      )
      .subscribe(tile => {
        this.getPlayerBuildingDetails(tile.player_building.player_building_id);
      });
  }

  getPlayerBuildingDetails(playerBuildingId: number) {
    this.buildingsService.getPlayerBuildingDetails(playerBuildingId).subscribe(buildingDetails => {
      this.buildingDetails = buildingDetails;
      this.afterBuildingDetails();
    });
  }

  afterBuildingDetails() {
    this.setParameters();
  }
  setParameters() {
    this.parameters = this.parametersService.getParametersToDisplay(this.buildingDetails.parameters);
  }
}
