import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";
import moment from "moment";
import { filter, map } from "rxjs/operators";

import { selectConfigState } from "../store/config/selectors";

export const InitialConfigGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const store: Store = inject(Store);
  const router: Router = inject(Router);

  return store.select(selectConfigState).pipe(
    filter(x => x.loaded),
    map(res => {
      const isGameLaunched = moment().isAfter(moment(res.applicationConfig.landing_page.start_game_at));
      const isLandingPageEnabled = res.applicationConfig.landing_page.enabled;

      // if there's no date timer then allow moving everywhere
      if (!isGameLaunched && !isLandingPageEnabled) {
        return true;
      }

      // case where landing page is disabled
      if (state.url.toLowerCase().includes("landing") && !isLandingPageEnabled) {
        router.navigate(["/"]);
        return false;
      }

      // redirect to landing if game didn't start
      if (!state.url.toLowerCase().includes("landing") && !isGameLaunched) {
        router.navigate(["/landing"]);
        return false;
      }

      return true;
    })
  );
};
