import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { AbstractChestComponent } from "./abstract-chest.component";

@NgModule({
  imports: [CommonModule],
  declarations: [AbstractChestComponent],
})
export class ChestAbstractModule {}
