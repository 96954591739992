import { Injectable } from "@angular/core";

import { ApiOptions } from "../interfaces/api-options";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class PingService {
  timeout: null | ReturnType<typeof setTimeout>;
  timeInSecondsToPing = {
    min: 180,
    max: 300,
  };

  constructor(private apiService: ApiService) {}

  private checkPing() {
    this.clearTimeout();

    this.timeout = setTimeout(() => {
      this.postPing();
    }, this.getRandomTimeInSeconds() * 1000);
  }

  private clearTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  private postPing() {
    const options: ApiOptions = {
      isNotShowLoading: true,
    };

    this.apiService.post("ping", options).subscribe(
      () => {
        this.checkPing();
      },
      () => {
        this.checkPing();
      }
    );
  }

  getRandomTimeInSeconds() {
    const min = this.timeInSecondsToPing.min;
    const max = this.timeInSecondsToPing.max;

    return Math.floor(Math.random() * (max - min)) + min;
  }
}
