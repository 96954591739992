import { Game } from "phaser";

import { GameService } from "../../../services/game.service";
import { MyGameConfig } from "../../interfaces/my-game-config";
import { MyScene } from "./MyScene";

export class MyGame extends Game {
  debug: boolean;
  iso;
  currentScene: MyScene;
  public gameService: GameService;

  constructor(config: MyGameConfig) {
    super(config);

    this.gameService = config.gameService;
  }
}
