<ng-container *ngIf="playerService.player as player">
  <ng-container *ngIf="visible">
    <div class="options">
      <div class="center">
        <!-- CARD TASKS VENDORS -->
        <button
          class="business"
          (click)="openCardTaskVendors()"
          [matTooltip]="'Panel biznesowy'"
          matTooltipPosition="above"
          matTooltipShowDelay="300"
        >
          <img [src]="'business.png' | asset: 'ui'"/>
          <span>{{"business.panel" | myTranslate}} </span>
        </button>

        <div
          *ngFor="let btn of buttons"
          class="click-space"
          [ngClass]="{'blocked': !playerService.isActiveMe && btn.show_only_active_me}"
          (click)="handleBtnClick(btn)"
        >
          <button
            [matTooltip]="btn.tooltip"
            class="square small primary"
            matTooltipPosition="above"
            matTooltipShowDelay="300"
          >
            <img [src]="btn.icon | asset: 'ui/menu/down'" />
          </button>
        </div>

        <div
          class="click-space"
          *ngIf="player.title_id.id === 2"
          (click)="openPreviewPlayer()"
        >
          <button
            [matTooltip]="'Podgląd oddziału'"
            class="square small primary"
            matTooltipPosition="above"
            matTooltipShowDelay="300"
          >
            <img [src]="'people.png' | asset: 'ui/menu/down'" />
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #notificationTpl>
  <div class="icon notification"></div>
</ng-template>
