import { Directive, EventEmitter, Injectable, Input, Output } from "@angular/core";
import { Store } from "@ngrx/store";

import { AbstractInjectBaseComponent } from "../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../core/decorators/ow-inject.decorator";
import { AppState } from "../../../../../store/state";
import { STOCK_VIEW } from "../mobile/consts/stock-view.const";

@Directive()
@Injectable()
export abstract class AbstractStockBase extends AbstractInjectBaseComponent {
  @OwInject(Store) store: Store<AppState>;

  @Input() stockView: string;
  @Input() item: any;
  @Input() other: { value?: string; afterValue?: string; amount?: number } = {};
  @Input() lack = true;
  @Input() hideValue: boolean;
  @Input() observable: boolean;
  @Input() lockOpenStorage: boolean;
  @Input() customClassStock: string;
  @Input() customIconSize: string;
  @Input() showBalance: boolean;
  @Output() itemChanged = new EventEmitter();
  @Input() disableTooltipCurrency: boolean;
  @Input() disableTooltipProduct: boolean;

  abstract defaultStockView: string;
  STOCK_VIEW = STOCK_VIEW;

  parseItem() {
    if (!this.lack) {
      this.item.have = true;
    }
    this.itemChanged.emit(this.item);
  }

  setDefaultStockView() {
    if (!this.stockView) {
      this.stockView = this.defaultStockView;
    }
  }
}
