import { Directive, Injectable, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Actions, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { filter, take, tap } from "rxjs/operators";

import { AbstractInjectBaseComponent } from "../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../core/decorators/ow-inject.decorator";
import { GlobalService } from "../../../core/providers/global.service";
import { NativeAppService } from "../../../core/providers/native-app.service";
import { LoginActions } from "../../../store/auth/login";
import { loginStart, loginSuccess } from "../../../store/auth/login/actions";
import { ApplicationConfig } from "../../../store/config/interfaces/config.state";
import { ConfigState } from "../../../store/config/reducer";
import { selectConfigOfApplication, selectConfigState } from "../../../store/config/selectors";
import { AppState } from "../../../store/state";
import { userFetchStart, userFetchSuccess } from "../../../store/user/actions";
import { selectUser } from "../../../store/user/selectors";
import { GuiService } from "../../game/services/gui.service";
import { DialogService } from "../../shared/providers/dialog.service";
import { AUTH_ROUTING_ABSOLUTE_PATH } from "../consts/core/routing-auth-absolute.const";
import { Credentials } from "../interfaces/credentials";

@Directive()
@Injectable()
export abstract class AbstractLoginComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(FormBuilder) fb: FormBuilder;
  @OwInject(Router) router: Router;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(Actions) actions$: Actions;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(Store) configStore: Store<ConfigState>;
  @OwInject(GuiService) guiService: GuiService;
  @OwInject(NativeAppService) nativeAppService: NativeAppService;
  @OwInject(GlobalService) globalService: GlobalService;
  config: ApplicationConfig = null;

  AUTH_ROUTING_ABSOLUTE_PATH = AUTH_ROUTING_ABSOLUTE_PATH;
  form: FormGroup;

  subs = {
    config: null,
    actionUserSuccess: null,
    actionAuthSuccess: null,
  };

  ngOnInit() {
    this.store
      .select(selectConfigOfApplication)
      .pipe(take(1))
      .subscribe(res => {
        this.config = res;
      });
  }

  afterConstructor() {
    this.form = this.fb.group({
      username: this.fb.control(null, [Validators.required]),
      password: this.fb.control(null, [Validators.required]),
      remember_me: this.fb.control(null),
    });
  }

  subscribeConfig() {
    this.subs.config = this.configStore.select(selectConfigState).subscribe(config => {
      this.config = config.applicationConfig;
    });
  }

  prepareData(): Credentials {
    return {
      username: this.form.value.username,
      password: this.form.value.password,
      remember_me: this.form.value.remember_me,
    };
  }

  login() {
    const data = this.prepareData();
    this.store.dispatch(loginStart(data));
  }

  subscribeAuthSuccess() {
    this.subs.actionAuthSuccess = this.actions$
      .pipe(
        ofType(loginSuccess),
        tap(() => {
          this.store.dispatch(userFetchStart({}));
        })
      )
      .subscribe(() => {});
  }

  subscribeUserSuccess() {
    this.subs.actionUserSuccess = this.actions$
      .pipe(
        ofType(userFetchSuccess),
        tap(() => {
          setTimeout(() => {
            this.resolveLoginSuccessRedirect();
          });
        })
      )
      .subscribe(() => {});
  }

  resolveLoginSuccessRedirect(init?: boolean) {
    if (init) this.store.dispatch(userFetchStart({}));
    this.store
      .pipe(
        select(selectUser),
        filter(value => value !== null && value !== undefined),
        take(1)
      )
      .subscribe(user => {
        if (user && user.enabled_players.length === 1) {
          this.router.navigate(["/auth/game"]);
        } else {
          this.router.navigate(["/auth/player-choose"]);
        }
      });
  }
}
