import { Injectable, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import moment from "moment";
import { map, take } from "rxjs/operators";
import { utilityDifferentVersionGame } from "src/app/store/utility/actions";

import { environment } from "../../../environments/environment";
import { DialogService } from "../../modules/shared/providers/dialog.service";
import { AppState } from "../../store/state";
import { ApiOptions } from "../interfaces/api-options";
import { ApiService } from "./api.service";
import { GlobalService } from "./global.service";

@Injectable()
export class BuildVersionService implements OnDestroy {
  timeout: null | ReturnType<typeof setTimeout>;
  timeInSecondsToCheckBuild = 60;
  isVersionGameChanged: boolean;

  constructor(
    private apiService: ApiService,
    private globalService: GlobalService,
    private store: Store<AppState>
  ) {
    setTimeout(() => {
      if (environment.production) {
        this.setGameVersion();
      }
    }, 0);
  }

  private setGameVersion() {
    this.getLatestGameVersion().subscribe(resp => {
      document["GAME_VERSION_TIMESTAMP"] = resp;
      this.checkGameVersion();
      this.checkVersionGameChangedAndSendEvent();
    });
  }

  private checkGameVersion() {
    this.clearTimeout();

    this.timeout = setTimeout(() => {
      this.triggerVersionCheck();
    }, this.timeInSecondsToCheckBuild * 1000);
  }

  triggerVersionCheck() {
    this.getLatestGameVersion()
      .pipe(take(1))
      .subscribe(resp => {
        if (resp !== document["GAME_VERSION_TIMESTAMP"]) {
          this.isVersionGameChanged = true;
        } else {
          this.checkGameVersion();
        }
      });
  }

  private clearTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  private getLatestGameVersion() {
    const options: ApiOptions = {
      params: {
        timestamp: moment().format(),
      },
      contentTypeAuto: true,
      responseType: "text",
      sendRequestWithoutToken: true,
      useBaseUrl: true,
      isNotShowLoading: true,
    };

    return this.apiService.get("build.txt", options).pipe(
      map(resp => {
        return resp.trim();
      })
    );
  }

  checkVersionGameChangedAndSendEvent() {
    setTimeout(() => {
      if (this.isVersionGameChanged && DialogService.openDialogs.length === 0) {
        this.store.dispatch(utilityDifferentVersionGame());
      } else {
        this.checkVersionGameChangedAndSendEvent();
      }
    }, 3000);
  }

  ngOnDestroy() {
    if (this.timeout) {
      this.clearTimeout();
    }
  }
}
