<table *ngIf="rankResults">
  <thead>
  <tr [ngClass]="{'hide-element': show !== 'header'}">
    <ng-container *ngFor="let column of columns">
      <!-- FE COLUMN HEADER -->
      <ng-container *ngIf="!column.columnFromApi">
        <th
          *ngIf="!column.prizes || (column.prizes && rank.show_prizes && (rank.is_branch_rank || (!rank.is_branch_rank && filterKey !== LEVEL_FILTER_KEY.COUNTRY)))"
          [style.text-align]="column.textAlign"
        >
          {{ column.header }}
        </th>
      </ng-container>

      <!-- BE COLUMN HEADER -->
      <ng-container *ngIf="column.columnFromApi">
        <th
          *ngIf="rank['value' + column.columnFromApi + '_label'] as label"
          [style.text-align]="column.textAlign"
        >
          {{ label }}
        </th>
      </ng-container>
    </ng-container>
  </tr>
  </thead>
  <tbody>
  <!-- MY POSITION -->
  <ng-container
    *ngIf="rankResults.my_position"
    [ngTemplateOutlet]="tableTrTpl"
    [ngTemplateOutletContext]="{
          item: rankResults.my_position,
          rank: rank,
          showTr: show === 'myPosition'
        }"
  ></ng-container>

  <!-- RESULTS -->
  <ng-container
    *ngFor="let item of rankResults.results"
    [ngTemplateOutlet]="tableTrTpl"
    [ngTemplateOutletContext]="{
          item: item,
          rank: rank,
          showTr: show === 'results'
        }"
  ></ng-container>
  </tbody>
</table>


<!-- TABLE TR TEMPLATE -->
<ng-template
  #tableTrTpl
  let-item="item"
  let-rank="rank"
  let-showTr="showTr"
>
  <tr [ngClass]="{'hide-element': !showTr}">
    <ng-container *ngFor="let column of columns">
      <!-- FE COLUMN -->
      <ng-container *ngIf="!column.columnFromApi">
        <!-- PRIZES COLUMN -->
        <td
          *ngIf="column.prizes && rank.show_prizes && (rank.is_branch_rank || (!rank.is_branch_rank && filterKey !== LEVEL_FILTER_KEY.COUNTRY)) ||
          column.prizes && rank.show_prizes && filterKey === LEVEL_FILTER_KEY.COUNTRY"
          [style.text-align]="column.textAlign"
          [ngClass]="column.classes"
        >
          <!-- second condition - #29221 -->
          <m-ui-currency
            *ngFor="let currency of item.currency_prizes"
            [item]="currency"
            [stockView]="STOCK_VIEW.H"
            [lack]="false"
          ></m-ui-currency>
          <m-ui-product
            *ngFor="let product of item.product_prizes"
            [lockOpenStorage]="true"
            [item]="{product_id: product.product_id, customName: product.custom_name, amount: product.amount }"
            [stockView]="STOCK_VIEW.H"
            [lack]="false"
          ></m-ui-product>
        </td>

        <!-- DEFAULT COLUMN -->
        <td
          *ngIf="column.default"
          [style.text-align]="column.textAlign"
          [ngClass]="column.classes"
        >
          {{ item[column.value] }}
        </td>
      </ng-container>

      <!-- BE COLUMN -->
      <ng-container *ngIf="column.columnFromApi">
        <td
          *ngIf="rank['value' + column.columnFromApi + '_label']"
          [ngbTooltip]="item['value' + column.columnFromApi + '_tooltip']"
          container="body"
          placement="top"
          [style.text-align]="column.textAlign"
        >
          {{ item['value' + column.columnFromApi] }}
        </td>
      </ng-container>
    </ng-container>
  </tr>
</ng-template>

