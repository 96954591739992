import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import * as Sentry from "@sentry/angular";
import * as R from "ramda";
import { Observable, of } from "rxjs";
import { catchError, mergeMap, tap } from "rxjs/operators";

import { omitErrorResponseHelper } from "../../core/helpers/omit-error-response.helper";
import { CurrencyBalanceDetails } from "../../core/interfaces/currency";
import { CurrencyService } from "../../core/providers/currency.service";
import { User } from "../../modules/user/interfaces/user";
import { UserService } from "../../modules/user/providers/user.service";
import { AppState } from "../state";
import { utilityUpdateMePlayerId } from "../utility/actions";
import {
  userFetchFailure,
  userFetchStart,
  userFetchSuccess,
  userNullAction,
  userUpdateCurrencyBalances,
  userUpdateDiscount,
  userUpdateStart,
  userUpdateSuccess,
} from "./actions";
import {NativeAppService} from '../../core/providers/native-app.service';
import {Router} from '@angular/router';
import {AUTH_ROUTING_ABSOLUTE_PATH} from '../../modules/auth/consts/core/routing-auth-absolute.const';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private userService: UserService,
    private currencyService: CurrencyService,
    private nativeAppService: NativeAppService,
    private router: Router
  ) {}

  $fetchBoard: Observable<any | Action> = createEffect((): any =>
    this.actions$.pipe(
      ofType(userFetchStart, userUpdateStart),
      mergeMap(action => {
        // 2 players scenario - if you're on choose-player screen it will trigger fetchUser, if you're on choose-player screen it will
        // trigger fetchUser, then userUpdateStart
        if (action.user) {
          this.handlerUserBeforeSave(<User>action.user);
          this.nativeAppService.gameStarted()
          return of();
        } else {
          return this.fetchUser();
        }
      })
    )
  );

  $updateUserCurrenciesBalances = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(userUpdateCurrencyBalances),
        mergeMap(action => {
          return this.updateUserCurrenciesBalances(action.balances);
        })
      )
  );

  $updateUserDiscount = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(userUpdateDiscount),
        mergeMap(action => {
          return this.updateUserDiscount(action.discount);
        })
      )
  );

  fetchUser() {
    return this.userService.getMe().pipe(
      tap((user: User) => {
        this.handlerUserBeforeSave(user);
      }),
      mergeMap((user: User) => {
        // core override
        this.checkLogicRedirectConfirmData(user);
        // end
        return [
          utilityUpdateMePlayerId({
            playerId: user.selected_player_id,
          }),
          userFetchSuccess({ user: user }),
        ];
      }),
      catchError((error: any) => {
        return of(userFetchFailure({ error: omitErrorResponseHelper(error) }));
      })
    );
  }

  updateUserCurrenciesBalances(balances) {
    return of(this.userService.me).pipe(
      tap((user: User) => {
        this.handlerUserBeforeSave({
          ...user,
          currency_balances: balances,
        });
      }),
      mergeMap(() => {
        return of(userNullAction());
      })
    );
  }

  handlerUserBeforeSave(user: User) {
    window["storeUser"] = user;
    Sentry.setUser({
      external_id: window["storeUser"].external_id ?? "unknown",
      id: window["storeUser"].id ?? "unknown",
      previous_login_at: window["storeUser"].previous_login_at ?? "unknown",
    });
    const userClone = R.clone(user);
    userClone.currency_balances = <CurrencyBalanceDetails[]>(
      this.currencyService.getCurrencyDefinitions(userClone.currency_balances)
    );
    this.userService.me = userClone;
    this.store.dispatch(userUpdateSuccess({ user: userClone }));
  }

  updateUserDiscount(currentDiscountValue: number) {
    return of(this.userService.me).pipe(
      tap((user: User) => {
        this.handlerUserBeforeSave({
          ...user,
          current_discount_value: currentDiscountValue,
        });
      }),
      mergeMap(() => {
        return of(userNullAction());
      })
    );
  }

  // core override
  checkLogicRedirectConfirmData(user: User) {
    setTimeout(() => {
      if (user['current_edition_consent'] && user['done_mandatory_verification'] === false) {
        this.router.navigate([AUTH_ROUTING_ABSOLUTE_PATH.REGISTRATION], {
          queryParams: {
            edit: 1,
            mandatoryVerification: 1,
          }
        });
      }
    }, 50);
  }
  // core override end
}
