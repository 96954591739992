import { EventEmitter, Injectable } from "@angular/core";

import { EventEmitterDialog } from "../../interfaces/event-emitter-dialog.interface";

@Injectable({
  providedIn: "root",
})
export class EventEmitterDialogsService {
  emitter: EventEmitter<EventEmitterDialog> = new EventEmitter<EventEmitterDialog>();
}
