import { Component } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { AbstractAlertComponent } from "../../../../abstracts/abstract-alert.component";
import { OwInject } from "../../../../decorators/ow-inject.decorator";
import { AlertImage } from "../../../../interfaces/alert";

@Component({
  selector: "app-core-alert-image",
  templateUrl: "./alert-image.component.html",
})
export class AlertImageComponent extends AbstractAlertComponent {
  @OwInject(MAT_DIALOG_DATA) data: AlertImage;
}
