import { Directive } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { Player } from "../../../../../player/interfaces/player";
import { PlayerService } from "../../../../../player/providers/player.service";
import { DialogService } from "../../../../../shared/providers/dialog.service";

@Directive()
export abstract class AbstractUserSettingsComponent extends AbstractInjectBaseComponent {
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractUserSettingsComponent>;
  @OwInject(FormBuilder) formBuilder: FormBuilder;

  player: Player;
  nickForm: FormGroup;

  afterConstructor() {
    this.player = this.playerService.player;
  }

  createForm() {
    this.nickForm = this.formBuilder.group({
      farm_name: [this.player.farm_name, [Validators.required, Validators.maxLength(23)]],
    });
  }

  changeNick() {
    const farm_name = this.nickForm.controls.farm_name.value;

    this.playerService.patchPlayer({ farm_name }).subscribe(() => {
      this.matDialogRef.close();
      this.dialogService.openAlert({ description: "Nick został zmieniony" });
    });
  }
}
