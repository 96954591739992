<div class="hud-element-container {{variant}}">
  <button
    #tooltip="ngbTooltip"
    (click)="handleOnClickEvent(guiItem, tooltip)"
    (mousedown)="tooltip.open()"
    [ngbTooltip]="guiItem.tooltip && !isMobile && tooltipTpl"
    container="body"
    [ngClass]="{
      'cursor-normal': !guiItem?.show_currency_history,
      'show-resource': type === 'products' || type === 'currency',
      'show-parameter-delta': type === 'parameters' && populationDelta != null,
      'show-parameter-default': type === 'parameters' && populationDelta == null
    }"
    tooltipClass="delayed">
    <!-- show icon -->
    <div [style.background-image]="'url(' + (guiItem.icon + '.png' | asset: type + '/hud') + ')'" class="icon {{type}}"></div>

    <!-- show value -->
    <div class="resource">
      <p class="value">{{guiItem.resourceBalance | NumberWithSpaces}}</p>
    </div>

    <div class="parameter-delta">
      <p class="value">{{ guiItem.displayHud | NumberWithSpaces }}</p>

      <!-- show delta (parameter only) -->
      <div class="delta" *ngIf="populationDelta" [ngClass]="populationDelta > 0 ? 'green' : 'red'">
        <span>
          {{ populationDelta > 0 ? "+" : "" }}{{ populationDelta }}
        </span>
        <div class="icon-delta"></div>
      </div>
    </div>

    <div class="parameter-default">
      <p class="value">{{ guiItem.displayHud + "%" }}</p>
    </div>
  </button>
</div>

<ng-template #tooltipTpl>
  <div style="font-weight: normal" [innerHTML]="guiItem.tooltip"></div>
</ng-template>
