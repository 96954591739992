/**
 * Created by Paweł Wawrzyński on 2017-01-04.
 */

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "numberLength",
})
export class NumberLengthPipe implements PipeTransform {
  transform(value: string, width: number, args: any[]) {
    value = value + "";
    return value.length >= width ? value : new Array(width - value.length + 1).join("0") + value;
  }
}
