import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'romanize'
})
export class RomanizePipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): unknown {
    const romanSigns = {
      M: 1000,
      CM: 900,
      D: 500,
      CD: 400,
      C: 100,
      XC: 90,
      L: 50,
      XL: 40,
      X: 10,
      IX: 9,
      V: 5,
      IV: 4,
      I: 1
    };
    let str = '';

    for (let i of Object.keys(romanSigns)) {
      let q = Math.floor(value / romanSigns[i]);
      value -= q * romanSigns[i];
      str += i.repeat(q);
    }

    return str;
  }

}
