import { Component, OnDestroy, OnInit } from "@angular/core";

import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { AbstractBuildingDemolishComponent } from "../../../../abstract/core/abstract-building-demolish.component";

@Component({
  selector: "m-building-demolish",
  templateUrl: "./building-demolish.component.html",
})
export class MBuildingDemolishComponent extends AbstractBuildingDemolishComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.subscribeBoardTile();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
