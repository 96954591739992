import { Injectable } from "@angular/core";
import { initializeApp } from "firebase/app";
import { deleteToken, getMessaging, getToken, isSupported } from "firebase/messaging";

import { CONFIG, VAPIDKEY } from "../../../../libs/firebase/config-firebase";
import { NativeAppService } from "../../providers/native-app.service";
import { getToken as Token } from "../../utility/token";

@Injectable({ providedIn: "root" })
export class RegisterCustomFirebaseTokenService {
  TYPE_ANDROID = 1;
  TYPE_IOS = 2;
  TYPE_PWA = 3;

  isRegisterFirebaseFired: boolean;
  didItGoToFirstIf: boolean;
  downloadedToken: boolean;
  catchErrorMsg;

  app;
  messaging;

  token: string;

  constructor(private nativeAppService: NativeAppService) {}

  registerFirebase() {
    if (window["NativeInterface"] || Token().is_impersonate) {
      return;
    }
    this.app = initializeApp(CONFIG);
    this.messaging = getMessaging(this.app);
    isSupported()
      .then(isSupported => {
        if (isSupported) {
          if (!Reflect.has(window, "Notification")) {
            console.log("This browser does not support notifications.");
            return null;
          } else {
            if (checkNotificationPromise()) {
              return Notification.requestPermission();
            } else {
              return Notification.requestPermission(handlePermission);
            }
          }
        }
      })
      .then(permission => {
        if (Notification.permission === "granted" || permission === "granted") {
          return navigator.serviceWorker.register("/main/firebase-messaging-sw.js", {
            type: "module",
          });
        } else {
          return null;
        }
      })
      .then(registration => {
        if (registration) {
          return getToken(this.messaging, { vapidKey: VAPIDKEY, serviceWorkerRegistration: registration });
        }
        return null;
      })
      .then(token => {
        if (token) {
          this.token = token;
          this.downloadedToken = true;
          this.isRegisterFirebaseFired = true;
          this.pushToken(this.token);
        }
      })
      .catch(error => {
        this.catchErrorMsg = error;
      });
    const that = this;
    if ("permissions" in navigator) {
      navigator.permissions.query({ name: "notifications" }).then(function (notificationPerm) {
        notificationPerm.onchange = function () {
          if (notificationPerm.state === "granted") {
            that.registerFirebase();
          }
        };
      });
    }
  }

  async unregisterFirebase() {
    if (this.token) {
      await deleteToken(this.messaging)
        .then(() => {})
        .catch(console.error);
    }
  }

  pushToken(token: string) {
    this.nativeAppService.registerForPushWithType(token, this.TYPE_PWA, document["GAME_VERSION_TIMESTAMP"]);
  }
}
function checkNotificationPromise() {
  try {
    Notification.requestPermission().then();
  } catch (e) {
    return false;
  }

  return true;
}
function handlePermission(permission) {
  // Whatever the user answers, we make sure Chrome stores the information
  if (!Reflect.has(Notification, "permission")) {
    // @ts-ignore
    Notification.permission = permission;
  }
}
