import { createReducer, on } from "@ngrx/store";

import { TokenObject } from "../../../modules/auth/interfaces/token-object";
import { passwordChangeFailed, passwordChangeStart, passwordChangeSuccess, passwordMustChange } from "./actions";

export interface PasswordState {
  error?: any;
  isChanging: boolean;
  tempToken: TokenObject;
}

const initialState: PasswordState = {
  error: null,
  isChanging: false,
  tempToken: null,
};

export const passwordReducer = createReducer(
  initialState,
  on(passwordMustChange, (state, action) => {
    return {
      ...state,
      tempToken: action.token,
    };
  }),
  on(passwordChangeStart, (state, action) => {
    return {
      ...state,
      isChanging: true,
      error: null,
    };
  }),
  on(passwordChangeSuccess, (state, action) => {
    return {
      ...state,
      isChanging: false,
      error: false,
      tempToken: null,
    };
  }),
  on(passwordChangeFailed, (state, action) => {
    return {
      ...state,
      isChanging: false,
      error: action.payload,
    };
  })
);
