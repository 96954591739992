import { Directive, Injectable } from "@angular/core";
import * as R from "ramda";

import { VideoComponent } from "../../../../../shared/components/video/video.component";
import { AbstractMessageDetailsBaseComponent } from "./abstract-message-details-base.component";

@Directive()
@Injectable()
export abstract class AbstractMessageDetailsNormalComponent extends AbstractMessageDetailsBaseComponent {
  scrollbarConfig = {};

  REGEX_TAGS: {
    [key: string]: {
      regex: RegExp;
      replaceValue: string;
      source: string;
    };
  } = {
    PDF: {
      regex: /\[pdf src="([^"]*)"\]/g,
      replaceValue: "",
      source: null,
    },
    YOUTUBE: {
      regex: /\[youtube id="([^"]*)"\]/g,
      replaceValue: "",
      source: null,
    },
    VIDEO: {
      regex: /\[video src="([^"]*)"\]/g,
      replaceValue: "",
      source: null,
    },
  };

  afterInit() {
    super.afterInit();
    this.checkRegex();
  }

  checkRegex() {
    this.message = R.clone(this.message);

    Object.keys(this.REGEX_TAGS).forEach(key => {
      const tag = this.REGEX_TAGS[key];

      tag.source = this.getRegexGroup(this.message.description, tag.regex);
      this.message.description = this.message.description.replace(tag.regex, tag.replaceValue);
    });
  }

  getRegexGroup(string: string, regex: RegExp, group?: number) {
    group = group || 1;

    let matchGroup: string;

    const matches = regex.exec(string);
    if (matches) {
      matchGroup = matches[group];
    }

    return matchGroup;
  }

  openVideo() {
    this.dialogService.open(VideoComponent, {
      data: {
        src: this.REGEX_TAGS.VIDEO.source,
        youtubeId: this.REGEX_TAGS.YOUTUBE.source,
      },
    });
  }
}
