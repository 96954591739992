import { Component, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as R from "ramda";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { ApiBusinessRanksService } from "../../../../api/custom/services/api-business-ranks.service";
import { LEVEL_FILTER_KEY } from "../../../../consts/custom/rank-filter-key.const";
import { RANK_CONFIG } from "./config/rank.config";
import { ApiService } from "../../../../../../../../core/providers/api.service";

export interface BusinessRank {
  task_definition_id: number;
  year: number;
  month: number;
  rank_id: number;
  type: "BRANCH" | "PLAYER" | "CONTEST" | undefined;
  cleared: boolean;
  contest_id: number;
  description: string;
  is_branch_rank: boolean;
  name: string;
  results_url: string | undefined;
  show_prizes: boolean;
  value1_label: string;
  value2_label: string;
}

@Component({
  selector: "app-ranking-business-details",
  templateUrl: "./ranking-business-details.component.html",
})
export class RankingBusinessDetails extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ApiBusinessRanksService) apiBusinessRanksService: ApiBusinessRanksService;
  @OwInject(MAT_DIALOG_DATA) data: { rank: BusinessRank };
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiService) apiService: ApiService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<RankingBusinessDetails>;

  defaultFilter = { filterKey: "krs" };
  limit = 100;
  rank: BusinessRank;
  rankResults: any;
  FILTERS = [
    { label: "Region", filterKey: "krs", visible: true },
    { label: "Kraj", filterKey: LEVEL_FILTER_KEY.COUNTRY, visible: true },
  ];
  activeFilter;
  RANK_CONFIG;

  ngOnInit() {
    this.rank = this.data.rank;
    this.changeFilter(this.defaultFilter);
  }

  changeFilter(filter) {
    this.activeFilter = this.searchFilter(filter.filterKey);
    this.getRank();
  }

  searchFilter(filterKey: string) {
    return this.FILTERS.find(filter => filter.filterKey === filterKey);
  }

  getRank() {
    this.getBusinessRank();
  }

  getBusinessRank() {
    const requestOptions = {
      taskDefinitionId: this.rank.task_definition_id || this.rank.contest_id,
      year: this.rank.year,
      month: this.rank.month,
      limit: this.limit,
      [this.activeFilter.filterKey]: true,
      rank_id: this.rank.rank_id,
    };

    // dirty workaround - if contest rank is clicked from the business rank we have no info about the type and results_url
    // in this scenario, we're forcing the type to 'contest'
    if (!this.rank.results_url && this.rank.contest_id) {
      this.rank.type = "CONTEST";
    }

    let apiRequest;
    switch (this.rank.type) {
      case "BRANCH":
        this.RANK_CONFIG = R.clone(RANK_CONFIG.BUSINESS_DEFAULT);
        apiRequest = this.apiBusinessRanksService.businessRanksBranchResults(requestOptions);
        break;
      case "PLAYER":
        this.RANK_CONFIG = R.clone(RANK_CONFIG.BUSINESS_PLAYER);
        apiRequest = this.apiBusinessRanksService.businessRanksPlayerResults(requestOptions);
        break;
      case "CONTEST":
        this.RANK_CONFIG = R.clone(RANK_CONFIG.BUSINESS_CONTEST);
        apiRequest = this.apiBusinessRanksService.businessContest(requestOptions);
        break;
      default:
        if (this.rank.contest_id) {
          this.RANK_CONFIG = R.clone(RANK_CONFIG.BUSINESS_CONTEST);
        } else {
          this.RANK_CONFIG = R.clone(this.rank.is_branch_rank ? RANK_CONFIG.BUSINESS_DEFAULT : RANK_CONFIG.BUSINESS_PLAYER );
        }
        const options = {
          params: {
            limit: requestOptions.limit,
            branch: requestOptions['branch'],
            krs: requestOptions['krs'],
            makroregion: requestOptions['makroregion'],
          },
        };

        apiRequest = this.apiService.request("get", this.rank.results_url, options);
        break;
    }

    apiRequest.subscribe(
      resp => {
        this.rankResults = resp;
        // fill if missing data(contest case)
        this.rank = {...this.rank, ...resp.rank};

        // add labels
        if (resp.rank?.value1_label) {
          this.RANK_CONFIG.columns.push({ header: resp.rank?.value1_label, value: "value1", default: true });
        }

        if (resp.rank?.value2_label) {
          this.RANK_CONFIG.columns.push({ header: resp.rank?.value2_label, value: "value2", default: true });
        }

        this.RANK_CONFIG.columns.push({ header: "Nagroda", prizes: true, textAlign: "right", classes: ["td-prizes"] })
      },
      errResp => {
        this.dialogService.openAlertErrorApi({ errResp });
      }
    );
  }

  close() {
    this.matDialogRef.close();
  }
}
