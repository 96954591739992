import { Component, OnDestroy, OnInit } from "@angular/core";

import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { AbstractBuildingUpgradeComponent } from "../../../../abstract/core/abstract-building-upgrade.component";

@Component({
  selector: "app-building-upgrade",
  templateUrl: "./m-building-upgrade.component.html",
})
export class MBuildingUpgradeComponent extends AbstractBuildingUpgradeComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.subscribeBoardTile();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
