<div class="global-dialog ow-dialog window-a primary">
  <loading></loading>

  <!-- TOP BAR BASE -->
  <div class="top-bar base">
    <div class="title">
      {{ trashView ? ("messages.archives" | myTranslate) : title }}
    </div>
  </div>

  <!-- EXIT BUTTON -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button [preventDefault]="true" (clicked)="toggleTrash()" *ngIf="trashView"></m-ui-back-button>

  <!-- MENU RIGHT -->
  <div class="menu-right">
    <button
      class="square normal primary archive"
      (click)="toggleTrash()"
      *ngIf="!trashView"
      [matTooltip]="'messages.archives' | myTranslate"
      matTooltipPosition="above"
      matTooltipShowDelay="300">
      <i class="svg-icon box-archive"></i>
    </button>
  </div>

  <ng-container *ngIf="messages">
    <div class="messages loading-opacity">
      <div class="message" *ngFor="let message of messages">
        <!-- IMAGE -->
        <div class="image">
          <img [src]="message.sender_icon + '.png' | asset: 'senders/basic'" />
        </div>
        <div class="content" (click)="readMessage(message)" [class.readed]="message.read">
          <!-- TITLE -->
          <p class="title">{{ message.title }}</p>
          <!-- TEASER -->
          <p class="teaser">{{ message.teaser }}</p>
        </div>

        <!-- TRASH ICON -->
        <i
          class="svg-icon box-archive"
          *ngIf="!trashView && message.deletable"
          (click)="moveToTrash(message.player_message_id)"
          [matTooltip]="'messages.archives' | myTranslate"
          matTooltipPosition="right"
          matTooltipShowDelay="300"></i>
      </div>
    </div>

    <!-- NO MESSAGES -->
    <div class="no-item" *ngIf="messages.length == 0">
      {{ "messages.empty-inbox" | myTranslate }}
    </div>

    <m-ui-navigation [navigation]="navigation" (pageChanged)="getMessages($event)"></m-ui-navigation>
  </ng-container>
</div>
