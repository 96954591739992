import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";

import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { AbstractCloseButtonComponent } from "../../../abstract/abstract-close-button.component";
import { CONFIG_CLOSE_BUTTON } from "../../../config/core/close-button.config";

@Component({
  selector: "m-ui-exit-button",
  templateUrl: "ui-exit-button.component.html",
  styleUrls: ["ui-exit-button.component.scss"],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
})
export class MUiExitButtonComponent extends AbstractInjectBaseComponent {
  @OwInject(DialogService) dialogService: DialogService;
  @Input() preventDefault: boolean;
  @Output() clicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  click() {
    this.clicked.emit(true);

    if (!this.preventDefault) {
      this.dialogService.closeAll();
    }
  }
}
