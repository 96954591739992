import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";

import { SharedModule } from "../../../../../shared/shared.module";
import { SharedUiMobileModule } from "../../../shared-ui/mobile/shared-ui-mobile.module";
import { BuildingsAbstractModule } from "../../abstract/core/buildings-abstract.module";
import { MBuildingAllowProductionComponent } from "../../base/core/components/building-allow-production/building-allow-production.component";
import { MBuildingProductionCyclesComponent } from "../../base/core/components/building-production-cycles/building-production-cycles.component";
import { MBuildingRowProductionComponent } from "../../base/core/components/building-row-production/building-row-production.component";
import { MBuildingThumbnailComponent } from "../../base/core/components/building-thumbnail/building-thumbnail.component";
import { CoreBuildingTimeComponent } from "../../base/core/components/building-time/building-time.component";
import { MBuildingUpgradeDiffParameterComponent } from "../../base/core/components/building-upgrade-diff-parameter/building-upgrade-diff-parameter.component";
import { MBuildingUpgradeDiffProductionComponent } from "../../base/core/components/building-upgrade-diff-production/building-upgrade-diff-production.component";
import { MBuildingAutoProductionComponent } from "../../base/core/dialogs/building-auto-production/building-auto-production.component";
import { MBuildingBuildComponent } from "../../base/core/dialogs/building-build/m-building-build.component";
import { MBuildingDailyProductionComponent } from "../../base/core/dialogs/building-daily-production/building-daily-production.component";
import { MBuildingDemolishComponent } from "../../base/core/dialogs/building-demolish/building-demolish.component";
import { MBuildingInfoComponent } from "../../base/core/dialogs/building-info/m-building-info.component";
import { MBuildingMoveComponent } from "../../base/core/dialogs/building-move/building-move.component";
import { MBuildingProductionComponent } from "../../base/core/dialogs/building-production/m-building-production.component";
import { MBuildingSpecialComponent } from "../../base/core/dialogs/building-special/building-special.component";
import { MBuildingUpgradeComponent } from "../../base/core/dialogs/building-upgrade/m-building-upgrade.component";
import { MBuildingsListComponent } from "../../base/core/dialogs/buildings-list/m-buildings-list.component";
import { MTileBuyComponent } from "../../base/core/dialogs/tile-buy/tile-buy.component";
import { CUSTOM_DECLARATIONS } from "../../consts/custom/components.const";
import { CUSTOM_EXPORTS } from "../../consts/custom/exports.const";
import { CUSTOM_IMPORTS } from "../../consts/custom/imports.const";

@NgModule({
  declarations: [
    MBuildingInfoComponent,
    MBuildingUpgradeComponent,
    MBuildingsListComponent,
    MBuildingBuildComponent,
    MBuildingProductionComponent,
    MBuildingThumbnailComponent,
    MBuildingUpgradeDiffParameterComponent,
    MBuildingUpgradeDiffProductionComponent,
    MBuildingSpecialComponent,
    MBuildingMoveComponent,
    MTileBuyComponent,
    MBuildingRowProductionComponent,
    MBuildingAutoProductionComponent,
    MBuildingDailyProductionComponent,
    MBuildingAllowProductionComponent,
    MBuildingProductionCyclesComponent,
    MBuildingDemolishComponent,
    CoreBuildingTimeComponent,
    ...CUSTOM_DECLARATIONS,
  ],
  imports: [CommonModule, SharedModule, BuildingsAbstractModule, SharedUiMobileModule, ...CUSTOM_IMPORTS],
  exports: [
    MBuildingInfoComponent,
    MBuildingsListComponent,
    MBuildingProductionComponent,
    MBuildingThumbnailComponent,
    MBuildingRowProductionComponent,
    MBuildingAllowProductionComponent,
    MBuildingProductionCyclesComponent,
    ...CUSTOM_EXPORTS,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BuildingsModule {}
