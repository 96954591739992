import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { take } from "rxjs/operators";
import { gameFetchBoard, gameSetCurrentScene } from "src/app/store/game/actions";
import { selectPlayer } from "src/app/store/player/selectors";

import { AbstractInjectBaseComponent } from "../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../core/decorators/ow-inject.decorator";
import { EventEmitterDialogsService } from "../../../../core/services/core/event-emitter-dialogs.service";
import { MockState } from "../../../../store/actions";
import { selectGameCurrentScene } from "../../../../store/game/selectors";
import { playerUpdateTry } from "../../../../store/player/actions";
import { primaryFetchSuccess } from "../../../../store/primary/actions";
import { selectPrimary } from "../../../../store/primary/selectors";
import { AppState } from "../../../../store/state";
import { userUpdateStart } from "../../../../store/user/actions";
import { selectUser } from "../../../../store/user/selectors";
import { utilityUpdateActivePlayerId } from "../../../../store/utility/actions";
import { MockBuildingsService } from "../../../game/game-ui/buildings/mock/services/mock-buildings.service";
import { MockCurrencyExchangeService } from "../../../game/game-ui/currency-exchange/mock/services/mock-currency-exchange.service";
import { MockHudService } from "../../../game/game-ui/hud/mock/services/mock-hud.service";
import { MockMessageService } from "../../../game/game-ui/message/mock/services/mock-message.service";
import { MockMissionsService } from "../../../game/game-ui/mission/mock/services/mock-missions.service";
import { MockQaService } from "../../../game/game-ui/qa/mock/services/mock-qa.service";
import { MockTransactionHistoryService } from "../../../game/game-ui/transaction-history/mock/services/mock-transaction-history.service";
import { MOCK_WAREHOUSE_STORE_PRODUCT } from "../../../game/game-ui/warehouse/mock/consts/mock-warehouse.const";
import { MockWarehouseService } from "../../../game/game-ui/warehouse/mock/services/mock-warehouse.service";
import { MOCK_BOARD_KEY } from "../../../game/mock/consts/mock-board.const";
import { MockBoardService } from "../../../game/mock/services/mock-board.service";
import { Product } from "../../../player/interfaces/product.interface";
import { ProductPlayerService } from "../../../player/providers/product-player.service";
import { DialogService } from "../../../shared/providers/dialog.service";
import { MOCK_DIALOG_LIST } from "../../const/mock-dialog-list.const";
import { MOCK_STATE_APPLICATION } from "../../const/mock-state-application.const";
import { MockDialog } from "../../interfaces/mock-dialog.interface";
import { MockDialogListService } from "../../services/mock-dialog-list.service";

@Component({
  selector: "mock-dialogs-list",
  templateUrl: "./mock-dialog-list.component.html",
})
export class MockDialogListComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(MockDialogListService) mockDialogService: MockDialogListService;
  @OwInject(FormBuilder) formBuilder: FormBuilder;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(MockMessageService) mockMessageService: MockMessageService;
  @OwInject(MockBuildingsService) mockBuildingsService: MockBuildingsService;
  @OwInject(MockMissionsService) mockMissionsService: MockMissionsService;
  @OwInject(MockWarehouseService) mockWarehouseService: MockWarehouseService;
  @OwInject(ProductPlayerService) productPlayerService: ProductPlayerService;
  @OwInject(MockQaService) mockQaService: MockQaService;
  @OwInject(MockTransactionHistoryService) mockTransactionHistoryService: MockTransactionHistoryService;
  @OwInject(MockBoardService) mockBoardService: MockBoardService;
  @OwInject(MockCurrencyExchangeService) mockCurrencyExchangeService: MockCurrencyExchangeService;
  @OwInject(MockHudService) mockHudService: MockHudService;

  showMockStore: boolean;
  dialogs: MockDialog[] = MOCK_DIALOG_LIST;
  selectedDialog: MockDialog;
  MOCK_BOARD_KEY = MOCK_BOARD_KEY;

  ngOnInit() {
    this.mockDialogService.isMocked = true;
    this.mockDialogService.keySubscription.emit(MOCK_BOARD_KEY);
    this.store.dispatch(
      new MockState({
        ...MOCK_STATE_APPLICATION,
      })
    );

    this.store.pipe(select(selectUser), take(1)).subscribe(user => {
      this.store.dispatch(userUpdateStart({ user: user }));
    });
    this.store.pipe(select(selectPrimary), take(1)).subscribe(primary => {
      this.store.dispatch(primaryFetchSuccess({ primary: primary }));
    });
    this.store.pipe(select(selectPlayer), take(1)).subscribe(player => {
      this.store.dispatch(playerUpdateTry({ player: player }));
      this.store.dispatch(
        gameFetchBoard({
          playerId: player.id,
          playerIslandId: player.main_player_island.player_island_id,
        })
      );
      this.store.dispatch(
        utilityUpdateActivePlayerId({
          playerId: player.id,
        })
      );
    });

    this.store.pipe(select(selectGameCurrentScene), take(1)).subscribe(scene => {
      this.store.dispatch(gameSetCurrentScene({ sceneName: scene }));
    });
  }

  selectDialog(mockDialog: MockDialog) {
    this.selectedDialog = mockDialog;
    this.mockDialogService.getMockDataByKey(mockDialog.key);
  }

  openDialog(key: string, data: any) {
    this.eventEmitterDialogsService.emitter.emit({
      name: key,
      config: {
        data: JSON.parse(data),
      },
    });
    const mock = this.mockDialogService.findMockApiResponse(MOCK_WAREHOUSE_STORE_PRODUCT);
    if (mock) {
      const products = JSON.parse(mock.data) as Product[];
      this.productPlayerService.setProductsDefinitions(products);
    }
  }

  ngOnDestroy() {
    this.mockDialogService.mockDialogRefJSON = null;
    this.mockDialogService.mockDataJSON = null;
    this.mockDialogService.isMocked = false;
  }
}
