import { MyGame } from "../classes/core/MyGame";
import { MyScene } from "../classes/core/MyScene";
import { BASIC_BOOT_SCENE } from "../scenes-basic/basic.constants";

export class InitialScene extends MyScene {
  init() {
    this.gameService = (this.game as MyGame).gameService;
    this.gameService.game.currentScene = this;
  }

  create() {
    this.scene.start(BASIC_BOOT_SCENE);
  }
}
