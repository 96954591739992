<!-- LOGO -->
<div class="logo"></div>

<loading></loading>

<div class="container loading-opacity">
    <p class="description text-center">
        {{ "auth.socket-connection-error.connecting-to-server-terminated" | myTranslate }}
    </p>

    <div class="buttons flex-center">
        <button class="base primary" (click)="reconnect()">
            {{ "auth.socket-connection-error.button-reconnect" | myTranslate }}
        </button>
    </div>

    <!-- HELPDESK -->
    <a class="contact" [href]="'mailto:' + config?.common?.contact_email">{{ config?.common?.contact_email ?? ''}}</a>
</div>
<div class="copyright-container" *ngIf="config.default_page.copyright_text">
    <p [innerHTML]="config.default_page.copyright_text"></p>
</div>
