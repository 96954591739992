import { ChangeDetectorRef, Directive, ElementRef, Injectable, OnDestroy, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import moment from "moment";
import { Swiper, SwiperOptions } from "swiper/types";

import { swiperInjectionStyles } from "../../../../../../../styles/swiper/swiper-injection-styles";
import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { EVENTS } from "../../../../../../core/consts/core/events";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { CurrencyDetails } from "../../../../../../core/interfaces/currency";
import { CurrencyService } from "../../../../../../core/providers/currency.service";
import { GlobalService } from "../../../../../../core/providers/global.service";
import { EventEmitterDialogsService } from "../../../../../../core/services/core/event-emitter-dialogs.service";
import { AppState } from "../../../../../../store/state";
import { utilitySetHasMissionsToCollect } from "../../../../../../store/utility/actions";
import { PlayerService } from "../../../../../player/providers/player.service";
import { ProductPlayerService } from "../../../../../player/providers/product-player.service";
import { generateEachPages } from "../../../../../shared/helpers/generate-pages.helper";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { STOCK_VIEW } from "../../../shared-ui/mobile/consts/stock-view.const";
import { ApiMissionService } from "../../api/core/services/api-mission.service";
import { EVENT_DIALOGS_NAMES_MISSION } from "../../consts/core/event-dialogs/event-names";
import { Mission } from "../../interfaces/core";

@Directive()
@Injectable()
export abstract class AbstractMissionsComponent extends AbstractInjectBaseComponent implements OnDestroy {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractMissionsComponent>;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiMissionService) apiMissionService: ApiMissionService;
  @OwInject(CurrencyService) currencyService: CurrencyService;
  @OwInject(ProductPlayerService) productPlayerService: ProductPlayerService;
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(ChangeDetectorRef) changeDetectorRef: ChangeDetectorRef;
  missions: Array<Mission & { complete?: boolean; iconDirectoryPath?: string }>;
  STOCK_VIEW = STOCK_VIEW;
  slot = 1;

  normalMissions = {
    config: {
      itemPerPage: 3,
    },
    pages: [],
    items: [],
  };

  initSwiper = false;
  swiper: Swiper = null;
  @ViewChild("swiperRef", { static: false })
  swiperRef: ElementRef | undefined;
  currentSlideIndex = 0;
  config: SwiperOptions = {
    slidesPerView: 3,
    initialSlide: 0,
    centeredSlides: true,
    pagination: {
      dynamicBullets: true,
      clickable: true,
    },
    injectStyles: [swiperInjectionStyles],
  };

  baseInit() {
    this.dispatchShowedMissions();
    this.getMissionList();
  }

  dispatchShowedMissions() {
    setTimeout(() => {
      this.store.dispatch(utilitySetHasMissionsToCollect({ setMissionToCollect: false }));
    });
  }

  getMissionList() {
    this.apiMissionService.getMissionList().subscribe((resp: Mission[]) => {
      resp = resp.filter(mission => mission.slot.type === this.slot);
      this.missions = resp;
      this.afterMissionsRequest();
    });
  }

  afterMissionsRequest() {
    this.setCurrencyAndProducts();
    this.checkMissionComplete();
    this.sortMissionsByCompletion();
    this.setDiffTimes();
    this.normalMissions.pages = [];
    this.normalMissions.items = this.missions;
    this.generatePages();
    this.setSwiper();
  }

  sortMissionsByCompletion() {
    this.missions.sort((missionA, missionB) => {
      if (missionA.complete && missionB.complete) {
        return 0;
      } else if (missionA.complete) {
        return -1;
      } else if (missionB.complete) {
        return 1;
      }
    });
  }

  setDiffTimes() {
    this.missions.forEach(mission => {
      if (mission.status === "rejected") {
        mission["diffTime"] = "--:--:--";
        this.setDiffTime(mission);
        mission["observableTime"] = setInterval(() => {
          this.setDiffTime(mission);
        }, 1000);
      }
    });
  }

  setDiffTime(mission) {
    const nextMissionDate = new Date(mission.locked_till);
    const actualDate = this.playerService.getRealTime();
    mission["diffTime"] = moment(nextMissionDate).diff(moment(actualDate), "seconds");

    if (mission["diffTime"] <= 0) {
      clearInterval(mission["observableTime"]);
      mission["observableTime"] = null;
      mission["diffTime"] = 0;
      this.getMissionList();
    }
  }

  cancelInterval(): void {
    this.missions.forEach((mission: Mission) => {
      if (mission["observableTime"]) {
        clearInterval(mission["observableTime"]);
      }
    });
  }

  generatePages() {
    this.normalMissions = generateEachPages(this.normalMissions);
  }

  setCurrencyAndProducts() {
    this.missions.forEach(mission => {
      mission.currency_prizes = <CurrencyDetails[]>this.currencyService.getCurrencyDefinitions(mission.currency_prizes);

      mission.product_prizes = this.productPlayerService.getProducts(mission.product_prizes);
    });
  }

  openMissionDetails(mission: Mission) {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_MISSION.MISSION_DETAILS,
      config: {
        data: {
          mission,
        },
      },
      callback: data => {
        if (data && data.playerMissionsReload) {
          this.getMissionList();
        }
      },
    });
  }

  checkMissionComplete() {
    this.missions.forEach(mission => {
      mission.complete =
        mission.conditions_count > 0 &&
        mission.conditions_count === mission.conditions_completed &&
        mission.status === "active";

      mission.iconDirectoryPath = `character/${mission.complete ? "green" : "basic"}`;
    });
  }

  showOnMap(event, tileId) {
    event.stopPropagation();

    this.globalService.globalEvents.emit({
      name: EVENTS.GAME.CENTER_TILE,
      value: tileId,
    });

    this.dialogService.closeAll();
  }

  ngOnDestroy() {
    this.cancelInterval();
  }

  setSwiper() {
    this.initSwiper = false;
    this.swiper = null;
    this.changeDetectorRef.detectChanges();
    this.initSwiper = true;
    this.changeDetectorRef.detectChanges();
    if (this.swiperRef?.nativeElement) {
      this.swiper = this.swiperRef.nativeElement.swiper;
      this.swiper.on("slideChange", swiper => {
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  prevSlide() {
    this.swiper.slidePrev();
    this.currentSlideIndex = this.swiper.activeIndex;
  }

  nextSlide() {
    this.swiper.slideNext();
    this.currentSlideIndex = this.swiper.activeIndex;
  }
}
