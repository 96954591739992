import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { STORAGE_DATA } from "../../../../../../core/consts/core/storage/storage-data.const";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { utilityRemoveToken } from "../../../../../../store/utility/actions";
import { AbstractTempTokenExchangeComponent } from "../../../../abstract/abstract-temp-token-exchange.component";

@Component({
  selector: "app-temp-token-exchange",
  template: "",
})
export class TempTokenExchangeComponent extends AbstractTempTokenExchangeComponent implements OnInit {
  @OwInject(ActivatedRoute) route: ActivatedRoute;

  ngOnInit() {
    if (this.route.snapshot.queryParams["isEphemeralSession"]) {
      const sessionStorageDef = STORAGE_DATA.storageDefinitions.find(x => x.type === "session_storage");
      if (sessionStorageDef.supported) {
        sessionStorage.setItem("isEphemeralSession", "true");
        STORAGE_DATA.initialize();
      }
    }

    this.store.dispatch(utilityRemoveToken());
    this.subscribeRoutingParams();
  }
}
