const moduleName = "message";

export const EVENT_DIALOGS_NAMES_MESSAGE = {
  MESSAGES: `${moduleName}-messages`,
  MESSAGE_DETAILS_NORMAL: `${moduleName}-details-normal`,
  MESSAGE_DETAILS_COMICS: `${moduleName}-details-comics`,
  MESSAGE_DETAILS_LEVEL_UP: `${moduleName}-details-level-up`,
  MESSAGE_DETAILS_HIDDEN_REWARD: `${moduleName}-details-hidden-reward`,
  MESSAGE_DETAILS_CHEST_SUCCESS: `${moduleName}-details-chest-success`,
  MESSAGE_DETAILS_CHEST_FAILED: `${moduleName}-details-chest-failed`,
  MESSAGE_DETAILS_PROBABILITY_COMPENSATION: `${moduleName}-details-probability-compensation`,
};
