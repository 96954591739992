import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import moment from "moment";
import { take } from "rxjs/operators";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { LandingPageInterface } from "../../../../../../core/interfaces/landing-page.interface";
import { selectConfigOfApplication } from "../../../../../../store/config/selectors";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { A2hsService } from "../../../../services/core/a2hs.service";

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
})
export class LandingComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(Router) router: Router;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(A2hsService) a2hsService: A2hsService;
  @OwInject(Store) store: Store;
  landingTexts: LandingPageInterface = null;
  timer = null;
  intervalTimer;
  isLoading = true;
  iOSInstallVisibility = false;
  isInstallable: { pwa: boolean; onlyIOS: boolean } = null;
  sub = {
    installable: null,
  };

  ngOnInit() {
    this.sub.installable = this.a2hsService.appCanBeInstalled.subscribe(installable => {
      this.isInstallable = installable;
    });

    this.store
      .select(selectConfigOfApplication)
      .pipe(take(1))
      .subscribe(res => {
        this.landingTexts = { ...res.landing_page };

        if (moment(this.landingTexts.start_game_at).isAfter(moment())) {
          this.setTimerInterval();
        } else {
          this.intervalTimer = null;
        }
      });
  }

  login() {
    if (this.timer <= 0) {
      this.router.navigate(["/login"]);
    }
  }

  setTimerInterval() {
    this.setTimer();

    this.intervalTimer = setInterval(() => {
      this.setTimer();
    }, 1000);
  }

  setTimer() {
    const actualDate = moment();
    const expiredAt = moment(this.landingTexts.start_game_at);

    this.timer = moment(expiredAt).diff(moment(actualDate), "seconds");
    this.isLoading = false;
    if (this.timer <= 0) {
      this.router.navigate(["/"]);
      this.clearTimerInterval();
    }
  }

  clearTimerInterval() {
    clearInterval(this.intervalTimer);
  }

  convertTime(seconds) {
    seconds = Number(seconds);

    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);

    const hour = h < 10 ? "0" + h + " : " : h + " : ";
    const min = m < 10 ? "0" + m + " : " : m + " : ";
    const sec = s < 10 ? "0" + s : s;

    return hour + min + sec;
  }

  addApplicationToHomeScreen() {
    if (this.isInstallable.pwa) {
      this.a2hsService.addApplicationToHomeScreen();
    } else if (this.isInstallable.onlyIOS) {
      this.toggleiOSInstallLabelVisibility();
    }
  }

  toggleiOSInstallLabelVisibility() {
    this.iOSInstallVisibility = !this.iOSInstallVisibility;
  }

  ngOnDestroy() {
    clearInterval(this.intervalTimer);
    this.sub.installable.unsubscribe();
  }

  protected readonly setInterval = setInterval;
  protected readonly window = window;
}
