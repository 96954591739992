import { Injectable } from "@angular/core";
import * as R from "ramda";
import { tap } from "rxjs/operators";

import { ApiOptions } from "../../../core/interfaces/api-options";
import { ApiService } from "../../../core/providers/api.service";
import { AssetsService } from "../../../core/providers/assets.service";
import { SchemaValidatorService } from "../../../services/schema-validator/schema-validator.service";
import { MOCK_MESSAGE_DETAILS_LEVEL_UP_PRODUCTS } from "../../game/game-ui/message/mock/consts/mock-message-details-level-up.const";
import {
  MOCK_WAREHOUSE_CATEGORY,
  MOCK_WAREHOUSE_CATEGORY_PRODUCT,
} from "../../game/game-ui/warehouse/mock/consts/mock-warehouse.const";
import { Product, ProductBalance, ProductBase, ProductDetailsBalance } from "../interfaces/product.interface";
import { PlayerService } from "./player.service";

@Injectable({
  providedIn: "root",
})
export class ProductPlayerService {
  products: Product[] = [];

  constructor(
    private apiService: ApiService,
    private playerService: PlayerService,
    private assetsService: AssetsService,
    private schemaValidator: SchemaValidatorService
  ) {}

  /**
   * FUNCTION
   */
  setProductsDefinitions(products: Product[]) {
    this.products = products;
  }

  getProductFromDictionary(product) {
    return this.products.find(p => p.product_id === product.product_id);
  }

  getProducts(products: (ProductBase | Product)[]): ProductDetailsBalance[] {
    return products.map(product => {
      return this.getProduct(product);
    });
  }

  getProduct(product: ProductBase): ProductDetailsBalance {
    let copyProduct = R.clone({ ...product, ...this.getProductFromDictionary(product) });
    copyProduct = this.haveProduct(copyProduct, this.playerService.player.product_balances);
    return <ProductDetailsBalance>copyProduct;
  }

  private haveProduct(productCopy: ProductBase | Product, searchArray: ProductBalance[]): ProductDetailsBalance {
    const product: ProductDetailsBalance = <ProductDetailsBalance>productCopy;
    product.have = false;
    product.balance = 0;

    /**
     * If quantity passed as amount
     */
    if (product.hasOwnProperty("amount")) {
      product.quantity = product.amount;
    }

    /**
     * Check required level
     * @type {boolean}
     */
    product.allowRequiredLevel = this.playerService.allowRequiredLevel(product.required_level);

    /**
     * Get product image from image service normal
     */
    product.iconUrl = this.getIconUrlNormal(product);

    /**
     * Get product image from image service big
     */
    product.iconUrlBig = this.getIconUrlBig(product);

    searchArray.forEach((playerProduct: ProductBalance) => {
      if (product.product_id == playerProduct.product_id) {
        product.balance = playerProduct.balance;

        /**
         * Have required amount
         */
        if (playerProduct.balance >= product.quantity) {
          product.have = true;
        }
      }
    });

    return product;
  }

  private getIconUrl(icon: string, directory = "basic"): string {
    let iconUrl = this.assetsService.getAssetPath(`products/${directory}/${icon}.png`);

    if (!iconUrl) {
      iconUrl = this.assetsService.getAssetPath(`products/big/no-image.png`);
    }

    return iconUrl;
  }

  getIconUrlNormal({ icon }: { icon: string }): string {
    return this.getIconUrl(icon);
  }

  getIconUrlBig({ icon }: { icon: string }): string {
    return this.getIconUrl(icon, "big");
  }

  /**
   * API
   */
  productsPlayer({
    category,
    playerId,
    show_special,
  }: {
    category?: number;
    playerId?: number;
    show_special?: boolean;
  }) {
    const options: ApiOptions = {
      params: {
        category,
        show_special,
      },
    };

    playerId = playerId || this.playerService.getActivePlayerId();
    return this.apiService.get(`products/player/${playerId}`, options, MOCK_WAREHOUSE_CATEGORY_PRODUCT).pipe(
      tap(response => {
        this.schemaValidator.validateResponse({
          url: `products/player/${playerId}`,
          payload: {},
          response: response,
          schema: "productsPlayerGame",
        });
      })
    );
  }

  productsCategories({ storage_id }: { storage_id: number }) {
    const options: ApiOptions = {
      params: {
        storage_id,
      },
    };

    return this.apiService.get("products/categories", options, `${MOCK_WAREHOUSE_CATEGORY}_${storage_id}`).pipe(
      tap(response => {
        this.schemaValidator.validateResponse({
          url: "products/categories",
          payload: {},
          response: response,
          schema: "productsCategoriesGame",
        });
      })
    );
  }

  buyProduct(productId: number, amount: number) {
    return this.apiService.request("POST", `products/${productId}/buy`, {
      body: {
        amount,
      },
    });
  }

  sellProduct(productId: number, amount: number, fromShip?: boolean) {
    const sellUrl = fromShip ? `transport/item/${productId}/sell` : `products/${productId}/sell`;
    return this.apiService.request("POST", sellUrl, {
      body: {
        amount,
      },
    });
  }

  storageValue() {
    return this.apiService.get(`museum/player-sets`);
  }

  unlockedProductsAtLevel(level) {
    return this.apiService.get(`products/at-level/${level}`, {}, MOCK_MESSAGE_DETAILS_LEVEL_UP_PRODUCTS);
  }
}

export interface PlayerSet {
  museum_set: MuseumSet;
  obtained: number;
  total: number;
}

export interface MuseumSet {
  set_id: number;
  name: string;
  worth: number;
}
