<div class="bottom-bar" *ngIf="navigation.maxPage > 0">
  <div class="bar-button long">
    <div class="pagination">
      <div class="buttons">
        <!-- FIRST -->
        <button class="square small primary" (click)="pageChange(1)" [disabled]="navigation.currentPage == 1">
          <i class="svg-icon angle-double-left"></i>
        </button>

        <!-- NEXT -->
        <button
          class="square small primary"
          (click)="pageChange(navigation.currentPage - 1)"
          [disabled]="navigation.currentPage == 1">
          <i class="svg-icon angle-left"></i>
        </button>
      </div>

      <div class="pages">{{ navigation.currentPage }}/{{ navigation.maxPage }}</div>

      <div class="buttons">
        <!-- PREV -->
        <button
          class="square small primary"
          (click)="pageChange(navigation.currentPage + 1)"
          [disabled]="navigation.currentPage == navigation.maxPage">
          <i class="svg-icon angle-right"></i>
        </button>

        <!-- LAST -->
        <button
          class="square small primary"
          (click)="pageChange(navigation.maxPage)"
          [disabled]="navigation.currentPage == navigation.maxPage">
          <i class="svg-icon angle-double-right"></i>
        </button>
      </div>
    </div>
  </div>
</div>
