import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";

const usedModules = [MatButtonModule, MatDialogModule, MatTooltipModule, NoopAnimationsModule];

@NgModule({
  imports: usedModules,
  exports: usedModules,
})
export class MyMaterialModule {}
