<div class="global-dialog ow-dialog window-a primary no-transparent">
  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <div class="top-bar">
    <!-- BUILDING NAME AND LEVEL -->
    <div class="title">PANEL BIZNESOWY</div>
  </div>

  <div class="business-tabs" *ngIf="activeTab">
    <div
      *ngFor="let tab of tabs"
      (click)="changeTab(tab)"
      class="tab"
      [class.active-tab]="activeTab.id === tab.id"
    >
      {{ tab.label }}
    </div>
  </div>

  <ng-container [ngSwitch]="activeTab?.id">
    <app-business-task-vendors *ngSwitchCase="TABS.VENDORS"
                               [currentPage]="owDate.month | romanize"
                               [cardVendor]="cardData.vendor[this.currentTabDate]?.data"
                               [blockNext]="loading || cardData.vendor[this.currentTabDate]?.blockNext"
                               [blockPrev]="loading || cardData.vendor[this.currentTabDate]?.blockPrev"
                               (prev)="prev()"
                               (next)="next()"></app-business-task-vendors>
    <app-business-task-contests *ngSwitchCase="TABS.CONTESTS"
                                [currentPage]="owDate.month | romanize"
                                [cardContest]="cardData.consent[this.currentTabDate]?.data"
                                [owDate]="owDate"
                                [blockNext]="loading || cardData.consent[this.currentTabDate]?.blockNext"
                                [blockPrev]="loading || cardData.consent[this.currentTabDate]?.blockPrev"
                                (prev)="prev()"
                                (next)="next()"></app-business-task-contests>
    <app-business-task-cards *ngSwitchCase="TABS.TASKS"
                             [currentPage]="owDate.month | romanize"
                             [currentPageMonthName]="owDate.month_text_normal"
                             [cardTasks]="cardData.task[this.currentTabDate]?.data"
                             [blockNext]="loading || cardData.task[this.currentTabDate]?.blockNext"
                             [blockPrev]="loading || cardData.task[this.currentTabDate]?.blockPrev"
                             (prev)="prev()"
                             (next)="next()"></app-business-task-cards>
  </ng-container>

  <!-- RANKING BUTTON -->
  <div class="bottom-bar rankings-button">
    <div class="bar-button long">
      <div class="pagination">
        <div class="buttons">
          <button
            (click)="openRankList()"
            matTooltip='{{this.rankingsName}}'
            class="square small primary"
            matTooltipPosition="above"
            matTooltipShowDelay="300">
            <img [src]="'rankings.png' | asset: 'ui'"/>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
