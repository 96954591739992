<div
  class="global-dialog ow-dialog window-a primary"
  [class.padding-c]="task.card_template === CARD_TEMPLATE.SOFT || task.card_template === CARD_TEMPLATE.SOFT_WITH_ESTIMATED"
>
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button buttonSize="small"></m-ui-back-button>

  <!-- TOP BAR LONG -->
  <div class="top-bar long" *ngIf="data.player">
    <div class="title">
      {{ data.player.first_name }} {{ data.player.last_name }}
    </div>
  </div>

  <!-- NAVIGATION CENTER -->
  <ng-container *ngIf="notEmptyCardTasks.length > 1">
    <m-ui-navigation-center
      (prevClicked)="prev()"
      (nextClicked)="next()"
    ></m-ui-navigation-center>
  </ng-container>

  <div class="container-inner" *ngIf="task && owDate">
    <div>
      <div class="header">
        <!-- TITLE -->
        <div class="title">
          <!-- TITLE -->
          {{ task?.task_definition?.name ? task.task_definition.name + ', ' : ''}}{{ owDate.month_text_normal }} {{ owDate.year }}
          <i
            class="svg-icon circle"
            *ngIf="task.task_definition.description"
            (click)="openDialogAlert({title: task.task_definition.name, description: task.task_definition.description})"
          ></i>
        </div>

        <div class="pagination">
          <span>
            <div class="buttons" [class.disabled]="!cardTask.other_tasks.length">
            <!-- NEXT -->
            <button
              class="square big primary"
              (click)="changeOtherTask('prev')"
            >
              <i class="svg-icon chevron-left"></i>
            </button>
          </div>

          <div class="pages">
            {{ task.branch.name }}
          </div>

          <div class="buttons" [class.disabled]="!cardTask.other_tasks.length">
            <!-- PREV -->
            <button
              class="square big primary"
              (click)="changeOtherTask('next')"
            >
              <i class="svg-icon chevron-right"></i>
            </button>
          </div>
          </span>

        </div>
      </div>


      <!-- PROGRESS BARS -->
      <ng-container *ngIf="task.performance !== null && task.card_template !== CARD_TEMPLATE.PROMO">
        <div
          *ngIf="task.baseScope"
          class="progressbar-1"
          [class.static-width-title]="CARD_TEMPLATE.SOFT_WITH_ESTIMATED === task.card_template || CARD_TEMPLATE.HARD_WITH_ESTIMATED === task.card_template"
        >
          <div class="progress-title">
            Aktualna realizacja planu:
          </div>

          <div class="progress-bar-container">
            <div class="progress-container">
              <div
                class="fill one"
                [ngClass]="task.taskClasses"
                [style.width]="task.performance/task.baseScope.target_from * 100 + '%'"
              ></div>
            </div>
          </div>

          <div class="value-progressbar">
            {{task.performance | percent:'0.2-2'}}
          </div>
        </div>
      </ng-container>

      <!-- PROGRESS BAR ESTIMATED -->
      <ng-container
        *ngIf="CARD_TEMPLATE.SOFT_WITH_ESTIMATED === task.card_template || CARD_TEMPLATE.HARD_WITH_ESTIMATED === task.card_template">
        <div class="progressbar-1 static-width-title" *ngIf="task.baseScope">
          <div class="progress-title">
            Estymacja wykonania w miesiącu:
          </div>

          <div class="progress-bar-container">
            <div class="progress-container">
              <div
                class="fill two"
                [ngClass]="task.taskClasses"
                [style.width]="task.estimated_performance/task.baseScope.target_from * 100 + '%'"
              ></div>
            </div>
          </div>

          <div class="value-progressbar">
            {{task.estimated_performance | percent:'0.2-2'}}
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="task.card_template !== CARD_TEMPLATE.PROMO">
        <m-ui-separator
          [title]="'Banknoty otrzymasz za wykonanie planu'"
        ></m-ui-separator>

        <!-- PRICES -->
        <div class="prices">
          <div>
            <div
              class="price"
              *ngFor="let scope of task.scopes; let index = index"
            >
              <!-- PRICE ICON -->
              <div
                class="price-icon money"
                [ngClass]="'price-' + (index + 1)"
              ></div>

              <div class="value-container">
              <span class="value">
                <ng-container *ngIf="scope.currency_prizes.length; else zeroPrizeTpl">
                  <ng-container *ngFor="let currency of scope.currency_prizes">
                    <ng-container *ngIf="currency.currency_id === 1">
                      {{ currency['amount'] * task.multiplier | floor | NumberWithSpaces }}
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-template #zeroPrizeTpl>
                  0
                </ng-template>
              </span>
              </div>
              <div class="progress">
            <span>
              <ng-container *ngIf="scope.target_from === 0; else targetToTpl">
                <i class="svg-icon less-than-equal-regular"></i>
                {{ scope.target_to | percent }}
              </ng-container>
              <ng-template #targetToTpl>
                <i class="svg-icon greater-than-solid"></i>
                {{ scope.target_from | percent }}
              </ng-template>
            </span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <!-- PROMO TEMPLATE -->
    <ng-container *ngIf="task.card_template === CARD_TEMPLATE.PROMO">
      <div>
        <!-- LOGO -->
        <div class="logo">
          <h3 *ngIf="!task.task_definition.show_logo">{{task.task_definition.name}}</h3>
          <img *ngIf="task.task_definition.show_logo" [src]="task.task_definition.vendor.logo + '-logo.png' | asset: 'ui/komputronik/vendors'"/>
        </div>
      </div>

      <div>
        <m-ui-separator title="Nagroda pieniężna, o którą grasz każdego miesiąca"></m-ui-separator>

        <span class="min-info">Sprzedawaj promocyjne produkty i uzyskuj super premie w postaci złotych monet!</span>

        <div class="container promo">
          <!-- TABLE -->
          <table>
            <tbody>
            <tr>
              <td>Aktualna wartość Twojej nagrody pieniężnej:</td>
              <td class="no-wrap">
                {{ task.current_cash_price_value }}
              </td>
            </tr>
            </tbody>
          </table>

          <div class="contracts">
            <ng-container [ngTemplateOutlet]="contractsButtonTpl"></ng-container>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container
      *ngIf="task.card_template === CARD_TEMPLATE.HARD || task.card_template === CARD_TEMPLATE.HARD_WITH_ESTIMATED; else softTpl"
    >
      <m-ui-separator title="Nagroda pieniężna, o którą grasz każdego miesiąca"></m-ui-separator>

      <span class="min-info">Zasady wyliczania premii: W zależności od wykonania zadania przez cały zespół, <br> suma twoich punktów zdobytych w danym miesiącu zostanie pomnożona przez odpowiedni mnożnik</span>

      <div class="container">
        <!-- TABLE -->
        <ng-container *ngIf="task.performance !== null; else noBusinessResultTpl">
          <table>
            <tbody>
            <tr>
              <td class="with-icon">Liczba Twoich wypracowanych punktów:</td>
              <td class="no-wrap">
                {{ task.points_in_plus | floor:'float' }}
              </td>
            </tr>
            <ng-container *ngIf="task.bonus">
              <tr
                class="pointer"
                (click)="openDialogAlert({title: 'Wartość Twojej nagrody pieniężnej', description: 'Do momentu osiągnięcia przez salon 100% realizacji planu <br> – liczba punktów x 1.', textAlign: 'center', height: 'auto'})"
              >
                <td class="with-icon">
                  Wartość Twojej nagrody pieniężnej <i class="svg-icon circle"></i> :
                </td>
                <td class="no-wrap">
                  {{ task.current_cash_price_value | number:'1.2-2' }} zł
                </td>
              </tr>
              <tr
                class="pointer"
                (click)="openDialogAlert({title: 'Wartość Twojej nagrody <br> pieniężnej z bonusem', description: 'Po zrealizowaniu planu salonowego na poziomie 100% i więcej <br> – BONUS: liczba punktów x ' + (owDate.year < 2024 ? '1.2.' : '1.1.'), textAlign: 'center', height: 'auto'})"
              >
                <td class="with-icon">Wartość Twojej nagrody pieniężnej z bonusem <i class="svg-icon circle"></i> :</td>
                <td class="no-wrap">
                  {{ task.max_bonus_price_value | number:'1.2-2' }} zł
                </td>
              </tr>
            </ng-container>
            <ng-container *ngIf="!task.bonus">
              <tr
                class="pointer"
                (click)="openDialogAlert({title: 'Aktualna wartość Twojej nagrody pieniężnej', description: 'Wynika z iloczynu punktów indywidualnych <br> i stopnia realizacji celu przez salon.', textAlign: 'center', height: 'auto'})"
              >
                <td class="with-icon">Aktualna wartość Twojej nagrody pieniężnej <i class="svg-icon circle"></i> :</td>
                <td class="no-wrap">
                  {{ task.current_cash_price_value | number:'1.2-2' }} zł
                </td>
              </tr>

              <tr
                *ngIf="task.year === 2021 && task.month >= 4"
                class="pointer"
                (click)="openDialogAlert( {title: 'Możliwa wartość Twojej nagrody pieniężnej', description: 'Wartość Twojej nagrody pieniężnej przy realizacji sklepu <br> na poziomie 100% lub wyżej.', textAlign: 'center', height: 'auto'})"
              >
                <td class="with-icon">Możliwa wartość Twojej nagrody pieniężnej <i class="svg-icon circle"></i> :</td>
                <td class="no-wrap">
                  {{ task.max_bonus_price_value | number:'1.2-2' }} zł
                </td>
              </tr>
            </ng-container>
            <ng-container *ngIf="task.task_with_bonus">
              <tr
                class="pointer"
                (click)="openDialogAlert({title: 'Aktualna wartość Twojej nagrody pieniężnej 2', description: 'Wartość nagrody pieniężnej, którą otrzymasz bez względu na realizację salonu.', textAlign: 'center', height: 'auto'})"
              >
                <td class="with-icon">Aktualna wartość Twojej nagrody pieniężnej 2 <i class="svg-icon circle"></i> :</td>
                <td class="no-wrap">
                  {{ task.task_bonus_value | number:'1.2-2' }} zł
                </td>
              </tr>
            </ng-container>
            <tr *ngIf="task.points_in_minus_with_performance !== 0">
              <td class="no-wrap">
                Wartość korekty z tytułu odstąpienia od umowy:
              </td>
              <td>
                {{ task.points_in_minus_with_performance }} zł
              </td>
            </tr>
            </tbody>
          </table>

          <span class="min-info" *ngIf="task.min_valid !== null">
            {{ 'business.card-task-details.info' | myTranslate }}
          </span>
        </ng-container>

        <div class="contracts promo">
          <ng-container [ngTemplateOutlet]="contractsButtonTpl"></ng-container>
        </div>
      </div>
    </ng-container>


    <ng-template #softTpl>
      <div *ngIf="task.card_template !== CARD_TEMPLATE.PROMO" class="contracts soft">
        <ng-container [ngTemplateOutlet]="contractsButtonTpl"></ng-container>
      </div>
    </ng-template>

    <!-- NO BUSINESS RESULT TPL -->
    <ng-template #noBusinessResultTpl>
      <p class="description no-business"
         [innerHTML]="'business.card-task-details.info-no-business-result' | myTranslate"
      ></p>
    </ng-template>
  </div>

  <!-- RANKING BUTTON -->
  <div class="bottom-bar rankings-button" *ngIf="data.cardTask.rank">
    <div class="bar-button long">
      <div class="pagination">
        <div class="buttons">
          <button
            (click)="openRankList()"
            matTooltip='{{task.task_definition.name}}'
            class="square small primary"
            matTooltipPosition="above"
            matTooltipShowDelay="300">
            <img [src]="'rankings.png' | asset: 'ui'"/>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #valueTypeTpl let-value="value">
  <ng-container [ngSwitch]="task.task_definition.value_type">
    <!-- INT -->
    <ng-container *ngSwitchCase="VALUE_TYPE.INT">
      {{ value }}{{ task.task_definition.string_format }}
    </ng-container>
    <!-- FLOAT PERCENTAGE -->
    <ng-container *ngSwitchCase="VALUE_TYPE.FLOAT_PERCENTAGE">
      {{ value | percent:'1.2-2' }}
    </ng-container>
    <!-- FLOAT -->
    <ng-container *ngSwitchCase="VALUE_TYPE.FLOAT">
      {{ value | floor:'float' | number:'1.2-2' }}{{ task.task_definition.string_format }}
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #contractsButtonTpl>
  <button class="base secondary" (click)="openContracts()">Szczegóły</button>
</ng-template>
