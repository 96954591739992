import { Component, EventEmitter, Output } from "@angular/core";
import { Store } from "@ngrx/store";

import { AbstractInjectBaseComponent } from "../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../core/decorators/ow-inject.decorator";
import { MockState } from "../../../../../store/actions";
import { AppState } from "../../../../../store/state";
import { MockDialogListService } from "../../../services/mock-dialog-list.service";

@Component({
  selector: "app-mock-store-data",
  templateUrl: "./mock-store-data.component.html",
})
export class MockStoreDataComponent extends AbstractInjectBaseComponent {
  @OwInject(MockDialogListService) mockDialogService: MockDialogListService;
  @OwInject(Store) store: Store<AppState>;

  @Output() accept = new EventEmitter<string>();
  @Output() close = new EventEmitter();

  save() {
    this.store.dispatch(
      new MockState({
        ...JSON.parse(this.mockDialogService.mockStoreJSON),
      })
    );
    this.close.emit();
  }
}
