export const MOCK_BUILDING_SPECIAL = {
  api: {
    details: {
      upgrade_building_id: 215,
      required_building: {
        exists: false,
        name: "Salon Komputronik",
        level: 1,
        icon: "komputronik",
        group: "komputronik",
      },
      required_building_on_island: null,
      required_star: { star_id: 4, star_name: "Rok 1990" },
      building_time_in_seconds: 300,
      fast_building_time_in_seconds: 0,
      build_currency_prizes: [],
      build_product_prizes: [
        {
          product_id: 16,
          category: { storage_id: 1, trading_currency_id: null, id: 2 },
          name: "Pierwszy komputer",
          description:
            "Pierwszy komputer to sprzęt, kt&oacute;ry otworzył przed użytkownikami nowe możliwości w takich dziedzinach jak praca czy rozrywka. Miejsce pozyskania : port.",
          icon: "computer_old",
          amount: 3,
        },
      ],
      parameters: [],
      building_id: 214,
      group_type: "production",
      group_limit: 1,
      group_count: 0,
      category_id: 1,
      player_concurrence_builds_reached_error_message: null,
      group: "port",
      group_description: "Port to centrum handlu międzymiastowego, z kt&oacute;rego możesz zamawiać produkty.",
      icon: "port",
      automatic_product: null,
      automatic_currency: null,
      level: 1,
      name: "Port",
      additional_details: "",
      required_level: 10,
      required_population: 1100,
      building_time: 300,
      building_time_in_days: null,
      fast_building_time: 0,
      repair_building_time: 0,
      build_product_prices: [
        {
          product_id: 1,
          category: { storage_id: 1, id: 2 },
          name: "Drewno",
          description:
            "Odpowiednio obrobione i wyselekcjonowane kawałki drewna posłużą Ci do produkcji mebli oraz budowy budynków. Miejsce obróbki: Tartak.",
          icon: "wood",
          amount: 20,
        },
      ],
      exp_points: 415,
      attributes: [],
      probability_parameters: [],
      repair_prices: [],
      build_currency_prices: [
        { currency_id: 1, amount: 1630 },
        { currency_id: 2, amount: 300 },
      ],
      fast_build_currency_prices: [],
      fast_build_product_prices: [],
      daily_product_productions: [],
      upgrade_button_name: "Odbuduj",
      auto_delete: false,
      variants: [],
      original_building_time_in_seconds: 300,
      original_build_currency_prices: [
        { currency_id: 1, amount: 1630 },
        { currency_id: 2, amount: 300 },
      ],
      build_prices: [
        { currency_id: 1, amount: 1630 },
        { currency_id: 2, amount: 300 },
      ],
      original_build_prices: [
        { currency_id: 1, amount: 1630 },
        { currency_id: 2, amount: 300 },
      ],
      fast_build_prices: [],
      required_products: [
        {
          product_id: 1,
          category: { storage_id: 1, id: 2 },
          name: "Drewno",
          description:
            "Odpowiednio obrobione i wyselekcjonowane kawałki drewna posłużą Ci do produkcji mebli oraz budowy budynków. Miejsce obróbki: Tartak.",
          icon: "wood",
          amount: 20,
        },
      ],
      probability_parameters_percentage: 0,
    },
    production: [
      {
        has_required_invention: false,
        currency_prizes: [],
        product_prizes: [
          {
            product_id: 16,
            category: {
              storage_id: 1,
              trading_currency_id: null,
              id: 2,
              name: "Produkty",
              description: "Produkty",
              icon: "produkty",
            },
            name: "Pierwszy komputer",
            description:
              "Pierwszy komputer to sprzęt, kt&oacute;ry otworzył przed użytkownikami nowe możliwości w takich dziedzinach jak praca czy rozrywka. Miejsce pozyskania : port.",
            icon: "computer_old",
            required_level: 10,
            amount: 3,
          },
        ],
        production_recipe_id: 40,
        time_in_days: 1,
        time_in_minutes: null,
        invention: null,
        alternative_icon: null,
        alternative_name: null,
        production_product_prices: [],
        production_currency_prices: [{ currency_id: 2, amount: 420 }],
        fast_production_currency_prices: [],
        order: 40,
      },
    ],
  },
  ref: { playerTileId: 55197 },
};
