import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { tap } from "rxjs/operators";

import { WebSocketService } from "../../core/providers/websocket.service";
import { gameClearGameStatuses } from "../game/actions";
import { socketConnect, socketDisconnect, socketReconnectAttempt } from "./actions";

@Injectable()
export class SocketEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private webSocketService: WebSocketService
  ) {}

  socketConnect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(socketConnect),
        tap(() => {
          this.webSocketService.connect();
        })
      ),
    { dispatch: false }
  );

  socketDisconnect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(socketDisconnect),
        tap(() => {
          this.webSocketService.disconnect();
        })
      ),
    { dispatch: false }
  );

  socketReconnectAttempt = createEffect(
    () =>
      this.actions$.pipe(
        ofType(socketReconnectAttempt),
        tap(() => {
          this.store.dispatch(gameClearGameStatuses());
        })
      ),
    { dispatch: false }
  );
}
