import { Injectable } from "@angular/core";

import { ApiService } from "../../../../../../../core/providers/api.service";

@Injectable({
  providedIn: "root",
})
export class ApiBusinessRanksService {
  constructor(public apiService: ApiService) {}

  businessRanks({ year, month }: { year: number; month: number }) {
    const options = {
      params: {
        year,
        month,
      },
    };
    return this.apiService.get("business/ranks", options);
  }

  businessContest({
    year,
    month,
    taskDefinitionId,
    limit,
    branch,
    krs,
    makroregion
  }: {
    year: number;
    month: number;
    taskDefinitionId: number;
    limit: number;
    branch?: boolean;
    krs?: boolean;
    makroregion?: boolean;
    rank_id: number;
  }) {
    const options = {
      params: {
        limit,
        branch,
        krs,
        makroregion,
      },
    };

    return this.apiService.get(`business/ranks/contest-results/${year}/${month}/${taskDefinitionId}`, options);
  }

  businessRanksBranchResults({
    year,
    month,
    taskDefinitionId,
    limit,
    branch,
    krs,
    makroregion,
    rank_id,
  }: {
    year: number;
    month: number;
    taskDefinitionId: number;
    limit: number;
    branch?: boolean;
    krs?: boolean;
    makroregion?: boolean;
    rank_id: number;
  }) {
    const options = {
      params: {
        limit,
        branch,
        krs,
        makroregion,
      },
    };

    return this.apiService.get(`business/ranks/branch-results/${year}/${month}/${taskDefinitionId}/${rank_id}`, options);
  }

  businessRanksPlayerResults({
    year,
    month,
    taskDefinitionId,
    limit,
    branch,
    krs,
    makroregion,
    rank_id,
  }: {
    year: number;
    month: number;
    taskDefinitionId: number;
    limit: number;
    branch?: boolean;
    krs?: boolean;
    makroregion?: boolean;
    rank_id: number;
  }) {
    const options = {
      params: {
        limit,
        branch,
        krs,
        makroregion,
      },
    };

    return this.apiService.get(`business/ranks/player-results/${year}/${month}/${taskDefinitionId}/${rank_id}`, options);
  }
}
