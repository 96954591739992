import { Pipe, PipeTransform } from "@angular/core";

import { BuildingConfig } from "../../game-engine/interfaces/building-config";
import { BuildingDetailsConfig } from "../../game-engine/interfaces/building-details-config";
import { PlayerBuilding } from "../../game-engine/interfaces/player-building.config";
import { BuildingsService } from "../../services/buildings.service";

@Pipe({
  name: "buildingIcon",
})
export class BuildingIconPipe implements PipeTransform {
  constructor(private buildingsService: BuildingsService) {}

  transform(building: PlayerBuilding | BuildingConfig | BuildingDetailsConfig, asUrl: boolean) {
    const imagePath = this.buildingsService.getBuildingIcon(building as PlayerBuilding);
    return asUrl ? `url(${imagePath})` : imagePath;
  }
}
