import {Component, OnInit} from '@angular/core';

import { AbstractForgotPasswordComponent } from "../../../../abstract/abstract-forgot-password.component";

@Component({
  selector: "auth-forgot-password",
  templateUrl: "./forgot-password.component.html",
})
export class ForgotPasswordComponent extends AbstractForgotPasswordComponent implements OnInit{

  ngOnInit() {
    super.ngOnInit();
  }
}
