<div class="global-dialog ow-dialog window-a primary no-transparent">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button></m-ui-back-button>

  <loading></loading>

  <ng-container *ngIf="data.eventShop as eventShop">
    <!-- TOP -->
    <div class="top-container">
      <div class="shop-title">
        {{ eventShop.title }}
      </div>

      <div class="shop-info" *ngIf="eventShop.event_shop_id !== 100">
        <div
          class="item"
          matTooltip="Czas na zbieranie żetonów"
          matTooltipClass="delayed"
        >
          <ng-container *ngIf="({currency_id: eventShop.prize_currency_id, amount: null} | singleCurrency) as currency">
            <img
              [src]="('cointime_' + currency.key + '.png') | asset:'ui'"
              class="icon"
            />
          </ng-container>
          {{ eventShop.currency_collection_start | date: 'dd.MM.yy'}} -
          {{ eventShop.currency_collection_end | date: 'dd.MM.yy'}}
        </div>

        <div
          class="item"
          matTooltip="Czas na wymianę żetonów"
          matTooltipClass="delayed"
        >
          <div
            class="icon-container"
            [class.icon-margin]="!eventShop.is_repeatable"
          >
            <img
              [src]="'shop.png' | asset:'ui'"
              class="icon"
            />
            <img
              *ngIf="!eventShop.is_repeatable"
              [src]="'exclamation.png' | asset:'ui'"
              class="icon not-repeatable"
              matTooltip="Te żetony wykorzystasz tylko w podanym terminie"
              matTooltipPosition="above"
              matTooltipClass="delayed"
            />
          </div>
          {{ eventShop.open_start | date: 'dd.MM.yy'}} -
          {{ eventShop.open_end | date: 'dd.MM.yy'}}
        </div>
      </div>
    </div>

    <div class="main-container loading-opacity">
      <div class="shop-details-container">
        <div
          *ngIf="({currency_id: eventShop.prize_currency_id, amount: null} | singleCurrency) as currency"
          class="currency"
        >
          <img
            class="icon"
            [src]="currency.iconUrlBig"
            matTooltip="Historia żetonów"
            matTooltipClass="delayed"
            [class.cursor-pointer]="currency.show_currency_history"
            (click)="openHistoryTransaction(currency)"
          />
          <i class="icon-history fas fa-history"></i>
          {{ ((playerService.player.currency_balances | currencyGameBalance:currency.key)?.balance) || 0  | NumberWithSpaces }}
        </div>

        <ow-perfect-scrollbar scrollGradient="true">
          <div [innerHTML]="eventShop.description" class="description"></div>
        </ow-perfect-scrollbar>

        <div class="description" *ngIf="eventShop.terms_url">
          Więcej szczegółów znajdziesz <a [href]="eventShop.terms_url" target="_blank">TUTAJ</a>
        </div>
      </div>

      <ow-perfect-scrollbar *ngIf="products">
        <div class="products-container">
          <div
            *ngFor="let product of products"
            class="product-container"
            [class.to-low-lvl]="!playerService.allowRequiredLevel(product.required_level)"
            [class.to-low-star]="product.required_star && product.required_star.star_id > playerService.player.star.star_id"
            (click)="openEventShopProductDetails(product)"
          >
            <div class="left">
              <img
                class="icon"
                [src]="product.icon + '.png' | asset:'products_eventmarket/big'"
              >
            </div>

            <div class="right">
              <div class="product-title">
                {{ product.name }}
              </div>

              <div class="product-details">
                <div class="cost">
                  {{ product.price | NumberWithSpaces }}

                  <img
                    class="icon-currency"
                    [src]="({currency_id: eventShop.prize_currency_id, amount: null} | singleCurrency).iconUrl"
                  />
                </div>

                <div class="limit">
                  <span *ngIf="product.limit_per_person > 0; else infinityTpl">
                    {{ ((product.limit_per_person - product.limit_per_person_left) + '/' + product.limit_per_person) }}
                  </span>
                </div>
              </div>
            </div>

            <div class="to-low-lvl-info">
              wymagany poziom {{ product.required_level }}
            </div>

            <div
              class="to-low-star-info"
              *ngIf="product.required_star && product.required_star.star_id > playerService.player.star.star_id"
            >
              <span>
                wymagana {{ product.required_star.star_name }}
              </span>
            </div>
          </div>
        </div>
      </ow-perfect-scrollbar>
    </div>
  </ng-container>
</div>

<ng-template #infinityTpl>
  <i class="fas fa-infinity"></i>
</ng-template>
