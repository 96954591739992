import { Injectable } from "@angular/core";

import { ApiService } from "../../../../../../../core/providers/api.service";
import { PlayerService } from "../../../../../../player/providers/player.service";

@Injectable({
  providedIn: "root",
})
export class ApiBusinessBasicActionsService {
  constructor(
    private apiService: ApiService,
    private playerService: PlayerService
  ) {}

  basicActionCurrent(playerId?: number) {
    playerId = playerId || this.playerService.getActivePlayerId();
    return this.apiService.get(`business/player/${playerId}/basic-actions/current`);
  }

  basicActionDetails({ date, playerId }: { date: string; playerId?: number }) {
    playerId = playerId || this.playerService.getActivePlayerId();
    return this.apiService.get(`business/player/${playerId}/basic-actions/details/${date}`);
  }
}
