import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { ApiService } from "../../../../../../../core/providers/api.service";
import { SchemaValidatorService } from "../../../../../../../services/schema-validator/schema-validator.service";
import { GroupMissionDetailsInterface, GroupMissionInterface } from "../../../interfaces/core";

@Injectable({
  providedIn: "root",
})
export class ApiMissionGroupService {
  constructor(
    private apiService: ApiService,
    private schemaValidator: SchemaValidatorService
  ) {}

  getMissionGroups(): Observable<GroupMissionInterface[]> {
    return this.apiService.get("mission-groups").pipe(
      tap(response => {
        this.schemaValidator.validateResponse({
          url: "mission-groups",
          payload: {},
          response: response,
          schema: "missionGroupsGame",
        });
      })
    );
  }

  getMissionGroupsDetails(mission_group_id: number): Observable<GroupMissionDetailsInterface> {
    return this.apiService.get(`mission-groups/${mission_group_id}`).pipe(
      tap(response => {
        this.schemaValidator.validateResponse({
          url: `mission-groups/${mission_group_id}`,
          payload: {},
          response: response,
          schema: "missionGroupDetailsGame",
        });
      })
    );
  }

  getMissionGroupReward(mission_group_id: number) {
    return this.apiService.post(`mission-groups/${mission_group_id}/reward`);
  }
}
