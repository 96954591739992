import { Injectable, OnDestroy } from "@angular/core";

import { AbstractEventDialogsService } from "../../../../../../core/abstracts/abstract-event-dialogs.service";
import { unsubscribeObject } from "../../../../../../core/utility/unsubscribe-array";
import { EVENT_DIALOGS_DAILY_QUESTIONS } from "../../consts/event-dialogs/event-dialogs.const";

@Injectable({
  providedIn: "root",
})
export class DailyQuestionsEventDialogsService extends AbstractEventDialogsService implements OnDestroy {
  getEventDialogs() {
    return {
      coreEventDialogs: EVENT_DIALOGS_DAILY_QUESTIONS,
      customEventDialogs: EVENT_DIALOGS_DAILY_QUESTIONS,
    };
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
