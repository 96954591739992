import { createAction, props } from "@ngrx/store";

import { Credentials } from "../../../modules/auth/interfaces/credentials";

export enum ActionTypes {
  AUTH_START = "[auth] start user authentication",
  AUTH_SUCCESS = "[auth] user authentication success",
  AUTH_FAIL = "[auth] user authentication failed",
  AUTH_LOGOUT = "[auth] start user logout",
}

export const loginStart = createAction(ActionTypes.AUTH_START, props<Credentials>());
export const loginSuccess = createAction(ActionTypes.AUTH_SUCCESS);
export const loginFailed = createAction(ActionTypes.AUTH_FAIL, props<any>());
export const loginLogout = createAction(ActionTypes.AUTH_LOGOUT);
