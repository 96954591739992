import { Injectable } from "@angular/core";

import { MockDialogListService } from "../../../../../mock-dialogs/services/mock-dialog-list.service";
import { EVENT_DIALOGS_NAMES_MISSION } from "../../consts/core/event-dialogs/event-names";
import { MOCK_MISSION_DETAILS } from "../consts/mock-mission-details.const";
import { MOCK_MISSION_TRANSFER } from "../consts/mock-mission-transfer.const";
import { MOCK_MISSION } from "../consts/mock-missions.const";

@Injectable({ providedIn: "root" })
export class MockMissionsService {
  constructor(private mockDialogListService: MockDialogListService) {
    this.mockDialogListService.keySubscription.subscribe(key => {
      switch (key) {
        case EVENT_DIALOGS_NAMES_MISSION.MISSIONS: {
          this.mockDialogListService.setMockApiData(EVENT_DIALOGS_NAMES_MISSION.MISSIONS, MOCK_MISSION.api);
          break;
        }
        case EVENT_DIALOGS_NAMES_MISSION.MISSION_DETAILS: {
          this.mockDialogListService.setDialogRef(MOCK_MISSION_DETAILS.ref);
          this.mockDialogListService.setMockApiData(
            EVENT_DIALOGS_NAMES_MISSION.MISSION_DETAILS,
            MOCK_MISSION_DETAILS.api
          );
          break;
        }
        case EVENT_DIALOGS_NAMES_MISSION.MISSION_TRANSFER: {
          this.mockDialogListService.setDialogRef(MOCK_MISSION_TRANSFER.ref);
          break;
        }
      }
    });
  }
}
