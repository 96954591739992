import { MAP_ATLAS_NAME } from "../../../constants";
import { MyGame } from "../../classes/core/MyGame";
import { MyScene } from "../../classes/core/MyScene";
import { TILE_MENU_ATLAS } from "../../scenes-main/main.constants";
import { getWorldSceneConfig } from "../helpers/WorldScene.helper";
import { WORLD_MAIN_SCENE, WORLD_PRELOAD_SCENE } from "../world.constants";

export class WorldPreloadScene extends MyScene {
  constructor(sceneClassConfig?: Phaser.Types.Scenes.SettingsConfig) {
    sceneClassConfig = sceneClassConfig ? sceneClassConfig : { key: WORLD_PRELOAD_SCENE };
    super(sceneClassConfig);
  }

  assetsToPreload: string[];

  init(data = {} as any) {
    this.assetsToPreload = data.assetsToPreload;
    this.passedData = data.passedData;
  }

  provideSceneConfig() {
    return getWorldSceneConfig();
  }

  customPreload() {}

  preload(): void {
    super.preload();
    const sceneConfig = this.provideSceneConfig();
    // @ts-ignore
    this.sceneConfig = sceneConfig;
    this.gameService = (this.game as MyGame).gameService;

    this.customPreload();

    if (sceneConfig.mapAtlasDirectory) {
      this.load.atlas(
        MAP_ATLAS_NAME,
        this.gameService.assetsService.getAssetPath(`${sceneConfig.mapAtlasDirectory}/atlas/map-atlas.png`),
        this.gameService.assetsService.getAssetPath(`${sceneConfig.mapAtlasDirectory}/atlas/map-atlas.json`)
      );
    }

    if (sceneConfig.tileMenuAtlasDirectory) {
      this.load.atlas(
        TILE_MENU_ATLAS,
        this.gameService.assetsService.getAssetPath(`${sceneConfig.tileMenuAtlasDirectory}/tile-menu-atlas.png`),
        this.gameService.assetsService.getAssetPath(`${sceneConfig.tileMenuAtlasDirectory}/tile-menu-atlas.json`)
      );
    }

    const preloadValue = this.add.text(-10000, -10000, `test`, {
      fontSize: "100px",
      fontFamily: "Futura Heavy",
      align: "center",
      fontStyle: "bold",
    });
    preloadValue.setOrigin(0.5);

    this.gameService = (this.sys.game as MyGame).gameService;

    this.load.on("progress", progress => {
      this.gameService.progressLoading = Math.round(100 * progress);
      preloadValue.setText(Math.round(100 * progress) + "%");
    });

    this.load.on("complete", progress => {
      preloadValue.destroy();
    });

    this.assetsToPreload.forEach(asset => {
      if (asset) {
        this.load.image(asset, asset);
      }
    });

    const gl = this.game.renderer["gl"];
    if (gl) {
      console.log("MAX TEXTURE SIZE", gl.getParameter(gl.MAX_TEXTURE_SIZE));
    }

    // @ts-ignore
    sceneConfig.backgroundImages.forEach(image => {
      this.load.image(
        image.key,
        this.gameService.assetsService.getAssetPath(`${sceneConfig.mapDirectoryName}/${image.filename}`)
      );
    });

    this.cameras.main.setBackgroundColor("#5065aa");
  }

  create(): void {
    this.gameService.guiService.isSplashShow.next(false);
    this.scene.start(WORLD_MAIN_SCENE, this.passedData);
  }
}
