import { Component } from "@angular/core";

import { BASIC_BOOT_SCENE } from "../../../../../../game-engine/scenes-basic/basic.constants";
import { AbstractBranchPreviewComponent } from "../../../../abstract/core/abstract-branch-preview.component";

@Component({
  selector: "branch-preview",
  templateUrl: "./branch-preview.component.html",
})
export class BranchPreviewComponent extends AbstractBranchPreviewComponent {
  BASIC_BOOT_SCENE = BASIC_BOOT_SCENE;
}
