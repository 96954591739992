import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { SharedModule } from "../../shared/shared.module";
import { AuthService } from "../api/services/auth.service";
import { AuthRoutingModule } from "../auth-routing.module";

@NgModule({
  imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule, RouterModule, AuthRoutingModule],
  exports: [SharedModule, FormsModule, ReactiveFormsModule, RouterModule, AuthRoutingModule],
  declarations: [],
  providers: [AuthService],
})
export class AuthAbstractModule {}
