import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { environment } from "../../../environments/environment";
const SchemaValidations = require("/src/scripts-build/schema-validator.compiled.js");

export type SchemaValidatorPayload = {
  url: string;
  payload: any;
  response: any;
  isValid?: boolean;
  schema?: any;
  error?: any;
};

@Injectable({
  providedIn: "root",
})
export class SchemaValidatorService {
  validatedResponses: BehaviorSubject<SchemaValidatorPayload[]> = null;
  environment = environment;

  constructor() {
    this.validatedResponses = new BehaviorSubject<SchemaValidatorPayload[]>([
      {
        url: "initialized",
        payload: {},
        response: {},
        isValid: true,
      },
    ]);
  }

  getResponses(): Observable<SchemaValidatorPayload[]> {
    return this.validatedResponses;
  }

  validateResponse(payload: SchemaValidatorPayload) {
    if (!environment.devel) {
      return;
    }
    const schemaValidator = SchemaValidations[payload.schema];
    const isValid = schemaValidator(payload.response);
    const validationStatus = {
      url: payload.url,
      payload: payload.payload,
      isValid: isValid,
      response: payload.response,
      error: schemaValidator.errors,
    };

    const oldResponses = this.validatedResponses.value;
    if (oldResponses.length > 100) {
      oldResponses.shift();
    }

    this.validatedResponses.next([...oldResponses, validationStatus]);

    return void 0;
  }
}
