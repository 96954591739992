import { Injectable } from "@angular/core";
import { clone } from "ramda";

import {
  BaseCurrency,
  Currency,
  CurrencyBalance,
  CurrencyBalanceDetails,
  CurrencyDefinition,
} from "../interfaces/currency";
import { AssetsService } from "./assets.service";

@Injectable({
  providedIn: "root",
})
export class CurrencyService {
  currencyDefinitions: CurrencyDefinition[] = [];

  constructor(private assetsService: AssetsService) {}

  setCurrencyDefinitions(currencies: CurrencyDefinition[]) {
    this.currencyDefinitions = currencies;
  }

  getCurrency(currencies: CurrencyDefinition[], currencyKey: string) {
    let currency;

    if (Array.isArray(currencies)) {
      currency = currencies.find(_currency => {
        return _currency.key === currencyKey;
      });
    }

    return clone(currency);
  }

  getCurrencyDefinition(currency: BaseCurrency): CurrencyDefinition {
    const searchCurrencyDefinition = this.currencyDefinitions.find(item => {
      return item.currency_id === currency.currency_id;
    });

    return {
      ...currency,
      ...searchCurrencyDefinition,
      ...this.setImage({ ...searchCurrencyDefinition }),
    };
  }

  getCurrencyBalance(currency: Currency, playerCurrencyBalances: CurrencyBalanceDetails[]): CurrencyBalance {
    let searchCurrencyBalance = playerCurrencyBalances.find(
      playerCurrencyBalance => playerCurrencyBalance.currency_id === currency.currency_id
    );

    // @todo: store definitions player balances
    if (!searchCurrencyBalance) {
      searchCurrencyBalance = this.getCurrencyDefinition(currency) as CurrencyBalanceDetails;
      searchCurrencyBalance.balance = 0;

      const balance_gross_preformatted = this.divisionCurrency(searchCurrencyBalance, searchCurrencyBalance.balance);
      const amount_gross_preformatted = this.divisionCurrency(searchCurrencyBalance, currency.amount);
      const amount_formatted = amount_gross_preformatted || currency.amount;
      const balance_formatted = balance_gross_preformatted || searchCurrencyBalance.balance || 0;

      return {
        ...currency,
        ...searchCurrencyBalance,
        balance_formatted,
        have: amount_formatted <= balance_formatted,
        amount_formatted,
      };
    }

    const balance_gross = this.divisionCurrency(searchCurrencyBalance, searchCurrencyBalance.balance);
    const amount_gross = this.divisionCurrency(searchCurrencyBalance, currency.amount);
    const amount = amount_gross || currency.amount;
    let balance = balance_gross || searchCurrencyBalance.balance || 0;

    if (searchCurrencyBalance.is_gross && balance !== 0) {
      balance = new Intl.NumberFormat("pl", { minimumFractionDigits: 2 }).format(Number(balance));
    }

    return {
      ...currency,
      ...searchCurrencyBalance,
      balance,
      have: amount <= balance,
      amount,
    };
  }

  getCurrencyDefinitions(currencies: BaseCurrency[]) {
    if (Array.isArray(currencies)) {
      currencies = currencies.map(currency => {
        return this.getCurrencyDefinition(currency);
      });
    }

    return currencies;
  }

  getCurrencyDefinitionByKey(currencyKey: string): CurrencyDefinition {
    return this.getCurrency(this.currencyDefinitions, currencyKey);
  }

  setImage(currency: CurrencyDefinition): CurrencyDefinition {
    if (currency) {
      const copy = { ...currency };

      copy.iconUrlBig = this.assetsService.getAssetPath(`currency/big/${currency.key}.png`);
      copy.iconUrl = this.assetsService.getAssetPath(`currency/basic/${currency.key}.png`);

      return copy;
    }

    return currency;
  }

  divisionCurrency(currency: CurrencyDefinition, value: number) {
    let value_gross;

    if (currency.is_gross) {
      value_gross = value / 100;
    }

    return value_gross;
  }
}
