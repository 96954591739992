import { Injectable, NgZone } from "@angular/core";

import { PlayerService } from "../../modules/player/providers/player.service";
import { DialogService } from "../../modules/shared/providers/dialog.service";
import { orientationModes } from "../consts/core/orientation-modes.enum";
import { ApiOptions } from "../interfaces/api-options";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class NativeAppService {
  nativeInterface: {
    setOrientation: (number) => void;
    gameStarted: (playerId: number) => void;
    closeApp: () => void;
  } = window["NativeInterface"];

  constructor(
    private dialogService: DialogService,
    private zone: NgZone,
    public apiService: ApiService,
    public playerService: PlayerService
  ) {
    this.setAndroidServiceInWindow();
  }

  private setAndroidServiceInWindow() {
    window["AndroidBridge"] = this; //legacy BC
    window["NativeBridge"] = this; //new
  }

  setNativeAppOrientation(orientation: orientationModes) {
    if (this.nativeInterface) {
      this.nativeInterface.setOrientation(orientation);
    }
  }

  gameStarted() {
    console.log('Game started', window['NativeInterface'], this.nativeInterface); // TODO: remove after testing
    if (this.nativeInterface) {
      const playerId = this.playerService.getMePlayerId();
      this.nativeInterface.gameStarted(playerId);
    }
  }

  closeApp() {
    if (this.nativeInterface) {
      this.nativeInterface.closeApp();
    }
  }

  closeWindow() {
    //wywolywane przez aplikacje natywne, przy kliknieciu przycisku wstecz
    if (DialogService.openDialogs.length) {
      this.zone.run(() => {
        this.dialogService.closeActive();
      });
    } else {
      this.zone.run(() => {
        this.dialogService.openConfirm(
          {
            title: "Uwaga!",
            description: "Czy na pewno chcesz zamknąć aplikację?",
          },
          confirm => {
            if (confirm) {
              this.closeApp();
            }
          }
        );
      });
    }
  }

  registerForPush(token: string, appVersion: string) {
    this.pushTokenRequest(token, appVersion).subscribe(() => {});
  }
  registerForPushWithType(token: string, type: number, appVersion: string) {
    this.pushTokenWithTypeRequest(token, type, appVersion).subscribe(() => {});
  }

  private pushTokenRequest(token: string, appVersion: string) {
    //legacy
    const options: ApiOptions = {
      body: {
        token,
        app_version: appVersion,
        type: 1,
      },
    };

    return this.apiService.post("push-token", options);
  }
  private pushTokenWithTypeRequest(token: string, type: number, appVersion: string) {
    const options: ApiOptions = {
      body: {
        token,
        app_version: appVersion,
        type: type,
      },
    };

    return this.apiService.post("push-token", options);
  }
}
