import { Directive, EventEmitter, Injectable, Input, Output } from "@angular/core";

import { AbstractInjectBaseComponent } from "../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../core/decorators/ow-inject.decorator";
import { DialogService } from "../../../../shared/providers/dialog.service";
import { CONFIG_CLOSE_BUTTON } from "../config/core/close-button.config";

@Directive()
@Injectable()
export abstract class AbstractCloseButtonComponent extends AbstractInjectBaseComponent {
  @OwInject(DialogService) dialogService: DialogService;
  @Input() preventDefault: boolean;
  @Input() buttonLayout = CONFIG_CLOSE_BUTTON.BUTTON_LAYOUT;
  @Input() buttonSize = CONFIG_CLOSE_BUTTON.BUTTON_SIZE;
  @Input() buttonColor = CONFIG_CLOSE_BUTTON.BUTTON_COLOR;
  @Input() otherClass = CONFIG_CLOSE_BUTTON.OTHER_CLASS;
  @Output() clicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  click() {
    this.clicked.emit(true);

    if (!this.preventDefault) {
      this.dialogService.closeAll();
    }
  }
}
