import { Injectable } from "@angular/core";

import { ApiOptions } from "../../../../../../../core/interfaces/api-options";
import { ApiService } from "../../../../../../../core/providers/api.service";
import { PlayerService } from "../../../../../../player/providers/player.service";
import { GetPlayerTasksRequest } from "../interfaces/get-player-tasks-request.interface";
import { PatchPlayerTasksCancelRequest } from "../interfaces/patch-player-tasks-cancel-request.interface";
import { PatchPlayerTasksChooseRequest } from "../interfaces/patch-player-tasks-choose-request.interface";

@Injectable({
  providedIn: "root",
})
export class ApiBusinessService {
  constructor(
    private apiService: ApiService,
    private playerService: PlayerService
  ) {}

  playerTasks({ month, year, playerId }: GetPlayerTasksRequest) {
    const options: ApiOptions = {
      params: {
        month,
        year,
      },
    };

    playerId = playerId || this.playerService.getActivePlayerId();

    return this.apiService.get(`business/player/${playerId}/tasks`, options);
  }

  playerTasksVendors({ month, year, playerId }: GetPlayerTasksRequest) {
    const options: ApiOptions = {
      params: {
        month,
        year,
      },
    };

    playerId = playerId || this.playerService.getActivePlayerId();

    return this.apiService.get(`business/player/${playerId}/vendor/tasks`, options);
  }

  playerContests({ month, year }: GetPlayerTasksRequest) {
    const options: ApiOptions = {
      params: {
        month,
        year,
      },
    };

    return this.apiService.get(`business/contests`, options);
  }

  playerTasksChoose({ player_task_id }: PatchPlayerTasksChooseRequest) {
    return this.apiService.patch(`business/player-tasks/${player_task_id}/choose`);
  }

  cancelTask({ player_task_id }: PatchPlayerTasksCancelRequest) {
    return this.apiService.patch(`business/player-tasks/${player_task_id}/cancel`);
  }

  getPlayerTask(player_task_id) {
    return this.apiService.get(`business/player-tasks/${player_task_id}`);
  }
}
