export const MOCK_MISSION_TRANSFER = {
  api: {},
  ref: {
    condition: {
      player_mission_condition_id: 244745,
      is_hand_over: true,
      can_hand_over: false,
      target_type: "integer",
      center_map: null,
      name: "Przekaż 5 monitorów",
      condition_type: 13,
      product: {
        product_id: 18,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 2,
          name: "Produkty",
          description: "Produkty",
          icon: "produkty",
        },
        name: "Monitor ",
        description:
          "Monitor to Twoje okno na wirtualny świat, gdy zdecydujesz się na tradycyjny PC. Miejsce produkcji: Fabryka elektroniki.",
        icon: "monitor",
      },
      currency: null,
      target: 5,
      value: 0,
      completed_at: null,
    },
  },
};
