import { Directive, Injectable } from "@angular/core";

import { MessageDetailsChestSuccess } from "../../interfaces/core/message-details-chest-success.interface";
import { AbstractMessageDetailsBaseComponent } from "./abstract-message-details-base.component";

@Directive()
@Injectable()
export abstract class AbstractMessageDetailsChestFailedComponent extends AbstractMessageDetailsBaseComponent {
  message: MessageDetailsChestSuccess;
}
