import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";

import { ApiOptions } from "../../../core/interfaces/api-options";
import { ApiService } from "../../../core/providers/api.service";
import { SchemaValidatorService } from "../../../services/schema-validator/schema-validator.service";
import { User } from "../interfaces/user";

@Injectable({
  providedIn: "root",
})
export class UserService {
  me: User;

  constructor(
    private apiService: ApiService,
    private schemaValidator: SchemaValidatorService
  ) {}

  getMe() {
    return this.apiService.get("user/me").pipe(
      tap(response => {
        this.schemaValidator.validateResponse({
          url: "user/me",
          payload: {},
          response: response,
          schema: "userMeUser",
        });
      })
    );
  }

  setDefaultPlayer(playerId) {
    const options: ApiOptions = {
      body: {
        selected_player: playerId,
      },
    };
    return this.apiService.patch("user/me/set-default-player", options);
  }

  changePassword(data) {
    const options = {
      body: data,
    };
    return this.apiService.post("user/change-password", options);
  }

  changePush(push_enabled: boolean) {
    const options: ApiOptions = {
      body: {
        push_enabled,
      },
    };

    return this.apiService.patch("user/change-push", options);
  }
}
