import { Component, EventEmitter, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AbstractInjectBaseComponent } from "src/app/core/abstracts/abstract-inject-base.component";
import { OwInject } from "src/app/core/decorators/ow-inject.decorator";
import { MyTranslatePipe } from "src/app/core/pipes/my-translate.pipe";
import { CardTask, ContestCard } from "../../../../interfaces/custom/card-task.interface";
import { GetPlayerTasksRequest } from "../../../../api/custom/interfaces/get-player-tasks-request.interface";
import { prepareCardTask } from "../../../../helpers/custom";
import { OwDate } from "../../../../../../../../core/utility/ow-date.class";
import { ApiBusinessService } from "../../../../api/custom/services/api-business.service";
import { EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM } from "../../../../../rankings/consts/custom/event-dialogs/event-names.const";
import { RANK_LIST_LOCATION } from "../../../../../rankings/consts/custom/rank-list-location.const";
import { EventEmitterDialogsService } from "../../../../../../../../core/services/core/event-emitter-dialogs.service";
import { RANKS_TABS } from "../../../../../rankings/interfaces/custom/rank-type.interface";

interface CardData {
  data: CardTask[];
  blockNext: boolean;
  blockPrev: boolean;
}

interface ConsentData {
  data: ContestCard[];
  blockNext: boolean;
  blockPrev: boolean;
}

@Component({
  selector: "app-business-dashboard",
  templateUrl: "./dashboard.component.html",
})
export class DashboardComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(MAT_DIALOG_DATA) data: DashboardComponent;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(ApiBusinessService) apiBusinessService: ApiBusinessService;

  translatePipe: MyTranslatePipe = new MyTranslatePipe();
  rankingsName = this.translatePipe.transform("business.rankings");
  TABS = {
    TASKS: "Tasks",
    CONTESTS: "Contests",
    VENDORS: "Vendors",
  };
  tabs: { id: string; label: string }[] = [
    {
      id: this.TABS.VENDORS,
      label: this.translatePipe.transform("business.tabs.vendors"),
    },
    {
      id: this.TABS.CONTESTS,
      label: this.translatePipe.transform("business.tabs.contests"),
    },
    {
      id: this.TABS.TASKS,
      label: this.translatePipe.transform("business.tabs.tasks"),
    },
  ];

  activeTab: { id: string; label: string };
  owDate: OwDate = new OwDate();
  maxPaginationDate = null;
  currentTabDate = "";
  cardData: {
    task: {
      [key: string]: CardData;
    };
    consent: {
      [key: string]: ConsentData;
    };
    vendor: {
      [key: string]: CardData;
    };
  } = {
    task: {},
    consent: {},
    vendor: {},
  };
  loading = false;

  ngOnInit() {
    this.updateCurrentTabDate();

    // set common task as a starter tab
    this.changeTab(this.tabs[2]);

    this.maxPaginationDate = this.getNextTabDate(new OwDate());
  }

  changeTab(tab: { id: string; label: string }) {
    // update current pagination date
    // this.owDate = new OwDate();
    this.updateCurrentTabDate();

    // update tab selection
    this.activeTab = tab;

    // get current cards/tasks/consents
    this.checkIfCardsAreAlreadyLoaded();
  }

  // updates current date text; reference switching purposes
  updateCurrentTabDate() {
    this.currentTabDate = `${this.owDate.month}_${this.owDate.year}`;
  }

  // get reference name for previous month data object
  getPrevTabDate(owDate: OwDate): string {
    if (this.owDate.month > 1) {
      return `${this.owDate.month - 1}_${owDate.year}`;
    } else {
      return `12_${owDate.year - 1}`;
    }
  }

  openRankList() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM.RANKING_LIST,
      config: {
        data: {
          location: RANK_LIST_LOCATION.BASE,
          openRankType: RANKS_TABS.BUSINESS,
          showBackButton: true,
        },
      },
    });
  }

  // get reference name for previous month data object
  getNextTabDate(owDate: OwDate): string {
    if (this.owDate.month < 12) {
      return `${this.owDate.month + 1}_${owDate.year}`;
    } else {
      return `1_${owDate.year + 1}`;
    }
  }

  // button prev action; change pagination date
  prev() {
    if (this.owDate.month > 1) {
      this.owDate.set({ month: this.owDate.month - 1, year: this.owDate.year });
    } else {
      this.owDate.set({ month: 12, year: this.owDate.year - 1 });
    }

    this.updateCurrentTabDate();
    this.checkIfCardsAreAlreadyLoaded();
  }

  // button next action; change pagination date
  next() {
    if (this.owDate.month < 12) {
      this.owDate.set({ month: this.owDate.month + 1, year: this.owDate.year });
    } else {
      this.owDate.set({ month: 1, year: this.owDate.year + 1 });
    }

    this.updateCurrentTabDate();
    this.checkIfCardsAreAlreadyLoaded();
  }

  // checks if cards are already loaded. If so, do nothing(reference already changed). If not, download them
  checkIfCardsAreAlreadyLoaded() {
    switch (this.activeTab.id) {
      case this.TABS.TASKS: {
        if (!this.cardData.task[this.currentTabDate]) {
          this.getTasksMonthly();
        }
        break;
      }
      case this.TABS.VENDORS: {
        if (!this.cardData.vendor[this.currentTabDate]) {
          this.getTasksVendors();
        }
        break;
      }
      case this.TABS.CONTESTS: {
        if (!this.cardData.consent[this.currentTabDate]) {
          this.getConsent();
        }
        break;
      }
      default:
        break;
    }
  }

  getTasksMonthly() {
    const getPlayerTasksRequest: GetPlayerTasksRequest = {
      month: this.owDate.month,
      year: this.owDate.year,
    };

    this.loading = true;
    this.apiBusinessService.playerTasks(getPlayerTasksRequest).subscribe(
      res => {
        // max prev date is hardcoded, max next date is current_date + month
        this.cardData.task[this.currentTabDate] = {
          data: prepareCardTask(res.items),
          blockNext: this.currentTabDate === this.maxPaginationDate,
          blockPrev: (getPlayerTasksRequest.month === 9 && getPlayerTasksRequest.year === 2024) || !res?.has_previous_month,
        };
        this.loading = false;
      },
      err => {
        this.loading = false;
      }
    );
  }

  getTasksVendors() {
    const getPlayerTasksRequest: GetPlayerTasksRequest = {
      month: this.owDate.month,
      year: this.owDate.year,
    };

    this.loading = true;
    this.apiBusinessService.playerTasksVendors(getPlayerTasksRequest).subscribe(
      res => {
        // max prev date is hardcoded, max next date is current_date + month
        this.cardData.vendor[this.currentTabDate] = {
          data: prepareCardTask(res.items),
          blockNext: this.currentTabDate === this.maxPaginationDate,
          blockPrev: (getPlayerTasksRequest.month === 9 && getPlayerTasksRequest.year === 2024) || !res?.has_previous_month,
        };
        this.loading = false;
      },
      err => {
        this.loading = false;
      }
    );
  }

  getConsent() {
    const getPlayerTasksRequest: GetPlayerTasksRequest = {
      month: this.owDate.month,
      year: this.owDate.year,
    };

    this.loading = true;
    this.apiBusinessService.playerContests(getPlayerTasksRequest).subscribe(
      res => {
        // max prev date is hardcoded, max next date is current_date + month
        this.cardData.consent[this.currentTabDate] = {
          data: res.items,
          blockNext: this.currentTabDate === this.maxPaginationDate,
          blockPrev: (getPlayerTasksRequest.month === 9 && getPlayerTasksRequest.year === 2024) || !res?.has_previous_month,
        };
        this.loading = false;
      },
      err => {
        this.loading = false;
      }
    );
  }
}
