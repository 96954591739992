<div class="global-dialog ow-dialog window-a primary loading-opacity">
  <loading></loading>

  <div class="wrapper quiz-end">
    <!-- QA NAME -->
    <div class="title" [innerHTML]="qa.name"></div>

    <!-- CONTENT AT FINISH -->
    <p
      *ngIf="qa.content_at_finish"
      class="description"
      [class.with-margin]="!qa.content_at_finish"
      [innerText]="qa.content_at_finish"
      owEllipsis></p>

    <!-- SCORE, TIME AND PRIZES -->
    <app-m-qa-summary [qa]="qa" [separatorTitle]="separatorTitle"></app-m-qa-summary>

    <!-- BACK TO LIST BUTTON-->
    <div class="bottom-bar">
      <div class="bar-button base">
        <button class="base primary" (click)="backToList()">
          {{ "qa.qa-finish.back-to-list" | myTranslate }}
        </button>
      </div>
    </div>
  </div>
</div>
