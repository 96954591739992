import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { SharedModule } from "../../../../../shared/shared.module";
import { SharedUiMobileModule } from "../../../shared-ui/mobile/shared-ui-mobile.module";
import { ChestAbstractModule } from "../../abstract/chest-abstract.module";
import { ChestComponent } from "../../base/core/dialogs/chest/chest.component";
import { CUSTOM_DECLARATIONS } from "../../consts/custom/components.const";
import { CUSTOM_IMPORTS } from "../../consts/custom/imports.const";
import { ChestService } from "../../services/core/chest.service";

@NgModule({
  imports: [CommonModule, SharedModule, ChestAbstractModule, SharedUiMobileModule, ...CUSTOM_IMPORTS],
  declarations: [ChestComponent, ...CUSTOM_DECLARATIONS],
  providers: [ChestService],
})
export class ChestModule {}
