import { EventDialog } from "../../../../../../../core/interfaces/event-dialog.interface";
import { MessageDetailsChestFailedComponent } from "../../../base/core/dialogs/message-details-chest-failed/message-details-chest-failed.component";
import { MessageDetailsChestSuccessComponent } from "../../../base/core/dialogs/message-details-chest-success/message-details-chest-success.component";
import { MessageDetailsComicsComponent } from "../../../base/core/dialogs/message-details-comics/message-details-comics.component";
import { MessageDetailsHiddenRewardComponent } from "../../../base/core/dialogs/message-details-hidden-reward/message-details-hidden-reward.component";
import { MessageDetailsLevelUpComponent } from "../../../base/core/dialogs/message-details-level-up/message-details-level-up.component";
import { MessageDetailsNormalComponent } from "../../../base/core/dialogs/message-details-normal/message-details-normal.component";
import { MessageDetailsProbabilityCompensationComponent } from "../../../base/core/dialogs/message-details-probability-compensation/message-details-probability-compensation.component";
import { MessagesComponent } from "../../../base/core/dialogs/messages/messages.component";
import { EVENT_DIALOGS_NAMES_MESSAGE } from "./event-names.const";

export const EVENT_DIALOGS_MESSAGE: EventDialog = {
  [EVENT_DIALOGS_NAMES_MESSAGE.MESSAGES]: MessagesComponent,
  [EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_NORMAL]: MessageDetailsNormalComponent,
  [EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_COMICS]: MessageDetailsComicsComponent,
  [EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_LEVEL_UP]: MessageDetailsLevelUpComponent,
  [EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_HIDDEN_REWARD]: MessageDetailsHiddenRewardComponent,
  [EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_CHEST_SUCCESS]: MessageDetailsChestSuccessComponent,
  [EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_CHEST_FAILED]: MessageDetailsChestFailedComponent,
  [EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_PROBABILITY_COMPENSATION]:
    MessageDetailsProbabilityCompensationComponent,
};
