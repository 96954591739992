import { Directive, Injectable } from "@angular/core";

import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { ProductPlayerService } from "../../../../../player/providers/product-player.service";
import { STOCK_VIEW } from "../../../shared-ui/mobile/consts/stock-view.const";
import { ApiMessageService } from "../../api/core/services/api-message.service";
import { MessageDetailsProbabilityCompensation } from "../../interfaces/core/message-details-probability-compensation.interface";
import { AbstractMessageDetailsBaseComponent } from "./abstract-message-details-base.component";

@Directive()
@Injectable()
export abstract class AbstractMessageDetailsProbabilityCompensationComponent extends AbstractMessageDetailsBaseComponent {
  message: MessageDetailsProbabilityCompensation;
  @OwInject(ProductPlayerService) productPlayerService: ProductPlayerService;
  @OwInject(ApiMessageService) apiMessageService: ApiMessageService;
  // END INJECTIONS

  loading: boolean;
  icon = "new-finding";
  STOCK_VIEW = STOCK_VIEW;

  setMessageProperty() {
    if (this.message.parameters.message) {
      /**
       * ICON
       */
      const icon = this.message.parameters.message.icon;
      if (icon) {
        this.icon = icon;
      }
      /**
       * TITLE
       */
      const title = this.message.parameters.message.title;
      if (title) {
        this.message.title = title;
      }
      /**
       * DESCRIPTION
       */
      const description = this.message.parameters.message.description;
      if (description) {
        this.message.description = description;
      }
    }
  }
}
