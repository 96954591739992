import { Pipe, PipeTransform } from "@angular/core";

/**
 * Add space between number group 3
 */
@Pipe({
  name: "NumberWithSpaces",
})
export class NumberWithSpacesPipe implements PipeTransform {
  /**
   * @param {string|number} number
   * @returns {string}
   *
   * ### Example 1:
   * ```
   * 132465 | NumberWithSpaces
   * ```
   * ### Output 1:
   * ```
   * '123 456'
   * ```
   *
   * ### Example 2:
   * ```
   * '<p>sample test -132465</p>' | NumberWithSpaces
   * ```
   * ### Output 2:
   * ```
   * '<p>sample test -132 465</p>'
   * ```
   */
  transform(number: string | number): string {
    if (typeof number == "number") {
      number = number.toString();
    }

    if (typeof number == "string") {
      return number.replace(/\B(?=(\d{3})+(?![\d]))/g, " ");
    }

    return (number = "");
  }
}
