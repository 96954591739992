import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";

import { EventEmitterDialogsService } from "../../../../../../core/services/core/event-emitter-dialogs.service";
import { ConfigState } from "../../../../../../store/config/reducer";
import { selectPrimaryState } from "../../../../../../store/primary/selectors";
import { BaseChest, Player } from "../../../../../player/interfaces/player";
import { PlayerService } from "../../../../../player/providers/player.service";
import { GuiService } from "../../../../services/gui.service";
import { EVENT_DIALOGS_NAMES_CHEST } from "../../consts/core/event-dialogs/event-names";

@Injectable()
export class ChestService {
  closedChests: BaseChest[] = [];
  ignoredPlayerChestIds: number[] = [];
  lastPlayerChestId: number;
  flags = {
    checkChestIsOpen: null,
  };

  constructor(
    private guiService: GuiService,
    private playerService: PlayerService,
    private eventEmitterDialogsService: EventEmitterDialogsService,
    private configStore: Store<ConfigState>
  ) {
    this.configStore.select(selectPrimaryState).subscribe(config => {
      this.flags.checkChestIsOpen = config.primary.chests.check_and_open;
    });
  }

  checkChestsAndOpen(player?: Player) {
    if (this.flags.checkChestIsOpen) {
      if (player) {
        this.closedChests = player.closed_chests;
      }

      if (this.guiService.isLockOpenDialog) {
        return;
      }

      const firstNotIgnoredChest = this.getFirstNotIgnoredClosedChest();

      if (firstNotIgnoredChest && this.playerService.isActiveMe) {
        this.guiService.isLockOpenDialog = true;
        this.lastPlayerChestId = this.closedChests[0].player_chest_id;

        setTimeout(() => {
          this.eventEmitterDialogsService.emitter.emit({
            name: EVENT_DIALOGS_NAMES_CHEST.CHEST,
            config: {
              data: {
                chest: firstNotIgnoredChest,
              },
              disableClose: true,
            },
            callback: () => {
              this.ignoredPlayerChestIds.push(firstNotIgnoredChest.player_chest_id);
              this.guiService.isLockOpenDialog = false;
              this.checkChestsAndOpen();
            },
          });
        });
      }
    }
  }

  getFirstNotIgnoredClosedChest() {
    const notIgnoredChests = this.closedChests.filter(chest => {
      return !this.ignoredPlayerChestIds.some(x => x === chest.player_chest_id);
    });

    return notIgnoredChests ? notIgnoredChests[0] : null;
  }
}
