<div class="ow-dialog window-a primary no-transparent">
  <!-- DIALOG EXIT -->
  <m-ui-close-button [preventDefault]="true" (clicked)="close()"></m-ui-close-button>

  <!-- TITLE -->
  <p class="title">
    {{ data.title || "&nbsp;" }}
  </p>

  <pinch-zoom>
    <img [src]="data.image" />
  </pinch-zoom>
</div>
