import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";

import { ApiService } from "../../../core/providers/api.service";
import { SchemaValidatorService } from "../../../services/schema-validator/schema-validator.service";
import { PlayerService } from "../../player/providers/player.service";
import { Region } from "../interfaces/region.interface";
import { MOCK_BOARD_KEY } from "../mock/consts/mock-board.const";

@Injectable({
  providedIn: "root",
})
export class BoardService {
  constructor(
    private apiService: ApiService,
    private playerService: PlayerService,
    private schemaValidator: SchemaValidatorService
  ) {}

  getWorld(playerId: number) {
    return this.apiService.get(`player/${playerId}/region`).pipe(
      tap(response => {
        this.schemaValidator.validateResponse({
          url: `player/${playerId}/region`,
          payload: {},
          response: response,
          schema: "regionGame",
        });
      }),
      tap((regions: Region[]) => {
        this.playerService.playerRegions.next({
          playerId,
          regions,
        });
      })
    );
  }

  getBoard(playerId: number, islandId?: number) {
    return this.apiService
      .get(
        `player/${playerId}/board`,
        {
          params: {
            player_island_id: islandId,
          },
        },
        MOCK_BOARD_KEY
      )
      .pipe(
        tap(response => {
          this.schemaValidator.validateResponse({
            url: `player/${playerId}/board`,
            payload: {},
            response: response,
            schema: "boardGame",
          });
        })
      );
  }

  buyTile(tileId: number) {
    const options = {
      body: {
        tile_id: tileId,
      },
    };
    return this.apiService.post("board/tile", options);
  }
}
