import { createAction, props } from "@ngrx/store";

import { Message } from "../../modules/game/game-ui/message/interfaces/core/message.interface";
import { GastroIncomeCollectPayload } from "./interfaces/actions-payloads";
import { BoardTileState } from "./interfaces/board-tile.state";

export enum ActionTypes {
  FETCH_BOARD = "[game] fetch board",
  BOARD_TILE_UPDATE = "[game] update board tile data",
  GASTRO_INCOME_COLLECT = "[game] update gastro income data",
  PRELOAD_BOARD_BUILDINGS_IMAGES_SUCCESS = "[game] preload board buildings images success",
  FETCH_BOARD_SUCCESS = "[game] fetch board success",
  FETCH_BOARD_CACHE_SUCCESS = "[game] fetch board from cache success",
  FETCH_BOARD_PREVIEW_SUCCESS = "[game] fetch board preview success",
  NEW_IMPORTANT_MESSAGE_RECEIVED = "[game] new important message received",
  NEW_IMPORTANT_MESSAGE_OPENED = "[game] new important message opened",
  NEW_IMPORTANT_MESSAGE_CLOSED = "[game] new important message closed",
  ENGINE_INIT_SUCCESS = "[game] game engine init success",
  SET_GAME_READY = "[game] set game ready",
  SET_CURRENT_SCENE = "[game] set current scene",
  RESET_BOARD = "[game] reset board",
  SET_CURRENT_PLAYER_ISLAND_ID = "[game] set current player island id",
  NULL_ACTION = "[game] null action",
  REMOVE_GAME_BOARD_STATUS = "[game] remove game board status",
  CLEAR_GAME_STATUSES = "[game] clean game statuses",
}

export const gameFetchBoard = createAction(
  ActionTypes.FETCH_BOARD,
  props<{ playerId: number; playerIslandId?: number }>()
);
export const gameFetchBoardSuccess = createAction(
  ActionTypes.FETCH_BOARD_SUCCESS,
  props<{ boardTiles: BoardTileState[] }>()
);
export const gameFetchBoardPreviewSuccess = createAction(
  ActionTypes.FETCH_BOARD_PREVIEW_SUCCESS,
  props<{ boardTiles: BoardTileState[] }>()
);
export const gamePreloadBoardBuildingsImagesSuccess = createAction(
  ActionTypes.PRELOAD_BOARD_BUILDINGS_IMAGES_SUCCESS,
  props<BoardTileState>()
);
export const gameBoardTileUpdate = createAction(ActionTypes.BOARD_TILE_UPDATE, props<BoardTileState>());
export const gameGastroIncomeCollect = createAction(
  ActionTypes.GASTRO_INCOME_COLLECT,
  props<GastroIncomeCollectPayload>()
);
export const gameEngineInitSuccess = createAction(ActionTypes.ENGINE_INIT_SUCCESS, props<{ payload: boolean }>());
export const gameNewImportantMessageReceived = createAction(
  ActionTypes.NEW_IMPORTANT_MESSAGE_RECEIVED,
  props<{ message: Message }>()
);
export const gameNewImportantMessageOpened = createAction(
  ActionTypes.NEW_IMPORTANT_MESSAGE_OPENED,
  props<{ opened: boolean }>()
);
export const gameNewImportantMessageClosed = createAction(
  ActionTypes.NEW_IMPORTANT_MESSAGE_CLOSED,
  props<{ message: Message }>()
);
export const gameSetGameReady = createAction(ActionTypes.SET_GAME_READY, props<{ payload: boolean }>());
export const gameSetCurrentScene = createAction(ActionTypes.SET_CURRENT_SCENE, props<{ sceneName: string }>());
export const gameResetBoard = createAction(ActionTypes.RESET_BOARD);
export const gameSetCurrentPlayerIslandId = createAction(
  ActionTypes.SET_CURRENT_PLAYER_ISLAND_ID,
  props<{ playerIslandId: number }>()
);
export const gameClearGameStatuses = createAction(ActionTypes.CLEAR_GAME_STATUSES);
export const gameNullAction = createAction(ActionTypes.NULL_ACTION, props<{ boardSate: BoardTileState[] }>());
export const gameRemoveBoardStatus = createAction(ActionTypes.REMOVE_GAME_BOARD_STATUS, props<{ regionId: number }>());
export const gameFetchBoardCacheSuccess = createAction(ActionTypes.FETCH_BOARD_CACHE_SUCCESS);
