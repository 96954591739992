import { EventDialog } from "../../../../../../../core/interfaces/event-dialog.interface";
import { MissionDetailsComponent } from "../../../base/core/dialogs/mission-details/mission-details.component";
import { MissionGroupDetailsComponent } from "../../../base/core/dialogs/mission-group-details/mission-group-details.component";
import { MissionGroupListComponent } from "../../../base/core/dialogs/mission-group-list/mission-group-list.component";
import { MissionTransferComponent } from "../../../base/core/dialogs/mission-transfer/mission-transfer.component";
import { MissionsComponent } from "../../../base/core/dialogs/missions/missions.component";
import { EVENT_DIALOGS_NAMES_MISSION } from "./event-names";

export const EVENT_DIALOGS_MISSION: EventDialog = {
  [EVENT_DIALOGS_NAMES_MISSION.MISSIONS]: MissionsComponent,
  [EVENT_DIALOGS_NAMES_MISSION.MISSION_DETAILS]: MissionDetailsComponent,
  [EVENT_DIALOGS_NAMES_MISSION.MISSION_TRANSFER]: MissionTransferComponent,
  [EVENT_DIALOGS_NAMES_MISSION.MISSION_GROUP_LIST]: MissionGroupListComponent,
  [EVENT_DIALOGS_NAMES_MISSION.MISSION_GROUP_DETAILS]: MissionGroupDetailsComponent,
};
