import "phaser";

import { select } from "@ngrx/store";
import { of } from "rxjs";
import { filter, mergeMap, take } from "rxjs/operators";

import { gameResetBoard } from "../../../../../store/game/actions";
import { selectGameBoardInitiationData } from "../../../../../store/game/selectors";
import { MyGame } from "../../classes/core/MyGame";
import { MyScene } from "../../classes/core/MyScene";
import { BASIC_BOOT_SCENE, BASIC_MAIN_SCENE, BASIC_PRELOAD_SCENE } from "../basic.constants";
import { BASIC_SCENE_CONFIG } from "../basic-scene.config";
import { CustomBasicMainScene } from "../custom/CustomBasic.main.scene";
import { CustomBasicPreloadScene } from "../custom/CustomBasic.preload.scene";

export class BasicBootScene extends MyScene {
  constructor(sceneClassConfig?: Phaser.Types.Scenes.SettingsConfig) {
    sceneClassConfig = sceneClassConfig ? sceneClassConfig : { key: BASIC_BOOT_SCENE };
    super(sceneClassConfig);
  }

  init(data?: any) {
    this.passedData = data;
  }

  provideAllScenes() {
    if (!this.scene.get(BASIC_PRELOAD_SCENE)) {
      this.scene.add(BASIC_PRELOAD_SCENE, CustomBasicPreloadScene, false);
      this.scene.add(BASIC_MAIN_SCENE, CustomBasicMainScene, false);
    }
  }

  provideSceneConfig() {
    if (this.passedData && this.passedData.sceneConfigKey) {
      return BASIC_SCENE_CONFIG[this.passedData.sceneConfigKey];
    }
    return BASIC_SCENE_CONFIG["default"];
  }

  preload(): void {
    super.preload();
    this.sceneConfig = this.provideSceneConfig();
    this.gameService = (this.game as MyGame).gameService;

    // show splash screen if board is not loaded yet
    if (!this.gameService.boardStatuses[this.passedData.playerIslandId]) {
      this.gameService.guiService.isSplashShow.next(true);
    }

    if (this.passedData && this.passedData.playerIslandId) {
      this.gameService.prepareGameData(this.gameService.playerService.player.id, this.passedData.playerIslandId);
    } else {
      this.gameService.boardService
        .getWorld(this.gameService.playerService.getActivePlayerId())
        .pipe(
          mergeMap(worldData => {
            let worldIsland;
            worldData.find(
              regionData =>
                (worldIsland = regionData.islands.find(
                  island => island.group_id === this.sceneConfig.islandGroupIdForBoard
                ))
            );
            if (worldIsland) {
              this.gameService.prepareGameData(this.gameService.playerService.player.id, worldIsland.player_island_id);
            }
            return of(worldIsland);
          })
        )
        .subscribe(console.log);
    }

    this.provideAllScenes();
  }

  create(): void {
    this.gameService.store
      .pipe(
        select(selectGameBoardInitiationData),
        filter(data => data.loaded),
        take(1)
      )
      .subscribe(res => {
        const assetsToPreload = this.gameService.buildingsService.getBuildingsAssetsNamesFromBoard(res.boardTiles);
        this.scene.start(BASIC_PRELOAD_SCENE, { assetsToPreload, passedData: this.passedData });
      });
  }
}
