import { EVENT_DIALOGS_NAMES_MESSAGE } from "../../consts/core/event-dialogs/event-names.const";

export const MOCK_MESSAGE_DIALOGS = [
  {
    key: EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_LEVEL_UP,
    name: "Message details level up",
    available: true,
  },
  {
    key: EVENT_DIALOGS_NAMES_MESSAGE.MESSAGES,
    name: "Messages",
    available: true,
  },
  {
    key: EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_NORMAL,
    name: "Messages details normal",
    available: true,
  },
  {
    key: EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_PROBABILITY_COMPENSATION,
    name: "Message Details Probability Compensation",
    available: false,
  },
  {
    key: EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_HIDDEN_REWARD,
    name: "Message Details Hidden Reward",
    available: true,
  },
  {
    key: EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_COMICS,
    name: "Message Details Comics",
    available: true,
  },
  {
    key: EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_CHEST_SUCCESS,
    name: "Message Details Chest Success",
    available: false,
  },
  {
    key: EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_CHEST_FAILED,
    name: "Message Details Chest Failed",
    available: false,
  },
];
