import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { filter } from "rxjs/operators";

import { AppState } from "../../../../../../store/state";
import { PlayerService } from "../../../../../player/providers/player.service";
import { QA_TYPE } from "../../consts/core/qa-type.const";
import {selectPlayer} from '../../../../../../store/player/selectors';

@Injectable({
  providedIn: "root",
})
export class QaService {
  qaWithoutAnswer;

  constructor(
    private playerService: PlayerService,
    private store: Store<AppState>
  ) {
    this.store
      .select(selectPlayer)
      .pipe(filter(Boolean))
      .subscribe(player => {
        this.setQaWithoutAnswer(player);
      });
  }

  private initQaWithoutAnswer() {
    this.qaWithoutAnswer = {
      [QA_TYPE.SURVEY]: {},
      [QA_TYPE.QUIZ]: {},
    };
  }

  private setQaWithoutAnswer(player) {
    this.initQaWithoutAnswer();

    const setQaIn = (qa, target) => {
      this.qaWithoutAnswer[target][qa.location] = qa.amount;
    };

    const activeSurveysWithoutAnswer = player.active_surveys_without_answer || [];
    const activeQuizzesWithoutAnswer = player.active_quizzes_without_answer || [];
    activeSurveysWithoutAnswer.forEach(qa => setQaIn(qa, QA_TYPE.SURVEY));
    activeQuizzesWithoutAnswer.forEach(qa => setQaIn(qa, QA_TYPE.QUIZ));

    // LOCATION 0 IS HACK (ALL QA)
    this.qaWithoutAnswer[QA_TYPE.SURVEY][0] = activeSurveysWithoutAnswer.reduce((amount, qa) => {
      return amount + qa.amount;
    }, 0);

    this.qaWithoutAnswer[QA_TYPE.QUIZ][0] = activeQuizzesWithoutAnswer.reduce((amount, qa) => {
      return amount + qa.amount;
    }, 0);
  }

  isQAWithoutAnswer({ location, type }: { location: number; type: string }) {
    let qaArray = [];

    switch (type) {
      case QA_TYPE.SURVEY:
        qaArray = this.playerService.player.active_surveys_without_answer;
        break;

      case QA_TYPE.QUIZ:
        qaArray = this.playerService.player.active_quizzes_without_answer;
        break;
    }

    if (!qaArray) {
      return false;
    }

    if (location === 0) {
      return qaArray.length > 0;
    } else {
      return qaArray.some(qa => qa.location === location);
    }
  }

  isSurveysWithoutAnswer({ location }) {
    return this.isQAWithoutAnswer({
      location,
      type: QA_TYPE.SURVEY,
    });
  }

  isQuizzesWithoutAnswer({ location }) {
    return this.isQAWithoutAnswer({
      location,
      type: QA_TYPE.QUIZ,
    });
  }
}
