import { Component, OnDestroy, OnInit } from "@angular/core";

import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { AbstractBuildingSpecialComponent } from "../../../../abstract/core/abstract-building-special.component";

@Component({
  selector: "app-m-building-special",
  templateUrl: "./building-special.component.html",
})
export class MBuildingSpecialComponent extends AbstractBuildingSpecialComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.subscribeBoardTile();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
