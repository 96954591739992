import { Directive, Injectable, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";

import { AbstractInjectBaseComponent } from "../../../core/abstracts/abstract-inject-base.component";
import { SOCKET_STATUS } from "../../../core/consts/core/socket.constants";
import { OwInject } from "../../../core/decorators/ow-inject.decorator";
import { GlobalService } from "../../../core/providers/global.service";
import { TokenExchangeService } from "../../../core/providers/token-exchange.service";
import { unsubscribeObject } from "../../../core/utility/unsubscribe-array";
import { socketClearStatus, socketConnect } from "../../../store/socket/actions";
import { selectSocketStatus } from "../../../store/socket/selectors";
import { AppState } from "../../../store/state";
import { DialogService } from "../../shared/providers/dialog.service";
import {ApplicationConfig} from '../../../store/config/interfaces/config.state';
import {selectConfigOfApplication} from '../../../store/config/selectors';
import {take} from 'rxjs/operators';

@Directive()
@Injectable()
export abstract class AbstractSocketConnectionErrorComponent
  extends AbstractInjectBaseComponent
  implements OnInit, OnDestroy
{
  @OwInject(Store) store: Store<AppState>;
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(Router) router: Router;
  @OwInject(TokenExchangeService) tokenExchangeService: TokenExchangeService;
  @OwInject(DialogService) dialogService: DialogService;
  config: ApplicationConfig = null;

  subs = {
    socket: null,
  };

  ngOnInit() {
    this.store.select(selectConfigOfApplication).pipe(take(1)).subscribe(res => {
      this.config = res;
    })
    this.dialogService.closeAll();
    this.tokenExchangeService.exchangeTimeoutStop();
    this.subscribeSocketStore();
  }

  subscribeSocketStore() {
    this.subs.socket = this.store.pipe(select(selectSocketStatus)).subscribe(status => {
      switch (status) {
        case SOCKET_STATUS.DISCONNECTED:
          this.store.dispatch(socketClearStatus());
          break;

        case SOCKET_STATUS.AUTHENTICATED:
          this.router.navigate(["/"]);
          this.tokenExchangeService.exchangeTimeoutStart();
          break;
      }
    });
  }

  reconnect() {
    this.store.dispatch(socketConnect());
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
