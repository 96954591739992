import { ChangeDetectorRef, Directive, ElementRef, Injectable, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { select, Store } from "@ngrx/store";
import * as R from "ramda";
import { filter, take } from "rxjs/operators";
import { Swiper, SwiperOptions } from "swiper/types";

import { swiperInjectionStyles } from "../../../../../../../styles/swiper/swiper-injection-styles";
import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { CURRENCIES } from "../../../../../../core/consts/core/currencies";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { translate } from "../../../../../../core/helpers/translate.helper";
import { CurrencyBalance } from "../../../../../../core/interfaces/currency";
import { CurrencyService } from "../../../../../../core/providers/currency.service";
import { EventEmitterDialogsService } from "../../../../../../core/services/core/event-emitter-dialogs.service";
import { BoardTileState } from "../../../../../../store/game/interfaces/board-tile.state";
import { selectGameTileByTileId } from "../../../../../../store/game/selectors";
import { selectPlayer } from "../../../../../../store/player/selectors";
import { AppState } from "../../../../../../store/state";
import { Player } from "../../../../../player/interfaces/player";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { BuildingConfig } from "../../../../game-engine/interfaces/building-config";
import { BuildingsService } from "../../../../services/buildings.service";
import { STOCK_VIEW } from "../../../shared-ui/mobile/consts/stock-view.const";
import { EVENT_DIALOGS_NAMES_BUILDINGS } from "../../consts/core/event-dialogs/event-names.const";
import { BuildingBuildData } from "../../interfaces/core/dialogs/building-build-data.interface";
import { BuildingData } from "../../interfaces/core/dialogs/building-data.interface";

@Directive()
@Injectable()
export abstract class AbstractBuildingsListComponent extends AbstractInjectBaseComponent {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractBuildingsListComponent>;
  @OwInject(MAT_DIALOG_DATA) data: BuildingData;
  @OwInject(BuildingsService) buildingsService: BuildingsService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(CurrencyService) currencyService: CurrencyService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(ChangeDetectorRef) changeDetectorRef: ChangeDetectorRef;

  initSwiper = false;
  swiper: Swiper = null;
  @ViewChild("swiperRef", { static: false })
  swiperRef: ElementRef | undefined;
  currentSlideIndex = 0;
  config: SwiperOptions = {
    slidesPerView: 3,
    initialSlide: 1,
    centeredSlides: true,
    pagination: {
      dynamicBullets: true,
      clickable: true,
    },
    injectStyles: [swiperInjectionStyles],
  };

  STOCK_VIEW = STOCK_VIEW;
  swiperConfig = {
    slidesPerView: 3,
    centeredSlides: true,
  };

  tile: BoardTileState;
  buildings: BuildingConfig[];
  player: Player;
  subs = {
    player: null,
    board: null,
  };

  ORDERS = {
    DEFAULT: 1,
    PLAYER_LEVEL: 2,
    GROUP_LIMIT: 4,
  };

  getBuildingsByPlayerTileId() {
    this.buildingsService
      .getBuildingsByPlayerTileId(this.data.playerTileId)
      .subscribe((buildings: BuildingConfig[]) => {
        this.buildings = buildings.map(building => {
          building = this.setOrderGroup(building);
          building = this.getCurrenciesBalance(building);
          building = this.setBuildPricesDisplay(building);
          return building;
        });
        this.setSwiper();
        this.setOrders();
        this.checkEmpty();
      });
  }

  subscribePlayer() {
    this.subs.player = this.store.pipe(select(selectPlayer)).subscribe(player => {
      this.player = player;
    });
  }

  openBuildingDetails(buildingId: number) {
    const buildingBuildData: BuildingBuildData = {
      buildingId,
      playerTileId: this.data.playerTileId,
      tileId: this.data.tileId,
    };

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_BUILD,
      config: {
        data: buildingBuildData,
      },
    });
  }

  getCurrenciesBalance(building: BuildingConfig): BuildingConfig {
    building.build_currency_prices = building.build_currency_prices.map(currency => {
      return this.currencyService.getCurrencyBalance(currency, this.player.currency_balances);
    });

    return building;
  }

  setBuildPricesDisplay(building: BuildingConfig): BuildingConfig {
    const orders = [CURRENCIES.ONE, CURRENCIES.TWO];
    const buildPrices = <CurrencyBalance[]>R.clone(building.build_currency_prices);

    orders.reverse();
    building.buildPricesDisplay = buildPrices
      .sort((a, b) => {
        return orders.indexOf(b.key) - orders.indexOf(a.key);
      })
      .slice(0, 2);

    return building;
  }

  setOrderGroup(building: BuildingConfig): BuildingConfig {
    building.orderInList = this.ORDERS.DEFAULT;

    if (building.required_level && building.required_level > this.player.level) {
      building.orderInList = this.ORDERS.PLAYER_LEVEL;
    }

    if (building.group_count >= building.group_limit) {
      building.orderInList = this.ORDERS.GROUP_LIMIT;
    }

    return building;
  }

  setOrders() {
    const sortedBuildings = [];

    Object.keys(this.ORDERS).forEach(key => {
      const order = this.ORDERS[key];

      const tmpBuildings = this.buildings
        .filter(building => building.orderInList === order)
        .sort((a, b) => a.name.localeCompare(b.name));

      switch (order) {
        case this.ORDERS.PLAYER_LEVEL:
          tmpBuildings.sort((a, b) => (a.required_level > b.required_level ? 1 : -1));
          break;
      }

      sortedBuildings.push(...tmpBuildings);
    });

    this.buildings = sortedBuildings;
  }

  subscribeBoardTile() {
    this.subs.board = this.store
      .pipe(
        select(selectGameTileByTileId(this.data.tileId)),
        filter(state => !!state),
        take(1)
      )
      .subscribe(tile => {
        this.tile = tile;
        this.getBuildingsByPlayerTileId();
      });
  }

  checkEmpty() {
    if (this.buildings.length === 0) {
      this.dialogService.openAlert(
        {
          description:
            this.tile.empty_buildings_list_message || translate("building-list.empty-buildings-list-message"),
        },
        () => {
          this.matDialogRef.close();
        }
      );
    }
  }

  setSwiper() {
    this.initSwiper = false;
    this.swiper = null;
    this.changeDetectorRef.detectChanges();
    this.initSwiper = true;
    this.changeDetectorRef.detectChanges();
    if (this.swiperRef?.nativeElement) {
      this.swiper = this.swiperRef.nativeElement.swiper;
      this.swiper.on("slideChange", swiper => {
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  prevSlide() {
    this.swiper.slidePrev();
    this.currentSlideIndex = this.swiper.activeIndex;
  }

  nextSlide() {
    this.swiper.slideNext();
    this.currentSlideIndex = this.swiper.activeIndex;
  }
}
