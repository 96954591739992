import { Component, ComponentFactoryResolver, ViewChild, ViewContainerRef } from "@angular/core";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { setDynamicsComponents } from "../../../../../../../../core/helpers/dynamic-component.helper";
import { AbstractPlayerDetailsComponent } from "../../../../abstract/core/abstract-player-details.component";
import { CORE_DYNAMIC_COMPONENTS } from "../../../../const/core/dynamic-components/player-details/dynamic-components.const";
import { CUSTOM_DYNAMIC_COMPONENTS } from "../../../../const/custom/dynamic-components/player-details/dynamic-components.const";
import { AUTH_ROUTING_ABSOLUTE_PATH } from '../../../../../../../auth/consts/core/routing-auth-absolute.const';
import { Router } from '@angular/router';

@Component({
  selector: "app-player-details",
  templateUrl: "./player-details.component.html",
})
export class PlayerDetailsComponent extends AbstractPlayerDetailsComponent {
  @OwInject(ComponentFactoryResolver) componentFactoryResolver: ComponentFactoryResolver;
  @OwInject(Router) router: Router;
  @ViewChild("otherViewTpl", { read: ViewContainerRef }) otherViewTpl: ViewContainerRef;

  setDynamicsComponents() {
    setDynamicsComponents.bind(this)({
      coreDynamicComponents: CORE_DYNAMIC_COMPONENTS,
      customDynamicComponents: CUSTOM_DYNAMIC_COMPONENTS,
    });
  }

  redirectToUserEdit() {
    this.dialogService.closeAll();

    this.router.navigate([AUTH_ROUTING_ABSOLUTE_PATH.REGISTRATION], {
      queryParams: {
        edit: 1,
        mandatoryVerification: null,
      }
    });
  }
}
