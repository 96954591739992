import { Component, Input } from "@angular/core";

import { BuildingDetailsConfig } from "../../../../../../game-engine/interfaces/building-details-config";

@Component({
  selector: "app-core-building-time",
  templateUrl: "./building-time.component.html",
})
export class CoreBuildingTimeComponent {
  @Input() building: BuildingDetailsConfig;
}
