import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { select, Store } from "@ngrx/store";
import moment from "moment";
import { Moment } from "moment";
import { Observable, of } from "rxjs";
import { catchError, take, tap } from "rxjs/operators";

import { APP_ROUTING_ABSOLUTE_PATH } from "../../consts/routing-app-absolute.const";
import { AuthService } from "../../modules/auth/api/services/auth.service";
import { AUTH_ROUTING_ABSOLUTE_PATH } from "../../modules/auth/consts/core/routing-auth-absolute.const";
import { TokenObject } from "../../modules/auth/interfaces/token-object";
import { PreviewPlayerService } from "../../modules/game/game-gui/providers/preview-player.service";
import { GuiService } from "../../modules/game/services/gui.service";
import { loginLogout } from "../../store/auth/login/actions";
import { primaryFetchStart } from "../../store/primary/actions";
import { AppState } from "../../store/state";
import { userFetchStart } from "../../store/user/actions";
import { selectUserState } from "../../store/user/selectors";
import { selectUtilityTokenObject } from "../../store/utility/selectors";
import { ApiTimesyncService } from "../api/timesync/services/api-timesync.service";
import { SynchronizeTimeService } from "../providers/synchronize-time.service";
import { isTokenExpired } from "../utility/is-expired-token.helper";
import { getToken } from "../utility/token";

@Injectable({
  providedIn: "root",
})
export class InitGuard implements CanActivate {
  static isSynchronizedTime: boolean = null;
  static isValidatedToken: boolean = null;
  resolve;
  tokenObject: TokenObject;
  state: RouterStateSnapshot;
  currentDate: Moment;
  effectsInitialized?: boolean;

  constructor(
    private guiService: GuiService,
    private apiTimesyncService: ApiTimesyncService,
    private synchronizeTimeService: SynchronizeTimeService,
    private store: Store<AppState>,
    private authService: AuthService,
    private router: Router,
    private previewPlayerService: PreviewPlayerService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.state = state;

    const promise: Promise<boolean> = new Promise(resolve => {
      this.resolve = resolve;
    });

    this.tokenObject = getToken();
    const isTokenExist = this.tokenObject.token;

    this.guiService.isSplashShow.next(true);

    if (isTokenExist) {
      if (InitGuard.isSynchronizedTime) {
        this.checkTokenLogic();
      } else {
        this.apiTimesyncService
          .getTimesync()
          .pipe(
            tap(() => {
              InitGuard.isSynchronizedTime = true;
            }),
            catchError(() => {
              return of();
            })
          )
          .subscribe({
            complete: () => {
              this.checkTokenLogic();
            },
          });
      }
    } else {
      if (this.state.url.includes(APP_ROUTING_ABSOLUTE_PATH.AUTH)) {
        this.router.navigate([AUTH_ROUTING_ABSOLUTE_PATH.LOGIN]);
      } else {
        this.end(true);
      }
    }

    return promise;
  }
  end(value: boolean) {
    this.guiService.isSplashShow.next(false);
    this.resolve(value);
  }

  checkTokenLogic() {
    this.currentDate = moment(this.synchronizeTimeService.getActualLocalTime());
    if (isTokenExpired()) {
      this.store.dispatch(loginLogout());
    } else {
      if (
        InitGuard.isValidatedToken ||
        moment(this.tokenObject.expired_at).subtract(14, "minute").isAfter(this.currentDate)
      ) {
        this.afterValidateRequest();
      } else {
        this.authService
          .validate()
          .pipe(
            tap(() => {
              InitGuard.isValidatedToken = true;
            })
          )
          .subscribe(() => {
            this.afterValidateRequest();
          });
      }
    }
  }

  afterValidateRequest() {
    if (!this.effectsInitialized) {
      // if null then this not a preview mode and we're waiting for player selection
      if (this.previewPlayerService.playerId === null) {
        this.store.dispatch(primaryFetchStart());
        this.previewPlayerService.changePlayer();

        this.store
          .pipe(
            select(selectUserState),
            tap(userState => {
              if (!userState.me && !userState.isLoading && !userState.error) {
                this.store.dispatch(userFetchStart({}));
              }
            })
          )
          .subscribe();
      }

      this.effectsInitialized = true;
    }
    this.store.pipe(select(selectUtilityTokenObject), take(1)).subscribe(freshTokenObject => {
      const { reauthorize_after } = freshTokenObject;
      let isAllowReauthorize = false;

      if (reauthorize_after) {
        const reauthorizeAfterDate = moment(reauthorize_after);
        isAllowReauthorize = this.currentDate.isAfter(reauthorizeAfterDate);
      }

      if (isAllowReauthorize) {
        this.authService.reauthorize().subscribe(() => {
          this.afterValidateRequest();
        });
      } else {
        this.end(true);
      }
    });
  }
}
