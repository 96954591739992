import { createReducer, on } from "@ngrx/store";

import { INFO_MESSAGE_STATUS } from "../../core/consts/core/info-message.constants";
import { getToken } from "../../core/utility/token";
import { TokenObject } from "../../modules/auth/interfaces/token-object";
import {
  utilityAddMissionToOpen,
  utilityAddNewProductsInStorage,
  utilityClearNewMessagesToRead,
  utilityClearNewProductsInStorage,
  utilityDifferentVersionGame,
  utilityErrorPreloadAssetsMap,
  utilityFetchNewTokenFinish,
  utilityFetchNewTokenStart,
  utilityRemoveMissionToOpen,
  utilityRemoveToken,
  utilitySetHasMissionsToCollect,
  utilitySetHasNewMessagesToRead,
  utilitySetToken,
  utilityUpdateActivePlayerId,
  utilityUpdateMePlayerId,
} from "./actions";
import { UtilityPlayer } from "./interfaces/utility-player";

export interface UtilityState {
  player: UtilityPlayer;
  token: TokenState;
  infoMessage: InfoMessageState;
}

export interface TokenState {
  isExchanging: boolean;
  tokenObject: TokenObject;
}

export interface InfoMessageState {
  status: number;
}

const initialState: UtilityState = {
  player: {
    activePlayerId: null,
    mePlayerId: null,
    hasMissionsToCollect: null,
    hasNewMessagesToRead: null,
    missionsToOpen: [],
    newProductsInStorage: [],
  },
  token: {
    isExchanging: null,
    tokenObject: getToken(),
  },
  infoMessage: {
    status: null,
  },
};

export const utilityReducer = createReducer(
  initialState,
  on(utilityUpdateActivePlayerId, (state, action) => {
    return {
      ...state,
      player: {
        ...state.player,
        activePlayerId: action.playerId,
      },
    };
  }),
  on(utilityUpdateMePlayerId, (state, action) => {
    return {
      ...state,
      player: {
        ...state.player,
        mePlayerId: action.playerId,
      },
    };
  }),
  on(utilityFetchNewTokenStart, (state, action) => {
    return {
      ...state,
      token: {
        ...state.token,
        isExchanging: true,
      },
    };
  }),
  on(utilityFetchNewTokenFinish, (state, action) => {
    return {
      ...state,
      token: {
        ...state.token,
        isExchanging: false,
      },
    };
  }),
  on(utilitySetToken, (state, action) => {
    return {
      ...state,
      token: {
        ...state.token,
        tokenObject: action.token,
      },
    };
  }),
  on(utilityRemoveToken, (state, action) => {
    return {
      ...state,
      token: null,
    };
  }),
  on(utilitySetHasMissionsToCollect, (state, action) => {
    return {
      ...state,
      player: {
        ...state.player,
        hasMissionsToCollect: action.setMissionToCollect,
      },
    };
  }),
  on(utilityAddMissionToOpen, (state, action) => {
    return {
      ...state,
      player: {
        ...state.player,
        missionsToOpen: [...state.player.missionsToOpen, action.mission],
      },
    };
  }),
  on(utilityRemoveMissionToOpen, (state, action) => {
    return {
      ...state,
      player: {
        ...state.player,
        missionsToOpen: state.player.missionsToOpen.filter(mission => {
          return mission.player_mission_id !== action.mission.player_mission_id;
        }),
      },
    };
  }),
  on(utilityAddNewProductsInStorage, (state, action) => {
    return {
      ...state,
      player: {
        ...state.player,
        newProductsInStorage: [...state.player.newProductsInStorage, ...action.products],
      },
    };
  }),
  on(utilityClearNewProductsInStorage, (state, action) => {
    return {
      ...state,
      player: {
        ...state.player,
        newProductsInStorage: [],
      },
    };
  }),
  on(utilitySetHasNewMessagesToRead, (state, action) => {
    return {
      ...state,
      player: {
        ...state.player,
        hasNewMessagesToRead: action.setHasNewMessagesToRead,
      },
    };
  }),
  on(utilityClearNewMessagesToRead, (state, action) => {
    return {
      ...state,
      player: {
        ...state.player,
        hasNewMessagesToRead: false,
      },
    };
  }),
  on(utilityErrorPreloadAssetsMap, (state, action) => {
    return {
      ...state,
      infoMessage: {
        status: INFO_MESSAGE_STATUS.ERROR_PRELOAD_ASSETS_MAP,
      },
    };
  }),
  on(utilityDifferentVersionGame, (state, action) => {
    return {
      ...state,
      infoMessage: {
        status: INFO_MESSAGE_STATUS.DIFFERENT_VERSION_GAME,
      },
    };
  })
);
