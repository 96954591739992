<div class="global-dialog">
  <loading [classNames]="['white', 'stroke']" [size]="'big'"></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <div [hidden]="!eventShops?.items?.length">
    <!-- NAVIGATION CENTER -->
    <m-ui-navigation-center
      (nextClicked)="nextSlide()"
      (prevClicked)="prevSlide()"
      [hideNext]="currentSlideIndex >= eventShops.pages.length - 1"
      [hidePrev]="currentSlideIndex <= 0"
    ></m-ui-navigation-center>

    <swiper-container #swiperRef [config]="config" init="false" swiperElement>
      <swiper-slide *ngFor="let page of eventShops.pages" class="event-shop">
        <div
          *ngFor="let eventShop of page"
          class="ow-dialog window-b primary"
        >
          <div
            (click)="openShopDetails(eventShop)"
            [class.deactivated]="!isShopEventDeactivate(eventShop)"
            class="event-shop-container"
          >
            <div
              *ngIf="({currency_id: eventShop.prize_currency_id, amount: null} | singleCurrency) as currency"
              class="shop-details"
            >
              <div class="shop-title">
                {{ eventShop.title }}
              </div>

              <div class="shop-currency">
                <img
                  [src]="currency.iconUrlBig"
                  class="icon"
                >
                {{ ((playerService.player.currency_balances | currencyGameBalance:currency.key)?.balance || 0)  | NumberWithSpaces }}
              </div>
            </div>

            <div class="shop-info">
              <div class="item">
                <ng-container
                  *ngIf="({currency_id: eventShop.prize_currency_id, amount: null} | singleCurrency) as currency">
                  <img
                    [src]="('cointime_' + currency.key + '.png') | asset:'ui'"
                    class="icon"
                  />
                </ng-container>
                <span matTooltip="Czas na zbieranie żetonów">
                    {{ eventShop.currency_collection_start | date: 'dd.MM.yy'}} -
                  {{ eventShop.currency_collection_end | date: 'dd.MM.yy'}}
                  </span>
              </div>

              <div class="item">
                <img
                  *ngIf="!eventShop.is_repeatable"
                  [src]="'exclamation.png' | asset:'ui'"
                  class="icon not-repeatable"
                  matTooltip="Te żetony wykorzystasz tylko w podanym terminie"
                  matTooltipClass="delayed"
                  matTooltipPosition="above"
                />
                <img [src]="'shop.png' | asset:'ui'" class="icon" />
                <span matTooltip="Czas na wymianę żetonów">
                    {{ eventShop.open_start | date: 'dd.MM.yy'}} -
                  {{ eventShop.open_end | date: 'dd.MM.yy'}}
                  </span>
              </div>

              <button class="base primary">
                Zobacz
              </button>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper-container>
  </div>
</div>
