import { Directive, Injectable } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { OwInject } from "../decorators/ow-inject.decorator";
import { Alert } from "../interfaces/alert";
import { AbstractInjectBaseComponent } from "./abstract-inject-base.component";

@Directive()
@Injectable()
export abstract class AbstractAlertComponent extends AbstractInjectBaseComponent {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractAlertComponent>;
  @OwInject(MAT_DIALOG_DATA) data: Alert;

  close(confirm?: boolean) {
    this.matDialogRef.close(confirm);
  }
}
