import { MOCK_BUILDINGS_DIALOGS } from "../../game/game-ui/buildings/mock/consts/mock-buildings-dialogs.const";
import { MOCK_CURRENCY_EXCHANGE_DIALOGS } from "../../game/game-ui/currency-exchange/mock/consts/mock-currency-exchange-dialogs.const";
import { MOCK_HUD_DIALOGS } from "../../game/game-ui/hud/mock/consts/mock-hud-dialogs.const";
import { MOCK_MESSAGE_DIALOGS } from "../../game/game-ui/message/mock/consts/mock-message-dialogs.const";
import { MOCK_MISSION_DIALOGS } from "../../game/game-ui/mission/mock/consts/mock-mission-dialogs.const";
import { MOCK_QA_DIALOGS } from "../../game/game-ui/qa/mock/conts/mock-qa-dialogs.const";
import { MOCK_TRANSACTIONS_HISTORY_DIALOGS } from "../../game/game-ui/transaction-history/mock/consts/mock-transaction-history-dialogs.const";
import { MOCK_WAREHOUSE_DIALOGS } from "../../game/game-ui/warehouse/mock/consts/mock-warehouse-dialogs.const";
import { MockDialog } from "../interfaces/mock-dialog.interface";

export const MOCK_DIALOG_LIST: MockDialog[] = [
  ...MOCK_MESSAGE_DIALOGS,
  ...MOCK_MISSION_DIALOGS,
  ...MOCK_WAREHOUSE_DIALOGS,
  ...MOCK_BUILDINGS_DIALOGS,
  ...MOCK_QA_DIALOGS,
  ...MOCK_TRANSACTIONS_HISTORY_DIALOGS,
  ...MOCK_CURRENCY_EXCHANGE_DIALOGS,
  ...MOCK_HUD_DIALOGS,
];
