export const MOCK_BUILDING_AUTO_PROD = {
  api: {
    upgrade_building_id: 165,
    required_building: null,
    required_building_on_island: null,
    required_star: null,
    building_time_in_seconds: 300,
    fast_building_time_in_seconds: 0,
    build_currency_prizes: [],
    build_product_prizes: [],
    parameters: [],
    building_id: 164,
    group_type: "auto-production",
    group_limit: 1,
    group_count: 1,
    category_id: 1,
    player_concurrence_builds_reached_error_message: null,
    group: "cityhall",
    group_description:
      "Ratusz to Twoja siedziba, z kt&oacute;rej podejmujesz decyzje. Rozbudowa ratusza zwiększa pojemność skarbca na srebrne talary oraz ich gromadzoną ilość w cyklu godzinowym.",
    icon: "cityhall",
    automatic_product: null,
    automatic_currency: { currency_id: 2 },
    level: 1,
    name: "Ratusz",
    additional_details: "",
    required_level: 1,
    required_population: null,
    building_time: 300,
    building_time_in_days: null,
    fast_building_time: 0,
    repair_building_time: 0,
    build_product_prices: [],
    exp_points: 151,
    attributes: [
      { id: 1, attribute: "auto-production-hourly-performance", value: 20 },
      { id: 4, attribute: "storage-size", value: 1500 },
    ],
    probability_parameters: [],
    repair_prices: [],
    build_currency_prices: [
      { currency_id: 1, amount: 600 },
      { currency_id: 2, amount: 100 },
    ],
    fast_build_currency_prices: [],
    fast_build_product_prices: [],
    daily_product_productions: [],
    upgrade_button_name: null,
    auto_delete: false,
    variants: [],
    original_building_time_in_seconds: 300,
    original_build_currency_prices: [
      { currency_id: 1, amount: 600 },
      { currency_id: 2, amount: 100 },
    ],
    build_prices: [
      { currency_id: 1, amount: 600 },
      { currency_id: 2, amount: 100 },
    ],
    original_build_prices: [
      { currency_id: 1, amount: 600 },
      { currency_id: 2, amount: 100 },
    ],
    fast_build_prices: [],
    required_products: [],
    probability_parameters_percentage: 0,
  },
  ref: { playerTileId: 55176 },
};
