import { Component, OnInit } from "@angular/core";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { AbstractBasicActionsComponent } from "../../../../abstract/custom/abstract-basic-actions.component";
import { BASIC_ACTIONS_MOCK } from "./basic-actions.mock";

@Component({
  selector: "app-basic-actions",
  templateUrl: "./basic-actions.component.html",
})
export class BasicActionsComponent extends AbstractBasicActionsComponent implements OnInit {
  @OwInject(DialogService) dialogService: DialogService;
  BASIC_ACTIONS_MOCK = BASIC_ACTIONS_MOCK;

  ngOnInit() {
    // this.getBasicActionDetails(new OwDate().momentDate.format('YYYY-MM-DD'));
    this.setBasicActionDetailsMock();
  }

  toggleBaseActions() {
    this.showBaseActions = !this.showBaseActions;
    this.setBasicActionCurrentMock();
  }

  setBasicActionDetailsMock() {
    this.basicActionDetails = this.BASIC_ACTIONS_MOCK.DETAILS;
    if (this.basicActionDetails.items.length === 0 && this.basicActionDetails.previous) {
      this.getBasicActionDetails(this.basicActionDetails.previous);
    } else {
      this.amountSums = this.sumsPrizes(this.basicActionDetails.items, "amount");
      this.quantitySums = this.sumsPrizes(this.basicActionDetails.items, "quantity");
    }
  }

  setBasicActionCurrentMock() {
    this.basicActionCurrent = this.BASIC_ACTIONS_MOCK.CURRENT;
  }
}
