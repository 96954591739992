export const RANK_CONFIG = {
  DEFAULT: {
    columns: [
      { header: null, value: "position" },
      { header: "Imię", value: "first_name" },
      { header: "Nazwisko", value: "last_name" },
      { columnFromApi: 1, textAlign: "center" },
      { columnFromApi: 2, textAlign: "center" },
      { columnFromApi: 3, textAlign: "center" },
      { header: "Nagroda", prizes: true, textAlign: "right" },
    ],
  },
  AGGREGATED_BRANCHES: {
    columns: [
      { header: null, value: "position" },
      { header: "Salon", value: "branch_name" },
      { columnFromApi: 1, textAlign: "center" },
      { columnFromApi: 2, textAlign: "center" },
      { columnFromApi: 3, textAlign: "center" },
      { header: "Nagroda", prizes: true, textAlign: "right" },
    ],
  },
};
