import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../../core/providers/api.service';
import { ApiOptions } from '../../../../../../../core/interfaces/api-options';
import { PlayerService } from '../../../../../../player/providers/player.service';

@Injectable({
  providedIn: 'root'
})
export class ApiBusinessQuestionService {

  constructor(
    private apiService: ApiService,
    private playerService: PlayerService,
  ) {
  }

  getQuestionsList({month, year, playerId}: { month: number, year: number, playerId?: number }) {
    const options: ApiOptions = {
      params: {
        month,
        year,
      }
    };

    playerId = playerId || this.playerService.getActivePlayerId();

    return this.apiService.get(`daily-question/${playerId}`, options);
  }

  postQuestion({playerQuestionId, answers}: { playerQuestionId: number, answers: number[] }) {
    const options: ApiOptions = {
      body: {
        answers,
      }
    };

    return this.apiService.post(`daily-question/${playerQuestionId}`, options);
  }

  patchQuestionOpenAttachment({playerQuestionId}: { playerQuestionId: number }) {
    return this.apiService.patch(`daily-question/${playerQuestionId}/open-attachment`);
  }
}
