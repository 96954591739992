<!-- LOGO -->
<div class="logo"></div>

<loading></loading>

<div class="wrapper">
    <div class="container loading-opacity">
        <p class="title">{{ "auth.change-password.title" | myTranslate }}:</p>

        <form [formGroup]="form">
            <!-- OLD PASSWORD FORM -->
            <div
                    class="control"
                    [class.error]="
          form.get('oldPassword').invalid && form.get('oldPassword').dirty && form.get('oldPassword').errors
        ">
                <div class="label-flex">
                    <label>{{ "auth.change-password.form.oldPassword" | myTranslate }}:</label>
                </div>
                <input class="control-input" formControlName="oldPassword" type="password" />
            </div>

            <!-- PASSWORD FORM -->
            <div
                    class="control"
                    [class.error]="form.get('password').invalid && form.get('password').dirty && form.get('password').errors">
                <div class="label-flex">
                    <label>{{ "auth.change-password.form.password" | myTranslate }}:</label>
                </div>
                <input class="control-input" formControlName="password" type="password" />
            </div>

            <!-- PASSWORD REPEAT FORM -->
            <div
                    class="control"
                    [class.error]="
          form.get('passwordRepeat').invalid && form.get('passwordRepeat').dirty && form.get('passwordRepeat').errors
        ">
                <div class="label-flex">
                    <label>{{ "auth.change-password.form.password-repeat" | myTranslate }}:</label>
                </div>
                <input class="control-input" formControlName="passwordRepeat" type="password" />
            </div>

            <p
                    class="description invalid-form error"
                    [class.opacity-1]="form.get('password').value && form.get('passwordRepeat').value && form.hasError('notSame')">
                {{ "auth.registration-confirm-a.form.invalid-password-the-same" | myTranslate }}
            </p>
        </form>

        <div class="buttons" [ngClass]="{'flex-center': !isShowBackButton}">
            <button class="base primary" [routerLink]="AUTH_ROUTING_ABSOLUTE_PATH.GAME" *ngIf="isShowBackButton">
                {{ "auth.forgot-password.button-back" | myTranslate }}
            </button>

            <button class="base primary" (click)="onFormSubmit()" [disabled]="form.invalid">
                {{ "auth.change-password.form.button-send" | myTranslate }}
            </button>
        </div>
    </div>
    <a class="contact" [href]="'mailto:' + config?.common?.contact_email">{{ config.common.contact_email }}</a>
</div>
<button class="base primary explore-game"
        *ngIf="config.landing_page.enabled"
        [routerLink]="AUTH_ROUTING_ABSOLUTE_PATH.LANDING">
    {{ "auth.login.know-your-mission" | myTranslate }}
</button>

<div class="copyright-container" *ngIf="config.default_page.copyright_text">
    <p [innerHTML]="config.default_page.copyright_text"></p>
</div>
