import { Component, OnDestroy, OnInit } from "@angular/core";

import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { AbstractHudMenuComponent } from "../../../../abstract/core/abstract-hud-menu.component";

@Component({
  selector: "app-hud-menu",
  templateUrl: "./hud-menu.component.html",
})
export class HudMenuComponent extends AbstractHudMenuComponent implements OnInit, OnDestroy {
  ngOnInit() {
    super.ngOnInit();

    if (!this.isDialog) {
      this.subs.globalEmitter = this.globalService.globalEvents.subscribe(this.handleGameEvents.bind(this));
    } else {
      this.setMenu();
    }
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
