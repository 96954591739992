export const MOCK_TRANSACTION_HISTORY = {
  api: {
    headers: [],
    status: 200,
    statusText: "OK",
    url: "https://komputronik.o-w.dev/api/player/795/transactions?page=1&currency=1&limit=10",
    ok: true,
    type: 4,
    body: [
      { reason: "Bonus za logowanie", value: 800, transaction_at: "2021-06-02T08:58:49+02:00" },
      {
        reason: "Bonus za logowanie",
        value: 700,
        transaction_at: "2021-06-01T14:44:40+02:00",
      },
      {
        reason: 'Realizacja zadania "SERWIS" [17] - 2021-4',
        value: 10000,
        transaction_at: "2021-05-31T11:55:12+02:00",
      },
      {
        reason: 'Realizacja zadania "FOLIA" [16] - 2021-4',
        value: 10000,
        transaction_at: "2021-05-31T11:55:12+02:00",
      },
      {
        reason: 'Realizacja zadania "KONFIGURACJE" [15] - 2021-4',
        value: 10000,
        transaction_at: "2021-05-31T11:55:12+02:00",
      },
      {
        reason: "Bonus za logowanie",
        value: 600,
        transaction_at: "2021-05-31T10:46:07+02:00",
      },
      {
        reason: 'Realizacja zadania "SERWIS" [17] - 2021-3',
        value: 6000,
        transaction_at: "2021-05-04T09:00:11+02:00",
      },
      {
        reason: 'Realizacja zadania "FOLIA" [16] - 2021-3',
        value: 14000,
        transaction_at: "2021-05-04T09:00:11+02:00",
      },
      {
        reason: 'Realizacja zadania "KONFIGURACJE" [15] - 2021-3',
        value: 6000,
        transaction_at: "2021-05-04T09:00:11+02:00",
      },
      { reason: 'Realizacja zadania "POLISY" [14] - 2021-3', value: 6000, transaction_at: "2021-05-04T09:00:11+02:00" },
    ],
  },
  ref: {
    currency: {
      type: "player",
      currency_id: 1,
      key: "brick",
      name: "Cegły",
      description: "Cegły",
      show_currency_history: true,
      is_gross: false,
      is_default_trading_currency: false,
      tooltip:
        "<b>Cegły to główna waluta w Twoim mieście, umożliwiająca budowanie oraz rozbudowywanie budynków.<br> Otrzymujesz je za realizację zadań biznesowych, quizów oraz misji w grze</b>",
      position: 1,
      balance: 136410,
      iconUrlBig: "/main/assets/currency/big/brick.png?fe2662c8a9caeff7d3772538c8829900",
      iconUrl: "/main/assets/currency/basic/brick.png?579da0b1224f0cdecc4960148c3e7d9f",
      have: false,
    },
  },
};
