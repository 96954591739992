import { Component, OnInit } from "@angular/core";

import { GAME_EVENTS } from "../../../../../../constants";
import { MAIN_BOOT_SCENE } from "../../../../../../game-engine/scenes-main/main.constants";
import { WORLD_BOOT_SCENE } from "../../../../../../game-engine/scenes-world/world.constants";
import { AbstractHudComponent } from "../../../../abstract/core/abstract-hud.component";

@Component({
  selector: "app-hud",
  templateUrl: "./hud.component.html",
})
export class HudComponent extends AbstractHudComponent implements OnInit {
  ngOnInit() {
    this.subscribePlayer();
    this.subscribeUser();
    this.setDynamicsComponents();
  }
}
