import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

import { omitErrorResponseHelper } from "../../../core/helpers/omit-error-response.helper";
import { AuthService } from "../../../modules/auth/api/services/auth.service";
import { ChangePassword } from "../../../modules/auth/interfaces/change-password";
import { TokenObject } from "../../../modules/auth/interfaces/token-object";
import { AppState } from "../../state";
import { utilitySetToken } from "../../utility/actions";
import { passwordChangeFailed, passwordChangeStart, passwordChangeSuccess, passwordMustChange } from "./actions";

@Injectable()
export class PasswordEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private store: Store<AppState>
  ) {}

  changePasswordMustChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(passwordMustChange),
        tap(action => {
          this.router.navigate(["/change-password"]);
        })
      ),
    { dispatch: false }
  );

  changePasswordStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(passwordChangeStart),
      tap(action => {
        return this.changePassword(action.data, action.tokenObject);
      })
    )
  );

  changePassword(data: ChangePassword, tokenObject: TokenObject) {
    return this.authService.changePassword(data).pipe(
      map(() => {
        return passwordChangeSuccess();
      }),
      tap(() => {
        this.store.dispatch(utilitySetToken({ token: tokenObject }));
      }),
      catchError((error: any) => {
        return of(passwordChangeFailed(omitErrorResponseHelper(error)));
      })
    );
  }
}
