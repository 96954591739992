import { Component, OnInit } from "@angular/core";

import { AbstractQaFinishComponent } from "../../../../abstract/core/abstract-qa-finish.component";

@Component({
  selector: "app-m-qa-finish",
  templateUrl: "./qa-finish.component.html",
})
export class MQaFinishComponent extends AbstractQaFinishComponent implements OnInit {
  ngOnInit() {
    this.setQa();
  }
}
