import { EventEmitter, Injectable, OnDestroy } from "@angular/core";

import { DEV_DOMAIN } from "../consts/core/dev-domain.const";
import { GlobalEvent } from "../interfaces/shared.interfaces";

@Injectable()
export class GlobalService implements OnDestroy {
  globalEvents: EventEmitter<GlobalEvent> = new EventEmitter<GlobalEvent>(true);
  isDevDomain: boolean;

  constructor() {
    this.setDevDomain();
  }

  // TODO - probably redundant #29214
  setDevDomain() {
    const hostname = window.location.hostname;
    this.isDevDomain = DEV_DOMAIN.some(domain => hostname.includes(domain));
  }

  ngOnDestroy() {
    this.globalEvents.unsubscribe();
  }
}
