import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../../core/providers/api.service';

@Injectable({
  providedIn: 'root'
})
export class ApiWithdrawTalarsService {

  constructor(
    private apiService: ApiService,
  ) {
  }

  postWithdrawTalars() {
    return this.apiService.post(`user/withdraw-talars`);
  }
}
