import { GameObjects } from "phaser";

import { BOARD_TILE_HEIGHT, PHASER_CAMERA_ZOOM } from "../../../constants";
import { TILE_MENU_ATLAS } from "../../scenes-main/main.constants";
import { keepScale1 } from "../../utils/utils";
import { Board } from "../custom/Board.class";
import { BoardTile } from "../custom/BoardTile.class";
import { MyScene } from "./MyScene";

export class MoveBuildingPreview extends GameObjects.Container {
  myScene: MyScene;
  movingTile: BoardTile;
  building: Phaser.GameObjects.Sprite;
  confirmButton: Phaser.GameObjects.Sprite;
  cancelButton: Phaser.GameObjects.Sprite;
  board: Board;

  constructor(target, board) {
    super(target.myScene, target.x, target.y);

    this.myScene = target.myScene;
    this.movingTile = target;
    this.board = board;

    this.building = this.myScene.add.sprite(0, 0, this.movingTile.baseSprite.texture.key);
    this.building.setOrigin(0.5, 1);
    this.add(this.building);

    this.confirmButton = this.myScene.add.sprite(-20, -BOARD_TILE_HEIGHT / 2, TILE_MENU_ATLAS, "accept.png");
    this.confirmButton.setOrigin(1, 0.5);
    this.add(this.confirmButton);

    this.cancelButton = this.myScene.add.sprite(20, -BOARD_TILE_HEIGHT / 2, TILE_MENU_ATLAS, "cancel.png");
    this.cancelButton.setOrigin(0, 0.5);
    this.add(this.cancelButton);

    this.depth = 100_000;

    this.handlePointerEvents();
    this.myScene.add.existing(this);
    keepScale1(this.cancelButton, this.scene.cameras.main.zoom * 2);
    keepScale1(this.confirmButton, this.scene.cameras.main.zoom * 2);

    (this.scene as MyScene).phaserEvents.on(PHASER_CAMERA_ZOOM, (zoom: number) => {
      keepScale1(this.cancelButton, zoom * 2);
      keepScale1(this.confirmButton, zoom * 2);
    });
  }

  handlePointerEvents() {
    this.confirmButton.setInteractive({ cursor: "pointer" });
    this.confirmButton.on("pointerover", () => {
      this.confirmButton.setAlpha(0.8);
    });
    this.confirmButton.on("pointerout", () => {
      this.confirmButton.setAlpha(1);
    });
    this.confirmButton.on("pointerdown", () => {
      // this.confirmButton.setFrame('button-round-green-small-a.png');
    });
    this.confirmButton.on("pointerup", () => {
      // this.confirmButton.setFrame('button-round-green-small.png');
      if (!this.movingTile.movedToTile) {
        this.board.stopBuildingMoveMode();
        return;
      }
      this.board.confirmBuildingMove(
        this.movingTile.movedToTile.tileData.player_tile_id,
        this.movingTile.playerBuildingData.player_building_id
      );
    });

    this.cancelButton.setInteractive({ cursor: "pointer" });
    this.cancelButton.on("pointerover", () => {
      this.cancelButton.setAlpha(0.8);
    });
    this.cancelButton.on("pointerout", () => {
      this.cancelButton.setAlpha(1);
    });
    this.cancelButton.on("pointerdown", () => {
      // this.cancelButton.setFrame('button-round-red-small-a.png');
    });
    this.cancelButton.on("pointerup", () => {
      // this.cancelButton.setFrame('button-round-red-small.png');
      this.board.stopBuildingMoveMode();
    });
  }
}
