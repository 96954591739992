<div class="global-dialog ow-dialog window-a primary no-transparent">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button *ngIf="data.showBackButton" buttonSize="small"></m-ui-back-button>

  <div class="container">
    <!-- CATEGORIES -->
    <div class="categories">
      <ng-container *ngFor="let item of RANKS_TYPES | keyvalue: keyAscOrder">
        <div
          *ngIf="item.value.show"
          (click)="changeActiveRankType(item.value)"
          class="category"
        >
          <span
            [class.active]="item.value === activeRankType"
          >
            {{ item.value.label }}
          </span>
        </div>
      </ng-container>
    </div>

    <ow-perfect-scrollbar class="ps-bottom-border" [scrollGradient]="rankList.length">
      <ng-container *ngIf ="rankList.length; then fetchedData else notfetchedData"></ng-container>
      <ng-template #fetchedData>
        <table>
          <tbody>
          <tr *ngFor="let rank of rankList">
            <td>{{ rank.name }}</td>
            <td>
            <span class="status-rank" [ngClass]="rank.cleared ? 'is-cleared' : 'is-active'">
              {{ rank.cleared ? 'Zamknięty' : 'Aktywny' }}
            </span>
            </td>
            <td></td>
            <td>
              <div class="buttons-container">
                <button
                  *ngIf="rank.description"
                  (click)="openRankDescription(rank)"
                  class="square small primary"
                >
                  <i class="svg-icon info-regular"></i>
                </button>

                <button
                  (click)="openRankDetails(rank)"
                  class="square small primary"
                >
                  <i class="svg-icon search-regular"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      </ng-template>
      <ng-template #notfetchedData>
        <ng-container *ngIf="!isLoading">
          <p class="no-results">Brak wyników</p>
        </ng-container>
      </ng-template>
    </ow-perfect-scrollbar>
  </div>

  <div class="bottom-bar" *ngIf="owPaginationDate && activeRankType.type === RANK_TYPE.BUSINESS">
    <div class="bar-button long">
      <div class="pagination">
        <div class="buttons">
          <!-- NEXT -->
          <button
            class="square small primary"
            (click)="prev()"
            [disabled]="!owPaginationDate.allowPrev.month"
          >
            <i class="svg-icon chevron-left"></i>
          </button>
        </div>

        <div class="pages">
          {{ owPaginationDate.current.month_text_roman }}
        </div>

        <div class="buttons">
          <!-- PREV -->
          <button
            class="square small primary"
            (click)="next()"

          >
            <i class="svg-icon chevron-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
