import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { SharedModule } from "../../shared/shared.module";

@NgModule({
  imports: [CommonModule, SharedModule],
  exports: [CommonModule, SharedModule],
})
export class UtilityAbstractModule {}
