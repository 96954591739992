import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";

import { SchemaValidatorService } from "../../services/schema-validator/schema-validator.service";
import { ApiService } from "./api.service";

@Injectable()
export class PrimaryService {
  constructor(
    private apiService: ApiService,
    private schemaValidator: SchemaValidatorService
  ) {}

  primary() {
    return this.apiService.get(`primary`).pipe(
      tap(response => {
        this.schemaValidator.validateResponse({
          url: "primary",
          payload: {},
          response: response,
          schema: "primaryCore",
        });
      })
    );
  }
}
