import { Component, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { AbstractInjectBaseComponent } from "../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../core/decorators/ow-inject.decorator";

const flowplayer = window["flowplayer"];

@Component({
  selector: "app-core-video",
  templateUrl: "./video.component.html",
})
export class VideoComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<VideoComponent>;
  @OwInject(MAT_DIALOG_DATA) data: {
    src: string;
    youtubeId: string;
  };
  isLogicNewTab = true;
  linkNewTab: string;

  youtubeEmbed = "https://www.youtube.com/embed/[youtubeId]?autoplay=1";

  ngOnInit() {
    this.setYoutubeEmbed();

    if (this.isLogicNewTab) {
      this.linkNewTab = this.data.src || (this.data.youtubeId && this.youtubeEmbed);
      this.openNewTab();
      this.close();
    } else {
      this.setPlayer(this.data.src);
    }
  }

  setYoutubeEmbed() {
    this.youtubeEmbed = this.youtubeEmbed.replace("[youtubeId]", this.data.youtubeId);
  }

  setPlayer(src: string) {
    setTimeout(() => {
      if (src) {
        const container = document.getElementById("flowplayer-movie");

        const currentPlayer = flowplayer(container, {
          share: false,
          fullscreen: true,
          mutedAutoplay: false,
          clip: {
            sources: [
              {
                type: "video/mp4",
                src,
              },
            ],
          },
        });

        currentPlayer.load();
      }
    });
  }

  openNewTab() {
    if (this.linkNewTab) {
      window.open(this.linkNewTab, "_blank");
    } else {
      console.error("Link new tab is empty");
    }
  }

  close() {
    setTimeout(() => {
      this.matDialogRef.close();
    });
  }
}
