<table *ngIf="rankResults">
  <thead>
  <tr [ngClass]="{'hide-element': show !== 'header'}">
    <ng-container *ngFor="let column of columns">
      <!-- FE COLUMN HEADER -->
      <th
        *ngIf="!column.columnFromApi"
        [style.text-align]="column.textAlign"
        [class.display-none]="
          column.header == 'Nagroda' &&
          (activeFilter.filterKey !== LEVEL_FILTER_KEY.COUNTRY || (rank.parameters && rank.parameters['is_hide_column_prizes']))
        "
        [class.column-prizes]="column.header == 'Nagroda'"
      >
        {{ column.header }}
      </th>

      <!-- BE COLUMN HEADER -->
      <ng-container *ngIf="column.columnFromApi">
        <th
          *ngIf="rank['value' + column.columnFromApi + '_label'] as label"
          [style.text-align]="column.textAlign"
        >
          {{ label }}
        </th>
      </ng-container>
    </ng-container>
  </tr>
  </thead>
  <tbody>
  <!-- MY POSITION -->
  <ng-container
    *ngIf="rankResults.my_position"
    [ngTemplateOutlet]="tableTrTpl"
    [ngTemplateOutletContext]="{
          item: rankResults.my_position,
          rank: rank,
          showTr: show === 'myPosition'
        }"
  ></ng-container>

  <!-- RESULTS -->
  <ng-container
    *ngFor="let item of rankResults.results"
    [ngTemplateOutlet]="tableTrTpl"
    [ngTemplateOutletContext]="{
          item: item,
          rank: rank,
          showTr: show === 'results'
        }"
  ></ng-container>
  </tbody>
</table>


<!-- TABLE TR TEMPLATE -->
<ng-template
  #tableTrTpl
  let-item="item"
  let-rank="rank"
  let-showTr="showTr"
>
  <tr [ngClass]="{'hide-element': !showTr}" >
    <ng-container *ngFor="let column of columns">
      <!-- FE COLUMN -->
      <ng-container *ngIf="!column.columnFromApi">
        <!-- PRIZES COLUMN -->
        <td
          *ngIf="column.prizes; else defaultTdTpl"
          [style.text-align]="column.textAlign"
          [class.display-none]="
            column.prizes &&
            (rank.parameters && rank.parameters['is_hide_column_prizes'])
          "
        >
          <div class="prizes">
            <!-- CURRENCY PRIZES -->
            <m-ui-currency
              *ngFor="let currency of item.currency_prizes"
              [item]="currency"
              [stockView]="STOCK_VIEW.H"
              [lack]="false"
            ></m-ui-currency>

            <!-- PRODUCT PRIZES -->
            <m-ui-product
              *ngFor="let product of item.product_prizes"
              [item]="product"
              [stockView]="STOCK_VIEW.H"
              [hideValue]="true"
              [lack]="false"
              [lockOpenStorage]="true"
            ></m-ui-product>
          </div>
        </td>

        <!-- DEFAULT COLUMN -->
        <ng-template #defaultTdTpl>
          <td [style.text-align]="column.textAlign">
            {{ item[column.value] }}
          </td>
        </ng-template>
      </ng-container>

      <!-- BE COLUMN -->
      <ng-container *ngIf="column.columnFromApi">
        <td
          *ngIf="rank['value' + column.columnFromApi + '_label']"
          [ngbTooltip]="item['value' + column.columnFromApi + '_tooltip']"
          container="body"
          placement="top"
          [style.text-align]="column.textAlign"
        >
          {{ item['value' + column.columnFromApi] }}
        </td>
      </ng-container>
    </ng-container>
  </tr>
</ng-template>
