import { Injectable } from "@angular/core";
import { CanActivate, CanLoad } from "@angular/router";
import { concatLatestFrom } from "@ngrx/operators";
import { select, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, filter, map, switchMap, take, tap } from "rxjs/operators";

import { primaryFetchStart } from "../../store/primary/actions";
import { selectPrimaryState } from "../../store/primary/selectors";
import { AppState } from "../../store/state";
import { ApiService } from "../providers/api.service";

@Injectable({
  providedIn: "root",
})
export class PrimaryGuard implements CanActivate, CanLoad {
  constructor(
    private apiService: ApiService,
    public store: Store<AppState>
  ) {}

  getState(): Observable<any> {
    return this.store.pipe(
      concatLatestFrom(() => this.store.pipe(select(selectPrimaryState))),
      map(([state, primaryState]) => primaryState),
      tap(primaryState => {
        if (!primaryState.isLoading && !primaryState.error) {
          this.store.dispatch(primaryFetchStart());
        }
      }),
      filter(primaryState => {
        return primaryState.primary !== null;
      }),
      take(1)
    );
  }

  checkPrimary() {
    return this.getState().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  canActivate(): Observable<boolean> {
    return this.checkPrimary();
  }

  canLoad(): Observable<boolean> {
    return this.checkPrimary();
  }
}
