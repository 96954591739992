<div class="top-left-container">
  <!-- HUD PLAYER -->
  <app-hud-player [player]="player"></app-hud-player>
</div>

<!-- HUD PARAMETERS -->
<div class="hud-parameters">
  <template #hudParametersTpl></template>
</div>

<!-- HUD RESOURCES -->
<div class="hud-resources">
  <template #hudResourcesTpl></template>
</div>

<!-- HUD OFFSET TIME -->
<app-hud-offset-time [player]="player"></app-hud-offset-time>

<!-- HUD MENU -->
<div class="hud-menu">
  <template #hudMenuTpl></template>
</div>

<div class="business-bottom">
  <template #hudMenuBusinessTpl></template>
</div>

<div class="special">
  <template #hudSpecialTpl></template>
</div>

<!-- OTHER DYNAMICS COMPONENT -->
<template #dynamicsComponentTpl></template>
