import "phaser";

import { select } from "@ngrx/store";
import { of } from "rxjs";
import { filter, mergeMap, take, tap } from "rxjs/operators";

import { gameResetBoard } from "../../../../../store/game/actions";
import { selectGameBoardInitiationData } from "../../../../../store/game/selectors";
import { MyGame } from "../../classes/core/MyGame";
import { MyScene } from "../../classes/core/MyScene";
import { CustomWorldMainScene } from "../custom/CustomWorld.main.scene";
import { CustomWorldPreloadScene } from "../custom/CustomWorld.preload.scene";
import { getWorldSceneConfig } from "../helpers/WorldScene.helper";
import { WORLD_BOOT_SCENE, WORLD_MAIN_SCENE, WORLD_PRELOAD_SCENE } from "../world.constants";

export class WorldBootScene extends MyScene {
  constructor(sceneClassConfig?: Phaser.Types.Scenes.SettingsConfig) {
    sceneClassConfig = sceneClassConfig ? sceneClassConfig : { key: WORLD_BOOT_SCENE };
    super(sceneClassConfig);
  }

  init(data?: any) {
    this.passedData = data;
  }

  provideAllScenes() {
    if (!this.scene.get(WORLD_PRELOAD_SCENE)) {
      this.scene.add(WORLD_PRELOAD_SCENE, CustomWorldPreloadScene, false);
      this.scene.add(WORLD_MAIN_SCENE, CustomWorldMainScene, false);
    }
  }

  provideSceneConfig() {
    return getWorldSceneConfig();
  }

  preload(): void {
    super.preload();
    // @ts-ignore
    this.sceneConfig = this.provideSceneConfig();
    this.gameService = (this.game as MyGame).gameService;
    this.gameService.guiService.isSplashShow.next(true);

    if (this.passedData && this.passedData.playerIslandId) {
      this.gameService.prepareGameData(this.gameService.playerService.player.id, this.passedData.playerIslandId);
    } else {
      this.gameService.boardService
        .getWorld(this.gameService.playerService.getActivePlayerId())
        .pipe(
          mergeMap(worldData => {
            let worldIsland;
            worldData.find(
              regionData =>
                (worldIsland = regionData.islands.find(
                  island => island.group_id === this.sceneConfig.islandGroupIdForBoard
                ))
            );
            if (worldIsland) {
              this.gameService.prepareGameData(this.gameService.playerService.player.id, worldIsland.player_island_id);
            }
            return of(worldIsland);
          })
        )
        .subscribe(console.log);
    }

    this.provideAllScenes();
  }

  create(): void {
    this.gameService.store
      .pipe(
        select(selectGameBoardInitiationData),
        filter(data => data.loaded),
        take(1)
      )
      .subscribe(data => {
        const assetsToPreload = this.gameService.buildingsService.getBuildingsAssetsNamesFromBoard(data.boardTiles);
        this.scene.start(WORLD_PRELOAD_SCENE, { assetsToPreload, passedData: this.passedData });
      });
  }
}
