export const TERYT = [
  {
    'id_voivodeship': '02',
    'name': 'DOLNOŚLĄSKIE',
    'counties': [
      {
        'id_voivodeship': '02',
        'id_county': '01',
        'name': 'bolesławiecki',
        'communes': [
          {
            'id_voivodeship': '02',
            'id_county': '01',
            'id_commune': '01',
            'name': 'Bolesławiec'
          },
          {
            'id_voivodeship': '02',
            'id_county': '01',
            'id_commune': '03',
            'name': 'Gromadka'
          },
          {
            'id_voivodeship': '02',
            'id_county': '01',
            'id_commune': '04',
            'name': 'Nowogrodziec'
          },
          {
            'id_voivodeship': '02',
            'id_county': '01',
            'id_commune': '05',
            'name': 'Osiecznica'
          },
          {
            'id_voivodeship': '02',
            'id_county': '01',
            'id_commune': '06',
            'name': 'Warta Bolesławiecka'
          }
        ]
      },
      {
        'id_voivodeship': '02',
        'id_county': '02',
        'name': 'dzierżoniowski',
        'communes': [
          {
            'id_voivodeship': '02',
            'id_county': '02',
            'id_commune': '01',
            'name': 'Bielawa'
          },
          {
            'id_voivodeship': '02',
            'id_county': '02',
            'id_commune': '02',
            'name': 'Dzierżoniów'
          },
          {
            'id_voivodeship': '02',
            'id_county': '02',
            'id_commune': '03',
            'name': 'Pieszyce'
          },
          {
            'id_voivodeship': '02',
            'id_county': '02',
            'id_commune': '04',
            'name': 'Piława Górna'
          },
          {
            'id_voivodeship': '02',
            'id_county': '02',
            'id_commune': '06',
            'name': 'Łagiewniki'
          },
          {
            'id_voivodeship': '02',
            'id_county': '02',
            'id_commune': '07',
            'name': 'Niemcza'
          }
        ]
      },
      {
        'id_voivodeship': '02',
        'id_county': '03',
        'name': 'głogowski',
        'communes': [
          {
            'id_voivodeship': '02',
            'id_county': '03',
            'id_commune': '01',
            'name': 'Głogów'
          },
          {
            'id_voivodeship': '02',
            'id_county': '03',
            'id_commune': '03',
            'name': 'Jerzmanowa'
          },
          {
            'id_voivodeship': '02',
            'id_county': '03',
            'id_commune': '04',
            'name': 'Kotla'
          },
          {
            'id_voivodeship': '02',
            'id_county': '03',
            'id_commune': '05',
            'name': 'Pęcław'
          },
          {
            'id_voivodeship': '02',
            'id_county': '03',
            'id_commune': '06',
            'name': 'Żukowice'
          }
        ]
      },
      {
        'id_voivodeship': '02',
        'id_county': '04',
        'name': 'górowski',
        'communes': [
          {
            'id_voivodeship': '02',
            'id_county': '04',
            'id_commune': '01',
            'name': 'Góra'
          },
          {
            'id_voivodeship': '02',
            'id_county': '04',
            'id_commune': '02',
            'name': 'Jemielno'
          },
          {
            'id_voivodeship': '02',
            'id_county': '04',
            'id_commune': '03',
            'name': 'Niechlów'
          },
          {
            'id_voivodeship': '02',
            'id_county': '04',
            'id_commune': '04',
            'name': 'Wąsosz'
          }
        ]
      },
      {
        'id_voivodeship': '02',
        'id_county': '05',
        'name': 'jaworski',
        'communes': [
          {
            'id_voivodeship': '02',
            'id_county': '05',
            'id_commune': '01',
            'name': 'Jawor'
          },
          {
            'id_voivodeship': '02',
            'id_county': '05',
            'id_commune': '02',
            'name': 'Bolków'
          },
          {
            'id_voivodeship': '02',
            'id_county': '05',
            'id_commune': '03',
            'name': 'Męcinka'
          },
          {
            'id_voivodeship': '02',
            'id_county': '05',
            'id_commune': '04',
            'name': 'Mściwojów'
          },
          {
            'id_voivodeship': '02',
            'id_county': '05',
            'id_commune': '05',
            'name': 'Paszowice'
          },
          {
            'id_voivodeship': '02',
            'id_county': '05',
            'id_commune': '06',
            'name': 'Wądroże Wielkie'
          }
        ]
      },
      {
        'id_voivodeship': '02',
        'id_county': '06',
        'name': 'jeleniogórski',
        'communes': [
          {
            'id_voivodeship': '02',
            'id_county': '06',
            'id_commune': '01',
            'name': 'Karpacz'
          },
          {
            'id_voivodeship': '02',
            'id_county': '06',
            'id_commune': '02',
            'name': 'Kowary'
          },
          {
            'id_voivodeship': '02',
            'id_county': '06',
            'id_commune': '03',
            'name': 'Piechowice'
          },
          {
            'id_voivodeship': '02',
            'id_county': '06',
            'id_commune': '04',
            'name': 'Szklarska Poręba'
          },
          {
            'id_voivodeship': '02',
            'id_county': '06',
            'id_commune': '05',
            'name': 'Janowice Wielkie'
          },
          {
            'id_voivodeship': '02',
            'id_county': '06',
            'id_commune': '06',
            'name': 'Jeżów Sudecki'
          },
          {
            'id_voivodeship': '02',
            'id_county': '06',
            'id_commune': '07',
            'name': 'Mysłakowice'
          },
          {
            'id_voivodeship': '02',
            'id_county': '06',
            'id_commune': '08',
            'name': 'Podgórzyn'
          },
          {
            'id_voivodeship': '02',
            'id_county': '06',
            'id_commune': '09',
            'name': 'Stara Kamienica'
          }
        ]
      },
      {
        'id_voivodeship': '02',
        'id_county': '07',
        'name': 'kamiennogórski',
        'communes': [
          {
            'id_voivodeship': '02',
            'id_county': '07',
            'id_commune': '01',
            'name': 'Kamienna Góra'
          },
          {
            'id_voivodeship': '02',
            'id_county': '07',
            'id_commune': '03',
            'name': 'Lubawka'
          },
          {
            'id_voivodeship': '02',
            'id_county': '07',
            'id_commune': '04',
            'name': 'Marciszów'
          }
        ]
      },
      {
        'id_voivodeship': '02',
        'id_county': '08',
        'name': 'kłodzki',
        'communes': [
          {
            'id_voivodeship': '02',
            'id_county': '08',
            'id_commune': '01',
            'name': 'Duszniki-Zdrój'
          },
          {
            'id_voivodeship': '02',
            'id_county': '08',
            'id_commune': '02',
            'name': 'Kłodzko'
          },
          {
            'id_voivodeship': '02',
            'id_county': '08',
            'id_commune': '03',
            'name': 'Kudowa-Zdrój'
          },
          {
            'id_voivodeship': '02',
            'id_county': '08',
            'id_commune': '04',
            'name': 'Nowa Ruda'
          },
          {
            'id_voivodeship': '02',
            'id_county': '08',
            'id_commune': '05',
            'name': 'Polanica-Zdrój'
          },
          {
            'id_voivodeship': '02',
            'id_county': '08',
            'id_commune': '06',
            'name': 'Bystrzyca Kłodzka'
          },
          {
            'id_voivodeship': '02',
            'id_county': '08',
            'id_commune': '08',
            'name': 'Lądek-Zdrój'
          },
          {
            'id_voivodeship': '02',
            'id_county': '08',
            'id_commune': '09',
            'name': 'Lewin Kłodzki'
          },
          {
            'id_voivodeship': '02',
            'id_county': '08',
            'id_commune': '10',
            'name': 'Międzylesie'
          },
          {
            'id_voivodeship': '02',
            'id_county': '08',
            'id_commune': '12',
            'name': 'Radków'
          },
          {
            'id_voivodeship': '02',
            'id_county': '08',
            'id_commune': '13',
            'name': 'Stronie Śląskie'
          },
          {
            'id_voivodeship': '02',
            'id_county': '08',
            'id_commune': '14',
            'name': 'Szczytna'
          }
        ]
      },
      {
        'id_voivodeship': '02',
        'id_county': '09',
        'name': 'legnicki',
        'communes': [
          {
            'id_voivodeship': '02',
            'id_county': '09',
            'id_commune': '01',
            'name': 'Chojnów'
          },
          {
            'id_voivodeship': '02',
            'id_county': '09',
            'id_commune': '03',
            'name': 'Krotoszyce'
          },
          {
            'id_voivodeship': '02',
            'id_county': '09',
            'id_commune': '04',
            'name': 'Kunice'
          },
          {
            'id_voivodeship': '02',
            'id_county': '09',
            'id_commune': '05',
            'name': 'Legnickie Pole'
          },
          {
            'id_voivodeship': '02',
            'id_county': '09',
            'id_commune': '06',
            'name': 'Miłkowice'
          },
          {
            'id_voivodeship': '02',
            'id_county': '09',
            'id_commune': '07',
            'name': 'Prochowice'
          },
          {
            'id_voivodeship': '02',
            'id_county': '09',
            'id_commune': '08',
            'name': 'Ruja'
          }
        ]
      },
      {
        'id_voivodeship': '02',
        'id_county': '10',
        'name': 'lubański',
        'communes': [
          {
            'id_voivodeship': '02',
            'id_county': '10',
            'id_commune': '01',
            'name': 'Lubań'
          },
          {
            'id_voivodeship': '02',
            'id_county': '10',
            'id_commune': '02',
            'name': 'Świeradów-Zdrój'
          },
          {
            'id_voivodeship': '02',
            'id_county': '10',
            'id_commune': '03',
            'name': 'Leśna'
          },
          {
            'id_voivodeship': '02',
            'id_county': '10',
            'id_commune': '05',
            'name': 'Olszyna'
          },
          {
            'id_voivodeship': '02',
            'id_county': '10',
            'id_commune': '06',
            'name': 'Platerówka'
          },
          {
            'id_voivodeship': '02',
            'id_county': '10',
            'id_commune': '07',
            'name': 'Siekierczyn'
          }
        ]
      },
      {
        'id_voivodeship': '02',
        'id_county': '11',
        'name': 'lubiński',
        'communes': [
          {
            'id_voivodeship': '02',
            'id_county': '11',
            'id_commune': '01',
            'name': 'Lubin'
          },
          {
            'id_voivodeship': '02',
            'id_county': '11',
            'id_commune': '03',
            'name': 'Rudna'
          },
          {
            'id_voivodeship': '02',
            'id_county': '11',
            'id_commune': '04',
            'name': 'Ścinawa'
          }
        ]
      },
      {
        'id_voivodeship': '02',
        'id_county': '12',
        'name': 'lwówecki',
        'communes': [
          {
            'id_voivodeship': '02',
            'id_county': '12',
            'id_commune': '01',
            'name': 'Gryfów Śląski'
          },
          {
            'id_voivodeship': '02',
            'id_county': '12',
            'id_commune': '02',
            'name': 'Lubomierz'
          },
          {
            'id_voivodeship': '02',
            'id_county': '12',
            'id_commune': '03',
            'name': 'Lwówek Śląski'
          },
          {
            'id_voivodeship': '02',
            'id_county': '12',
            'id_commune': '04',
            'name': 'Mirsk'
          },
          {
            'id_voivodeship': '02',
            'id_county': '12',
            'id_commune': '05',
            'name': 'Wleń'
          }
        ]
      },
      {
        'id_voivodeship': '02',
        'id_county': '13',
        'name': 'milicki',
        'communes': [
          {
            'id_voivodeship': '02',
            'id_county': '13',
            'id_commune': '01',
            'name': 'Cieszków'
          },
          {
            'id_voivodeship': '02',
            'id_county': '13',
            'id_commune': '02',
            'name': 'Krośnice'
          },
          {
            'id_voivodeship': '02',
            'id_county': '13',
            'id_commune': '03',
            'name': 'Milicz'
          }
        ]
      },
      {
        'id_voivodeship': '02',
        'id_county': '14',
        'name': 'oleśnicki',
        'communes': [
          {
            'id_voivodeship': '02',
            'id_county': '14',
            'id_commune': '01',
            'name': 'Oleśnica'
          },
          {
            'id_voivodeship': '02',
            'id_county': '14',
            'id_commune': '02',
            'name': 'Bierutów'
          },
          {
            'id_voivodeship': '02',
            'id_county': '14',
            'id_commune': '03',
            'name': 'Dobroszyce'
          },
          {
            'id_voivodeship': '02',
            'id_county': '14',
            'id_commune': '04',
            'name': 'Dziadowa Kłoda'
          },
          {
            'id_voivodeship': '02',
            'id_county': '14',
            'id_commune': '05',
            'name': 'Międzybórz'
          },
          {
            'id_voivodeship': '02',
            'id_county': '14',
            'id_commune': '07',
            'name': 'Syców'
          },
          {
            'id_voivodeship': '02',
            'id_county': '14',
            'id_commune': '08',
            'name': 'Twardogóra'
          }
        ]
      },
      {
        'id_voivodeship': '02',
        'id_county': '15',
        'name': 'oławski',
        'communes': [
          {
            'id_voivodeship': '02',
            'id_county': '15',
            'id_commune': '01',
            'name': 'Oława'
          },
          {
            'id_voivodeship': '02',
            'id_county': '15',
            'id_commune': '02',
            'name': 'Domaniów'
          },
          {
            'id_voivodeship': '02',
            'id_county': '15',
            'id_commune': '03',
            'name': 'Jelcz-Laskowice'
          }
        ]
      },
      {
        'id_voivodeship': '02',
        'id_county': '16',
        'name': 'polkowicki',
        'communes': [
          {
            'id_voivodeship': '02',
            'id_county': '16',
            'id_commune': '01',
            'name': 'Chocianów'
          },
          {
            'id_voivodeship': '02',
            'id_county': '16',
            'id_commune': '02',
            'name': 'Gaworzyce'
          },
          {
            'id_voivodeship': '02',
            'id_county': '16',
            'id_commune': '03',
            'name': 'Grębocice'
          },
          {
            'id_voivodeship': '02',
            'id_county': '16',
            'id_commune': '04',
            'name': 'Polkowice'
          },
          {
            'id_voivodeship': '02',
            'id_county': '16',
            'id_commune': '05',
            'name': 'Przemków'
          },
          {
            'id_voivodeship': '02',
            'id_county': '16',
            'id_commune': '06',
            'name': 'Radwanice'
          }
        ]
      },
      {
        'id_voivodeship': '02',
        'id_county': '17',
        'name': 'strzeliński',
        'communes': [
          {
            'id_voivodeship': '02',
            'id_county': '17',
            'id_commune': '01',
            'name': 'Borów'
          },
          {
            'id_voivodeship': '02',
            'id_county': '17',
            'id_commune': '02',
            'name': 'Kondratowice'
          },
          {
            'id_voivodeship': '02',
            'id_county': '17',
            'id_commune': '03',
            'name': 'Przeworno'
          },
          {
            'id_voivodeship': '02',
            'id_county': '17',
            'id_commune': '04',
            'name': 'Strzelin'
          },
          {
            'id_voivodeship': '02',
            'id_county': '17',
            'id_commune': '05',
            'name': 'Wiązów'
          }
        ]
      },
      {
        'id_voivodeship': '02',
        'id_county': '18',
        'name': 'średzki',
        'communes': [
          {
            'id_voivodeship': '02',
            'id_county': '18',
            'id_commune': '01',
            'name': 'Kostomłoty'
          },
          {
            'id_voivodeship': '02',
            'id_county': '18',
            'id_commune': '02',
            'name': 'Malczyce'
          },
          {
            'id_voivodeship': '02',
            'id_county': '18',
            'id_commune': '03',
            'name': 'Miękinia'
          },
          {
            'id_voivodeship': '02',
            'id_county': '18',
            'id_commune': '04',
            'name': 'Środa Śląska'
          },
          {
            'id_voivodeship': '02',
            'id_county': '18',
            'id_commune': '05',
            'name': 'Udanin'
          }
        ]
      },
      {
        'id_voivodeship': '02',
        'id_county': '19',
        'name': 'świdnicki',
        'communes': [
          {
            'id_voivodeship': '02',
            'id_county': '19',
            'id_commune': '01',
            'name': 'Świdnica'
          },
          {
            'id_voivodeship': '02',
            'id_county': '19',
            'id_commune': '02',
            'name': 'Świebodzice'
          },
          {
            'id_voivodeship': '02',
            'id_county': '19',
            'id_commune': '03',
            'name': 'Dobromierz'
          },
          {
            'id_voivodeship': '02',
            'id_county': '19',
            'id_commune': '04',
            'name': 'Jaworzyna Śląska'
          },
          {
            'id_voivodeship': '02',
            'id_county': '19',
            'id_commune': '05',
            'name': 'Marcinowice'
          },
          {
            'id_voivodeship': '02',
            'id_county': '19',
            'id_commune': '06',
            'name': 'Strzegom'
          },
          {
            'id_voivodeship': '02',
            'id_county': '19',
            'id_commune': '08',
            'name': 'Żarów'
          }
        ]
      },
      {
        'id_voivodeship': '02',
        'id_county': '20',
        'name': 'trzebnicki',
        'communes': [
          {
            'id_voivodeship': '02',
            'id_county': '20',
            'id_commune': '01',
            'name': 'Oborniki Śląskie'
          },
          {
            'id_voivodeship': '02',
            'id_county': '20',
            'id_commune': '02',
            'name': 'Prusice'
          },
          {
            'id_voivodeship': '02',
            'id_county': '20',
            'id_commune': '03',
            'name': 'Trzebnica'
          },
          {
            'id_voivodeship': '02',
            'id_county': '20',
            'id_commune': '04',
            'name': 'Wisznia Mała'
          },
          {
            'id_voivodeship': '02',
            'id_county': '20',
            'id_commune': '05',
            'name': 'Zawonia'
          },
          {
            'id_voivodeship': '02',
            'id_county': '20',
            'id_commune': '06',
            'name': 'Żmigród'
          }
        ]
      },
      {
        'id_voivodeship': '02',
        'id_county': '21',
        'name': 'wałbrzyski',
        'communes': [
          {
            'id_voivodeship': '02',
            'id_county': '21',
            'id_commune': '01',
            'name': 'Boguszów-Gorce'
          },
          {
            'id_voivodeship': '02',
            'id_county': '21',
            'id_commune': '02',
            'name': 'Jedlina-Zdrój'
          },
          {
            'id_voivodeship': '02',
            'id_county': '21',
            'id_commune': '03',
            'name': 'Szczawno-Zdrój'
          },
          {
            'id_voivodeship': '02',
            'id_county': '21',
            'id_commune': '04',
            'name': 'Czarny Bór'
          },
          {
            'id_voivodeship': '02',
            'id_county': '21',
            'id_commune': '05',
            'name': 'Głuszyca'
          },
          {
            'id_voivodeship': '02',
            'id_county': '21',
            'id_commune': '06',
            'name': 'Mieroszów'
          },
          {
            'id_voivodeship': '02',
            'id_county': '21',
            'id_commune': '07',
            'name': 'Stare Bogaczowice'
          },
          {
            'id_voivodeship': '02',
            'id_county': '21',
            'id_commune': '08',
            'name': 'Walim'
          }
        ]
      },
      {
        'id_voivodeship': '02',
        'id_county': '22',
        'name': 'wołowski',
        'communes': [
          {
            'id_voivodeship': '02',
            'id_county': '22',
            'id_commune': '01',
            'name': 'Brzeg Dolny'
          },
          {
            'id_voivodeship': '02',
            'id_county': '22',
            'id_commune': '02',
            'name': 'Wińsko'
          },
          {
            'id_voivodeship': '02',
            'id_county': '22',
            'id_commune': '03',
            'name': 'Wołów'
          }
        ]
      },
      {
        'id_voivodeship': '02',
        'id_county': '23',
        'name': 'wrocławski',
        'communes': [
          {
            'id_voivodeship': '02',
            'id_county': '23',
            'id_commune': '01',
            'name': 'Czernica'
          },
          {
            'id_voivodeship': '02',
            'id_county': '23',
            'id_commune': '02',
            'name': 'Długołęka'
          },
          {
            'id_voivodeship': '02',
            'id_county': '23',
            'id_commune': '03',
            'name': 'Jordanów Śląski'
          },
          {
            'id_voivodeship': '02',
            'id_county': '23',
            'id_commune': '04',
            'name': 'Kąty Wrocławskie'
          },
          {
            'id_voivodeship': '02',
            'id_county': '23',
            'id_commune': '05',
            'name': 'Kobierzyce'
          },
          {
            'id_voivodeship': '02',
            'id_county': '23',
            'id_commune': '06',
            'name': 'Mietków'
          },
          {
            'id_voivodeship': '02',
            'id_county': '23',
            'id_commune': '07',
            'name': 'Sobótka'
          },
          {
            'id_voivodeship': '02',
            'id_county': '23',
            'id_commune': '08',
            'name': 'Siechnice'
          },
          {
            'id_voivodeship': '02',
            'id_county': '23',
            'id_commune': '09',
            'name': 'Żórawina'
          }
        ]
      },
      {
        'id_voivodeship': '02',
        'id_county': '24',
        'name': 'ząbkowicki',
        'communes': [
          {
            'id_voivodeship': '02',
            'id_county': '24',
            'id_commune': '01',
            'name': 'Bardo'
          },
          {
            'id_voivodeship': '02',
            'id_county': '24',
            'id_commune': '02',
            'name': 'Ciepłowody'
          },
          {
            'id_voivodeship': '02',
            'id_county': '24',
            'id_commune': '03',
            'name': 'Kamieniec Ząbkowicki'
          },
          {
            'id_voivodeship': '02',
            'id_county': '24',
            'id_commune': '04',
            'name': 'Stoszowice'
          },
          {
            'id_voivodeship': '02',
            'id_county': '24',
            'id_commune': '05',
            'name': 'Ząbkowice Śląskie'
          },
          {
            'id_voivodeship': '02',
            'id_county': '24',
            'id_commune': '06',
            'name': 'Ziębice'
          },
          {
            'id_voivodeship': '02',
            'id_county': '24',
            'id_commune': '07',
            'name': 'Złoty Stok'
          }
        ]
      },
      {
        'id_voivodeship': '02',
        'id_county': '25',
        'name': 'zgorzelecki',
        'communes': [
          {
            'id_voivodeship': '02',
            'id_county': '25',
            'id_commune': '01',
            'name': 'Zawidów'
          },
          {
            'id_voivodeship': '02',
            'id_county': '25',
            'id_commune': '02',
            'name': 'Zgorzelec'
          },
          {
            'id_voivodeship': '02',
            'id_county': '25',
            'id_commune': '03',
            'name': 'Bogatynia'
          },
          {
            'id_voivodeship': '02',
            'id_county': '25',
            'id_commune': '04',
            'name': 'Pieńsk'
          },
          {
            'id_voivodeship': '02',
            'id_county': '25',
            'id_commune': '05',
            'name': 'Sulików'
          },
          {
            'id_voivodeship': '02',
            'id_county': '25',
            'id_commune': '06',
            'name': 'Węgliniec'
          }
        ]
      },
      {
        'id_voivodeship': '02',
        'id_county': '26',
        'name': 'złotoryjski',
        'communes': [
          {
            'id_voivodeship': '02',
            'id_county': '26',
            'id_commune': '01',
            'name': 'Wojcieszów'
          },
          {
            'id_voivodeship': '02',
            'id_county': '26',
            'id_commune': '02',
            'name': 'Złotoryja'
          },
          {
            'id_voivodeship': '02',
            'id_county': '26',
            'id_commune': '03',
            'name': 'Pielgrzymka'
          },
          {
            'id_voivodeship': '02',
            'id_county': '26',
            'id_commune': '04',
            'name': 'Świerzawa'
          },
          {
            'id_voivodeship': '02',
            'id_county': '26',
            'id_commune': '05',
            'name': 'Zagrodno'
          }
        ]
      },
      {
        'id_voivodeship': '02',
        'id_county': '61',
        'name': 'Jelenia Góra',
        'communes': [
          {
            'id_voivodeship': '02',
            'id_county': '61',
            'id_commune': '01',
            'name': 'Jelenia Góra'
          }
        ]
      },
      {
        'id_voivodeship': '02',
        'id_county': '62',
        'name': 'Legnica',
        'communes': [
          {
            'id_voivodeship': '02',
            'id_county': '62',
            'id_commune': '01',
            'name': 'Legnica'
          }
        ]
      },
      {
        'id_voivodeship': '02',
        'id_county': '64',
        'name': 'Wrocław',
        'communes': [
          {
            'id_voivodeship': '02',
            'id_county': '64',
            'id_commune': '01',
            'name': 'Wrocław'
          },
        ]
      },
      {
        'id_voivodeship': '02',
        'id_county': '65',
        'name': 'Wałbrzych',
        'communes': [
          {
            'id_voivodeship': '02',
            'id_county': '65',
            'id_commune': '01',
            'name': 'Wałbrzych'
          }
        ]
      }
    ]
  },
  {
    'id_voivodeship': '04',
    'name': 'KUJAWSKO-POMORSKIE',
    'counties': [
      {
        'id_voivodeship': '04',
        'id_county': '01',
        'name': 'aleksandrowski',
        'communes': [
          {
            'id_voivodeship': '04',
            'id_county': '01',
            'id_commune': '01',
            'name': 'Aleksandrów Kujawski'
          },
          {
            'id_voivodeship': '04',
            'id_county': '01',
            'id_commune': '02',
            'name': 'Ciechocinek'
          },
          {
            'id_voivodeship': '04',
            'id_county': '01',
            'id_commune': '03',
            'name': 'Nieszawa'
          },
          {
            'id_voivodeship': '04',
            'id_county': '01',
            'id_commune': '05',
            'name': 'Bądkowo'
          },
          {
            'id_voivodeship': '04',
            'id_county': '01',
            'id_commune': '06',
            'name': 'Koneck'
          },
          {
            'id_voivodeship': '04',
            'id_county': '01',
            'id_commune': '07',
            'name': 'Raciążek'
          },
          {
            'id_voivodeship': '04',
            'id_county': '01',
            'id_commune': '08',
            'name': 'Waganiec'
          },
          {
            'id_voivodeship': '04',
            'id_county': '01',
            'id_commune': '09',
            'name': 'Zakrzewo'
          }
        ]
      },
      {
        'id_voivodeship': '04',
        'id_county': '02',
        'name': 'brodnicki',
        'communes': [
          {
            'id_voivodeship': '04',
            'id_county': '02',
            'id_commune': '01',
            'name': 'Brodnica'
          },
          {
            'id_voivodeship': '04',
            'id_county': '02',
            'id_commune': '02',
            'name': 'Bobrowo'
          },
          {
            'id_voivodeship': '04',
            'id_county': '02',
            'id_commune': '04',
            'name': 'Brzozie'
          },
          {
            'id_voivodeship': '04',
            'id_county': '02',
            'id_commune': '05',
            'name': 'Górzno'
          },
          {
            'id_voivodeship': '04',
            'id_county': '02',
            'id_commune': '06',
            'name': 'Bartniczka'
          },
          {
            'id_voivodeship': '04',
            'id_county': '02',
            'id_commune': '07',
            'name': 'Jabłonowo Pomorskie'
          },
          {
            'id_voivodeship': '04',
            'id_county': '02',
            'id_commune': '08',
            'name': 'Osiek'
          },
          {
            'id_voivodeship': '04',
            'id_county': '02',
            'id_commune': '09',
            'name': 'Świedziebnia'
          },
          {
            'id_voivodeship': '04',
            'id_county': '02',
            'id_commune': '10',
            'name': 'Zbiczno'
          }
        ]
      },
      {
        'id_voivodeship': '04',
        'id_county': '03',
        'name': 'bydgoski',
        'communes': [
          {
            'id_voivodeship': '04',
            'id_county': '03',
            'id_commune': '01',
            'name': 'Białe Błota'
          },
          {
            'id_voivodeship': '04',
            'id_county': '03',
            'id_commune': '02',
            'name': 'Dąbrowa Chełmińska'
          },
          {
            'id_voivodeship': '04',
            'id_county': '03',
            'id_commune': '03',
            'name': 'Dobrcz'
          },
          {
            'id_voivodeship': '04',
            'id_county': '03',
            'id_commune': '04',
            'name': 'Koronowo'
          },
          {
            'id_voivodeship': '04',
            'id_county': '03',
            'id_commune': '05',
            'name': 'Nowa Wieś Wielka'
          },
          {
            'id_voivodeship': '04',
            'id_county': '03',
            'id_commune': '06',
            'name': 'Osielsko'
          },
          {
            'id_voivodeship': '04',
            'id_county': '03',
            'id_commune': '07',
            'name': 'Sicienko'
          },
          {
            'id_voivodeship': '04',
            'id_county': '03',
            'id_commune': '08',
            'name': 'Solec Kujawski'
          }
        ]
      },
      {
        'id_voivodeship': '04',
        'id_county': '04',
        'name': 'chełmiński',
        'communes': [
          {
            'id_voivodeship': '04',
            'id_county': '04',
            'id_commune': '01',
            'name': 'Chełmno'
          },
          {
            'id_voivodeship': '04',
            'id_county': '04',
            'id_commune': '03',
            'name': 'Kijewo Królewskie'
          },
          {
            'id_voivodeship': '04',
            'id_county': '04',
            'id_commune': '04',
            'name': 'Lisewo'
          },
          {
            'id_voivodeship': '04',
            'id_county': '04',
            'id_commune': '05',
            'name': 'Papowo Biskupie'
          },
          {
            'id_voivodeship': '04',
            'id_county': '04',
            'id_commune': '06',
            'name': 'Stolno'
          },
          {
            'id_voivodeship': '04',
            'id_county': '04',
            'id_commune': '07',
            'name': 'Unisław'
          }
        ]
      },
      {
        'id_voivodeship': '04',
        'id_county': '05',
        'name': 'golubsko-dobrzyński',
        'communes': [
          {
            'id_voivodeship': '04',
            'id_county': '05',
            'id_commune': '01',
            'name': 'Golub-Dobrzyń'
          },
          {
            'id_voivodeship': '04',
            'id_county': '05',
            'id_commune': '02',
            'name': 'Ciechocin'
          },
          {
            'id_voivodeship': '04',
            'id_county': '05',
            'id_commune': '04',
            'name': 'Kowalewo Pomorskie'
          },
          {
            'id_voivodeship': '04',
            'id_county': '05',
            'id_commune': '05',
            'name': 'Radomin'
          },
          {
            'id_voivodeship': '04',
            'id_county': '05',
            'id_commune': '06',
            'name': 'Zbójno'
          }
        ]
      },
      {
        'id_voivodeship': '04',
        'id_county': '06',
        'name': 'grudziądzki',
        'communes': [
          {
            'id_voivodeship': '04',
            'id_county': '06',
            'id_commune': '01',
            'name': 'Grudziądz'
          },
          {
            'id_voivodeship': '04',
            'id_county': '06',
            'id_commune': '02',
            'name': 'Gruta'
          },
          {
            'id_voivodeship': '04',
            'id_county': '06',
            'id_commune': '03',
            'name': 'Łasin'
          },
          {
            'id_voivodeship': '04',
            'id_county': '06',
            'id_commune': '04',
            'name': 'Radzyń Chełmiński'
          },
          {
            'id_voivodeship': '04',
            'id_county': '06',
            'id_commune': '05',
            'name': 'Rogóźno'
          },
          {
            'id_voivodeship': '04',
            'id_county': '06',
            'id_commune': '06',
            'name': 'Świecie nad Osą'
          }
        ]
      },
      {
        'id_voivodeship': '04',
        'id_county': '07',
        'name': 'inowrocławski',
        'communes': [
          {
            'id_voivodeship': '04',
            'id_county': '07',
            'id_commune': '01',
            'name': 'Inowrocław'
          },
          {
            'id_voivodeship': '04',
            'id_county': '07',
            'id_commune': '02',
            'name': 'Dąbrowa Biskupia'
          },
          {
            'id_voivodeship': '04',
            'id_county': '07',
            'id_commune': '03',
            'name': 'Gniewkowo'
          },
          {
            'id_voivodeship': '04',
            'id_county': '07',
            'id_commune': '05',
            'name': 'Janikowo'
          },
          {
            'id_voivodeship': '04',
            'id_county': '07',
            'id_commune': '06',
            'name': 'Kruszwica'
          },
          {
            'id_voivodeship': '04',
            'id_county': '07',
            'id_commune': '07',
            'name': 'Pakość'
          },
          {
            'id_voivodeship': '04',
            'id_county': '07',
            'id_commune': '08',
            'name': 'Rojewo'
          },
          {
            'id_voivodeship': '04',
            'id_county': '07',
            'id_commune': '09',
            'name': 'Złotniki Kujawskie'
          }
        ]
      },
      {
        'id_voivodeship': '04',
        'id_county': '08',
        'name': 'lipnowski',
        'communes': [
          {
            'id_voivodeship': '04',
            'id_county': '08',
            'id_commune': '01',
            'name': 'Lipno'
          },
          {
            'id_voivodeship': '04',
            'id_county': '08',
            'id_commune': '02',
            'name': 'Bobrowniki'
          },
          {
            'id_voivodeship': '04',
            'id_county': '08',
            'id_commune': '03',
            'name': 'Chrostkowo'
          },
          {
            'id_voivodeship': '04',
            'id_county': '08',
            'id_commune': '04',
            'name': 'Dobrzyń nad Wisłą'
          },
          {
            'id_voivodeship': '04',
            'id_county': '08',
            'id_commune': '05',
            'name': 'Kikół'
          },
          {
            'id_voivodeship': '04',
            'id_county': '08',
            'id_commune': '07',
            'name': 'Skępe'
          },
          {
            'id_voivodeship': '04',
            'id_county': '08',
            'id_commune': '08',
            'name': 'Tłuchowo'
          },
          {
            'id_voivodeship': '04',
            'id_county': '08',
            'id_commune': '09',
            'name': 'Wielgie'
          }
        ]
      },
      {
        'id_voivodeship': '04',
        'id_county': '09',
        'name': 'mogileński',
        'communes': [
          {
            'id_voivodeship': '04',
            'id_county': '09',
            'id_commune': '01',
            'name': 'Dąbrowa'
          },
          {
            'id_voivodeship': '04',
            'id_county': '09',
            'id_commune': '02',
            'name': 'Jeziora Wielkie'
          },
          {
            'id_voivodeship': '04',
            'id_county': '09',
            'id_commune': '03',
            'name': 'Mogilno'
          },
          {
            'id_voivodeship': '04',
            'id_county': '09',
            'id_commune': '04',
            'name': 'Strzelno'
          }
        ]
      },
      {
        'id_voivodeship': '04',
        'id_county': '10',
        'name': 'nakielski',
        'communes': [
          {
            'id_voivodeship': '04',
            'id_county': '10',
            'id_commune': '01',
            'name': 'Kcynia'
          },
          {
            'id_voivodeship': '04',
            'id_county': '10',
            'id_commune': '02',
            'name': 'Mrocza'
          },
          {
            'id_voivodeship': '04',
            'id_county': '10',
            'id_commune': '03',
            'name': 'Nakło nad Notecią'
          },
          {
            'id_voivodeship': '04',
            'id_county': '10',
            'id_commune': '04',
            'name': 'Sadki'
          },
          {
            'id_voivodeship': '04',
            'id_county': '10',
            'id_commune': '05',
            'name': 'Szubin'
          }
        ]
      },
      {
        'id_voivodeship': '04',
        'id_county': '11',
        'name': 'radziejowski',
        'communes': [
          {
            'id_voivodeship': '04',
            'id_county': '11',
            'id_commune': '01',
            'name': 'Radziejów'
          },
          {
            'id_voivodeship': '04',
            'id_county': '11',
            'id_commune': '02',
            'name': 'Bytoń'
          },
          {
            'id_voivodeship': '04',
            'id_county': '11',
            'id_commune': '03',
            'name': 'Dobre'
          },
          {
            'id_voivodeship': '04',
            'id_county': '11',
            'id_commune': '04',
            'name': 'Osięciny'
          },
          {
            'id_voivodeship': '04',
            'id_county': '11',
            'id_commune': '05',
            'name': 'Piotrków Kujawski'
          },
          {
            'id_voivodeship': '04',
            'id_county': '11',
            'id_commune': '07',
            'name': 'Topólka'
          }
        ]
      },
      {
        'id_voivodeship': '04',
        'id_county': '12',
        'name': 'rypiński',
        'communes': [
          {
            'id_voivodeship': '04',
            'id_county': '12',
            'id_commune': '01',
            'name': 'Rypin'
          },
          {
            'id_voivodeship': '04',
            'id_county': '12',
            'id_commune': '02',
            'name': 'Brzuze'
          },
          {
            'id_voivodeship': '04',
            'id_county': '12',
            'id_commune': '03',
            'name': 'Rogowo'
          },
          {
            'id_voivodeship': '04',
            'id_county': '12',
            'id_commune': '05',
            'name': 'Skrwilno'
          },
          {
            'id_voivodeship': '04',
            'id_county': '12',
            'id_commune': '06',
            'name': 'Wąpielsk'
          }
        ]
      },
      {
        'id_voivodeship': '04',
        'id_county': '13',
        'name': 'sępoleński',
        'communes': [
          {
            'id_voivodeship': '04',
            'id_county': '13',
            'id_commune': '01',
            'name': 'Kamień Krajeński'
          },
          {
            'id_voivodeship': '04',
            'id_county': '13',
            'id_commune': '02',
            'name': 'Sępólno Krajeńskie'
          },
          {
            'id_voivodeship': '04',
            'id_county': '13',
            'id_commune': '03',
            'name': 'Sośno'
          },
          {
            'id_voivodeship': '04',
            'id_county': '13',
            'id_commune': '04',
            'name': 'Więcbork'
          }
        ]
      },
      {
        'id_voivodeship': '04',
        'id_county': '14',
        'name': 'świecki',
        'communes': [
          {
            'id_voivodeship': '04',
            'id_county': '14',
            'id_commune': '01',
            'name': 'Bukowiec'
          },
          {
            'id_voivodeship': '04',
            'id_county': '14',
            'id_commune': '02',
            'name': 'Dragacz'
          },
          {
            'id_voivodeship': '04',
            'id_county': '14',
            'id_commune': '03',
            'name': 'Drzycim'
          },
          {
            'id_voivodeship': '04',
            'id_county': '14',
            'id_commune': '04',
            'name': 'Jeżewo'
          },
          {
            'id_voivodeship': '04',
            'id_county': '14',
            'id_commune': '05',
            'name': 'Lniano'
          },
          {
            'id_voivodeship': '04',
            'id_county': '14',
            'id_commune': '06',
            'name': 'Nowe'
          },
          {
            'id_voivodeship': '04',
            'id_county': '14',
            'id_commune': '07',
            'name': 'Osie'
          },
          {
            'id_voivodeship': '04',
            'id_county': '14',
            'id_commune': '08',
            'name': 'Pruszcz'
          },
          {
            'id_voivodeship': '04',
            'id_county': '14',
            'id_commune': '09',
            'name': 'Świecie'
          },
          {
            'id_voivodeship': '04',
            'id_county': '14',
            'id_commune': '10',
            'name': 'Świekatowo'
          },
          {
            'id_voivodeship': '04',
            'id_county': '14',
            'id_commune': '11',
            'name': 'Warlubie'
          }
        ]
      },
      {
        'id_voivodeship': '04',
        'id_county': '15',
        'name': 'toruński',
        'communes': [
          {
            'id_voivodeship': '04',
            'id_county': '15',
            'id_commune': '01',
            'name': 'Chełmża'
          },
          {
            'id_voivodeship': '04',
            'id_county': '15',
            'id_commune': '03',
            'name': 'Czernikowo'
          },
          {
            'id_voivodeship': '04',
            'id_county': '15',
            'id_commune': '04',
            'name': 'Lubicz'
          },
          {
            'id_voivodeship': '04',
            'id_county': '15',
            'id_commune': '05',
            'name': 'Łubianka'
          },
          {
            'id_voivodeship': '04',
            'id_county': '15',
            'id_commune': '06',
            'name': 'Łysomice'
          },
          {
            'id_voivodeship': '04',
            'id_county': '15',
            'id_commune': '07',
            'name': 'Obrowo'
          },
          {
            'id_voivodeship': '04',
            'id_county': '15',
            'id_commune': '08',
            'name': 'Wielka Nieszawka'
          },
          {
            'id_voivodeship': '04',
            'id_county': '15',
            'id_commune': '09',
            'name': 'Zławieś Wielka'
          }
        ]
      },
      {
        'id_voivodeship': '04',
        'id_county': '16',
        'name': 'tucholski',
        'communes': [
          {
            'id_voivodeship': '04',
            'id_county': '16',
            'id_commune': '01',
            'name': 'Cekcyn'
          },
          {
            'id_voivodeship': '04',
            'id_county': '16',
            'id_commune': '02',
            'name': 'Gostycyn'
          },
          {
            'id_voivodeship': '04',
            'id_county': '16',
            'id_commune': '03',
            'name': 'Kęsowo'
          },
          {
            'id_voivodeship': '04',
            'id_county': '16',
            'id_commune': '04',
            'name': 'Lubiewo'
          },
          {
            'id_voivodeship': '04',
            'id_county': '16',
            'id_commune': '05',
            'name': 'Śliwice'
          },
          {
            'id_voivodeship': '04',
            'id_county': '16',
            'id_commune': '06',
            'name': 'Tuchola'
          }
        ]
      },
      {
        'id_voivodeship': '04',
        'id_county': '17',
        'name': 'wąbrzeski',
        'communes': [
          {
            'id_voivodeship': '04',
            'id_county': '17',
            'id_commune': '01',
            'name': 'Wąbrzeźno'
          },
          {
            'id_voivodeship': '04',
            'id_county': '17',
            'id_commune': '02',
            'name': 'Dębowa Łąka'
          },
          {
            'id_voivodeship': '04',
            'id_county': '17',
            'id_commune': '03',
            'name': 'Książki'
          },
          {
            'id_voivodeship': '04',
            'id_county': '17',
            'id_commune': '04',
            'name': 'Płużnica'
          },
          {
            'id_voivodeship': '04',
            'id_county': '17',
            'id_commune': '05',
            'name': 'Ryńsk'
          }
        ]
      },
      {
        'id_voivodeship': '04',
        'id_county': '18',
        'name': 'włocławski',
        'communes': [
          {
            'id_voivodeship': '04',
            'id_county': '18',
            'id_commune': '01',
            'name': 'Kowal'
          },
          {
            'id_voivodeship': '04',
            'id_county': '18',
            'id_commune': '02',
            'name': 'Baruchowo'
          },
          {
            'id_voivodeship': '04',
            'id_county': '18',
            'id_commune': '03',
            'name': 'Boniewo'
          },
          {
            'id_voivodeship': '04',
            'id_county': '18',
            'id_commune': '04',
            'name': 'Brześć Kujawski'
          },
          {
            'id_voivodeship': '04',
            'id_county': '18',
            'id_commune': '05',
            'name': 'Choceń'
          },
          {
            'id_voivodeship': '04',
            'id_county': '18',
            'id_commune': '06',
            'name': 'Chodecz'
          },
          {
            'id_voivodeship': '04',
            'id_county': '18',
            'id_commune': '07',
            'name': 'Fabianki'
          },
          {
            'id_voivodeship': '04',
            'id_county': '18',
            'id_commune': '08',
            'name': 'Izbica Kujawska'
          },
          {
            'id_voivodeship': '04',
            'id_county': '18',
            'id_commune': '10',
            'name': 'Lubanie'
          },
          {
            'id_voivodeship': '04',
            'id_county': '18',
            'id_commune': '11',
            'name': 'Lubień Kujawski'
          },
          {
            'id_voivodeship': '04',
            'id_county': '18',
            'id_commune': '12',
            'name': 'Lubraniec'
          },
          {
            'id_voivodeship': '04',
            'id_county': '18',
            'id_commune': '13',
            'name': 'Włocławek'
          }
        ]
      },
      {
        'id_voivodeship': '04',
        'id_county': '19',
        'name': 'żniński',
        'communes': [
          {
            'id_voivodeship': '04',
            'id_county': '19',
            'id_commune': '01',
            'name': 'Barcin'
          },
          {
            'id_voivodeship': '04',
            'id_county': '19',
            'id_commune': '02',
            'name': 'Gąsawa'
          },
          {
            'id_voivodeship': '04',
            'id_county': '19',
            'id_commune': '03',
            'name': 'Janowiec Wielkopolski'
          },
          {
            'id_voivodeship': '04',
            'id_county': '19',
            'id_commune': '04',
            'name': 'Łabiszyn'
          },
          {
            'id_voivodeship': '04',
            'id_county': '19',
            'id_commune': '05',
            'name': 'Rogowo'
          },
          {
            'id_voivodeship': '04',
            'id_county': '19',
            'id_commune': '06',
            'name': 'Żnin'
          }
        ]
      },
      {
        'id_voivodeship': '04',
        'id_county': '61',
        'name': 'Bydgoszcz',
        'communes': [
          {
            'id_voivodeship': '04',
            'id_county': '61',
            'id_commune': '01',
            'name': 'Bydgoszcz'
          }
        ]
      },
      {
        'id_voivodeship': '04',
        'id_county': '62',
        'name': 'Grudziądz',
        'communes': [
          {
            'id_voivodeship': '04',
            'id_county': '62',
            'id_commune': '01',
            'name': 'Grudziądz'
          }
        ]
      },
      {
        'id_voivodeship': '04',
        'id_county': '63',
        'name': 'Toruń',
        'communes': [
          {
            'id_voivodeship': '04',
            'id_county': '63',
            'id_commune': '01',
            'name': 'Toruń'
          }
        ]
      },
      {
        'id_voivodeship': '04',
        'id_county': '64',
        'name': 'Włocławek',
        'communes': [
          {
            'id_voivodeship': '04',
            'id_county': '64',
            'id_commune': '01',
            'name': 'Włocławek'
          }
        ]
      }
    ]
  },
  {
    'id_voivodeship': '06',
    'name': 'LUBELSKIE',
    'counties': [
      {
        'id_voivodeship': '06',
        'id_county': '01',
        'name': 'bialski',
        'communes': [
          {
            'id_voivodeship': '06',
            'id_county': '01',
            'id_commune': '01',
            'name': 'Międzyrzec Podlaski'
          },
          {
            'id_voivodeship': '06',
            'id_county': '01',
            'id_commune': '02',
            'name': 'Terespol'
          },
          {
            'id_voivodeship': '06',
            'id_county': '01',
            'id_commune': '03',
            'name': 'Biała Podlaska'
          },
          {
            'id_voivodeship': '06',
            'id_county': '01',
            'id_commune': '04',
            'name': 'Drelów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '01',
            'id_commune': '05',
            'name': 'Janów Podlaski'
          },
          {
            'id_voivodeship': '06',
            'id_county': '01',
            'id_commune': '06',
            'name': 'Kodeń'
          },
          {
            'id_voivodeship': '06',
            'id_county': '01',
            'id_commune': '07',
            'name': 'Konstantynów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '01',
            'id_commune': '08',
            'name': 'Leśna Podlaska'
          },
          {
            'id_voivodeship': '06',
            'id_county': '01',
            'id_commune': '09',
            'name': 'Łomazy'
          },
          {
            'id_voivodeship': '06',
            'id_county': '01',
            'id_commune': '11',
            'name': 'Piszczac'
          },
          {
            'id_voivodeship': '06',
            'id_county': '01',
            'id_commune': '12',
            'name': 'Rokitno'
          },
          {
            'id_voivodeship': '06',
            'id_county': '01',
            'id_commune': '13',
            'name': 'Rossosz'
          },
          {
            'id_voivodeship': '06',
            'id_county': '01',
            'id_commune': '14',
            'name': 'Sławatycze'
          },
          {
            'id_voivodeship': '06',
            'id_county': '01',
            'id_commune': '15',
            'name': 'Sosnówka'
          },
          {
            'id_voivodeship': '06',
            'id_county': '01',
            'id_commune': '17',
            'name': 'Tuczna'
          },
          {
            'id_voivodeship': '06',
            'id_county': '01',
            'id_commune': '18',
            'name': 'Wisznice'
          },
          {
            'id_voivodeship': '06',
            'id_county': '01',
            'id_commune': '19',
            'name': 'Zalesie'
          }
        ]
      },
      {
        'id_voivodeship': '06',
        'id_county': '02',
        'name': 'biłgorajski',
        'communes': [
          {
            'id_voivodeship': '06',
            'id_county': '02',
            'id_commune': '01',
            'name': 'Biłgoraj'
          },
          {
            'id_voivodeship': '06',
            'id_county': '02',
            'id_commune': '02',
            'name': 'Aleksandrów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '02',
            'id_commune': '04',
            'name': 'Biszcza'
          },
          {
            'id_voivodeship': '06',
            'id_county': '02',
            'id_commune': '05',
            'name': 'Frampol'
          },
          {
            'id_voivodeship': '06',
            'id_county': '02',
            'id_commune': '06',
            'name': 'Goraj'
          },
          {
            'id_voivodeship': '06',
            'id_county': '02',
            'id_commune': '07',
            'name': 'Józefów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '02',
            'id_commune': '08',
            'name': 'Księżpol'
          },
          {
            'id_voivodeship': '06',
            'id_county': '02',
            'id_commune': '09',
            'name': 'Łukowa'
          },
          {
            'id_voivodeship': '06',
            'id_county': '02',
            'id_commune': '10',
            'name': 'Obsza'
          },
          {
            'id_voivodeship': '06',
            'id_county': '02',
            'id_commune': '11',
            'name': 'Potok Górny'
          },
          {
            'id_voivodeship': '06',
            'id_county': '02',
            'id_commune': '12',
            'name': 'Tarnogród'
          },
          {
            'id_voivodeship': '06',
            'id_county': '02',
            'id_commune': '13',
            'name': 'Tereszpol'
          },
          {
            'id_voivodeship': '06',
            'id_county': '02',
            'id_commune': '14',
            'name': 'Turobin'
          }
        ]
      },
      {
        'id_voivodeship': '06',
        'id_county': '03',
        'name': 'chełmski',
        'communes': [
          {
            'id_voivodeship': '06',
            'id_county': '03',
            'id_commune': '01',
            'name': 'Rejowiec Fabryczny'
          },
          {
            'id_voivodeship': '06',
            'id_county': '03',
            'id_commune': '02',
            'name': 'Białopole'
          },
          {
            'id_voivodeship': '06',
            'id_county': '03',
            'id_commune': '03',
            'name': 'Chełm'
          },
          {
            'id_voivodeship': '06',
            'id_county': '03',
            'id_commune': '04',
            'name': 'Dorohusk'
          },
          {
            'id_voivodeship': '06',
            'id_county': '03',
            'id_commune': '05',
            'name': 'Dubienka'
          },
          {
            'id_voivodeship': '06',
            'id_county': '03',
            'id_commune': '06',
            'name': 'Kamień'
          },
          {
            'id_voivodeship': '06',
            'id_county': '03',
            'id_commune': '07',
            'name': 'Leśniowice'
          },
          {
            'id_voivodeship': '06',
            'id_county': '03',
            'id_commune': '09',
            'name': 'Ruda-Huta'
          },
          {
            'id_voivodeship': '06',
            'id_county': '03',
            'id_commune': '10',
            'name': 'Sawin'
          },
          {
            'id_voivodeship': '06',
            'id_county': '03',
            'id_commune': '11',
            'name': 'Siedliszcze'
          },
          {
            'id_voivodeship': '06',
            'id_county': '03',
            'id_commune': '12',
            'name': 'Wierzbica'
          },
          {
            'id_voivodeship': '06',
            'id_county': '03',
            'id_commune': '13',
            'name': 'Wojsławice'
          },
          {
            'id_voivodeship': '06',
            'id_county': '03',
            'id_commune': '14',
            'name': 'Żmudź'
          },
          {
            'id_voivodeship': '06',
            'id_county': '03',
            'id_commune': '15',
            'name': 'Rejowiec'
          }
        ]
      },
      {
        'id_voivodeship': '06',
        'id_county': '04',
        'name': 'hrubieszowski',
        'communes': [
          {
            'id_voivodeship': '06',
            'id_county': '04',
            'id_commune': '01',
            'name': 'Hrubieszów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '04',
            'id_commune': '02',
            'name': 'Dołhobyczów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '04',
            'id_commune': '03',
            'name': 'Horodło'
          },
          {
            'id_voivodeship': '06',
            'id_county': '04',
            'id_commune': '05',
            'name': 'Mircze'
          },
          {
            'id_voivodeship': '06',
            'id_county': '04',
            'id_commune': '06',
            'name': 'Trzeszczany'
          },
          {
            'id_voivodeship': '06',
            'id_county': '04',
            'id_commune': '07',
            'name': 'Uchanie'
          },
          {
            'id_voivodeship': '06',
            'id_county': '04',
            'id_commune': '08',
            'name': 'Werbkowice'
          }
        ]
      },
      {
        'id_voivodeship': '06',
        'id_county': '05',
        'name': 'janowski',
        'communes': [
          {
            'id_voivodeship': '06',
            'id_county': '05',
            'id_commune': '01',
            'name': 'Batorz'
          },
          {
            'id_voivodeship': '06',
            'id_county': '05',
            'id_commune': '02',
            'name': 'Chrzanów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '05',
            'id_commune': '03',
            'name': 'Dzwola'
          },
          {
            'id_voivodeship': '06',
            'id_county': '05',
            'id_commune': '04',
            'name': 'Godziszów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '05',
            'id_commune': '05',
            'name': 'Janów Lubelski'
          },
          {
            'id_voivodeship': '06',
            'id_county': '05',
            'id_commune': '06',
            'name': 'Modliborzyce'
          },
          {
            'id_voivodeship': '06',
            'id_county': '05',
            'id_commune': '07',
            'name': 'Potok Wielki'
          }
        ]
      },
      {
        'id_voivodeship': '06',
        'id_county': '06',
        'name': 'krasnostawski',
        'communes': [
          {
            'id_voivodeship': '06',
            'id_county': '06',
            'id_commune': '01',
            'name': 'Krasnystaw'
          },
          {
            'id_voivodeship': '06',
            'id_county': '06',
            'id_commune': '02',
            'name': 'Fajsławice'
          },
          {
            'id_voivodeship': '06',
            'id_county': '06',
            'id_commune': '03',
            'name': 'Gorzków'
          },
          {
            'id_voivodeship': '06',
            'id_county': '06',
            'id_commune': '04',
            'name': 'Izbica'
          },
          {
            'id_voivodeship': '06',
            'id_county': '06',
            'id_commune': '06',
            'name': 'Kraśniczyn'
          },
          {
            'id_voivodeship': '06',
            'id_county': '06',
            'id_commune': '07',
            'name': 'Łopiennik Górny'
          },
          {
            'id_voivodeship': '06',
            'id_county': '06',
            'id_commune': '09',
            'name': 'Rudnik'
          },
          {
            'id_voivodeship': '06',
            'id_county': '06',
            'id_commune': '10',
            'name': 'Siennica Różana'
          },
          {
            'id_voivodeship': '06',
            'id_county': '06',
            'id_commune': '11',
            'name': 'Żółkiewka'
          }
        ]
      },
      {
        'id_voivodeship': '06',
        'id_county': '07',
        'name': 'kraśnicki',
        'communes': [
          {
            'id_voivodeship': '06',
            'id_county': '07',
            'id_commune': '01',
            'name': 'Kraśnik'
          },
          {
            'id_voivodeship': '06',
            'id_county': '07',
            'id_commune': '02',
            'name': 'Annopol'
          },
          {
            'id_voivodeship': '06',
            'id_county': '07',
            'id_commune': '03',
            'name': 'Dzierzkowice'
          },
          {
            'id_voivodeship': '06',
            'id_county': '07',
            'id_commune': '04',
            'name': 'Gościeradów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '07',
            'id_commune': '06',
            'name': 'Szastarka'
          },
          {
            'id_voivodeship': '06',
            'id_county': '07',
            'id_commune': '07',
            'name': 'Trzydnik Duży'
          },
          {
            'id_voivodeship': '06',
            'id_county': '07',
            'id_commune': '08',
            'name': 'Urzędów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '07',
            'id_commune': '09',
            'name': 'Wilkołaz'
          },
          {
            'id_voivodeship': '06',
            'id_county': '07',
            'id_commune': '10',
            'name': 'Zakrzówek'
          }
        ]
      },
      {
        'id_voivodeship': '06',
        'id_county': '08',
        'name': 'lubartowski',
        'communes': [
          {
            'id_voivodeship': '06',
            'id_county': '08',
            'id_commune': '01',
            'name': 'Lubartów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '08',
            'id_commune': '02',
            'name': 'Abramów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '08',
            'id_commune': '03',
            'name': 'Firlej'
          },
          {
            'id_voivodeship': '06',
            'id_county': '08',
            'id_commune': '04',
            'name': 'Jeziorzany'
          },
          {
            'id_voivodeship': '06',
            'id_county': '08',
            'id_commune': '05',
            'name': 'Kamionka'
          },
          {
            'id_voivodeship': '06',
            'id_county': '08',
            'id_commune': '06',
            'name': 'Kock'
          },
          {
            'id_voivodeship': '06',
            'id_county': '08',
            'id_commune': '08',
            'name': 'Michów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '08',
            'id_commune': '09',
            'name': 'Niedźwiada'
          },
          {
            'id_voivodeship': '06',
            'id_county': '08',
            'id_commune': '10',
            'name': 'Ostrów Lubelski'
          },
          {
            'id_voivodeship': '06',
            'id_county': '08',
            'id_commune': '11',
            'name': 'Ostrówek'
          },
          {
            'id_voivodeship': '06',
            'id_county': '08',
            'id_commune': '12',
            'name': 'Serniki'
          },
          {
            'id_voivodeship': '06',
            'id_county': '08',
            'id_commune': '13',
            'name': 'Uścimów'
          }
        ]
      },
      {
        'id_voivodeship': '06',
        'id_county': '09',
        'name': 'lubelski',
        'communes': [
          {
            'id_voivodeship': '06',
            'id_county': '09',
            'id_commune': '01',
            'name': 'Bełżyce'
          },
          {
            'id_voivodeship': '06',
            'id_county': '09',
            'id_commune': '02',
            'name': 'Borzechów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '09',
            'id_commune': '03',
            'name': 'Bychawa'
          },
          {
            'id_voivodeship': '06',
            'id_county': '09',
            'id_commune': '04',
            'name': 'Garbów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '09',
            'id_commune': '05',
            'name': 'Głusk'
          },
          {
            'id_voivodeship': '06',
            'id_county': '09',
            'id_commune': '06',
            'name': 'Jabłonna'
          },
          {
            'id_voivodeship': '06',
            'id_county': '09',
            'id_commune': '07',
            'name': 'Jastków'
          },
          {
            'id_voivodeship': '06',
            'id_county': '09',
            'id_commune': '08',
            'name': 'Konopnica'
          },
          {
            'id_voivodeship': '06',
            'id_county': '09',
            'id_commune': '09',
            'name': 'Krzczonów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '09',
            'id_commune': '10',
            'name': 'Niedrzwica Duża'
          },
          {
            'id_voivodeship': '06',
            'id_county': '09',
            'id_commune': '11',
            'name': 'Niemce'
          },
          {
            'id_voivodeship': '06',
            'id_county': '09',
            'id_commune': '12',
            'name': 'Strzyżewice'
          },
          {
            'id_voivodeship': '06',
            'id_county': '09',
            'id_commune': '13',
            'name': 'Wojciechów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '09',
            'id_commune': '14',
            'name': 'Wólka'
          },
          {
            'id_voivodeship': '06',
            'id_county': '09',
            'id_commune': '15',
            'name': 'Wysokie'
          },
          {
            'id_voivodeship': '06',
            'id_county': '09',
            'id_commune': '16',
            'name': 'Zakrzew'
          }
        ]
      },
      {
        'id_voivodeship': '06',
        'id_county': '10',
        'name': 'łęczyński',
        'communes': [
          {
            'id_voivodeship': '06',
            'id_county': '10',
            'id_commune': '01',
            'name': 'Cyców'
          },
          {
            'id_voivodeship': '06',
            'id_county': '10',
            'id_commune': '02',
            'name': 'Ludwin'
          },
          {
            'id_voivodeship': '06',
            'id_county': '10',
            'id_commune': '03',
            'name': 'Łęczna'
          },
          {
            'id_voivodeship': '06',
            'id_county': '10',
            'id_commune': '04',
            'name': 'Milejów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '10',
            'id_commune': '05',
            'name': 'Puchaczów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '10',
            'id_commune': '06',
            'name': 'Spiczyn'
          }
        ]
      },
      {
        'id_voivodeship': '06',
        'id_county': '11',
        'name': 'łukowski',
        'communes': [
          {
            'id_voivodeship': '06',
            'id_county': '11',
            'id_commune': '01',
            'name': 'Łuków'
          },
          {
            'id_voivodeship': '06',
            'id_county': '11',
            'id_commune': '02',
            'name': 'Stoczek Łukowski'
          },
          {
            'id_voivodeship': '06',
            'id_county': '11',
            'id_commune': '03',
            'name': 'Adamów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '11',
            'id_commune': '04',
            'name': 'Krzywda'
          },
          {
            'id_voivodeship': '06',
            'id_county': '11',
            'id_commune': '06',
            'name': 'Serokomla'
          },
          {
            'id_voivodeship': '06',
            'id_county': '11',
            'id_commune': '07',
            'name': 'Stanin'
          },
          {
            'id_voivodeship': '06',
            'id_county': '11',
            'id_commune': '09',
            'name': 'Trzebieszów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '11',
            'id_commune': '10',
            'name': 'Wojcieszków'
          },
          {
            'id_voivodeship': '06',
            'id_county': '11',
            'id_commune': '11',
            'name': 'Wola Mysłowska'
          }
        ]
      },
      {
        'id_voivodeship': '06',
        'id_county': '12',
        'name': 'opolski',
        'communes': [
          {
            'id_voivodeship': '06',
            'id_county': '12',
            'id_commune': '01',
            'name': 'Chodel'
          },
          {
            'id_voivodeship': '06',
            'id_county': '12',
            'id_commune': '02',
            'name': 'Józefów nad Wisłą'
          },
          {
            'id_voivodeship': '06',
            'id_county': '12',
            'id_commune': '03',
            'name': 'Karczmiska'
          },
          {
            'id_voivodeship': '06',
            'id_county': '12',
            'id_commune': '04',
            'name': 'Łaziska'
          },
          {
            'id_voivodeship': '06',
            'id_county': '12',
            'id_commune': '05',
            'name': 'Opole Lubelskie'
          },
          {
            'id_voivodeship': '06',
            'id_county': '12',
            'id_commune': '06',
            'name': 'Poniatowa'
          },
          {
            'id_voivodeship': '06',
            'id_county': '12',
            'id_commune': '07',
            'name': 'Wilków'
          }
        ]
      },
      {
        'id_voivodeship': '06',
        'id_county': '13',
        'name': 'parczewski',
        'communes': [
          {
            'id_voivodeship': '06',
            'id_county': '13',
            'id_commune': '01',
            'name': 'Dębowa Kłoda'
          },
          {
            'id_voivodeship': '06',
            'id_county': '13',
            'id_commune': '02',
            'name': 'Jabłoń'
          },
          {
            'id_voivodeship': '06',
            'id_county': '13',
            'id_commune': '03',
            'name': 'Milanów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '13',
            'id_commune': '04',
            'name': 'Parczew'
          },
          {
            'id_voivodeship': '06',
            'id_county': '13',
            'id_commune': '05',
            'name': 'Podedwórze'
          },
          {
            'id_voivodeship': '06',
            'id_county': '13',
            'id_commune': '06',
            'name': 'Siemień'
          },
          {
            'id_voivodeship': '06',
            'id_county': '13',
            'id_commune': '07',
            'name': 'Sosnowica'
          }
        ]
      },
      {
        'id_voivodeship': '06',
        'id_county': '14',
        'name': 'puławski',
        'communes': [
          {
            'id_voivodeship': '06',
            'id_county': '14',
            'id_commune': '01',
            'name': 'Puławy'
          },
          {
            'id_voivodeship': '06',
            'id_county': '14',
            'id_commune': '02',
            'name': 'Baranów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '14',
            'id_commune': '03',
            'name': 'Janowiec'
          },
          {
            'id_voivodeship': '06',
            'id_county': '14',
            'id_commune': '04',
            'name': 'Kazimierz Dolny'
          },
          {
            'id_voivodeship': '06',
            'id_county': '14',
            'id_commune': '05',
            'name': 'Końskowola'
          },
          {
            'id_voivodeship': '06',
            'id_county': '14',
            'id_commune': '06',
            'name': 'Kurów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '14',
            'id_commune': '07',
            'name': 'Markuszów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '14',
            'id_commune': '08',
            'name': 'Nałęczów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '14',
            'id_commune': '10',
            'name': 'Wąwolnica'
          },
          {
            'id_voivodeship': '06',
            'id_county': '14',
            'id_commune': '11',
            'name': 'Żyrzyn'
          }
        ]
      },
      {
        'id_voivodeship': '06',
        'id_county': '15',
        'name': 'radzyński',
        'communes': [
          {
            'id_voivodeship': '06',
            'id_county': '15',
            'id_commune': '01',
            'name': 'Radzyń Podlaski'
          },
          {
            'id_voivodeship': '06',
            'id_county': '15',
            'id_commune': '02',
            'name': 'Borki'
          },
          {
            'id_voivodeship': '06',
            'id_county': '15',
            'id_commune': '03',
            'name': 'Czemierniki'
          },
          {
            'id_voivodeship': '06',
            'id_county': '15',
            'id_commune': '04',
            'name': 'Kąkolewnica'
          },
          {
            'id_voivodeship': '06',
            'id_county': '15',
            'id_commune': '05',
            'name': 'Komarówka Podlaska'
          },
          {
            'id_voivodeship': '06',
            'id_county': '15',
            'id_commune': '07',
            'name': 'Ulan-Majorat'
          },
          {
            'id_voivodeship': '06',
            'id_county': '15',
            'id_commune': '08',
            'name': 'Wohyń'
          }
        ]
      },
      {
        'id_voivodeship': '06',
        'id_county': '16',
        'name': 'rycki',
        'communes': [
          {
            'id_voivodeship': '06',
            'id_county': '16',
            'id_commune': '01',
            'name': 'Dęblin'
          },
          {
            'id_voivodeship': '06',
            'id_county': '16',
            'id_commune': '02',
            'name': 'Kłoczew'
          },
          {
            'id_voivodeship': '06',
            'id_county': '16',
            'id_commune': '03',
            'name': 'Nowodwór'
          },
          {
            'id_voivodeship': '06',
            'id_county': '16',
            'id_commune': '04',
            'name': 'Ryki'
          },
          {
            'id_voivodeship': '06',
            'id_county': '16',
            'id_commune': '05',
            'name': 'Stężyca'
          },
          {
            'id_voivodeship': '06',
            'id_county': '16',
            'id_commune': '06',
            'name': 'Ułęż'
          }
        ]
      },
      {
        'id_voivodeship': '06',
        'id_county': '17',
        'name': 'świdnicki',
        'communes': [
          {
            'id_voivodeship': '06',
            'id_county': '17',
            'id_commune': '01',
            'name': 'Świdnik'
          },
          {
            'id_voivodeship': '06',
            'id_county': '17',
            'id_commune': '02',
            'name': 'Mełgiew'
          },
          {
            'id_voivodeship': '06',
            'id_county': '17',
            'id_commune': '03',
            'name': 'Piaski'
          },
          {
            'id_voivodeship': '06',
            'id_county': '17',
            'id_commune': '04',
            'name': 'Rybczewice'
          },
          {
            'id_voivodeship': '06',
            'id_county': '17',
            'id_commune': '05',
            'name': 'Trawniki'
          }
        ]
      },
      {
        'id_voivodeship': '06',
        'id_county': '18',
        'name': 'tomaszowski',
        'communes': [
          {
            'id_voivodeship': '06',
            'id_county': '18',
            'id_commune': '01',
            'name': 'Tomaszów Lubelski'
          },
          {
            'id_voivodeship': '06',
            'id_county': '18',
            'id_commune': '02',
            'name': 'Bełżec'
          },
          {
            'id_voivodeship': '06',
            'id_county': '18',
            'id_commune': '03',
            'name': 'Jarczów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '18',
            'id_commune': '04',
            'name': 'Krynice'
          },
          {
            'id_voivodeship': '06',
            'id_county': '18',
            'id_commune': '05',
            'name': 'Lubycza Królewska'
          },
          {
            'id_voivodeship': '06',
            'id_county': '18',
            'id_commune': '06',
            'name': 'Łaszczów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '18',
            'id_commune': '07',
            'name': 'Rachanie'
          },
          {
            'id_voivodeship': '06',
            'id_county': '18',
            'id_commune': '08',
            'name': 'Susiec'
          },
          {
            'id_voivodeship': '06',
            'id_county': '18',
            'id_commune': '09',
            'name': 'Tarnawatka'
          },
          {
            'id_voivodeship': '06',
            'id_county': '18',
            'id_commune': '10',
            'name': 'Telatyn'
          },
          {
            'id_voivodeship': '06',
            'id_county': '18',
            'id_commune': '12',
            'name': 'Tyszowce'
          },
          {
            'id_voivodeship': '06',
            'id_county': '18',
            'id_commune': '13',
            'name': 'Ulhówek'
          }
        ]
      },
      {
        'id_voivodeship': '06',
        'id_county': '19',
        'name': 'włodawski',
        'communes': [
          {
            'id_voivodeship': '06',
            'id_county': '19',
            'id_commune': '01',
            'name': 'Włodawa'
          },
          {
            'id_voivodeship': '06',
            'id_county': '19',
            'id_commune': '02',
            'name': 'Hanna'
          },
          {
            'id_voivodeship': '06',
            'id_county': '19',
            'id_commune': '03',
            'name': 'Hańsk'
          },
          {
            'id_voivodeship': '06',
            'id_county': '19',
            'id_commune': '04',
            'name': 'Stary Brus'
          },
          {
            'id_voivodeship': '06',
            'id_county': '19',
            'id_commune': '05',
            'name': 'Urszulin'
          },
          {
            'id_voivodeship': '06',
            'id_county': '19',
            'id_commune': '07',
            'name': 'Wola Uhruska'
          },
          {
            'id_voivodeship': '06',
            'id_county': '19',
            'id_commune': '08',
            'name': 'Wyryki'
          }
        ]
      },
      {
        'id_voivodeship': '06',
        'id_county': '20',
        'name': 'zamojski',
        'communes': [
          {
            'id_voivodeship': '06',
            'id_county': '20',
            'id_commune': '01',
            'name': 'Adamów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '20',
            'id_commune': '02',
            'name': 'Grabowiec'
          },
          {
            'id_voivodeship': '06',
            'id_county': '20',
            'id_commune': '03',
            'name': 'Komarów-Osada'
          },
          {
            'id_voivodeship': '06',
            'id_county': '20',
            'id_commune': '04',
            'name': 'Krasnobród'
          },
          {
            'id_voivodeship': '06',
            'id_county': '20',
            'id_commune': '05',
            'name': 'Łabunie'
          },
          {
            'id_voivodeship': '06',
            'id_county': '20',
            'id_commune': '06',
            'name': 'Miączyn'
          },
          {
            'id_voivodeship': '06',
            'id_county': '20',
            'id_commune': '07',
            'name': 'Nielisz'
          },
          {
            'id_voivodeship': '06',
            'id_county': '20',
            'id_commune': '08',
            'name': 'Radecznica'
          },
          {
            'id_voivodeship': '06',
            'id_county': '20',
            'id_commune': '09',
            'name': 'Sitno'
          },
          {
            'id_voivodeship': '06',
            'id_county': '20',
            'id_commune': '10',
            'name': 'Skierbieszów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '20',
            'id_commune': '11',
            'name': 'Stary Zamość'
          },
          {
            'id_voivodeship': '06',
            'id_county': '20',
            'id_commune': '12',
            'name': 'Sułów'
          },
          {
            'id_voivodeship': '06',
            'id_county': '20',
            'id_commune': '13',
            'name': 'Szczebrzeszyn'
          },
          {
            'id_voivodeship': '06',
            'id_county': '20',
            'id_commune': '14',
            'name': 'Zamość'
          },
          {
            'id_voivodeship': '06',
            'id_county': '20',
            'id_commune': '15',
            'name': 'Zwierzyniec'
          }
        ]
      },
      {
        'id_voivodeship': '06',
        'id_county': '61',
        'name': 'Biała Podlaska',
        'communes': [
          {
            'id_voivodeship': '06',
            'id_county': '61',
            'id_commune': '01',
            'name': 'Biała Podlaska'
          }
        ]
      },
      {
        'id_voivodeship': '06',
        'id_county': '62',
        'name': 'Chełm',
        'communes': [
          {
            'id_voivodeship': '06',
            'id_county': '62',
            'id_commune': '01',
            'name': 'Chełm'
          }
        ]
      },
      {
        'id_voivodeship': '06',
        'id_county': '63',
        'name': 'Lublin',
        'communes': [
          {
            'id_voivodeship': '06',
            'id_county': '63',
            'id_commune': '01',
            'name': 'Lublin'
          }
        ]
      },
      {
        'id_voivodeship': '06',
        'id_county': '64',
        'name': 'Zamość',
        'communes': [
          {
            'id_voivodeship': '06',
            'id_county': '64',
            'id_commune': '01',
            'name': 'Zamość'
          }
        ]
      }
    ]
  },
  {
    'id_voivodeship': '08',
    'name': 'LUBUSKIE',
    'counties': [
      {
        'id_voivodeship': '08',
        'id_county': '01',
        'name': 'gorzowski',
        'communes': [
          {
            'id_voivodeship': '08',
            'id_county': '01',
            'id_commune': '01',
            'name': 'Kostrzyn nad Odrą'
          },
          {
            'id_voivodeship': '08',
            'id_county': '01',
            'id_commune': '02',
            'name': 'Bogdaniec'
          },
          {
            'id_voivodeship': '08',
            'id_county': '01',
            'id_commune': '03',
            'name': 'Deszczno'
          },
          {
            'id_voivodeship': '08',
            'id_county': '01',
            'id_commune': '04',
            'name': 'Kłodawa'
          },
          {
            'id_voivodeship': '08',
            'id_county': '01',
            'id_commune': '05',
            'name': 'Lubiszyn'
          },
          {
            'id_voivodeship': '08',
            'id_county': '01',
            'id_commune': '06',
            'name': 'Santok'
          },
          {
            'id_voivodeship': '08',
            'id_county': '01',
            'id_commune': '07',
            'name': 'Witnica'
          }
        ]
      },
      {
        'id_voivodeship': '08',
        'id_county': '02',
        'name': 'krośnieński',
        'communes': [
          {
            'id_voivodeship': '08',
            'id_county': '02',
            'id_commune': '01',
            'name': 'Gubin'
          },
          {
            'id_voivodeship': '08',
            'id_county': '02',
            'id_commune': '02',
            'name': 'Bobrowice'
          },
          {
            'id_voivodeship': '08',
            'id_county': '02',
            'id_commune': '03',
            'name': 'Bytnica'
          },
          {
            'id_voivodeship': '08',
            'id_county': '02',
            'id_commune': '04',
            'name': 'Dąbie'
          },
          {
            'id_voivodeship': '08',
            'id_county': '02',
            'id_commune': '06',
            'name': 'Krosno Odrzańskie'
          },
          {
            'id_voivodeship': '08',
            'id_county': '02',
            'id_commune': '07',
            'name': 'Maszewo'
          }
        ]
      },
      {
        'id_voivodeship': '08',
        'id_county': '03',
        'name': 'międzyrzecki',
        'communes': [
          {
            'id_voivodeship': '08',
            'id_county': '03',
            'id_commune': '01',
            'name': 'Bledzew'
          },
          {
            'id_voivodeship': '08',
            'id_county': '03',
            'id_commune': '02',
            'name': 'Międzyrzecz'
          },
          {
            'id_voivodeship': '08',
            'id_county': '03',
            'id_commune': '03',
            'name': 'Przytoczna'
          },
          {
            'id_voivodeship': '08',
            'id_county': '03',
            'id_commune': '04',
            'name': 'Pszczew'
          },
          {
            'id_voivodeship': '08',
            'id_county': '03',
            'id_commune': '05',
            'name': 'Skwierzyna'
          },
          {
            'id_voivodeship': '08',
            'id_county': '03',
            'id_commune': '06',
            'name': 'Trzciel'
          }
        ]
      },
      {
        'id_voivodeship': '08',
        'id_county': '04',
        'name': 'nowosolski',
        'communes': [
          {
            'id_voivodeship': '08',
            'id_county': '04',
            'id_commune': '01',
            'name': 'Nowa Sól'
          },
          {
            'id_voivodeship': '08',
            'id_county': '04',
            'id_commune': '02',
            'name': 'Bytom Odrzański'
          },
          {
            'id_voivodeship': '08',
            'id_county': '04',
            'id_commune': '03',
            'name': 'Kolsko'
          },
          {
            'id_voivodeship': '08',
            'id_county': '04',
            'id_commune': '04',
            'name': 'Kożuchów'
          },
          {
            'id_voivodeship': '08',
            'id_county': '04',
            'id_commune': '06',
            'name': 'Nowe Miasteczko'
          },
          {
            'id_voivodeship': '08',
            'id_county': '04',
            'id_commune': '07',
            'name': 'Otyń'
          },
          {
            'id_voivodeship': '08',
            'id_county': '04',
            'id_commune': '08',
            'name': 'Siedlisko'
          }
        ]
      },
      {
        'id_voivodeship': '08',
        'id_county': '05',
        'name': 'słubicki',
        'communes': [
          {
            'id_voivodeship': '08',
            'id_county': '05',
            'id_commune': '01',
            'name': 'Cybinka'
          },
          {
            'id_voivodeship': '08',
            'id_county': '05',
            'id_commune': '02',
            'name': 'Górzyca'
          },
          {
            'id_voivodeship': '08',
            'id_county': '05',
            'id_commune': '03',
            'name': 'Ośno Lubuskie'
          },
          {
            'id_voivodeship': '08',
            'id_county': '05',
            'id_commune': '04',
            'name': 'Rzepin'
          },
          {
            'id_voivodeship': '08',
            'id_county': '05',
            'id_commune': '05',
            'name': 'Słubice'
          }
        ]
      },
      {
        'id_voivodeship': '08',
        'id_county': '06',
        'name': 'strzelecko-drezdenecki',
        'communes': [
          {
            'id_voivodeship': '08',
            'id_county': '06',
            'id_commune': '01',
            'name': 'Dobiegniew'
          },
          {
            'id_voivodeship': '08',
            'id_county': '06',
            'id_commune': '02',
            'name': 'Drezdenko'
          },
          {
            'id_voivodeship': '08',
            'id_county': '06',
            'id_commune': '03',
            'name': 'Stare Kurowo'
          },
          {
            'id_voivodeship': '08',
            'id_county': '06',
            'id_commune': '04',
            'name': 'Strzelce Krajeńskie'
          },
          {
            'id_voivodeship': '08',
            'id_county': '06',
            'id_commune': '05',
            'name': 'Zwierzyn'
          }
        ]
      },
      {
        'id_voivodeship': '08',
        'id_county': '07',
        'name': 'sulęciński',
        'communes': [
          {
            'id_voivodeship': '08',
            'id_county': '07',
            'id_commune': '01',
            'name': 'Krzeszyce'
          },
          {
            'id_voivodeship': '08',
            'id_county': '07',
            'id_commune': '02',
            'name': 'Lubniewice'
          },
          {
            'id_voivodeship': '08',
            'id_county': '07',
            'id_commune': '03',
            'name': 'Słońsk'
          },
          {
            'id_voivodeship': '08',
            'id_county': '07',
            'id_commune': '04',
            'name': 'Sulęcin'
          },
          {
            'id_voivodeship': '08',
            'id_county': '07',
            'id_commune': '05',
            'name': 'Torzym'
          }
        ]
      },
      {
        'id_voivodeship': '08',
        'id_county': '08',
        'name': 'świebodziński',
        'communes': [
          {
            'id_voivodeship': '08',
            'id_county': '08',
            'id_commune': '01',
            'name': 'Lubrza'
          },
          {
            'id_voivodeship': '08',
            'id_county': '08',
            'id_commune': '02',
            'name': 'Łagów'
          },
          {
            'id_voivodeship': '08',
            'id_county': '08',
            'id_commune': '03',
            'name': 'Skąpe'
          },
          {
            'id_voivodeship': '08',
            'id_county': '08',
            'id_commune': '04',
            'name': 'Szczaniec'
          },
          {
            'id_voivodeship': '08',
            'id_county': '08',
            'id_commune': '05',
            'name': 'Świebodzin'
          },
          {
            'id_voivodeship': '08',
            'id_county': '08',
            'id_commune': '06',
            'name': 'Zbąszynek'
          }
        ]
      },
      {
        'id_voivodeship': '08',
        'id_county': '09',
        'name': 'zielonogórski',
        'communes': [
          {
            'id_voivodeship': '08',
            'id_county': '09',
            'id_commune': '01',
            'name': 'Babimost'
          },
          {
            'id_voivodeship': '08',
            'id_county': '09',
            'id_commune': '02',
            'name': 'Bojadła'
          },
          {
            'id_voivodeship': '08',
            'id_county': '09',
            'id_commune': '03',
            'name': 'Czerwieńsk'
          },
          {
            'id_voivodeship': '08',
            'id_county': '09',
            'id_commune': '04',
            'name': 'Kargowa'
          },
          {
            'id_voivodeship': '08',
            'id_county': '09',
            'id_commune': '05',
            'name': 'Nowogród Bobrzański'
          },
          {
            'id_voivodeship': '08',
            'id_county': '09',
            'id_commune': '06',
            'name': 'Sulechów'
          },
          {
            'id_voivodeship': '08',
            'id_county': '09',
            'id_commune': '07',
            'name': 'Świdnica'
          },
          {
            'id_voivodeship': '08',
            'id_county': '09',
            'id_commune': '08',
            'name': 'Trzebiechów'
          },
          {
            'id_voivodeship': '08',
            'id_county': '09',
            'id_commune': '09',
            'name': 'Zabór'
          }
        ]
      },
      {
        'id_voivodeship': '08',
        'id_county': '10',
        'name': 'żagański',
        'communes': [
          {
            'id_voivodeship': '08',
            'id_county': '10',
            'id_commune': '01',
            'name': 'Gozdnica'
          },
          {
            'id_voivodeship': '08',
            'id_county': '10',
            'id_commune': '02',
            'name': 'Żagań'
          },
          {
            'id_voivodeship': '08',
            'id_county': '10',
            'id_commune': '03',
            'name': 'Brzeźnica'
          },
          {
            'id_voivodeship': '08',
            'id_county': '10',
            'id_commune': '04',
            'name': 'Iłowa'
          },
          {
            'id_voivodeship': '08',
            'id_county': '10',
            'id_commune': '05',
            'name': 'Małomice'
          },
          {
            'id_voivodeship': '08',
            'id_county': '10',
            'id_commune': '06',
            'name': 'Niegosławice'
          },
          {
            'id_voivodeship': '08',
            'id_county': '10',
            'id_commune': '07',
            'name': 'Szprotawa'
          },
          {
            'id_voivodeship': '08',
            'id_county': '10',
            'id_commune': '08',
            'name': 'Wymiarki'
          }
        ]
      },
      {
        'id_voivodeship': '08',
        'id_county': '11',
        'name': 'żarski',
        'communes': [
          {
            'id_voivodeship': '08',
            'id_county': '11',
            'id_commune': '01',
            'name': 'Łęknica'
          },
          {
            'id_voivodeship': '08',
            'id_county': '11',
            'id_commune': '02',
            'name': 'Żary'
          },
          {
            'id_voivodeship': '08',
            'id_county': '11',
            'id_commune': '03',
            'name': 'Brody'
          },
          {
            'id_voivodeship': '08',
            'id_county': '11',
            'id_commune': '04',
            'name': 'Jasień'
          },
          {
            'id_voivodeship': '08',
            'id_county': '11',
            'id_commune': '05',
            'name': 'Lipinki Łużyckie'
          },
          {
            'id_voivodeship': '08',
            'id_county': '11',
            'id_commune': '06',
            'name': 'Lubsko'
          },
          {
            'id_voivodeship': '08',
            'id_county': '11',
            'id_commune': '07',
            'name': 'Przewóz'
          },
          {
            'id_voivodeship': '08',
            'id_county': '11',
            'id_commune': '08',
            'name': 'Trzebiel'
          },
          {
            'id_voivodeship': '08',
            'id_county': '11',
            'id_commune': '09',
            'name': 'Tuplice'
          }
        ]
      },
      {
        'id_voivodeship': '08',
        'id_county': '12',
        'name': 'wschowski',
        'communes': [
          {
            'id_voivodeship': '08',
            'id_county': '12',
            'id_commune': '01',
            'name': 'Sława'
          },
          {
            'id_voivodeship': '08',
            'id_county': '12',
            'id_commune': '02',
            'name': 'Szlichtyngowa'
          },
          {
            'id_voivodeship': '08',
            'id_county': '12',
            'id_commune': '03',
            'name': 'Wschowa'
          }
        ]
      },
      {
        'id_voivodeship': '08',
        'id_county': '61',
        'name': 'Gorzów Wielkopolski',
        'communes': [
          {
            'id_voivodeship': '08',
            'id_county': '61',
            'id_commune': '01',
            'name': 'Gorzów Wielkopolski'
          }
        ]
      },
      {
        'id_voivodeship': '08',
        'id_county': '62',
        'name': 'Zielona Góra',
        'communes': [
          {
            'id_voivodeship': '08',
            'id_county': '62',
            'id_commune': '01',
            'name': 'Zielona Góra'
          }
        ]
      }
    ]
  },
  {
    'id_voivodeship': '10',
    'name': 'ŁÓDZKIE',
    'counties': [
      {
        'id_voivodeship': '10',
        'id_county': '01',
        'name': 'bełchatowski',
        'communes': [
          {
            'id_voivodeship': '10',
            'id_county': '01',
            'id_commune': '01',
            'name': 'Bełchatów'
          },
          {
            'id_voivodeship': '10',
            'id_county': '01',
            'id_commune': '03',
            'name': 'Drużbice'
          },
          {
            'id_voivodeship': '10',
            'id_county': '01',
            'id_commune': '04',
            'name': 'Kleszczów'
          },
          {
            'id_voivodeship': '10',
            'id_county': '01',
            'id_commune': '05',
            'name': 'Kluki'
          },
          {
            'id_voivodeship': '10',
            'id_county': '01',
            'id_commune': '06',
            'name': 'Rusiec'
          },
          {
            'id_voivodeship': '10',
            'id_county': '01',
            'id_commune': '07',
            'name': 'Szczerców'
          },
          {
            'id_voivodeship': '10',
            'id_county': '01',
            'id_commune': '08',
            'name': 'Zelów'
          }
        ]
      },
      {
        'id_voivodeship': '10',
        'id_county': '02',
        'name': 'kutnowski',
        'communes': [
          {
            'id_voivodeship': '10',
            'id_county': '02',
            'id_commune': '01',
            'name': 'Kutno'
          },
          {
            'id_voivodeship': '10',
            'id_county': '02',
            'id_commune': '02',
            'name': 'Bedlno'
          },
          {
            'id_voivodeship': '10',
            'id_county': '02',
            'id_commune': '03',
            'name': 'Dąbrowice'
          },
          {
            'id_voivodeship': '10',
            'id_county': '02',
            'id_commune': '04',
            'name': 'Krośniewice'
          },
          {
            'id_voivodeship': '10',
            'id_county': '02',
            'id_commune': '05',
            'name': 'Krzyżanów'
          },
          {
            'id_voivodeship': '10',
            'id_county': '02',
            'id_commune': '07',
            'name': 'Łanięta'
          },
          {
            'id_voivodeship': '10',
            'id_county': '02',
            'id_commune': '08',
            'name': 'Nowe Ostrowy'
          },
          {
            'id_voivodeship': '10',
            'id_county': '02',
            'id_commune': '09',
            'name': 'Oporów'
          },
          {
            'id_voivodeship': '10',
            'id_county': '02',
            'id_commune': '10',
            'name': 'Strzelce'
          },
          {
            'id_voivodeship': '10',
            'id_county': '02',
            'id_commune': '11',
            'name': 'Żychlin'
          }
        ]
      },
      {
        'id_voivodeship': '10',
        'id_county': '03',
        'name': 'łaski',
        'communes': [
          {
            'id_voivodeship': '10',
            'id_county': '03',
            'id_commune': '01',
            'name': 'Buczek'
          },
          {
            'id_voivodeship': '10',
            'id_county': '03',
            'id_commune': '02',
            'name': 'Łask'
          },
          {
            'id_voivodeship': '10',
            'id_county': '03',
            'id_commune': '03',
            'name': 'Sędziejowice'
          },
          {
            'id_voivodeship': '10',
            'id_county': '03',
            'id_commune': '04',
            'name': 'Widawa'
          },
          {
            'id_voivodeship': '10',
            'id_county': '03',
            'id_commune': '05',
            'name': 'Wodzierady'
          }
        ]
      },
      {
        'id_voivodeship': '10',
        'id_county': '04',
        'name': 'łęczycki',
        'communes': [
          {
            'id_voivodeship': '10',
            'id_county': '04',
            'id_commune': '01',
            'name': 'Łęczyca'
          },
          {
            'id_voivodeship': '10',
            'id_county': '04',
            'id_commune': '02',
            'name': 'Daszyna'
          },
          {
            'id_voivodeship': '10',
            'id_county': '04',
            'id_commune': '03',
            'name': 'Góra Świętej Małgorzaty'
          },
          {
            'id_voivodeship': '10',
            'id_county': '04',
            'id_commune': '04',
            'name': 'Grabów'
          },
          {
            'id_voivodeship': '10',
            'id_county': '04',
            'id_commune': '06',
            'name': 'Piątek'
          },
          {
            'id_voivodeship': '10',
            'id_county': '04',
            'id_commune': '07',
            'name': 'Świnice Warckie'
          },
          {
            'id_voivodeship': '10',
            'id_county': '04',
            'id_commune': '08',
            'name': 'Witonia'
          }
        ]
      },
      {
        'id_voivodeship': '10',
        'id_county': '05',
        'name': 'łowicki',
        'communes': [
          {
            'id_voivodeship': '10',
            'id_county': '05',
            'id_commune': '01',
            'name': 'Łowicz'
          },
          {
            'id_voivodeship': '10',
            'id_county': '05',
            'id_commune': '02',
            'name': 'Bielawy'
          },
          {
            'id_voivodeship': '10',
            'id_county': '05',
            'id_commune': '03',
            'name': 'Chąśno'
          },
          {
            'id_voivodeship': '10',
            'id_county': '05',
            'id_commune': '04',
            'name': 'Domaniewice'
          },
          {
            'id_voivodeship': '10',
            'id_county': '05',
            'id_commune': '05',
            'name': 'Kiernozia'
          },
          {
            'id_voivodeship': '10',
            'id_county': '05',
            'id_commune': '06',
            'name': 'Kocierzew Południowy'
          },
          {
            'id_voivodeship': '10',
            'id_county': '05',
            'id_commune': '08',
            'name': 'Łyszkowice'
          },
          {
            'id_voivodeship': '10',
            'id_county': '05',
            'id_commune': '09',
            'name': 'Nieborów'
          },
          {
            'id_voivodeship': '10',
            'id_county': '05',
            'id_commune': '10',
            'name': 'Zduny'
          }
        ]
      },
      {
        'id_voivodeship': '10',
        'id_county': '06',
        'name': 'łódzki wschodni',
        'communes': [
          {
            'id_voivodeship': '10',
            'id_county': '06',
            'id_commune': '02',
            'name': 'Andrespol'
          },
          {
            'id_voivodeship': '10',
            'id_county': '06',
            'id_commune': '03',
            'name': 'Brójce'
          },
          {
            'id_voivodeship': '10',
            'id_county': '06',
            'id_commune': '07',
            'name': 'Koluszki'
          },
          {
            'id_voivodeship': '10',
            'id_county': '06',
            'id_commune': '08',
            'name': 'Nowosolna'
          },
          {
            'id_voivodeship': '10',
            'id_county': '06',
            'id_commune': '10',
            'name': 'Rzgów'
          },
          {
            'id_voivodeship': '10',
            'id_county': '06',
            'id_commune': '11',
            'name': 'Tuszyn'
          }
        ]
      },
      {
        'id_voivodeship': '10',
        'id_county': '07',
        'name': 'opoczyński',
        'communes': [
          {
            'id_voivodeship': '10',
            'id_county': '07',
            'id_commune': '01',
            'name': 'Białaczów'
          },
          {
            'id_voivodeship': '10',
            'id_county': '07',
            'id_commune': '02',
            'name': 'Drzewica'
          },
          {
            'id_voivodeship': '10',
            'id_county': '07',
            'id_commune': '03',
            'name': 'Mniszków'
          },
          {
            'id_voivodeship': '10',
            'id_county': '07',
            'id_commune': '04',
            'name': 'Opoczno'
          },
          {
            'id_voivodeship': '10',
            'id_county': '07',
            'id_commune': '05',
            'name': 'Paradyż'
          },
          {
            'id_voivodeship': '10',
            'id_county': '07',
            'id_commune': '06',
            'name': 'Poświętne'
          },
          {
            'id_voivodeship': '10',
            'id_county': '07',
            'id_commune': '07',
            'name': 'Sławno'
          },
          {
            'id_voivodeship': '10',
            'id_county': '07',
            'id_commune': '08',
            'name': 'Żarnów'
          }
        ]
      },
      {
        'id_voivodeship': '10',
        'id_county': '08',
        'name': 'pabianicki',
        'communes': [
          {
            'id_voivodeship': '10',
            'id_county': '08',
            'id_commune': '01',
            'name': 'Konstantynów Łódzki'
          },
          {
            'id_voivodeship': '10',
            'id_county': '08',
            'id_commune': '02',
            'name': 'Pabianice'
          },
          {
            'id_voivodeship': '10',
            'id_county': '08',
            'id_commune': '03',
            'name': 'Dłutów'
          },
          {
            'id_voivodeship': '10',
            'id_county': '08',
            'id_commune': '04',
            'name': 'Dobroń'
          },
          {
            'id_voivodeship': '10',
            'id_county': '08',
            'id_commune': '05',
            'name': 'Ksawerów'
          },
          {
            'id_voivodeship': '10',
            'id_county': '08',
            'id_commune': '06',
            'name': 'Lutomiersk'
          }
        ]
      },
      {
        'id_voivodeship': '10',
        'id_county': '09',
        'name': 'pajęczański',
        'communes': [
          {
            'id_voivodeship': '10',
            'id_county': '09',
            'id_commune': '01',
            'name': 'Działoszyn'
          },
          {
            'id_voivodeship': '10',
            'id_county': '09',
            'id_commune': '02',
            'name': 'Kiełczygłów'
          },
          {
            'id_voivodeship': '10',
            'id_county': '09',
            'id_commune': '03',
            'name': 'Nowa Brzeźnica'
          },
          {
            'id_voivodeship': '10',
            'id_county': '09',
            'id_commune': '04',
            'name': 'Pajęczno'
          },
          {
            'id_voivodeship': '10',
            'id_county': '09',
            'id_commune': '05',
            'name': 'Rząśnia'
          },
          {
            'id_voivodeship': '10',
            'id_county': '09',
            'id_commune': '06',
            'name': 'Siemkowice'
          },
          {
            'id_voivodeship': '10',
            'id_county': '09',
            'id_commune': '07',
            'name': 'Strzelce Wielkie'
          },
          {
            'id_voivodeship': '10',
            'id_county': '09',
            'id_commune': '08',
            'name': 'Sulmierzyce'
          }
        ]
      },
      {
        'id_voivodeship': '10',
        'id_county': '10',
        'name': 'piotrkowski',
        'communes': [
          {
            'id_voivodeship': '10',
            'id_county': '10',
            'id_commune': '01',
            'name': 'Aleksandrów'
          },
          {
            'id_voivodeship': '10',
            'id_county': '10',
            'id_commune': '02',
            'name': 'Czarnocin'
          },
          {
            'id_voivodeship': '10',
            'id_county': '10',
            'id_commune': '03',
            'name': 'Gorzkowice'
          },
          {
            'id_voivodeship': '10',
            'id_county': '10',
            'id_commune': '04',
            'name': 'Grabica'
          },
          {
            'id_voivodeship': '10',
            'id_county': '10',
            'id_commune': '05',
            'name': 'Łęki Szlacheckie'
          },
          {
            'id_voivodeship': '10',
            'id_county': '10',
            'id_commune': '06',
            'name': 'Moszczenica'
          },
          {
            'id_voivodeship': '10',
            'id_county': '10',
            'id_commune': '07',
            'name': 'Ręczno'
          },
          {
            'id_voivodeship': '10',
            'id_county': '10',
            'id_commune': '08',
            'name': 'Rozprza'
          },
          {
            'id_voivodeship': '10',
            'id_county': '10',
            'id_commune': '09',
            'name': 'Sulejów'
          },
          {
            'id_voivodeship': '10',
            'id_county': '10',
            'id_commune': '10',
            'name': 'Wola Krzysztoporska'
          },
          {
            'id_voivodeship': '10',
            'id_county': '10',
            'id_commune': '11',
            'name': 'Wolbórz'
          }
        ]
      },
      {
        'id_voivodeship': '10',
        'id_county': '11',
        'name': 'poddębicki',
        'communes': [
          {
            'id_voivodeship': '10',
            'id_county': '11',
            'id_commune': '01',
            'name': 'Dalików'
          },
          {
            'id_voivodeship': '10',
            'id_county': '11',
            'id_commune': '02',
            'name': 'Pęczniew'
          },
          {
            'id_voivodeship': '10',
            'id_county': '11',
            'id_commune': '03',
            'name': 'Poddębice'
          },
          {
            'id_voivodeship': '10',
            'id_county': '11',
            'id_commune': '04',
            'name': 'Uniejów'
          },
          {
            'id_voivodeship': '10',
            'id_county': '11',
            'id_commune': '05',
            'name': 'Wartkowice'
          },
          {
            'id_voivodeship': '10',
            'id_county': '11',
            'id_commune': '06',
            'name': 'Zadzim'
          }
        ]
      },
      {
        'id_voivodeship': '10',
        'id_county': '12',
        'name': 'radomszczański',
        'communes': [
          {
            'id_voivodeship': '10',
            'id_county': '12',
            'id_commune': '01',
            'name': 'Radomsko'
          },
          {
            'id_voivodeship': '10',
            'id_county': '12',
            'id_commune': '02',
            'name': 'Dobryszyce'
          },
          {
            'id_voivodeship': '10',
            'id_county': '12',
            'id_commune': '03',
            'name': 'Gidle'
          },
          {
            'id_voivodeship': '10',
            'id_county': '12',
            'id_commune': '04',
            'name': 'Gomunice'
          },
          {
            'id_voivodeship': '10',
            'id_county': '12',
            'id_commune': '05',
            'name': 'Kamieńsk'
          },
          {
            'id_voivodeship': '10',
            'id_county': '12',
            'id_commune': '06',
            'name': 'Kobiele Wielkie'
          },
          {
            'id_voivodeship': '10',
            'id_county': '12',
            'id_commune': '07',
            'name': 'Kodrąb'
          },
          {
            'id_voivodeship': '10',
            'id_county': '12',
            'id_commune': '08',
            'name': 'Lgota Wielka'
          },
          {
            'id_voivodeship': '10',
            'id_county': '12',
            'id_commune': '09',
            'name': 'Ładzice'
          },
          {
            'id_voivodeship': '10',
            'id_county': '12',
            'id_commune': '10',
            'name': 'Masłowice'
          },
          {
            'id_voivodeship': '10',
            'id_county': '12',
            'id_commune': '11',
            'name': 'Przedbórz'
          },
          {
            'id_voivodeship': '10',
            'id_county': '12',
            'id_commune': '13',
            'name': 'Wielgomłyny'
          },
          {
            'id_voivodeship': '10',
            'id_county': '12',
            'id_commune': '14',
            'name': 'Żytno'
          }
        ]
      },
      {
        'id_voivodeship': '10',
        'id_county': '13',
        'name': 'rawski',
        'communes': [
          {
            'id_voivodeship': '10',
            'id_county': '13',
            'id_commune': '01',
            'name': 'Rawa Mazowiecka'
          },
          {
            'id_voivodeship': '10',
            'id_county': '13',
            'id_commune': '02',
            'name': 'Biała Rawska'
          },
          {
            'id_voivodeship': '10',
            'id_county': '13',
            'id_commune': '03',
            'name': 'Cielądz'
          },
          {
            'id_voivodeship': '10',
            'id_county': '13',
            'id_commune': '05',
            'name': 'Regnów'
          },
          {
            'id_voivodeship': '10',
            'id_county': '13',
            'id_commune': '06',
            'name': 'Sadkowice'
          }
        ]
      },
      {
        'id_voivodeship': '10',
        'id_county': '14',
        'name': 'sieradzki',
        'communes': [
          {
            'id_voivodeship': '10',
            'id_county': '14',
            'id_commune': '01',
            'name': 'Sieradz'
          },
          {
            'id_voivodeship': '10',
            'id_county': '14',
            'id_commune': '02',
            'name': 'Błaszki'
          },
          {
            'id_voivodeship': '10',
            'id_county': '14',
            'id_commune': '03',
            'name': 'Brąszewice'
          },
          {
            'id_voivodeship': '10',
            'id_county': '14',
            'id_commune': '04',
            'name': 'Brzeźnio'
          },
          {
            'id_voivodeship': '10',
            'id_county': '14',
            'id_commune': '05',
            'name': 'Burzenin'
          },
          {
            'id_voivodeship': '10',
            'id_county': '14',
            'id_commune': '06',
            'name': 'Goszczanów'
          },
          {
            'id_voivodeship': '10',
            'id_county': '14',
            'id_commune': '07',
            'name': 'Klonowa'
          },
          {
            'id_voivodeship': '10',
            'id_county': '14',
            'id_commune': '09',
            'name': 'Warta'
          },
          {
            'id_voivodeship': '10',
            'id_county': '14',
            'id_commune': '10',
            'name': 'Wróblew'
          },
          {
            'id_voivodeship': '10',
            'id_county': '14',
            'id_commune': '11',
            'name': 'Złoczew'
          }
        ]
      },
      {
        'id_voivodeship': '10',
        'id_county': '15',
        'name': 'skierniewicki',
        'communes': [
          {
            'id_voivodeship': '10',
            'id_county': '15',
            'id_commune': '01',
            'name': 'Bolimów'
          },
          {
            'id_voivodeship': '10',
            'id_county': '15',
            'id_commune': '02',
            'name': 'Głuchów'
          },
          {
            'id_voivodeship': '10',
            'id_county': '15',
            'id_commune': '03',
            'name': 'Godzianów'
          },
          {
            'id_voivodeship': '10',
            'id_county': '15',
            'id_commune': '04',
            'name': 'Kowiesy'
          },
          {
            'id_voivodeship': '10',
            'id_county': '15',
            'id_commune': '05',
            'name': 'Lipce Reymontowskie'
          },
          {
            'id_voivodeship': '10',
            'id_county': '15',
            'id_commune': '06',
            'name': 'Maków'
          },
          {
            'id_voivodeship': '10',
            'id_county': '15',
            'id_commune': '07',
            'name': 'Nowy Kawęczyn'
          },
          {
            'id_voivodeship': '10',
            'id_county': '15',
            'id_commune': '08',
            'name': 'Skierniewice'
          },
          {
            'id_voivodeship': '10',
            'id_county': '15',
            'id_commune': '09',
            'name': 'Słupia'
          }
        ]
      },
      {
        'id_voivodeship': '10',
        'id_county': '16',
        'name': 'tomaszowski',
        'communes': [
          {
            'id_voivodeship': '10',
            'id_county': '16',
            'id_commune': '01',
            'name': 'Tomaszów Mazowiecki'
          },
          {
            'id_voivodeship': '10',
            'id_county': '16',
            'id_commune': '02',
            'name': 'Będków'
          },
          {
            'id_voivodeship': '10',
            'id_county': '16',
            'id_commune': '03',
            'name': 'Budziszewice'
          },
          {
            'id_voivodeship': '10',
            'id_county': '16',
            'id_commune': '04',
            'name': 'Czerniewice'
          },
          {
            'id_voivodeship': '10',
            'id_county': '16',
            'id_commune': '05',
            'name': 'Inowłódz'
          },
          {
            'id_voivodeship': '10',
            'id_county': '16',
            'id_commune': '06',
            'name': 'Lubochnia'
          },
          {
            'id_voivodeship': '10',
            'id_county': '16',
            'id_commune': '07',
            'name': 'Rokiciny'
          },
          {
            'id_voivodeship': '10',
            'id_county': '16',
            'id_commune': '08',
            'name': 'Rzeczyca'
          },
          {
            'id_voivodeship': '10',
            'id_county': '16',
            'id_commune': '10',
            'name': 'Ujazd'
          },
          {
            'id_voivodeship': '10',
            'id_county': '16',
            'id_commune': '11',
            'name': 'Żelechlinek'
          }
        ]
      },
      {
        'id_voivodeship': '10',
        'id_county': '17',
        'name': 'wieluński',
        'communes': [
          {
            'id_voivodeship': '10',
            'id_county': '17',
            'id_commune': '01',
            'name': 'Biała'
          },
          {
            'id_voivodeship': '10',
            'id_county': '17',
            'id_commune': '02',
            'name': 'Czarnożyły'
          },
          {
            'id_voivodeship': '10',
            'id_county': '17',
            'id_commune': '03',
            'name': 'Konopnica'
          },
          {
            'id_voivodeship': '10',
            'id_county': '17',
            'id_commune': '04',
            'name': 'Mokrsko'
          },
          {
            'id_voivodeship': '10',
            'id_county': '17',
            'id_commune': '05',
            'name': 'Osjaków'
          },
          {
            'id_voivodeship': '10',
            'id_county': '17',
            'id_commune': '06',
            'name': 'Ostrówek'
          },
          {
            'id_voivodeship': '10',
            'id_county': '17',
            'id_commune': '07',
            'name': 'Pątnów'
          },
          {
            'id_voivodeship': '10',
            'id_county': '17',
            'id_commune': '08',
            'name': 'Skomlin'
          },
          {
            'id_voivodeship': '10',
            'id_county': '17',
            'id_commune': '09',
            'name': 'Wieluń'
          },
          {
            'id_voivodeship': '10',
            'id_county': '17',
            'id_commune': '10',
            'name': 'Wierzchlas'
          }
        ]
      },
      {
        'id_voivodeship': '10',
        'id_county': '18',
        'name': 'wieruszowski',
        'communes': [
          {
            'id_voivodeship': '10',
            'id_county': '18',
            'id_commune': '01',
            'name': 'Bolesławiec'
          },
          {
            'id_voivodeship': '10',
            'id_county': '18',
            'id_commune': '02',
            'name': 'Czastary'
          },
          {
            'id_voivodeship': '10',
            'id_county': '18',
            'id_commune': '03',
            'name': 'Galewice'
          },
          {
            'id_voivodeship': '10',
            'id_county': '18',
            'id_commune': '04',
            'name': 'Lututów'
          },
          {
            'id_voivodeship': '10',
            'id_county': '18',
            'id_commune': '05',
            'name': 'Łubnice'
          },
          {
            'id_voivodeship': '10',
            'id_county': '18',
            'id_commune': '06',
            'name': 'Sokolniki'
          },
          {
            'id_voivodeship': '10',
            'id_county': '18',
            'id_commune': '07',
            'name': 'Wieruszów'
          }
        ]
      },
      {
        'id_voivodeship': '10',
        'id_county': '19',
        'name': 'zduńskowolski',
        'communes': [
          {
            'id_voivodeship': '10',
            'id_county': '19',
            'id_commune': '01',
            'name': 'Zduńska Wola'
          },
          {
            'id_voivodeship': '10',
            'id_county': '19',
            'id_commune': '02',
            'name': 'Szadek'
          },
          {
            'id_voivodeship': '10',
            'id_county': '19',
            'id_commune': '03',
            'name': 'Zapolice'
          }
        ]
      },
      {
        'id_voivodeship': '10',
        'id_county': '20',
        'name': 'zgierski',
        'communes': [
          {
            'id_voivodeship': '10',
            'id_county': '20',
            'id_commune': '01',
            'name': 'Głowno'
          },
          {
            'id_voivodeship': '10',
            'id_county': '20',
            'id_commune': '02',
            'name': 'Ozorków'
          },
          {
            'id_voivodeship': '10',
            'id_county': '20',
            'id_commune': '03',
            'name': 'Zgierz'
          },
          {
            'id_voivodeship': '10',
            'id_county': '20',
            'id_commune': '04',
            'name': 'Aleksandrów Łódzki'
          },
          {
            'id_voivodeship': '10',
            'id_county': '20',
            'id_commune': '07',
            'name': 'Parzęczew'
          },
          {
            'id_voivodeship': '10',
            'id_county': '20',
            'id_commune': '08',
            'name': 'Stryków'
          }
        ]
      },
      {
        'id_voivodeship': '10',
        'id_county': '21',
        'name': 'brzeziński',
        'communes': [
          {
            'id_voivodeship': '10',
            'id_county': '21',
            'id_commune': '01',
            'name': 'Brzeziny'
          },
          {
            'id_voivodeship': '10',
            'id_county': '21',
            'id_commune': '03',
            'name': 'Dmosin'
          },
          {
            'id_voivodeship': '10',
            'id_county': '21',
            'id_commune': '04',
            'name': 'Jeżów'
          },
          {
            'id_voivodeship': '10',
            'id_county': '21',
            'id_commune': '05',
            'name': 'Rogów'
          }
        ]
      },
      {
        'id_voivodeship': '10',
        'id_county': '61',
        'name': 'Łódź',
        'communes': [
          {
            'id_voivodeship': '10',
            'id_county': '61',
            'id_commune': '01',
            'name': 'Łódź'
          },
        ]
      },
      {
        'id_voivodeship': '10',
        'id_county': '62',
        'name': 'Piotrków Trybunalski',
        'communes': [
          {
            'id_voivodeship': '10',
            'id_county': '62',
            'id_commune': '01',
            'name': 'Piotrków Trybunalski'
          }
        ]
      },
      {
        'id_voivodeship': '10',
        'id_county': '63',
        'name': 'Skierniewice',
        'communes': [
          {
            'id_voivodeship': '10',
            'id_county': '63',
            'id_commune': '01',
            'name': 'Skierniewice'
          }
        ]
      }
    ]
  },
  {
    'id_voivodeship': '12',
    'name': 'MAŁOPOLSKIE',
    'counties': [
      {
        'id_voivodeship': '12',
        'id_county': '01',
        'name': 'bocheński',
        'communes': [
          {
            'id_voivodeship': '12',
            'id_county': '01',
            'id_commune': '01',
            'name': 'Bochnia'
          },
          {
            'id_voivodeship': '12',
            'id_county': '01',
            'id_commune': '03',
            'name': 'Drwinia'
          },
          {
            'id_voivodeship': '12',
            'id_county': '01',
            'id_commune': '04',
            'name': 'Lipnica Murowana'
          },
          {
            'id_voivodeship': '12',
            'id_county': '01',
            'id_commune': '05',
            'name': 'Łapanów'
          },
          {
            'id_voivodeship': '12',
            'id_county': '01',
            'id_commune': '06',
            'name': 'Nowy Wiśnicz'
          },
          {
            'id_voivodeship': '12',
            'id_county': '01',
            'id_commune': '07',
            'name': 'Rzezawa'
          },
          {
            'id_voivodeship': '12',
            'id_county': '01',
            'id_commune': '08',
            'name': 'Trzciana'
          },
          {
            'id_voivodeship': '12',
            'id_county': '01',
            'id_commune': '09',
            'name': 'Żegocina'
          }
        ]
      },
      {
        'id_voivodeship': '12',
        'id_county': '02',
        'name': 'brzeski',
        'communes': [
          {
            'id_voivodeship': '12',
            'id_county': '02',
            'id_commune': '01',
            'name': 'Borzęcin'
          },
          {
            'id_voivodeship': '12',
            'id_county': '02',
            'id_commune': '02',
            'name': 'Brzesko'
          },
          {
            'id_voivodeship': '12',
            'id_county': '02',
            'id_commune': '03',
            'name': 'Czchów'
          },
          {
            'id_voivodeship': '12',
            'id_county': '02',
            'id_commune': '04',
            'name': 'Dębno'
          },
          {
            'id_voivodeship': '12',
            'id_county': '02',
            'id_commune': '05',
            'name': 'Gnojnik'
          },
          {
            'id_voivodeship': '12',
            'id_county': '02',
            'id_commune': '06',
            'name': 'Iwkowa'
          },
          {
            'id_voivodeship': '12',
            'id_county': '02',
            'id_commune': '07',
            'name': 'Szczurowa'
          }
        ]
      },
      {
        'id_voivodeship': '12',
        'id_county': '03',
        'name': 'chrzanowski',
        'communes': [
          {
            'id_voivodeship': '12',
            'id_county': '03',
            'id_commune': '01',
            'name': 'Alwernia'
          },
          {
            'id_voivodeship': '12',
            'id_county': '03',
            'id_commune': '02',
            'name': 'Babice'
          },
          {
            'id_voivodeship': '12',
            'id_county': '03',
            'id_commune': '03',
            'name': 'Chrzanów'
          },
          {
            'id_voivodeship': '12',
            'id_county': '03',
            'id_commune': '04',
            'name': 'Libiąż'
          },
          {
            'id_voivodeship': '12',
            'id_county': '03',
            'id_commune': '05',
            'name': 'Trzebinia'
          }
        ]
      },
      {
        'id_voivodeship': '12',
        'id_county': '04',
        'name': 'dąbrowski',
        'communes': [
          {
            'id_voivodeship': '12',
            'id_county': '04',
            'id_commune': '01',
            'name': 'Bolesław'
          },
          {
            'id_voivodeship': '12',
            'id_county': '04',
            'id_commune': '02',
            'name': 'Dąbrowa Tarnowska'
          },
          {
            'id_voivodeship': '12',
            'id_county': '04',
            'id_commune': '03',
            'name': 'Gręboszów'
          },
          {
            'id_voivodeship': '12',
            'id_county': '04',
            'id_commune': '04',
            'name': 'Mędrzechów'
          },
          {
            'id_voivodeship': '12',
            'id_county': '04',
            'id_commune': '05',
            'name': 'Olesno'
          },
          {
            'id_voivodeship': '12',
            'id_county': '04',
            'id_commune': '06',
            'name': 'Radgoszcz'
          },
          {
            'id_voivodeship': '12',
            'id_county': '04',
            'id_commune': '07',
            'name': 'Szczucin'
          }
        ]
      },
      {
        'id_voivodeship': '12',
        'id_county': '05',
        'name': 'gorlicki',
        'communes': [
          {
            'id_voivodeship': '12',
            'id_county': '05',
            'id_commune': '01',
            'name': 'Gorlice'
          },
          {
            'id_voivodeship': '12',
            'id_county': '05',
            'id_commune': '02',
            'name': 'Biecz'
          },
          {
            'id_voivodeship': '12',
            'id_county': '05',
            'id_commune': '03',
            'name': 'Bobowa'
          },
          {
            'id_voivodeship': '12',
            'id_county': '05',
            'id_commune': '05',
            'name': 'Lipinki'
          },
          {
            'id_voivodeship': '12',
            'id_county': '05',
            'id_commune': '06',
            'name': 'Łużna'
          },
          {
            'id_voivodeship': '12',
            'id_county': '05',
            'id_commune': '07',
            'name': 'Moszczenica'
          },
          {
            'id_voivodeship': '12',
            'id_county': '05',
            'id_commune': '08',
            'name': 'Ropa'
          },
          {
            'id_voivodeship': '12',
            'id_county': '05',
            'id_commune': '09',
            'name': 'Sękowa'
          },
          {
            'id_voivodeship': '12',
            'id_county': '05',
            'id_commune': '10',
            'name': 'Uście Gorlickie'
          }
        ]
      },
      {
        'id_voivodeship': '12',
        'id_county': '06',
        'name': 'krakowski',
        'communes': [
          {
            'id_voivodeship': '12',
            'id_county': '06',
            'id_commune': '01',
            'name': 'Czernichów'
          },
          {
            'id_voivodeship': '12',
            'id_county': '06',
            'id_commune': '02',
            'name': 'Igołomia-Wawrzeńczyce'
          },
          {
            'id_voivodeship': '12',
            'id_county': '06',
            'id_commune': '03',
            'name': 'Iwanowice'
          },
          {
            'id_voivodeship': '12',
            'id_county': '06',
            'id_commune': '04',
            'name': 'Jerzmanowice-Przeginia'
          },
          {
            'id_voivodeship': '12',
            'id_county': '06',
            'id_commune': '05',
            'name': 'Kocmyrzów-Luborzyca'
          },
          {
            'id_voivodeship': '12',
            'id_county': '06',
            'id_commune': '06',
            'name': 'Krzeszowice'
          },
          {
            'id_voivodeship': '12',
            'id_county': '06',
            'id_commune': '07',
            'name': 'Liszki'
          },
          {
            'id_voivodeship': '12',
            'id_county': '06',
            'id_commune': '08',
            'name': 'Michałowice'
          },
          {
            'id_voivodeship': '12',
            'id_county': '06',
            'id_commune': '09',
            'name': 'Mogilany'
          },
          {
            'id_voivodeship': '12',
            'id_county': '06',
            'id_commune': '10',
            'name': 'Skała'
          },
          {
            'id_voivodeship': '12',
            'id_county': '06',
            'id_commune': '11',
            'name': 'Skawina'
          },
          {
            'id_voivodeship': '12',
            'id_county': '06',
            'id_commune': '12',
            'name': 'Słomniki'
          },
          {
            'id_voivodeship': '12',
            'id_county': '06',
            'id_commune': '13',
            'name': 'Sułoszowa'
          },
          {
            'id_voivodeship': '12',
            'id_county': '06',
            'id_commune': '14',
            'name': 'Świątniki Górne'
          },
          {
            'id_voivodeship': '12',
            'id_county': '06',
            'id_commune': '15',
            'name': 'Wielka Wieś'
          },
          {
            'id_voivodeship': '12',
            'id_county': '06',
            'id_commune': '16',
            'name': 'Zabierzów'
          },
          {
            'id_voivodeship': '12',
            'id_county': '06',
            'id_commune': '17',
            'name': 'Zielonki'
          }
        ]
      },
      {
        'id_voivodeship': '12',
        'id_county': '07',
        'name': 'limanowski',
        'communes': [
          {
            'id_voivodeship': '12',
            'id_county': '07',
            'id_commune': '01',
            'name': 'Limanowa'
          },
          {
            'id_voivodeship': '12',
            'id_county': '07',
            'id_commune': '02',
            'name': 'Mszana Dolna'
          },
          {
            'id_voivodeship': '12',
            'id_county': '07',
            'id_commune': '03',
            'name': 'Dobra'
          },
          {
            'id_voivodeship': '12',
            'id_county': '07',
            'id_commune': '04',
            'name': 'Jodłownik'
          },
          {
            'id_voivodeship': '12',
            'id_county': '07',
            'id_commune': '05',
            'name': 'Kamienica'
          },
          {
            'id_voivodeship': '12',
            'id_county': '07',
            'id_commune': '06',
            'name': 'Laskowa'
          },
          {
            'id_voivodeship': '12',
            'id_county': '07',
            'id_commune': '08',
            'name': 'Łukowica'
          },
          {
            'id_voivodeship': '12',
            'id_county': '07',
            'id_commune': '10',
            'name': 'Niedźwiedź'
          },
          {
            'id_voivodeship': '12',
            'id_county': '07',
            'id_commune': '11',
            'name': 'Słopnice'
          },
          {
            'id_voivodeship': '12',
            'id_county': '07',
            'id_commune': '12',
            'name': 'Tymbark'
          }
        ]
      },
      {
        'id_voivodeship': '12',
        'id_county': '08',
        'name': 'miechowski',
        'communes': [
          {
            'id_voivodeship': '12',
            'id_county': '08',
            'id_commune': '01',
            'name': 'Charsznica'
          },
          {
            'id_voivodeship': '12',
            'id_county': '08',
            'id_commune': '02',
            'name': 'Gołcza'
          },
          {
            'id_voivodeship': '12',
            'id_county': '08',
            'id_commune': '03',
            'name': 'Kozłów'
          },
          {
            'id_voivodeship': '12',
            'id_county': '08',
            'id_commune': '04',
            'name': 'Książ Wielki'
          },
          {
            'id_voivodeship': '12',
            'id_county': '08',
            'id_commune': '05',
            'name': 'Miechów'
          },
          {
            'id_voivodeship': '12',
            'id_county': '08',
            'id_commune': '06',
            'name': 'Racławice'
          },
          {
            'id_voivodeship': '12',
            'id_county': '08',
            'id_commune': '07',
            'name': 'Słaboszów'
          }
        ]
      },
      {
        'id_voivodeship': '12',
        'id_county': '09',
        'name': 'myślenicki',
        'communes': [
          {
            'id_voivodeship': '12',
            'id_county': '09',
            'id_commune': '01',
            'name': 'Dobczyce'
          },
          {
            'id_voivodeship': '12',
            'id_county': '09',
            'id_commune': '02',
            'name': 'Lubień'
          },
          {
            'id_voivodeship': '12',
            'id_county': '09',
            'id_commune': '03',
            'name': 'Myślenice'
          },
          {
            'id_voivodeship': '12',
            'id_county': '09',
            'id_commune': '04',
            'name': 'Pcim'
          },
          {
            'id_voivodeship': '12',
            'id_county': '09',
            'id_commune': '05',
            'name': 'Raciechowice'
          },
          {
            'id_voivodeship': '12',
            'id_county': '09',
            'id_commune': '06',
            'name': 'Siepraw'
          },
          {
            'id_voivodeship': '12',
            'id_county': '09',
            'id_commune': '07',
            'name': 'Sułkowice'
          },
          {
            'id_voivodeship': '12',
            'id_county': '09',
            'id_commune': '08',
            'name': 'Tokarnia'
          },
          {
            'id_voivodeship': '12',
            'id_county': '09',
            'id_commune': '09',
            'name': 'Wiśniowa'
          }
        ]
      },
      {
        'id_voivodeship': '12',
        'id_county': '10',
        'name': 'nowosądecki',
        'communes': [
          {
            'id_voivodeship': '12',
            'id_county': '10',
            'id_commune': '01',
            'name': 'Grybów'
          },
          {
            'id_voivodeship': '12',
            'id_county': '10',
            'id_commune': '02',
            'name': 'Chełmiec'
          },
          {
            'id_voivodeship': '12',
            'id_county': '10',
            'id_commune': '03',
            'name': 'Gródek nad Dunajcem'
          },
          {
            'id_voivodeship': '12',
            'id_county': '10',
            'id_commune': '05',
            'name': 'Kamionka Wielka'
          },
          {
            'id_voivodeship': '12',
            'id_county': '10',
            'id_commune': '06',
            'name': 'Korzenna'
          },
          {
            'id_voivodeship': '12',
            'id_county': '10',
            'id_commune': '07',
            'name': 'Krynica-Zdrój'
          },
          {
            'id_voivodeship': '12',
            'id_county': '10',
            'id_commune': '08',
            'name': 'Łabowa'
          },
          {
            'id_voivodeship': '12',
            'id_county': '10',
            'id_commune': '09',
            'name': 'Łącko'
          },
          {
            'id_voivodeship': '12',
            'id_county': '10',
            'id_commune': '10',
            'name': 'Łososina Dolna'
          },
          {
            'id_voivodeship': '12',
            'id_county': '10',
            'id_commune': '11',
            'name': 'Muszyna'
          },
          {
            'id_voivodeship': '12',
            'id_county': '10',
            'id_commune': '12',
            'name': 'Nawojowa'
          },
          {
            'id_voivodeship': '12',
            'id_county': '10',
            'id_commune': '13',
            'name': 'Piwniczna-Zdrój'
          },
          {
            'id_voivodeship': '12',
            'id_county': '10',
            'id_commune': '14',
            'name': 'Podegrodzie'
          },
          {
            'id_voivodeship': '12',
            'id_county': '10',
            'id_commune': '15',
            'name': 'Rytro'
          },
          {
            'id_voivodeship': '12',
            'id_county': '10',
            'id_commune': '16',
            'name': 'Stary Sącz'
          }
        ]
      },
      {
        'id_voivodeship': '12',
        'id_county': '11',
        'name': 'nowotarski',
        'communes': [
          {
            'id_voivodeship': '12',
            'id_county': '11',
            'id_commune': '01',
            'name': 'Nowy Targ'
          },
          {
            'id_voivodeship': '12',
            'id_county': '11',
            'id_commune': '02',
            'name': 'Szczawnica'
          },
          {
            'id_voivodeship': '12',
            'id_county': '11',
            'id_commune': '03',
            'name': 'Czarny Dunajec'
          },
          {
            'id_voivodeship': '12',
            'id_county': '11',
            'id_commune': '04',
            'name': 'Czorsztyn'
          },
          {
            'id_voivodeship': '12',
            'id_county': '11',
            'id_commune': '05',
            'name': 'Jabłonka'
          },
          {
            'id_voivodeship': '12',
            'id_county': '11',
            'id_commune': '06',
            'name': 'Krościenko nad Dunajcem'
          },
          {
            'id_voivodeship': '12',
            'id_county': '11',
            'id_commune': '07',
            'name': 'Lipnica Wielka'
          },
          {
            'id_voivodeship': '12',
            'id_county': '11',
            'id_commune': '08',
            'name': 'Łapsze Niżne'
          },
          {
            'id_voivodeship': '12',
            'id_county': '11',
            'id_commune': '10',
            'name': 'Ochotnica Dolna'
          },
          {
            'id_voivodeship': '12',
            'id_county': '11',
            'id_commune': '11',
            'name': 'Raba Wyżna'
          },
          {
            'id_voivodeship': '12',
            'id_county': '11',
            'id_commune': '12',
            'name': 'Rabka-Zdrój'
          },
          {
            'id_voivodeship': '12',
            'id_county': '11',
            'id_commune': '13',
            'name': 'Spytkowice'
          },
          {
            'id_voivodeship': '12',
            'id_county': '11',
            'id_commune': '14',
            'name': 'Szaflary'
          }
        ]
      },
      {
        'id_voivodeship': '12',
        'id_county': '12',
        'name': 'olkuski',
        'communes': [
          {
            'id_voivodeship': '12',
            'id_county': '12',
            'id_commune': '01',
            'name': 'Bukowno'
          },
          {
            'id_voivodeship': '12',
            'id_county': '12',
            'id_commune': '03',
            'name': 'Bolesław'
          },
          {
            'id_voivodeship': '12',
            'id_county': '12',
            'id_commune': '04',
            'name': 'Klucze'
          },
          {
            'id_voivodeship': '12',
            'id_county': '12',
            'id_commune': '05',
            'name': 'Olkusz'
          },
          {
            'id_voivodeship': '12',
            'id_county': '12',
            'id_commune': '06',
            'name': 'Trzyciąż'
          },
          {
            'id_voivodeship': '12',
            'id_county': '12',
            'id_commune': '07',
            'name': 'Wolbrom'
          }
        ]
      },
      {
        'id_voivodeship': '12',
        'id_county': '13',
        'name': 'oświęcimski',
        'communes': [
          {
            'id_voivodeship': '12',
            'id_county': '13',
            'id_commune': '01',
            'name': 'Oświęcim'
          },
          {
            'id_voivodeship': '12',
            'id_county': '13',
            'id_commune': '02',
            'name': 'Brzeszcze'
          },
          {
            'id_voivodeship': '12',
            'id_county': '13',
            'id_commune': '03',
            'name': 'Chełmek'
          },
          {
            'id_voivodeship': '12',
            'id_county': '13',
            'id_commune': '04',
            'name': 'Kęty'
          },
          {
            'id_voivodeship': '12',
            'id_county': '13',
            'id_commune': '05',
            'name': 'Osiek'
          },
          {
            'id_voivodeship': '12',
            'id_county': '13',
            'id_commune': '07',
            'name': 'Polanka Wielka'
          },
          {
            'id_voivodeship': '12',
            'id_county': '13',
            'id_commune': '08',
            'name': 'Przeciszów'
          },
          {
            'id_voivodeship': '12',
            'id_county': '13',
            'id_commune': '09',
            'name': 'Zator'
          }
        ]
      },
      {
        'id_voivodeship': '12',
        'id_county': '14',
        'name': 'proszowicki',
        'communes': [
          {
            'id_voivodeship': '12',
            'id_county': '14',
            'id_commune': '01',
            'name': 'Koniusza'
          },
          {
            'id_voivodeship': '12',
            'id_county': '14',
            'id_commune': '02',
            'name': 'Koszyce'
          },
          {
            'id_voivodeship': '12',
            'id_county': '14',
            'id_commune': '03',
            'name': 'Nowe Brzesko'
          },
          {
            'id_voivodeship': '12',
            'id_county': '14',
            'id_commune': '04',
            'name': 'Pałecznica'
          },
          {
            'id_voivodeship': '12',
            'id_county': '14',
            'id_commune': '05',
            'name': 'Proszowice'
          },
          {
            'id_voivodeship': '12',
            'id_county': '14',
            'id_commune': '06',
            'name': 'Radziemice'
          }
        ]
      },
      {
        'id_voivodeship': '12',
        'id_county': '15',
        'name': 'suski',
        'communes': [
          {
            'id_voivodeship': '12',
            'id_county': '15',
            'id_commune': '01',
            'name': 'Jordanów'
          },
          {
            'id_voivodeship': '12',
            'id_county': '15',
            'id_commune': '02',
            'name': 'Sucha Beskidzka'
          },
          {
            'id_voivodeship': '12',
            'id_county': '15',
            'id_commune': '03',
            'name': 'Budzów'
          },
          {
            'id_voivodeship': '12',
            'id_county': '15',
            'id_commune': '04',
            'name': 'Bystra-Sidzina'
          },
          {
            'id_voivodeship': '12',
            'id_county': '15',
            'id_commune': '06',
            'name': 'Maków Podhalański'
          },
          {
            'id_voivodeship': '12',
            'id_county': '15',
            'id_commune': '07',
            'name': 'Stryszawa'
          },
          {
            'id_voivodeship': '12',
            'id_county': '15',
            'id_commune': '08',
            'name': 'Zawoja'
          },
          {
            'id_voivodeship': '12',
            'id_county': '15',
            'id_commune': '09',
            'name': 'Zembrzyce'
          }
        ]
      },
      {
        'id_voivodeship': '12',
        'id_county': '16',
        'name': 'tarnowski',
        'communes': [
          {
            'id_voivodeship': '12',
            'id_county': '16',
            'id_commune': '01',
            'name': 'Ciężkowice'
          },
          {
            'id_voivodeship': '12',
            'id_county': '16',
            'id_commune': '02',
            'name': 'Gromnik'
          },
          {
            'id_voivodeship': '12',
            'id_county': '16',
            'id_commune': '03',
            'name': 'Lisia Góra'
          },
          {
            'id_voivodeship': '12',
            'id_county': '16',
            'id_commune': '04',
            'name': 'Pleśna'
          },
          {
            'id_voivodeship': '12',
            'id_county': '16',
            'id_commune': '05',
            'name': 'Radłów'
          },
          {
            'id_voivodeship': '12',
            'id_county': '16',
            'id_commune': '06',
            'name': 'Ryglice'
          },
          {
            'id_voivodeship': '12',
            'id_county': '16',
            'id_commune': '07',
            'name': 'Rzepiennik Strzyżewski'
          },
          {
            'id_voivodeship': '12',
            'id_county': '16',
            'id_commune': '08',
            'name': 'Skrzyszów'
          },
          {
            'id_voivodeship': '12',
            'id_county': '16',
            'id_commune': '09',
            'name': 'Tarnów'
          },
          {
            'id_voivodeship': '12',
            'id_county': '16',
            'id_commune': '10',
            'name': 'Tuchów'
          },
          {
            'id_voivodeship': '12',
            'id_county': '16',
            'id_commune': '11',
            'name': 'Wierzchosławice'
          },
          {
            'id_voivodeship': '12',
            'id_county': '16',
            'id_commune': '12',
            'name': 'Wietrzychowice'
          },
          {
            'id_voivodeship': '12',
            'id_county': '16',
            'id_commune': '13',
            'name': 'Wojnicz'
          },
          {
            'id_voivodeship': '12',
            'id_county': '16',
            'id_commune': '14',
            'name': 'Zakliczyn'
          },
          {
            'id_voivodeship': '12',
            'id_county': '16',
            'id_commune': '15',
            'name': 'Żabno'
          },
          {
            'id_voivodeship': '12',
            'id_county': '16',
            'id_commune': '16',
            'name': 'Szerzyny'
          }
        ]
      },
      {
        'id_voivodeship': '12',
        'id_county': '17',
        'name': 'tatrzański',
        'communes': [
          {
            'id_voivodeship': '12',
            'id_county': '17',
            'id_commune': '01',
            'name': 'Zakopane'
          },
          {
            'id_voivodeship': '12',
            'id_county': '17',
            'id_commune': '02',
            'name': 'Biały Dunajec'
          },
          {
            'id_voivodeship': '12',
            'id_county': '17',
            'id_commune': '03',
            'name': 'Bukowina Tatrzańska'
          },
          {
            'id_voivodeship': '12',
            'id_county': '17',
            'id_commune': '04',
            'name': 'Kościelisko'
          },
          {
            'id_voivodeship': '12',
            'id_county': '17',
            'id_commune': '05',
            'name': 'Poronin'
          }
        ]
      },
      {
        'id_voivodeship': '12',
        'id_county': '18',
        'name': 'wadowicki',
        'communes': [
          {
            'id_voivodeship': '12',
            'id_county': '18',
            'id_commune': '01',
            'name': 'Andrychów'
          },
          {
            'id_voivodeship': '12',
            'id_county': '18',
            'id_commune': '02',
            'name': 'Brzeźnica'
          },
          {
            'id_voivodeship': '12',
            'id_county': '18',
            'id_commune': '03',
            'name': 'Kalwaria Zebrzydowska'
          },
          {
            'id_voivodeship': '12',
            'id_county': '18',
            'id_commune': '04',
            'name': 'Lanckorona'
          },
          {
            'id_voivodeship': '12',
            'id_county': '18',
            'id_commune': '05',
            'name': 'Mucharz'
          },
          {
            'id_voivodeship': '12',
            'id_county': '18',
            'id_commune': '06',
            'name': 'Spytkowice'
          },
          {
            'id_voivodeship': '12',
            'id_county': '18',
            'id_commune': '07',
            'name': 'Stryszów'
          },
          {
            'id_voivodeship': '12',
            'id_county': '18',
            'id_commune': '08',
            'name': 'Tomice'
          },
          {
            'id_voivodeship': '12',
            'id_county': '18',
            'id_commune': '09',
            'name': 'Wadowice'
          },
          {
            'id_voivodeship': '12',
            'id_county': '18',
            'id_commune': '10',
            'name': 'Wieprz'
          }
        ]
      },
      {
        'id_voivodeship': '12',
        'id_county': '19',
        'name': 'wielicki',
        'communes': [
          {
            'id_voivodeship': '12',
            'id_county': '19',
            'id_commune': '01',
            'name': 'Biskupice'
          },
          {
            'id_voivodeship': '12',
            'id_county': '19',
            'id_commune': '02',
            'name': 'Gdów'
          },
          {
            'id_voivodeship': '12',
            'id_county': '19',
            'id_commune': '03',
            'name': 'Kłaj'
          },
          {
            'id_voivodeship': '12',
            'id_county': '19',
            'id_commune': '04',
            'name': 'Niepołomice'
          },
          {
            'id_voivodeship': '12',
            'id_county': '19',
            'id_commune': '05',
            'name': 'Wieliczka'
          }
        ]
      },
      {
        'id_voivodeship': '12',
        'id_county': '61',
        'name': 'Kraków',
        'communes': [
          {
            'id_voivodeship': '12',
            'id_county': '61',
            'id_commune': '01',
            'name': 'Kraków'
          },
        ]
      },
      {
        'id_voivodeship': '12',
        'id_county': '62',
        'name': 'Nowy Sącz',
        'communes': [
          {
            'id_voivodeship': '12',
            'id_county': '62',
            'id_commune': '01',
            'name': 'Nowy Sącz'
          }
        ]
      },
      {
        'id_voivodeship': '12',
        'id_county': '63',
        'name': 'Tarnów',
        'communes': [
          {
            'id_voivodeship': '12',
            'id_county': '63',
            'id_commune': '01',
            'name': 'Tarnów'
          }
        ]
      }
    ]
  },
  {
    'id_voivodeship': '14',
    'name': 'MAZOWIECKIE',
    'counties': [
      {
        'id_voivodeship': '14',
        'id_county': '01',
        'name': 'białobrzeski',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '01',
            'id_commune': '01',
            'name': 'Białobrzegi'
          },
          {
            'id_voivodeship': '14',
            'id_county': '01',
            'id_commune': '02',
            'name': 'Promna'
          },
          {
            'id_voivodeship': '14',
            'id_county': '01',
            'id_commune': '03',
            'name': 'Radzanów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '01',
            'id_commune': '04',
            'name': 'Stara Błotnica'
          },
          {
            'id_voivodeship': '14',
            'id_county': '01',
            'id_commune': '05',
            'name': 'Stromiec'
          },
          {
            'id_voivodeship': '14',
            'id_county': '01',
            'id_commune': '06',
            'name': 'Wyśmierzyce'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '02',
        'name': 'ciechanowski',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '02',
            'id_commune': '01',
            'name': 'Ciechanów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '02',
            'id_commune': '03',
            'name': 'Glinojeck'
          },
          {
            'id_voivodeship': '14',
            'id_county': '02',
            'id_commune': '04',
            'name': 'Gołymin-Ośrodek'
          },
          {
            'id_voivodeship': '14',
            'id_county': '02',
            'id_commune': '05',
            'name': 'Grudusk'
          },
          {
            'id_voivodeship': '14',
            'id_county': '02',
            'id_commune': '06',
            'name': 'Ojrzeń'
          },
          {
            'id_voivodeship': '14',
            'id_county': '02',
            'id_commune': '07',
            'name': 'Opinogóra Górna'
          },
          {
            'id_voivodeship': '14',
            'id_county': '02',
            'id_commune': '08',
            'name': 'Regimin'
          },
          {
            'id_voivodeship': '14',
            'id_county': '02',
            'id_commune': '09',
            'name': 'Sońsk'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '03',
        'name': 'garwoliński',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '03',
            'id_commune': '01',
            'name': 'Garwolin'
          },
          {
            'id_voivodeship': '14',
            'id_county': '03',
            'id_commune': '02',
            'name': 'Łaskarzew'
          },
          {
            'id_voivodeship': '14',
            'id_county': '03',
            'id_commune': '03',
            'name': 'Borowie'
          },
          {
            'id_voivodeship': '14',
            'id_county': '03',
            'id_commune': '05',
            'name': 'Górzno'
          },
          {
            'id_voivodeship': '14',
            'id_county': '03',
            'id_commune': '07',
            'name': 'Maciejowice'
          },
          {
            'id_voivodeship': '14',
            'id_county': '03',
            'id_commune': '08',
            'name': 'Miastków Kościelny'
          },
          {
            'id_voivodeship': '14',
            'id_county': '03',
            'id_commune': '09',
            'name': 'Parysów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '03',
            'id_commune': '10',
            'name': 'Pilawa'
          },
          {
            'id_voivodeship': '14',
            'id_county': '03',
            'id_commune': '11',
            'name': 'Sobolew'
          },
          {
            'id_voivodeship': '14',
            'id_county': '03',
            'id_commune': '12',
            'name': 'Trojanów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '03',
            'id_commune': '13',
            'name': 'Wilga'
          },
          {
            'id_voivodeship': '14',
            'id_county': '03',
            'id_commune': '14',
            'name': 'Żelechów'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '04',
        'name': 'gostyniński',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '04',
            'id_commune': '01',
            'name': 'Gostynin'
          },
          {
            'id_voivodeship': '14',
            'id_county': '04',
            'id_commune': '03',
            'name': 'Pacyna'
          },
          {
            'id_voivodeship': '14',
            'id_county': '04',
            'id_commune': '04',
            'name': 'Sanniki'
          },
          {
            'id_voivodeship': '14',
            'id_county': '04',
            'id_commune': '05',
            'name': 'Szczawin Kościelny'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '05',
        'name': 'grodziski',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '05',
            'id_commune': '01',
            'name': 'Milanówek'
          },
          {
            'id_voivodeship': '14',
            'id_county': '05',
            'id_commune': '02',
            'name': 'Podkowa Leśna'
          },
          {
            'id_voivodeship': '14',
            'id_county': '05',
            'id_commune': '03',
            'name': 'Baranów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '05',
            'id_commune': '04',
            'name': 'Grodzisk Mazowiecki'
          },
          {
            'id_voivodeship': '14',
            'id_county': '05',
            'id_commune': '05',
            'name': 'Jaktorów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '05',
            'id_commune': '06',
            'name': 'Żabia Wola'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '06',
        'name': 'grójecki',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '06',
            'id_commune': '01',
            'name': 'Belsk Duży'
          },
          {
            'id_voivodeship': '14',
            'id_county': '06',
            'id_commune': '02',
            'name': 'Błędów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '06',
            'id_commune': '03',
            'name': 'Chynów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '06',
            'id_commune': '04',
            'name': 'Goszczyn'
          },
          {
            'id_voivodeship': '14',
            'id_county': '06',
            'id_commune': '05',
            'name': 'Grójec'
          },
          {
            'id_voivodeship': '14',
            'id_county': '06',
            'id_commune': '06',
            'name': 'Jasieniec'
          },
          {
            'id_voivodeship': '14',
            'id_county': '06',
            'id_commune': '07',
            'name': 'Mogielnica'
          },
          {
            'id_voivodeship': '14',
            'id_county': '06',
            'id_commune': '08',
            'name': 'Nowe Miasto nad Pilicą'
          },
          {
            'id_voivodeship': '14',
            'id_county': '06',
            'id_commune': '09',
            'name': 'Pniewy'
          },
          {
            'id_voivodeship': '14',
            'id_county': '06',
            'id_commune': '11',
            'name': 'Warka'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '07',
        'name': 'kozienicki',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '07',
            'id_commune': '01',
            'name': 'Garbatka-Letnisko'
          },
          {
            'id_voivodeship': '14',
            'id_county': '07',
            'id_commune': '02',
            'name': 'Głowaczów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '07',
            'id_commune': '03',
            'name': 'Gniewoszów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '07',
            'id_commune': '04',
            'name': 'Grabów nad Pilicą'
          },
          {
            'id_voivodeship': '14',
            'id_county': '07',
            'id_commune': '05',
            'name': 'Kozienice'
          },
          {
            'id_voivodeship': '14',
            'id_county': '07',
            'id_commune': '06',
            'name': 'Magnuszew'
          },
          {
            'id_voivodeship': '14',
            'id_county': '07',
            'id_commune': '07',
            'name': 'Sieciechów'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '08',
        'name': 'legionowski',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '08',
            'id_commune': '01',
            'name': 'Legionowo'
          },
          {
            'id_voivodeship': '14',
            'id_county': '08',
            'id_commune': '02',
            'name': 'Jabłonna'
          },
          {
            'id_voivodeship': '14',
            'id_county': '08',
            'id_commune': '03',
            'name': 'Nieporęt'
          },
          {
            'id_voivodeship': '14',
            'id_county': '08',
            'id_commune': '04',
            'name': 'Serock'
          },
          {
            'id_voivodeship': '14',
            'id_county': '08',
            'id_commune': '05',
            'name': 'Wieliszew'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '09',
        'name': 'lipski',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '09',
            'id_commune': '01',
            'name': 'Chotcza'
          },
          {
            'id_voivodeship': '14',
            'id_county': '09',
            'id_commune': '02',
            'name': 'Ciepielów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '09',
            'id_commune': '03',
            'name': 'Lipsko'
          },
          {
            'id_voivodeship': '14',
            'id_county': '09',
            'id_commune': '04',
            'name': 'Rzeczniów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '09',
            'id_commune': '05',
            'name': 'Sienno'
          },
          {
            'id_voivodeship': '14',
            'id_county': '09',
            'id_commune': '06',
            'name': 'Solec nad Wisłą'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '10',
        'name': 'łosicki',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '10',
            'id_commune': '01',
            'name': 'Huszlew'
          },
          {
            'id_voivodeship': '14',
            'id_county': '10',
            'id_commune': '02',
            'name': 'Łosice'
          },
          {
            'id_voivodeship': '14',
            'id_county': '10',
            'id_commune': '03',
            'name': 'Olszanka'
          },
          {
            'id_voivodeship': '14',
            'id_county': '10',
            'id_commune': '04',
            'name': 'Platerów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '10',
            'id_commune': '05',
            'name': 'Sarnaki'
          },
          {
            'id_voivodeship': '14',
            'id_county': '10',
            'id_commune': '06',
            'name': 'Stara Kornica'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '11',
        'name': 'makowski',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '11',
            'id_commune': '01',
            'name': 'Maków Mazowiecki'
          },
          {
            'id_voivodeship': '14',
            'id_county': '11',
            'id_commune': '02',
            'name': 'Czerwonka'
          },
          {
            'id_voivodeship': '14',
            'id_county': '11',
            'id_commune': '03',
            'name': 'Karniewo'
          },
          {
            'id_voivodeship': '14',
            'id_county': '11',
            'id_commune': '04',
            'name': 'Krasnosielc'
          },
          {
            'id_voivodeship': '14',
            'id_county': '11',
            'id_commune': '05',
            'name': 'Młynarze'
          },
          {
            'id_voivodeship': '14',
            'id_county': '11',
            'id_commune': '06',
            'name': 'Płoniawy-Bramura'
          },
          {
            'id_voivodeship': '14',
            'id_county': '11',
            'id_commune': '07',
            'name': 'Różan'
          },
          {
            'id_voivodeship': '14',
            'id_county': '11',
            'id_commune': '08',
            'name': 'Rzewnie'
          },
          {
            'id_voivodeship': '14',
            'id_county': '11',
            'id_commune': '09',
            'name': 'Sypniewo'
          },
          {
            'id_voivodeship': '14',
            'id_county': '11',
            'id_commune': '10',
            'name': 'Szelków'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '12',
        'name': 'miński',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '12',
            'id_commune': '01',
            'name': 'Mińsk Mazowiecki'
          },
          {
            'id_voivodeship': '14',
            'id_county': '12',
            'id_commune': '04',
            'name': 'Cegłów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '12',
            'id_commune': '05',
            'name': 'Dębe Wielkie'
          },
          {
            'id_voivodeship': '14',
            'id_county': '12',
            'id_commune': '06',
            'name': 'Dobre'
          },
          {
            'id_voivodeship': '14',
            'id_county': '12',
            'id_commune': '07',
            'name': 'Halinów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '12',
            'id_commune': '08',
            'name': 'Jakubów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '12',
            'id_commune': '09',
            'name': 'Kałuszyn'
          },
          {
            'id_voivodeship': '14',
            'id_county': '12',
            'id_commune': '10',
            'name': 'Latowicz'
          },
          {
            'id_voivodeship': '14',
            'id_county': '12',
            'id_commune': '12',
            'name': 'Mrozy'
          },
          {
            'id_voivodeship': '14',
            'id_county': '12',
            'id_commune': '13',
            'name': 'Siennica'
          },
          {
            'id_voivodeship': '14',
            'id_county': '12',
            'id_commune': '14',
            'name': 'Stanisławów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '12',
            'id_commune': '15',
            'name': 'Sulejówek'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '13',
        'name': 'mławski',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '13',
            'id_commune': '01',
            'name': 'Mława'
          },
          {
            'id_voivodeship': '14',
            'id_county': '13',
            'id_commune': '02',
            'name': 'Dzierzgowo'
          },
          {
            'id_voivodeship': '14',
            'id_county': '13',
            'id_commune': '03',
            'name': 'Lipowiec Kościelny'
          },
          {
            'id_voivodeship': '14',
            'id_county': '13',
            'id_commune': '04',
            'name': 'Radzanów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '13',
            'id_commune': '05',
            'name': 'Strzegowo'
          },
          {
            'id_voivodeship': '14',
            'id_county': '13',
            'id_commune': '06',
            'name': 'Stupsk'
          },
          {
            'id_voivodeship': '14',
            'id_county': '13',
            'id_commune': '07',
            'name': 'Szreńsk'
          },
          {
            'id_voivodeship': '14',
            'id_county': '13',
            'id_commune': '08',
            'name': 'Szydłowo'
          },
          {
            'id_voivodeship': '14',
            'id_county': '13',
            'id_commune': '09',
            'name': 'Wieczfnia Kościelna'
          },
          {
            'id_voivodeship': '14',
            'id_county': '13',
            'id_commune': '10',
            'name': 'Wiśniewo'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '14',
        'name': 'nowodworski',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '14',
            'id_commune': '01',
            'name': 'Nowy Dwór Mazowiecki'
          },
          {
            'id_voivodeship': '14',
            'id_county': '14',
            'id_commune': '02',
            'name': 'Czosnów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '14',
            'id_commune': '03',
            'name': 'Leoncin'
          },
          {
            'id_voivodeship': '14',
            'id_county': '14',
            'id_commune': '04',
            'name': 'Nasielsk'
          },
          {
            'id_voivodeship': '14',
            'id_county': '14',
            'id_commune': '05',
            'name': 'Pomiechówek'
          },
          {
            'id_voivodeship': '14',
            'id_county': '14',
            'id_commune': '06',
            'name': 'Zakroczym'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '15',
        'name': 'ostrołęcki',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '15',
            'id_commune': '01',
            'name': 'Baranowo'
          },
          {
            'id_voivodeship': '14',
            'id_county': '15',
            'id_commune': '02',
            'name': 'Czarnia'
          },
          {
            'id_voivodeship': '14',
            'id_county': '15',
            'id_commune': '03',
            'name': 'Czerwin'
          },
          {
            'id_voivodeship': '14',
            'id_county': '15',
            'id_commune': '04',
            'name': 'Goworowo'
          },
          {
            'id_voivodeship': '14',
            'id_county': '15',
            'id_commune': '05',
            'name': 'Kadzidło'
          },
          {
            'id_voivodeship': '14',
            'id_county': '15',
            'id_commune': '06',
            'name': 'Lelis'
          },
          {
            'id_voivodeship': '14',
            'id_county': '15',
            'id_commune': '07',
            'name': 'Łyse'
          },
          {
            'id_voivodeship': '14',
            'id_county': '15',
            'id_commune': '08',
            'name': 'Myszyniec'
          },
          {
            'id_voivodeship': '14',
            'id_county': '15',
            'id_commune': '09',
            'name': 'Olszewo-Borki'
          },
          {
            'id_voivodeship': '14',
            'id_county': '15',
            'id_commune': '10',
            'name': 'Rzekuń'
          },
          {
            'id_voivodeship': '14',
            'id_county': '15',
            'id_commune': '11',
            'name': 'Troszyn'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '16',
        'name': 'ostrowski',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '16',
            'id_commune': '01',
            'name': 'Ostrów Mazowiecka'
          },
          {
            'id_voivodeship': '14',
            'id_county': '16',
            'id_commune': '02',
            'name': 'Andrzejewo'
          },
          {
            'id_voivodeship': '14',
            'id_county': '16',
            'id_commune': '03',
            'name': 'Boguty-Pianki'
          },
          {
            'id_voivodeship': '14',
            'id_county': '16',
            'id_commune': '04',
            'name': 'Brok'
          },
          {
            'id_voivodeship': '14',
            'id_county': '16',
            'id_commune': '05',
            'name': 'Małkinia Górna'
          },
          {
            'id_voivodeship': '14',
            'id_county': '16',
            'id_commune': '06',
            'name': 'Nur'
          },
          {
            'id_voivodeship': '14',
            'id_county': '16',
            'id_commune': '08',
            'name': 'Stary Lubotyń'
          },
          {
            'id_voivodeship': '14',
            'id_county': '16',
            'id_commune': '09',
            'name': 'Szulborze Wielkie'
          },
          {
            'id_voivodeship': '14',
            'id_county': '16',
            'id_commune': '10',
            'name': 'Wąsewo'
          },
          {
            'id_voivodeship': '14',
            'id_county': '16',
            'id_commune': '11',
            'name': 'Zaręby Kościelne'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '17',
        'name': 'otwocki',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '17',
            'id_commune': '01',
            'name': 'Józefów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '17',
            'id_commune': '02',
            'name': 'Otwock'
          },
          {
            'id_voivodeship': '14',
            'id_county': '17',
            'id_commune': '03',
            'name': 'Celestynów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '17',
            'id_commune': '04',
            'name': 'Karczew'
          },
          {
            'id_voivodeship': '14',
            'id_county': '17',
            'id_commune': '05',
            'name': 'Kołbiel'
          },
          {
            'id_voivodeship': '14',
            'id_county': '17',
            'id_commune': '06',
            'name': 'Osieck'
          },
          {
            'id_voivodeship': '14',
            'id_county': '17',
            'id_commune': '07',
            'name': 'Sobienie-Jeziory'
          },
          {
            'id_voivodeship': '14',
            'id_county': '17',
            'id_commune': '08',
            'name': 'Wiązowna'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '18',
        'name': 'piaseczyński',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '18',
            'id_commune': '01',
            'name': 'Góra Kalwaria'
          },
          {
            'id_voivodeship': '14',
            'id_county': '18',
            'id_commune': '02',
            'name': 'Konstancin-Jeziorna'
          },
          {
            'id_voivodeship': '14',
            'id_county': '18',
            'id_commune': '03',
            'name': 'Lesznowola'
          },
          {
            'id_voivodeship': '14',
            'id_county': '18',
            'id_commune': '04',
            'name': 'Piaseczno'
          },
          {
            'id_voivodeship': '14',
            'id_county': '18',
            'id_commune': '05',
            'name': 'Prażmów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '18',
            'id_commune': '06',
            'name': 'Tarczyn'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '19',
        'name': 'płocki',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '19',
            'id_commune': '01',
            'name': 'Bielsk'
          },
          {
            'id_voivodeship': '14',
            'id_county': '19',
            'id_commune': '02',
            'name': 'Bodzanów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '19',
            'id_commune': '03',
            'name': 'Brudzeń Duży'
          },
          {
            'id_voivodeship': '14',
            'id_county': '19',
            'id_commune': '04',
            'name': 'Bulkowo'
          },
          {
            'id_voivodeship': '14',
            'id_county': '19',
            'id_commune': '05',
            'name': 'Drobin'
          },
          {
            'id_voivodeship': '14',
            'id_county': '19',
            'id_commune': '06',
            'name': 'Gąbin'
          },
          {
            'id_voivodeship': '14',
            'id_county': '19',
            'id_commune': '07',
            'name': 'Łąck'
          },
          {
            'id_voivodeship': '14',
            'id_county': '19',
            'id_commune': '08',
            'name': 'Mała Wieś'
          },
          {
            'id_voivodeship': '14',
            'id_county': '19',
            'id_commune': '09',
            'name': 'Nowy Duninów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '19',
            'id_commune': '10',
            'name': 'Radzanowo'
          },
          {
            'id_voivodeship': '14',
            'id_county': '19',
            'id_commune': '11',
            'name': 'Słubice'
          },
          {
            'id_voivodeship': '14',
            'id_county': '19',
            'id_commune': '12',
            'name': 'Słupno'
          },
          {
            'id_voivodeship': '14',
            'id_county': '19',
            'id_commune': '13',
            'name': 'Stara Biała'
          },
          {
            'id_voivodeship': '14',
            'id_county': '19',
            'id_commune': '14',
            'name': 'Staroźreby'
          },
          {
            'id_voivodeship': '14',
            'id_county': '19',
            'id_commune': '15',
            'name': 'Wyszogród'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '20',
        'name': 'płoński',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '20',
            'id_commune': '01',
            'name': 'Płońsk'
          },
          {
            'id_voivodeship': '14',
            'id_county': '20',
            'id_commune': '02',
            'name': 'Raciąż'
          },
          {
            'id_voivodeship': '14',
            'id_county': '20',
            'id_commune': '03',
            'name': 'Baboszewo'
          },
          {
            'id_voivodeship': '14',
            'id_county': '20',
            'id_commune': '04',
            'name': 'Czerwińsk nad Wisłą'
          },
          {
            'id_voivodeship': '14',
            'id_county': '20',
            'id_commune': '05',
            'name': 'Dzierzążnia'
          },
          {
            'id_voivodeship': '14',
            'id_county': '20',
            'id_commune': '06',
            'name': 'Joniec'
          },
          {
            'id_voivodeship': '14',
            'id_county': '20',
            'id_commune': '07',
            'name': 'Naruszewo'
          },
          {
            'id_voivodeship': '14',
            'id_county': '20',
            'id_commune': '08',
            'name': 'Nowe Miasto'
          },
          {
            'id_voivodeship': '14',
            'id_county': '20',
            'id_commune': '11',
            'name': 'Sochocin'
          },
          {
            'id_voivodeship': '14',
            'id_county': '20',
            'id_commune': '12',
            'name': 'Załuski'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '21',
        'name': 'pruszkowski',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '21',
            'id_commune': '01',
            'name': 'Piastów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '21',
            'id_commune': '02',
            'name': 'Pruszków'
          },
          {
            'id_voivodeship': '14',
            'id_county': '21',
            'id_commune': '03',
            'name': 'Brwinów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '21',
            'id_commune': '04',
            'name': 'Michałowice'
          },
          {
            'id_voivodeship': '14',
            'id_county': '21',
            'id_commune': '05',
            'name': 'Nadarzyn'
          },
          {
            'id_voivodeship': '14',
            'id_county': '21',
            'id_commune': '06',
            'name': 'Raszyn'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '22',
        'name': 'przasnyski',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '22',
            'id_commune': '01',
            'name': 'Przasnysz'
          },
          {
            'id_voivodeship': '14',
            'id_county': '22',
            'id_commune': '02',
            'name': 'Chorzele'
          },
          {
            'id_voivodeship': '14',
            'id_county': '22',
            'id_commune': '03',
            'name': 'Czernice Borowe'
          },
          {
            'id_voivodeship': '14',
            'id_county': '22',
            'id_commune': '04',
            'name': 'Jednorożec'
          },
          {
            'id_voivodeship': '14',
            'id_county': '22',
            'id_commune': '05',
            'name': 'Krasne'
          },
          {
            'id_voivodeship': '14',
            'id_county': '22',
            'id_commune': '06',
            'name': 'Krzynowłoga Mała'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '23',
        'name': 'przysuski',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '23',
            'id_commune': '01',
            'name': 'Borkowice'
          },
          {
            'id_voivodeship': '14',
            'id_county': '23',
            'id_commune': '02',
            'name': 'Gielniów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '23',
            'id_commune': '03',
            'name': 'Klwów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '23',
            'id_commune': '04',
            'name': 'Odrzywół'
          },
          {
            'id_voivodeship': '14',
            'id_county': '23',
            'id_commune': '05',
            'name': 'Potworów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '23',
            'id_commune': '06',
            'name': 'Przysucha'
          },
          {
            'id_voivodeship': '14',
            'id_county': '23',
            'id_commune': '07',
            'name': 'Rusinów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '23',
            'id_commune': '08',
            'name': 'Wieniawa'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '24',
        'name': 'pułtuski',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '24',
            'id_commune': '01',
            'name': 'Gzy'
          },
          {
            'id_voivodeship': '14',
            'id_county': '24',
            'id_commune': '02',
            'name': 'Obryte'
          },
          {
            'id_voivodeship': '14',
            'id_county': '24',
            'id_commune': '03',
            'name': 'Pokrzywnica'
          },
          {
            'id_voivodeship': '14',
            'id_county': '24',
            'id_commune': '04',
            'name': 'Pułtusk'
          },
          {
            'id_voivodeship': '14',
            'id_county': '24',
            'id_commune': '05',
            'name': 'Świercze'
          },
          {
            'id_voivodeship': '14',
            'id_county': '24',
            'id_commune': '06',
            'name': 'Winnica'
          },
          {
            'id_voivodeship': '14',
            'id_county': '24',
            'id_commune': '07',
            'name': 'Zatory'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '25',
        'name': 'radomski',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '25',
            'id_commune': '01',
            'name': 'Pionki'
          },
          {
            'id_voivodeship': '14',
            'id_county': '25',
            'id_commune': '02',
            'name': 'Gózd'
          },
          {
            'id_voivodeship': '14',
            'id_county': '25',
            'id_commune': '03',
            'name': 'Iłża'
          },
          {
            'id_voivodeship': '14',
            'id_county': '25',
            'id_commune': '04',
            'name': 'Jastrzębia'
          },
          {
            'id_voivodeship': '14',
            'id_county': '25',
            'id_commune': '05',
            'name': 'Jedlińsk'
          },
          {
            'id_voivodeship': '14',
            'id_county': '25',
            'id_commune': '06',
            'name': 'Jedlnia-Letnisko'
          },
          {
            'id_voivodeship': '14',
            'id_county': '25',
            'id_commune': '07',
            'name': 'Kowala'
          },
          {
            'id_voivodeship': '14',
            'id_county': '25',
            'id_commune': '09',
            'name': 'Przytyk'
          },
          {
            'id_voivodeship': '14',
            'id_county': '25',
            'id_commune': '10',
            'name': 'Skaryszew'
          },
          {
            'id_voivodeship': '14',
            'id_county': '25',
            'id_commune': '11',
            'name': 'Wierzbica'
          },
          {
            'id_voivodeship': '14',
            'id_county': '25',
            'id_commune': '12',
            'name': 'Wolanów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '25',
            'id_commune': '13',
            'name': 'Zakrzew'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '26',
        'name': 'siedlecki',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '26',
            'id_commune': '01',
            'name': 'Domanice'
          },
          {
            'id_voivodeship': '14',
            'id_county': '26',
            'id_commune': '02',
            'name': 'Korczew'
          },
          {
            'id_voivodeship': '14',
            'id_county': '26',
            'id_commune': '03',
            'name': 'Kotuń'
          },
          {
            'id_voivodeship': '14',
            'id_county': '26',
            'id_commune': '04',
            'name': 'Mokobody'
          },
          {
            'id_voivodeship': '14',
            'id_county': '26',
            'id_commune': '05',
            'name': 'Mordy'
          },
          {
            'id_voivodeship': '14',
            'id_county': '26',
            'id_commune': '06',
            'name': 'Paprotnia'
          },
          {
            'id_voivodeship': '14',
            'id_county': '26',
            'id_commune': '07',
            'name': 'Przesmyki'
          },
          {
            'id_voivodeship': '14',
            'id_county': '26',
            'id_commune': '08',
            'name': 'Siedlce'
          },
          {
            'id_voivodeship': '14',
            'id_county': '26',
            'id_commune': '09',
            'name': 'Skórzec'
          },
          {
            'id_voivodeship': '14',
            'id_county': '26',
            'id_commune': '10',
            'name': 'Suchożebry'
          },
          {
            'id_voivodeship': '14',
            'id_county': '26',
            'id_commune': '11',
            'name': 'Wiśniew'
          },
          {
            'id_voivodeship': '14',
            'id_county': '26',
            'id_commune': '12',
            'name': 'Wodynie'
          },
          {
            'id_voivodeship': '14',
            'id_county': '26',
            'id_commune': '13',
            'name': 'Zbuczyn'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '27',
        'name': 'sierpecki',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '27',
            'id_commune': '01',
            'name': 'Sierpc'
          },
          {
            'id_voivodeship': '14',
            'id_county': '27',
            'id_commune': '02',
            'name': 'Gozdowo'
          },
          {
            'id_voivodeship': '14',
            'id_county': '27',
            'id_commune': '03',
            'name': 'Mochowo'
          },
          {
            'id_voivodeship': '14',
            'id_county': '27',
            'id_commune': '04',
            'name': 'Rościszewo'
          },
          {
            'id_voivodeship': '14',
            'id_county': '27',
            'id_commune': '06',
            'name': 'Szczutowo'
          },
          {
            'id_voivodeship': '14',
            'id_county': '27',
            'id_commune': '07',
            'name': 'Zawidz'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '28',
        'name': 'sochaczewski',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '28',
            'id_commune': '01',
            'name': 'Sochaczew'
          },
          {
            'id_voivodeship': '14',
            'id_county': '28',
            'id_commune': '02',
            'name': 'Brochów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '28',
            'id_commune': '03',
            'name': 'Iłów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '28',
            'id_commune': '04',
            'name': 'Młodzieszyn'
          },
          {
            'id_voivodeship': '14',
            'id_county': '28',
            'id_commune': '05',
            'name': 'Nowa Sucha'
          },
          {
            'id_voivodeship': '14',
            'id_county': '28',
            'id_commune': '06',
            'name': 'Rybno'
          },
          {
            'id_voivodeship': '14',
            'id_county': '28',
            'id_commune': '08',
            'name': 'Teresin'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '29',
        'name': 'sokołowski',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '29',
            'id_commune': '01',
            'name': 'Sokołów Podlaski'
          },
          {
            'id_voivodeship': '14',
            'id_county': '29',
            'id_commune': '02',
            'name': 'Bielany'
          },
          {
            'id_voivodeship': '14',
            'id_county': '29',
            'id_commune': '03',
            'name': 'Ceranów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '29',
            'id_commune': '04',
            'name': 'Jabłonna Lacka'
          },
          {
            'id_voivodeship': '14',
            'id_county': '29',
            'id_commune': '05',
            'name': 'Kosów Lacki'
          },
          {
            'id_voivodeship': '14',
            'id_county': '29',
            'id_commune': '06',
            'name': 'Repki'
          },
          {
            'id_voivodeship': '14',
            'id_county': '29',
            'id_commune': '07',
            'name': 'Sabnie'
          },
          {
            'id_voivodeship': '14',
            'id_county': '29',
            'id_commune': '09',
            'name': 'Sterdyń'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '30',
        'name': 'szydłowiecki',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '30',
            'id_commune': '01',
            'name': 'Chlewiska'
          },
          {
            'id_voivodeship': '14',
            'id_county': '30',
            'id_commune': '02',
            'name': 'Jastrząb'
          },
          {
            'id_voivodeship': '14',
            'id_county': '30',
            'id_commune': '03',
            'name': 'Mirów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '30',
            'id_commune': '04',
            'name': 'Orońsko'
          },
          {
            'id_voivodeship': '14',
            'id_county': '30',
            'id_commune': '05',
            'name': 'Szydłowiec'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '32',
        'name': 'warszawski zachodni',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '32',
            'id_commune': '01',
            'name': 'Błonie'
          },
          {
            'id_voivodeship': '14',
            'id_county': '32',
            'id_commune': '02',
            'name': 'Izabelin'
          },
          {
            'id_voivodeship': '14',
            'id_county': '32',
            'id_commune': '03',
            'name': 'Kampinos'
          },
          {
            'id_voivodeship': '14',
            'id_county': '32',
            'id_commune': '04',
            'name': 'Leszno'
          },
          {
            'id_voivodeship': '14',
            'id_county': '32',
            'id_commune': '05',
            'name': 'Łomianki'
          },
          {
            'id_voivodeship': '14',
            'id_county': '32',
            'id_commune': '06',
            'name': 'Ożarów Mazowiecki'
          },
          {
            'id_voivodeship': '14',
            'id_county': '32',
            'id_commune': '07',
            'name': 'Stare Babice'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '33',
        'name': 'węgrowski',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '33',
            'id_commune': '01',
            'name': 'Węgrów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '33',
            'id_commune': '02',
            'name': 'Grębków'
          },
          {
            'id_voivodeship': '14',
            'id_county': '33',
            'id_commune': '03',
            'name': 'Korytnica'
          },
          {
            'id_voivodeship': '14',
            'id_county': '33',
            'id_commune': '04',
            'name': 'Liw'
          },
          {
            'id_voivodeship': '14',
            'id_county': '33',
            'id_commune': '05',
            'name': 'Łochów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '33',
            'id_commune': '06',
            'name': 'Miedzna'
          },
          {
            'id_voivodeship': '14',
            'id_county': '33',
            'id_commune': '07',
            'name': 'Sadowne'
          },
          {
            'id_voivodeship': '14',
            'id_county': '33',
            'id_commune': '08',
            'name': 'Stoczek'
          },
          {
            'id_voivodeship': '14',
            'id_county': '33',
            'id_commune': '09',
            'name': 'Wierzbno'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '34',
        'name': 'wołomiński',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '34',
            'id_commune': '01',
            'name': 'Kobyłka'
          },
          {
            'id_voivodeship': '14',
            'id_county': '34',
            'id_commune': '02',
            'name': 'Marki'
          },
          {
            'id_voivodeship': '14',
            'id_county': '34',
            'id_commune': '03',
            'name': 'Ząbki'
          },
          {
            'id_voivodeship': '14',
            'id_county': '34',
            'id_commune': '04',
            'name': 'Zielonka'
          },
          {
            'id_voivodeship': '14',
            'id_county': '34',
            'id_commune': '05',
            'name': 'Dąbrówka'
          },
          {
            'id_voivodeship': '14',
            'id_county': '34',
            'id_commune': '06',
            'name': 'Jadów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '34',
            'id_commune': '07',
            'name': 'Klembów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '34',
            'id_commune': '08',
            'name': 'Poświętne'
          },
          {
            'id_voivodeship': '14',
            'id_county': '34',
            'id_commune': '09',
            'name': 'Radzymin'
          },
          {
            'id_voivodeship': '14',
            'id_county': '34',
            'id_commune': '10',
            'name': 'Strachówka'
          },
          {
            'id_voivodeship': '14',
            'id_county': '34',
            'id_commune': '11',
            'name': 'Tłuszcz'
          },
          {
            'id_voivodeship': '14',
            'id_county': '34',
            'id_commune': '12',
            'name': 'Wołomin'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '35',
        'name': 'wyszkowski',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '35',
            'id_commune': '01',
            'name': 'Brańszczyk'
          },
          {
            'id_voivodeship': '14',
            'id_county': '35',
            'id_commune': '02',
            'name': 'Długosiodło'
          },
          {
            'id_voivodeship': '14',
            'id_county': '35',
            'id_commune': '03',
            'name': 'Rząśnik'
          },
          {
            'id_voivodeship': '14',
            'id_county': '35',
            'id_commune': '04',
            'name': 'Somianka'
          },
          {
            'id_voivodeship': '14',
            'id_county': '35',
            'id_commune': '05',
            'name': 'Wyszków'
          },
          {
            'id_voivodeship': '14',
            'id_county': '35',
            'id_commune': '06',
            'name': 'Zabrodzie'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '36',
        'name': 'zwoleński',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '36',
            'id_commune': '01',
            'name': 'Kazanów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '36',
            'id_commune': '02',
            'name': 'Policzna'
          },
          {
            'id_voivodeship': '14',
            'id_county': '36',
            'id_commune': '03',
            'name': 'Przyłęk'
          },
          {
            'id_voivodeship': '14',
            'id_county': '36',
            'id_commune': '04',
            'name': 'Tczów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '36',
            'id_commune': '05',
            'name': 'Zwoleń'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '37',
        'name': 'żuromiński',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '37',
            'id_commune': '01',
            'name': 'Bieżuń'
          },
          {
            'id_voivodeship': '14',
            'id_county': '37',
            'id_commune': '02',
            'name': 'Kuczbork-Osada'
          },
          {
            'id_voivodeship': '14',
            'id_county': '37',
            'id_commune': '03',
            'name': 'Lubowidz'
          },
          {
            'id_voivodeship': '14',
            'id_county': '37',
            'id_commune': '04',
            'name': 'Lutocin'
          },
          {
            'id_voivodeship': '14',
            'id_county': '37',
            'id_commune': '05',
            'name': 'Siemiątkowo'
          },
          {
            'id_voivodeship': '14',
            'id_county': '37',
            'id_commune': '06',
            'name': 'Żuromin'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '38',
        'name': 'żyrardowski',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '38',
            'id_commune': '01',
            'name': 'Żyrardów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '38',
            'id_commune': '02',
            'name': 'Mszczonów'
          },
          {
            'id_voivodeship': '14',
            'id_county': '38',
            'id_commune': '03',
            'name': 'Puszcza Mariańska'
          },
          {
            'id_voivodeship': '14',
            'id_county': '38',
            'id_commune': '04',
            'name': 'Radziejowice'
          },
          {
            'id_voivodeship': '14',
            'id_county': '38',
            'id_commune': '05',
            'name': 'Wiskitki'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '61',
        'name': 'Ostrołęka',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '61',
            'id_commune': '01',
            'name': 'Ostrołęka'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '62',
        'name': 'Płock',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '62',
            'id_commune': '01',
            'name': 'Płock'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '63',
        'name': 'Radom',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '63',
            'id_commune': '01',
            'name': 'Radom'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '64',
        'name': 'Siedlce',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '64',
            'id_commune': '01',
            'name': 'Siedlce'
          }
        ]
      },
      {
        'id_voivodeship': '14',
        'id_county': '65',
        'name': 'Warszawa',
        'communes': [
          {
            'id_voivodeship': '14',
            'id_county': '65',
            'id_commune': '01',
            'name': 'Warszawa'
          },
        ]
      }
    ]
  },
  {
    'id_voivodeship': '16',
    'name': 'OPOLSKIE',
    'counties': [
      {
        'id_voivodeship': '16',
        'id_county': '01',
        'name': 'brzeski',
        'communes': [
          {
            'id_voivodeship': '16',
            'id_county': '01',
            'id_commune': '01',
            'name': 'Brzeg'
          },
          {
            'id_voivodeship': '16',
            'id_county': '01',
            'id_commune': '02',
            'name': 'Skarbimierz'
          },
          {
            'id_voivodeship': '16',
            'id_county': '01',
            'id_commune': '03',
            'name': 'Grodków'
          },
          {
            'id_voivodeship': '16',
            'id_county': '01',
            'id_commune': '04',
            'name': 'Lewin Brzeski'
          },
          {
            'id_voivodeship': '16',
            'id_county': '01',
            'id_commune': '05',
            'name': 'Lubsza'
          },
          {
            'id_voivodeship': '16',
            'id_county': '01',
            'id_commune': '06',
            'name': 'Olszanka'
          }
        ]
      },
      {
        'id_voivodeship': '16',
        'id_county': '02',
        'name': 'głubczycki',
        'communes': [
          {
            'id_voivodeship': '16',
            'id_county': '02',
            'id_commune': '01',
            'name': 'Baborów'
          },
          {
            'id_voivodeship': '16',
            'id_county': '02',
            'id_commune': '02',
            'name': 'Branice'
          },
          {
            'id_voivodeship': '16',
            'id_county': '02',
            'id_commune': '03',
            'name': 'Głubczyce'
          },
          {
            'id_voivodeship': '16',
            'id_county': '02',
            'id_commune': '04',
            'name': 'Kietrz'
          }
        ]
      },
      {
        'id_voivodeship': '16',
        'id_county': '03',
        'name': 'kędzierzyńsko-kozielski',
        'communes': [
          {
            'id_voivodeship': '16',
            'id_county': '03',
            'id_commune': '01',
            'name': 'Kędzierzyn-Koźle'
          },
          {
            'id_voivodeship': '16',
            'id_county': '03',
            'id_commune': '02',
            'name': 'Bierawa'
          },
          {
            'id_voivodeship': '16',
            'id_county': '03',
            'id_commune': '03',
            'name': 'Cisek'
          },
          {
            'id_voivodeship': '16',
            'id_county': '03',
            'id_commune': '04',
            'name': 'Pawłowiczki'
          },
          {
            'id_voivodeship': '16',
            'id_county': '03',
            'id_commune': '05',
            'name': 'Polska Cerekiew'
          },
          {
            'id_voivodeship': '16',
            'id_county': '03',
            'id_commune': '06',
            'name': 'Reńska Wieś'
          }
        ]
      },
      {
        'id_voivodeship': '16',
        'id_county': '04',
        'name': 'kluczborski',
        'communes': [
          {
            'id_voivodeship': '16',
            'id_county': '04',
            'id_commune': '01',
            'name': 'Byczyna'
          },
          {
            'id_voivodeship': '16',
            'id_county': '04',
            'id_commune': '02',
            'name': 'Kluczbork'
          },
          {
            'id_voivodeship': '16',
            'id_county': '04',
            'id_commune': '03',
            'name': 'Lasowice Wielkie'
          },
          {
            'id_voivodeship': '16',
            'id_county': '04',
            'id_commune': '04',
            'name': 'Wołczyn'
          }
        ]
      },
      {
        'id_voivodeship': '16',
        'id_county': '05',
        'name': 'krapkowicki',
        'communes': [
          {
            'id_voivodeship': '16',
            'id_county': '05',
            'id_commune': '01',
            'name': 'Gogolin'
          },
          {
            'id_voivodeship': '16',
            'id_county': '05',
            'id_commune': '02',
            'name': 'Krapkowice'
          },
          {
            'id_voivodeship': '16',
            'id_county': '05',
            'id_commune': '03',
            'name': 'Strzeleczki'
          },
          {
            'id_voivodeship': '16',
            'id_county': '05',
            'id_commune': '04',
            'name': 'Walce'
          },
          {
            'id_voivodeship': '16',
            'id_county': '05',
            'id_commune': '05',
            'name': 'Zdzieszowice'
          }
        ]
      },
      {
        'id_voivodeship': '16',
        'id_county': '06',
        'name': 'namysłowski',
        'communes': [
          {
            'id_voivodeship': '16',
            'id_county': '06',
            'id_commune': '01',
            'name': 'Domaszowice'
          },
          {
            'id_voivodeship': '16',
            'id_county': '06',
            'id_commune': '02',
            'name': 'Namysłów'
          },
          {
            'id_voivodeship': '16',
            'id_county': '06',
            'id_commune': '03',
            'name': 'Pokój'
          },
          {
            'id_voivodeship': '16',
            'id_county': '06',
            'id_commune': '04',
            'name': 'Świerczów'
          },
          {
            'id_voivodeship': '16',
            'id_county': '06',
            'id_commune': '05',
            'name': 'Wilków'
          }
        ]
      },
      {
        'id_voivodeship': '16',
        'id_county': '07',
        'name': 'nyski',
        'communes': [
          {
            'id_voivodeship': '16',
            'id_county': '07',
            'id_commune': '01',
            'name': 'Głuchołazy'
          },
          {
            'id_voivodeship': '16',
            'id_county': '07',
            'id_commune': '02',
            'name': 'Kamiennik'
          },
          {
            'id_voivodeship': '16',
            'id_county': '07',
            'id_commune': '03',
            'name': 'Korfantów'
          },
          {
            'id_voivodeship': '16',
            'id_county': '07',
            'id_commune': '04',
            'name': 'Łambinowice'
          },
          {
            'id_voivodeship': '16',
            'id_county': '07',
            'id_commune': '05',
            'name': 'Nysa'
          },
          {
            'id_voivodeship': '16',
            'id_county': '07',
            'id_commune': '06',
            'name': 'Otmuchów'
          },
          {
            'id_voivodeship': '16',
            'id_county': '07',
            'id_commune': '07',
            'name': 'Paczków'
          },
          {
            'id_voivodeship': '16',
            'id_county': '07',
            'id_commune': '08',
            'name': 'Pakosławice'
          },
          {
            'id_voivodeship': '16',
            'id_county': '07',
            'id_commune': '09',
            'name': 'Skoroszyce'
          }
        ]
      },
      {
        'id_voivodeship': '16',
        'id_county': '08',
        'name': 'oleski',
        'communes': [
          {
            'id_voivodeship': '16',
            'id_county': '08',
            'id_commune': '01',
            'name': 'Dobrodzień'
          },
          {
            'id_voivodeship': '16',
            'id_county': '08',
            'id_commune': '02',
            'name': 'Gorzów Śląski'
          },
          {
            'id_voivodeship': '16',
            'id_county': '08',
            'id_commune': '03',
            'name': 'Olesno'
          },
          {
            'id_voivodeship': '16',
            'id_county': '08',
            'id_commune': '04',
            'name': 'Praszka'
          },
          {
            'id_voivodeship': '16',
            'id_county': '08',
            'id_commune': '05',
            'name': 'Radłów'
          },
          {
            'id_voivodeship': '16',
            'id_county': '08',
            'id_commune': '06',
            'name': 'Rudniki'
          },
          {
            'id_voivodeship': '16',
            'id_county': '08',
            'id_commune': '07',
            'name': 'Zębowice'
          }
        ]
      },
      {
        'id_voivodeship': '16',
        'id_county': '09',
        'name': 'opolski',
        'communes': [
          {
            'id_voivodeship': '16',
            'id_county': '09',
            'id_commune': '01',
            'name': 'Chrząstowice'
          },
          {
            'id_voivodeship': '16',
            'id_county': '09',
            'id_commune': '02',
            'name': 'Dąbrowa'
          },
          {
            'id_voivodeship': '16',
            'id_county': '09',
            'id_commune': '03',
            'name': 'Dobrzeń Wielki'
          },
          {
            'id_voivodeship': '16',
            'id_county': '09',
            'id_commune': '04',
            'name': 'Komprachcice'
          },
          {
            'id_voivodeship': '16',
            'id_county': '09',
            'id_commune': '05',
            'name': 'Łubniany'
          },
          {
            'id_voivodeship': '16',
            'id_county': '09',
            'id_commune': '06',
            'name': 'Murów'
          },
          {
            'id_voivodeship': '16',
            'id_county': '09',
            'id_commune': '07',
            'name': 'Niemodlin'
          },
          {
            'id_voivodeship': '16',
            'id_county': '09',
            'id_commune': '08',
            'name': 'Ozimek'
          },
          {
            'id_voivodeship': '16',
            'id_county': '09',
            'id_commune': '09',
            'name': 'Popielów'
          },
          {
            'id_voivodeship': '16',
            'id_county': '09',
            'id_commune': '10',
            'name': 'Prószków'
          },
          {
            'id_voivodeship': '16',
            'id_county': '09',
            'id_commune': '11',
            'name': 'Tarnów Opolski'
          },
          {
            'id_voivodeship': '16',
            'id_county': '09',
            'id_commune': '12',
            'name': 'Tułowice'
          },
          {
            'id_voivodeship': '16',
            'id_county': '09',
            'id_commune': '13',
            'name': 'Turawa'
          }
        ]
      },
      {
        'id_voivodeship': '16',
        'id_county': '10',
        'name': 'prudnicki',
        'communes': [
          {
            'id_voivodeship': '16',
            'id_county': '10',
            'id_commune': '01',
            'name': 'Biała'
          },
          {
            'id_voivodeship': '16',
            'id_county': '10',
            'id_commune': '02',
            'name': 'Głogówek'
          },
          {
            'id_voivodeship': '16',
            'id_county': '10',
            'id_commune': '03',
            'name': 'Lubrza'
          },
          {
            'id_voivodeship': '16',
            'id_county': '10',
            'id_commune': '04',
            'name': 'Prudnik'
          }
        ]
      },
      {
        'id_voivodeship': '16',
        'id_county': '11',
        'name': 'strzelecki',
        'communes': [
          {
            'id_voivodeship': '16',
            'id_county': '11',
            'id_commune': '01',
            'name': 'Izbicko'
          },
          {
            'id_voivodeship': '16',
            'id_county': '11',
            'id_commune': '02',
            'name': 'Jemielnica'
          },
          {
            'id_voivodeship': '16',
            'id_county': '11',
            'id_commune': '03',
            'name': 'Kolonowskie'
          },
          {
            'id_voivodeship': '16',
            'id_county': '11',
            'id_commune': '04',
            'name': 'Leśnica'
          },
          {
            'id_voivodeship': '16',
            'id_county': '11',
            'id_commune': '05',
            'name': 'Strzelce Opolskie'
          },
          {
            'id_voivodeship': '16',
            'id_county': '11',
            'id_commune': '06',
            'name': 'Ujazd'
          },
          {
            'id_voivodeship': '16',
            'id_county': '11',
            'id_commune': '07',
            'name': 'Zawadzkie'
          }
        ]
      },
      {
        'id_voivodeship': '16',
        'id_county': '61',
        'name': 'Opole',
        'communes': [
          {
            'id_voivodeship': '16',
            'id_county': '61',
            'id_commune': '01',
            'name': 'Opole'
          }
        ]
      }
    ]
  },
  {
    'id_voivodeship': '18',
    'name': 'PODKARPACKIE',
    'counties': [
      {
        'id_voivodeship': '18',
        'id_county': '01',
        'name': 'bieszczadzki',
        'communes': [
          {
            'id_voivodeship': '18',
            'id_county': '01',
            'id_commune': '03',
            'name': 'Czarna'
          },
          {
            'id_voivodeship': '18',
            'id_county': '01',
            'id_commune': '05',
            'name': 'Lutowiska'
          },
          {
            'id_voivodeship': '18',
            'id_county': '01',
            'id_commune': '08',
            'name': 'Ustrzyki Dolne'
          }
        ]
      },
      {
        'id_voivodeship': '18',
        'id_county': '02',
        'name': 'brzozowski',
        'communes': [
          {
            'id_voivodeship': '18',
            'id_county': '02',
            'id_commune': '01',
            'name': 'Brzozów'
          },
          {
            'id_voivodeship': '18',
            'id_county': '02',
            'id_commune': '02',
            'name': 'Domaradz'
          },
          {
            'id_voivodeship': '18',
            'id_county': '02',
            'id_commune': '03',
            'name': 'Dydnia'
          },
          {
            'id_voivodeship': '18',
            'id_county': '02',
            'id_commune': '04',
            'name': 'Haczów'
          },
          {
            'id_voivodeship': '18',
            'id_county': '02',
            'id_commune': '05',
            'name': 'Jasienica Rosielna'
          },
          {
            'id_voivodeship': '18',
            'id_county': '02',
            'id_commune': '06',
            'name': 'Nozdrzec'
          }
        ]
      },
      {
        'id_voivodeship': '18',
        'id_county': '03',
        'name': 'dębicki',
        'communes': [
          {
            'id_voivodeship': '18',
            'id_county': '03',
            'id_commune': '01',
            'name': 'Dębica'
          },
          {
            'id_voivodeship': '18',
            'id_county': '03',
            'id_commune': '02',
            'name': 'Brzostek'
          },
          {
            'id_voivodeship': '18',
            'id_county': '03',
            'id_commune': '03',
            'name': 'Czarna'
          },
          {
            'id_voivodeship': '18',
            'id_county': '03',
            'id_commune': '05',
            'name': 'Jodłowa'
          },
          {
            'id_voivodeship': '18',
            'id_county': '03',
            'id_commune': '06',
            'name': 'Pilzno'
          },
          {
            'id_voivodeship': '18',
            'id_county': '03',
            'id_commune': '07',
            'name': 'Żyraków'
          }
        ]
      },
      {
        'id_voivodeship': '18',
        'id_county': '04',
        'name': 'jarosławski',
        'communes': [
          {
            'id_voivodeship': '18',
            'id_county': '04',
            'id_commune': '01',
            'name': 'Jarosław'
          },
          {
            'id_voivodeship': '18',
            'id_county': '04',
            'id_commune': '02',
            'name': 'Radymno'
          },
          {
            'id_voivodeship': '18',
            'id_county': '04',
            'id_commune': '03',
            'name': 'Chłopice'
          },
          {
            'id_voivodeship': '18',
            'id_county': '04',
            'id_commune': '05',
            'name': 'Laszki'
          },
          {
            'id_voivodeship': '18',
            'id_county': '04',
            'id_commune': '06',
            'name': 'Pawłosiów'
          },
          {
            'id_voivodeship': '18',
            'id_county': '04',
            'id_commune': '07',
            'name': 'Pruchnik'
          },
          {
            'id_voivodeship': '18',
            'id_county': '04',
            'id_commune': '09',
            'name': 'Rokietnica'
          },
          {
            'id_voivodeship': '18',
            'id_county': '04',
            'id_commune': '10',
            'name': 'Roźwienica'
          },
          {
            'id_voivodeship': '18',
            'id_county': '04',
            'id_commune': '11',
            'name': 'Wiązownica'
          }
        ]
      },
      {
        'id_voivodeship': '18',
        'id_county': '05',
        'name': 'jasielski',
        'communes': [
          {
            'id_voivodeship': '18',
            'id_county': '05',
            'id_commune': '01',
            'name': 'Jasło'
          },
          {
            'id_voivodeship': '18',
            'id_county': '05',
            'id_commune': '02',
            'name': 'Brzyska'
          },
          {
            'id_voivodeship': '18',
            'id_county': '05',
            'id_commune': '03',
            'name': 'Dębowiec'
          },
          {
            'id_voivodeship': '18',
            'id_county': '05',
            'id_commune': '05',
            'name': 'Kołaczyce'
          },
          {
            'id_voivodeship': '18',
            'id_county': '05',
            'id_commune': '06',
            'name': 'Krempna'
          },
          {
            'id_voivodeship': '18',
            'id_county': '05',
            'id_commune': '07',
            'name': 'Nowy Żmigród'
          },
          {
            'id_voivodeship': '18',
            'id_county': '05',
            'id_commune': '08',
            'name': 'Osiek Jasielski'
          },
          {
            'id_voivodeship': '18',
            'id_county': '05',
            'id_commune': '09',
            'name': 'Skołyszyn'
          },
          {
            'id_voivodeship': '18',
            'id_county': '05',
            'id_commune': '11',
            'name': 'Tarnowiec'
          }
        ]
      },
      {
        'id_voivodeship': '18',
        'id_county': '06',
        'name': 'kolbuszowski',
        'communes': [
          {
            'id_voivodeship': '18',
            'id_county': '06',
            'id_commune': '01',
            'name': 'Cmolas'
          },
          {
            'id_voivodeship': '18',
            'id_county': '06',
            'id_commune': '02',
            'name': 'Kolbuszowa'
          },
          {
            'id_voivodeship': '18',
            'id_county': '06',
            'id_commune': '03',
            'name': 'Majdan Królewski'
          },
          {
            'id_voivodeship': '18',
            'id_county': '06',
            'id_commune': '04',
            'name': 'Niwiska'
          },
          {
            'id_voivodeship': '18',
            'id_county': '06',
            'id_commune': '05',
            'name': 'Raniżów'
          },
          {
            'id_voivodeship': '18',
            'id_county': '06',
            'id_commune': '06',
            'name': 'Dzikowiec'
          }
        ]
      },
      {
        'id_voivodeship': '18',
        'id_county': '07',
        'name': 'krośnieński',
        'communes': [
          {
            'id_voivodeship': '18',
            'id_county': '07',
            'id_commune': '01',
            'name': 'Chorkówka'
          },
          {
            'id_voivodeship': '18',
            'id_county': '07',
            'id_commune': '02',
            'name': 'Dukla'
          },
          {
            'id_voivodeship': '18',
            'id_county': '07',
            'id_commune': '03',
            'name': 'Iwonicz-Zdrój'
          },
          {
            'id_voivodeship': '18',
            'id_county': '07',
            'id_commune': '04',
            'name': 'Jedlicze'
          },
          {
            'id_voivodeship': '18',
            'id_county': '07',
            'id_commune': '05',
            'name': 'Korczyna'
          },
          {
            'id_voivodeship': '18',
            'id_county': '07',
            'id_commune': '06',
            'name': 'Krościenko Wyżne'
          },
          {
            'id_voivodeship': '18',
            'id_county': '07',
            'id_commune': '07',
            'name': 'Miejsce Piastowe'
          },
          {
            'id_voivodeship': '18',
            'id_county': '07',
            'id_commune': '08',
            'name': 'Rymanów'
          },
          {
            'id_voivodeship': '18',
            'id_county': '07',
            'id_commune': '09',
            'name': 'Wojaszówka'
          },
          {
            'id_voivodeship': '18',
            'id_county': '07',
            'id_commune': '10',
            'name': 'Jaśliska'
          }
        ]
      },
      {
        'id_voivodeship': '18',
        'id_county': '08',
        'name': 'leżajski',
        'communes': [
          {
            'id_voivodeship': '18',
            'id_county': '08',
            'id_commune': '01',
            'name': 'Leżajsk'
          },
          {
            'id_voivodeship': '18',
            'id_county': '08',
            'id_commune': '02',
            'name': 'Grodzisko Dolne'
          },
          {
            'id_voivodeship': '18',
            'id_county': '08',
            'id_commune': '03',
            'name': 'Kuryłówka'
          },
          {
            'id_voivodeship': '18',
            'id_county': '08',
            'id_commune': '05',
            'name': 'Nowa Sarzyna'
          }
        ]
      },
      {
        'id_voivodeship': '18',
        'id_county': '09',
        'name': 'lubaczowski',
        'communes': [
          {
            'id_voivodeship': '18',
            'id_county': '09',
            'id_commune': '01',
            'name': 'Lubaczów'
          },
          {
            'id_voivodeship': '18',
            'id_county': '09',
            'id_commune': '02',
            'name': 'Cieszanów'
          },
          {
            'id_voivodeship': '18',
            'id_county': '09',
            'id_commune': '03',
            'name': 'Horyniec-Zdrój'
          },
          {
            'id_voivodeship': '18',
            'id_county': '09',
            'id_commune': '05',
            'name': 'Narol'
          },
          {
            'id_voivodeship': '18',
            'id_county': '09',
            'id_commune': '06',
            'name': 'Oleszyce'
          },
          {
            'id_voivodeship': '18',
            'id_county': '09',
            'id_commune': '07',
            'name': 'Stary Dzików'
          },
          {
            'id_voivodeship': '18',
            'id_county': '09',
            'id_commune': '08',
            'name': 'Wielkie Oczy'
          }
        ]
      },
      {
        'id_voivodeship': '18',
        'id_county': '10',
        'name': 'łańcucki',
        'communes': [
          {
            'id_voivodeship': '18',
            'id_county': '10',
            'id_commune': '01',
            'name': 'Łańcut'
          },
          {
            'id_voivodeship': '18',
            'id_county': '10',
            'id_commune': '02',
            'name': 'Białobrzegi'
          },
          {
            'id_voivodeship': '18',
            'id_county': '10',
            'id_commune': '03',
            'name': 'Czarna'
          },
          {
            'id_voivodeship': '18',
            'id_county': '10',
            'id_commune': '05',
            'name': 'Markowa'
          },
          {
            'id_voivodeship': '18',
            'id_county': '10',
            'id_commune': '06',
            'name': 'Rakszawa'
          },
          {
            'id_voivodeship': '18',
            'id_county': '10',
            'id_commune': '07',
            'name': 'Żołynia'
          }
        ]
      },
      {
        'id_voivodeship': '18',
        'id_county': '11',
        'name': 'mielecki',
        'communes': [
          {
            'id_voivodeship': '18',
            'id_county': '11',
            'id_commune': '01',
            'name': 'Mielec'
          },
          {
            'id_voivodeship': '18',
            'id_county': '11',
            'id_commune': '02',
            'name': 'Borowa'
          },
          {
            'id_voivodeship': '18',
            'id_county': '11',
            'id_commune': '03',
            'name': 'Czermin'
          },
          {
            'id_voivodeship': '18',
            'id_county': '11',
            'id_commune': '04',
            'name': 'Gawłuszowice'
          },
          {
            'id_voivodeship': '18',
            'id_county': '11',
            'id_commune': '06',
            'name': 'Padew Narodowa'
          },
          {
            'id_voivodeship': '18',
            'id_county': '11',
            'id_commune': '07',
            'name': 'Przecław'
          },
          {
            'id_voivodeship': '18',
            'id_county': '11',
            'id_commune': '08',
            'name': 'Radomyśl Wielki'
          },
          {
            'id_voivodeship': '18',
            'id_county': '11',
            'id_commune': '09',
            'name': 'Tuszów Narodowy'
          },
          {
            'id_voivodeship': '18',
            'id_county': '11',
            'id_commune': '10',
            'name': 'Wadowice Górne'
          }
        ]
      },
      {
        'id_voivodeship': '18',
        'id_county': '12',
        'name': 'niżański',
        'communes': [
          {
            'id_voivodeship': '18',
            'id_county': '12',
            'id_commune': '01',
            'name': 'Harasiuki'
          },
          {
            'id_voivodeship': '18',
            'id_county': '12',
            'id_commune': '02',
            'name': 'Jarocin'
          },
          {
            'id_voivodeship': '18',
            'id_county': '12',
            'id_commune': '03',
            'name': 'Jeżowe'
          },
          {
            'id_voivodeship': '18',
            'id_county': '12',
            'id_commune': '04',
            'name': 'Krzeszów'
          },
          {
            'id_voivodeship': '18',
            'id_county': '12',
            'id_commune': '05',
            'name': 'Nisko'
          },
          {
            'id_voivodeship': '18',
            'id_county': '12',
            'id_commune': '06',
            'name': 'Rudnik nad Sanem'
          },
          {
            'id_voivodeship': '18',
            'id_county': '12',
            'id_commune': '07',
            'name': 'Ulanów'
          }
        ]
      },
      {
        'id_voivodeship': '18',
        'id_county': '13',
        'name': 'przemyski',
        'communes': [
          {
            'id_voivodeship': '18',
            'id_county': '13',
            'id_commune': '01',
            'name': 'Bircza'
          },
          {
            'id_voivodeship': '18',
            'id_county': '13',
            'id_commune': '02',
            'name': 'Dubiecko'
          },
          {
            'id_voivodeship': '18',
            'id_county': '13',
            'id_commune': '03',
            'name': 'Fredropol'
          },
          {
            'id_voivodeship': '18',
            'id_county': '13',
            'id_commune': '04',
            'name': 'Krasiczyn'
          },
          {
            'id_voivodeship': '18',
            'id_county': '13',
            'id_commune': '05',
            'name': 'Krzywcza'
          },
          {
            'id_voivodeship': '18',
            'id_county': '13',
            'id_commune': '06',
            'name': 'Medyka'
          },
          {
            'id_voivodeship': '18',
            'id_county': '13',
            'id_commune': '07',
            'name': 'Orły'
          },
          {
            'id_voivodeship': '18',
            'id_county': '13',
            'id_commune': '08',
            'name': 'Przemyśl'
          },
          {
            'id_voivodeship': '18',
            'id_county': '13',
            'id_commune': '09',
            'name': 'Stubno'
          },
          {
            'id_voivodeship': '18',
            'id_county': '13',
            'id_commune': '10',
            'name': 'Żurawica'
          }
        ]
      },
      {
        'id_voivodeship': '18',
        'id_county': '14',
        'name': 'przeworski',
        'communes': [
          {
            'id_voivodeship': '18',
            'id_county': '14',
            'id_commune': '01',
            'name': 'Przeworsk'
          },
          {
            'id_voivodeship': '18',
            'id_county': '14',
            'id_commune': '02',
            'name': 'Adamówka'
          },
          {
            'id_voivodeship': '18',
            'id_county': '14',
            'id_commune': '03',
            'name': 'Gać'
          },
          {
            'id_voivodeship': '18',
            'id_county': '14',
            'id_commune': '04',
            'name': 'Jawornik Polski'
          },
          {
            'id_voivodeship': '18',
            'id_county': '14',
            'id_commune': '05',
            'name': 'Kańczuga'
          },
          {
            'id_voivodeship': '18',
            'id_county': '14',
            'id_commune': '07',
            'name': 'Sieniawa'
          },
          {
            'id_voivodeship': '18',
            'id_county': '14',
            'id_commune': '08',
            'name': 'Tryńcza'
          },
          {
            'id_voivodeship': '18',
            'id_county': '14',
            'id_commune': '09',
            'name': 'Zarzecze'
          }
        ]
      },
      {
        'id_voivodeship': '18',
        'id_county': '15',
        'name': 'ropczycko-sędziszowski',
        'communes': [
          {
            'id_voivodeship': '18',
            'id_county': '15',
            'id_commune': '01',
            'name': 'Iwierzyce'
          },
          {
            'id_voivodeship': '18',
            'id_county': '15',
            'id_commune': '02',
            'name': 'Ostrów'
          },
          {
            'id_voivodeship': '18',
            'id_county': '15',
            'id_commune': '03',
            'name': 'Ropczyce'
          },
          {
            'id_voivodeship': '18',
            'id_county': '15',
            'id_commune': '04',
            'name': 'Sędziszów Małopolski'
          },
          {
            'id_voivodeship': '18',
            'id_county': '15',
            'id_commune': '05',
            'name': 'Wielopole Skrzyńskie'
          }
        ]
      },
      {
        'id_voivodeship': '18',
        'id_county': '16',
        'name': 'rzeszowski',
        'communes': [
          {
            'id_voivodeship': '18',
            'id_county': '16',
            'id_commune': '01',
            'name': 'Dynów'
          },
          {
            'id_voivodeship': '18',
            'id_county': '16',
            'id_commune': '02',
            'name': 'Błażowa'
          },
          {
            'id_voivodeship': '18',
            'id_county': '16',
            'id_commune': '03',
            'name': 'Boguchwała'
          },
          {
            'id_voivodeship': '18',
            'id_county': '16',
            'id_commune': '04',
            'name': 'Chmielnik'
          },
          {
            'id_voivodeship': '18',
            'id_county': '16',
            'id_commune': '06',
            'name': 'Głogów Małopolski'
          },
          {
            'id_voivodeship': '18',
            'id_county': '16',
            'id_commune': '07',
            'name': 'Hyżne'
          },
          {
            'id_voivodeship': '18',
            'id_county': '16',
            'id_commune': '08',
            'name': 'Kamień'
          },
          {
            'id_voivodeship': '18',
            'id_county': '16',
            'id_commune': '09',
            'name': 'Krasne'
          },
          {
            'id_voivodeship': '18',
            'id_county': '16',
            'id_commune': '10',
            'name': 'Lubenia'
          },
          {
            'id_voivodeship': '18',
            'id_county': '16',
            'id_commune': '11',
            'name': 'Sokołów Małopolski'
          },
          {
            'id_voivodeship': '18',
            'id_county': '16',
            'id_commune': '12',
            'name': 'Świlcza'
          },
          {
            'id_voivodeship': '18',
            'id_county': '16',
            'id_commune': '13',
            'name': 'Trzebownisko'
          },
          {
            'id_voivodeship': '18',
            'id_county': '16',
            'id_commune': '14',
            'name': 'Tyczyn'
          }
        ]
      },
      {
        'id_voivodeship': '18',
        'id_county': '17',
        'name': 'sanocki',
        'communes': [
          {
            'id_voivodeship': '18',
            'id_county': '17',
            'id_commune': '01',
            'name': 'Sanok'
          },
          {
            'id_voivodeship': '18',
            'id_county': '17',
            'id_commune': '02',
            'name': 'Besko'
          },
          {
            'id_voivodeship': '18',
            'id_county': '17',
            'id_commune': '03',
            'name': 'Bukowsko'
          },
          {
            'id_voivodeship': '18',
            'id_county': '17',
            'id_commune': '04',
            'name': 'Komańcza'
          },
          {
            'id_voivodeship': '18',
            'id_county': '17',
            'id_commune': '06',
            'name': 'Tyrawa Wołoska'
          },
          {
            'id_voivodeship': '18',
            'id_county': '17',
            'id_commune': '07',
            'name': 'Zagórz'
          },
          {
            'id_voivodeship': '18',
            'id_county': '17',
            'id_commune': '08',
            'name': 'Zarszyn'
          }
        ]
      },
      {
        'id_voivodeship': '18',
        'id_county': '18',
        'name': 'stalowowolski',
        'communes': [
          {
            'id_voivodeship': '18',
            'id_county': '18',
            'id_commune': '01',
            'name': 'Stalowa Wola'
          },
          {
            'id_voivodeship': '18',
            'id_county': '18',
            'id_commune': '02',
            'name': 'Bojanów'
          },
          {
            'id_voivodeship': '18',
            'id_county': '18',
            'id_commune': '03',
            'name': 'Pysznica'
          },
          {
            'id_voivodeship': '18',
            'id_county': '18',
            'id_commune': '04',
            'name': 'Radomyśl nad Sanem'
          },
          {
            'id_voivodeship': '18',
            'id_county': '18',
            'id_commune': '05',
            'name': 'Zaklików'
          },
          {
            'id_voivodeship': '18',
            'id_county': '18',
            'id_commune': '06',
            'name': 'Zaleszany'
          }
        ]
      },
      {
        'id_voivodeship': '18',
        'id_county': '19',
        'name': 'strzyżowski',
        'communes': [
          {
            'id_voivodeship': '18',
            'id_county': '19',
            'id_commune': '01',
            'name': 'Czudec'
          },
          {
            'id_voivodeship': '18',
            'id_county': '19',
            'id_commune': '02',
            'name': 'Frysztak'
          },
          {
            'id_voivodeship': '18',
            'id_county': '19',
            'id_commune': '03',
            'name': 'Niebylec'
          },
          {
            'id_voivodeship': '18',
            'id_county': '19',
            'id_commune': '04',
            'name': 'Strzyżów'
          },
          {
            'id_voivodeship': '18',
            'id_county': '19',
            'id_commune': '05',
            'name': 'Wiśniowa'
          }
        ]
      },
      {
        'id_voivodeship': '18',
        'id_county': '20',
        'name': 'tarnobrzeski',
        'communes': [
          {
            'id_voivodeship': '18',
            'id_county': '20',
            'id_commune': '01',
            'name': 'Baranów Sandomierski'
          },
          {
            'id_voivodeship': '18',
            'id_county': '20',
            'id_commune': '02',
            'name': 'Gorzyce'
          },
          {
            'id_voivodeship': '18',
            'id_county': '20',
            'id_commune': '03',
            'name': 'Grębów'
          },
          {
            'id_voivodeship': '18',
            'id_county': '20',
            'id_commune': '04',
            'name': 'Nowa Dęba'
          }
        ]
      },
      {
        'id_voivodeship': '18',
        'id_county': '21',
        'name': 'leski',
        'communes': [
          {
            'id_voivodeship': '18',
            'id_county': '21',
            'id_commune': '01',
            'name': 'Baligród'
          },
          {
            'id_voivodeship': '18',
            'id_county': '21',
            'id_commune': '02',
            'name': 'Cisna'
          },
          {
            'id_voivodeship': '18',
            'id_county': '21',
            'id_commune': '03',
            'name': 'Lesko'
          },
          {
            'id_voivodeship': '18',
            'id_county': '21',
            'id_commune': '04',
            'name': 'Olszanica'
          },
          {
            'id_voivodeship': '18',
            'id_county': '21',
            'id_commune': '05',
            'name': 'Solina'
          }
        ]
      },
      {
        'id_voivodeship': '18',
        'id_county': '61',
        'name': 'Krosno',
        'communes': [
          {
            'id_voivodeship': '18',
            'id_county': '61',
            'id_commune': '01',
            'name': 'Krosno'
          }
        ]
      },
      {
        'id_voivodeship': '18',
        'id_county': '62',
        'name': 'Przemyśl',
        'communes': [
          {
            'id_voivodeship': '18',
            'id_county': '62',
            'id_commune': '01',
            'name': 'Przemyśl'
          }
        ]
      },
      {
        'id_voivodeship': '18',
        'id_county': '63',
        'name': 'Rzeszów',
        'communes': [
          {
            'id_voivodeship': '18',
            'id_county': '63',
            'id_commune': '01',
            'name': 'Rzeszów'
          }
        ]
      },
      {
        'id_voivodeship': '18',
        'id_county': '64',
        'name': 'Tarnobrzeg',
        'communes': [
          {
            'id_voivodeship': '18',
            'id_county': '64',
            'id_commune': '01',
            'name': 'Tarnobrzeg'
          }
        ]
      }
    ]
  },
  {
    'id_voivodeship': '20',
    'name': 'PODLASKIE',
    'counties': [
      {
        'id_voivodeship': '20',
        'id_county': '01',
        'name': 'augustowski',
        'communes': [
          {
            'id_voivodeship': '20',
            'id_county': '01',
            'id_commune': '01',
            'name': 'Augustów'
          },
          {
            'id_voivodeship': '20',
            'id_county': '01',
            'id_commune': '03',
            'name': 'Bargłów Kościelny'
          },
          {
            'id_voivodeship': '20',
            'id_county': '01',
            'id_commune': '04',
            'name': 'Lipsk'
          },
          {
            'id_voivodeship': '20',
            'id_county': '01',
            'id_commune': '05',
            'name': 'Nowinka'
          },
          {
            'id_voivodeship': '20',
            'id_county': '01',
            'id_commune': '06',
            'name': 'Płaska'
          },
          {
            'id_voivodeship': '20',
            'id_county': '01',
            'id_commune': '07',
            'name': 'Sztabin'
          }
        ]
      },
      {
        'id_voivodeship': '20',
        'id_county': '02',
        'name': 'białostocki',
        'communes': [
          {
            'id_voivodeship': '20',
            'id_county': '02',
            'id_commune': '01',
            'name': 'Choroszcz'
          },
          {
            'id_voivodeship': '20',
            'id_county': '02',
            'id_commune': '02',
            'name': 'Czarna Białostocka'
          },
          {
            'id_voivodeship': '20',
            'id_county': '02',
            'id_commune': '03',
            'name': 'Dobrzyniewo Duże'
          },
          {
            'id_voivodeship': '20',
            'id_county': '02',
            'id_commune': '04',
            'name': 'Gródek'
          },
          {
            'id_voivodeship': '20',
            'id_county': '02',
            'id_commune': '05',
            'name': 'Juchnowiec Kościelny'
          },
          {
            'id_voivodeship': '20',
            'id_county': '02',
            'id_commune': '06',
            'name': 'Łapy'
          },
          {
            'id_voivodeship': '20',
            'id_county': '02',
            'id_commune': '07',
            'name': 'Michałowo'
          },
          {
            'id_voivodeship': '20',
            'id_county': '02',
            'id_commune': '08',
            'name': 'Poświętne'
          },
          {
            'id_voivodeship': '20',
            'id_county': '02',
            'id_commune': '09',
            'name': 'Supraśl'
          },
          {
            'id_voivodeship': '20',
            'id_county': '02',
            'id_commune': '10',
            'name': 'Suraż'
          },
          {
            'id_voivodeship': '20',
            'id_county': '02',
            'id_commune': '11',
            'name': 'Turośń Kościelna'
          },
          {
            'id_voivodeship': '20',
            'id_county': '02',
            'id_commune': '12',
            'name': 'Tykocin'
          },
          {
            'id_voivodeship': '20',
            'id_county': '02',
            'id_commune': '13',
            'name': 'Wasilków'
          },
          {
            'id_voivodeship': '20',
            'id_county': '02',
            'id_commune': '14',
            'name': 'Zabłudów'
          },
          {
            'id_voivodeship': '20',
            'id_county': '02',
            'id_commune': '15',
            'name': 'Zawady'
          }
        ]
      },
      {
        'id_voivodeship': '20',
        'id_county': '03',
        'name': 'bielski',
        'communes': [
          {
            'id_voivodeship': '20',
            'id_county': '03',
            'id_commune': '01',
            'name': 'Bielsk Podlaski'
          },
          {
            'id_voivodeship': '20',
            'id_county': '03',
            'id_commune': '02',
            'name': 'Brańsk'
          },
          {
            'id_voivodeship': '20',
            'id_county': '03',
            'id_commune': '04',
            'name': 'Boćki'
          },
          {
            'id_voivodeship': '20',
            'id_county': '03',
            'id_commune': '06',
            'name': 'Orla'
          },
          {
            'id_voivodeship': '20',
            'id_county': '03',
            'id_commune': '07',
            'name': 'Rudka'
          },
          {
            'id_voivodeship': '20',
            'id_county': '03',
            'id_commune': '08',
            'name': 'Wyszki'
          }
        ]
      },
      {
        'id_voivodeship': '20',
        'id_county': '04',
        'name': 'grajewski',
        'communes': [
          {
            'id_voivodeship': '20',
            'id_county': '04',
            'id_commune': '01',
            'name': 'Grajewo'
          },
          {
            'id_voivodeship': '20',
            'id_county': '04',
            'id_commune': '03',
            'name': 'Radziłów'
          },
          {
            'id_voivodeship': '20',
            'id_county': '04',
            'id_commune': '04',
            'name': 'Rajgród'
          },
          {
            'id_voivodeship': '20',
            'id_county': '04',
            'id_commune': '05',
            'name': 'Szczuczyn'
          },
          {
            'id_voivodeship': '20',
            'id_county': '04',
            'id_commune': '06',
            'name': 'Wąsosz'
          }
        ]
      },
      {
        'id_voivodeship': '20',
        'id_county': '05',
        'name': 'hajnowski',
        'communes': [
          {
            'id_voivodeship': '20',
            'id_county': '05',
            'id_commune': '01',
            'name': 'Hajnówka'
          },
          {
            'id_voivodeship': '20',
            'id_county': '05',
            'id_commune': '02',
            'name': 'Białowieża'
          },
          {
            'id_voivodeship': '20',
            'id_county': '05',
            'id_commune': '03',
            'name': 'Czeremcha'
          },
          {
            'id_voivodeship': '20',
            'id_county': '05',
            'id_commune': '04',
            'name': 'Czyże'
          },
          {
            'id_voivodeship': '20',
            'id_county': '05',
            'id_commune': '05',
            'name': 'Dubicze Cerkiewne'
          },
          {
            'id_voivodeship': '20',
            'id_county': '05',
            'id_commune': '07',
            'name': 'Kleszczele'
          },
          {
            'id_voivodeship': '20',
            'id_county': '05',
            'id_commune': '08',
            'name': 'Narew'
          },
          {
            'id_voivodeship': '20',
            'id_county': '05',
            'id_commune': '09',
            'name': 'Narewka'
          }
        ]
      },
      {
        'id_voivodeship': '20',
        'id_county': '06',
        'name': 'kolneński',
        'communes': [
          {
            'id_voivodeship': '20',
            'id_county': '06',
            'id_commune': '01',
            'name': 'Kolno'
          },
          {
            'id_voivodeship': '20',
            'id_county': '06',
            'id_commune': '02',
            'name': 'Grabowo'
          },
          {
            'id_voivodeship': '20',
            'id_county': '06',
            'id_commune': '04',
            'name': 'Mały Płock'
          },
          {
            'id_voivodeship': '20',
            'id_county': '06',
            'id_commune': '05',
            'name': 'Stawiski'
          },
          {
            'id_voivodeship': '20',
            'id_county': '06',
            'id_commune': '06',
            'name': 'Turośl'
          }
        ]
      },
      {
        'id_voivodeship': '20',
        'id_county': '07',
        'name': 'łomżyński',
        'communes': [
          {
            'id_voivodeship': '20',
            'id_county': '07',
            'id_commune': '01',
            'name': 'Jedwabne'
          },
          {
            'id_voivodeship': '20',
            'id_county': '07',
            'id_commune': '02',
            'name': 'Łomża'
          },
          {
            'id_voivodeship': '20',
            'id_county': '07',
            'id_commune': '03',
            'name': 'Miastkowo'
          },
          {
            'id_voivodeship': '20',
            'id_county': '07',
            'id_commune': '04',
            'name': 'Nowogród'
          },
          {
            'id_voivodeship': '20',
            'id_county': '07',
            'id_commune': '05',
            'name': 'Piątnica'
          },
          {
            'id_voivodeship': '20',
            'id_county': '07',
            'id_commune': '06',
            'name': 'Przytuły'
          },
          {
            'id_voivodeship': '20',
            'id_county': '07',
            'id_commune': '07',
            'name': 'Śniadowo'
          },
          {
            'id_voivodeship': '20',
            'id_county': '07',
            'id_commune': '08',
            'name': 'Wizna'
          },
          {
            'id_voivodeship': '20',
            'id_county': '07',
            'id_commune': '09',
            'name': 'Zbójna'
          }
        ]
      },
      {
        'id_voivodeship': '20',
        'id_county': '08',
        'name': 'moniecki',
        'communes': [
          {
            'id_voivodeship': '20',
            'id_county': '08',
            'id_commune': '01',
            'name': 'Goniądz'
          },
          {
            'id_voivodeship': '20',
            'id_county': '08',
            'id_commune': '02',
            'name': 'Jasionówka'
          },
          {
            'id_voivodeship': '20',
            'id_county': '08',
            'id_commune': '03',
            'name': 'Jaświły'
          },
          {
            'id_voivodeship': '20',
            'id_county': '08',
            'id_commune': '04',
            'name': 'Knyszyn'
          },
          {
            'id_voivodeship': '20',
            'id_county': '08',
            'id_commune': '05',
            'name': 'Krypno'
          },
          {
            'id_voivodeship': '20',
            'id_county': '08',
            'id_commune': '06',
            'name': 'Mońki'
          },
          {
            'id_voivodeship': '20',
            'id_county': '08',
            'id_commune': '07',
            'name': 'Trzcianne'
          }
        ]
      },
      {
        'id_voivodeship': '20',
        'id_county': '09',
        'name': 'sejneński',
        'communes': [
          {
            'id_voivodeship': '20',
            'id_county': '09',
            'id_commune': '01',
            'name': 'Sejny'
          },
          {
            'id_voivodeship': '20',
            'id_county': '09',
            'id_commune': '02',
            'name': 'Giby'
          },
          {
            'id_voivodeship': '20',
            'id_county': '09',
            'id_commune': '03',
            'name': 'Krasnopol'
          },
          {
            'id_voivodeship': '20',
            'id_county': '09',
            'id_commune': '04',
            'name': 'Puńsk'
          }
        ]
      },
      {
        'id_voivodeship': '20',
        'id_county': '10',
        'name': 'siemiatycki',
        'communes': [
          {
            'id_voivodeship': '20',
            'id_county': '10',
            'id_commune': '01',
            'name': 'Siemiatycze'
          },
          {
            'id_voivodeship': '20',
            'id_county': '10',
            'id_commune': '02',
            'name': 'Drohiczyn'
          },
          {
            'id_voivodeship': '20',
            'id_county': '10',
            'id_commune': '03',
            'name': 'Dziadkowice'
          },
          {
            'id_voivodeship': '20',
            'id_county': '10',
            'id_commune': '04',
            'name': 'Grodzisk'
          },
          {
            'id_voivodeship': '20',
            'id_county': '10',
            'id_commune': '05',
            'name': 'Mielnik'
          },
          {
            'id_voivodeship': '20',
            'id_county': '10',
            'id_commune': '06',
            'name': 'Milejczyce'
          },
          {
            'id_voivodeship': '20',
            'id_county': '10',
            'id_commune': '07',
            'name': 'Nurzec-Stacja'
          },
          {
            'id_voivodeship': '20',
            'id_county': '10',
            'id_commune': '08',
            'name': 'Perlejewo'
          }
        ]
      },
      {
        'id_voivodeship': '20',
        'id_county': '11',
        'name': 'sokólski',
        'communes': [
          {
            'id_voivodeship': '20',
            'id_county': '11',
            'id_commune': '01',
            'name': 'Dąbrowa Białostocka'
          },
          {
            'id_voivodeship': '20',
            'id_county': '11',
            'id_commune': '02',
            'name': 'Janów'
          },
          {
            'id_voivodeship': '20',
            'id_county': '11',
            'id_commune': '03',
            'name': 'Korycin'
          },
          {
            'id_voivodeship': '20',
            'id_county': '11',
            'id_commune': '04',
            'name': 'Krynki'
          },
          {
            'id_voivodeship': '20',
            'id_county': '11',
            'id_commune': '05',
            'name': 'Kuźnica'
          },
          {
            'id_voivodeship': '20',
            'id_county': '11',
            'id_commune': '06',
            'name': 'Nowy Dwór'
          },
          {
            'id_voivodeship': '20',
            'id_county': '11',
            'id_commune': '07',
            'name': 'Sidra'
          },
          {
            'id_voivodeship': '20',
            'id_county': '11',
            'id_commune': '08',
            'name': 'Sokółka'
          },
          {
            'id_voivodeship': '20',
            'id_county': '11',
            'id_commune': '09',
            'name': 'Suchowola'
          },
          {
            'id_voivodeship': '20',
            'id_county': '11',
            'id_commune': '10',
            'name': 'Szudziałowo'
          }
        ]
      },
      {
        'id_voivodeship': '20',
        'id_county': '12',
        'name': 'suwalski',
        'communes': [
          {
            'id_voivodeship': '20',
            'id_county': '12',
            'id_commune': '01',
            'name': 'Bakałarzewo'
          },
          {
            'id_voivodeship': '20',
            'id_county': '12',
            'id_commune': '02',
            'name': 'Filipów'
          },
          {
            'id_voivodeship': '20',
            'id_county': '12',
            'id_commune': '03',
            'name': 'Jeleniewo'
          },
          {
            'id_voivodeship': '20',
            'id_county': '12',
            'id_commune': '04',
            'name': 'Przerośl'
          },
          {
            'id_voivodeship': '20',
            'id_county': '12',
            'id_commune': '05',
            'name': 'Raczki'
          },
          {
            'id_voivodeship': '20',
            'id_county': '12',
            'id_commune': '06',
            'name': 'Rutka-Tartak'
          },
          {
            'id_voivodeship': '20',
            'id_county': '12',
            'id_commune': '07',
            'name': 'Suwałki'
          },
          {
            'id_voivodeship': '20',
            'id_county': '12',
            'id_commune': '08',
            'name': 'Szypliszki'
          },
          {
            'id_voivodeship': '20',
            'id_county': '12',
            'id_commune': '09',
            'name': 'Wiżajny'
          }
        ]
      },
      {
        'id_voivodeship': '20',
        'id_county': '13',
        'name': 'wysokomazowiecki',
        'communes': [
          {
            'id_voivodeship': '20',
            'id_county': '13',
            'id_commune': '01',
            'name': 'Wysokie Mazowieckie'
          },
          {
            'id_voivodeship': '20',
            'id_county': '13',
            'id_commune': '02',
            'name': 'Ciechanowiec'
          },
          {
            'id_voivodeship': '20',
            'id_county': '13',
            'id_commune': '03',
            'name': 'Czyżew'
          },
          {
            'id_voivodeship': '20',
            'id_county': '13',
            'id_commune': '04',
            'name': 'Klukowo'
          },
          {
            'id_voivodeship': '20',
            'id_county': '13',
            'id_commune': '05',
            'name': 'Kobylin-Borzymy'
          },
          {
            'id_voivodeship': '20',
            'id_county': '13',
            'id_commune': '06',
            'name': 'Kulesze Kościelne'
          },
          {
            'id_voivodeship': '20',
            'id_county': '13',
            'id_commune': '07',
            'name': 'Nowe Piekuty'
          },
          {
            'id_voivodeship': '20',
            'id_county': '13',
            'id_commune': '08',
            'name': 'Sokoły'
          },
          {
            'id_voivodeship': '20',
            'id_county': '13',
            'id_commune': '09',
            'name': 'Szepietowo'
          }
        ]
      },
      {
        'id_voivodeship': '20',
        'id_county': '14',
        'name': 'zambrowski',
        'communes': [
          {
            'id_voivodeship': '20',
            'id_county': '14',
            'id_commune': '01',
            'name': 'Zambrów'
          },
          {
            'id_voivodeship': '20',
            'id_county': '14',
            'id_commune': '02',
            'name': 'Kołaki Kościelne'
          },
          {
            'id_voivodeship': '20',
            'id_county': '14',
            'id_commune': '03',
            'name': 'Rutki'
          },
          {
            'id_voivodeship': '20',
            'id_county': '14',
            'id_commune': '04',
            'name': 'Szumowo'
          }
        ]
      },
      {
        'id_voivodeship': '20',
        'id_county': '61',
        'name': 'Białystok',
        'communes': [
          {
            'id_voivodeship': '20',
            'id_county': '61',
            'id_commune': '01',
            'name': 'Białystok'
          }
        ]
      },
      {
        'id_voivodeship': '20',
        'id_county': '62',
        'name': 'Łomża',
        'communes': [
          {
            'id_voivodeship': '20',
            'id_county': '62',
            'id_commune': '01',
            'name': 'Łomża'
          }
        ]
      },
      {
        'id_voivodeship': '20',
        'id_county': '63',
        'name': 'Suwałki',
        'communes': [
          {
            'id_voivodeship': '20',
            'id_county': '63',
            'id_commune': '01',
            'name': 'Suwałki'
          }
        ]
      }
    ]
  },
  {
    'id_voivodeship': '22',
    'name': 'POMORSKIE',
    'counties': [
      {
        'id_voivodeship': '22',
        'id_county': '01',
        'name': 'bytowski',
        'communes': [
          {
            'id_voivodeship': '22',
            'id_county': '01',
            'id_commune': '01',
            'name': 'Borzytuchom'
          },
          {
            'id_voivodeship': '22',
            'id_county': '01',
            'id_commune': '02',
            'name': 'Bytów'
          },
          {
            'id_voivodeship': '22',
            'id_county': '01',
            'id_commune': '03',
            'name': 'Czarna Dąbrówka'
          },
          {
            'id_voivodeship': '22',
            'id_county': '01',
            'id_commune': '04',
            'name': 'Kołczygłowy'
          },
          {
            'id_voivodeship': '22',
            'id_county': '01',
            'id_commune': '05',
            'name': 'Lipnica'
          },
          {
            'id_voivodeship': '22',
            'id_county': '01',
            'id_commune': '06',
            'name': 'Miastko'
          },
          {
            'id_voivodeship': '22',
            'id_county': '01',
            'id_commune': '07',
            'name': 'Parchowo'
          },
          {
            'id_voivodeship': '22',
            'id_county': '01',
            'id_commune': '08',
            'name': 'Studzienice'
          },
          {
            'id_voivodeship': '22',
            'id_county': '01',
            'id_commune': '09',
            'name': 'Trzebielino'
          },
          {
            'id_voivodeship': '22',
            'id_county': '01',
            'id_commune': '10',
            'name': 'Tuchomie'
          }
        ]
      },
      {
        'id_voivodeship': '22',
        'id_county': '02',
        'name': 'chojnicki',
        'communes': [
          {
            'id_voivodeship': '22',
            'id_county': '02',
            'id_commune': '01',
            'name': 'Chojnice'
          },
          {
            'id_voivodeship': '22',
            'id_county': '02',
            'id_commune': '02',
            'name': 'Brusy'
          },
          {
            'id_voivodeship': '22',
            'id_county': '02',
            'id_commune': '04',
            'name': 'Czersk'
          },
          {
            'id_voivodeship': '22',
            'id_county': '02',
            'id_commune': '05',
            'name': 'Konarzyny'
          }
        ]
      },
      {
        'id_voivodeship': '22',
        'id_county': '03',
        'name': 'człuchowski',
        'communes': [
          {
            'id_voivodeship': '22',
            'id_county': '03',
            'id_commune': '01',
            'name': 'Człuchów'
          },
          {
            'id_voivodeship': '22',
            'id_county': '03',
            'id_commune': '02',
            'name': 'Czarne'
          },
          {
            'id_voivodeship': '22',
            'id_county': '03',
            'id_commune': '04',
            'name': 'Debrzno'
          },
          {
            'id_voivodeship': '22',
            'id_county': '03',
            'id_commune': '05',
            'name': 'Koczała'
          },
          {
            'id_voivodeship': '22',
            'id_county': '03',
            'id_commune': '06',
            'name': 'Przechlewo'
          },
          {
            'id_voivodeship': '22',
            'id_county': '03',
            'id_commune': '07',
            'name': 'Rzeczenica'
          }
        ]
      },
      {
        'id_voivodeship': '22',
        'id_county': '04',
        'name': 'gdański',
        'communes': [
          {
            'id_voivodeship': '22',
            'id_county': '04',
            'id_commune': '01',
            'name': 'Pruszcz Gdański'
          },
          {
            'id_voivodeship': '22',
            'id_county': '04',
            'id_commune': '02',
            'name': 'Cedry Wielkie'
          },
          {
            'id_voivodeship': '22',
            'id_county': '04',
            'id_commune': '03',
            'name': 'Kolbudy'
          },
          {
            'id_voivodeship': '22',
            'id_county': '04',
            'id_commune': '05',
            'name': 'Przywidz'
          },
          {
            'id_voivodeship': '22',
            'id_county': '04',
            'id_commune': '06',
            'name': 'Pszczółki'
          },
          {
            'id_voivodeship': '22',
            'id_county': '04',
            'id_commune': '07',
            'name': 'Suchy Dąb'
          },
          {
            'id_voivodeship': '22',
            'id_county': '04',
            'id_commune': '08',
            'name': 'Trąbki Wielkie'
          }
        ]
      },
      {
        'id_voivodeship': '22',
        'id_county': '05',
        'name': 'kartuski',
        'communes': [
          {
            'id_voivodeship': '22',
            'id_county': '05',
            'id_commune': '01',
            'name': 'Chmielno'
          },
          {
            'id_voivodeship': '22',
            'id_county': '05',
            'id_commune': '02',
            'name': 'Kartuzy'
          },
          {
            'id_voivodeship': '22',
            'id_county': '05',
            'id_commune': '03',
            'name': 'Przodkowo'
          },
          {
            'id_voivodeship': '22',
            'id_county': '05',
            'id_commune': '04',
            'name': 'Sierakowice'
          },
          {
            'id_voivodeship': '22',
            'id_county': '05',
            'id_commune': '05',
            'name': 'Somonino'
          },
          {
            'id_voivodeship': '22',
            'id_county': '05',
            'id_commune': '06',
            'name': 'Stężyca'
          },
          {
            'id_voivodeship': '22',
            'id_county': '05',
            'id_commune': '07',
            'name': 'Sulęczyno'
          },
          {
            'id_voivodeship': '22',
            'id_county': '05',
            'id_commune': '08',
            'name': 'Żukowo'
          }
        ]
      },
      {
        'id_voivodeship': '22',
        'id_county': '06',
        'name': 'kościerski',
        'communes': [
          {
            'id_voivodeship': '22',
            'id_county': '06',
            'id_commune': '01',
            'name': 'Kościerzyna'
          },
          {
            'id_voivodeship': '22',
            'id_county': '06',
            'id_commune': '02',
            'name': 'Dziemiany'
          },
          {
            'id_voivodeship': '22',
            'id_county': '06',
            'id_commune': '03',
            'name': 'Karsin'
          },
          {
            'id_voivodeship': '22',
            'id_county': '06',
            'id_commune': '05',
            'name': 'Liniewo'
          },
          {
            'id_voivodeship': '22',
            'id_county': '06',
            'id_commune': '06',
            'name': 'Lipusz'
          },
          {
            'id_voivodeship': '22',
            'id_county': '06',
            'id_commune': '07',
            'name': 'Nowa Karczma'
          },
          {
            'id_voivodeship': '22',
            'id_county': '06',
            'id_commune': '08',
            'name': 'Stara Kiszewa'
          }
        ]
      },
      {
        'id_voivodeship': '22',
        'id_county': '07',
        'name': 'kwidzyński',
        'communes': [
          {
            'id_voivodeship': '22',
            'id_county': '07',
            'id_commune': '01',
            'name': 'Kwidzyn'
          },
          {
            'id_voivodeship': '22',
            'id_county': '07',
            'id_commune': '02',
            'name': 'Gardeja'
          },
          {
            'id_voivodeship': '22',
            'id_county': '07',
            'id_commune': '04',
            'name': 'Prabuty'
          },
          {
            'id_voivodeship': '22',
            'id_county': '07',
            'id_commune': '05',
            'name': 'Ryjewo'
          },
          {
            'id_voivodeship': '22',
            'id_county': '07',
            'id_commune': '06',
            'name': 'Sadlinki'
          }
        ]
      },
      {
        'id_voivodeship': '22',
        'id_county': '08',
        'name': 'lęborski',
        'communes': [
          {
            'id_voivodeship': '22',
            'id_county': '08',
            'id_commune': '01',
            'name': 'Lębork'
          },
          {
            'id_voivodeship': '22',
            'id_county': '08',
            'id_commune': '02',
            'name': 'Łeba'
          },
          {
            'id_voivodeship': '22',
            'id_county': '08',
            'id_commune': '03',
            'name': 'Cewice'
          },
          {
            'id_voivodeship': '22',
            'id_county': '08',
            'id_commune': '04',
            'name': 'Nowa Wieś Lęborska'
          },
          {
            'id_voivodeship': '22',
            'id_county': '08',
            'id_commune': '05',
            'name': 'Wicko'
          }
        ]
      },
      {
        'id_voivodeship': '22',
        'id_county': '09',
        'name': 'malborski',
        'communes': [
          {
            'id_voivodeship': '22',
            'id_county': '09',
            'id_commune': '01',
            'name': 'Malbork'
          },
          {
            'id_voivodeship': '22',
            'id_county': '09',
            'id_commune': '03',
            'name': 'Lichnowy'
          },
          {
            'id_voivodeship': '22',
            'id_county': '09',
            'id_commune': '06',
            'name': 'Miłoradz'
          },
          {
            'id_voivodeship': '22',
            'id_county': '09',
            'id_commune': '07',
            'name': 'Nowy Staw'
          },
          {
            'id_voivodeship': '22',
            'id_county': '09',
            'id_commune': '08',
            'name': 'Stare Pole'
          }
        ]
      },
      {
        'id_voivodeship': '22',
        'id_county': '10',
        'name': 'nowodworski',
        'communes': [
          {
            'id_voivodeship': '22',
            'id_county': '10',
            'id_commune': '01',
            'name': 'Krynica Morska'
          },
          {
            'id_voivodeship': '22',
            'id_county': '10',
            'id_commune': '02',
            'name': 'Nowy Dwór Gdański'
          },
          {
            'id_voivodeship': '22',
            'id_county': '10',
            'id_commune': '03',
            'name': 'Ostaszewo'
          },
          {
            'id_voivodeship': '22',
            'id_county': '10',
            'id_commune': '04',
            'name': 'Stegna'
          },
          {
            'id_voivodeship': '22',
            'id_county': '10',
            'id_commune': '05',
            'name': 'Sztutowo'
          }
        ]
      },
      {
        'id_voivodeship': '22',
        'id_county': '11',
        'name': 'pucki',
        'communes': [
          {
            'id_voivodeship': '22',
            'id_county': '11',
            'id_commune': '01',
            'name': 'Hel'
          },
          {
            'id_voivodeship': '22',
            'id_county': '11',
            'id_commune': '02',
            'name': 'Jastarnia'
          },
          {
            'id_voivodeship': '22',
            'id_county': '11',
            'id_commune': '03',
            'name': 'Puck'
          },
          {
            'id_voivodeship': '22',
            'id_county': '11',
            'id_commune': '04',
            'name': 'Władysławowo'
          },
          {
            'id_voivodeship': '22',
            'id_county': '11',
            'id_commune': '05',
            'name': 'Kosakowo'
          },
          {
            'id_voivodeship': '22',
            'id_county': '11',
            'id_commune': '06',
            'name': 'Krokowa'
          }
        ]
      },
      {
        'id_voivodeship': '22',
        'id_county': '12',
        'name': 'słupski',
        'communes': [
          {
            'id_voivodeship': '22',
            'id_county': '12',
            'id_commune': '01',
            'name': 'Ustka'
          },
          {
            'id_voivodeship': '22',
            'id_county': '12',
            'id_commune': '02',
            'name': 'Damnica'
          },
          {
            'id_voivodeship': '22',
            'id_county': '12',
            'id_commune': '03',
            'name': 'Dębnica Kaszubska'
          },
          {
            'id_voivodeship': '22',
            'id_county': '12',
            'id_commune': '04',
            'name': 'Główczyce'
          },
          {
            'id_voivodeship': '22',
            'id_county': '12',
            'id_commune': '05',
            'name': 'Kępice'
          },
          {
            'id_voivodeship': '22',
            'id_county': '12',
            'id_commune': '06',
            'name': 'Kobylnica'
          },
          {
            'id_voivodeship': '22',
            'id_county': '12',
            'id_commune': '07',
            'name': 'Potęgowo'
          },
          {
            'id_voivodeship': '22',
            'id_county': '12',
            'id_commune': '08',
            'name': 'Słupsk'
          },
          {
            'id_voivodeship': '22',
            'id_county': '12',
            'id_commune': '09',
            'name': 'Smołdzino'
          }
        ]
      },
      {
        'id_voivodeship': '22',
        'id_county': '13',
        'name': 'starogardzki',
        'communes': [
          {
            'id_voivodeship': '22',
            'id_county': '13',
            'id_commune': '01',
            'name': 'Czarna Woda'
          },
          {
            'id_voivodeship': '22',
            'id_county': '13',
            'id_commune': '02',
            'name': 'Skórcz'
          },
          {
            'id_voivodeship': '22',
            'id_county': '13',
            'id_commune': '03',
            'name': 'Starogard Gdański'
          },
          {
            'id_voivodeship': '22',
            'id_county': '13',
            'id_commune': '04',
            'name': 'Bobowo'
          },
          {
            'id_voivodeship': '22',
            'id_county': '13',
            'id_commune': '05',
            'name': 'Kaliska'
          },
          {
            'id_voivodeship': '22',
            'id_county': '13',
            'id_commune': '06',
            'name': 'Lubichowo'
          },
          {
            'id_voivodeship': '22',
            'id_county': '13',
            'id_commune': '07',
            'name': 'Osieczna'
          },
          {
            'id_voivodeship': '22',
            'id_county': '13',
            'id_commune': '08',
            'name': 'Osiek'
          },
          {
            'id_voivodeship': '22',
            'id_county': '13',
            'id_commune': '09',
            'name': 'Skarszewy'
          },
          {
            'id_voivodeship': '22',
            'id_county': '13',
            'id_commune': '11',
            'name': 'Smętowo Graniczne'
          },
          {
            'id_voivodeship': '22',
            'id_county': '13',
            'id_commune': '13',
            'name': 'Zblewo'
          }
        ]
      },
      {
        'id_voivodeship': '22',
        'id_county': '14',
        'name': 'tczewski',
        'communes': [
          {
            'id_voivodeship': '22',
            'id_county': '14',
            'id_commune': '01',
            'name': 'Tczew'
          },
          {
            'id_voivodeship': '22',
            'id_county': '14',
            'id_commune': '02',
            'name': 'Gniew'
          },
          {
            'id_voivodeship': '22',
            'id_county': '14',
            'id_commune': '03',
            'name': 'Morzeszczyn'
          },
          {
            'id_voivodeship': '22',
            'id_county': '14',
            'id_commune': '04',
            'name': 'Pelplin'
          },
          {
            'id_voivodeship': '22',
            'id_county': '14',
            'id_commune': '05',
            'name': 'Subkowy'
          }
        ]
      },
      {
        'id_voivodeship': '22',
        'id_county': '15',
        'name': 'wejherowski',
        'communes': [
          {
            'id_voivodeship': '22',
            'id_county': '15',
            'id_commune': '01',
            'name': 'Reda'
          },
          {
            'id_voivodeship': '22',
            'id_county': '15',
            'id_commune': '02',
            'name': 'Rumia'
          },
          {
            'id_voivodeship': '22',
            'id_county': '15',
            'id_commune': '03',
            'name': 'Wejherowo'
          },
          {
            'id_voivodeship': '22',
            'id_county': '15',
            'id_commune': '04',
            'name': 'Choczewo'
          },
          {
            'id_voivodeship': '22',
            'id_county': '15',
            'id_commune': '05',
            'name': 'Gniewino'
          },
          {
            'id_voivodeship': '22',
            'id_county': '15',
            'id_commune': '06',
            'name': 'Linia'
          },
          {
            'id_voivodeship': '22',
            'id_county': '15',
            'id_commune': '07',
            'name': 'Luzino'
          },
          {
            'id_voivodeship': '22',
            'id_county': '15',
            'id_commune': '08',
            'name': 'Łęczyce'
          },
          {
            'id_voivodeship': '22',
            'id_county': '15',
            'id_commune': '09',
            'name': 'Szemud'
          }
        ]
      },
      {
        'id_voivodeship': '22',
        'id_county': '16',
        'name': 'sztumski',
        'communes': [
          {
            'id_voivodeship': '22',
            'id_county': '16',
            'id_commune': '01',
            'name': 'Dzierzgoń'
          },
          {
            'id_voivodeship': '22',
            'id_county': '16',
            'id_commune': '02',
            'name': 'Mikołajki Pomorskie'
          },
          {
            'id_voivodeship': '22',
            'id_county': '16',
            'id_commune': '03',
            'name': 'Stary Dzierzgoń'
          },
          {
            'id_voivodeship': '22',
            'id_county': '16',
            'id_commune': '04',
            'name': 'Stary Targ'
          },
          {
            'id_voivodeship': '22',
            'id_county': '16',
            'id_commune': '05',
            'name': 'Sztum'
          }
        ]
      },
      {
        'id_voivodeship': '22',
        'id_county': '61',
        'name': 'Gdańsk',
        'communes': [
          {
            'id_voivodeship': '22',
            'id_county': '61',
            'id_commune': '01',
            'name': 'Gdańsk'
          }
        ]
      },
      {
        'id_voivodeship': '22',
        'id_county': '62',
        'name': 'Gdynia',
        'communes': [
          {
            'id_voivodeship': '22',
            'id_county': '62',
            'id_commune': '01',
            'name': 'Gdynia'
          }
        ]
      },
      {
        'id_voivodeship': '22',
        'id_county': '63',
        'name': 'Słupsk',
        'communes': [
          {
            'id_voivodeship': '22',
            'id_county': '63',
            'id_commune': '01',
            'name': 'Słupsk'
          }
        ]
      },
      {
        'id_voivodeship': '22',
        'id_county': '64',
        'name': 'Sopot',
        'communes': [
          {
            'id_voivodeship': '22',
            'id_county': '64',
            'id_commune': '01',
            'name': 'Sopot'
          }
        ]
      }
    ]
  },
  {
    'id_voivodeship': '24',
    'name': 'ŚLĄSKIE',
    'counties': [
      {
        'id_voivodeship': '24',
        'id_county': '01',
        'name': 'będziński',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '01',
            'id_commune': '01',
            'name': 'Będzin'
          },
          {
            'id_voivodeship': '24',
            'id_county': '01',
            'id_commune': '02',
            'name': 'Czeladź'
          },
          {
            'id_voivodeship': '24',
            'id_county': '01',
            'id_commune': '03',
            'name': 'Wojkowice'
          },
          {
            'id_voivodeship': '24',
            'id_county': '01',
            'id_commune': '04',
            'name': 'Bobrowniki'
          },
          {
            'id_voivodeship': '24',
            'id_county': '01',
            'id_commune': '05',
            'name': 'Mierzęcice'
          },
          {
            'id_voivodeship': '24',
            'id_county': '01',
            'id_commune': '06',
            'name': 'Psary'
          },
          {
            'id_voivodeship': '24',
            'id_county': '01',
            'id_commune': '07',
            'name': 'Siewierz'
          },
          {
            'id_voivodeship': '24',
            'id_county': '01',
            'id_commune': '08',
            'name': 'Sławków'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '02',
        'name': 'bielski',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '02',
            'id_commune': '01',
            'name': 'Szczyrk'
          },
          {
            'id_voivodeship': '24',
            'id_county': '02',
            'id_commune': '02',
            'name': 'Bestwina'
          },
          {
            'id_voivodeship': '24',
            'id_county': '02',
            'id_commune': '03',
            'name': 'Buczkowice'
          },
          {
            'id_voivodeship': '24',
            'id_county': '02',
            'id_commune': '04',
            'name': 'Czechowice-Dziedzice'
          },
          {
            'id_voivodeship': '24',
            'id_county': '02',
            'id_commune': '05',
            'name': 'Jasienica'
          },
          {
            'id_voivodeship': '24',
            'id_county': '02',
            'id_commune': '06',
            'name': 'Jaworze'
          },
          {
            'id_voivodeship': '24',
            'id_county': '02',
            'id_commune': '07',
            'name': 'Kozy'
          },
          {
            'id_voivodeship': '24',
            'id_county': '02',
            'id_commune': '08',
            'name': 'Porąbka'
          },
          {
            'id_voivodeship': '24',
            'id_county': '02',
            'id_commune': '09',
            'name': 'Wilamowice'
          },
          {
            'id_voivodeship': '24',
            'id_county': '02',
            'id_commune': '10',
            'name': 'Wilkowice'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '03',
        'name': 'cieszyński',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '03',
            'id_commune': '01',
            'name': 'Cieszyn'
          },
          {
            'id_voivodeship': '24',
            'id_county': '03',
            'id_commune': '02',
            'name': 'Ustroń'
          },
          {
            'id_voivodeship': '24',
            'id_county': '03',
            'id_commune': '03',
            'name': 'Wisła'
          },
          {
            'id_voivodeship': '24',
            'id_county': '03',
            'id_commune': '04',
            'name': 'Brenna'
          },
          {
            'id_voivodeship': '24',
            'id_county': '03',
            'id_commune': '05',
            'name': 'Chybie'
          },
          {
            'id_voivodeship': '24',
            'id_county': '03',
            'id_commune': '06',
            'name': 'Dębowiec'
          },
          {
            'id_voivodeship': '24',
            'id_county': '03',
            'id_commune': '07',
            'name': 'Goleszów'
          },
          {
            'id_voivodeship': '24',
            'id_county': '03',
            'id_commune': '08',
            'name': 'Hażlach'
          },
          {
            'id_voivodeship': '24',
            'id_county': '03',
            'id_commune': '09',
            'name': 'Istebna'
          },
          {
            'id_voivodeship': '24',
            'id_county': '03',
            'id_commune': '10',
            'name': 'Skoczów'
          },
          {
            'id_voivodeship': '24',
            'id_county': '03',
            'id_commune': '11',
            'name': 'Strumień'
          },
          {
            'id_voivodeship': '24',
            'id_county': '03',
            'id_commune': '12',
            'name': 'Zebrzydowice'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '04',
        'name': 'częstochowski',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '04',
            'id_commune': '01',
            'name': 'Blachownia'
          },
          {
            'id_voivodeship': '24',
            'id_county': '04',
            'id_commune': '02',
            'name': 'Dąbrowa Zielona'
          },
          {
            'id_voivodeship': '24',
            'id_county': '04',
            'id_commune': '03',
            'name': 'Janów'
          },
          {
            'id_voivodeship': '24',
            'id_county': '04',
            'id_commune': '04',
            'name': 'Kamienica Polska'
          },
          {
            'id_voivodeship': '24',
            'id_county': '04',
            'id_commune': '05',
            'name': 'Kłomnice'
          },
          {
            'id_voivodeship': '24',
            'id_county': '04',
            'id_commune': '06',
            'name': 'Koniecpol'
          },
          {
            'id_voivodeship': '24',
            'id_county': '04',
            'id_commune': '07',
            'name': 'Konopiska'
          },
          {
            'id_voivodeship': '24',
            'id_county': '04',
            'id_commune': '08',
            'name': 'Kruszyna'
          },
          {
            'id_voivodeship': '24',
            'id_county': '04',
            'id_commune': '09',
            'name': 'Lelów'
          },
          {
            'id_voivodeship': '24',
            'id_county': '04',
            'id_commune': '10',
            'name': 'Mstów'
          },
          {
            'id_voivodeship': '24',
            'id_county': '04',
            'id_commune': '11',
            'name': 'Mykanów'
          },
          {
            'id_voivodeship': '24',
            'id_county': '04',
            'id_commune': '12',
            'name': 'Olsztyn'
          },
          {
            'id_voivodeship': '24',
            'id_county': '04',
            'id_commune': '13',
            'name': 'Poczesna'
          },
          {
            'id_voivodeship': '24',
            'id_county': '04',
            'id_commune': '14',
            'name': 'Przyrów'
          },
          {
            'id_voivodeship': '24',
            'id_county': '04',
            'id_commune': '15',
            'name': 'Rędziny'
          },
          {
            'id_voivodeship': '24',
            'id_county': '04',
            'id_commune': '16',
            'name': 'Starcza'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '05',
        'name': 'gliwicki',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '05',
            'id_commune': '01',
            'name': 'Knurów'
          },
          {
            'id_voivodeship': '24',
            'id_county': '05',
            'id_commune': '02',
            'name': 'Pyskowice'
          },
          {
            'id_voivodeship': '24',
            'id_county': '05',
            'id_commune': '03',
            'name': 'Gierałtowice'
          },
          {
            'id_voivodeship': '24',
            'id_county': '05',
            'id_commune': '04',
            'name': 'Pilchowice'
          },
          {
            'id_voivodeship': '24',
            'id_county': '05',
            'id_commune': '05',
            'name': 'Rudziniec'
          },
          {
            'id_voivodeship': '24',
            'id_county': '05',
            'id_commune': '06',
            'name': 'Sośnicowice'
          },
          {
            'id_voivodeship': '24',
            'id_county': '05',
            'id_commune': '07',
            'name': 'Toszek'
          },
          {
            'id_voivodeship': '24',
            'id_county': '05',
            'id_commune': '08',
            'name': 'Wielowieś'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '06',
        'name': 'kłobucki',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '06',
            'id_commune': '01',
            'name': 'Kłobuck'
          },
          {
            'id_voivodeship': '24',
            'id_county': '06',
            'id_commune': '02',
            'name': 'Krzepice'
          },
          {
            'id_voivodeship': '24',
            'id_county': '06',
            'id_commune': '03',
            'name': 'Lipie'
          },
          {
            'id_voivodeship': '24',
            'id_county': '06',
            'id_commune': '04',
            'name': 'Miedźno'
          },
          {
            'id_voivodeship': '24',
            'id_county': '06',
            'id_commune': '05',
            'name': 'Opatów'
          },
          {
            'id_voivodeship': '24',
            'id_county': '06',
            'id_commune': '06',
            'name': 'Panki'
          },
          {
            'id_voivodeship': '24',
            'id_county': '06',
            'id_commune': '07',
            'name': 'Popów'
          },
          {
            'id_voivodeship': '24',
            'id_county': '06',
            'id_commune': '08',
            'name': 'Przystajń'
          },
          {
            'id_voivodeship': '24',
            'id_county': '06',
            'id_commune': '09',
            'name': 'Wręczyca Wielka'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '07',
        'name': 'lubliniecki',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '07',
            'id_commune': '01',
            'name': 'Lubliniec'
          },
          {
            'id_voivodeship': '24',
            'id_county': '07',
            'id_commune': '02',
            'name': 'Boronów'
          },
          {
            'id_voivodeship': '24',
            'id_county': '07',
            'id_commune': '03',
            'name': 'Ciasna'
          },
          {
            'id_voivodeship': '24',
            'id_county': '07',
            'id_commune': '04',
            'name': 'Herby'
          },
          {
            'id_voivodeship': '24',
            'id_county': '07',
            'id_commune': '05',
            'name': 'Kochanowice'
          },
          {
            'id_voivodeship': '24',
            'id_county': '07',
            'id_commune': '06',
            'name': 'Koszęcin'
          },
          {
            'id_voivodeship': '24',
            'id_county': '07',
            'id_commune': '07',
            'name': 'Pawonków'
          },
          {
            'id_voivodeship': '24',
            'id_county': '07',
            'id_commune': '08',
            'name': 'Woźniki'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '08',
        'name': 'mikołowski',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '08',
            'id_commune': '01',
            'name': 'Łaziska Górne'
          },
          {
            'id_voivodeship': '24',
            'id_county': '08',
            'id_commune': '02',
            'name': 'Mikołów'
          },
          {
            'id_voivodeship': '24',
            'id_county': '08',
            'id_commune': '03',
            'name': 'Orzesze'
          },
          {
            'id_voivodeship': '24',
            'id_county': '08',
            'id_commune': '04',
            'name': 'Ornontowice'
          },
          {
            'id_voivodeship': '24',
            'id_county': '08',
            'id_commune': '05',
            'name': 'Wyry'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '09',
        'name': 'myszkowski',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '09',
            'id_commune': '01',
            'name': 'Myszków'
          },
          {
            'id_voivodeship': '24',
            'id_county': '09',
            'id_commune': '02',
            'name': 'Koziegłowy'
          },
          {
            'id_voivodeship': '24',
            'id_county': '09',
            'id_commune': '03',
            'name': 'Niegowa'
          },
          {
            'id_voivodeship': '24',
            'id_county': '09',
            'id_commune': '04',
            'name': 'Poraj'
          },
          {
            'id_voivodeship': '24',
            'id_county': '09',
            'id_commune': '05',
            'name': 'Żarki'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '10',
        'name': 'pszczyński',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '10',
            'id_commune': '01',
            'name': 'Goczałkowice-Zdrój'
          },
          {
            'id_voivodeship': '24',
            'id_county': '10',
            'id_commune': '02',
            'name': 'Kobiór'
          },
          {
            'id_voivodeship': '24',
            'id_county': '10',
            'id_commune': '03',
            'name': 'Miedźna'
          },
          {
            'id_voivodeship': '24',
            'id_county': '10',
            'id_commune': '04',
            'name': 'Pawłowice'
          },
          {
            'id_voivodeship': '24',
            'id_county': '10',
            'id_commune': '05',
            'name': 'Pszczyna'
          },
          {
            'id_voivodeship': '24',
            'id_county': '10',
            'id_commune': '06',
            'name': 'Suszec'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '11',
        'name': 'raciborski',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '11',
            'id_commune': '01',
            'name': 'Racibórz'
          },
          {
            'id_voivodeship': '24',
            'id_county': '11',
            'id_commune': '02',
            'name': 'Kornowac'
          },
          {
            'id_voivodeship': '24',
            'id_county': '11',
            'id_commune': '03',
            'name': 'Krzanowice'
          },
          {
            'id_voivodeship': '24',
            'id_county': '11',
            'id_commune': '04',
            'name': 'Krzyżanowice'
          },
          {
            'id_voivodeship': '24',
            'id_county': '11',
            'id_commune': '05',
            'name': 'Kuźnia Raciborska'
          },
          {
            'id_voivodeship': '24',
            'id_county': '11',
            'id_commune': '06',
            'name': 'Nędza'
          },
          {
            'id_voivodeship': '24',
            'id_county': '11',
            'id_commune': '07',
            'name': 'Pietrowice Wielkie'
          },
          {
            'id_voivodeship': '24',
            'id_county': '11',
            'id_commune': '08',
            'name': 'Rudnik'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '12',
        'name': 'rybnicki',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '12',
            'id_commune': '01',
            'name': 'Czerwionka-Leszczyny'
          },
          {
            'id_voivodeship': '24',
            'id_county': '12',
            'id_commune': '02',
            'name': 'Gaszowice'
          },
          {
            'id_voivodeship': '24',
            'id_county': '12',
            'id_commune': '03',
            'name': 'Jejkowice'
          },
          {
            'id_voivodeship': '24',
            'id_county': '12',
            'id_commune': '04',
            'name': 'Lyski'
          },
          {
            'id_voivodeship': '24',
            'id_county': '12',
            'id_commune': '05',
            'name': 'Świerklany'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '13',
        'name': 'tarnogórski',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '13',
            'id_commune': '01',
            'name': 'Kalety'
          },
          {
            'id_voivodeship': '24',
            'id_county': '13',
            'id_commune': '02',
            'name': 'Miasteczko Śląskie'
          },
          {
            'id_voivodeship': '24',
            'id_county': '13',
            'id_commune': '03',
            'name': 'Radzionków'
          },
          {
            'id_voivodeship': '24',
            'id_county': '13',
            'id_commune': '04',
            'name': 'Tarnowskie Góry'
          },
          {
            'id_voivodeship': '24',
            'id_county': '13',
            'id_commune': '05',
            'name': 'Krupski Młyn'
          },
          {
            'id_voivodeship': '24',
            'id_county': '13',
            'id_commune': '06',
            'name': 'Ożarowice'
          },
          {
            'id_voivodeship': '24',
            'id_county': '13',
            'id_commune': '07',
            'name': 'Świerklaniec'
          },
          {
            'id_voivodeship': '24',
            'id_county': '13',
            'id_commune': '08',
            'name': 'Tworóg'
          },
          {
            'id_voivodeship': '24',
            'id_county': '13',
            'id_commune': '09',
            'name': 'Zbrosławice'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '14',
        'name': 'bieruńsko-lędziński',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '14',
            'id_commune': '01',
            'name': 'Bieruń'
          },
          {
            'id_voivodeship': '24',
            'id_county': '14',
            'id_commune': '02',
            'name': 'Imielin'
          },
          {
            'id_voivodeship': '24',
            'id_county': '14',
            'id_commune': '03',
            'name': 'Lędziny'
          },
          {
            'id_voivodeship': '24',
            'id_county': '14',
            'id_commune': '04',
            'name': 'Bojszowy'
          },
          {
            'id_voivodeship': '24',
            'id_county': '14',
            'id_commune': '05',
            'name': 'Chełm Śląski'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '15',
        'name': 'wodzisławski',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '15',
            'id_commune': '01',
            'name': 'Pszów'
          },
          {
            'id_voivodeship': '24',
            'id_county': '15',
            'id_commune': '02',
            'name': 'Radlin'
          },
          {
            'id_voivodeship': '24',
            'id_county': '15',
            'id_commune': '03',
            'name': 'Rydułtowy'
          },
          {
            'id_voivodeship': '24',
            'id_county': '15',
            'id_commune': '04',
            'name': 'Wodzisław Śląski'
          },
          {
            'id_voivodeship': '24',
            'id_county': '15',
            'id_commune': '05',
            'name': 'Godów'
          },
          {
            'id_voivodeship': '24',
            'id_county': '15',
            'id_commune': '06',
            'name': 'Gorzyce'
          },
          {
            'id_voivodeship': '24',
            'id_county': '15',
            'id_commune': '07',
            'name': 'Lubomia'
          },
          {
            'id_voivodeship': '24',
            'id_county': '15',
            'id_commune': '08',
            'name': 'Marklowice'
          },
          {
            'id_voivodeship': '24',
            'id_county': '15',
            'id_commune': '09',
            'name': 'Mszana'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '16',
        'name': 'zawierciański',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '16',
            'id_commune': '01',
            'name': 'Poręba'
          },
          {
            'id_voivodeship': '24',
            'id_county': '16',
            'id_commune': '02',
            'name': 'Zawiercie'
          },
          {
            'id_voivodeship': '24',
            'id_county': '16',
            'id_commune': '03',
            'name': 'Irządze'
          },
          {
            'id_voivodeship': '24',
            'id_county': '16',
            'id_commune': '04',
            'name': 'Kroczyce'
          },
          {
            'id_voivodeship': '24',
            'id_county': '16',
            'id_commune': '05',
            'name': 'Łazy'
          },
          {
            'id_voivodeship': '24',
            'id_county': '16',
            'id_commune': '06',
            'name': 'Ogrodzieniec'
          },
          {
            'id_voivodeship': '24',
            'id_county': '16',
            'id_commune': '07',
            'name': 'Pilica'
          },
          {
            'id_voivodeship': '24',
            'id_county': '16',
            'id_commune': '08',
            'name': 'Szczekociny'
          },
          {
            'id_voivodeship': '24',
            'id_county': '16',
            'id_commune': '09',
            'name': 'Włodowice'
          },
          {
            'id_voivodeship': '24',
            'id_county': '16',
            'id_commune': '10',
            'name': 'Żarnowiec'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '17',
        'name': 'żywiecki',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '17',
            'id_commune': '01',
            'name': 'Żywiec'
          },
          {
            'id_voivodeship': '24',
            'id_county': '17',
            'id_commune': '02',
            'name': 'Czernichów'
          },
          {
            'id_voivodeship': '24',
            'id_county': '17',
            'id_commune': '03',
            'name': 'Gilowice'
          },
          {
            'id_voivodeship': '24',
            'id_county': '17',
            'id_commune': '04',
            'name': 'Jeleśnia'
          },
          {
            'id_voivodeship': '24',
            'id_county': '17',
            'id_commune': '05',
            'name': 'Koszarawa'
          },
          {
            'id_voivodeship': '24',
            'id_county': '17',
            'id_commune': '06',
            'name': 'Lipowa'
          },
          {
            'id_voivodeship': '24',
            'id_county': '17',
            'id_commune': '07',
            'name': 'Łękawica'
          },
          {
            'id_voivodeship': '24',
            'id_county': '17',
            'id_commune': '08',
            'name': 'Łodygowice'
          },
          {
            'id_voivodeship': '24',
            'id_county': '17',
            'id_commune': '09',
            'name': 'Milówka'
          },
          {
            'id_voivodeship': '24',
            'id_county': '17',
            'id_commune': '10',
            'name': 'Radziechowy-Wieprz'
          },
          {
            'id_voivodeship': '24',
            'id_county': '17',
            'id_commune': '11',
            'name': 'Rajcza'
          },
          {
            'id_voivodeship': '24',
            'id_county': '17',
            'id_commune': '12',
            'name': 'Ślemień'
          },
          {
            'id_voivodeship': '24',
            'id_county': '17',
            'id_commune': '13',
            'name': 'Świnna'
          },
          {
            'id_voivodeship': '24',
            'id_county': '17',
            'id_commune': '14',
            'name': 'Ujsoły'
          },
          {
            'id_voivodeship': '24',
            'id_county': '17',
            'id_commune': '15',
            'name': 'Węgierska Górka'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '61',
        'name': 'Bielsko-Biała',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '61',
            'id_commune': '01',
            'name': 'Bielsko-Biała'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '62',
        'name': 'Bytom',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '62',
            'id_commune': '01',
            'name': 'Bytom'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '63',
        'name': 'Chorzów',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '63',
            'id_commune': '01',
            'name': 'Chorzów'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '64',
        'name': 'Częstochowa',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '64',
            'id_commune': '01',
            'name': 'Częstochowa'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '65',
        'name': 'Dąbrowa Górnicza',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '65',
            'id_commune': '01',
            'name': 'Dąbrowa Górnicza'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '66',
        'name': 'Gliwice',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '66',
            'id_commune': '01',
            'name': 'Gliwice'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '67',
        'name': 'Jastrzębie-Zdrój',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '67',
            'id_commune': '01',
            'name': 'Jastrzębie-Zdrój'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '68',
        'name': 'Jaworzno',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '68',
            'id_commune': '01',
            'name': 'Jaworzno'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '69',
        'name': 'Katowice',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '69',
            'id_commune': '01',
            'name': 'Katowice'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '70',
        'name': 'Mysłowice',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '70',
            'id_commune': '01',
            'name': 'Mysłowice'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '71',
        'name': 'Piekary Śląskie',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '71',
            'id_commune': '01',
            'name': 'Piekary Śląskie'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '72',
        'name': 'Ruda Śląska',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '72',
            'id_commune': '01',
            'name': 'Ruda Śląska'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '73',
        'name': 'Rybnik',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '73',
            'id_commune': '01',
            'name': 'Rybnik'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '74',
        'name': 'Siemianowice Śląskie',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '74',
            'id_commune': '01',
            'name': 'Siemianowice Śląskie'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '75',
        'name': 'Sosnowiec',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '75',
            'id_commune': '01',
            'name': 'Sosnowiec'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '76',
        'name': 'Świętochłowice',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '76',
            'id_commune': '01',
            'name': 'Świętochłowice'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '77',
        'name': 'Tychy',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '77',
            'id_commune': '01',
            'name': 'Tychy'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '78',
        'name': 'Zabrze',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '78',
            'id_commune': '01',
            'name': 'Zabrze'
          }
        ]
      },
      {
        'id_voivodeship': '24',
        'id_county': '79',
        'name': 'Żory',
        'communes': [
          {
            'id_voivodeship': '24',
            'id_county': '79',
            'id_commune': '01',
            'name': 'Żory'
          }
        ]
      }
    ]
  },
  {
    'id_voivodeship': '26',
    'name': 'ŚWIĘTOKRZYSKIE',
    'counties': [
      {
        'id_voivodeship': '26',
        'id_county': '01',
        'name': 'buski',
        'communes': [
          {
            'id_voivodeship': '26',
            'id_county': '01',
            'id_commune': '01',
            'name': 'Busko-Zdrój'
          },
          {
            'id_voivodeship': '26',
            'id_county': '01',
            'id_commune': '02',
            'name': 'Gnojno'
          },
          {
            'id_voivodeship': '26',
            'id_county': '01',
            'id_commune': '03',
            'name': 'Nowy Korczyn'
          },
          {
            'id_voivodeship': '26',
            'id_county': '01',
            'id_commune': '04',
            'name': 'Pacanów'
          },
          {
            'id_voivodeship': '26',
            'id_county': '01',
            'id_commune': '05',
            'name': 'Solec-Zdrój'
          },
          {
            'id_voivodeship': '26',
            'id_county': '01',
            'id_commune': '06',
            'name': 'Stopnica'
          },
          {
            'id_voivodeship': '26',
            'id_county': '01',
            'id_commune': '07',
            'name': 'Tuczępy'
          },
          {
            'id_voivodeship': '26',
            'id_county': '01',
            'id_commune': '08',
            'name': 'Wiślica'
          }
        ]
      },
      {
        'id_voivodeship': '26',
        'id_county': '02',
        'name': 'jędrzejowski',
        'communes': [
          {
            'id_voivodeship': '26',
            'id_county': '02',
            'id_commune': '01',
            'name': 'Imielno'
          },
          {
            'id_voivodeship': '26',
            'id_county': '02',
            'id_commune': '02',
            'name': 'Jędrzejów'
          },
          {
            'id_voivodeship': '26',
            'id_county': '02',
            'id_commune': '03',
            'name': 'Małogoszcz'
          },
          {
            'id_voivodeship': '26',
            'id_county': '02',
            'id_commune': '04',
            'name': 'Nagłowice'
          },
          {
            'id_voivodeship': '26',
            'id_county': '02',
            'id_commune': '05',
            'name': 'Oksa'
          },
          {
            'id_voivodeship': '26',
            'id_county': '02',
            'id_commune': '06',
            'name': 'Sędziszów'
          },
          {
            'id_voivodeship': '26',
            'id_county': '02',
            'id_commune': '07',
            'name': 'Słupia'
          },
          {
            'id_voivodeship': '26',
            'id_county': '02',
            'id_commune': '08',
            'name': 'Sobków'
          },
          {
            'id_voivodeship': '26',
            'id_county': '02',
            'id_commune': '09',
            'name': 'Wodzisław'
          }
        ]
      },
      {
        'id_voivodeship': '26',
        'id_county': '03',
        'name': 'kazimierski',
        'communes': [
          {
            'id_voivodeship': '26',
            'id_county': '03',
            'id_commune': '01',
            'name': 'Bejsce'
          },
          {
            'id_voivodeship': '26',
            'id_county': '03',
            'id_commune': '02',
            'name': 'Czarnocin'
          },
          {
            'id_voivodeship': '26',
            'id_county': '03',
            'id_commune': '03',
            'name': 'Kazimierza Wielka'
          },
          {
            'id_voivodeship': '26',
            'id_county': '03',
            'id_commune': '04',
            'name': 'Opatowiec'
          },
          {
            'id_voivodeship': '26',
            'id_county': '03',
            'id_commune': '05',
            'name': 'Skalbmierz'
          }
        ]
      },
      {
        'id_voivodeship': '26',
        'id_county': '04',
        'name': 'kielecki',
        'communes': [
          {
            'id_voivodeship': '26',
            'id_county': '04',
            'id_commune': '01',
            'name': 'Bieliny'
          },
          {
            'id_voivodeship': '26',
            'id_county': '04',
            'id_commune': '02',
            'name': 'Bodzentyn'
          },
          {
            'id_voivodeship': '26',
            'id_county': '04',
            'id_commune': '03',
            'name': 'Chęciny'
          },
          {
            'id_voivodeship': '26',
            'id_county': '04',
            'id_commune': '04',
            'name': 'Chmielnik'
          },
          {
            'id_voivodeship': '26',
            'id_county': '04',
            'id_commune': '05',
            'name': 'Daleszyce'
          },
          {
            'id_voivodeship': '26',
            'id_county': '04',
            'id_commune': '06',
            'name': 'Górno'
          },
          {
            'id_voivodeship': '26',
            'id_county': '04',
            'id_commune': '07',
            'name': 'Łagów'
          },
          {
            'id_voivodeship': '26',
            'id_county': '04',
            'id_commune': '08',
            'name': 'Łopuszno'
          },
          {
            'id_voivodeship': '26',
            'id_county': '04',
            'id_commune': '09',
            'name': 'Masłów'
          },
          {
            'id_voivodeship': '26',
            'id_county': '04',
            'id_commune': '10',
            'name': 'Miedziana Góra'
          },
          {
            'id_voivodeship': '26',
            'id_county': '04',
            'id_commune': '11',
            'name': 'Mniów'
          },
          {
            'id_voivodeship': '26',
            'id_county': '04',
            'id_commune': '12',
            'name': 'Morawica'
          },
          {
            'id_voivodeship': '26',
            'id_county': '04',
            'id_commune': '13',
            'name': 'Nowa Słupia'
          },
          {
            'id_voivodeship': '26',
            'id_county': '04',
            'id_commune': '14',
            'name': 'Piekoszów'
          },
          {
            'id_voivodeship': '26',
            'id_county': '04',
            'id_commune': '15',
            'name': 'Pierzchnica'
          },
          {
            'id_voivodeship': '26',
            'id_county': '04',
            'id_commune': '16',
            'name': 'Raków'
          },
          {
            'id_voivodeship': '26',
            'id_county': '04',
            'id_commune': '17',
            'name': 'Sitkówka-Nowiny'
          },
          {
            'id_voivodeship': '26',
            'id_county': '04',
            'id_commune': '18',
            'name': 'Strawczyn'
          },
          {
            'id_voivodeship': '26',
            'id_county': '04',
            'id_commune': '19',
            'name': 'Zagnańsk'
          }
        ]
      },
      {
        'id_voivodeship': '26',
        'id_county': '05',
        'name': 'konecki',
        'communes': [
          {
            'id_voivodeship': '26',
            'id_county': '05',
            'id_commune': '01',
            'name': 'Fałków'
          },
          {
            'id_voivodeship': '26',
            'id_county': '05',
            'id_commune': '02',
            'name': 'Gowarczów'
          },
          {
            'id_voivodeship': '26',
            'id_county': '05',
            'id_commune': '03',
            'name': 'Końskie'
          },
          {
            'id_voivodeship': '26',
            'id_county': '05',
            'id_commune': '04',
            'name': 'Radoszyce'
          },
          {
            'id_voivodeship': '26',
            'id_county': '05',
            'id_commune': '05',
            'name': 'Ruda Maleniecka'
          },
          {
            'id_voivodeship': '26',
            'id_county': '05',
            'id_commune': '06',
            'name': 'Słupia Konecka'
          },
          {
            'id_voivodeship': '26',
            'id_county': '05',
            'id_commune': '07',
            'name': 'Smyków'
          },
          {
            'id_voivodeship': '26',
            'id_county': '05',
            'id_commune': '08',
            'name': 'Stąporków'
          }
        ]
      },
      {
        'id_voivodeship': '26',
        'id_county': '06',
        'name': 'opatowski',
        'communes': [
          {
            'id_voivodeship': '26',
            'id_county': '06',
            'id_commune': '01',
            'name': 'Baćkowice'
          },
          {
            'id_voivodeship': '26',
            'id_county': '06',
            'id_commune': '02',
            'name': 'Iwaniska'
          },
          {
            'id_voivodeship': '26',
            'id_county': '06',
            'id_commune': '03',
            'name': 'Lipnik'
          },
          {
            'id_voivodeship': '26',
            'id_county': '06',
            'id_commune': '04',
            'name': 'Opatów'
          },
          {
            'id_voivodeship': '26',
            'id_county': '06',
            'id_commune': '05',
            'name': 'Ożarów'
          },
          {
            'id_voivodeship': '26',
            'id_county': '06',
            'id_commune': '06',
            'name': 'Sadowie'
          },
          {
            'id_voivodeship': '26',
            'id_county': '06',
            'id_commune': '07',
            'name': 'Tarłów'
          },
          {
            'id_voivodeship': '26',
            'id_county': '06',
            'id_commune': '08',
            'name': 'Wojciechowice'
          }
        ]
      },
      {
        'id_voivodeship': '26',
        'id_county': '07',
        'name': 'ostrowiecki',
        'communes': [
          {
            'id_voivodeship': '26',
            'id_county': '07',
            'id_commune': '01',
            'name': 'Ostrowiec Świętokrzyski'
          },
          {
            'id_voivodeship': '26',
            'id_county': '07',
            'id_commune': '02',
            'name': 'Bałtów'
          },
          {
            'id_voivodeship': '26',
            'id_county': '07',
            'id_commune': '03',
            'name': 'Bodzechów'
          },
          {
            'id_voivodeship': '26',
            'id_county': '07',
            'id_commune': '04',
            'name': 'Ćmielów'
          },
          {
            'id_voivodeship': '26',
            'id_county': '07',
            'id_commune': '05',
            'name': 'Kunów'
          },
          {
            'id_voivodeship': '26',
            'id_county': '07',
            'id_commune': '06',
            'name': 'Waśniów'
          }
        ]
      },
      {
        'id_voivodeship': '26',
        'id_county': '08',
        'name': 'pińczowski',
        'communes': [
          {
            'id_voivodeship': '26',
            'id_county': '08',
            'id_commune': '01',
            'name': 'Działoszyce'
          },
          {
            'id_voivodeship': '26',
            'id_county': '08',
            'id_commune': '02',
            'name': 'Kije'
          },
          {
            'id_voivodeship': '26',
            'id_county': '08',
            'id_commune': '03',
            'name': 'Michałów'
          },
          {
            'id_voivodeship': '26',
            'id_county': '08',
            'id_commune': '04',
            'name': 'Pińczów'
          },
          {
            'id_voivodeship': '26',
            'id_county': '08',
            'id_commune': '05',
            'name': 'Złota'
          }
        ]
      },
      {
        'id_voivodeship': '26',
        'id_county': '09',
        'name': 'sandomierski',
        'communes': [
          {
            'id_voivodeship': '26',
            'id_county': '09',
            'id_commune': '01',
            'name': 'Sandomierz'
          },
          {
            'id_voivodeship': '26',
            'id_county': '09',
            'id_commune': '02',
            'name': 'Dwikozy'
          },
          {
            'id_voivodeship': '26',
            'id_county': '09',
            'id_commune': '03',
            'name': 'Klimontów'
          },
          {
            'id_voivodeship': '26',
            'id_county': '09',
            'id_commune': '04',
            'name': 'Koprzywnica'
          },
          {
            'id_voivodeship': '26',
            'id_county': '09',
            'id_commune': '05',
            'name': 'Łoniów'
          },
          {
            'id_voivodeship': '26',
            'id_county': '09',
            'id_commune': '06',
            'name': 'Obrazów'
          },
          {
            'id_voivodeship': '26',
            'id_county': '09',
            'id_commune': '07',
            'name': 'Samborzec'
          },
          {
            'id_voivodeship': '26',
            'id_county': '09',
            'id_commune': '08',
            'name': 'Wilczyce'
          },
          {
            'id_voivodeship': '26',
            'id_county': '09',
            'id_commune': '09',
            'name': 'Zawichost'
          }
        ]
      },
      {
        'id_voivodeship': '26',
        'id_county': '10',
        'name': 'skarżyski',
        'communes': [
          {
            'id_voivodeship': '26',
            'id_county': '10',
            'id_commune': '01',
            'name': 'Skarżysko-Kamienna'
          },
          {
            'id_voivodeship': '26',
            'id_county': '10',
            'id_commune': '02',
            'name': 'Bliżyn'
          },
          {
            'id_voivodeship': '26',
            'id_county': '10',
            'id_commune': '03',
            'name': 'Łączna'
          },
          {
            'id_voivodeship': '26',
            'id_county': '10',
            'id_commune': '04',
            'name': 'Skarżysko Kościelne'
          },
          {
            'id_voivodeship': '26',
            'id_county': '10',
            'id_commune': '05',
            'name': 'Suchedniów'
          }
        ]
      },
      {
        'id_voivodeship': '26',
        'id_county': '11',
        'name': 'starachowicki',
        'communes': [
          {
            'id_voivodeship': '26',
            'id_county': '11',
            'id_commune': '01',
            'name': 'Starachowice'
          },
          {
            'id_voivodeship': '26',
            'id_county': '11',
            'id_commune': '02',
            'name': 'Brody'
          },
          {
            'id_voivodeship': '26',
            'id_county': '11',
            'id_commune': '03',
            'name': 'Mirzec'
          },
          {
            'id_voivodeship': '26',
            'id_county': '11',
            'id_commune': '04',
            'name': 'Pawłów'
          },
          {
            'id_voivodeship': '26',
            'id_county': '11',
            'id_commune': '05',
            'name': 'Wąchock'
          }
        ]
      },
      {
        'id_voivodeship': '26',
        'id_county': '12',
        'name': 'staszowski',
        'communes': [
          {
            'id_voivodeship': '26',
            'id_county': '12',
            'id_commune': '01',
            'name': 'Bogoria'
          },
          {
            'id_voivodeship': '26',
            'id_county': '12',
            'id_commune': '02',
            'name': 'Łubnice'
          },
          {
            'id_voivodeship': '26',
            'id_county': '12',
            'id_commune': '03',
            'name': 'Oleśnica'
          },
          {
            'id_voivodeship': '26',
            'id_county': '12',
            'id_commune': '04',
            'name': 'Osiek'
          },
          {
            'id_voivodeship': '26',
            'id_county': '12',
            'id_commune': '05',
            'name': 'Połaniec'
          },
          {
            'id_voivodeship': '26',
            'id_county': '12',
            'id_commune': '06',
            'name': 'Rytwiany'
          },
          {
            'id_voivodeship': '26',
            'id_county': '12',
            'id_commune': '07',
            'name': 'Staszów'
          },
          {
            'id_voivodeship': '26',
            'id_county': '12',
            'id_commune': '08',
            'name': 'Szydłów'
          }
        ]
      },
      {
        'id_voivodeship': '26',
        'id_county': '13',
        'name': 'włoszczowski',
        'communes': [
          {
            'id_voivodeship': '26',
            'id_county': '13',
            'id_commune': '01',
            'name': 'Kluczewsko'
          },
          {
            'id_voivodeship': '26',
            'id_county': '13',
            'id_commune': '02',
            'name': 'Krasocin'
          },
          {
            'id_voivodeship': '26',
            'id_county': '13',
            'id_commune': '03',
            'name': 'Moskorzew'
          },
          {
            'id_voivodeship': '26',
            'id_county': '13',
            'id_commune': '04',
            'name': 'Radków'
          },
          {
            'id_voivodeship': '26',
            'id_county': '13',
            'id_commune': '05',
            'name': 'Secemin'
          },
          {
            'id_voivodeship': '26',
            'id_county': '13',
            'id_commune': '06',
            'name': 'Włoszczowa'
          }
        ]
      },
      {
        'id_voivodeship': '26',
        'id_county': '61',
        'name': 'Kielce',
        'communes': [
          {
            'id_voivodeship': '26',
            'id_county': '61',
            'id_commune': '01',
            'name': 'Kielce'
          }
        ]
      }
    ]
  },
  {
    'id_voivodeship': '28',
    'name': 'WARMIŃSKO-MAZURSKIE',
    'counties': [
      {
        'id_voivodeship': '28',
        'id_county': '01',
        'name': 'bartoszycki',
        'communes': [
          {
            'id_voivodeship': '28',
            'id_county': '01',
            'id_commune': '01',
            'name': 'Bartoszyce'
          },
          {
            'id_voivodeship': '28',
            'id_county': '01',
            'id_commune': '02',
            'name': 'Górowo Iławeckie'
          },
          {
            'id_voivodeship': '28',
            'id_county': '01',
            'id_commune': '04',
            'name': 'Bisztynek'
          },
          {
            'id_voivodeship': '28',
            'id_county': '01',
            'id_commune': '06',
            'name': 'Sępopol'
          }
        ]
      },
      {
        'id_voivodeship': '28',
        'id_county': '02',
        'name': 'braniewski',
        'communes': [
          {
            'id_voivodeship': '28',
            'id_county': '02',
            'id_commune': '01',
            'name': 'Braniewo'
          },
          {
            'id_voivodeship': '28',
            'id_county': '02',
            'id_commune': '03',
            'name': 'Frombork'
          },
          {
            'id_voivodeship': '28',
            'id_county': '02',
            'id_commune': '04',
            'name': 'Lelkowo'
          },
          {
            'id_voivodeship': '28',
            'id_county': '02',
            'id_commune': '05',
            'name': 'Pieniężno'
          },
          {
            'id_voivodeship': '28',
            'id_county': '02',
            'id_commune': '06',
            'name': 'Płoskinia'
          },
          {
            'id_voivodeship': '28',
            'id_county': '02',
            'id_commune': '07',
            'name': 'Wilczęta'
          }
        ]
      },
      {
        'id_voivodeship': '28',
        'id_county': '03',
        'name': 'działdowski',
        'communes': [
          {
            'id_voivodeship': '28',
            'id_county': '03',
            'id_commune': '01',
            'name': 'Działdowo'
          },
          {
            'id_voivodeship': '28',
            'id_county': '03',
            'id_commune': '03',
            'name': 'Iłowo-Osada'
          },
          {
            'id_voivodeship': '28',
            'id_county': '03',
            'id_commune': '04',
            'name': 'Lidzbark'
          },
          {
            'id_voivodeship': '28',
            'id_county': '03',
            'id_commune': '05',
            'name': 'Płośnica'
          },
          {
            'id_voivodeship': '28',
            'id_county': '03',
            'id_commune': '06',
            'name': 'Rybno'
          }
        ]
      },
      {
        'id_voivodeship': '28',
        'id_county': '04',
        'name': 'elbląski',
        'communes': [
          {
            'id_voivodeship': '28',
            'id_county': '04',
            'id_commune': '01',
            'name': 'Elbląg'
          },
          {
            'id_voivodeship': '28',
            'id_county': '04',
            'id_commune': '02',
            'name': 'Godkowo'
          },
          {
            'id_voivodeship': '28',
            'id_county': '04',
            'id_commune': '03',
            'name': 'Gronowo Elbląskie'
          },
          {
            'id_voivodeship': '28',
            'id_county': '04',
            'id_commune': '04',
            'name': 'Markusy'
          },
          {
            'id_voivodeship': '28',
            'id_county': '04',
            'id_commune': '05',
            'name': 'Milejewo'
          },
          {
            'id_voivodeship': '28',
            'id_county': '04',
            'id_commune': '06',
            'name': 'Młynary'
          },
          {
            'id_voivodeship': '28',
            'id_county': '04',
            'id_commune': '07',
            'name': 'Pasłęk'
          },
          {
            'id_voivodeship': '28',
            'id_county': '04',
            'id_commune': '08',
            'name': 'Rychliki'
          },
          {
            'id_voivodeship': '28',
            'id_county': '04',
            'id_commune': '09',
            'name': 'Tolkmicko'
          }
        ]
      },
      {
        'id_voivodeship': '28',
        'id_county': '05',
        'name': 'ełcki',
        'communes': [
          {
            'id_voivodeship': '28',
            'id_county': '05',
            'id_commune': '01',
            'name': 'Ełk'
          },
          {
            'id_voivodeship': '28',
            'id_county': '05',
            'id_commune': '03',
            'name': 'Kalinowo'
          },
          {
            'id_voivodeship': '28',
            'id_county': '05',
            'id_commune': '04',
            'name': 'Prostki'
          },
          {
            'id_voivodeship': '28',
            'id_county': '05',
            'id_commune': '05',
            'name': 'Stare Juchy'
          }
        ]
      },
      {
        'id_voivodeship': '28',
        'id_county': '06',
        'name': 'giżycki',
        'communes': [
          {
            'id_voivodeship': '28',
            'id_county': '06',
            'id_commune': '01',
            'name': 'Giżycko'
          },
          {
            'id_voivodeship': '28',
            'id_county': '06',
            'id_commune': '05',
            'name': 'Kruklanki'
          },
          {
            'id_voivodeship': '28',
            'id_county': '06',
            'id_commune': '06',
            'name': 'Miłki'
          },
          {
            'id_voivodeship': '28',
            'id_county': '06',
            'id_commune': '08',
            'name': 'Ryn'
          },
          {
            'id_voivodeship': '28',
            'id_county': '06',
            'id_commune': '10',
            'name': 'Wydminy'
          }
        ]
      },
      {
        'id_voivodeship': '28',
        'id_county': '07',
        'name': 'iławski',
        'communes': [
          {
            'id_voivodeship': '28',
            'id_county': '07',
            'id_commune': '01',
            'name': 'Iława'
          },
          {
            'id_voivodeship': '28',
            'id_county': '07',
            'id_commune': '02',
            'name': 'Lubawa'
          },
          {
            'id_voivodeship': '28',
            'id_county': '07',
            'id_commune': '04',
            'name': 'Kisielice'
          },
          {
            'id_voivodeship': '28',
            'id_county': '07',
            'id_commune': '06',
            'name': 'Susz'
          },
          {
            'id_voivodeship': '28',
            'id_county': '07',
            'id_commune': '07',
            'name': 'Zalewo'
          }
        ]
      },
      {
        'id_voivodeship': '28',
        'id_county': '08',
        'name': 'kętrzyński',
        'communes': [
          {
            'id_voivodeship': '28',
            'id_county': '08',
            'id_commune': '01',
            'name': 'Kętrzyn'
          },
          {
            'id_voivodeship': '28',
            'id_county': '08',
            'id_commune': '02',
            'name': 'Barciany'
          },
          {
            'id_voivodeship': '28',
            'id_county': '08',
            'id_commune': '04',
            'name': 'Korsze'
          },
          {
            'id_voivodeship': '28',
            'id_county': '08',
            'id_commune': '05',
            'name': 'Reszel'
          },
          {
            'id_voivodeship': '28',
            'id_county': '08',
            'id_commune': '06',
            'name': 'Srokowo'
          }
        ]
      },
      {
        'id_voivodeship': '28',
        'id_county': '09',
        'name': 'lidzbarski',
        'communes': [
          {
            'id_voivodeship': '28',
            'id_county': '09',
            'id_commune': '01',
            'name': 'Lidzbark Warmiński'
          },
          {
            'id_voivodeship': '28',
            'id_county': '09',
            'id_commune': '02',
            'name': 'Kiwity'
          },
          {
            'id_voivodeship': '28',
            'id_county': '09',
            'id_commune': '04',
            'name': 'Lubomino'
          },
          {
            'id_voivodeship': '28',
            'id_county': '09',
            'id_commune': '05',
            'name': 'Orneta'
          }
        ]
      },
      {
        'id_voivodeship': '28',
        'id_county': '10',
        'name': 'mrągowski',
        'communes': [
          {
            'id_voivodeship': '28',
            'id_county': '10',
            'id_commune': '01',
            'name': 'Mrągowo'
          },
          {
            'id_voivodeship': '28',
            'id_county': '10',
            'id_commune': '02',
            'name': 'Mikołajki'
          },
          {
            'id_voivodeship': '28',
            'id_county': '10',
            'id_commune': '04',
            'name': 'Piecki'
          },
          {
            'id_voivodeship': '28',
            'id_county': '10',
            'id_commune': '05',
            'name': 'Sorkwity'
          }
        ]
      },
      {
        'id_voivodeship': '28',
        'id_county': '11',
        'name': 'nidzicki',
        'communes': [
          {
            'id_voivodeship': '28',
            'id_county': '11',
            'id_commune': '01',
            'name': 'Janowiec Kościelny'
          },
          {
            'id_voivodeship': '28',
            'id_county': '11',
            'id_commune': '02',
            'name': 'Janowo'
          },
          {
            'id_voivodeship': '28',
            'id_county': '11',
            'id_commune': '03',
            'name': 'Kozłowo'
          },
          {
            'id_voivodeship': '28',
            'id_county': '11',
            'id_commune': '04',
            'name': 'Nidzica'
          }
        ]
      },
      {
        'id_voivodeship': '28',
        'id_county': '12',
        'name': 'nowomiejski',
        'communes': [
          {
            'id_voivodeship': '28',
            'id_county': '12',
            'id_commune': '01',
            'name': 'Nowe Miasto Lubawskie'
          },
          {
            'id_voivodeship': '28',
            'id_county': '12',
            'id_commune': '02',
            'name': 'Biskupiec'
          },
          {
            'id_voivodeship': '28',
            'id_county': '12',
            'id_commune': '03',
            'name': 'Grodziczno'
          },
          {
            'id_voivodeship': '28',
            'id_county': '12',
            'id_commune': '04',
            'name': 'Kurzętnik'
          }
        ]
      },
      {
        'id_voivodeship': '28',
        'id_county': '13',
        'name': 'olecki',
        'communes': [
          {
            'id_voivodeship': '28',
            'id_county': '13',
            'id_commune': '03',
            'name': 'Kowale Oleckie'
          },
          {
            'id_voivodeship': '28',
            'id_county': '13',
            'id_commune': '04',
            'name': 'Olecko'
          },
          {
            'id_voivodeship': '28',
            'id_county': '13',
            'id_commune': '05',
            'name': 'Świętajno'
          },
          {
            'id_voivodeship': '28',
            'id_county': '13',
            'id_commune': '06',
            'name': 'Wieliczki'
          }
        ]
      },
      {
        'id_voivodeship': '28',
        'id_county': '14',
        'name': 'olsztyński',
        'communes': [
          {
            'id_voivodeship': '28',
            'id_county': '14',
            'id_commune': '01',
            'name': 'Barczewo'
          },
          {
            'id_voivodeship': '28',
            'id_county': '14',
            'id_commune': '02',
            'name': 'Biskupiec'
          },
          {
            'id_voivodeship': '28',
            'id_county': '14',
            'id_commune': '03',
            'name': 'Dobre Miasto'
          },
          {
            'id_voivodeship': '28',
            'id_county': '14',
            'id_commune': '04',
            'name': 'Dywity'
          },
          {
            'id_voivodeship': '28',
            'id_county': '14',
            'id_commune': '05',
            'name': 'Gietrzwałd'
          },
          {
            'id_voivodeship': '28',
            'id_county': '14',
            'id_commune': '06',
            'name': 'Jeziorany'
          },
          {
            'id_voivodeship': '28',
            'id_county': '14',
            'id_commune': '07',
            'name': 'Jonkowo'
          },
          {
            'id_voivodeship': '28',
            'id_county': '14',
            'id_commune': '08',
            'name': 'Kolno'
          },
          {
            'id_voivodeship': '28',
            'id_county': '14',
            'id_commune': '09',
            'name': 'Olsztynek'
          },
          {
            'id_voivodeship': '28',
            'id_county': '14',
            'id_commune': '10',
            'name': 'Purda'
          },
          {
            'id_voivodeship': '28',
            'id_county': '14',
            'id_commune': '11',
            'name': 'Stawiguda'
          },
          {
            'id_voivodeship': '28',
            'id_county': '14',
            'id_commune': '12',
            'name': 'Świątki'
          }
        ]
      },
      {
        'id_voivodeship': '28',
        'id_county': '15',
        'name': 'ostródzki',
        'communes': [
          {
            'id_voivodeship': '28',
            'id_county': '15',
            'id_commune': '01',
            'name': 'Ostróda'
          },
          {
            'id_voivodeship': '28',
            'id_county': '15',
            'id_commune': '02',
            'name': 'Dąbrówno'
          },
          {
            'id_voivodeship': '28',
            'id_county': '15',
            'id_commune': '03',
            'name': 'Grunwald'
          },
          {
            'id_voivodeship': '28',
            'id_county': '15',
            'id_commune': '04',
            'name': 'Łukta'
          },
          {
            'id_voivodeship': '28',
            'id_county': '15',
            'id_commune': '05',
            'name': 'Małdyty'
          },
          {
            'id_voivodeship': '28',
            'id_county': '15',
            'id_commune': '06',
            'name': 'Miłakowo'
          },
          {
            'id_voivodeship': '28',
            'id_county': '15',
            'id_commune': '07',
            'name': 'Miłomłyn'
          },
          {
            'id_voivodeship': '28',
            'id_county': '15',
            'id_commune': '08',
            'name': 'Morąg'
          }
        ]
      },
      {
        'id_voivodeship': '28',
        'id_county': '16',
        'name': 'piski',
        'communes': [
          {
            'id_voivodeship': '28',
            'id_county': '16',
            'id_commune': '01',
            'name': 'Biała Piska'
          },
          {
            'id_voivodeship': '28',
            'id_county': '16',
            'id_commune': '02',
            'name': 'Orzysz'
          },
          {
            'id_voivodeship': '28',
            'id_county': '16',
            'id_commune': '03',
            'name': 'Pisz'
          },
          {
            'id_voivodeship': '28',
            'id_county': '16',
            'id_commune': '04',
            'name': 'Ruciane-Nida'
          }
        ]
      },
      {
        'id_voivodeship': '28',
        'id_county': '17',
        'name': 'szczycieński',
        'communes': [
          {
            'id_voivodeship': '28',
            'id_county': '17',
            'id_commune': '01',
            'name': 'Szczytno'
          },
          {
            'id_voivodeship': '28',
            'id_county': '17',
            'id_commune': '02',
            'name': 'Dźwierzuty'
          },
          {
            'id_voivodeship': '28',
            'id_county': '17',
            'id_commune': '03',
            'name': 'Jedwabno'
          },
          {
            'id_voivodeship': '28',
            'id_county': '17',
            'id_commune': '04',
            'name': 'Pasym'
          },
          {
            'id_voivodeship': '28',
            'id_county': '17',
            'id_commune': '05',
            'name': 'Rozogi'
          },
          {
            'id_voivodeship': '28',
            'id_county': '17',
            'id_commune': '07',
            'name': 'Świętajno'
          },
          {
            'id_voivodeship': '28',
            'id_county': '17',
            'id_commune': '08',
            'name': 'Wielbark'
          }
        ]
      },
      {
        'id_voivodeship': '28',
        'id_county': '18',
        'name': 'gołdapski',
        'communes': [
          {
            'id_voivodeship': '28',
            'id_county': '18',
            'id_commune': '01',
            'name': 'Banie Mazurskie'
          },
          {
            'id_voivodeship': '28',
            'id_county': '18',
            'id_commune': '02',
            'name': 'Dubeninki'
          },
          {
            'id_voivodeship': '28',
            'id_county': '18',
            'id_commune': '03',
            'name': 'Gołdap'
          }
        ]
      },
      {
        'id_voivodeship': '28',
        'id_county': '19',
        'name': 'węgorzewski',
        'communes': [
          {
            'id_voivodeship': '28',
            'id_county': '19',
            'id_commune': '01',
            'name': 'Budry'
          },
          {
            'id_voivodeship': '28',
            'id_county': '19',
            'id_commune': '02',
            'name': 'Pozezdrze'
          },
          {
            'id_voivodeship': '28',
            'id_county': '19',
            'id_commune': '03',
            'name': 'Węgorzewo'
          }
        ]
      },
      {
        'id_voivodeship': '28',
        'id_county': '61',
        'name': 'Elbląg',
        'communes': [
          {
            'id_voivodeship': '28',
            'id_county': '61',
            'id_commune': '01',
            'name': 'Elbląg'
          }
        ]
      },
      {
        'id_voivodeship': '28',
        'id_county': '62',
        'name': 'Olsztyn',
        'communes': [
          {
            'id_voivodeship': '28',
            'id_county': '62',
            'id_commune': '01',
            'name': 'Olsztyn'
          }
        ]
      }
    ]
  },
  {
    'id_voivodeship': '30',
    'name': 'WIELKOPOLSKIE',
    'counties': [
      {
        'id_voivodeship': '30',
        'id_county': '01',
        'name': 'chodzieski',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '01',
            'id_commune': '01',
            'name': 'Chodzież'
          },
          {
            'id_voivodeship': '30',
            'id_county': '01',
            'id_commune': '02',
            'name': 'Budzyń'
          },
          {
            'id_voivodeship': '30',
            'id_county': '01',
            'id_commune': '04',
            'name': 'Margonin'
          },
          {
            'id_voivodeship': '30',
            'id_county': '01',
            'id_commune': '05',
            'name': 'Szamocin'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '02',
        'name': 'czarnkowsko-trzcianecki',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '02',
            'id_commune': '01',
            'name': 'Czarnków'
          },
          {
            'id_voivodeship': '30',
            'id_county': '02',
            'id_commune': '03',
            'name': 'Drawsko'
          },
          {
            'id_voivodeship': '30',
            'id_county': '02',
            'id_commune': '04',
            'name': 'Krzyż Wielkopolski'
          },
          {
            'id_voivodeship': '30',
            'id_county': '02',
            'id_commune': '05',
            'name': 'Lubasz'
          },
          {
            'id_voivodeship': '30',
            'id_county': '02',
            'id_commune': '06',
            'name': 'Połajewo'
          },
          {
            'id_voivodeship': '30',
            'id_county': '02',
            'id_commune': '07',
            'name': 'Trzcianka'
          },
          {
            'id_voivodeship': '30',
            'id_county': '02',
            'id_commune': '08',
            'name': 'Wieleń'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '03',
        'name': 'gnieźnieński',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '03',
            'id_commune': '01',
            'name': 'Gniezno'
          },
          {
            'id_voivodeship': '30',
            'id_county': '03',
            'id_commune': '02',
            'name': 'Czerniejewo'
          },
          {
            'id_voivodeship': '30',
            'id_county': '03',
            'id_commune': '04',
            'name': 'Kiszkowo'
          },
          {
            'id_voivodeship': '30',
            'id_county': '03',
            'id_commune': '05',
            'name': 'Kłecko'
          },
          {
            'id_voivodeship': '30',
            'id_county': '03',
            'id_commune': '06',
            'name': 'Łubowo'
          },
          {
            'id_voivodeship': '30',
            'id_county': '03',
            'id_commune': '07',
            'name': 'Mieleszyn'
          },
          {
            'id_voivodeship': '30',
            'id_county': '03',
            'id_commune': '08',
            'name': 'Niechanowo'
          },
          {
            'id_voivodeship': '30',
            'id_county': '03',
            'id_commune': '09',
            'name': 'Trzemeszno'
          },
          {
            'id_voivodeship': '30',
            'id_county': '03',
            'id_commune': '10',
            'name': 'Witkowo'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '04',
        'name': 'gostyński',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '04',
            'id_commune': '01',
            'name': 'Borek Wielkopolski'
          },
          {
            'id_voivodeship': '30',
            'id_county': '04',
            'id_commune': '02',
            'name': 'Gostyń'
          },
          {
            'id_voivodeship': '30',
            'id_county': '04',
            'id_commune': '03',
            'name': 'Krobia'
          },
          {
            'id_voivodeship': '30',
            'id_county': '04',
            'id_commune': '04',
            'name': 'Pępowo'
          },
          {
            'id_voivodeship': '30',
            'id_county': '04',
            'id_commune': '05',
            'name': 'Piaski'
          },
          {
            'id_voivodeship': '30',
            'id_county': '04',
            'id_commune': '06',
            'name': 'Pogorzela'
          },
          {
            'id_voivodeship': '30',
            'id_county': '04',
            'id_commune': '07',
            'name': 'Poniec'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '05',
        'name': 'grodziski',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '05',
            'id_commune': '01',
            'name': 'Granowo'
          },
          {
            'id_voivodeship': '30',
            'id_county': '05',
            'id_commune': '02',
            'name': 'Grodzisk Wielkopolski'
          },
          {
            'id_voivodeship': '30',
            'id_county': '05',
            'id_commune': '03',
            'name': 'Kamieniec'
          },
          {
            'id_voivodeship': '30',
            'id_county': '05',
            'id_commune': '04',
            'name': 'Rakoniewice'
          },
          {
            'id_voivodeship': '30',
            'id_county': '05',
            'id_commune': '05',
            'name': 'Wielichowo'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '06',
        'name': 'jarociński',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '06',
            'id_commune': '01',
            'name': 'Jaraczewo'
          },
          {
            'id_voivodeship': '30',
            'id_county': '06',
            'id_commune': '02',
            'name': 'Jarocin'
          },
          {
            'id_voivodeship': '30',
            'id_county': '06',
            'id_commune': '03',
            'name': 'Kotlin'
          },
          {
            'id_voivodeship': '30',
            'id_county': '06',
            'id_commune': '04',
            'name': 'Żerków'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '07',
        'name': 'kaliski',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '07',
            'id_commune': '01',
            'name': 'Blizanów'
          },
          {
            'id_voivodeship': '30',
            'id_county': '07',
            'id_commune': '02',
            'name': 'Brzeziny'
          },
          {
            'id_voivodeship': '30',
            'id_county': '07',
            'id_commune': '03',
            'name': 'Ceków-Kolonia'
          },
          {
            'id_voivodeship': '30',
            'id_county': '07',
            'id_commune': '04',
            'name': 'Godziesze Wielkie'
          },
          {
            'id_voivodeship': '30',
            'id_county': '07',
            'id_commune': '05',
            'name': 'Koźminek'
          },
          {
            'id_voivodeship': '30',
            'id_county': '07',
            'id_commune': '06',
            'name': 'Lisków'
          },
          {
            'id_voivodeship': '30',
            'id_county': '07',
            'id_commune': '07',
            'name': 'Mycielin'
          },
          {
            'id_voivodeship': '30',
            'id_county': '07',
            'id_commune': '08',
            'name': 'Opatówek'
          },
          {
            'id_voivodeship': '30',
            'id_county': '07',
            'id_commune': '09',
            'name': 'Stawiszyn'
          },
          {
            'id_voivodeship': '30',
            'id_county': '07',
            'id_commune': '10',
            'name': 'Szczytniki'
          },
          {
            'id_voivodeship': '30',
            'id_county': '07',
            'id_commune': '11',
            'name': 'Żelazków'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '08',
        'name': 'kępiński',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '08',
            'id_commune': '01',
            'name': 'Baranów'
          },
          {
            'id_voivodeship': '30',
            'id_county': '08',
            'id_commune': '02',
            'name': 'Bralin'
          },
          {
            'id_voivodeship': '30',
            'id_county': '08',
            'id_commune': '03',
            'name': 'Kępno'
          },
          {
            'id_voivodeship': '30',
            'id_county': '08',
            'id_commune': '04',
            'name': 'Łęka Opatowska'
          },
          {
            'id_voivodeship': '30',
            'id_county': '08',
            'id_commune': '05',
            'name': 'Perzów'
          },
          {
            'id_voivodeship': '30',
            'id_county': '08',
            'id_commune': '06',
            'name': 'Rychtal'
          },
          {
            'id_voivodeship': '30',
            'id_county': '08',
            'id_commune': '07',
            'name': 'Trzcinica'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '09',
        'name': 'kolski',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '09',
            'id_commune': '01',
            'name': 'Koło'
          },
          {
            'id_voivodeship': '30',
            'id_county': '09',
            'id_commune': '02',
            'name': 'Babiak'
          },
          {
            'id_voivodeship': '30',
            'id_county': '09',
            'id_commune': '03',
            'name': 'Chodów'
          },
          {
            'id_voivodeship': '30',
            'id_county': '09',
            'id_commune': '04',
            'name': 'Dąbie'
          },
          {
            'id_voivodeship': '30',
            'id_county': '09',
            'id_commune': '05',
            'name': 'Grzegorzew'
          },
          {
            'id_voivodeship': '30',
            'id_county': '09',
            'id_commune': '06',
            'name': 'Kłodawa'
          },
          {
            'id_voivodeship': '30',
            'id_county': '09',
            'id_commune': '08',
            'name': 'Kościelec'
          },
          {
            'id_voivodeship': '30',
            'id_county': '09',
            'id_commune': '09',
            'name': 'Olszówka'
          },
          {
            'id_voivodeship': '30',
            'id_county': '09',
            'id_commune': '10',
            'name': 'Osiek Mały'
          },
          {
            'id_voivodeship': '30',
            'id_county': '09',
            'id_commune': '11',
            'name': 'Przedecz'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '10',
        'name': 'koniński',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '10',
            'id_commune': '01',
            'name': 'Golina'
          },
          {
            'id_voivodeship': '30',
            'id_county': '10',
            'id_commune': '02',
            'name': 'Grodziec'
          },
          {
            'id_voivodeship': '30',
            'id_county': '10',
            'id_commune': '03',
            'name': 'Kazimierz Biskupi'
          },
          {
            'id_voivodeship': '30',
            'id_county': '10',
            'id_commune': '04',
            'name': 'Kleczew'
          },
          {
            'id_voivodeship': '30',
            'id_county': '10',
            'id_commune': '05',
            'name': 'Kramsk'
          },
          {
            'id_voivodeship': '30',
            'id_county': '10',
            'id_commune': '06',
            'name': 'Krzymów'
          },
          {
            'id_voivodeship': '30',
            'id_county': '10',
            'id_commune': '07',
            'name': 'Rychwał'
          },
          {
            'id_voivodeship': '30',
            'id_county': '10',
            'id_commune': '08',
            'name': 'Rzgów'
          },
          {
            'id_voivodeship': '30',
            'id_county': '10',
            'id_commune': '09',
            'name': 'Skulsk'
          },
          {
            'id_voivodeship': '30',
            'id_county': '10',
            'id_commune': '10',
            'name': 'Sompolno'
          },
          {
            'id_voivodeship': '30',
            'id_county': '10',
            'id_commune': '11',
            'name': 'Stare Miasto'
          },
          {
            'id_voivodeship': '30',
            'id_county': '10',
            'id_commune': '12',
            'name': 'Ślesin'
          },
          {
            'id_voivodeship': '30',
            'id_county': '10',
            'id_commune': '13',
            'name': 'Wierzbinek'
          },
          {
            'id_voivodeship': '30',
            'id_county': '10',
            'id_commune': '14',
            'name': 'Wilczyn'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '11',
        'name': 'kościański',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '11',
            'id_commune': '01',
            'name': 'Kościan'
          },
          {
            'id_voivodeship': '30',
            'id_county': '11',
            'id_commune': '02',
            'name': 'Czempiń'
          },
          {
            'id_voivodeship': '30',
            'id_county': '11',
            'id_commune': '04',
            'name': 'Krzywiń'
          },
          {
            'id_voivodeship': '30',
            'id_county': '11',
            'id_commune': '05',
            'name': 'Śmigiel'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '12',
        'name': 'krotoszyński',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '12',
            'id_commune': '01',
            'name': 'Sulmierzyce'
          },
          {
            'id_voivodeship': '30',
            'id_county': '12',
            'id_commune': '02',
            'name': 'Kobylin'
          },
          {
            'id_voivodeship': '30',
            'id_county': '12',
            'id_commune': '03',
            'name': 'Koźmin Wielkopolski'
          },
          {
            'id_voivodeship': '30',
            'id_county': '12',
            'id_commune': '04',
            'name': 'Krotoszyn'
          },
          {
            'id_voivodeship': '30',
            'id_county': '12',
            'id_commune': '05',
            'name': 'Rozdrażew'
          },
          {
            'id_voivodeship': '30',
            'id_county': '12',
            'id_commune': '06',
            'name': 'Zduny'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '13',
        'name': 'leszczyński',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '13',
            'id_commune': '01',
            'name': 'Krzemieniewo'
          },
          {
            'id_voivodeship': '30',
            'id_county': '13',
            'id_commune': '02',
            'name': 'Lipno'
          },
          {
            'id_voivodeship': '30',
            'id_county': '13',
            'id_commune': '03',
            'name': 'Osieczna'
          },
          {
            'id_voivodeship': '30',
            'id_county': '13',
            'id_commune': '04',
            'name': 'Rydzyna'
          },
          {
            'id_voivodeship': '30',
            'id_county': '13',
            'id_commune': '05',
            'name': 'Święciechowa'
          },
          {
            'id_voivodeship': '30',
            'id_county': '13',
            'id_commune': '06',
            'name': 'Wijewo'
          },
          {
            'id_voivodeship': '30',
            'id_county': '13',
            'id_commune': '07',
            'name': 'Włoszakowice'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '14',
        'name': 'międzychodzki',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '14',
            'id_commune': '01',
            'name': 'Chrzypsko Wielkie'
          },
          {
            'id_voivodeship': '30',
            'id_county': '14',
            'id_commune': '02',
            'name': 'Kwilcz'
          },
          {
            'id_voivodeship': '30',
            'id_county': '14',
            'id_commune': '03',
            'name': 'Międzychód'
          },
          {
            'id_voivodeship': '30',
            'id_county': '14',
            'id_commune': '04',
            'name': 'Sieraków'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '15',
        'name': 'nowotomyski',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '15',
            'id_commune': '01',
            'name': 'Kuślin'
          },
          {
            'id_voivodeship': '30',
            'id_county': '15',
            'id_commune': '02',
            'name': 'Lwówek'
          },
          {
            'id_voivodeship': '30',
            'id_county': '15',
            'id_commune': '03',
            'name': 'Miedzichowo'
          },
          {
            'id_voivodeship': '30',
            'id_county': '15',
            'id_commune': '04',
            'name': 'Nowy Tomyśl'
          },
          {
            'id_voivodeship': '30',
            'id_county': '15',
            'id_commune': '05',
            'name': 'Opalenica'
          },
          {
            'id_voivodeship': '30',
            'id_county': '15',
            'id_commune': '06',
            'name': 'Zbąszyń'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '16',
        'name': 'obornicki',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '16',
            'id_commune': '01',
            'name': 'Oborniki'
          },
          {
            'id_voivodeship': '30',
            'id_county': '16',
            'id_commune': '02',
            'name': 'Rogoźno'
          },
          {
            'id_voivodeship': '30',
            'id_county': '16',
            'id_commune': '03',
            'name': 'Ryczywół'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '17',
        'name': 'ostrowski',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '17',
            'id_commune': '01',
            'name': 'Ostrów Wielkopolski'
          },
          {
            'id_voivodeship': '30',
            'id_county': '17',
            'id_commune': '02',
            'name': 'Nowe Skalmierzyce'
          },
          {
            'id_voivodeship': '30',
            'id_county': '17',
            'id_commune': '03',
            'name': 'Odolanów'
          },
          {
            'id_voivodeship': '30',
            'id_county': '17',
            'id_commune': '05',
            'name': 'Przygodzice'
          },
          {
            'id_voivodeship': '30',
            'id_county': '17',
            'id_commune': '06',
            'name': 'Raszków'
          },
          {
            'id_voivodeship': '30',
            'id_county': '17',
            'id_commune': '07',
            'name': 'Sieroszewice'
          },
          {
            'id_voivodeship': '30',
            'id_county': '17',
            'id_commune': '08',
            'name': 'Sośnie'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '18',
        'name': 'ostrzeszowski',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '18',
            'id_commune': '01',
            'name': 'Czajków'
          },
          {
            'id_voivodeship': '30',
            'id_county': '18',
            'id_commune': '02',
            'name': 'Doruchów'
          },
          {
            'id_voivodeship': '30',
            'id_county': '18',
            'id_commune': '03',
            'name': 'Grabów nad Prosną'
          },
          {
            'id_voivodeship': '30',
            'id_county': '18',
            'id_commune': '04',
            'name': 'Kobyla Góra'
          },
          {
            'id_voivodeship': '30',
            'id_county': '18',
            'id_commune': '05',
            'name': 'Kraszewice'
          },
          {
            'id_voivodeship': '30',
            'id_county': '18',
            'id_commune': '06',
            'name': 'Mikstat'
          },
          {
            'id_voivodeship': '30',
            'id_county': '18',
            'id_commune': '07',
            'name': 'Ostrzeszów'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '19',
        'name': 'pilski',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '19',
            'id_commune': '01',
            'name': 'Piła'
          },
          {
            'id_voivodeship': '30',
            'id_county': '19',
            'id_commune': '02',
            'name': 'Białośliwie'
          },
          {
            'id_voivodeship': '30',
            'id_county': '19',
            'id_commune': '03',
            'name': 'Kaczory'
          },
          {
            'id_voivodeship': '30',
            'id_county': '19',
            'id_commune': '04',
            'name': 'Łobżenica'
          },
          {
            'id_voivodeship': '30',
            'id_county': '19',
            'id_commune': '05',
            'name': 'Miasteczko Krajeńskie'
          },
          {
            'id_voivodeship': '30',
            'id_county': '19',
            'id_commune': '06',
            'name': 'Szydłowo'
          },
          {
            'id_voivodeship': '30',
            'id_county': '19',
            'id_commune': '07',
            'name': 'Ujście'
          },
          {
            'id_voivodeship': '30',
            'id_county': '19',
            'id_commune': '08',
            'name': 'Wyrzysk'
          },
          {
            'id_voivodeship': '30',
            'id_county': '19',
            'id_commune': '09',
            'name': 'Wysoka'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '20',
        'name': 'pleszewski',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '20',
            'id_commune': '01',
            'name': 'Chocz'
          },
          {
            'id_voivodeship': '30',
            'id_county': '20',
            'id_commune': '02',
            'name': 'Czermin'
          },
          {
            'id_voivodeship': '30',
            'id_county': '20',
            'id_commune': '03',
            'name': 'Dobrzyca'
          },
          {
            'id_voivodeship': '30',
            'id_county': '20',
            'id_commune': '04',
            'name': 'Gizałki'
          },
          {
            'id_voivodeship': '30',
            'id_county': '20',
            'id_commune': '05',
            'name': 'Gołuchów'
          },
          {
            'id_voivodeship': '30',
            'id_county': '20',
            'id_commune': '06',
            'name': 'Pleszew'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '21',
        'name': 'poznański',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '21',
            'id_commune': '01',
            'name': 'Luboń'
          },
          {
            'id_voivodeship': '30',
            'id_county': '21',
            'id_commune': '02',
            'name': 'Puszczykowo'
          },
          {
            'id_voivodeship': '30',
            'id_county': '21',
            'id_commune': '03',
            'name': 'Buk'
          },
          {
            'id_voivodeship': '30',
            'id_county': '21',
            'id_commune': '04',
            'name': 'Czerwonak'
          },
          {
            'id_voivodeship': '30',
            'id_county': '21',
            'id_commune': '05',
            'name': 'Dopiewo'
          },
          {
            'id_voivodeship': '30',
            'id_county': '21',
            'id_commune': '06',
            'name': 'Kleszczewo'
          },
          {
            'id_voivodeship': '30',
            'id_county': '21',
            'id_commune': '07',
            'name': 'Komorniki'
          },
          {
            'id_voivodeship': '30',
            'id_county': '21',
            'id_commune': '08',
            'name': 'Kostrzyn'
          },
          {
            'id_voivodeship': '30',
            'id_county': '21',
            'id_commune': '09',
            'name': 'Kórnik'
          },
          {
            'id_voivodeship': '30',
            'id_county': '21',
            'id_commune': '10',
            'name': 'Mosina'
          },
          {
            'id_voivodeship': '30',
            'id_county': '21',
            'id_commune': '11',
            'name': 'Murowana Goślina'
          },
          {
            'id_voivodeship': '30',
            'id_county': '21',
            'id_commune': '12',
            'name': 'Pobiedziska'
          },
          {
            'id_voivodeship': '30',
            'id_county': '21',
            'id_commune': '13',
            'name': 'Rokietnica'
          },
          {
            'id_voivodeship': '30',
            'id_county': '21',
            'id_commune': '14',
            'name': 'Stęszew'
          },
          {
            'id_voivodeship': '30',
            'id_county': '21',
            'id_commune': '15',
            'name': 'Suchy Las'
          },
          {
            'id_voivodeship': '30',
            'id_county': '21',
            'id_commune': '16',
            'name': 'Swarzędz'
          },
          {
            'id_voivodeship': '30',
            'id_county': '21',
            'id_commune': '17',
            'name': 'Tarnowo Podgórne'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '22',
        'name': 'rawicki',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '22',
            'id_commune': '01',
            'name': 'Bojanowo'
          },
          {
            'id_voivodeship': '30',
            'id_county': '22',
            'id_commune': '02',
            'name': 'Jutrosin'
          },
          {
            'id_voivodeship': '30',
            'id_county': '22',
            'id_commune': '03',
            'name': 'Miejska Górka'
          },
          {
            'id_voivodeship': '30',
            'id_county': '22',
            'id_commune': '04',
            'name': 'Pakosław'
          },
          {
            'id_voivodeship': '30',
            'id_county': '22',
            'id_commune': '05',
            'name': 'Rawicz'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '23',
        'name': 'słupecki',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '23',
            'id_commune': '01',
            'name': 'Słupca'
          },
          {
            'id_voivodeship': '30',
            'id_county': '23',
            'id_commune': '02',
            'name': 'Lądek'
          },
          {
            'id_voivodeship': '30',
            'id_county': '23',
            'id_commune': '03',
            'name': 'Orchowo'
          },
          {
            'id_voivodeship': '30',
            'id_county': '23',
            'id_commune': '04',
            'name': 'Ostrowite'
          },
          {
            'id_voivodeship': '30',
            'id_county': '23',
            'id_commune': '05',
            'name': 'Powidz'
          },
          {
            'id_voivodeship': '30',
            'id_county': '23',
            'id_commune': '07',
            'name': 'Strzałkowo'
          },
          {
            'id_voivodeship': '30',
            'id_county': '23',
            'id_commune': '08',
            'name': 'Zagórów'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '24',
        'name': 'szamotulski',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '24',
            'id_commune': '01',
            'name': 'Obrzycko'
          },
          {
            'id_voivodeship': '30',
            'id_county': '24',
            'id_commune': '02',
            'name': 'Duszniki'
          },
          {
            'id_voivodeship': '30',
            'id_county': '24',
            'id_commune': '03',
            'name': 'Kaźmierz'
          },
          {
            'id_voivodeship': '30',
            'id_county': '24',
            'id_commune': '05',
            'name': 'Ostroróg'
          },
          {
            'id_voivodeship': '30',
            'id_county': '24',
            'id_commune': '06',
            'name': 'Pniewy'
          },
          {
            'id_voivodeship': '30',
            'id_county': '24',
            'id_commune': '07',
            'name': 'Szamotuły'
          },
          {
            'id_voivodeship': '30',
            'id_county': '24',
            'id_commune': '08',
            'name': 'Wronki'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '25',
        'name': 'średzki',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '25',
            'id_commune': '01',
            'name': 'Dominowo'
          },
          {
            'id_voivodeship': '30',
            'id_county': '25',
            'id_commune': '02',
            'name': 'Krzykosy'
          },
          {
            'id_voivodeship': '30',
            'id_county': '25',
            'id_commune': '03',
            'name': 'Nowe Miasto nad Wartą'
          },
          {
            'id_voivodeship': '30',
            'id_county': '25',
            'id_commune': '04',
            'name': 'Środa Wielkopolska'
          },
          {
            'id_voivodeship': '30',
            'id_county': '25',
            'id_commune': '05',
            'name': 'Zaniemyśl'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '26',
        'name': 'śremski',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '26',
            'id_commune': '01',
            'name': 'Brodnica'
          },
          {
            'id_voivodeship': '30',
            'id_county': '26',
            'id_commune': '02',
            'name': 'Dolsk'
          },
          {
            'id_voivodeship': '30',
            'id_county': '26',
            'id_commune': '03',
            'name': 'Książ Wielkopolski'
          },
          {
            'id_voivodeship': '30',
            'id_county': '26',
            'id_commune': '04',
            'name': 'Śrem'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '27',
        'name': 'turecki',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '27',
            'id_commune': '01',
            'name': 'Turek'
          },
          {
            'id_voivodeship': '30',
            'id_county': '27',
            'id_commune': '02',
            'name': 'Brudzew'
          },
          {
            'id_voivodeship': '30',
            'id_county': '27',
            'id_commune': '03',
            'name': 'Dobra'
          },
          {
            'id_voivodeship': '30',
            'id_county': '27',
            'id_commune': '04',
            'name': 'Kawęczyn'
          },
          {
            'id_voivodeship': '30',
            'id_county': '27',
            'id_commune': '05',
            'name': 'Malanów'
          },
          {
            'id_voivodeship': '30',
            'id_county': '27',
            'id_commune': '06',
            'name': 'Przykona'
          },
          {
            'id_voivodeship': '30',
            'id_county': '27',
            'id_commune': '07',
            'name': 'Tuliszków'
          },
          {
            'id_voivodeship': '30',
            'id_county': '27',
            'id_commune': '09',
            'name': 'Władysławów'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '28',
        'name': 'wągrowiecki',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '28',
            'id_commune': '01',
            'name': 'Wągrowiec'
          },
          {
            'id_voivodeship': '30',
            'id_county': '28',
            'id_commune': '02',
            'name': 'Damasławek'
          },
          {
            'id_voivodeship': '30',
            'id_county': '28',
            'id_commune': '03',
            'name': 'Gołańcz'
          },
          {
            'id_voivodeship': '30',
            'id_county': '28',
            'id_commune': '04',
            'name': 'Mieścisko'
          },
          {
            'id_voivodeship': '30',
            'id_county': '28',
            'id_commune': '05',
            'name': 'Skoki'
          },
          {
            'id_voivodeship': '30',
            'id_county': '28',
            'id_commune': '06',
            'name': 'Wapno'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '29',
        'name': 'wolsztyński',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '29',
            'id_commune': '01',
            'name': 'Przemęt'
          },
          {
            'id_voivodeship': '30',
            'id_county': '29',
            'id_commune': '02',
            'name': 'Siedlec'
          },
          {
            'id_voivodeship': '30',
            'id_county': '29',
            'id_commune': '03',
            'name': 'Wolsztyn'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '30',
        'name': 'wrzesiński',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '30',
            'id_commune': '01',
            'name': 'Kołaczkowo'
          },
          {
            'id_voivodeship': '30',
            'id_county': '30',
            'id_commune': '02',
            'name': 'Miłosław'
          },
          {
            'id_voivodeship': '30',
            'id_county': '30',
            'id_commune': '03',
            'name': 'Nekla'
          },
          {
            'id_voivodeship': '30',
            'id_county': '30',
            'id_commune': '04',
            'name': 'Pyzdry'
          },
          {
            'id_voivodeship': '30',
            'id_county': '30',
            'id_commune': '05',
            'name': 'Września'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '31',
        'name': 'złotowski',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '31',
            'id_commune': '01',
            'name': 'Złotów'
          },
          {
            'id_voivodeship': '30',
            'id_county': '31',
            'id_commune': '02',
            'name': 'Jastrowie'
          },
          {
            'id_voivodeship': '30',
            'id_county': '31',
            'id_commune': '03',
            'name': 'Krajenka'
          },
          {
            'id_voivodeship': '30',
            'id_county': '31',
            'id_commune': '04',
            'name': 'Lipka'
          },
          {
            'id_voivodeship': '30',
            'id_county': '31',
            'id_commune': '05',
            'name': 'Okonek'
          },
          {
            'id_voivodeship': '30',
            'id_county': '31',
            'id_commune': '06',
            'name': 'Tarnówka'
          },
          {
            'id_voivodeship': '30',
            'id_county': '31',
            'id_commune': '07',
            'name': 'Zakrzewo'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '61',
        'name': 'Kalisz',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '61',
            'id_commune': '01',
            'name': 'Kalisz'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '62',
        'name': 'Konin',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '62',
            'id_commune': '01',
            'name': 'Konin'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '63',
        'name': 'Leszno',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '63',
            'id_commune': '01',
            'name': 'Leszno'
          }
        ]
      },
      {
        'id_voivodeship': '30',
        'id_county': '64',
        'name': 'Poznań',
        'communes': [
          {
            'id_voivodeship': '30',
            'id_county': '64',
            'id_commune': '01',
            'name': 'Poznań'
          },
        ]
      }
    ]
  },
  {
    'id_voivodeship': '32',
    'name': 'ZACHODNIOPOMORSKIE',
    'counties': [
      {
        'id_voivodeship': '32',
        'id_county': '01',
        'name': 'białogardzki',
        'communes': [
          {
            'id_voivodeship': '32',
            'id_county': '01',
            'id_commune': '01',
            'name': 'Białogard'
          },
          {
            'id_voivodeship': '32',
            'id_county': '01',
            'id_commune': '03',
            'name': 'Karlino'
          },
          {
            'id_voivodeship': '32',
            'id_county': '01',
            'id_commune': '04',
            'name': 'Tychowo'
          }
        ]
      },
      {
        'id_voivodeship': '32',
        'id_county': '02',
        'name': 'choszczeński',
        'communes': [
          {
            'id_voivodeship': '32',
            'id_county': '02',
            'id_commune': '01',
            'name': 'Bierzwnik'
          },
          {
            'id_voivodeship': '32',
            'id_county': '02',
            'id_commune': '02',
            'name': 'Choszczno'
          },
          {
            'id_voivodeship': '32',
            'id_county': '02',
            'id_commune': '03',
            'name': 'Drawno'
          },
          {
            'id_voivodeship': '32',
            'id_county': '02',
            'id_commune': '04',
            'name': 'Krzęcin'
          },
          {
            'id_voivodeship': '32',
            'id_county': '02',
            'id_commune': '05',
            'name': 'Pełczyce'
          },
          {
            'id_voivodeship': '32',
            'id_county': '02',
            'id_commune': '06',
            'name': 'Recz'
          }
        ]
      },
      {
        'id_voivodeship': '32',
        'id_county': '03',
        'name': 'drawski',
        'communes': [
          {
            'id_voivodeship': '32',
            'id_county': '03',
            'id_commune': '01',
            'name': 'Czaplinek'
          },
          {
            'id_voivodeship': '32',
            'id_county': '03',
            'id_commune': '02',
            'name': 'Drawsko Pomorskie'
          },
          {
            'id_voivodeship': '32',
            'id_county': '03',
            'id_commune': '03',
            'name': 'Kalisz Pomorski'
          },
          {
            'id_voivodeship': '32',
            'id_county': '03',
            'id_commune': '05',
            'name': 'Wierzchowo'
          },
          {
            'id_voivodeship': '32',
            'id_county': '03',
            'id_commune': '06',
            'name': 'Złocieniec'
          }
        ]
      },
      {
        'id_voivodeship': '32',
        'id_county': '04',
        'name': 'goleniowski',
        'communes': [
          {
            'id_voivodeship': '32',
            'id_county': '04',
            'id_commune': '02',
            'name': 'Goleniów'
          },
          {
            'id_voivodeship': '32',
            'id_county': '04',
            'id_commune': '03',
            'name': 'Maszewo'
          },
          {
            'id_voivodeship': '32',
            'id_county': '04',
            'id_commune': '04',
            'name': 'Nowogard'
          },
          {
            'id_voivodeship': '32',
            'id_county': '04',
            'id_commune': '05',
            'name': 'Osina'
          },
          {
            'id_voivodeship': '32',
            'id_county': '04',
            'id_commune': '06',
            'name': 'Przybiernów'
          },
          {
            'id_voivodeship': '32',
            'id_county': '04',
            'id_commune': '07',
            'name': 'Stepnica'
          }
        ]
      },
      {
        'id_voivodeship': '32',
        'id_county': '05',
        'name': 'gryficki',
        'communes': [
          {
            'id_voivodeship': '32',
            'id_county': '05',
            'id_commune': '01',
            'name': 'Brojce'
          },
          {
            'id_voivodeship': '32',
            'id_county': '05',
            'id_commune': '02',
            'name': 'Gryfice'
          },
          {
            'id_voivodeship': '32',
            'id_county': '05',
            'id_commune': '03',
            'name': 'Karnice'
          },
          {
            'id_voivodeship': '32',
            'id_county': '05',
            'id_commune': '04',
            'name': 'Płoty'
          },
          {
            'id_voivodeship': '32',
            'id_county': '05',
            'id_commune': '07',
            'name': 'Rewal'
          },
          {
            'id_voivodeship': '32',
            'id_county': '05',
            'id_commune': '08',
            'name': 'Trzebiatów'
          }
        ]
      },
      {
        'id_voivodeship': '32',
        'id_county': '06',
        'name': 'gryfiński',
        'communes': [
          {
            'id_voivodeship': '32',
            'id_county': '06',
            'id_commune': '01',
            'name': 'Banie'
          },
          {
            'id_voivodeship': '32',
            'id_county': '06',
            'id_commune': '02',
            'name': 'Cedynia'
          },
          {
            'id_voivodeship': '32',
            'id_county': '06',
            'id_commune': '03',
            'name': 'Chojna'
          },
          {
            'id_voivodeship': '32',
            'id_county': '06',
            'id_commune': '04',
            'name': 'Gryfino'
          },
          {
            'id_voivodeship': '32',
            'id_county': '06',
            'id_commune': '05',
            'name': 'Mieszkowice'
          },
          {
            'id_voivodeship': '32',
            'id_county': '06',
            'id_commune': '06',
            'name': 'Moryń'
          },
          {
            'id_voivodeship': '32',
            'id_county': '06',
            'id_commune': '07',
            'name': 'Stare Czarnowo'
          },
          {
            'id_voivodeship': '32',
            'id_county': '06',
            'id_commune': '08',
            'name': 'Trzcińsko-Zdrój'
          },
          {
            'id_voivodeship': '32',
            'id_county': '06',
            'id_commune': '09',
            'name': 'Widuchowa'
          }
        ]
      },
      {
        'id_voivodeship': '32',
        'id_county': '07',
        'name': 'kamieński',
        'communes': [
          {
            'id_voivodeship': '32',
            'id_county': '07',
            'id_commune': '01',
            'name': 'Dziwnów'
          },
          {
            'id_voivodeship': '32',
            'id_county': '07',
            'id_commune': '02',
            'name': 'Golczewo'
          },
          {
            'id_voivodeship': '32',
            'id_county': '07',
            'id_commune': '03',
            'name': 'Kamień Pomorski'
          },
          {
            'id_voivodeship': '32',
            'id_county': '07',
            'id_commune': '04',
            'name': 'Międzyzdroje'
          },
          {
            'id_voivodeship': '32',
            'id_county': '07',
            'id_commune': '05',
            'name': 'Świerzno'
          },
          {
            'id_voivodeship': '32',
            'id_county': '07',
            'id_commune': '06',
            'name': 'Wolin'
          }
        ]
      },
      {
        'id_voivodeship': '32',
        'id_county': '08',
        'name': 'kołobrzeski',
        'communes': [
          {
            'id_voivodeship': '32',
            'id_county': '08',
            'id_commune': '01',
            'name': 'Kołobrzeg'
          },
          {
            'id_voivodeship': '32',
            'id_county': '08',
            'id_commune': '02',
            'name': 'Dygowo'
          },
          {
            'id_voivodeship': '32',
            'id_county': '08',
            'id_commune': '03',
            'name': 'Gościno'
          },
          {
            'id_voivodeship': '32',
            'id_county': '08',
            'id_commune': '05',
            'name': 'Rymań'
          },
          {
            'id_voivodeship': '32',
            'id_county': '08',
            'id_commune': '06',
            'name': 'Siemyśl'
          },
          {
            'id_voivodeship': '32',
            'id_county': '08',
            'id_commune': '07',
            'name': 'Ustronie Morskie'
          }
        ]
      },
      {
        'id_voivodeship': '32',
        'id_county': '09',
        'name': 'koszaliński',
        'communes': [
          {
            'id_voivodeship': '32',
            'id_county': '09',
            'id_commune': '01',
            'name': 'Będzino'
          },
          {
            'id_voivodeship': '32',
            'id_county': '09',
            'id_commune': '02',
            'name': 'Biesiekierz'
          },
          {
            'id_voivodeship': '32',
            'id_county': '09',
            'id_commune': '03',
            'name': 'Bobolice'
          },
          {
            'id_voivodeship': '32',
            'id_county': '09',
            'id_commune': '04',
            'name': 'Manowo'
          },
          {
            'id_voivodeship': '32',
            'id_county': '09',
            'id_commune': '05',
            'name': 'Mielno'
          },
          {
            'id_voivodeship': '32',
            'id_county': '09',
            'id_commune': '06',
            'name': 'Polanów'
          },
          {
            'id_voivodeship': '32',
            'id_county': '09',
            'id_commune': '07',
            'name': 'Sianów'
          },
          {
            'id_voivodeship': '32',
            'id_county': '09',
            'id_commune': '08',
            'name': 'Świeszyno'
          }
        ]
      },
      {
        'id_voivodeship': '32',
        'id_county': '10',
        'name': 'myśliborski',
        'communes': [
          {
            'id_voivodeship': '32',
            'id_county': '10',
            'id_commune': '01',
            'name': 'Barlinek'
          },
          {
            'id_voivodeship': '32',
            'id_county': '10',
            'id_commune': '02',
            'name': 'Boleszkowice'
          },
          {
            'id_voivodeship': '32',
            'id_county': '10',
            'id_commune': '03',
            'name': 'Dębno'
          },
          {
            'id_voivodeship': '32',
            'id_county': '10',
            'id_commune': '04',
            'name': 'Myślibórz'
          },
          {
            'id_voivodeship': '32',
            'id_county': '10',
            'id_commune': '05',
            'name': 'Nowogródek Pomorski'
          }
        ]
      },
      {
        'id_voivodeship': '32',
        'id_county': '11',
        'name': 'policki',
        'communes': [
          {
            'id_voivodeship': '32',
            'id_county': '11',
            'id_commune': '01',
            'name': 'Dobra (Szczecińska)'
          },
          {
            'id_voivodeship': '32',
            'id_county': '11',
            'id_commune': '02',
            'name': 'Kołbaskowo'
          },
          {
            'id_voivodeship': '32',
            'id_county': '11',
            'id_commune': '03',
            'name': 'Nowe Warpno'
          },
          {
            'id_voivodeship': '32',
            'id_county': '11',
            'id_commune': '04',
            'name': 'Police'
          }
        ]
      },
      {
        'id_voivodeship': '32',
        'id_county': '12',
        'name': 'pyrzycki',
        'communes': [
          {
            'id_voivodeship': '32',
            'id_county': '12',
            'id_commune': '01',
            'name': 'Bielice'
          },
          {
            'id_voivodeship': '32',
            'id_county': '12',
            'id_commune': '02',
            'name': 'Kozielice'
          },
          {
            'id_voivodeship': '32',
            'id_county': '12',
            'id_commune': '03',
            'name': 'Lipiany'
          },
          {
            'id_voivodeship': '32',
            'id_county': '12',
            'id_commune': '04',
            'name': 'Przelewice'
          },
          {
            'id_voivodeship': '32',
            'id_county': '12',
            'id_commune': '05',
            'name': 'Pyrzyce'
          },
          {
            'id_voivodeship': '32',
            'id_county': '12',
            'id_commune': '06',
            'name': 'Warnice'
          }
        ]
      },
      {
        'id_voivodeship': '32',
        'id_county': '13',
        'name': 'sławieński',
        'communes': [
          {
            'id_voivodeship': '32',
            'id_county': '13',
            'id_commune': '01',
            'name': 'Darłowo'
          },
          {
            'id_voivodeship': '32',
            'id_county': '13',
            'id_commune': '02',
            'name': 'Sławno'
          },
          {
            'id_voivodeship': '32',
            'id_county': '13',
            'id_commune': '04',
            'name': 'Malechowo'
          },
          {
            'id_voivodeship': '32',
            'id_county': '13',
            'id_commune': '05',
            'name': 'Postomino'
          }
        ]
      },
      {
        'id_voivodeship': '32',
        'id_county': '14',
        'name': 'stargardzki',
        'communes': [
          {
            'id_voivodeship': '32',
            'id_county': '14',
            'id_commune': '01',
            'name': 'Stargard'
          },
          {
            'id_voivodeship': '32',
            'id_county': '14',
            'id_commune': '02',
            'name': 'Chociwel'
          },
          {
            'id_voivodeship': '32',
            'id_county': '14',
            'id_commune': '03',
            'name': 'Dobrzany'
          },
          {
            'id_voivodeship': '32',
            'id_county': '14',
            'id_commune': '04',
            'name': 'Dolice'
          },
          {
            'id_voivodeship': '32',
            'id_county': '14',
            'id_commune': '05',
            'name': 'Ińsko'
          },
          {
            'id_voivodeship': '32',
            'id_county': '14',
            'id_commune': '06',
            'name': 'Kobylanka'
          },
          {
            'id_voivodeship': '32',
            'id_county': '14',
            'id_commune': '08',
            'name': 'Marianowo'
          },
          {
            'id_voivodeship': '32',
            'id_county': '14',
            'id_commune': '09',
            'name': 'Stara Dąbrowa'
          },
          {
            'id_voivodeship': '32',
            'id_county': '14',
            'id_commune': '11',
            'name': 'Suchań'
          }
        ]
      },
      {
        'id_voivodeship': '32',
        'id_county': '15',
        'name': 'szczecinecki',
        'communes': [
          {
            'id_voivodeship': '32',
            'id_county': '15',
            'id_commune': '01',
            'name': 'Szczecinek'
          },
          {
            'id_voivodeship': '32',
            'id_county': '15',
            'id_commune': '02',
            'name': 'Barwice'
          },
          {
            'id_voivodeship': '32',
            'id_county': '15',
            'id_commune': '03',
            'name': 'Biały Bór'
          },
          {
            'id_voivodeship': '32',
            'id_county': '15',
            'id_commune': '04',
            'name': 'Borne Sulinowo'
          },
          {
            'id_voivodeship': '32',
            'id_county': '15',
            'id_commune': '05',
            'name': 'Grzmiąca'
          }
        ]
      },
      {
        'id_voivodeship': '32',
        'id_county': '16',
        'name': 'świdwiński',
        'communes': [
          {
            'id_voivodeship': '32',
            'id_county': '16',
            'id_commune': '01',
            'name': 'Świdwin'
          },
          {
            'id_voivodeship': '32',
            'id_county': '16',
            'id_commune': '02',
            'name': 'Brzeżno'
          },
          {
            'id_voivodeship': '32',
            'id_county': '16',
            'id_commune': '03',
            'name': 'Połczyn-Zdrój'
          },
          {
            'id_voivodeship': '32',
            'id_county': '16',
            'id_commune': '04',
            'name': 'Rąbino'
          },
          {
            'id_voivodeship': '32',
            'id_county': '16',
            'id_commune': '05',
            'name': 'Sławoborze'
          }
        ]
      },
      {
        'id_voivodeship': '32',
        'id_county': '17',
        'name': 'wałecki',
        'communes': [
          {
            'id_voivodeship': '32',
            'id_county': '17',
            'id_commune': '01',
            'name': 'Wałcz'
          },
          {
            'id_voivodeship': '32',
            'id_county': '17',
            'id_commune': '02',
            'name': 'Człopa'
          },
          {
            'id_voivodeship': '32',
            'id_county': '17',
            'id_commune': '03',
            'name': 'Mirosławiec'
          },
          {
            'id_voivodeship': '32',
            'id_county': '17',
            'id_commune': '04',
            'name': 'Tuczno'
          }
        ]
      },
      {
        'id_voivodeship': '32',
        'id_county': '18',
        'name': 'łobeski',
        'communes': [
          {
            'id_voivodeship': '32',
            'id_county': '18',
            'id_commune': '01',
            'name': 'Dobra'
          },
          {
            'id_voivodeship': '32',
            'id_county': '18',
            'id_commune': '02',
            'name': 'Łobez'
          },
          {
            'id_voivodeship': '32',
            'id_county': '18',
            'id_commune': '03',
            'name': 'Radowo Małe'
          },
          {
            'id_voivodeship': '32',
            'id_county': '18',
            'id_commune': '04',
            'name': 'Resko'
          },
          {
            'id_voivodeship': '32',
            'id_county': '18',
            'id_commune': '05',
            'name': 'Węgorzyno'
          }
        ]
      },
      {
        'id_voivodeship': '32',
        'id_county': '61',
        'name': 'Koszalin',
        'communes': [
          {
            'id_voivodeship': '32',
            'id_county': '61',
            'id_commune': '01',
            'name': 'Koszalin'
          }
        ]
      },
      {
        'id_voivodeship': '32',
        'id_county': '62',
        'name': 'Szczecin',
        'communes': [
          {
            'id_voivodeship': '32',
            'id_county': '62',
            'id_commune': '01',
            'name': 'Szczecin'
          }
        ]
      },
      {
        'id_voivodeship': '32',
        'id_county': '63',
        'name': 'Świnoujście',
        'communes': [
          {
            'id_voivodeship': '32',
            'id_county': '63',
            'id_commune': '01',
            'name': 'Świnoujście'
          }
        ]
      }
    ]
  }
];
