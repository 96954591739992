<div class="landing-background">
  <div class="content" *ngIf="landingTexts">
    <div class="top">
      <div class="logo"></div>
      <div class="action">
        <div class="enter" *ngIf="intervalTimer === null">
          <a class="button-blue" [routerLink]="'/'">
            {{ "landing.enterGameBtn" | myTranslate }}
          </a>
          <button
            class="button-blue golden"
            *ngIf="(isInstallable.pwa || isInstallable.onlyIOS) && (!window.matchMedia('(display-mode: standalone)').matches)"
            (click)="addApplicationToHomeScreen()">
            {{ "landing.enterPwaBtn" | myTranslate }}
          </button>
        </div>
        <div class="timer" *ngIf="intervalTimer !== null && timer !== null">
          <h5 class="timer-top">{{ "landing.timer" | myTranslate }}</h5>
          <div class="counter">{{ convertTime(timer) }}</div>
        </div>
      </div>
      <div class="steps-container">
        <h2 [innerHTML]="'landing.stepTitle' | myTranslate"></h2>
        <div class="steps-wrapper">
          <div class="steps-graphic"></div>
          <div class="steps-description">
            <p>{{ "landing.stepDescriptionOne" | myTranslate }}</p>
            <p>{{ "landing.stepDescriptionTwo" | myTranslate }}</p>
            <p>{{ "landing.stepDescriptionThree" | myTranslate }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <h3 class="title">{{ landingTexts.title }}</h3>
      <div class="description" [innerHTML]="landingTexts.text_block"></div>
      <div class="rules-wrapper">
        <ng-container *ngIf="landingTexts.download_button_text">
          <a
            *ngIf="landingTexts.download_button_file_url"
            [href]="landingTexts.download_button_file_url"
            target="_blank"
            class="button-transparent"
            >{{ landingTexts.download_button_text }}</a
          >
          <a
            *ngIf="!landingTexts.download_button_file_url && landingTexts.download_button_external_url"
            [href]="landingTexts.download_button_external_url"
            target="_blank"
            class="button-transparent"
            >{{ landingTexts.download_button_text }}</a
          >
        </ng-container>
      </div>

      <div class="action">
        <div class="enter" *ngIf="intervalTimer === null">
          <h4>{{ "landing.teaser" | myTranslate }}</h4>
          <div class="bottom-buttons-container">
            <a [class.hide-button]="!window['NativeInterface']" class="button-blue" [routerLink]="'/'">
              {{ "landing.enterGameBtn" | myTranslate }}
            </a>
            <button
              class="button-blue golden"
              *ngIf="(isInstallable.pwa || isInstallable.onlyIOS) && (!window.matchMedia('(display-mode: standalone)').matches)"
              (click)="addApplicationToHomeScreen()">
              {{ "landing.enterPwaBtn" | myTranslate }}
            </button>
          </div>
        </div>
        <div class="timer transparent" *ngIf="intervalTimer !== null && timer !== null">
          <h5>{{ "landing.timer" | myTranslate }}</h5>
          <div class="counter">{{ convertTime(timer) }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="ios-install-label" *ngIf="iOSInstallVisibility" (click)="toggleiOSInstallLabelVisibility()">
<!--  <i class="svg-icon info"></i>-->
  <i class="fa-light fa-circle-info"></i>
  <p>
    {{ "landing.ios-install" | myTranslate }}
    <b>{{ "landing.ios-install-share" | myTranslate }}</b>
    {{ "landing.ios-install-add" | myTranslate }}
    <b>{{ "landing.ios-install-screen" | myTranslate }}</b>
  </p>
</div>
