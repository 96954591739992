import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { SharedModule } from "../shared/shared.module";
import { AuthAbstractModule } from "./abstract/auth-abstract.module";
import { AuthService } from "./api/services/auth.service";
import { AuthRoutingModule } from "./auth-routing.module";
import { ChangePasswordComponent } from "./base/core/components/change-password/change-password.component";
import { ForgotPasswordComponent } from "./base/core/components/forgot-password/forgot-password.component";
import { LandingComponent } from "./base/core/components/landing/landing.component";
import { LoginComponent } from "./base/core/components/login/login.component";
import { LoginExternalComponent } from "./base/core/components/login-external/login-external.component";
import { PlayerChooseComponent } from "./base/core/components/player-choose/player-choose.component";
import { ReauthorizeComponent } from "./base/core/components/reauthorize/reauthorize.component";
import { RegistrationAComponent } from "./base/core/components/registration/variant-a/registration-a.component";
import { RegistrationConfirmAComponent } from "./base/core/components/registration-confirm/variant-a/registration-confirm-a.component";
import { ResetPasswordComponent } from "./base/core/components/reset-password/reset-password.component";
import { SocketConnectionErrorComponent } from "./base/core/components/socket-connection-error/socket-connection-error.component";
import { TempTokenExchangeComponent } from "./base/core/components/temp-token-exchange/temp-token-exchange.component";
import { CUSTOM_COMPONENTS } from "./consts/custom/components.const";
import { CUSTOM_IMPORTS } from "./consts/custom/imports.const";
import { CUSTOM_PROVIDERS } from "./consts/custom/providers.const";
import { ApiInterceptor } from "./interceptor/api.interceptor";
import { FingerprintInterceptor } from "./interceptor/fingerprint.interceptor";
import { TokenInterceptor } from "./interceptor/token.interceptor";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    AuthAbstractModule,
    AuthRoutingModule,
    LoginExternalComponent,
    ...CUSTOM_IMPORTS,
  ],
  declarations: [
    LoginComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PlayerChooseComponent,
    TempTokenExchangeComponent,
    RegistrationConfirmAComponent,
    RegistrationAComponent,
    ReauthorizeComponent,
    SocketConnectionErrorComponent,
    LandingComponent,
    ...CUSTOM_COMPONENTS,
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FingerprintInterceptor,
      multi: true,
    },
    ...CUSTOM_PROVIDERS,
  ],
})
export class AuthModule {}
