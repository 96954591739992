import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Swiper, SwiperOptions } from "swiper/types";

import { swiperInjectionStyles } from "../../../../../../../../../styles/swiper/swiper-injection-styles";
import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { EventEmitterDialogsService } from "../../../../../../../../core/services/core/event-emitter-dialogs.service";
import { generateEachPages } from "../../../../../../../shared/helpers/generate-pages.helper";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { STOCK_VIEW } from "../../../../../shared-ui/mobile/consts/stock-view.const";
import { ApiMissionGroupService } from "../../../../api/core/services/api-mission-group.service";
import { EVENT_DIALOGS_NAMES_MISSION } from "../../../../consts/core/event-dialogs/event-names";

@Component({
  selector: "app-mission-group-list",
  templateUrl: "./mission-group-list.component.html",
})
export class MissionGroupListComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ApiMissionGroupService) apiMissionGroupService: ApiMissionGroupService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<MissionGroupListComponent>;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(ChangeDetectorRef) changeDetectorRef: ChangeDetectorRef;
  @OwInject(MAT_DIALOG_DATA) data: {
    index: number;
  };
  STOCK_VIEW = STOCK_VIEW;
  sliderActiveIndex;
  groups: {
    config: {
      itemPerPage: number;
    };
    pages: {
      mission_group_id: number;
      icon: string;
      name: string;
      reward_product_id: number;
      total_missions: number;
      completed_missions: number;
      is_reward_collected: boolean;
    }[][];
    items: any[];
  } = {
    config: {
      itemPerPage: 3,
    },
    pages: [],
    items: [],
  };

  initSwiper = false;
  swiper: Swiper = null;
  @ViewChild("swiperRef", { static: false })
  swiperRef: ElementRef | undefined;
  currentSlideIndex = 0;
  config: SwiperOptions = {
    slidesPerView: 3,
    initialSlide: 0,
    centeredSlides: true,
    pagination: {
      dynamicBullets: true,
      clickable: true,
    },
    injectStyles: [swiperInjectionStyles],
  };

  ngOnInit() {
    this.getMissionGroups();
  }

  setSwiper() {
    this.initSwiper = false;
    this.swiper = null;
    this.changeDetectorRef.detectChanges();
    this.initSwiper = true;
    this.changeDetectorRef.detectChanges();
    if (this.swiperRef?.nativeElement) {
      this.swiper = this.swiperRef.nativeElement.swiper;
      this.swiper.on("slideChange", swiper => {
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  prevSlide() {
    this.swiper.slidePrev();
    this.currentSlideIndex = this.swiper.activeIndex;
  }

  nextSlide() {
    this.swiper.slideNext();
    this.currentSlideIndex = this.swiper.activeIndex;
  }

  getMissionGroups() {
    this.apiMissionGroupService.getMissionGroups().subscribe(resp => {
      this.groups.items = resp;
      this.groups.pages = [];
      this.generatePages();
      this.setActivePage(this.data.index || 0);
      this.setSwiper();
    });
  }

  generatePages() {
    this.groups = generateEachPages(this.groups);
  }

  setActivePage(index: number) {
    this.sliderActiveIndex = Math.floor(index / 3);
  }

  openMissionGroupDetails(mission: any, index: number) {
    this.matDialogRef.close();
    setTimeout(() => {
      this.eventEmitterDialogsService.emitter.emit({
        name: EVENT_DIALOGS_NAMES_MISSION.MISSION_GROUP_DETAILS,
        config: {
          data: {
            missionGroups: this.groups.items,
            missionGroupId: mission.mission_group_id,
            index,
          },
        },
      });
    });
  }
}
