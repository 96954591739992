import { HttpRequest } from "@angular/common/http";

import { generateHash } from "./generate-hash.helper";

export function checkIsDoubleRequest(request: HttpRequest<any>, requests: HttpRequest<any>[]) {
  return requests.some(r => {
    const bodyHash1 = generateHash(r.body);
    const bodyHash2 = generateHash(request.body);

    return r.urlWithParams === request.urlWithParams && r.method === request.method && bodyHash1 === bodyHash2;
  });
}
