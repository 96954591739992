import { Component, OnInit } from "@angular/core";

import { AbstractResetPasswordComponent } from "../../../../abstract/abstract-reset-password.component";

@Component({
  selector: "auth-reset-password",
  templateUrl: "./reset-password.component.html",
})
export class ResetPasswordComponent extends AbstractResetPasswordComponent implements OnInit {
  ngOnInit() {
    super.ngOnInit();
    this.subscribeRoutingParams();
  }
}
