import { PositionOffset } from "../interfaces/shared";

export const WORLD_SCENE_WORLD_WIDTH = 3983;
export const WORLD_SCENE_WORLD_HEIGHT = 1482;

export const WORLD_BOOT_SCENE = "WORLD_BOOT_SCENE";
export const WORLD_PRELOAD_SCENE = "WORLD_PRELOAD_SCENE";
export const WORLD_MAIN_SCENE = "WORLD_MAIN_SCENE";

export const WORLD_BOARD_CENTER_OFFSET: PositionOffset = {
  x: 0,
  y: 0,
};

export const WORLD_TILED_OFFSET = {
  x: 0,
  y: -1699,
};

export const PATHFINDER_GRID_SIZE = {
  width: 100,
  height: 37,
};
