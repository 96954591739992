import { Directive, Injectable } from "@angular/core";

import { AbstractMessageDetailsNormalComponent } from "./abstract-message-details-normal.component";

@Directive()
@Injectable()
export abstract class AbstractMessageDetailsComicsComponent extends AbstractMessageDetailsNormalComponent {
  selectedItem = 0;

  loadItem(item) {
    if (this.message.gallery[item]) {
      this.selectedItem = item;
    }
  }
}
