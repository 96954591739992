export const BASIC_ACTIONS_MOCK = {
  DETAILS: {
    items: [
      {
        basic_action: { name: "Sprzeda\u017c produkt\u00f3w z grupy 1.3", sticky_order: 1 },
        date: "2020-06-01",
        prizes: [{ currency_id: 1, quantity: 974, amount: 0, prize_base_amount: 1 }],
      },
      {
        basic_action: { name: "Sprzeda\u017c produkt\u00f3w z grupy 2.3", sticky_order: 2 },
        date: "2020-06-01",
        prizes: [{ currency_id: 1, quantity: 0, amount: 0, prize_base_amount: 1 }],
      },
      {
        basic_action: { name: "Sprzeda\u017c produkt\u00f3w", sticky_order: null },
        date: "2020-06-01",
        prizes: [{ currency_id: 1, quantity: 44, amount: 0, prize_base_amount: 6 }],
      },
      {
        basic_action: { name: "Sprzedaz produkt\u00f3w NPW", sticky_order: null },
        date: "2020-06-01",
        prizes: [{ currency_id: 1, quantity: 0, amount: 0, prize_base_amount: 10 }],
      },
      {
        basic_action: { name: "Sprzeda\u017c Gwarancji", sticky_order: null },
        date: "2020-06-01",
        prizes: [{ currency_id: 1, quantity: 2, amount: 0, prize_base_amount: 7 }],
      },
      {
        basic_action: { name: "Sprzeda\u017c aktywna", sticky_order: null },
        date: "2020-06-01",
        prizes: [{ currency_id: 1, quantity: 2, amount: 0, prize_base_amount: 4 }],
      },
      {
        basic_action: { name: "Sprzeda\u017c us\u0142ug", sticky_order: null },
        date: "2020-06-01",
        prizes: [{ currency_id: 1, quantity: 0, amount: 0, prize_base_amount: 6 }],
      },
      {
        basic_action: { name: "Wydanie zwrotu na magazyn", sticky_order: null },
        date: "2020-06-01",
        prizes: [{ currency_id: 1, quantity: 0, amount: 0, prize_base_amount: 1 }],
      },
      {
        basic_action: { name: "Przyj\u0119cie towaru do sklepu", sticky_order: null },
        date: "2020-06-01",
        prizes: [{ currency_id: 1, quantity: 0, amount: 0, prize_base_amount: 1 }],
      },
      {
        basic_action: { name: "Sprawdzenie cen kolektorem", sticky_order: null },
        date: "2020-06-01",
        prizes: [{ currency_id: 1, quantity: 0, amount: 0, prize_base_amount: 1 }],
      },
      {
        basic_action: { name: "Obs\u0142uga kasy", sticky_order: null },
        date: "2020-06-01",
        prizes: [{ currency_id: 1, quantity: 83, amount: 0, prize_base_amount: 1 }],
      },
      {
        basic_action: { name: "Zbieranie adres\u00f3w email", sticky_order: null },
        date: "2020-06-01",
        prizes: [{ currency_id: 1, quantity: 0, amount: 0, prize_base_amount: 1 }],
      },
      {
        basic_action: { name: "Wystawianie faktur", sticky_order: null },
        date: "2020-06-01",
        prizes: [{ currency_id: 1, quantity: 4, amount: 0, prize_base_amount: 2 }],
      },
      {
        basic_action: { name: "Sprzeda\u017c na raty dla sali sprzeda\u017cy", sticky_order: null },
        date: "2020-06-01",
        prizes: [{ currency_id: 1, quantity: 1, amount: 0, prize_base_amount: 3 }],
      },
      {
        basic_action: { name: "Zaliczenie testu e-learning", sticky_order: null },
        date: "2020-06-01",
        prizes: [{ currency_id: 1, quantity: 0, amount: 0, prize_base_amount: 20 }],
      },
      {
        basic_action: { name: "Udzia\u0142 w szkoleniu w sklepie", sticky_order: null },
        date: "2020-06-01",
        prizes: [{ currency_id: 1, quantity: 0, amount: 0, prize_base_amount: 120 }],
      },
      {
        basic_action: { name: "Udzia\u0142 w szkoleniu wyjazdowym", sticky_order: null },
        date: "2020-06-01",
        prizes: [{ currency_id: 1, quantity: 0, amount: 0, prize_base_amount: 200 }],
      },
    ],
    previous: null,
    next: null,
    monthly_income: 8000,
  },
  CURRENT: [
    {
      currency_prizes: [{ currency_id: 1, amount: 1 }],
      name: "Sprzeda\u017c produkt\u00f3w z grupy 1.3",
      sticky_order: 1,
    },
    {
      currency_prizes: [{ currency_id: 1, amount: 1 }],
      name: "Sprzeda\u017c produkt\u00f3w z grupy 2.3",
      sticky_order: 2,
    },
    {
      currency_prizes: [{ currency_id: 1, amount: 6 }],
      name: "Sprzeda\u017c produkt\u00f3w",
      sticky_order: null,
    },
    {
      currency_prizes: [{ currency_id: 1, amount: 10 }],
      name: "Sprzedaz produkt\u00f3w NPW",
      sticky_order: null,
    },
    {
      currency_prizes: [{ currency_id: 1, amount: 7 }],
      name: "Sprzeda\u017c Gwarancji",
      sticky_order: null,
    },
    {
      currency_prizes: [{ currency_id: 1, amount: 4 }],
      name: "Sprzeda\u017c aktywna",
      sticky_order: null,
    },
    {
      currency_prizes: [{ currency_id: 1, amount: 6 }],
      name: "Sprzeda\u017c us\u0142ug",
      sticky_order: null,
    },
    {
      currency_prizes: [{ currency_id: 1, amount: 1 }],
      name: "Wydanie zwrotu na magazyn",
      sticky_order: null,
    },
    {
      currency_prizes: [{ currency_id: 1, amount: 1 }],
      name: "Przyj\u0119cie towaru do sklepu",
      sticky_order: null,
    },
    {
      currency_prizes: [{ currency_id: 1, amount: 1 }],
      name: "Sprawdzenie cen kolektorem",
      sticky_order: null,
    },
    {
      currency_prizes: [{ currency_id: 1, amount: 1 }],
      name: "Obs\u0142uga kasy",
      sticky_order: null,
    },
    {
      currency_prizes: [{ currency_id: 1, amount: 1 }],
      name: "Zbieranie adres\u00f3w email",
      sticky_order: null,
    },
    {
      currency_prizes: [{ currency_id: 1, amount: 2 }],
      name: "Wystawianie faktur",
      sticky_order: null,
    },
    {
      currency_prizes: [{ currency_id: 1, amount: 3 }],
      name: "Sprzeda\u017c na raty dla sali sprzeda\u017cy",
      sticky_order: null,
    },
    {
      currency_prizes: [{ currency_id: 1, amount: 20 }],
      name: "Zaliczenie testu e-learning",
      sticky_order: null,
    },
    {
      currency_prizes: [{ currency_id: 1, amount: 120 }],
      name: "Udzia\u0142 w szkoleniu w sklepie",
      sticky_order: null,
    },
    { currency_prizes: [{ currency_id: 1, amount: 200 }], name: "Udzia\u0142 w szkoleniu wyjazdowym", sticky_order: null },
  ],
};
