import { CommonModule, DecimalPipe, PercentPipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { SharedModule } from "../../shared/shared.module";
import { FilterObjectPipe } from "./directives/filter-object/filter-object.pipe";
import { FilterRankDetailsPipe } from "./directives/filter-rank-details.pipe";
import { BuildingIconPipe } from "./pipes/building-icon.pipe";
import { ParameterPipe } from "./pipes/parameter.pipe";

@NgModule({
  imports: [CommonModule, SharedModule, ReactiveFormsModule, FormsModule],
  declarations: [FilterObjectPipe, FilterRankDetailsPipe, ParameterPipe],
  providers: [DecimalPipe, PercentPipe],
  exports: [BuildingIconPipe],
})
export class GameGuiModule {}
