import { Directive, EventEmitter, Input, Output } from "@angular/core";
import { select, Store } from "@ngrx/store";
import moment from "moment";
import { filter, take } from "rxjs/operators";

import { AbstractInjectBaseComponent } from "../../../../../core/abstracts/abstract-inject-base.component";
import { EVENTS } from "../../../../../core/consts/core/events";
import { OwInject } from "../../../../../core/decorators/ow-inject.decorator";
import { translate } from "../../../../../core/helpers/translate.helper";
import { Currency } from "../../../../../core/interfaces/currency";
import { Primary } from "../../../../../core/interfaces/primary";
import { ParametersService } from "../../../../../core/providers/parameters.service";
import { clone } from "../../../../../helpers/common.helpers";
import { getCorrectlyStarId } from "../../../../../store/player/custom/helpers/correctly-star-id.helper";
import { selectPlayer } from "../../../../../store/player/selectors";
import { selectPrimary } from "../../../../../store/primary/selectors";
import { AppState } from "../../../../../store/state";
import { Player, Star } from "../../../../player/interfaces/player";
import { PlayerService } from "../../../../player/providers/player.service";
import { RequiredBuildingConfig } from "../../../game-engine/interfaces/required-building-config";
import { STOCK_VIEW } from "../mobile/consts/stock-view.const";
import { AbstractGroupCountComponent } from "./abstract-group-count.component";

@Directive()
export abstract class AbstractRequirementsComponent extends AbstractInjectBaseComponent {
  @OwInject(Store) store: Store<AppState>;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(ParametersService) parametersService: ParametersService;

  @Input() separator: string;
  private _products: any[];
  @Input()
  set products(value: any[]) {
    this._products = value;
    this.buildWaitingList();
  }
  get products(): any[] {
    return this._products;
  }
  private _currencies: Currency[];
  @Input()
  set currencies(value: Currency[]) {
    this._currencies = value;
    this.buildWaitingList();
  }
  get currencies(): Currency[] {
    return this._currencies;
  }
  _playerLevel: number;
  @Input()
  set playerLevel(value: number) {
    this._playerLevel = value;
    this.buildWaitingList();
  }
  get playerLevel(): number {
    return this._playerLevel;
  }
  _populationOutcome: number;
  @Input()
  set populationOutcome(value: number) {
    this._populationOutcome = value;
    this.buildWaitingList();
  }
  get populationOutcome(): number {
    return this._populationOutcome;
  }
  _requiredBuilding: RequiredBuildingConfig;
  @Input()
  set requiredBuilding(value: RequiredBuildingConfig) {
    this._requiredBuilding = value;
    this.buildWaitingList();
  }
  get requiredBuilding(): RequiredBuildingConfig {
    return this._requiredBuilding;
  }

  private _groupLimit: any;
  @Input()
  set groupLimit(value: any) {
    this.buildWaitingList();
    this._groupLimit = value;
  }
  get groupLimit(): any {
    return this._groupLimit;
  }

  private _population: number;
  @Input()
  set population(value: number) {
    this.buildWaitingList();
    this._population = value;
  }
  get population(): number {
    return this._population;
  }

  private _star: Star;
  @Input()
  set star(value: Star) {
    this.buildWaitingList();
    this._star = value;
  }
  get star(): Star {
    return this._star;
  }

  private _parametersOutcome: any[];
  @Input()
  set parametersOutcome(value: any[]) {
    this._parametersOutcome = value;
    this.buildWaitingList();
  }
  get parametersOutcome(): any[] {
    return this._parametersOutcome;
  }

  @Output() requirementsStatus = new EventEmitter();

  player: Player;
  EVENTS = EVENTS;
  waitingList = {};
  textsList: any[] = [];
  primary: Primary;
  parametersOutcomeFormatted: any[]; // Outcome parameters needed to build or upgrade a building

  STOCK_VIEW = STOCK_VIEW;

  subs = {
    player: null,
    groupCount: null,
    primary: null,
  };

  subscribePlayer() {
    this.subs.player = this.store.pipe(select(selectPlayer)).subscribe(player => {
      this.player = player;
      this.buildWaitingList();
    });
  }

  subscribePrimary() {
    this.subs["primary"] = this.store.pipe(select(selectPrimary)).subscribe(primary => {
      this.primary = primary;
      this.buildWaitingList();
    });
  }

  buildWaitingList() {
    if (this.products || this.currencies) {
      this.waitingList[EVENTS.GUI.REQUIREMENTS.COSTS] = { progress: true };
      // CHECK INNER COSTS COMPONENT
    }

    if (this.playerLevel) {
      this.waitingList[EVENTS.GUI.REQUIREMENTS.PLAYER_LEVEL] = { progress: true };
      this.checkPlayerLevel();
    }

    if (this.population && this.player) {
      this.waitingList[EVENTS.GUI.REQUIREMENTS.POPULATION] = { progress: true };
      this.checkPopulation();
    }

    if (this.requiredBuilding) {
      this.waitingList[EVENTS.GUI.REQUIREMENTS.BUILDING] = { progress: true };
      this.checkBuilding();
    }

    if (this.groupLimit) {
      this.waitingList[EVENTS.GUI.REQUIREMENTS.GROUP_LIMIT] = { progress: true };
      this.checkGroupLimit();
    }

    if (this.star) {
      this.waitingList["star"] = { progress: true };
      this.checkStar();
    }

    if (this.primary) {
      this.waitingList["buildings-day"] = { progress: true };
      this.checkRequireDay();
    }

    if (this.parametersOutcome) {
      this.waitingList["parameters-outcome"] = { progress: true };
      this.checkParametersOutcome();
    }
  }

  checkParametersOutcome() {
    let valid = true;
    if (this.parametersOutcome) {
      this.parametersOutcomeFormatted = clone(this.parametersOutcome);

      this.parametersOutcomeFormatted.forEach(parameter => {
        const parameterBalance =
          this.parametersService.getParameterBalanceBy({
            key: "name",
            value: parameter.name,
          })?.["balance"] || 0;

        parameter.have = parameterBalance >= parameter.outcome;
        parameter.amount = parameter.outcome;
      });

      this.parametersOutcomeFormatted = this.parametersOutcomeFormatted.filter(parameter => parameter.amount > 0);

      valid = this.parametersOutcomeFormatted.every(parameter => parameter.have);
    }

    const event = {
      action: "parameters-outcome",
      value: {
        valid,
      },
    };

    this.checkCompleted(event);
  }

  checkPlayerLevel() {
    const valid = this.playerService.allowRequiredLevel(this.playerLevel);

    const event = {
      action: EVENTS.GUI.REQUIREMENTS.PLAYER_LEVEL,
      value: {
        valid,
        text: `${translate("global.level")} ${this.playerLevel}`,
      },
    };

    this.checkCompleted(event);
  }

  checkPopulation() {
    const valid = this.player.population_current >= this.population;
    const populationDef = this.parametersService.getParameterDefinitionByType("population_delta");

    const event = {
      action: EVENTS.GUI.REQUIREMENTS.POPULATION,
      value: {
        valid,
        text: `${populationDef.name} (${this.population})`,
      },
    };

    this.checkCompleted(event);
  }

  checkBuilding() {
    const valid = this.requiredBuilding.exists;

    const event = {
      action: EVENTS.GUI.REQUIREMENTS.BUILDING,
      value: {
        valid,
        text: `${this.requiredBuilding.name} (${this.requiredBuilding.level})`,
      },
    };

    this.checkCompleted(event);
  }

  checkRequireDay() {
    const action_block_dates = this.primary["action_block_dates"];

    let isTodayBlock;

    if (action_block_dates.build) {
      isTodayBlock = action_block_dates.build.some(date => {
        return moment(this.playerService.getRealTime()).isSame(date, "days");
      });
    }

    const event = {
      action: "buildings-day",
      value: {
        valid: !isTodayBlock,
        textError: "Twoi pracownicy mają dziś wolne. Spróbuj ponownie w najbliższy dzień roboczy.",
      },
    };

    this.checkCompleted(event);
  }

  checkGroupLimit() {
    const event = {
      action: EVENTS.GUI.REQUIREMENTS.GROUP_LIMIT,
      value: {
        valid: null,
      },
    };

    if (this.groupLimit instanceof AbstractGroupCountComponent) {
      this.subs.groupCount = this.groupLimit.event$
        .pipe(filter(value => value !== null))
        .subscribe((next: { valid: boolean; message: string }) => {
          event.value = <any>next;

          if (event.value.valid !== null) {
            this.checkCompleted(event);
          }
        });
    }
  }

  checkStar() {
    if (this.player) {
      this.star.star_id = getCorrectlyStarId(this.star.star_id);
      const valid = this.player.star.star_id >= this.star.star_id;

      const event = {
        action: "star",
        value: {
          valid,
          text: translate("requirements.star", this.star),
        },
      };

      this.checkCompleted(event);
    }
  }

  checkCompleted(event) {
    if (this.waitingList[event.action]) {
      this.waitingList[event.action].progress = false;
      this.waitingList[event.action].value = event.value;
      this.checkEnd();
    }
  }

  checkEnd() {
    const countNotFinished = Object.keys(this.waitingList).filter(key => {
      return this.waitingList[key].progress === true;
    });
    if (countNotFinished.length === 0) {
      setTimeout(() => {
        this.createTextsList();
        this.emitStatus();
      });
    }
  }

  createTextsList() {
    this.textsList = [];

    Object.keys(this.waitingList).forEach(key => {
      if (this.waitingList[key].value && this.waitingList[key].value.text) {
        this.textsList.push(this.waitingList[key].value);
      }
    });
  }

  emitStatus() {
    const valid =
      Object.keys(this.waitingList).filter(key => {
        return this.waitingList[key].value.valid === false;
      }).length === 0;

    this.requirementsStatus.emit({
      valid: valid,
      requirementsList: this.waitingList,
    });
  }
}
