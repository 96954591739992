import { Injectable, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PhaserGameService implements OnDestroy {
  lastUpdatedTile$: Subscription;

  constructor() {}

  ngOnDestroy() {
    if (this.lastUpdatedTile$) {
      this.lastUpdatedTile$.unsubscribe();
    }
  }
}
