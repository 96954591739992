export const MOCK_BUILDING_INFO = {
  api: {
    upgrade_building_id: 2,
    required_building: null,
    required_building_on_island: null,
    required_star: null,
    building_time_in_seconds: 15,
    fast_building_time_in_seconds: 0,
    build_currency_prizes: [],
    build_product_prizes: [],
    parameters: [{ parameter_id: 1, income: 20, outcome: null }],
    building_id: 1,
    group_type: "normal",
    group_limit: 6,
    group_count: 6,
    category_id: 1,
    player_concurrence_builds_reached_error_message: null,
    group: "house",
    group_description:
      "Cichy i słoneczny dom. Budynek szczeg&oacute;lnie lubiany przez mieszkańc&oacute;w ceniących prywatność.",
    icon: "house",
    automatic_product: null,
    automatic_currency: null,
    level: 1,
    name: "Dom",
    additional_details: "",
    required_level: 1,
    required_population: null,
    building_time: 15,
    building_time_in_days: null,
    fast_building_time: 0,
    repair_building_time: 0,
    build_product_prices: [
      {
        product_id: 1,
        category: { storage_id: 1, id: 2 },
        name: "Drewno",
        description:
          "Odpowiednio obrobione i wyselekcjonowane kawałki drewna posłużą Ci do produkcji mebli oraz budowy budynków. Miejsce obróbki: Tartak.",
        icon: "wood",
        amount: 2,
      },
    ],
    exp_points: 69,
    attributes: [],
    probability_parameters: [],
    repair_prices: [],
    build_currency_prices: [
      { currency_id: 1, amount: 270 },
      { currency_id: 2, amount: 100 },
    ],
    fast_build_currency_prices: [],
    fast_build_product_prices: [],
    daily_product_productions: [],
    upgrade_button_name: null,
    auto_delete: false,
    variants: [],
    original_building_time_in_seconds: 15,
    original_build_currency_prices: [
      { currency_id: 1, amount: 270 },
      { currency_id: 2, amount: 100 },
    ],
    build_prices: [
      { currency_id: 1, amount: 270 },
      { currency_id: 2, amount: 100 },
    ],
    original_build_prices: [
      { currency_id: 1, amount: 270 },
      { currency_id: 2, amount: 100 },
    ],
    fast_build_prices: [],
    required_products: [
      {
        product_id: 1,
        category: { storage_id: 1, id: 2 },
        name: "Drewno",
        description:
          "Odpowiednio obrobione i wyselekcjonowane kawałki drewna posłużą Ci do produkcji mebli oraz budowy budynków. Miejsce obróbki: Tartak.",
        icon: "wood",
        amount: 2,
      },
    ],
    probability_parameters_percentage: 0,
  },
  ref: {
    playerTileId: 55194,
  },
};
