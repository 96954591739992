export const RANK_CONFIG = {
  BUSINESS_DEFAULT: {
    columns: [
      { header: null, value: "position", default: true },
      { header: "Sklep", value: "branch_name", default: true },
      // { columnFromApi: 1, textAlign: "center" },
      // { header: "Nagroda", prizes: true, textAlign: "right" },
    ],
  },
  BUSINESS_PLAYER: {
    columns: [
      { header: null, value: "position", default: true },
      { header: "Imię", value: "first_name", default: true },
      { header: "Nazwisko", value: "last_name", default: true },
      // { columnFromApi: 1, textAlign: "center" },
      // { header: "Nagroda", prizes: true, textAlign: "right", classes: ["td-prizes"] },
    ],
  },
  BUSINESS_CONTEST: {
    columns: [
      { header: null, value: "position", default: true },
      { header: "Imię", value: "first_name", default: true },
      { header: "Nazwisko", value: "last_name", default: true },
      // { columnFromApi: 1, textAlign: "center" },
      // { header: "Nagroda", prizes: true, textAlign: "right", classes: ["td-prizes"] },
    ],
  }
};
