import {Component, OnInit} from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import {MenuButtonsConfigService} from '../../../../hud-menu-buttons/services/core/menu-buttons-config.service';
import {PlayerService} from '../../../../../../../player/providers/player.service';
import {GlobalService} from '../../../../../../../../core/providers/global.service';
import {MenuButtonConfig} from '../../../../hud-menu-buttons/interfaces/core/menu-buttonts-config.interface';

@Component({
  selector: "app-hud-menu-more",
  templateUrl: "./hud-menu-more.component.html",
})
export class HudMenuMoreComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<HudMenuMoreComponent>;
  @OwInject(MenuButtonsConfigService) menuButtonsConfigService: MenuButtonsConfigService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(GlobalService) globalService: GlobalService;
  buttons = [];

  ngOnInit() {
    const { gui_unlocks } = this.playerService.player;
    this.buttons = this.menuButtonsConfigService.getAllButtons(gui_unlocks);
  }

  handleBtnAction(e: MenuButtonConfig) {
    this.menuButtonsConfigService.triggerCorrectEventButtonHandler(e);
  }

  close() {
    this.matDialogRef.close();
  }
}
