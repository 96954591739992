import { Pipe, PipeTransform } from "@angular/core";

import { ApiService } from "../../../core/providers/api.service";

@Pipe({
  name: "downloadToken",
})
export class DownloadTokenPipe implements PipeTransform {
  pattern = /(https:[^"']*api\/files[^"']*)/g;

  constructor(private apiService: ApiService) {}

  transform(value: string): string {
    return value.replace(this.pattern, url => this.addTokenToUrl(url));
  }

  addTokenToUrl(url) {
    const newUrl = new URL(url);
    newUrl.searchParams.set("download_token", this.apiService.getToken().download_token);
    return newUrl.href;
  }
}
