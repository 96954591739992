import { Directive, Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { filter, take } from "rxjs/operators";

import { APP_ROUTING_ABSOLUTE_PATH } from "../../../consts/routing-app-absolute.const";
import { AbstractInjectBaseComponent } from "../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../core/decorators/ow-inject.decorator";
import { translate } from "../../../core/helpers/translate.helper";
import { getToken } from "../../../core/utility/token";
import { selectAuthState } from "../../../store/auth/selectors";
import { AppState } from "../../../store/state";
import { utilitySetToken } from "../../../store/utility/actions";
import { DialogService } from "../../shared/providers/dialog.service";
import { AuthService } from "../api/services/auth.service";
import { AUTH_ROUTING_ABSOLUTE_PATH } from "../consts/core/routing-auth-absolute.const";
import { checkPasswordTheSame } from "../helpers/validators-form-password-the-same.helper";
import { ChangePassword } from "../interfaces/change-password";
import { TokenObject } from "../interfaces/token-object";
import {ApplicationConfig} from '../../../store/config/interfaces/config.state';
import {selectConfigOfApplication} from '../../../store/config/selectors';

@Directive()
@Injectable()
export abstract class AbstractChangePasswordComponent extends AbstractInjectBaseComponent {
  @OwInject(FormBuilder) fb: FormBuilder;
  @OwInject(AuthService) authService: AuthService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(Router) router: Router;
  @OwInject(ActivatedRoute) activatedRoute: ActivatedRoute;
  @OwInject(Store) store: Store<AppState>;
  AUTH_ROUTING_ABSOLUTE_PATH = AUTH_ROUTING_ABSOLUTE_PATH;
  form: FormGroup;
  isShowBackButton: boolean;
  tokenObject: TokenObject;
  config: ApplicationConfig = null

  baseInit() {
    this.store.select(selectConfigOfApplication).pipe(take(1)).subscribe(res => {
      this.config = res;
    })
    this.subscribeTempToken();
    this.subscribeRoutingParams();
    this.clearForm();
  }

  subscribeRoutingParams() {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
      if (params.hasOwnProperty("isShowBackButton")) {
        this.isShowBackButton = !!parseInt(params["isShowBackButton"], 10);
      }
    });
  }

  subscribeTempToken() {
    this.store
      .pipe(
        select(selectAuthState),
        filter(x => x !== null && x !== undefined),
        take(1)
      )
      .subscribe(authStart => {
        this.tokenObject = authStart.changePassword.tempToken || getToken();

        if (!this.tokenObject.token) {
          this.router.navigate(["/"]);
        }
      });
  }

  clearForm() {
    this.form = this.fb.group(
      {
        oldPassword: this.fb.control(null, [Validators.required]),
        password: this.fb.control(null, [Validators.required]),
        passwordRepeat: this.fb.control(null, [Validators.required]),
      },
      {
        validator: checkPasswordTheSame,
      }
    );
  }

  onFormSubmit() {
    const data: ChangePassword = {
      old_password: this.form.value.oldPassword,
      new_password: this.form.value.password,
    };

    const token = this.tokenObject.token;

    this.authService.changePassword(data, token).subscribe(() => {
      this.store.dispatch(utilitySetToken({ token: this.tokenObject }));

      this.dialogService.openAlert(
        {
          title: translate("auth.change-password.alert-success.title"),
          description: translate("auth.change-password.alert-success.description"),
        },
        () => {
          this.router.navigate([APP_ROUTING_ABSOLUTE_PATH.PLAYER_CHOOSE]);
        }
      );
    });
  }
}
