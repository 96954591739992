import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { OwPerfectScrollbarModule } from "@oskarwegner/ow-perfect-scrollbar";

import { BuildingsModule } from "../../../buildings/module/core/buildings.module";
import { SharedUiMobileModule } from "../../../shared-ui/mobile/shared-ui-mobile.module";
import { MessageAbstractModule } from "../../abstract/core/message-abstract.module";
import { ButtonOpenQaComponent } from "../../base/core/components/button-open-qa/button-open-qa.component";
import { MessageDetailsChestFailedComponent } from "../../base/core/dialogs/message-details-chest-failed/message-details-chest-failed.component";
import { MessageDetailsChestSuccessComponent } from "../../base/core/dialogs/message-details-chest-success/message-details-chest-success.component";
import { MessageDetailsComicsComponent } from "../../base/core/dialogs/message-details-comics/message-details-comics.component";
import { MessageDetailsHiddenRewardComponent } from "../../base/core/dialogs/message-details-hidden-reward/message-details-hidden-reward.component";
import { MessageDetailsLevelUpComponent } from "../../base/core/dialogs/message-details-level-up/message-details-level-up.component";
import { MessageDetailsNormalComponent } from "../../base/core/dialogs/message-details-normal/message-details-normal.component";
import { MessageDetailsProbabilityCompensationComponent } from "../../base/core/dialogs/message-details-probability-compensation/message-details-probability-compensation.component";
import { MessagesComponent } from "../../base/core/dialogs/messages/messages.component";
import { CUSTOM_DECLARATIONS, CUSTOM_IMPORTS } from "../../consts/custom/components.const";
import { CUSTOM_PROVIDERS } from "../../consts/custom/providers.const";

@NgModule({
  imports: [
    CommonModule,
    MessageAbstractModule,
    BuildingsModule,
    SharedUiMobileModule,
    OwPerfectScrollbarModule,
    ...CUSTOM_IMPORTS,
  ],
  declarations: [
    MessagesComponent,
    MessageDetailsNormalComponent,
    MessageDetailsComicsComponent,
    MessageDetailsLevelUpComponent,
    MessageDetailsHiddenRewardComponent,
    MessageDetailsChestSuccessComponent,
    MessageDetailsChestFailedComponent,
    MessageDetailsProbabilityCompensationComponent,
    ButtonOpenQaComponent,
    ...CUSTOM_DECLARATIONS,
  ],
  exports: [],
  providers: [...CUSTOM_PROVIDERS],
})
export class MessageModule {}
