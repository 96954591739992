import { Injectable } from "@angular/core";
import { take } from "rxjs/operators";

import { GlobalService } from "../../../../../../../core/providers/global.service";
import { PlayerService } from "../../../../../../player/providers/player.service";
import { GAME_EVENTS } from "../../../../../constants";
import { BASIC_BOOT_SCENE } from "../../../../../game-engine/scenes-basic/basic.constants";
import {Region} from '../../../../../interfaces/region.interface';
import {Store} from '@ngrx/store';
import {gameSetCurrentScene} from '../../../../../../../store/game/actions';

@Injectable({
  providedIn: "root",
})
export class ChangeMapService {
  subs = {
    playerRegions: null,
  };

  constructor(
    public globalService: GlobalService,
    public playerService: PlayerService,
    public store: Store
  ) {}

  goToCity() {
    this.globalService.globalEvents.emit({
      name: GAME_EVENTS.START_SCENE,
      value: BASIC_BOOT_SCENE,
    });
  }
  goToIsland(islandId?: number, configName?: string) {
    if (islandId == null && configName == null) {
      return;
    }

    this.playerService.playerRegions.pipe(take(1)).subscribe((regionsData: {playerId: number, regions: Region[]}) => {
      let targetIsland = islandId ? this.findIslandByIslandId(islandId, regionsData.regions) : this.findIslandByConfigName(configName, regionsData.regions);

      if (!targetIsland) {
        return;
      }

      this.store.dispatch(gameSetCurrentScene(targetIsland.config_name));

      this.globalService.globalEvents.emit({
        name: GAME_EVENTS.START_SCENE,
        value: BASIC_BOOT_SCENE,
        data: {
          sceneConfigKey: targetIsland.config_name,
          playerIslandId: islandId,
        },
      });
    });
  }

  private findIslandByIslandId(islandId: number, regions: Region[]) {
    if (islandId == null || regions == null || !regions?.length) {
      return null;
    }

    let targetIsland = null;
    regions.find(regionData => (targetIsland = regionData.islands.find(island => island.player_island_id === islandId)));

    return targetIsland;
  }

  private findIslandByConfigName(configName: string, regions: Region[]) {
    if (configName == null || regions == null || !regions?.length) {
      return null;
    }

    let targetIsland = null;
    regions.find(regionData => (targetIsland = regionData.islands.find(island => island.config_name === configName)));

    return targetIsland;
  }
}
