import { Component, OnDestroy, OnInit } from "@angular/core";

import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { AbstractHudResourcesComponent } from "../../../../abstract/core/abstract-hud-resources.component";

@Component({
  selector: "app-hud-resources",
  templateUrl: "./hud-resources.component.html",
})
export class HudResourcesComponent extends AbstractHudResourcesComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.resizeEvent(new Event(null));
    this.setParameters();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
