import { createAction, props } from "@ngrx/store";

import { ChangePassword } from "../../../modules/auth/interfaces/change-password";
import { TokenObject } from "../../../modules/auth/interfaces/token-object";

export enum ActionTypes {
  MUST_CHANGE = "[auth] must change password",
  CHANGE_START = "[auth] change password start",
  CHANGE_SUCCESS = "[auth] change password success",
  CHANGE_FAILED = "[auth] change password failed",
}

export const passwordMustChange = createAction(ActionTypes.MUST_CHANGE, props<{ token: TokenObject }>());
export const passwordChangeStart = createAction(
  ActionTypes.CHANGE_START,
  props<{ data: ChangePassword; tokenObject: TokenObject }>()
);
export const passwordChangeSuccess = createAction(ActionTypes.CHANGE_SUCCESS);
export const passwordChangeFailed = createAction(ActionTypes.CHANGE_FAILED, props<any>());
