import {Component, OnInit} from '@angular/core';

import { AbstractSocketConnectionErrorComponent } from "../../../../abstract/abstract-socket-connection-error.component";

@Component({
  selector: "app-socket-connection-error",
  templateUrl: "./socket-connection-error.component.html",
})
export class SocketConnectionErrorComponent extends AbstractSocketConnectionErrorComponent implements OnInit {
  ngOnInit() {
    super.ngOnInit();
  }
}
