import { Action } from "@ngrx/store";

import { GameState } from './game/reducer';
import { PlayerState } from './player/reducer';
import { PrimaryState } from './primary/reducer';
import { SocketState } from './socket/reducer';
import { UserState } from './user/reducer';
import { UtilityState } from './utility/reducer';
import { AuthState } from './auth/state';
import {ConfigState} from './config/reducer';

export enum ApplicationActionTypes {
  CLEAR_STATE = "[application] clear state",
  MOCK_STATE = "[application] mock state",
}

export class ClearState implements Action {
  readonly type = ApplicationActionTypes.CLEAR_STATE;

  constructor() {}
}

export class MockState implements Action {
  readonly type = ApplicationActionTypes.MOCK_STATE;

  constructor(
    public payload: {
      auth: AuthState;
      game: GameState;
      user: UserState;
      player: PlayerState;
      utility: UtilityState;
      primary: PrimaryState;
      socket: SocketState;
      config: ConfigState;
    }
  ) {}
}

export type ApplicationActions = ClearState;
