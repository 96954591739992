import { createAction, props } from "@ngrx/store";

import { Primary } from "../../core/interfaces/primary";

export enum ActionTypes {
  FETCH_PRIMARY_START = "[primary] Fetch Start",
  FETCH_PRIMARY_SUCCESS = "[primary] Fetch Success",
  FETCH_PRIMARY_FAILURE = "[primary] Fetch Failure",
}

export const primaryFetchStart = createAction(ActionTypes.FETCH_PRIMARY_START);
export const primaryFetchSuccess = createAction(ActionTypes.FETCH_PRIMARY_SUCCESS, props<{ primary: Primary }>());
export const primaryFetchFailure = createAction(ActionTypes.FETCH_PRIMARY_FAILURE, props<{ error: any }>());
