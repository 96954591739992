import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Injectable,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import moment from "moment";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { LoadingService } from "../../../../../../core/providers/loading.service";
import { ToHourPipe } from "../../../../game-gui/directives/to-hour.pipe";
import { STOCK_VIEW } from "../../../shared-ui/mobile/consts/stock-view.const";
import { QA_TYPE } from "../../consts/core/qa-type.const";
import { Qa } from "../../interfaces/core/qa.interface";

@Directive()
@Injectable()
export abstract class AbstractQaSummaryComponent
  extends AbstractInjectBaseComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @OwInject(LoadingService) loadingService: LoadingService;
  @OwInject(ToHourPipe) toHourPipe: ToHourPipe;
  @ViewChild("scoreAndPrizes") scoreAndPrizes: ElementRef;
  @Output() refreshQaList = new EventEmitter();
  @Input() qa: Qa;
  @Input() separatorTitle: string;
  STOCK_VIEW = STOCK_VIEW;
  QA_TYPE = QA_TYPE;
  scoreText: string;
  answeringTime: string;
  gotMaxPoint: boolean;
  isShowSeparator: boolean;
  isLoading = true;
  intervalTimer;
  prizeReductionTimer;

  ngOnInit(): void {
    this.afterQa();

    if (this.qa.prize_reduction?.valid_till) {
      this.setTimer();
    }
  }

  ngAfterViewInit() {
    this.checkAnyElScoreAndPrizes();
  }

  ngOnChanges() {
    this.afterQa();
    this.checkAnyElScoreAndPrizes();
  }

  afterQa() {
    this.clear();
    const lastAttempt = this.qa.last_attempt;
    this.scoreText = this.calculateScoreText();
    this.gotMaxPoint = this.gotMaxPoints();
    if (lastAttempt && lastAttempt.finished_at && lastAttempt.started_at) {
      this.answeringTime = this.timeDifference(lastAttempt.finished_at, lastAttempt.started_at);
    }
  }

  checkAnyElScoreAndPrizes() {
    setTimeout(() => {
      if (this.scoreAndPrizes) {
        this.isShowSeparator = this.scoreAndPrizes.nativeElement.lastElementChild;
      }
    });
  }

  clear() {
    this.scoreText = null;
    this.answeringTime = null;
    this.gotMaxPoint = null;
  }

  timeDifference(a, b) {
    const time = moment(a).diff(moment(b));
    const timeSec = Math.floor(time / 1000);
    return this.toHourPipe.transform(timeSec);
  }

  calculateScoreText(): string {
    const lastAttempt = this.qa.last_attempt;
    if (!lastAttempt || (lastAttempt && !lastAttempt.finished_at) || this.qa.type === QA_TYPE.SURVEY) {
      return null;
    } else {
      const score = lastAttempt.player_points;
      const maxScore = lastAttempt.max_points;

      if (score === maxScore) {
        return `${score}/${maxScore} (100%)`;
      } else if (score < maxScore) {
        return `${score}/${maxScore} (${Math.round((score / maxScore) * 100)}%)`;
      }
    }
  }

  setTimer() {
    this.intervalTimer = setInterval(() => {
      const actualDate = new Date().getTime();
      const expiredAt = new Date(this.qa.prize_reduction.valid_till).getTime();
      this.prizeReductionTimer = moment(expiredAt).diff(moment(actualDate), "seconds");
      this.isLoading = false;
      if (this.prizeReductionTimer <= 0) {
        clearInterval(this.intervalTimer);
        this.refreshQaListEmit();
      }
    }, 1000);
  }

  refreshQaListEmit() {
    this.refreshQaList.emit();
    this.loadingService.show();
    setTimeout(() => {
      this.ngOnInit();
    }, 2050);
  }

  gotMaxPoints(): boolean {
    const lastAttempt = this.qa.last_attempt;
    if (lastAttempt && lastAttempt.max_points !== 0) {
      return lastAttempt.player_points === lastAttempt.max_points;
    }
    return false;
  }

  ngOnDestroy() {
    clearInterval(this.intervalTimer);
  }
}
