<div class="global-dialog ow-dialog window-a primary" [ngClass]="'type-' + message?.type">
  <!-- DIALOG EXIT -->
  <m-ui-close-button [preventDefault]="true" (clicked)="closeOrCloseAll()"></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button
    [preventDefault]="true"
    (clicked)="close()"
    *ngIf="messageOpenDetailsData.fromList"></m-ui-back-button>

  <ng-container *ngIf="message">
    <!-- TOP BAR BASE -->
    <div class="top-bar base">
      <div class="title">{{ selectedItem + 1 }}/{{ message.gallery.length }}</div>
    </div>

    <!-- NAVIGATION CENTER -->
    <m-ui-navigation-center
      [hidePrev]="selectedItem === 0"
      [hideNext]="selectedItem >= message.gallery.length - 1"
      (prevClicked)="loadItem(selectedItem - 1)"
      (nextClicked)="loadItem(selectedItem + 1)"></m-ui-navigation-center>

    <!-- IF MESSAGE EXIST -->
    <div class="height-100 relative">
      <loading></loading>

      <!-- MESSAGE GALLERY -->
      <div class="gallery-page loading-opacity">
        <!-- IMAGE -->
        <img src="{{ message.gallery[selectedItem] }}" />
      </div>

      <!-- MESSAGE TRASH -->
      <i
        class="svg-icon box-archive"
        *ngIf="!message.deleted && message.deletable"
        (click)="moveToTrash()"
        [matTooltip]="'messages.archives' | myTranslate"
        matTooltipPosition="above"
        matTooltipShowDelay="300"></i>
    </div>

    <button-open-qa [qaInfo]="message.qa_info"></button-open-qa>
  </ng-container>
</div>
