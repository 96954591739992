import { Injectable, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import * as R from "ramda";
import { Subscription } from "rxjs";

import { GlobalService } from "../../../../../../../core/providers/global.service";
import { unsubscribeObject } from "../../../../../../../core/utility/unsubscribe-array";
import { selectGameCurrentScene } from "../../../../../../../store/game/selectors";
import { GAME_EVENTS } from "../../../../../constants";
import { MENU_BUTTONS_CONFIG } from "../../const/core/menu-buttons.const";
import { CUSTOM_MENU_BUTTONS_CONFIG } from "../../const/custom/menu-buttons-custom.const";
import {
  ButtonMenuConfigFilter,
  MappedMenuButtonConfig,
  MenuButtonConfig,
} from "../../interfaces/core/menu-buttonts-config.interface";

@Injectable({
  providedIn: "root",
})
export class MenuButtonsConfigService implements OnDestroy {
  buttons: MenuButtonConfig[] = [];
  currentScene = null;

  subs: { scene: Subscription } = {
    scene: null,
  };

  constructor(
    private store: Store,
    private globalService: GlobalService,
    private router: Router
  ) {
    const config = R.mergeDeepRight(MENU_BUTTONS_CONFIG, CUSTOM_MENU_BUTTONS_CONFIG);
    this.buttons = Object.values(config) as MenuButtonConfig[];

    this.subs.scene = this.store.select(selectGameCurrentScene).subscribe(res => {
      this.currentScene = res;
    });
  }

  getMenuLeft(config: ButtonMenuConfigFilter): MappedMenuButtonConfig[] {
    let buttons = structuredClone(this.buttons)
      .filter(x => x.place !== "menu_left") // pick buttons which should be visible on the left
      .filter(x => !x.gui_unlocks_button || config.gui_unlocks[x.gui_unlocks_button]) // positive gui_unlock
      .filter(x => x.click_event !== this.currentScene) // filter map button if the map is currently active(you're on it right now)
      .map(x => (x.group_name ? x : { ...x, isVisible: true })) // if this is group add bool .isVisible; this one changes after clicking the pin icon(location)
      .sort((a, b) => a.order - b.order); // set order

    buttons = buttons.reduce((result, button) => {
      // code for handling "pin" icon
      if (button.type === "group") {
        const groupButtons = buttons.filter(btn => btn.group_name === button.name);

        if (groupButtons.length === 1) {
          // if there is only one map button available - show it directly
          result.push({ ...groupButtons[0], isVisible: true });
        }

        if (groupButtons.length > 1) {
          // if there is more than one map button - show pin button with all map buttons in group
          result.push({ ...button, group_buttons: groupButtons });
        }

        // if there's no map btn then show nothing
        // if (groupButtons.length === 0) {}
      } else if (!button.group_name) {
        result.push(button);
      }

      return result;
    }, []);

    return buttons;
  }

  getMenuBottom(guiUnlocks: { [key: string]: boolean }): MappedMenuButtonConfig[] {
    let buttons = structuredClone(this.buttons)
      .filter(x => x.place === "menu-bottom") // pick buttons which should be visible on the left
      .filter(x => !x.gui_unlocks_button || guiUnlocks[x.gui_unlocks_button]) // positive gui_unlock
      .filter(x => x.click_event !== this.currentScene) // filter map button if the map is currently active(you're on it right now)
      .map(x => (x.group_name ? x : { ...x, isVisible: true })) // if this is group add bool .isVisible; this one changes after clicking the pin icon(location)
      .sort((a, b) => a.order - b.order); // set order

    buttons = buttons.reduce((result, button) => {
      // code for handling "pin" icon
      if (button.type === "group") {
        const groupButtons = buttons.filter(btn => btn.group_name === button.name);

        if (groupButtons.length === 1) {
          // if there is only one map button available - show it directly
          result.push({ ...groupButtons[0], isVisible: true });
        }

        if (groupButtons.length > 1) {
          // if there is more than one map button - show pin button with all map buttons in group
          result.push({ ...button, group_buttons: groupButtons });
        }

        // if there's no map btn then show nothing
        // if (groupButtons.length === 0) {}
      } else if (!button.group_name) {
        result.push(button);
      }

      return result;
    }, []);

    return buttons;
  }

  getAllButtons(guiUnlocks: { [key: string]: boolean }): MappedMenuButtonConfig[] {
    const buttons = structuredClone(this.buttons)
      .filter(x => x.type !== "group") // remove "pin" button
      .filter(x => !x.gui_unlocks_button || guiUnlocks[x.gui_unlocks_button]) // positive gui_unlock
      .filter(x => x.click_event !== this.currentScene) // filter map button if the map is currently active(you're on it right now)
      .sort((a, b) => a.order - b.order); // sort by order

    return buttons;
  }

  triggerCorrectEventButtonHandler(button: MenuButtonConfig) {
    switch (button.data.type) {
      case "DIALOG": {
        this.handleDialogEvent(button);
        break;
      }
      case "MAP": {
        this.handleMapChangeEvent(button);
        break;
      }
      case "ROUTE": {
        this.handleRedirectEvent(button);
        break;
      }
      default:
      case "GROUP": {
        break;
      }
    }
  }

  handleRedirectEvent(e: MenuButtonConfig) {
    this.router.navigate([e.data.route]);
  }

  handleMapChangeEvent(e: MenuButtonConfig) {
    this.globalService.globalEvents.emit({
      name: GAME_EVENTS.MAP_CHANGE,
      value: {
        name: e.click_event,
      },
    });
  }

  handleDialogEvent(e: MenuButtonConfig) {
    this.globalService.globalEvents.emit({
      name: GAME_EVENTS.OPEN_DIALOG,
      value: {
        name: e.click_event,
        config: e.data.dialogConfig ? { data: e.data.dialogConfig } : undefined,
      },
    });
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
