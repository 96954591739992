import { Directive, Injectable, Input, Type } from "@angular/core";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { EVENTS } from "../../../../../../core/consts/core/events";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { GlobalService } from "../../../../../../core/providers/global.service";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { GameService } from "../../../../services/game.service";
import { STOCK_VIEW } from "../../../shared-ui/mobile/consts/stock-view.const";
import { CARD_TEMPLATE } from "../../consts/custom/card-template.const";
import { CardTask } from "../../interfaces/custom/card-task.interface";
import { AbstractCardTaskDetailsComponent } from "./abstract-card-task-details.component";
import { AbstractChooseTaskComponent } from "./abstract-choose-task.component";

@Directive()
@Injectable()
export abstract class AbstractCardTaskComponent extends AbstractInjectBaseComponent {
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(GameService) gameService: GameService;
  @Input() cardTask: CardTask;
  @Input() cardTasks: CardTask[];
  abstract dialogService: DialogService;
  abstract cardTaskDetailsComponent: Type<AbstractCardTaskDetailsComponent>;
  abstract chooseTaskComponent: Type<AbstractChooseTaskComponent>;
  STOCK_VIEW = STOCK_VIEW;
  CARD_TEMPLATE = CARD_TEMPLATE;

  actionCard() {
    if (this.cardTask.selected) {
      switch (this.cardTask.selected.card_template) {
        // case this.CARD_TEMPLATE.SANTA:
        //   this.openMiniGame();
        //   break;

        default:
          this.openCardTask();
          break;
      }
    }
  }

  openCardTask() {
    this.dialogService.open(
      this.cardTaskDetailsComponent,
      {
        data: {
          cardTask: this.cardTask,
          cardTasks: this.cardTasks.filter(c => c.selected.card_template !== CARD_TEMPLATE.SANTA),
        },
      },
      (data?) => {
        if (data && data.reload) {
          this.globalService.globalEvents.emit({ name: EVENTS.GUI.RELOAD_CARDS });
        }
      }
    );
  }

  openChooseTasks() {
    this.dialogService.open(this.chooseTaskComponent, {
      data: {
        cardTask: this.cardTask,
        hideBackButton: true,
      },
    });
  }
}
