import { Component } from "@angular/core";

import { AbstractInjectBaseComponent } from "../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../core/decorators/ow-inject.decorator";
import { DeviceService } from "../../../../core/providers/device.service";

@Component({
  selector: "app-device-rotate",
  templateUrl: "./device-rotate.component.html",
})
export class DeviceRotateComponent extends AbstractInjectBaseComponent {
  @OwInject(DeviceService) deviceService: DeviceService;
}
