import { Directive, Injectable, OnInit } from "@angular/core";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { Player } from "../../../../../player/interfaces/player";
import { PlayerService } from "../../../../../player/providers/player.service";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { BranchService } from "../../../../game-gui/providers/branch.service";
import { PreviewPlayerService } from "../../../../game-gui/providers/preview-player.service";

@Directive()
@Injectable()
export class AbstractBranchPreviewComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(BranchService) branchService: BranchService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(PreviewPlayerService) previewPlayerService: PreviewPlayerService;

  branchesPlayers: Player[];

  ngOnInit() {
    this.getBranchesPlayers();
  }

  getBranchesPlayers() {
    const branchId = this.playerService.player.branch_id;

    this.branchService.branchesPlayers({ branchId }).subscribe(resp => {
      this.branchesPlayers = resp;
    });
  }
}
