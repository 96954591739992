import {Component} from '@angular/core';
import {AbstractAlertConfirmComponent} from '../../../../../../../../core/abstracts/abstract-alert-confirm.component';
import {OwInject} from '../../../../../../../../core/decorators/ow-inject.decorator';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AlertConfirm} from '../../../../../../../../core/interfaces/alert';
import {CurrencyBalance} from '../../../../../../../../core/interfaces/currency';

@Component({
  selector: 'app-exchange-confirm',
  templateUrl: './exchange-confirm.component.html',
})
export class ExchangeConfirmComponent extends AbstractAlertConfirmComponent {
  @OwInject(MAT_DIALOG_DATA) data: AlertConfirm & {
    title: string,
    from: CurrencyBalance,
    to: CurrencyBalance
  };
}
