import { CARD_TEMPLATE } from "../../consts/custom/card-template.const";
import { Task } from "../../interfaces/custom/task.interface";

export function setTaskClasses(task: Task): string[] {
  const classes: string[] = [];

  // CARD TEMPLATE
  switch (task.card_template) {
    case CARD_TEMPLATE.HARD:
      classes.push("card-template-1");
      break;

    case CARD_TEMPLATE.SOFT:
      classes.push("card-template-2");
      break;

    case CARD_TEMPLATE.SOFT_WITH_ESTIMATED:
      classes.push("card-template-3");
      break;

    case CARD_TEMPLATE.HARD_WITH_ESTIMATED:
      classes.push("card-template-4");
      break;

    case CARD_TEMPLATE.PROMO:
      classes.push("card-template-5");
      break;
  }

  return classes;
}
