import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { ApiOptions } from "../../../../../../../core/interfaces/api-options";
import { ApiService } from "../../../../../../../core/providers/api.service";
import { SchemaValidatorService } from "../../../../../../../services/schema-validator/schema-validator.service";
import { EVENT_DIALOGS_NAMES_MESSAGE } from "../../../consts/core/event-dialogs/event-names.const";
import { MOCK_MESSAGES_FILTER } from "../../../mock/consts/mock-messages.const";
import {
  DeleteMessageRequest,
  GetMessageDetailsRequest,
  GetMessagesFiltersRequest,
  GetMessagesRequest,
  PatchMarkReadMessageRequest,
} from "../interfaces";

@Injectable({
  providedIn: "root",
})
export class ApiMessageService {
  constructor(
    protected apiService: ApiService,
    private schemaValidator: SchemaValidatorService
  ) {}

  getMessages(getMessagesRequest: GetMessagesRequest): Observable<any> {
    const options: ApiOptions = {
      params: {
        ...getMessagesRequest,
      },
      responseWithHeaders: true,
    };
    return this.apiService.get("messages", options, EVENT_DIALOGS_NAMES_MESSAGE.MESSAGES).pipe(
      tap(response => {
        this.schemaValidator.validateResponse({
          url: `/messages`,
          payload: {},
          response: response["body"],
          schema: "messagesGame",
        });
      })
    );
  }

  getMessageDetails({ playerMessageId }: GetMessageDetailsRequest) {
    return this.apiService.get(
      `messages/${playerMessageId}`,
      {},
      EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_HIDDEN_REWARD
    );
    return this.apiService.get(`messages/${playerMessageId}`, {}, EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_HIDDEN_REWARD).pipe(
      tap(response => {
        this.schemaValidator.validateResponse({
          url: `messages/${playerMessageId}`,
          payload: {},
          response: response,
          schema: "messageDetailsGame",
        });
      })
    );
  }

  getMessagesFilters({ filter }: GetMessagesFiltersRequest) {
    return this.apiService.get(`messages/${filter}`, {}, `${MOCK_MESSAGES_FILTER}_${filter}`).pipe(
      tap(response => {
        switch (filter) {
          case "categories": {
            this.schemaValidator.validateResponse({
              url: `/messages/categories`,
              payload: {},
              response: response,
              schema: "messagesCategoriesGame",
            });
            break;
          }
          case "sender-groups": {
            this.schemaValidator.validateResponse({
              url: `/messages/sender-groups`,
              payload: {},
              response: response,
              schema: "messagesSenderGroupsGame",
            });
            break;
          }
        }
      })
    );
  }

  deleteMessage({ messageId }: DeleteMessageRequest) {
    return this.apiService.patch(`messages/${messageId}/trash`);
  }

  markSeen({ messageId }: PatchMarkReadMessageRequest) {
    return this.apiService.patch(`messages/${messageId}/seen`);
  }

  markRead({ messageId }: PatchMarkReadMessageRequest) {
    const options: ApiOptions = {
      isNotShowLoading: true,
    };

    return this.apiService.patch(`messages/${messageId}/read`, options);
  }
}
