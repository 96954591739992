<div class="global-dialog ow-dialog window-a primary">
  <!-- TOP BAR BASE -->
  <div class="top-bar base">
    <div class="title">
      {{ "messages.message" | myTranslate }}
    </div>
  </div>

  <!-- DIALOG EXIT -->
  <m-ui-close-button [preventDefault]="true" (clicked)="closeOrCloseAll()"></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button
    [preventDefault]="true"
    (clicked)="close()"
    *ngIf="messageOpenDetailsData.fromList"></m-ui-back-button>

  <!-- IF MESSAGE EXIST -->
  <div class="height-100 relative" *ngIf="message">
    <loading></loading>

    <!-- HIDDEN REWARD -->
    <div class="flex-container loading-opacity">
      <div class="hidden-reward-container">
        <!-- REWARD IMAGE -->
        <div class="hidden-reward-image">
          <img [src]="icon + '.png' | asset: 'ui'" />
        </div>

        <!-- DESCRIPTION -->
        <div class="inner-html-description" [innerHTML]="message.description | safe: 'html'"></div>

        <!-- PRIZES EXPERT CITY-->
        <ng-container *ngIf="message.parameters.currencies.length || message.parameters.prizes.length">
          <!-- SEPARATOR -->
          <m-ui-separator></m-ui-separator>

          <div class="parameters">
            <!-- CURRENCIES -->
            <m-ui-currency
              *ngFor="let currency of message.parameters.currencies"
              [item]="currency"
              [other]="{ value: 'x' }"
              [stockView]="STOCK_VIEW.E"
              [lack]="false"></m-ui-currency>

            <!-- PRODUCTS -->
            <m-ui-product
              *ngFor="let product of message.parameters.prizes"
              [item]="product"
              [other]="{ value: 'x' }"
              [stockView]="STOCK_VIEW.E"
              [lack]="false"></m-ui-product>
          </div>
        </ng-container>
      </div>
    </div>

    <!-- MESSAGE TRASH -->
    <i
      class="svg-icon box-archive"
      *ngIf="!message.deleted && message.deletable"
      (click)="moveToTrash()"
      [matTooltip]="'messages.archives' | myTranslate"
      matTooltipPosition="above"
      matTooltipShowDelay="300"></i>
  </div>
</div>
