import { CardTask } from "../../interfaces/custom/card-task.interface";
import { setBaseScope } from "./set-base-scope.helper";
import { setScopePrize } from "./set-scope-prize.helper";
import { setTaskClasses } from "./set-task-classes.helper";

export function prepareCardTask(cardTasks: CardTask[], returnArray: CardTask[] = []): CardTask[] {
  const isVendorCards = cardTasks.some(t => t.selected && t.selected.task_definition.vendor);

  cardTasks.forEach(cardTask => {
    if (cardTask.selected) {
      cardTask.selected.scopesPrizes = setScopePrize(cardTask.selected.scopes);
      cardTask.selected.taskClasses = setTaskClasses(cardTask.selected);
      cardTask.selected.baseScope = setBaseScope(cardTask.selected.scopes);
    }

    cardTask.other_tasks.forEach(task => {
      task.scopesPrizes = setScopePrize(task.scopes);
      task.taskClasses = setTaskClasses(task);
      task.baseScope = setBaseScope(task.scopes);
    });

    const cardPosition = cardTask.card_position;

    if (!isVendorCards && cardPosition) {
      returnArray[cardPosition - 1] = cardTask;
    }
  });

  if (isVendorCards) {
    return cardTasks;
  } else {
    return returnArray;
  }
}
