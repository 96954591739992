import { Component, OnDestroy, OnInit } from "@angular/core";

import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { AbstractMissionDetailsComponent } from "../../../../abstract/core/abstract-mission-details.component";

@Component({
  selector: "app-mission-details",
  templateUrl: "./mission-details.component.html",
})
export class MissionDetailsComponent extends AbstractMissionDetailsComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.getMissionDetails(this.data.mission.player_mission_id);
  }

  ngOnDestroy() {
    this.dispatchRemoveMissionToOpen();
    this.clearInterval();
    unsubscribeObject(this.subs);
  }
}
