import { Component, OnChanges, SimpleChanges } from "@angular/core";

import { AbstractHudOffsetTimeComponent } from "../../../../abstract/core/abstract-hud-offset-time.component";

@Component({
  selector: "app-hud-offset-time",
  templateUrl: "./hud-offset-time.component.html",
})
export class HudOffsetTimeComponent extends AbstractHudOffsetTimeComponent implements OnChanges {
  ngOnChanges(changes: SimpleChanges): void {
    this.startTimer();
  }
}
