<div class="global-dialog ow-dialog window-a primary">
  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- LOADING API -->
  <loading></loading>

  <div class="tabs-container">
    <button class="tab"
            [ngClass]="{'active': activeTab === 0}"
            (click)="activeTab = 0">
      <p>Profil</p>
    </button>
    <button class="tab"
            [ngClass]="{'active': activeTab === 1}"
            (click)="activeTab = 1">
      <p>Więcej</p>
    </button>
  </div>

  <div class="player-container loading-opacity" *ngIf="player && activeTab === 0">
    <!-- PLAYER BAR -->
    <app-hud-player-with-photo [blockDetailsOpen]="true" [player]="player"></app-hud-player-with-photo>

    <p class="name">{{ player.first_name }} {{ player.last_name }}</p>

    <!-- EXPERIENCE -->
    <span class="experience">
      {{ player.points_balance | NumberWithSpaces }} / {{ player.next_level_points }} {{ "player-details.exp" | myTranslate }}
    </span>

    <p class="description">Rozbudowuj miasto i realizuj misje fabularne, aby zdobyć dodatkowe punkty doświadczenia</p>

    <m-ui-separator></m-ui-separator>

    <!-- EMAIL -->
    <span class="e-mail" *ngIf="player.email">
      {{ player.email }}
    </span>
  </div>

  <div class="others-container loading-opacity" *ngIf="activeTab === 1">
      <div class="buttons-container">
          <div class="column">
              <!-- CHANGE PLAYER BUTTON -->
              <m-ui-change-player-button *ngIf="flag.change_password" (clicked)="close()"></m-ui-change-player-button>

              <!-- CHANGE PASSWORD BUTTON -->
              <m-ui-change-password-button (clicked)="close()"></m-ui-change-password-button>

              <button class="base primary" (click)="redirectToUserEdit()">
                Edytuj dane
              </button>
          </div>
          <div class="column">
              <a *ngFor="let link of links" [href]="link.url" target="_blank">
                  <button class="base primary">
                      {{link.name}}
                  </button>
              </a>
          </div>
      </div>
    <!-- OTHER DYNAMICS OPTIONS -->
    <ng-template #otherViewTpl></ng-template>

    <!-- PUSH MOBILE -->
    <form [formGroup]="formPush" *ngIf="userService.me.has_installed_mobile_app">
      <div class="control control-checkbox">
        <div class="custom-checkbox">
          <input type="checkbox" formControlName="pushEnabled" (change)="changePush()" />
          <span class="custom-checkbox-fill">
            <i class="svg-icon check"></i>
          </span>
        </div>

        <label>{{ "player-details.push" | myTranslate }}</label>
      </div>
    </form>
  </div>

  <!-- LOGOUT -->
  <div class="logout-button" (click)="logout()">
    <img [src]="'right-from-bracket-solid.svg' | asset: 'ui/icons_fa'"/>
  </div>
</div>
