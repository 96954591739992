import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AbstractInjectBaseComponent } from "src/app/core/abstracts/abstract-inject-base.component";

import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { ApiOptions } from "../../../../../../core/interfaces/api-options";
import { ApiService } from "../../../../../../core/providers/api.service";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { AUTH_ROUTING_ABSOLUTE_PATH } from "../../../../consts/core/routing-auth-absolute.const";

@Component({
  selector: "app-registration-confirm",
  templateUrl: "./registration-confirm.component.html",
})
export class RegistrationConfirmComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ActivatedRoute) activatedRoute: ActivatedRoute;
  @OwInject(Router) router: Router;
  @OwInject(ApiService) apiService: ApiService;
  @OwInject(DialogService) dialogService: DialogService;

  token: string;

  ngOnInit() {
    this.subscribeRoutingParams();
    console.log('bang');
  }

  subscribeRoutingParams() {
    this.activatedRoute.params.subscribe(params => {
      if (params.hasOwnProperty("token")) {
        this.token = params["token"];
        this.registrationConfirm();
      }
    });
  }

  openAlertSuccess() {
    this.dialogService.openAlert({
      title: "Sukces",
      description: "Twoje konto jest teraz aktywne!",
    });
  }

  routeToLogin() {
    setTimeout(() => {
      this.router.navigate([AUTH_ROUTING_ABSOLUTE_PATH.LOGIN]);
    });
  }

  registrationConfirm() {
    const data: RegistrationConfirmRequestData = {
      token: this.token,
    };
    this.registrationConfirmRequest(data).subscribe({
      next: () => {
        this.openAlertSuccess();
        this.routeToLogin();
      },
      error: errResp => {
        this.dialogService.openAlertErrorApi({ errResp });
        this.routeToLogin();
      },
    });
  }

  registrationConfirmRequest(data: RegistrationConfirmRequestData) {
    const options: ApiOptions = {
      body: {
        ...data,
      },
      sendRequestWithoutToken: true,
    };

    return this.apiService.post("registration/confirm", options);
  }
}

interface RegistrationConfirmRequestData {
  token: string;
}
