<!-- LOGO -->
<ng-container *ngIf="!guiService.isSplashShow.value">
  <div class="logo"></div>

  <loading></loading>

  <div class="wrapper">
    <div class="container loading-opacity">
      <form [formGroup]="form" autocomplete="off">
        <p class="teaser">{{ config.auth.sso_options.title_text }}:</p>
        <div *ngIf="config.auth.sso_options.allow_remember_me" class="control control-checkbox remember-me-container">
          <div class="form-checkbox">
            <input autocomplete="off" formControlName="remember_me" id="remember_me" type="checkbox" />
            <span class="form-checkbox-fill">
              <i class="svg-icon square"></i>
              <i class="svg-icon square-check"></i>
            </span>
          </div>
          <label for="remember_me">
            {{ "auth.remember-me" | myTranslate }}
          </label>
        </div>
        <div class="buttons flex-center">
          <button (click)="login()" class="base primary">
            {{ "auth.login.button-send" | myTranslate }}
          </button>
        </div>
      </form>
    </div>
    <a class="contact" [href]="'mailto:' + config?.common?.contact_email">{{ config?.common?.contact_email ?? "" }}</a>
  </div>
  <button
    *ngIf="config.landing_page.enabled"
    class="base primary explore-game"
    [routerLink]="AUTH_ROUTING_ABSOLUTE_PATH.LANDING">
    {{ "auth.login.know-your-mission" | myTranslate }}
  </button>
  <button
    *ngIf="(isInstallable.pwa || isInstallable.onlyIOS) && !window.matchMedia('(display-mode: standalone)').matches"
    class="base secondary install-game"
    (click)="addApplicationToHomeScreen()">
    {{ "auth.login.install-app" | myTranslate }}
  </button>
  <div class="ios-install-label" *ngIf="iOSInstallVisibility" (click)="toggleiOSInstallLabelVisibility()">
    <!--  <i class="svg-icon info"></i>-->
    <i class="fa-light fa-circle-info"></i>
    <p>
      {{ "landing.ios-install" | myTranslate }}
      <b>{{ "landing.ios-install-share" | myTranslate }}</b>
      {{ "landing.ios-install-add" | myTranslate }}
      <b>{{ "landing.ios-install-screen" | myTranslate }}</b>
    </p>
  </div>
  <div class="copyright-container" *ngIf="config.default_page.copyright_text">
      <p [innerHTML]="config.default_page.copyright_text"></p>
  </div>
</ng-container>
