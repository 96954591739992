import {Directive, HostBinding, HostListener, Injectable, OnDestroy, OnInit} from '@angular/core';
import { select, Store } from "@ngrx/store";
import * as R from "ramda";
import { take} from 'rxjs/operators';
import { selectPlayer } from "src/app/store/player/selectors";
import { combineLatest } from 'rxjs';

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { EVENTS } from "../../../../../../core/consts/core/events";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { GlobalService } from "../../../../../../core/providers/global.service";
import { unsubscribeObject } from "../../../../../../core/utility/unsubscribe-array";
import { selectPrimaryParameters } from "../../../../../../store/primary/selectors";
import { AppState } from "../../../../../../store/state";
import { Player } from "../../../../../player/interfaces/player";
import { ParameterDefinition, PlayerParameterBalance } from "../../../../interfaces/parameters";
import { PlayerParameterBalanceHud } from "../../interfaces/core/player-parameter-balance-hud.interface";
import {GuiItem, HudGuiItem} from '../../interfaces/core/gui-item.interface';
import {selectGameCurrentScene} from '../../../../../../store/game/selectors';
import {DialogService} from '../../../../../shared/providers/dialog.service';
import {translate} from '../../../../../../core/helpers/translate.helper';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {selectConfigHudPosition} from '../../../../../../store/config/selectors';

@Directive()
@Injectable()
export abstract class AbstractHudParametersComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(Store) store: Store<AppState>;
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(DialogService) dialogService: DialogService;
  @HostBinding('class') hostClass: 'left' | 'top' | 'right' = null;
  @HostListener('window:resize', ['$event']) resizeEvent(event: Event) {
    this.isMobileMediaQuery = window.innerWidth <= 1023;
  }
  player: Player;
  parameterDefinitions: ParameterDefinition[] = [];
  parameterBalances: PlayerParameterBalanceHud[] = [];
  guiItems: HudGuiItem[] = [];
  visible = true;
  currentIslandId = null;
  isMobileMediaQuery = false;

  subs = {
    parameters: null,
    player: null,
    global: null,
    combined: null
  };

  PLACEHOLDERS = [
    {
      placeholder: "%population_current",
      getValue: parameter => Math.floor(this.player.population_current * parameter.population_multiplier),
    },
    {
      placeholder: "%population_delta",
      getValue: () => this.player.population_delta,
    },
    {
      placeholder: "%population_max",
      getValue: () => this.player.population_max,
    },
    {
      placeholder: "%current_percentage_value",
    },
    {
      placeholder: "%current_income_value",
    },
    {
      placeholder: "%current_outcome_value",
    },
  ];

  ngOnInit() {
    this.resizeEvent(new Event(null))
    this.subscribeGlobalEvents();
    this.subscribePlayer();

    // set correct position of resources
    this.store.pipe(select(selectConfigHudPosition)).pipe(take(1)).subscribe(res => {
      this.hostClass = res.element_positions.parameter;
    })
  }

  // register and handle player or currentScene change
  subscribePlayer() {
    const player$ = this.store.pipe(select(selectPlayer));
    const currentScene$ = this.store.pipe(select(selectGameCurrentScene));

    // refresh data after player or current scene change
    this.subs.combined = combineLatest([player$, currentScene$]).subscribe(([player, currentIslandId]) => {
      this.currentIslandId = parseInt(currentIslandId);
      this.player = player;
      this.guiItems = structuredClone(player.gui_items)
        .filter(x => x.parameter_id != null)
        .filter(x => x.player_island_id === null || x.player_island_id != null && x.player_island_id === this.currentIslandId)
        .sort((a, b) => a.sequence - b.sequence);

      this.getLatestPrimaryParameters()
    })
  }

  // get parameters definitions once per player and map change
  getLatestPrimaryParameters() {
    this.subs.parameters = this.store.pipe(select(selectPrimaryParameters), take(1)).subscribe(parameterDefinitions => {
      this.parameterDefinitions = parameterDefinitions;
      this.setHudParameters();
      this.combineParameterWithGuiItem();
    });
  }

  setHudParameters() {
    this.parameterBalances = [];
    this.parameterDefinitions.forEach(parameterDefinition => {
      this.player.parameter_balances.forEach(parameterBalance => {
        const parameter: PlayerParameterBalanceHud = <PlayerParameterBalanceHud>R.clone(parameterBalance);
        if (parameterDefinition.parameter_id === parameter.parameter_id) {
          this.setDisplayHudValues(parameter);
          this.setTooltip(parameter);
          this.parameterBalances.push(parameter);
        }
      });
    });
    this.filterActiveParameter();
    this.filterHiddenParameter();
  }

  setDisplayHudValues(parameter: PlayerParameterBalanceHud) {
    switch (parameter.type) {
      case "population_delta": {
        parameter.displayHud = parameter.balance;
        break;
      }
      case "resources": {
        parameter.displayHud = parameter.balance ?? parameter.income;
        break;
      }
      default: {
        parameter.displayHud = Math.floor(parameter.percentage * 100);
        if (parameter.displayHud > 199) {
          parameter.displayHud = 199;
        }
      }
    }
  }

  subscribeGlobalEvents() {
    this.subs.global = this.globalService.globalEvents.subscribe(event => {
      switch (event.name) {
        case EVENTS.GUI.HUD_PARAMETERS.SHOW:
          this.visible = true;
          break;

        case EVENTS.GUI.HUD_PARAMETERS.HIDE:
          this.visible = false;
          break;
      }
    });
  }

  setTooltip(parameter: PlayerParameterBalanceHud) {
    if (parameter.tooltip) {
      Object.keys(this.PLACEHOLDERS).forEach(key => {
        const placeholderObject: { placeholder: string; getValue: (parameter?: PlayerParameterBalance) => any } =
          this.PLACEHOLDERS[key];

        if (placeholderObject.placeholder === "%current_percentage_value") {
          placeholderObject.getValue = () => `${parameter.displayHud}%`;
        }

        if (placeholderObject.placeholder === "%current_income_value") {
          placeholderObject.getValue = () => `${parameter.income}`;
        }

        if (placeholderObject.placeholder === "%current_outcome_value") {
          placeholderObject.getValue = () => `${parameter.outcome}`;
        }

        parameter.tooltip = parameter.tooltip.replace(
          placeholderObject.placeholder,
          placeholderObject.getValue(parameter)
        );
      });
    }
  }

  combineParameterWithGuiItem() {
    this.guiItems.forEach(guiItem => {
      const parameterBalance = this.parameterBalances.find(x => x.parameter_id === guiItem.parameter_id);
      if (parameterBalance) {
        guiItem.displayHud = parameterBalance.displayHud;
        guiItem.tooltip = parameterBalance.tooltip;
        guiItem.sequence = parameterBalance.position ?? 0;
        guiItem.parameterType = parameterBalance.type;
        guiItem.isActive = parameterBalance.is_parameter_active;
      } else {
        // it mean we dont have any balance, so it is not active
        guiItem.isActive = false;
      }
    });
    // remove not active, keep sorting via parameters
    this.guiItems = this.guiItems
      .filter(x => x.isActive !== false)
      .sort((a, b) => a.sequence - b.sequence);

  }

  handleClickEvent(e: {item: GuiItem, tooltip?: NgbTooltip}) {
    if (this.isMobileMediaQuery) {
      this.dialogService.openAlert({
        description: e.item.tooltip,
      });
    }
  }

  filterActiveParameter() {
    this.parameterBalances = this.parameterBalances.filter(parameter => parameter.is_parameter_active);
  }

  filterHiddenParameter() {
    this.parameterBalances = this.parameterBalances.filter(parameter => parameter.position >= 0);
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
