import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GuiItem, HudGuiItem} from '../../../../interfaces/core/gui-item.interface';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';

type HudElementType = 'currency' | 'products' | 'parameters';

@Component({
  selector: 'app-hud-element',
  templateUrl: './hud-element.component.html'
})
export class HudElementComponent implements OnInit {
  @Input() guiItem: HudGuiItem = null;
  @Input() populationDelta: number = null;
  @Input() variant: string = '';
  @Input() isMobile = true;
  @Output() onClickEvent = new EventEmitter<{item: HudGuiItem, tooltip?: NgbTooltip}>();
  type: HudElementType = 'products';
  path: string = '';

  ngOnInit() {
    this.type = this.determineTypeOfElement(this.guiItem)
  }

  determineTypeOfElement(guiItem: GuiItem): HudElementType {
    if (guiItem.product_id) {
      return 'products';
    }

    return guiItem.currency_id ? 'currency' : 'parameters';
  }

  handleOnClickEvent(item: HudGuiItem, tooltip: NgbTooltip) {
    if (!item) {
      return;
    }

    this.onClickEvent.emit({item, tooltip});
  }
}
