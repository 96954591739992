<div
  class="global-dialog ow-dialog window-a primary no-transparent"
  *ngIf="({currency_id: data.eventShop.prize_currency_id, amount: null} | singleCurrency) as currency"
>
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button></m-ui-back-button>

  <!-- TITLE -->
  <div class="title">
    {{ data.eventShop.title }}
  </div>

  <div class="currency-balance-container">
    <img
      [src]="({currency_id: data.eventShop.prize_currency_id, amount: null} | singleCurrency).iconUrl"
      class="icon-currency"
    />

    {{ (playerService.player.currency_balances | currencyGameBalance:currency.key).balance | NumberWithSpaces }}
  </div>

  <div class="frame loading-opacity">
    <div
      class="limit-container"
      [class.text-error]="isLimitOverrun"
    >
      <ng-container *ngIf="data.product.limit_per_person > 0 else infinityTpl"
      >
        {{ (data.product.limit_per_person - data.product.limit_per_person_left) + '/' + data.product.limit_per_person }}
      </ng-container>
    </div>

    <div class="requirements" *ngIf="isLowLevel || isLowStar">
      <div
        class="to-low-lvl-info text-error"
        *ngIf="isLowLevel"
      >
        (wymagany poziom {{ data.product.required_level }})
      </div>

      <div
        class="to-low-star-info text-error"
        *ngIf="isLowStar"
      >
        (wymagana {{ data.product.required_star.star_name }})
      </div>

    </div>

    <div class="start">
      <ng-container *ngIf="(data.product.icon + '.png' | asset:'products_eventmarket/big') as imagePath">
        <img
          class="icon-product"
          (click)="openAlertImage(imagePath)"
          [src]="imagePath"
        />
      </ng-container>

      <div class="product-name">
        {{ data.product.name }}
      </div>

      <div class="description" [innerHTML]="data.product.description"></div>
    </div>

    <form
      [formGroup]="form"
      class="end"
      [class.deactivated]="!isActive()"
      [matTooltip]="setTooltip()"
    >
      <div class="control">
        <div class="default-field" *ngIf="!hideInputField">
          <input
            placeholder="ilość"
            class="control-input no-ow-input-directive"
            formControlName="amount"
            type="number"
            min="1"
          />
        </div>
      </div>

      <div class="summary">
        {{ data.product.price }}
        <img
          [src]="({currency_id: data.eventShop.prize_currency_id, amount: null} | singleCurrency).iconUrl"
          class="icon-currency"
        />
        x {{ form.get('amount').value || 0 }} szt. =&nbsp;
        <span
          [class.text-error]="!isCurrencyEnough((playerService.player.currency_balances | currencyGameBalance:currency.key).balance)">
          {{ (form.get('amount').value || 0) * data.product.price }}
        </span>
        <img
          [src]="({currency_id: data.eventShop.prize_currency_id, amount: null} | singleCurrency).iconUrl"
          class="icon-currency"
        />
      </div>
    </form>
  </div>

  <div class="bottom-bar">
    <div class="bar-button base">
      <button
        class="base primary"
        (click)="submit()"
        [disabled]="form.invalid || isLowLevel || isLowStar || isLimitOverrun || !isActive()"
      >
        Kup
      </button>
    </div>
  </div>
</div>

<ng-template #infinityTpl>
  <i class="fas fa-infinity"></i>
</ng-template>
