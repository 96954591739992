import { Component, OnDestroy, OnInit } from "@angular/core";

import { orientationModes } from "../../../../../../core/consts/core/orientation-modes.enum";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { isTokenExpired } from "../../../../../../core/utility/is-expired-token.helper";
import { getToken } from "../../../../../../core/utility/token";
import { unsubscribeObject } from "../../../../../../core/utility/unsubscribe-array";
import { loginLogout } from "../../../../../../store/auth/login/actions";
import { AbstractLoginComponent } from "../../../../abstract/abstract-login.component";
import { A2hsService } from "../../../../services/core/a2hs.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent extends AbstractLoginComponent implements OnInit, OnDestroy {
  @OwInject(A2hsService) a2hsService: A2hsService;
  iOSInstallVisibility = false;
  isInstallable: { pwa: boolean; onlyIOS: boolean } = null;
  subs = {
    config: null,
    actionUserSuccess: null,
    actionAuthSuccess: null,
    installable: null,
  };

  ngOnInit() {
    super.ngOnInit();
    this.subs.installable = this.a2hsService.appCanBeInstalled.subscribe(installable => {
      this.isInstallable = installable;
    });
    if (getToken().token) {
      if (isTokenExpired()) {
        this.store.dispatch(loginLogout());
        return;
      }
      super.resolveLoginSuccessRedirect(true);
    } else {
      this.subscribeConfig();
      this.nativeAppService.setNativeAppOrientation(orientationModes.Auto);
      this.subscribeAuthSuccess();
      this.subscribeUserSuccess();
    }
  }

  addApplicationToHomeScreen() {
    if (this.isInstallable.pwa) {
      this.a2hsService.addApplicationToHomeScreen();
    } else if (this.isInstallable.onlyIOS) {
      this.toggleiOSInstallLabelVisibility();
    }
  }
  toggleiOSInstallLabelVisibility() {
    this.iOSInstallVisibility = !this.iOSInstallVisibility;
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }

  protected readonly window = window;
}
