import { Injectable, OnDestroy } from "@angular/core";

import { AbstractEventDialogsService } from "../../../../../../core/abstracts/abstract-event-dialogs.service";
import { unsubscribeObject } from "../../../../../../core/utility/unsubscribe-array";
import { EVENT_DIALOGS_WAREHOUSE } from "../../consts/core/event-dialogs/event-dialogs.const";
import { EVENT_DIALOGS_WAREHOUSE_CUSTOM } from "../../consts/custom/event-dialogs/event-dialogs.const";

@Injectable({
  providedIn: "root",
})
export class WarehouseEventDialogsService extends AbstractEventDialogsService implements OnDestroy {
  getEventDialogs() {
    return {
      coreEventDialogs: EVENT_DIALOGS_WAREHOUSE,
      customEventDialogs: EVENT_DIALOGS_WAREHOUSE_CUSTOM,
    };
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
