import * as R from "ramda";

export function getAndPrepareDynamicComponents(dynamicComponents) {
  Object.keys(dynamicComponents).forEach(key => {
    const dynamicComponent = dynamicComponents[key];
    if (typeof dynamicComponent.data === "function") {
      dynamicComponent.data = dynamicComponent.data(this);
    }
  });

  return dynamicComponents;
}

export function loadComponent({ component, viewContainerRef, data }) {
  const componentFactory = this["componentFactoryResolver"].resolveComponentFactory(component);
  const componentRef = this[viewContainerRef].createComponent(componentFactory);
  componentRef.instance["data"] = data;
}

export function loadComponents(dynamicsComponents) {
  if (!dynamicsComponents) {
    return;
  }

  Object.keys(dynamicsComponents).forEach(key => {
    const dynamicComponent = dynamicsComponents[key];
    loadComponent.call(this, dynamicComponent);
  });
}

export function setDynamicsComponents({ coreDynamicComponents, customDynamicComponents }) {
  const mergeDynamicsComponents = R.mergeDeepRight(coreDynamicComponents, customDynamicComponents);
  const dynamicsComponents = getAndPrepareDynamicComponents.call(this, mergeDynamicsComponents);
  loadComponents.call(this, dynamicsComponents);
}
