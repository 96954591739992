import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AbstractInjectBaseComponent } from "src/app/core/abstracts/abstract-inject-base.component";
import { CurrencyPrize } from "../../../../interfaces/custom/basic-actions.interface";
import { STOCK_VIEW } from "../../../../../shared-ui/mobile/consts/stock-view.const";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { CardTaskContestComponent } from "../../dialogs/card-task-contest/card-task-contest.component";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ContestCard } from '../../../../interfaces/custom/card-task.interface';
import { OwDate } from '../../../../../../../../core/utility/ow-date.class';

@Component({
  selector: "app-business-task-contests",
  templateUrl: "./task-contests.component.html",
})
export class TaskContestsComponent extends AbstractInjectBaseComponent {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MAT_DIALOG_DATA) data: Array<{
    name: string;
    id: number;
    description: string;
    currency_prizes: CurrencyPrize[];
    product_prizes: any;
  }>;
  @Input() cardContest: ContestCard[] = [];
  @Input() currentPage: string = "";
  @Input() blockNext = true;
  @Input() blockPrev = true;
  @Input() owDate: OwDate;
  @Output() prev: EventEmitter<any> = new EventEmitter();
  @Output() next: EventEmitter<any> = new EventEmitter();
  cardTaskContestComponent = CardTaskContestComponent;

  openContest(index: number) {
    this.dialogService.open(this.cardTaskContestComponent, {
      data: {
        index,
        cardTasks: this.cardContest,
        year: this.owDate.year,
        month: this.owDate.month
      },
    });
  }

  triggerNext() {
    this.next.emit(true);
  }

  triggerPrev() {
    this.prev.emit(true);
  }

  protected readonly STOCK_VIEW = STOCK_VIEW;
}
