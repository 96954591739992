import { Directive, Injectable, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, take } from "rxjs/operators";

import { AbstractInjectBaseComponent } from "../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../core/decorators/ow-inject.decorator";
import { ApplicationConfig } from "../../../store/config/interfaces/config.state";
import { selectConfigOfApplication } from "../../../store/config/selectors";
import { AppState } from "../../../store/state";
import { DialogService } from "../../shared/providers/dialog.service";
import { AuthService } from "../api/services/auth.service";
import { AUTH_ROUTING_ABSOLUTE_PATH } from "../consts/core/routing-auth-absolute.const";
import { ResetPasswordRequest } from "../interfaces/reset-password";

@Directive()
@Injectable()
export abstract class AbstractForgotPasswordComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(FormBuilder) fb: FormBuilder;
  @OwInject(AuthService) authService: AuthService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(Store) store: Store<AppState>;
  AUTH_ROUTING_ABSOLUTE_PATH = AUTH_ROUTING_ABSOLUTE_PATH;
  formSendSuccess: boolean;
  form: FormGroup;
  config: ApplicationConfig = null;
  loginFieldLabel: string;

  ngOnInit() {
    this.store
      .select(selectConfigOfApplication)
      .pipe(take(1))
      .subscribe(res => {
        this.config = res;
        this.loginFieldLabel = res.auth.form_options.login_field_label;
      });
  }

  afterConstructor() {
    this.form = this.fb.group({
      email: this.fb.control(null, [Validators.email, Validators.required]),
    });
  }

  onFormSubmit() {
    const data: ResetPasswordRequest = {
      email: this.form.value.email,
    };

    this.authService
      .resetPasswordRequest(data)
      .pipe(
        map(() => {
          this.formSendSuccess = true;
        }),
        catchError(errResp => {
          return of(errResp);
        })
      )
      .subscribe(() => {});
  }
}
