import { EventDialog } from "../../../../../../../core/interfaces/event-dialog.interface";
import { CoreWarehouseComponent } from "../../../base/core/dialogs/warehouse/warehouse.component";
import { CoreWarehouseDetailsComponent } from "../../../base/core/dialogs/warehouse-details/warehouse-details.component";
import { CoreWarehouseSaleComponent } from "../../../base/core/dialogs/warehouse-sale/warehouse-sale.component";
import { EVENT_DIALOGS_NAMES_WAREHOUSE } from "./event-names.const";

export const EVENT_DIALOGS_WAREHOUSE: EventDialog = {
  [EVENT_DIALOGS_NAMES_WAREHOUSE.WAREHOUSE]: CoreWarehouseComponent,
  [EVENT_DIALOGS_NAMES_WAREHOUSE.WAREHOUSE_DETAILS]: CoreWarehouseDetailsComponent,
  [EVENT_DIALOGS_NAMES_WAREHOUSE.WAREHOUSE_SALE]: CoreWarehouseSaleComponent,
};
