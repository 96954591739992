import { Directive, Input, ViewContainerRef } from "@angular/core";

@Directive({
  selector: "[appDynamicTemplate]",
})
export class DynamicTemplateDirective {
  @Input() ref: string;

  constructor(public viewContainerRef: ViewContainerRef) {}
}
