import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { PlayGameComponent } from "../../basic/core/components/play-game/play-game.component";

const gameRoutes: Routes = [
  {
    path: "",
    component: PlayGameComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(gameRoutes)],
  exports: [RouterModule],
})
export class GameRoutingModule {}
