import { HudMenuComponent } from "../../../../base/core/components/hud-menu/hud-menu.component";
import { HudParametersComponent } from "../../../../base/core/components/hud-parameters/hud-parameters.component";
import { HudResourcesComponent } from "../../../../base/core/components/hud-resources/hud-resources.component";

export const CORE_DYNAMIC_COMPONENTS = {
  HUD_MENU: {
    component: HudMenuComponent,
    viewContainerRef: "hudMenuTpl",
    data: context => {
      return {
        player: context["player"],
      };
    },
  },
  HUD_PARAMETERS: {
    component: HudParametersComponent,
    viewContainerRef: "hudParametersTpl",
  },
  HUD_RESOURCES: {
    component: HudResourcesComponent,
    viewContainerRef: "hudResourcesTpl",
  },
};
