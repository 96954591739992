import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { SharedModule } from "../../../../shared/shared.module";
import { MUiCurrencyComponent } from "./components/stock/ui-currency/ui-currency.component";
import { MUiParameterComponent } from "./components/stock/ui-parameter/ui-parameter.component";
import { MUiProductComponent } from "./components/stock/ui-product/ui-product.component";
import { MUiStockOtherComponent } from "./components/stock/ui-stock-other/ui-stock-other.component";
import { MUiBackButtonComponent } from "./components/ui-back-button/ui-back-button.component";
import { MUiChangePasswordButtonComponent } from "./components/ui-change-password-button/ui-change-password-button.component";
import { MUiChangePlayerButtonComponent } from "./components/ui-change-player-button/ui-change-player-button.component";
import { MUiCloseButtonComponent } from "./components/ui-close-button/ui-close-button.component";
import { MUiCostsComponent } from "./components/ui-costs/ui-costs.component";
import { MUiExpUpComponent } from "./components/ui-exp-up/ui-exp-up.component";
import { MUiGameDateComponent } from "./components/ui-game-date/ui-game-date.component";
import { MUiGroupCountComponent } from "./components/ui-group-count/ui-group-count.component";
import { MUiMultiStockComponent } from "./components/ui-multi-stock/ui-multi-stock.component";
import { MUiNavigationComponent } from "./components/ui-navigation/ui-navigation.component";
import { MUiNavigationCenterComponent } from "./components/ui-navigation-center/ui-navigation-center.component";
import { MUiRequirementsComponent } from "./components/ui-requirements/ui-requirements.component";
import { MUiSeparatorComponent } from "./components/ui-separator/ui-separator.component";

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [
    MUiNavigationComponent,
    MUiBackButtonComponent,
    MUiNavigationCenterComponent,
    MUiCloseButtonComponent,
    MUiCurrencyComponent,
    MUiProductComponent,
    MUiStockOtherComponent,
    MUiGroupCountComponent,
    MUiGameDateComponent,
    MUiExpUpComponent,
    MUiCostsComponent,
    MUiRequirementsComponent,
    MUiSeparatorComponent,
    MUiChangePasswordButtonComponent,
    MUiMultiStockComponent,
    MUiParameterComponent,
    MUiChangePlayerButtonComponent,
  ],
  exports: [
    MUiNavigationComponent,
    MUiBackButtonComponent,
    MUiNavigationCenterComponent,
    MUiCloseButtonComponent,
    MUiCurrencyComponent,
    MUiProductComponent,
    MUiStockOtherComponent,
    MUiGroupCountComponent,
    MUiGameDateComponent,
    MUiExpUpComponent,
    MUiCostsComponent,
    MUiRequirementsComponent,
    MUiSeparatorComponent,
    MUiChangePasswordButtonComponent,
    MUiMultiStockComponent,
    MUiParameterComponent,
    MUiChangePlayerButtonComponent,
  ],
})
export class SharedUiMobileModule {}
