import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GuiService {
  isSplashShow: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLockOpenDialog: boolean;
  isOfficeOpen: boolean;
  HISTORY_MAP_LEVEL = 10;
  gameLoadingProgress: number;

  constructor() {}

  toggleOffice() {
    this.isOfficeOpen = !this.isOfficeOpen;
  }
}
