import { Injectable } from "@angular/core";

import { AbstractEventDialogsService } from "../../../../../../core/abstracts/abstract-event-dialogs.service";
import { EVENT_DIALOGS_EVENT_SHOP_CUSTOM } from "../../consts/core/event-dialogs/event-dialogs.const";

@Injectable({
  providedIn: "root",
})
export class EventShopEventDialogsService extends AbstractEventDialogsService {
  getEventDialogs() {
    return {
      customEventDialogs: EVENT_DIALOGS_EVENT_SHOP_CUSTOM,
    };
  }
}
