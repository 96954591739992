import { createAction, props } from "@ngrx/store";

export enum ActionTypes {
  SOCKET_CLEAR_STATUS = "[socket] clear status",
  SOCKET_CONNECT = "[socket] connect socket",
  SOCKET_CONNECTING = "[socket] connecting socket",
  SOCKET_CONNECTED = "[socket] socket connected",
  SOCKET_AUTHENTICATE = "[socket] socket authenticate",
  SOCKET_AUTHENTICATING = "[socket] socket authenticating",
  SOCKET_AUTHENTICATED = "[socket] socket authenticated",
  SOCKET_DISCONNECT = "[socket] socket disconnect",
  SOCKET_DISCONNECTING = "[socket] socket disconnecting",
  SOCKET_DISCONNECTED = "[socket] socket disconnected",
  SOCKET_RECONNECT_ATTEMPT = "[socket] socket reconnect attempt",
  SOCKET_OTHER_SESSION = "[socket] socket other session",
  SOCKET_CONNECTION_ERROR = "[socket] socket connection error",
}

export const socketClearStatus = createAction(ActionTypes.SOCKET_CLEAR_STATUS);
export const socketConnect = createAction(ActionTypes.SOCKET_CONNECT);
export const socketConnecting = createAction(ActionTypes.SOCKET_CONNECTING);
export const socketConnected = createAction(ActionTypes.SOCKET_CONNECTED);
export const socketAuthenticate = createAction(ActionTypes.SOCKET_AUTHENTICATE);
export const socketAuthenticating = createAction(ActionTypes.SOCKET_AUTHENTICATING);
export const socketAuthenticated = createAction(ActionTypes.SOCKET_AUTHENTICATED);
export const socketDisconnect = createAction(ActionTypes.SOCKET_DISCONNECT);
export const socketDisconnecting = createAction(ActionTypes.SOCKET_DISCONNECTING);
export const socketDisconnected = createAction(ActionTypes.SOCKET_DISCONNECTED);
export const socketReconnectAttempt = createAction(ActionTypes.SOCKET_RECONNECT_ATTEMPT, props<{ attempt: number }>());
export const socketOtherSession = createAction(ActionTypes.SOCKET_OTHER_SESSION);
export const socketConnectionError = createAction(ActionTypes.SOCKET_CONNECTION_ERROR);
