import { Directive, Injectable } from "@angular/core";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { GlobalService } from "../../../../../../core/providers/global.service";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { STOCK_VIEW } from "../../../shared-ui/mobile/consts/stock-view.const";
import { ApiBusinessService } from "../../api/custom/services/api-business.service";
import { ApiBusinessBasicActionsService } from "../../api/custom/services/api-business-basic-actions.service";
import { BasicActionCurrent, BasicActionDetails, BasicActionItem, CurrencyPrizeSum } from "../../interfaces/custom/basic-actions.interface";

@Directive()
@Injectable()
export abstract class AbstractBasicActionsComponent extends AbstractInjectBaseComponent {
  @OwInject(ApiBusinessService) apiBusinessService: ApiBusinessService;
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(ApiBusinessBasicActionsService) apiBusinessBasicActionsService: ApiBusinessBasicActionsService;
  abstract dialogService: DialogService;
  showBaseActions: boolean;
  basicActionDetails: BasicActionDetails;
  basicActionCurrent: BasicActionCurrent[];
  amountSums: CurrencyPrizeSum[];
  quantitySums: CurrencyPrizeSum[];
  STOCK_VIEW = STOCK_VIEW;
  limitPrize = "8000";

  toggleBaseActions() {
    this.showBaseActions = !this.showBaseActions;
    this.getBasicActionCurrent();
  }

  getBasicActionDetails(date) {
    this.apiBusinessBasicActionsService.basicActionDetails({ date }).subscribe(
      resp => {
        this.basicActionDetails = resp;
        if (this.basicActionDetails.items.length === 0 && this.basicActionDetails.previous) {
          this.getBasicActionDetails(this.basicActionDetails.previous);
        } else {
          this.amountSums = this.sumsPrizes(this.basicActionDetails.items, "amount");
          this.quantitySums = this.sumsPrizes(this.basicActionDetails.items, "quantity");
        }
      },
      errResp => {
        this.dialogService.openAlertErrorApi({ errResp });
      }
    );
  }

  getBasicActionCurrent() {
    if (this.basicActionCurrent) {
      return;
    }

    this.apiBusinessBasicActionsService.basicActionCurrent().subscribe(
      resp => {
        this.basicActionCurrent = resp;
      },
      errResp => {
        this.dialogService.openAlertErrorApi({ errResp });
      }
    );
  }

  sumsPrizes(array: BasicActionItem[], key: string) {
    const arrayToSave = [];
    const mapSums = new Map();

    array.forEach(item => {
      item.prizes.forEach(prize => {
        let tmp = 0;
        if (mapSums.has(prize.currency_id)) {
          tmp = mapSums.get(prize.currency_id);
        }

        mapSums.set(prize.currency_id, tmp + prize[key]);
      });
    });

    mapSums.forEach((value, currency_id) => {
      arrayToSave.push({ currency_id, value });
    });

    return arrayToSave;
  }
}
