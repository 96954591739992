import { Component, OnDestroy, OnInit } from "@angular/core";

import { unsubscribeObject } from "../../../../../../../core/utility/unsubscribe-array";
import { AbstractRequirementsComponent } from "../../../abstract/abstract-requirements.component";

@Component({
  selector: "m-ui-requirements",
  templateUrl: "./ui-requirements.component.html",
})
export class MUiRequirementsComponent extends AbstractRequirementsComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.subscribePlayer();
    this.subscribePrimary();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
