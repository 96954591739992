<ow-perfect-scrollbar [scrollGradient]="false">
  <ng-container *ngIf="cardVendor?.length">
    <card-task-vendor
      *ngFor="let item of cardVendor"
      [cardTasks]="cardVendor"
      [cardTask]="item"
    ></card-task-vendor>
  </ng-container>

  <ng-container *ngIf="cardVendor?.length === 0">
    <p class="no-results">Brak wyników</p>
  </ng-container>

  <ng-container *ngIf="cardVendor == null">
    <loading [showAlways]="true"></loading>
  </ng-container>
</ow-perfect-scrollbar>

<!-- NAVIGATION BUTTONS -->
<div class="bottom-bar">
  <div class="bar-button long">
    <div class="pagination">
      <div class="buttons">
        <!-- PREV -->
        <button class="square small primary"
                [disabled]="blockPrev"
                (click)="!blockPrev && triggerPrev()">
          <i class="svg-icon chevron-left"></i>
        </button>
      </div>

      <div class="pages">{{currentPage}}</div>

      <div class="buttons">
        <!-- NEXT -->
        <button
          [disabled]="blockNext" (click)="!blockNext && triggerNext()"
          class="square small primary">
          <i class="svg-icon chevron-right"></i>
        </button>
      </div>
    </div>
  </div>
</div>
