import {Directive, Injectable, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import moment from "moment";

import { APP_ROUTING_ABSOLUTE_PATH } from "../../../consts/routing-app-absolute.const";
import { AbstractInjectBaseComponent } from "../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../core/decorators/ow-inject.decorator";
import { NativeAppService } from "../../../core/providers/native-app.service";
import { AppState } from "../../../store/state";
import { utilityUpdateMePlayerId } from "../../../store/utility/actions";
import { GuiService } from "../../game/services/gui.service";
import { Player } from "../../player/interfaces/player";
import { DialogService } from "../../shared/providers/dialog.service";
import { User } from "../../user/interfaces/user";
import { UserService } from "../../user/providers/user.service";
import {ApplicationConfig} from '../../../store/config/interfaces/config.state';
import {selectConfigOfApplication} from '../../../store/config/selectors';
import {take} from 'rxjs/operators';

@Directive()
@Injectable()
export abstract class AbstractPlayerChooseComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(UserService) userService: UserService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(Router) router: Router;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(GuiService) guiService: GuiService;
  @OwInject(ActivatedRoute) activatedRoute: ActivatedRoute;
  @OwInject(NativeAppService) nativeAppService: NativeAppService;
  loading: boolean;
  currentPlayer: Partial<Player>;
  enabledPlayers: Partial<Player>[];
  selectedPlayerId: number;
  subs = {
    user: null,
  };
  config: ApplicationConfig = null;

  ngOnInit() {
    this.store.select(selectConfigOfApplication).pipe(take(1)).subscribe(res => {
      this.config = res;
    })
  }

  checkReload() {
    const isReload = this.activatedRoute.snapshot.queryParamMap.get("reload");
    if (isReload) {
      setTimeout(() => {
        this.guiService.isSplashShow.next(true);
        location.replace(location.pathname);
      });
    }
  }

  showFirstLoginDialog(user: User) {
    setTimeout(() => {
      let lastLoginDate = "Brak";

      if (user.previous_login_at) {
        this.checkPlayerLength();
        lastLoginDate = moment(user.previous_login_at).format("YYYY-MM-DD HH:mm");
      }

      this.dialogService.openAlert(
        {
          title: "Witaj ponownie!",
          description: `Ostatnie logowanie: ${lastLoginDate}`,
          buttonClose: {
            label: "Ok",
          },
        },
        () => {
          this.checkPlayerLength();
        }
      );
    });
  }

  checkPlayerLength() {
    if (this.enabledPlayers.length === 1) {
      if (this.selectedPlayerId !== null) {
        this.redirectGame();
      } else {
        this.setDefaultPlayer();
      }
    }
  }

  changePlayer(player) {
    this.currentPlayer = player;
  }

  setDefaultPlayer(playerId = this.currentPlayer.id) {
    this.guiService.isSplashShow.next(true);
    this.loading = true;

    this.userService.setDefaultPlayer(playerId).subscribe(
      () => {
        this.store.dispatch(utilityUpdateMePlayerId({ playerId }));

        this.redirectGame();
      },
      errResp => {
        errResp.defaultHandler.unsubscribe();
        this.loading = false;
        this.guiService.isSplashShow.next(false);
      }
    );
  }

  redirectGame() {
    this.loading = true;
    this.router.navigate([APP_ROUTING_ABSOLUTE_PATH.GAME]);
    // this.nativeAppHandler();
  }

  findSelectedPlayer() {
    if (this.selectedPlayerId) {
      return this.enabledPlayers.find(player => player.id === this.selectedPlayerId);
    }
  }

  nativeAppHandler() {
    this.nativeAppService.gameStarted();
  }
}
