import { Directive, Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { select, Store } from "@ngrx/store";
import * as R from "ramda";
import { map, take } from "rxjs/operators";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { translate } from "../../../../../../core/helpers/translate.helper";
import { selectPrimary } from "../../../../../../store/primary/selectors";
import { AppState } from "../../../../../../store/state";
import { PlayerService } from "../../../../../player/providers/player.service";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { STOCK_VIEW } from "../../../shared-ui/mobile/consts/stock-view.const";
import { PostCurrencyExchangeRequest } from "../../api/core/interfaces/post-currency-exchange-request.interface";
import { ApiCurrencyExchangeService } from "../../api/core/services/api-currency-exchange.service";
import { SpreadDefinition } from "../../interfaces/core/spread-definition.interface";
import {ExchangeConfirmComponent} from '../../basic/core/dialogs/exchange-confirm/exchange-confirm.component';

@Directive()
@Injectable()
export abstract class AbstractCurrencyExchangeComponent extends AbstractInjectBaseComponent {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractCurrencyExchangeComponent>;
  @OwInject(ApiCurrencyExchangeService) apiCurrencyExchangeService: ApiCurrencyExchangeService;
  @OwInject(FormBuilder) fb: FormBuilder;

  allSpreads: SpreadDefinition[];
  spread: SpreadDefinition;
  currenciesIdFrom: number[];
  spreadsTo: SpreadDefinition[];
  STOCK_VIEW = STOCK_VIEW;
  form: FormGroup;
  activeCurrencyFromId;
  activeTooltipCurrencyFrom = false;
  activeTooltipSpreadTo = false;
  isSellAllChecked = false;
  amount = 0;
  max: number;
  subs = {
    primary: null,
  };

  subscribePrimary() {
    this.subs.primary = this.store
      .pipe(
        select(selectPrimary),
        map(primary => {
          return primary.spreads;
        }),
        take(1)
      )
      .subscribe(spreads => {
        this.allSpreads = spreads;
        this.setCurrenciesFrom();
        this.checkAndSetOneSpread();
      });
  }

  checkAndSetOneSpread() {
    if (this.allSpreads.length === 1) {
      this.changeActiveCurrencyFrom(this.allSpreads[0].from.currency_id);
      this.changeSpread(this.spreadsTo[0]);
    }
  }

  setAmount() {
    this.isSellAllChecked = !this.isSellAllChecked;
    if (this.isSellAllChecked) {
      this.form.get("amount").setValue(this.max);
    } else {
      this.form.get("amount").setValue(0);
    }
  }

  setMax(balance) {
    this.max = balance;
  }

  showTooltipCurrencyFrom() {
    this.hideAllTooltips();
    this.activeTooltipCurrencyFrom = true;
    this.activeCurrencyFromId = null;
    this.clearSpread();
  }

  showTooltipCurrencyTo() {
    this.hideAllTooltips();
    if (!this.activeCurrencyFromId) {
      return;
    }
    this.activeTooltipSpreadTo = true;
  }

  hideAllTooltips() {
    this.activeTooltipCurrencyFrom = false;
    this.activeTooltipSpreadTo = false;
  }

  changeActiveCurrencyFrom(id: number, event?) {
    if (event) {
      event.stopPropagation();
    }
    this.clearSpread();
    this.activeCurrencyFromId = id;
    this.activeTooltipCurrencyFrom = false;
    this.setSpreadsTo();
    this.showTooltipCurrencyTo();
  }

  setCurrenciesFrom() {
    this.currenciesIdFrom = this.allSpreads.map(spread => {
      return spread.from.currency_id;
    });
    this.currenciesIdFrom = R.uniq(this.currenciesIdFrom);
  }

  changeSpread(spread: SpreadDefinition, event?) {
    if (event) {
      event.stopPropagation();
    }
    this.spread = spread;
    this.clearForm();
    this.activeTooltipSpreadTo = false;
  }

  setSpreadsTo() {
    this.spreadsTo = this.allSpreads.filter(spread => {
      return spread.from.currency_id === this.activeCurrencyFromId;
    });
  }

  clearForm() {
    this.amount = 0;
    this.form = this.fb.group({
      amount: ["", [Validators.required, Validators.minLength(1)]],
    });
    this.subscribeChangeAmount();
  }

  clearSpread() {
    this.spread = null;
    this.clearForm();
  }

  subscribeChangeAmount() {
    this.form.get("amount").valueChanges.subscribe(value => {
      const control = this.form.controls["amount"];
      let error: string = null;
      control.setErrors({ error });

      if (value != null) {
        if (value < 0) {
          error = translate("currency-exchange.form.errors.error-1");
          control.setErrors({ error });
          return;
        }

        if (this.spread.spread < 100) {
          const spread = (1 / this.spread.spread) * 100;
          if (value % spread !== 0) {
            error = translate("currency-exchange.form.errors.error-2", [spread]);
            control.setErrors({ error });
            return;
          }
        } else {
          if (value % 1 !== 0) {
            error = translate("currency-exchange.form.errors.error-3");
            control.setErrors({ error });
            return;
          }
        }
      }

      control.patchValue(value, { emitEvent: false });
      this.amount = parseInt(value || 0, 10);
    });
  }

  exchangeConfirm() {
    this.dialogService.open(ExchangeConfirmComponent, {
      data: {
        title: translate("currency-exchange.alert-confirm.title"),
        from: {...this.spreadsTo[0].from, amount: this.amount},
        to: {...this.spreadsTo[0].to, amount: !this.spread ? 0 : (this.spread.spread * this.amount) / 100}
      }
    }, confirm => {
        if (confirm) {
          this.exchange();
        }
      });
  }

  exchange() {
    const currencyExchangeRequest: PostCurrencyExchangeRequest = {
      amount: this.amount,
      spread_id: this.spread.id,
    };
    const description = translate("currency-exchange.alert-success.description");

    this.apiCurrencyExchangeService.postCurrencyExchange(currencyExchangeRequest).subscribe(() => {
      this.clearForm();
      this.dialogService.openAlert({ description: description });
    });
  }

  close() {
    setTimeout(() => {
      this.matDialogRef.close();
    });
  }
}
