import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";

import { UserEffects } from "../../store/user/effects";

@NgModule({
  imports: [
    CommonModule,
    //StoreModule.forFeature('user', userReducer),
    EffectsModule.forFeature([UserEffects]),
  ],
  declarations: [],
})
export class UserModule {}
