<!-- PREV -->
<div class="navigation-bar prev" [class.transparent]="hidePrev">
  <button
    [ngClass]="[buttonLayout, buttonSize, buttonColor, otherClass]"
    (click)="prev($event)"
    [disabled]="disabledPrev">
    <i class="svg-icon angle-left"></i>
  </button>
</div>

<!-- NEXT -->
<div class="navigation-bar next" [class.transparent]="hideNext">
  <button
    [ngClass]="[buttonLayout, buttonSize, buttonColor, otherClass]"
    (click)="next($event)"
    [disabled]="disabledNext">
    <i class="svg-icon angle-right"></i>
  </button>
</div>
