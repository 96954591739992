import { AfterViewInit, Directive, ElementRef, Input } from "@angular/core";
import { register } from "swiper/element/bundle";
import { SwiperOptions } from "swiper/types/swiper-options";

register();

@Directive({
  selector: "[swiperElement]",
})
export class SwiperDirective implements AfterViewInit {
  private readonly swiperElement: HTMLElement;
  @Input()
  config?: SwiperOptions;

  constructor(private element: ElementRef<HTMLElement>) {
    this.swiperElement = element.nativeElement;
  }

  public ngAfterViewInit(): void {
    Object.assign(this.element.nativeElement, this.config);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.element.nativeElement.initialize();
  }
}
