import { Directive, Injectable, Input } from "@angular/core";

import { AbstractInjectBaseComponent } from "../../../../../core/abstracts/abstract-inject-base.component";
import { STOCK_VIEW } from "../mobile/consts/stock-view.const";

@Directive()
@Injectable()
export abstract class AbstractExpUpComponent extends AbstractInjectBaseComponent {
  @Input() experience: number;
  STOCK_VIEW = STOCK_VIEW;
}
