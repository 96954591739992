import { Injectable } from "@angular/core";

import { AbstractMessageService } from "../../abstract/core/abstract-message.service";
import { MAPPED_TYPES_TO_COMPONENT } from "../../consts/core/mapped-types-to-component.const";

@Injectable({
  providedIn: "root",
})
export class MessageService extends AbstractMessageService {
  mappedTypesToEventDialog = MAPPED_TYPES_TO_COMPONENT;
}
