import {ApiService} from '../../core/providers/api.service';
import {ApplicationConfig} from '../../store/config/interfaces/config.state';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class ApplicationConfigService {
  constructor(private httpClient: HttpClient) {
  }

  getApplicationConfig(): Observable<ApplicationConfig> {
    let url = environment.apiUrl;
    return this.httpClient.get<ApplicationConfig>(url + 'config');
  }
}
