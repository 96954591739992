import { Injectable } from "@angular/core";

import { MyTranslatePipe } from "../pipes/my-translate.pipe";

@Injectable({
  providedIn: "root",
})
export class MyTranslateService {
  constructor(private myTranslatePipe: MyTranslatePipe) {}

  translate(value: any, args?: any) {
    return this.myTranslatePipe.transform(value, args);
  }
}
