import { TileHoverConfig } from "../../interfaces/tile-hover-config.interface";

const BASE_TEXT_STYLES = {
  color: "#FFF",
  fontFamily: "MikadoPhaser",
  align: "center",
  boundsAlignH: "bottom",
  wordWrapWidth: 200,
  wordWrap: true,
  boundsAlignV: "bottom",
};

export const TILE_HOVER_CONFIG: TileHoverConfig = {
  mainTextStyles: {
    ...BASE_TEXT_STYLES,
    fontSize: 32,
  },
  additionalTextStyles: {
    ...BASE_TEXT_STYLES,
    fontSize: 24,
  },
  basePositions: {
    autoProductionText: { x: 0, y: -60 },
    levelText: { x: 0, y: 16 },
    textObject: { x: 0, y: -16 },
    textObjectBelow: { x: 0, y: 20 },
  },
  maxZoomScaleValue: 2,
};
